import { FC } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import CommonStudentActivityList from "./common/CommonStudentActivityList";

const StudentActivityReportPage: FC = () => {

    return (
        <PageWrapper title='Histórico de Alunos'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonStudentActivityList />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default StudentActivityReportPage;