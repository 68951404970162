import { useState } from 'react';
import PaginationButtons from '../components/PaginationButtons';
import { DEFAULT_PAGE_COUNT, INITIAL_PAGE, PER_COUNT } from '../components/table/utils';

interface IUsePagination {
	data: any[];
	label?: string;
}

export const usePagination = ({ data, label = 'itens' }: IUsePagination) => {
	const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
	const [perPage, setPerPage] = useState(PER_COUNT[DEFAULT_PAGE_COUNT]);

	return {
		currentPage,
		perPage,
		PaginationElement: () => (
			<PaginationButtons
				data={data}
				label={label}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		),
	};
};
