import { useState } from 'react';
import { Calendar as DatePicker } from 'react-date-range';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../../components/bootstrap/Dropdown';
import { FilterForm } from '../../../../components/common/FilterForm';
import { FILTER_FORM_INITIAL_VALUES } from '../constants';
import { AttendanceListFilterFormValues } from '../types';
import { ptBR } from 'date-fns/locale';
import dayjs from 'dayjs';

interface Props {
	date: Date;
	onFilterChange: (values: Date) => void;
}

export const AttendanceFilter = ({ date, onFilterChange }: Props) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [dateFilter, setDateFilter] = useState<Date>(date);

	const onFormSubmit = (): void => {
		setIsFilterOpened(false);
		onFilterChange(dateFilter);
	};

	const onClear = () => {
		setDateFilter(dayjs().toDate());
	};

	const onDateFilterChange = (newDate: Date) => {
		setDateFilter(newDate);
	};

	return (
		<Dropdown isOpen={isFilterOpened} setIsOpen={setIsFilterOpened} isButtonGroup>
			<DropdownToggle hasIcon={false}>
				<Button isLink={true} icon='FilterAlt' color='primary'>
					Filtrar
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd isCloseAfterLeave={false}>
				<FilterForm<AttendanceListFilterFormValues>
					initialValues={FILTER_FORM_INITIAL_VALUES}
					onSubmit={onFormSubmit}
					onClear={onClear}>
					{() => (
						<div className='col-12'>
							<DatePicker
								onChange={onDateFilterChange}
								date={dateFilter}
								color={process.env.REACT_APP_PRIMARY_COLOR}
								locale={ptBR}
							/>
						</div>
					)}
				</FilterForm>
			</DropdownMenu>
		</Dropdown>
	);
};
