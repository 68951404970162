import { Elements, EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import stripePaymentService from '../../../services/stripe-payment-service';
import CheckoutForm from './CheckoutForm';
import AuthContext from '../../../contexts/authContext';

interface Props {
	amount: number;
	currency: string;
	onSuccess: () => void;
	customAmount: boolean;
}

const StripeFormElement = ({ amount, currency, onSuccess }: Props) => {
	const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
	const [clientSecret, setClientSecret] = useState('');
	const { cognitoId } = useContext(AuthContext);

	const getStripePromise = async () => {
		const stripe = await stripePaymentService.getStripeObject();
		setStripePromise(stripe); 
	};

	const getClientSecret = async () => {
		const clientSecret = await stripePaymentService.createaPaymentIntent(amount, currency, cognitoId);
		setClientSecret(clientSecret);
	};

	useEffect(() => {
		getStripePromise();
	}, []);

	useEffect(() => {
		getClientSecret();
	}, []);

	if (!stripePromise || !clientSecret) {
		return (
			<Card borderSize={1} stretch>
				<CardBody className='d-flex flex-wrap justify-content-center align-content-center'>
					<Spinner color='primary' size='3rem' />
				</CardBody>
			</Card>
		);
	} 
	// else if (customAmount) {
	// 	return (
	// 		<Card borderSize={1} stretch>
	// 			<CardBody>

	// 			</CardBody>
	// 		</Card>
	// 	);
	// }

	return (
		<Card borderSize={1} stretch>
			<CardBody>
				{stripePromise && clientSecret && (
					<Elements
						stripe={stripePromise}
						options={{
							clientSecret,
							locale: ptBR.code as StripeElementLocale,							
						}}
						key={clientSecret}>  
		
						<CheckoutForm amount={amount} onSuccess={onSuccess} />
					</Elements>
			
				)}

			</CardBody>
		</Card>
	);
};

export default StripeFormElement;
