import classNames from "classnames";
import { useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GracieBarra from '../../../../assets/logos/gbbatatais.png';
import { decodeToken } from "../../../../common/data/jwtPayload";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import { POST_ASYNC } from "../../../../components/extras/HttpHelper";
import { showSuccess, showWarning } from "../../../../components/extras/Notifications";
import attendanceService from "../../../../services/attendance-service";

const CommonFacialCheckin = () => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const navigate = useNavigate();

    const [authorizedWebCam, setAuthorizedWebCam] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [image, setImage] = useState<Blob | null>(null);
    
    const handleOnClick = useCallback(() => navigate('../attendance/facial-checkin'), [navigate]);

    const authorizeWebCam = async () => {
        setIsLoading(true);

        setAuthorizedWebCam(true);
        startCamera();
        setIsLoading(false);
        // Cleanup function to stop the media stream
        return () => {
            if (stream) {
                let tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }

            setIsLoading(false);
        };
    };

    const startCamera = async () => {
        try {
            if (stream) {
                let tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                setStream(mediaStream);
                await videoRef.current.play();
            }
        } catch (error) {
            setAuthorizedWebCam(false);
            setIsLoading(false);
            showWarning('Falha ao iniciar a câmera');
        }
    };

    const handleCaptureAndSend = async () => {

        try {
            setIsLoading(true);

            if (videoRef.current) {
                videoRef.current.pause();
                const capturedImage = await captureImage();
                setImage(capturedImage);

                if (capturedImage) {
                    await sendImageToApi(capturedImage);
                }

                videoRef.current.play();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const captureImage = async (): Promise<Blob | null> => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                return new Promise((resolve) => {
                    canvas.toBlob(resolve, 'image/jpeg');
                });
            }
        }
        return null;
    };

    const sendImageToApi = async (imageBlob: Blob) => {

        setIsLoading(true);

        const checkUserFaceResponse = await checkUserFace(imageBlob);
        if (checkUserFaceResponse && checkUserFaceResponse.success) {

            if (checkUserFaceResponse.collection.length > 0) {
                const faceLoginResponse = await faceLogin(checkUserFaceResponse.collection[0].externalImageId);

                if (faceLoginResponse && faceLoginResponse.success && faceLoginResponse.entity) {
                    const decodedToken = decodeToken(faceLoginResponse.entity.authenticationResult.idToken);

                    await handleCheckIn(decodedToken.sub);
                }

            } else {
                showWarning('Rosto não encontrado. Por favor, entre em contato com o administrador do sistema');
            }

        } else {
            showWarning(checkUserFaceResponse.message);
        }

        setIsLoading(false);
    }

    const checkUserFace = async (imageBlob: Blob) => {
        const request = new FormData();
        request.append('image', imageBlob, 'captured-image.jpg');

        const response = await POST_ASYNC('api/NUser/CheckUserFace', request);

        if (response && response.success) {
            return response;
        } else {
            showWarning(response.message);
        }
    }

    const faceLogin = async (username: string) => {

        const request = {
            externalImageId: username
        }

        const response = await POST_ASYNC('api/NAuth/FaceLogin', request);

        if (response && response.success) {
            return response;
        } else {
            showWarning(response.message);
        }
    }

    const handleCheckIn = async (userId: string) => {
        const request = {
            userId: userId,
            comment: 'Checkin facial realizado via tela'
        };

        const response = await attendanceService.addNewAttendance(request);
        
        if (response.success) {
            showSuccess('Check-in realizado com sucesso');
        } else {
            showWarning(response.error || 'Falha ao realizar check-in');
        }

        setAuthorizedWebCam(true);
        setImage(null);
        setStream(null);
        videoRef.current!.srcObject = null;
        handleOnClick();

        authorizeWebCam();
    }

    return (
        <>
            <div className='row h-100 align-items-center justify-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                    <Card className='shadow-3d-primary'>
                        <CardBody>
                            <div className='text-center my-5'>
                                <Link
                                    to='/'
                                    className={classNames('text-decoration-none fw-bold display-2 text-dark'
                                    )}
                                    aria-label='Gracie Barra'>
                                    <img src={GracieBarra} alt='Gracie Barra' width={200} />
                                </Link>
                            </div>
                            <div>
                                <video className='w-100' ref={videoRef} playsInline />
                            </div>

                            <br />
                            <br />

                            <form className='row g-4'>
                                {!authorizedWebCam ? (
                                    <>
                                        <div className='col-12'>
                                            <Button
                                                color='primary'
                                                className='w-100 py-3'
                                                onClick={() => { authorizeWebCam() }}
                                            >
                                                {isLoading && (
                                                    <Spinner isSmall inButton isGrow />
                                                )}
                                                Autorizar Câmera
                                            </Button>
                                        </div>
                                    </>) : (
                                    <>
                                        <div className='col-12'>
                                            <Button
                                                color='primary'
                                                className='w-100 py-3'
                                                onClick={async () => { await handleCaptureAndSend() }}
                                                isDisable={isLoading}>
                                                {isLoading && (
                                                    <Spinner isSmall inButton isGrow />
                                                )}
                                                Realizar Check-in
                                            </Button>
                                        </div>
                                    </>
                                )}

                                {/* <div className='col-12'>
                                    <Button
                                        color='dark'
                                        className='w-100 py-3'
                                        isOutline={false}
                                        onClick={() => navigate(`/`)}
                                    >
                                        {isLoading && (
                                            <Spinner isSmall inButton isGrow />
                                        )}
                                        Sair
                                    </Button>
                                </div> */}
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default CommonFacialCheckin;