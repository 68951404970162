import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';
import AuthContext from '../../../contexts/authContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight
} from '../../../layout/SubHeader/SubHeader';
import { appMenu, dashboardPagesMenu } from '../../../menu';
import cognitoService from '../../../services/cognito-service';
import LoadingPage from '../loading/LoadingPage';
import CommonStudent from '../user/common/CommonStudent';
import CommonStudentDetailInfo from './Common/CommonStudentDetailInfo';

const StudentDetail = () => {

	const navigate = useNavigate();
	const { userData } = useContext(AuthContext);

	const { action } = useParams();
	const { id } = useParams();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [student, setStudent] = useState<IStudent>();
	const [payments, setPayments] = useState<IPayment[]>([]);
	const [studentDetailsEditOffcanvas, setStudentDetailsEditOffcanvas] = useState<boolean>(false);
	const [promoteUser, setPromoteUser] = useState<boolean>(false);
	const [isSubmittingPromoteUser, setIsSubmittingPromoteUser] = useState<boolean>(false);
	const [role, setRole] = useState<string>('');

	useEffect(() => {
		fetchStudents();
	}, [id, userData]);

	const fetchStudents = async () => {

		setIsLoading(true);

		const { student, error } = await cognitoService.getById(id!!);

		setIsLoading(false);

		if (error) {
			showWarning(error);
			return;
		}

		setStudent(student);
		setPayments(student?.payments || []);
	};

	const afterSubmit = () => {
		if (action === 'detail') {
			navigate('/student/students');
		} else {
			navigate('/');
		}
	}

	const handleStudentDetailEdit = () => {
		setStudentDetailsEditOffcanvas(!studentDetailsEditOffcanvas);
	};

	const handlePromoteUser = (role: string) => {
		setRole(role);
		setPromoteUser(true);
	};

	const handleSubmitPromoteUser = async () => {

		const request = {
			username: student?.username,
			role: role
		};

		setIsLoading(true);

		const response = await cognitoService.promoteUser(request);

		setIsLoading(false);

		if (response && response.success) {
			setPromoteUser(false);
			showSuccess('Usuário promovido com sucesso');
		} else {
			showWarning(response.error || 'Falha ao promover aluno');
		}
	}

	return (
		<PageWrapper title={'Detalhes do Aluno'}>
			<SubHeader>
				<SubHeaderLeft>
					<Button
						color='primary'
						isLink
						icon='ArrowBack'
						tag='a'
						to={action === 'detail' ? `../${appMenu.registers.subMenu.students.path}` : `../${dashboardPagesMenu.dashboard.path}`}  >
						Voltar
					</Button>
				</SubHeaderLeft>

				<SubHeaderRight>
					<Button
						color={'warning'}
						icon='Edit'
						onClick={() => handleStudentDetailEdit()}
					>
						Alterar
					</Button>

					{
						userData?.isAdmin && (
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									<Button
										color={'info'}
										icon='StarOutline'
									>
										Promover
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									<DropdownItem isHeader>
										Promover aluno para:
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='Person'
											onClick={() => handlePromoteUser('Professores')}
										>
											Instrutor
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='AdminPanelSettings'
											onClick={() => handlePromoteUser('Administradores')}
										>
											Administrador
										</Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						)
					}

				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row'>
					<div className='col-xl-12'>
						{
							isLoading ? (
								<LoadingPage />
							) : (
								<>
									<CommonStudentDetailInfo student={student} />
								</>
							)
						}
					</div>
				</div>
			</Page>

			<Modal
				tag='form'
				setIsOpen={setStudentDetailsEditOffcanvas}
				isOpen={studentDetailsEditOffcanvas}
				titleId='studentDetailEdit'
				isCentered
				size='xl'
				isStaticBackdrop={true}
			>
				<ModalHeader setIsOpen={setStudentDetailsEditOffcanvas}>
					<OffCanvasTitle id='studentDetailEdit'>Alterar Aluno</OffCanvasTitle>
				</ModalHeader>
				<ModalBody>
					<CommonStudent afterSubmit={afterSubmit} />
				</ModalBody>

			</Modal>

			<Modal
				setIsOpen={setPromoteUser}
				isOpen={!!promoteUser}
				isCentered
			>
				<ModalHeader setIsOpen={setPromoteUser}>
					<ModalTitle id='preview'>Promover Aluno</ModalTitle>
				</ModalHeader>
				<ModalBody>

					{
						role && (
							<div className='row g-4'>
								<p>Deseja promover o aluno <b>{student?.fullName}</b> para {role === 'Professores' ? 'instrutor' : 'administrador'} ?</p>
							</div>
						)
					}
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<Button
						color='primary'
						className='w-100'
						onClick={handleSubmitPromoteUser}
						isDisable={isSubmittingPromoteUser}
					>
						{isSubmitting && (
							<Spinner isSmall inButton isGrow />
						)}
						Promover
					</Button>
				</ModalFooter>
			</Modal>

		</PageWrapper>
	);
};

export default StudentDetail;

