import { FC } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import useDarkMode from "../../../../hooks/useDarkMode";

interface ICommonEmergencyCardProps {
    student?: IStudent
}

const CommonAddressCard: FC<ICommonEmergencyCardProps> = ({ student }) => {

    const { themeStatus, darkModeStatus } = useDarkMode();

    return (

        <>
            <Card borderSize={1} stretch>
                <CardHeader>
                    <CardLabel icon='MapsHomeWork' iconColor='primary'>
                        <CardTitle tag='div' className='h5'>
                            Endereço
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody>

                    {
                        student?.address ? (
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                    <div className="text-muted fw-bold">{student?.address}</div>
                                    <div className="text-muted fw-bold">{student?.neighborhood}</div>
                                    <div className="text-muted fw-bold">{`${student?.city} - ${student?.country || 'Brasil'}`}</div>
                                    <div className="text-muted fw-bold">{student?.zipCode}</div>
                                    <div className="text-muted fw-bold">{student?.observation}</div>
                                </div>
                            </div>
                        ) :
                            (
                                <div className='row'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                        <div>
                                            <div className='fw-bold fs-6 mb-0'>{'Dados de endereço não informados'}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </CardBody>
            </Card >
        </>
    );
};

CommonAddressCard.defaultProps = {
    student: undefined
};

export default CommonAddressCard;