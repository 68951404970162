import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { IFileUpload, OperationTypeEnum } from "../../../../common/data/document/fileUpload";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { POST_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import Icon from "../../../../components/icon/Icon";

interface ICommonFileUploadProps {
    onUpdateDocuments?: any;
    uploadedFiles?: IDocument[];
}


const CommonFileUpload: FC<ICommonFileUploadProps> = ({ onUpdateDocuments, uploadedFiles }) => {

    const inputFileRef = React.useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [files, setFiles] = useState<IFileUpload[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (uploadedFiles) {

            const databaseFiles: IFileUpload[] = [];

            uploadedFiles.map(f => {
                const uploadedFile: IFileUpload = {
                    id: f.id,
                    file: f.s3Url,
                    description: f.description,
                    name: getFileName(f.s3Url),
                    type: f.documentType || '',
                    operationType: OperationTypeEnum.Added
                };

                databaseFiles.push(uploadedFile);

            });

            setFiles(databaseFiles);
        }
    }, []);

    const getFileName = (fileName: string) => {
        let name = '';

        const segments = fileName.split("/");
        if (segments && segments.length > 0) {
            name = segments[segments.length - 1];
        }

        return name;

    }

    const formik = useFormik({
        initialValues: {
            file: null,
            description: '',
            name: '',
            type: ''
        },
        validateOnChange: false,
        onSubmit: () => {

        }
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            setFile(file);

            const reader = new FileReader();
            reader.onload = (e) => {
                formik.setFieldValue('file', e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleAddFileClick = () => {
        if (!formik.values.description) {
            showWarning('Insira uma descrição para adicionar o arquivo');
            return;
        }

        if (!formik.values.file) {
            showWarning('Selecione um arquivo para adicionar');
            return;
        }

        const isFound = files.some((element) => {
            return element.name === file?.name;
        });

        if (isFound) {
            showWarning('Arquivo já adicionado');
            return;
        }

        const fileToUpload: IFileUpload = {
            id: '',
            file: formik.values.file || '',
            description: formik.values.description,
            name: file?.name || '',
            type: file?.type || '',
            operationType: OperationTypeEnum.Add
        };

        setFiles([...files, fileToUpload]);
        setFile(null);
        formik.resetForm();

        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }

        onUpdateDocuments(fileToUpload);
    }

    const handleRemoveFileClick = async (id: string, fileName: string) => {

        if (id) {
            const response = await inactivateDocument(id);

            if (response && response.success) {
                removeFromFiles(fileName);
            }

        } else {
            removeFromFiles(fileName);
        }

        const fileToUpload: IFileUpload = {
            id: '',
            name: fileName,
            operationType: OperationTypeEnum.Remove
        };

        onUpdateDocuments(fileToUpload);
    }

    const inactivateDocument = async (guid: string) => {

        try {

            const request = {
                id: guid
            };

            setIsSubmitting(true);

            const response = await POST_ASYNC(`api/NDocument/Inactivate`, request);

            setIsSubmitting(false);

            return response;

        } catch (error) {
            setIsSubmitting(false);
        }
    }

    const removeFromFiles = (fileName: string) => {
        const updatedFiles = files.filter((file) => file.name !== fileName);
        setFiles(updatedFiles);
    }


    return (
        <>
            <Card stretch noValidate>
                <CardHeader>
                    <CardLabel icon='UploadFile' iconColor='primary'>
                        <CardTitle className='h5'>
                            Upload de Documentos
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody isScrollable>
                    <Card>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='row g-4 align-items-center'>

                                    <div className='col-xl'>
                                        <div className='row g-4'>

                                            <div className='col-12'>
                                                <Input
                                                    type='file'
                                                    ref={inputFileRef}
                                                    onChange={handleFileChange}
                                                />
                                            </div>

                                            <div className='col-12'>
                                                <FormGroup
                                                    id='description'
                                                    label='Descrição'
                                                    isFloating
                                                >
                                                    <Input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.description}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-12'>
                                                <Button
                                                    color='success'
                                                    icon='AddCircle'
                                                    isLight
                                                    onClick={() => handleAddFileClick()}
                                                >
                                                    Adicionar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </CardBody>
                    </Card>

                    <div className="row">

                        {files.map((file: IFileUpload, index) => (
                            <div key={index} className='col-md-6'>

                                <Card key={index}>
                                    <CardHeader>
                                        <CardLabel>
                                            <CardTitle tag='div' className='h5 text-wrap'>
                                                {file.name}

                                                {
                                                    file.operationType === OperationTypeEnum.Added ?

                                                        (
                                                            <span className='position-absolute top-0 end-0 px-2 py-1'>
                                                                <Icon
                                                                    icon="Cloud"
                                                                    color="success"
                                                                    size='2x'
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className='position-absolute top-0 end-0 px-2 py-1'>
                                                                <Icon
                                                                    icon="CloudOff"
                                                                    color="primary"
                                                                    size='2x'
                                                                />
                                                            </span>
                                                        )
                                                }

                                            </CardTitle>
                                            <CardSubTitle tag='div' className='h6 text-wrap'>
                                                {file.type}
                                            </CardSubTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>


                                        <div className='mx-auto d-block img-fluid mb-3 text-center'>
                                            <Icon icon={'FilePresent'} size='7x' color={'primary'} />
                                        </div>

                                        <div className='row align-items-center text-wrap'>
                                            <div className='col'>{file.description}</div>
                                        </div>
                                    </CardBody>
                                    <CardFooter className='shadow-3d-container'>

                                            <Button
                                                color='primary'
                                                className={`w-100 mb-4`}
                                                size='lg'
                                                onClick={() => handleRemoveFileClick(file.id, file.name)}
                                            >
                                                Remover
                                            </Button>
                                        {/* </CardFooterRight> */}


                                    </CardFooter>
                                </Card>

                            </div>
                        ))}

                    </div>

                </CardBody>
            </Card>
        </>
    );

};

export default CommonFileUpload;