import { PaymentTypeFilter } from "../../../../common/data/filters/paymentTypeFilter";
import Button from "../../../../components/bootstrap/Button";
import { CardActions, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import { CommonPaymentTypesFilter } from "./CommonPaymentTypesFilter";

interface Props {
	paymentTypes: IPaymentType[];
	onFilterChange: (values: PaymentTypeFilter) => void;
	onCreateNewStudent: () => void;
}

const CommonPaymentsTypePageHeader = ({ paymentTypes, onFilterChange, onCreateNewStudent }: Props) => {
	return (
		<CardHeader borderSize={1}>
			<CardLabel icon='AccountBalanceWallet' iconColor='primary'>
				<CardTitle tag='div' className='h5'>
                    Formas de Pagamento
				</CardTitle>
			</CardLabel>
			<CardActions>
				<Button color='success' icon='AddCircle' isLight onClick={onCreateNewStudent}>
					Adicionar
				</Button>

				<CommonPaymentTypesFilter
					paymentTypes={paymentTypes}
					onFilterChange={onFilterChange}
				/>
			</CardActions>
		</CardHeader>
	);
};

export default CommonPaymentsTypePageHeader;