import { useState } from 'react';
import { ColorResult, GithubPicker } from 'react-color';

export const DEFAULT_COLOR = '#BED3F3';

interface Props {
	onColorSelect: (color: string) => void;
	color?: string;
}

export const ColorPicker = ({ color = DEFAULT_COLOR, onColorSelect }: Props) => {
	const [pickedColor, setPickedColor] = useState(color);

	const onColorChange = (color: ColorResult) => {
		const hexColor = color.hex;

		setPickedColor(hexColor);
		onColorSelect(hexColor);
	};

	return (
		<>
			<div
				style={{
					backgroundColor: pickedColor,
					width: 100,
					height: 50,
					border: '2px solid white',
				}}></div>

			<GithubPicker width='100%' onChange={onColorChange} color={pickedColor} />
		</>
	);
};
