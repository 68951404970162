import PropTypes from 'prop-types';
import { FC } from 'react';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 347 && !!height ? height * (1024 / 347) : width}
			height={width !== 1024 && !!width ? width * (347 / 1024) : height}
			viewBox='0 0 1024 347'
			fill='none'
		>
			<path opacity="0.933" fill="#e1201b" d="M 101.5,59.5 C 143.505,59.1668 185.505,59.5001 227.5,60.5C 228.765,68.1534 228.765,75.82 227.5,83.5C 187.499,83.3333 147.499,83.5 107.5,84C 95.1166,89.0803 89.1166,98.4137 89.5,112C 88.9204,123.665 93.587,132.331 103.5,138C 135.459,139.463 167.459,139.963 199.5,139.5C 199.5,134.167 199.5,128.833 199.5,123.5C 168.5,123.5 137.5,123.5 106.5,123.5C 106.5,115.5 106.5,107.5 106.5,99.5C 147.22,98.8425 187.72,99.5091 228,101.5C 228.821,122.21 228.655,142.877 227.5,163.5C 185.504,164.667 143.504,164.833 101.5,164C 78.1506,161.925 64.3173,149.425 60,126.5C 58.3421,112.153 59.6755,98.1529 64,84.5C 68.9739,74.858 76.4739,68.0246 86.5,64C 91.5351,62.1506 96.5351,60.6506 101.5,59.5 Z" />
			<path opacity="0.938" fill="#e1201b" d="M 236.5,59.5 C 282.835,59.3333 329.168,59.5 375.5,60C 393.976,62.977 403.976,73.8104 405.5,92.5C 405.056,107.432 398.056,117.932 384.5,124C 392.106,136.551 399.44,149.218 406.5,162C 406.355,162.772 406.022,163.439 405.5,164C 395.833,164.667 386.167,164.667 376.5,164C 369.693,152.216 363.026,140.382 356.5,128.5C 326.174,127.5 295.841,127.167 265.5,127.5C 265.667,139.171 265.5,150.838 265,162.5C 264.5,163 264,163.5 263.5,164C 259.167,164.167 254.833,164.333 250.5,164.5C 245.746,164.466 241.246,163.799 237,162.5C 236.5,128.168 236.333,93.835 236.5,59.5 Z M 265.5,83.5 C 298.303,84.4394 330.803,84.4394 363,83.5C 370.453,83.3336 375.286,86.667 377.5,93.5C 377.444,96.1113 376.444,98.278 374.5,100C 370.971,101.466 367.305,102.466 363.5,103C 330.835,103.5 298.168,103.667 265.5,103.5C 265.5,96.8333 265.5,90.1667 265.5,83.5 Z" />
			<path opacity="0.935" fill="#e1201b" d="M 480.5,59.5 C 490.506,59.3335 500.506,59.5002 510.5,60C 533.79,93.4129 556.957,126.913 580,160.5C 580.667,161.5 580.667,162.5 580,163.5C 570.911,164.65 561.745,164.817 552.5,164C 547.463,157.427 542.796,150.594 538.5,143.5C 510.167,142.167 481.833,142.167 453.5,143.5C 448.378,150.245 443.378,157.078 438.5,164C 429.506,164.5 420.506,164.666 411.5,164.5C 411.298,162.011 411.798,159.677 413,157.5C 435.712,124.959 458.212,92.2922 480.5,59.5 Z M 495.5,82.5 C 503.688,94.3543 512.022,106.188 520.5,118C 504.156,118.833 487.823,118.666 471.5,117.5C 479.757,105.978 487.757,94.3115 495.5,82.5 Z" />
			<path opacity="0.935" fill="#e1201b" d="M 621.5,59.5 C 662.501,59.3333 703.501,59.5 744.5,60C 745.482,67.747 745.815,75.5803 745.5,83.5C 706.165,83.3333 666.832,83.5 627.5,84C 613.755,88.1213 607.089,97.4546 607.5,112C 606.954,121.713 610.287,129.713 617.5,136C 619.957,137.562 622.624,138.562 625.5,139C 665.499,139.5 705.499,139.667 745.5,139.5C 745.816,147.752 745.482,155.919 744.5,164C 703.167,164.667 661.833,164.667 620.5,164C 590.277,160.269 575.611,143.102 576.5,112.5C 576.351,81.725 591.351,64.0583 621.5,59.5 Z" />
			<path opacity="0.937" fill="#e1201b" d="M 755.5,59.5 C 765.749,59.1806 775.916,59.5139 786,60.5C 786.826,94.8751 786.66,129.208 785.5,163.5C 775.272,164.818 765.106,164.818 755,163.5C 754.174,128.762 754.341,94.0952 755.5,59.5 Z" />
			<path opacity="0.94" fill="#e1201b" d="M 836.5,59.5 C 878.833,59.5 921.167,59.5 963.5,59.5C 963.5,67.5 963.5,75.5 963.5,83.5C 923.499,83.3333 883.499,83.5 843.5,84C 836.118,86.5471 830.785,91.3805 827.5,98.5C 872.496,99.3333 917.496,99.8333 962.5,100C 963.482,107.747 963.815,115.58 963.5,123.5C 918.26,123.543 872.926,123.543 827.5,123.5C 829.805,132.303 835.472,137.47 844.5,139C 884.165,139.5 923.832,139.667 963.5,139.5C 963.5,147.833 963.5,156.167 963.5,164.5C 921.499,164.667 879.499,164.5 837.5,164C 808.047,159.649 793.714,142.482 794.5,112.5C 794.051,82.9463 808.051,65.2796 836.5,59.5 Z" />
			<path opacity="0.928" fill="#e1201b" d="M 59.5,181.5 C 104.835,181.333 150.168,181.5 195.5,182C 214.94,181.947 226.273,191.447 229.5,210.5C 229.047,219.906 225.047,227.406 217.5,233C 225.354,238.362 229.354,245.862 229.5,255.5C 228.795,267.361 223.128,275.861 212.5,281C 205.972,282.572 199.472,284.072 193,285.5C 148.98,285.651 104.814,285.484 60.5,285C 59.5039,250.573 59.1706,216.073 59.5,181.5 Z M 87.5,206.5 C 122.168,206.333 156.835,206.5 191.5,207C 196.668,207.816 199.168,210.649 199,215.5C 197.681,217.487 195.848,218.654 193.5,219C 158.168,219.5 122.835,219.667 87.5,219.5C 87.5,215.167 87.5,210.833 87.5,206.5 Z M 87.5,245.5 C 122.502,245.333 157.502,245.5 192.5,246C 198.724,247.282 200.557,250.782 198,256.5C 196.581,257.46 195.081,258.293 193.5,259C 158.168,259.5 122.835,259.667 87.5,259.5C 87.5,254.833 87.5,250.167 87.5,245.5 Z" />
			<path opacity="0.931" fill="#e1201b" d="M 307.5,181.5 C 317.749,181.181 327.916,181.514 338,182.5C 360.276,215.775 382.943,248.775 406,281.5C 406.72,283.112 406.554,284.612 405.5,286C 396.482,286.832 387.482,286.665 378.5,285.5C 373.867,278.4 368.867,271.567 363.5,265C 335.833,264.333 308.167,264.333 280.5,265C 275.133,271.567 270.133,278.4 265.5,285.5C 256.273,286.817 247.107,286.817 238,285.5C 237.48,283.711 237.813,282.044 239,280.5C 261.35,247.123 284.183,214.123 307.5,181.5 Z M 321.5,204.5 C 330.164,215.847 338.498,227.513 346.5,239.5C 330.177,240.666 313.844,240.833 297.5,240C 305.644,228.196 313.644,216.362 321.5,204.5 Z" />
			<path opacity="0.934" fill="#e1201b" d="M 423.5,181.5 C 468.835,181.333 514.168,181.5 559.5,182C 585.665,187.166 595.498,202.666 589,228.5C 585.888,237.114 580.054,242.781 571.5,245.5C 577.768,258.026 584.601,270.359 592,282.5C 592.684,283.784 592.517,284.951 591.5,286C 581.483,286.832 571.483,286.666 561.5,285.5C 554.793,274.386 548.46,263.053 542.5,251.5C 512.174,250.5 481.841,250.167 451.5,250.5C 451.667,262.171 451.5,273.838 451,285.5C 441.583,286.485 432.083,286.818 422.5,286.5C 422.174,251.427 422.507,216.427 423.5,181.5 Z M 451.5,206.5 C 485.502,206.333 519.502,206.5 553.5,207C 555.833,208 558.167,209 560.5,210C 564.5,214 564.5,218 560.5,222C 557.102,223.916 553.435,224.916 549.5,225C 516.835,225.5 484.168,225.667 451.5,225.5C 451.5,219.167 451.5,212.833 451.5,206.5 Z" />
			<path opacity="0.934" fill="#e1201b" d="M 609.5,181.5 C 654.835,181.333 700.168,181.5 745.5,182C 772.963,188.088 782.463,204.588 774,231.5C 769.791,238.043 764.291,243.209 757.5,247C 763.867,258.233 770.367,269.399 777,280.5C 777.495,282.473 777.662,284.473 777.5,286.5C 767.478,286.832 757.478,286.499 747.5,285.5C 741.167,274.167 734.833,262.833 728.5,251.5C 697.841,250.5 667.174,250.167 636.5,250.5C 636.5,262.5 636.5,274.5 636.5,286.5C 627.5,286.5 618.5,286.5 609.5,286.5C 609.5,251.5 609.5,216.5 609.5,181.5 Z M 636.5,206.5 C 670.835,206.333 705.168,206.5 739.5,207C 749.755,209.483 751.755,214.816 745.5,223C 742.899,223.92 740.232,224.587 737.5,225C 703.835,225.5 670.168,225.667 636.5,225.5C 636.5,219.167 636.5,212.833 636.5,206.5 Z" />
			<path opacity="0.931" fill="#e1201b" d="M 864.5,181.5 C 874.506,181.334 884.506,181.5 894.5,182C 917.21,215.254 940.043,248.42 963,281.5C 963.72,283.112 963.554,284.612 962.5,286C 953.482,286.832 944.482,286.665 935.5,285.5C 930.833,278.833 926.167,272.167 921.5,265.5C 893.506,264.333 865.506,264.167 837.5,265C 832.5,272 827.5,279 822.5,286C 813.255,286.817 804.089,286.65 795,285.5C 794.333,284.5 794.333,283.5 795,282.5C 817.997,248.678 841.164,215.011 864.5,181.5 Z M 878.5,204.5 C 887.526,215.504 895.859,227.171 903.5,239.5C 887.177,240.666 870.844,240.833 854.5,240C 863.139,228.541 871.139,216.708 878.5,204.5 Z" />
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 1024,
	height: 347,
};

export default Logo;
