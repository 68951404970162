import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { SaveForm } from '../../../../components/common/SaveForm';
import { showSuccess, showWarning } from '../../../../components/extras/Notifications';
import coursesService from '../../../../services/courses-service';
import schedulesService from '../../../../services/schedules-service';
import { DAYS_OF_WEEK } from '../constants';

type ScheduleFormValues = {
	courseId: string;
	startTime: string;
	endTime: string;
	dayOfWeek: number;
};

interface Props {
	isModalOpened: boolean;
	onModalClose: () => void;
	onAfterSubmit: () => void;
}

export const ScheduleModal = ({ isModalOpened, onModalClose, onAfterSubmit }: Props) => {
	const [courses, setCourses] = useState<ICourse[]>([]);

	useEffect(() => {
		fetchCourses();
	}, []);

	const fetchCourses = async () => {
		const response = await coursesService.getAllCourses();

		if (response.error) {
			showWarning(response.error);
			return;
		}
		setCourses(response.courses || []);
	};

	const onFormValidate = (values: ScheduleFormValues) => {
		const errors: FormikErrors<ScheduleFormValues> = {};

		if (!values.startTime) {
			errors.startTime = 'Campo obrigatório';
		}
		if (!values.endTime) {
			errors.endTime = 'Campo obrigatório';
		}

		return errors;
	};

	const onFormSubmit = async (values: ScheduleFormValues) => {
		// on submit, change dayOfWeek from string to number
        const newSchedule = { ...values };
		newSchedule.dayOfWeek = Number(values.dayOfWeek);

		const response = await schedulesService.addNewSchedule({
			...newSchedule,
		});

		if (response.error) {
			showWarning(response.error);
			return;
		}

		showSuccess('Aula registrada no cronograma com sucesso');

		onAfterSubmit();
	};

	return (
    
		<Modal isOpen={isModalOpened} setIsOpen={onModalClose} isCentered isScrollable isStaticBackdrop>
			<ModalHeader setIsOpen={onModalClose}>
				<ModalTitle id='schedule-modal'>Adicionar Aula</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<SaveForm<ScheduleFormValues>
					initialValues={{
						courseId: '',
						startTime: '',
						endTime: '',
						dayOfWeek: 0,
					}}
					onSubmit={onFormSubmit}
					onValidate={onFormValidate}>
          
					{(form) => (
						<>
							<div className='col-12'>
								<FormGroup label='Curso' isFloating>
									<Select
										id='courseId'
										ariaLabel='Escolha o Curso'
										placeholder='Escolha o Curso'
										onChange={(selectedOption) => {
											form.handleChange(selectedOption);
											if (!selectedOption) {
												form.setFieldValue('courseId', 'selectedOption'); // Set the value to an empty string when clearing the select
											}
										}}
										list={courses.map((course) => ({
											value: course.id,
											text: `${course.name}`,
										}))}
										value={form.values.courseId}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>

								<FormGroup id='startTime' label='Hora de início' isFloating>
									<Input
										type='time'
										name='startTime'
										id='startTime'
										onChange={form.handleChange}
										value={form.values.startTime}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>

								<FormGroup id='endTime' label='Hora de término' isFloating>
									<Input
										type='time'
										name='endTime'
										id='endTime'
										onChange={form.handleChange}
										value={form.values.endTime}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>

								<FormGroup id='dayOfWeek' label='Dia da semana' isFloating>
									<Select
										ariaLabel='Escolha o Dia da Semana'
										placeholder='Escolha o Dia da Semana'
										onChange={form.handleChange}
										value={form.values.dayOfWeek.toString()}
										list={DAYS_OF_WEEK}
									/>
								</FormGroup>
							</div>
						</>
					)}
				</SaveForm>
			</ModalBody>
		</Modal>
	);
};
