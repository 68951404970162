import classNames from 'classnames';
import { useEffect, useState } from 'react';
import COLORS from '../../../common/data/enumColors';
import Avatar from '../../../components/Avatar';
import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '../../../components/PaginationButtons';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner';
import { DELETE_ASYNC, GET_ASYNC, POST_ASYNC } from '../../../components/extras/HttpHelper';
import {
	showCreateSuccess,
	showSuccess,
	showWarning,
} from '../../../components/extras/Notifications';
import Icon from '../../../components/icon/Icon';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import subscriptionService from '../../../services/subscription-service';
import LoadingPage from '../loading/LoadingPage';
import CommonStudent from '../user/common/CommonStudent';
import StudentsPageHeader from './StudentsPageHeader';
import { STUDENTS_FILTER_INITIAL_VALUES } from './consts';
import { FilterFormValues } from './types';

enum EntityStatus {
	ActiveEntity,
	DeletedEntity,
	BlockedEntity,
	InactiveEntity,
}

const StudentsPage = () => {
	const { darkModeStatus } = useDarkMode();
	const [data, setData] = useState<IStudent[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const [filters, setFilters] = useState<FilterFormValues>(STUDENTS_FILTER_INITIAL_VALUES);

	const [subscriptionTypeOffcanvas, setSubscriptionTypeOffcanvas] = useState<boolean>(false);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);
	const [activeSubscriptionTypes, setActiveSubscriptionTypes] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedSubscription, setSelectedSubscription] = useState<ISubscriptionType>();
	const [selectedCognitoId, setSelectedCognitoId] = useState<string>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [studentCreateOffcanvas, setstudentCreateOffcanvas] = useState<boolean>(false);
	const [subscriptionTypes, setSubscriptionTypes] = useState<ISubscriptionType[]>([]);

	useEffect(() => {
		fetchAllSubscriptionTypes();
		getAllStudents();
	}, []);

	const getAllStudents = async () => {
		setIsLoading(true);

		const response = await GET_ASYNC('api/NStudent/GetAll');

		setIsLoading(false);

		if (response && response.success) {
			setData(response.collection);
		} else {
			showWarning(response.message);
		}
	};
	const fetchAllSubscriptionTypes = async () => {
		const { subscriptionTypes, error } = await subscriptionService.getAllSubscriptionTypes();
		if (error) {
			showWarning(error);
			return;
		}

		setSubscriptionTypes(subscriptionTypes || []);
	};

	const onFilterChange = (values: FilterFormValues) => {
		setFilters(values);
	};

	const handleSubscriptionSubmit = async () => {
		const request = {
			startDate: new Date(),
			subscriptionTypeId: selectedSubscription?.id,
			cognitoId: selectedCognitoId,
		};

		if (!request.subscriptionTypeId || !request.cognitoId) {
			showWarning(
				'Falha ao matricular o aluno. Por favor, selecione um tipo de matrícula para matricular o aluno',
			);
			return;
		}

		setIsSubmitting(true);

		const response = await POST_ASYNC('api/NSubscription/Create', request);

		setIsSubmitting(false);

		if (response && response.success) {
			setSubscriptionTypeOffcanvas(false);
			getAllStudents();
			showCreateSuccess();
		} else {
			showWarning(response.message);
		}
	};

	const afterSubmit = (success: boolean) => {
		setstudentCreateOffcanvas(false);
		getAllStudents();
	};

	const getActiveSubscriptionTypes = async () => {
		setIsLoading(true);

		const response = await GET_ASYNC('api/NSubscriptionType/GetActive');

		setIsLoading(false);

		if (response && response.success) {
			setActiveSubscriptionTypes(response.collection);
		} else {
			showWarning(response.message);
		}
	};

	const handleSubscriptionTypes = (cognitoUserId: string) => {
		setSubscriptionTypeOffcanvas(!subscriptionTypeOffcanvas);
		setSelectedSubscription(undefined);
		setSelectedCognitoId(cognitoUserId);

		getActiveSubscriptionTypes();
	};

	const selectSubscription = (subscriptionType: ISubscriptionType) => {
		if (selectedSubscription) {
			selectedSubscription.selected = false;

			if (selectedSubscription.id == subscriptionType.id) {
				setSelectedSubscription(undefined);
				return;
			}
		}

		subscriptionType.selected = true;
		setSelectedSubscription(subscriptionType);
	};

	const handleStudentCreate = () => {
		setstudentCreateOffcanvas(!studentCreateOffcanvas);
	};

	const unsubscribeStudent = async (cognitoUserId: string) => {
		setIsLoading(true);

		const response = await DELETE_ASYNC(`api/NSubscription/DeleteOld/${cognitoUserId}`);

		setIsLoading(false);

		if (response && response.success) {
			showSuccess('Aluno desmatriculado com sucesso');
			getAllStudents();
		} else {
			showWarning(response.message);
		}
	};

	const inactivateStudent = async (cognitoUserId: string) => {
		const request = {
			id: cognitoUserId,
		};

		setIsLoading(true);

		const response = await POST_ASYNC('api/NStudent/Inactivate', request);

		setIsLoading(false);

		if (response && response.success) {
			getAllStudents();
			showSuccess('Aluno inativado com sucesso');
		} else {
			showWarning(response.message);
		}
	};

	const activateStudent = async (cognitoUserId: string) => {
		const request = {
			id: cognitoUserId,
		};

		setIsLoading(true);

		const response = await POST_ASYNC('api/NStudent/Activate', request);

		setIsLoading(false);

		if (response && response.success) {
			getAllStudents();
			showSuccess('Aluno ativado com sucesso');
		} else {
			showWarning(response.message);
		}
	};

	const blockStudent = async (cognitoUserId: string) => {
		const request = {
			id: cognitoUserId,
		};

		setIsLoading(true);

		const response = await POST_ASYNC('api/NStudent/Block', request);

		setIsLoading(false);

		if (response && response.success) {
			getAllStudents();
			showSuccess('Aluno bloqueado com sucesso');
		} else {
			showWarning(response.message);
		}
	};
	const unblockStudent = async (cognitoUserId: string) => {
		const request = {
			id: cognitoUserId,
		};

		setIsLoading(true);

		const response = await POST_ASYNC('api/NStudent/Unblock', request);

		setIsLoading(false);

		if (response && response.success) {
			getAllStudents();
			showSuccess('Aluno desbloqueado com sucesso');
		} else {
			showWarning(response.message);
		}
	};

	const deleteStudent = async (cognitoUserId: string) => {
		const request = {
			id: cognitoUserId
		};

		setIsLoading(true);

		const response = await POST_ASYNC(`api/NStudent/Delete`, request);

		setIsLoading(false);

		if (response && response.success) {
			getAllStudents();
			showSuccess('Aluno removido com sucesso');
		} else {
			showWarning(response.message);
		}
	}

	const filterData = (items: IStudent[]) => {

		if (!filters.nameFilter && !filters.subscriptionFilter && !filters.beltFilter && filters.statusFilter === null)
			return items;

		let filteredItems = [...items];

		if (filters.nameFilter) {
			filteredItems = items.filter((student: IStudent) =>
				student.fullName.toLowerCase().includes(filters.nameFilter.toLowerCase()),
			);
		}
		if (filters.subscriptionFilter) {
			filteredItems = filteredItems.filter(
				(student: IStudent) =>
					student.subscription &&
					student.subscription.subscriptionTypeId === filters.subscriptionFilter,
			);
		}
		if (filters.beltFilter) {
			filteredItems = filteredItems.filter(
				(student: IStudent) => student.belt && student.belt === filters.beltFilter,
			);
		}

		filteredItems = filteredItems.filter(
			(student: IStudent) => student.status === filters.statusFilter,
		);

		return filteredItems;
	}

	const filteredData = filterData(items);

	return (
		<PageWrapper title={'Alunos'}>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-12'>
						{isLoading ? (
							<LoadingPage />
						) : (
							<Card style={{ minHeight: 400 }} stretch>
								<StudentsPageHeader
									subscriptionTypes={subscriptionTypes}
									onFilterChange={onFilterChange}
									onCreateNewStudent={handleStudentCreate}
								/>
								<CardBody isScrollable className='table-responsive'>
									<table className='table table-modern table-hover'>
										<thead>
											<tr>
												<td></td>
												<th
													onClick={() => requestSort('firstName')}
													className='cursor-pointer text-decoration-underline'>
													Nome{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('firstName')}
														icon='FilterList'
													/>
												</th>
												<th>Situação</th>
												<td aria-labelledby='Actions' />
											</tr>
										</thead>
										<tbody>
											{dataPagination(filteredData || [], currentPage, perPage).map(
												(i, index) => (
													<tr key={index}>
														<td style={{ width: 60 }}>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Avatar
																		src={i.profileImageUrl}
																		srcSet={i.profileImageUrl}
																		size={54}
																		userName={i.username}
																		color={COLORS.PRIMARY.name}
																	/>
																</div>
															</div>
														</td>

														<td>{i.fullName}</td>
														<td>
															{i.status ? (
																<h5>
																	<Badge
																		color='success'
																		className='align-items-center justify-content-center'>
																		Ativo
																	</Badge>
																</h5>
															) : (
																<h5>
																	<Badge
																		color='danger'
																		className='align-items-center justify-content-center'>
																		Inativo
																	</Badge>
																</h5>
															)}
															{i.entityStatus === EntityStatus.BlockedEntity && (
																<h5>
																	<Badge
																		color='danger'
																		className='align-items-center justify-content-center'>
																		Bloqueado
																	</Badge>
																</h5>
															)}
															{i.entityStatus === EntityStatus.DeletedEntity && (
																<h5>
																	<Badge
																		color='danger'
																		className='align-items-center justify-content-center'>
																		Deletado
																	</Badge>
																</h5>
															)}
														</td>

														<td className='text-end'>
															<Dropdown>
																<DropdownToggle hasIcon={false}>
																	<Button
																		icon='MoreHoriz'
																		color='dark'
																		isLight
																		shadow='sm'
																		aria-label='More actions'
																	/>
																</DropdownToggle>
																<DropdownMenu isAlignmentEnd>
																	<DropdownItem>
																		<Button
																			isOutline={
																				!darkModeStatus
																			}
																			color='dark'
																			isLight
																			className={classNames(
																				'text-nowrap',
																				{
																					'border-light':
																						!darkModeStatus,
																				},
																			)}
																			icon='Visibility'
																			tag='a'
																			to={`../students/student-detail/detail/${i.cognitoUserId}`}>
																			Detalhes
																		</Button>
																	</DropdownItem>
																	{i.entityStatus !== EntityStatus.BlockedEntity ? (
																		<DropdownItem>
																			<Button
																				isOutline={
																					!darkModeStatus
																				}
																				color='dark'
																				isLight
																				className={classNames(
																					'text-nowrap',
																					{
																						'border-light':
																							!darkModeStatus,
																					},
																				)}
																				icon='Block'
																				tag='a'
																				onClick={() =>
																					blockStudent(
																						i.cognitoUserId,
																					)
																				}>
																				{'Bloquear'}
																			</Button>
																		</DropdownItem>
																	) : (

																		<DropdownItem>
																			<Button
																				isOutline={
																					!darkModeStatus
																				}
																				color='dark'
																				isLight
																				className={classNames(
																					'text-nowrap',
																					{
																						'border-light':
																							!darkModeStatus,
																					},
																				)}
																				icon='Block'
																				tag='a'
																				onClick={() =>
																					activateStudent(
																						i.cognitoUserId,
																					)
																				}>
																				{'Desbloquear'}
																			</Button>
																		</DropdownItem>
																	)}

																	{
																		i.subscription ?
																			(
																				<DropdownItem>
																					<Button
																						isOutline={
																							!darkModeStatus
																						}
																						color='dark'
																						isLight
																						className={classNames(
																							'text-nowrap',
																							{
																								'border-light':
																									!darkModeStatus,
																							},
																						)}
																						icon='Assignment'
																						tag='a'
																						onClick={() =>
																							unsubscribeStudent(
																								i.cognitoUserId,
																							)
																						}>
																						{'Desmatricular'}
																					</Button>
																				</DropdownItem>
																			) : (
																				<DropdownItem>
																					<Button
																						isOutline={
																							!darkModeStatus
																						}
																						color='dark'
																						isLight
																						className={classNames(
																							'text-nowrap',
																							{
																								'border-light':
																									!darkModeStatus,
																							},
																						)}
																						icon='Assignment'
																						tag='a'
																						onClick={() =>
																							handleSubscriptionTypes(
																								i.cognitoUserId,
																							)
																						}>
																						{'Matricular'}
																					</Button>
																				</DropdownItem>
																			)
																	}

																	{i.status ?
																		(
																			<DropdownItem>
																				<Button
																					isOutline={
																						!darkModeStatus
																					}
																					color='dark'
																					isLight
																					className={classNames(
																						'text-nowrap',
																						{
																							'border-light':
																								!darkModeStatus,
																						},
																					)}
																					icon='CheckBox'
																					tag='a'
																					onClick={() =>
																						inactivateStudent(
																							i.cognitoUserId,
																						)
																					}>
																					{'Inativar'}
																				</Button>
																			</DropdownItem>
																		) : (
																			<DropdownItem>
																				<Button
																					isOutline={
																						!darkModeStatus
																					}
																					color='dark'
																					isLight
																					className={classNames(
																						'text-nowrap',
																						{
																							'border-light':
																								!darkModeStatus,
																						},
																					)}
																					icon='CheckBox'
																					tag='a'
																					onClick={() =>
																						activateStudent(
																							i.cognitoUserId,
																						)
																					}>
																					{'Ativar'}
																				</Button>
																			</DropdownItem>
																		)
																	}

																	<DropdownItem>
																		<Button
																			isOutline={
																				!darkModeStatus
																			}
																			color='dark'
																			isLight
																			className={classNames(
																				'text-nowrap',
																				{
																					'border-light':
																						!darkModeStatus,
																				},
																			)}
																			icon='Delete'
																			tag='a'
																			onClick={() =>
																				deleteStudent(
																					i.cognitoUserId,
																				)
																			}>
																			{'Remover'}
																		</Button>
																	</DropdownItem>

																</DropdownMenu>
															</Dropdown>
														</td>
													</tr>
												),
											)}
										</tbody>
									</table>
								</CardBody>
								<PaginationButtons
									data={filteredData || []}
									label='alunos'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						)}
					</div>
				</div>
			</Page>

			<Modal
				setIsOpen={setSubscriptionTypeOffcanvas}
				isOpen={subscriptionTypeOffcanvas}
				titleId='subscriptionTypes'
				isCentered
				isScrollable
				size='lg'
				isStaticBackdrop={true}>
				<ModalHeader setIsOpen={setSubscriptionTypeOffcanvas}>
					<OffCanvasTitle id='subscriptionTypes'>Planos</OffCanvasTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4'>
						{
							activeSubscriptionTypes.map((subscriptionType: ISubscriptionType) => (

								<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' key={subscriptionType.id}>
									<Card
										className={subscriptionType.selected ? 'bg-l25-success' : ''}
									>
										<CardHeader className='bg-transparent'>
											<CardLabel>
												<CardTitle tag='div' className='h3 text-break'>
													{subscriptionType.description}
												</CardTitle>
											</CardLabel>
										</CardHeader>


										<CardBody>

											<div className='d-flex align-items-center pb-3'>
												<div className='flex-shrink-0'>
													<Icon icon='SportsKabaddi' size='4x' color='primary' />
												</div>
												<div className='flex-grow-1 ms-3 display-6 fw-bold text-break'>
													{priceFormat(subscriptionType.fee ?? 0)}
												</div>
											</div>
										</CardBody>
										<CardFooter className='bg-transparent'>
											<CardFooterRight>
													<Button
														color='primary'
														//isLight
														onClick={() =>
															selectSubscription(subscriptionType)
														}>
														{subscriptionType.selected
															? 'Desmatricular'
															: 'Matricular'}
													</Button>
											</CardFooterRight>
										</CardFooter>
									</Card>
								</div>
							))}
					</div>
				</ModalBody>

				<ModalFooter className='bg-transparent'>
					<Button
						color='primary'
						className='w-100'
						onClick={handleSubscriptionSubmit}
						isDisable={isSubmitting}>
						{isSubmitting && <Spinner isSmall inButton isGrow />}
						Salvar
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				tag='form'
				setIsOpen={setstudentCreateOffcanvas}
				isOpen={studentCreateOffcanvas}
				titleId='studentDetailEdit'
				isCentered
				size='xl'
				isStaticBackdrop={true}>
				<ModalHeader setIsOpen={setstudentCreateOffcanvas}>
					<OffCanvasTitle id='studentDetailEdit'>Adicionar Aluno</OffCanvasTitle>
				</ModalHeader>

				<ModalBody>
					<CommonStudent afterSubmit={afterSubmit} />
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default StudentsPage;
