export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Início',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	loginv2: {
		id: 'loginV2',
		text: 'LoginV2',
		path: 'auth-pages/loginv2',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};


export const userMenu = {
	schedule: {
		id: 'schedule',
		text: 'Cronograma',
		icon: 'Class',
		path: 'schedule/class-schedule',
	}
	 ,
	 payments: {
	 	id: 'payments',
	 	text: 'Pagamento de Mensalidade',
	 	icon: 'Payments',
	 	path: 'payments',
	 },
};

export const appMenu = {
	registers: {
		id: 'registers',
		text: 'Cadastros',
		path: 'registers',
		icon: 'LibraryAdd',
		subMenu: {
			// leadsAndProspects: {
			// 	id: 'leadsAndProspects',
			// 	text: 'Leads/Prospecção',
			// 	path: 'registers/leadsAndProspects',
			// 	icon: 'PersonAdd',
			// },
			// students: {
			// 	id: 'students',
			// 	text: 'Alunos',
			// 	path: 'registers/students',
			// 	icon: 'EmojiPeople',
			// },
			// classes: {
			// 	id: 'classes',
			// 	text: 'Aulas',
			// 	path: 'registers/classes',
			// 	icon: 'Class',
			// },
			classCheckin: {
				id: 'classCheckin',
				text: 'Check-in Classe',
				path: 'attendance/class-checkin',
				icon: 'FactCheck',
			},
			// classCheckinList:
			// {
			// 	id: 'classCheckinList',
			// 	text: 'Lista de Check-in',
			// 	path: 'attendance/classCheckinList',
			// 	icon: 'FactCheck',
			// },
			schedule: {
				id: 'schedule',
				text: 'Cronograma',
				path: 'registers/schedule',
				icon: 'CalendarToday',
			},
			// message: {
			// 	id: 'message',
			// 	text: 'Mensagem',
			// 	path: 'registers/message',
			// 	icon: 'Message',
			// },
			subscriptions: {
				id: 'subscriptions',
				text: 'Planos',
				path: 'registers/subscriptions',
				icon: 'LocalOffer',
			},
			courses: {
				id: 'courses',
				text: 'Aulas',
				path: 'registers/courses',
				icon: 'Class',
			},
			students: {
				id: 'students',
				text: 'Alunos',
				path: 'student/students',
				icon: 'EmojiPeople',
			}
			// ,
			// instructors: {
			// 	id: 'instructors',
			// 	text: 'Professores',
			// 	path: 'instructor/instructors',
			// 	icon: 'PeopleAlt',
			// },
		},
	},

	management: {
		id: 'management',
		text: 'Gestão',
		path: 'management',
		icon: 'Settings',
		subMenu: {
			attendanceMaintenance: {
				id: 'attendanceMaintenance',
				text: 'Check-in',
				path: 'attendance/maintenance',
				icon: 'TouchApp',
			},
			paymentMaintenance: {
				id: 'paymentMaintenance',
				text: 'Aprovação de Pagamento',
				path: 'payment/maintenance',
				icon: 'Payments',
			},
			// classEvaluation: {
			// 	id: 'classEvaluation',
			// 	text: 'Avaliações de Aulas',
			// 	path: 'management/classEvaluation',
			// 	icon: 'RateReview',
			// },
			// message: {
			// 	id: 'message',
			// 	text: 'Mensagem',
			// 	path: 'management/message',
			// 	icon: 'Message',
			// },
			// digitalMedia: {
			// 	id: 'digitalMedia',
			// 	text: 'Midia Digital',
			// 	path: 'management/digitalMedia',
			// 	icon: 'PermMedia',
			// },
		},
	},

	financialControl: {
		id: 'financialControl',
		text: 'Financeiro',
		path: 'financialControl',
		icon: 'AttachMoney',
		subMenu: {
			monthlyPayments: {
				id: 'monthlyPayments',
				text: 'Mensalidades',
				path: 'financial/financial-page',
				icon: 'Payments',
			},
			cashFlowAccountGroup: {
				id: 'cashFlowAccountGroupPage',
				text: 'Grupo de Contas',
				path: 'financial/cash-flow-account-group-page',
				icon: 'Workspaces',
			},
			cashFlowAccount: {
				id: 'cashFlowAccountPage',
				text: 'Contas',
				path: 'financial/cash-flow-account-page',
				icon: 'Analytics',
			},
			cashFlow: {
				id: 'cashFlowEntries',
				text: 'Fluxo de Caixa',
				path: 'financial/cash-flow-page',
				icon: 'MonetizationOn',
			},
			paymentType: {
				id: 'paymentType',
				text: 'Forma de Pagamento',
				path: 'financial/payment-type',
				icon: 'AccountBalanceWallet',
			},
			// billingPeriod: {
			// 	id: 'billingPeriod',
			// 	text: 'Período de Faturamento',
			// 	path: 'financial/billing-period',
			// 	icon: 'GridView',
			// },
		},
	},

	reports: {
		id: 'reports',
		text: 'Relatórios',
		path: 'reports',
		icon: 'Description',
		subMenu: {
			upgcomingGraduations: {
				id: 'upgcomingGraduations',
				text: 'Prox. Graduações',
				path: 'reports/graduations',
				icon: 'School',
			},
			birthdays: {
				id: 'birthdays',
				text: 'Aniversáriantes',
				path: 'reports/birthdays',
				icon: 'Cake',
			},
			studentHistory: {
				id: 'studentHistory',
				text: 'Histórico de Alunos',
				path: 'reports/student-history',
				icon: 'History',
			},
			frequency: {
				id: 'frequency',
				text: 'Frequência',
				path: 'reports/frequency',
				icon: 'AccessTime',
			},
			// graduations: {
			// 	id: 'graduations',
			// 	text: 'Graduações',
			// 	path: 'reports/graduations',
			// 	icon: 'School',
			// },
			absences: {
				id: 'absences',
				text: 'Ausencias',
				path: 'reports/absences',
				icon: 'ReportProblem',
			},
			noSubscription: {
				id: 'noSubscription',
				text: 'Sem Matrícula',
				path: 'reports/no-subscription',
				icon: 'LocalOffer',
			},
			// teachers: {
			// 	id: 'teachers',
			// 	text: 'Professores',
			// 	path: 'reports/teachers',
			// 	icon: 'PeopleAlt',
			// },
			cashFlowEntries: {
				id: 'cashFlowEntries',
				text: 'Fluxo de Caixa',
				path: 'reports/cash-flow-entries',
				icon: 'MonetizationOn',
			},
			// leadsAndProspects: {
			// 	id: 'leadsAndProspects',
			// 	text: 'Leads/Prospecção',
			// 	path: 'reports/leadsAndProspects',
			// 	icon: 'PersonAdd',
			// },
		},
	},

	settings: {
		id: 'settings',
		text: 'Configurações',
		path: 'settings',
		icon: 'Settings',
		subMenu: {
			addon: {
				id: 'addon',
				text: 'Extensão',
				path: 'settings/addon',
				icon: 'Extension',
			},
			settings: {
				id: 'settings',
				text: 'Sistema',
				path: 'settings/system',
				icon: 'SettingsApplications',
			}
		},
	}
};

// 		subscriptionTypes: {
// 			id: 'subscriptionTypes',
// 			text: 'Tipos de Matrícula',
// 			path: 'subscription/subscriptionTypePage',
// 			icon: 'Article',
// 		},
// 		student: {
// 			id: 'user',
// 			text: 'Aluno',
// 			path: 'user/studentPage',
// 			icon: 'PeopleAlt',
// 		},
// 		classCheckin: {
// 			id: 'classCheckin',
// 			text: 'Check-in Classe',
// 			path: 'attendance/classCheckin',
// 			icon: 'FactCheck',
// 		},
// 		classCheckinList:
// 		{
// 			id: 'classCheckinList',
// 			text: 'Lista de Check-in',
// 			path: 'attendance/classCheckinList',
// 			icon: 'FactCheck',
// 		}
// 	}
// },

export const hiddenMenu = {
	financial: {
		id: 'financial',
		text: 'Financeiro',
		path: 'financial/customer/:id',
		icon: 'MonetizationOn',
	},
	financialId: {
		id: 'financial',
		text: 'Financeiro',
		path: 'financial/customer/',
		icon: 'MonetizationOn',
	},
	students: {
		id: 'students',
		text: 'Students',
		path: 'students/student-detail/:action/:id',
		icon: 'EmojiPeople',
	},
	instructors: {
		id: 'instructors',
		text: 'Professores',
		path: 'instructors/instructor-detail/:action/:id',
		icon: 'EmojiPeople',
	},
	profile: {
		id: 'user',
		text: 'Aluno',
		path: 'students/student-detail',
		icon: 'PeopleAlt',
	},
	classCheckinDetail: {
		id: 'classCheckinDetail',
		text: 'Detalhes das Imagens',
		path: 'attendance/class-checkin-detail/:id',
		icon: 'FactCheck',
	},
	facialCheckin: {
		id: 'facialCheckin',
		text: 'Check-in Facial',
		path: 'attendance/facial-checkin',
		icon: 'Face',
	},
	payment: {
		id: 'payment',
		text: 'Pagamento de Mensalidade',
		path: 'payments/payment',
		icon: 'Payments',
	},
};

export const authMenu = {
	loginV2: {
		id: 'loginV2',
		text: 'LoginV2',
		path: 'auth/loginv2',
		icon: 'Login',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth/login',
		icon: 'Login',
	},
};
