import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer, View as TView, Views } from 'react-big-calendar';

import React from 'react';
import { getMonthByDigit } from '../../../common/data/calendar';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle
} from '../../../components/bootstrap/Card';
import {
	CalendarTodayButton,
	getUnitType
} from '../../../components/extras/calendarHelper';
import { GET_ASYNC } from '../../../components/extras/HttpHelper';
import { showWarning } from '../../../components/extras/Notifications';
import AuthContext from '../../../contexts/authContext';
import CommonAttendanceBelt from '../attendance/common/CommonAttendanceBelt';

interface CommonCalendarPageProps {
	studentCognitoId?: string;
	grade?: string;
	graduation?: string;
}

const CalendarPage: FC<CommonCalendarPageProps> = ({ studentCognitoId, graduation, grade }) => {

	const { userData, cognitoId } = useContext(AuthContext);
	const [viewMode] = useState<TView>(Views.MONTH);
	const [date, setDate] = useState(new Date());
	const [attendances, setAttendances] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentDateText, setCurrentDateText] = useState<string>('');

	
	const localizer = momentLocalizer(moment.tz.setDefault("America/Sao_Paulo"));
	const unitType = getUnitType(viewMode);

	useEffect(() => {
	}, [userData, cognitoId]);

	useEffect(() => {
		getUserAttendancesByDate(date);
	}, [date]);

	const getUserAttendancesByDate = async (selectedDate: Date) => {
		let selectedCognitoId = studentCognitoId ? studentCognitoId : cognitoId;

		const month = selectedDate.getMonth() + 1;
		const year = selectedDate.getFullYear();
		const monthText = getMonthByDigit(month);

		if (!cognitoId || cognitoId === '') {
			setCurrentDateText(`${monthText} - ${year}`);
			setAttendances([]);

			return;
		}

		setIsLoading(true);

		const response = await GET_ASYNC(`api/NAttendance/GetUserAttendancesByDate/${month}/${year}/${selectedCognitoId}`);
		setIsLoading(false);

		if (response && response.success) {
			setCurrentDateText(`${monthText} - ${year}`);
			setAttendances(response.collection);
		} else {
			showWarning(response.message);
		}
	}

	const EventWrapperComponent = () => {
		if (studentCognitoId)
			return React.createElement(CommonAttendanceBelt, { userBelt: graduation, userGrade: grade });
		else
			return React.createElement(CommonAttendanceBelt, { userBelt: userData.graduation, userGrade: userData.grade });
	};

	return (

		<Card borderSize={1} stretch style={{ minHeight: 700 }}>
			<CardHeader>
				<CardLabel icon='CalendarToday' iconColor='primary'>
					<CardTitle tag='div' className='h5'>
						Minhas Presenças
					</CardTitle>
				</CardLabel>

				<CardActions>

					<Button
						color='primary'
						isLink
						tag='a'>
						{currentDateText}
					</Button>

					<CalendarTodayButton
						unitType={unitType}
						date={date}
						setDate={setDate}
						viewMode={viewMode}
						previousClickAction={(previousDate: Date) => getUserAttendancesByDate(previousDate)}
						currentClickAction={(currentDate: Date) => getUserAttendancesByDate(currentDate)}
						nextClickAction={(nextDate: Date) => getUserAttendancesByDate(nextDate)}
					/>
				</CardActions>
			</CardHeader>
			<CardBody isScrollable>
				<Calendar
					style={{ height: '95%' }}
					toolbar={false}
					localizer={localizer}
					events={attendances}
					onNavigate={(_date) => setDate(_date)}
					showMultiDayTimes={false}
					defaultView={Views.MONTH}
					date={date}
					culture="pt"
					scrollToTime={new Date(1970, 1, 1, 6)}
					defaultDate={new Date()}
					components={{
						//add parameter inside CommonAttendanceBelt
						eventWrapper: EventWrapperComponent,
					}}
				/>
			</CardBody>
		</Card>

	);
};

export default CalendarPage;
