export const PER_COUNT = {
	3: 3,
	5: 5,
	10: 10,
	12: 12,
	25: 25,
	50: 50,
};

export const DEFAULT_PAGE_COUNT = 10;

export const INITIAL_PAGE = 1;

export const dataPagination = (data: any[], currentPage: number, perPage: number) =>
	data.filter(
		(i, index) => index + 1 > (currentPage - 1) * perPage && index + 1 <= currentPage * perPage,
	);
