import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FC, useCallback, useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GracieBarra from '../../../assets/logos/gbbatatais.png';
import { getDecodedToken } from '../../../common/data/jwtPayload';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import Toasts from '../../../components/bootstrap/Toasts';
import { POST_ASYNC } from '../../../components/extras/HttpHelper';
import { showWarning } from '../../../components/extras/Notifications';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { TColor } from '../../../type/color-type';
import SignInModal from './SignInModal';

interface ILoginProps {
    isSignUp?: boolean;
}

const LoginV2: FC<ILoginProps> = ({ isSignUp }) => {

    const { setCognitoId } = useContext(AuthContext);

    const { darkModeStatus } = useDarkMode();
    const [showAlertError, setshowAlertError] = useState(false);
    const [authorizedWebCam, setAuthorizedWebCam] = useState(false);
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [color, setColor] = useState<TColor>('primary');
    const [showAlertMessage, setshowAlertMessage] = useState("Erro ao registrar checkin");
    const videoRef = useRef<HTMLVideoElement>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [image, setImage] = useState<Blob | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/'), [navigate]);

    const startCamera = async () => {
        try {           
            if (stream) {
                let tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                setStream(mediaStream);
                await videoRef.current.play();
            }
        } catch (error) {
            setAuthorizedWebCam(false);
            setIsLoading(false);
            showWarning('Falha ao iniciar a câmera');
        }
    };

    const stopCamera = () => {
        if (stream) {
            let tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            setStream(null);
            if (videoRef.current)
                videoRef.current.srcObject = null;
        }
    }

    const authorizeWebCam = async () => {
        setIsLoading(true);

        setAuthorizedWebCam(true);
        startCamera();
        setIsLoading(false);
        // Cleanup function to stop the media stream
        return () => {
            if (stream) {
                let tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }

            setIsLoading(false);
        };
    };

    const handleCaptureAndSend = async () => {

        try {
            setIsLoading(true);

            if (videoRef.current) {
                videoRef.current.pause();
                const capturedImage = await captureImage();
                setImage(capturedImage);

                if (capturedImage) {
                    await sendImageToApi(capturedImage);
                }
            }
            setIsLoading(false);
        } catch (error) {
            videoRef.current?.play();
            setIsLoading(false);

        }
    };

    const captureImage = async (): Promise<Blob | null> => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                return new Promise((resolve) => {
                    canvas.toBlob(resolve, 'image/jpeg');
                });
            }
        }
        return null;
    };

    const sendImageToApi = async (imageBlob: Blob) => {

        try {
            const request = new FormData();
            request.append('image', imageBlob, 'captured-image.jpg');

            const response = await POST_ASYNC('api/NUser/CheckUserFace', request);
            if (response && response.success) {
                if (response.collection.length > 0) {

                    const request = {
                        externalImageId: response.collection[0].externalImageId
                    }

                    const responseLogin = await POST_ASYNC('api/NAuth/FaceLogin', request);
                    if (responseLogin && responseLogin.success && responseLogin.entity) {
                        if (setCognitoId) {
                            sessionStorage.setItem('gb_accesstoken', responseLogin.entity.authenticationResult.idToken);
                            localStorage.setItem('gb_accesstoken', responseLogin.entity.authenticationResult.idToken);
                            sessionStorage.setItem('jwt_accesstoken', responseLogin.entity.authenticationResult.accessToken);
                            localStorage.setItem('jwt_accesstoken', responseLogin.entity.authenticationResult.accessToken);
                            const decodedToken = getDecodedToken();
                            setCognitoId(decodedToken.sub);
                            stopCamera();
                        }
                        handleOnClick();
                    } else {
                        showWarning(responseLogin.message);
                    }
                } else {
                    setModalStatus(true);
                }
            } else {
                showWarning(response.message);
            }
        } catch (error) {
            setshowAlertError(true);
            setshowAlertMessage('Error sending image.');
            setColor('primary');
            toast(<Toasts title={"Erro"} icon='Verified' iconColor={color}>
                {showAlertMessage}
            </Toasts>);
        }
    }

    return (

        <PageWrapper
            isProtected={false}
            title={'Acesso'}
            className={'page-wrapper-background'}
        >
            <Page className='p-0'>

                <div className='row h-100 align-items-center justify-content-center'>

                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-primary'>
                            <CardBody>
                                <div className='text-center my-5'>
                                    <Link
                                        to='/'
                                        className={classNames(
                                            'text-decoration-none  fw-bold display-2',
                                            {
                                                'text-dark': !darkModeStatus,
                                                'text-light': darkModeStatus,
                                            },
                                        )}
                                        aria-label='Gracie Barra'>
                                        <img src={GracieBarra} alt='Gracie Barra' width={200} />
                                    </Link>
                                </div>
                                <div>
                                    <div>
                                        <video className='w-100' ref={videoRef} playsInline />
                                    </div>
                                </div>
                                {/* <div className='text-center h3 fw-bold mt-5'>Login Facial</div> */}

                                <form className='row g-4 mt-5'>
                                    {!authorizedWebCam ? (
                                        <>
                                            <div className='col-12'>
                                                <Button
                                                    color='primary'
                                                    className='w-100 py-3'
                                                    onClick={() => { authorizeWebCam() }}>
                                                    {isLoading && (
                                                        <Spinner isSmall inButton isGrow />
                                                    )}
                                                    Autorizar Câmera
                                                </Button>
                                            </div>
                                        </>) : (
                                        <>
                                            <div className='col-12'>
                                                <Button
                                                    color='primary'
                                                    className='w-100 py-3'
                                                    onClick={async () => { await handleCaptureAndSend() }}
                                                    isDisable={isLoading}>
                                                    {isLoading && (
                                                        <Spinner isSmall inButton isGrow />
                                                    )}
                                                    Entrar
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>

            <SignInModal image={image as Blob} setIsOpen={setModalStatus} isOpen={modalStatus} />

        </PageWrapper>
    );
};

LoginV2.propTypes = {
    isSignUp: PropTypes.bool,
};

LoginV2.defaultProps = {
    isSignUp: false,
};

export default LoginV2;
