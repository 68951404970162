import { GET_ASYNC, POST_TYPED_ASYNC } from '../components/extras/HttpHelper';

export interface GetByIdResponse {
	success: boolean;
	student?: IStudent;
	error?: string;
}

interface IPromoteUserRequest {
	username?: string;
	role?: string;
}

export interface IPromoteUserResponse {
	statusCode?: number;
	success: boolean;
	error?: string;
}

const getById = async (cognitoId: string): Promise<GetByIdResponse> => {
	try {
		const response = await GET_ASYNC(`api/NStudent/GetByCognitoId/${cognitoId}`);

		if (response && response.success) {
			return { success: response.success, student: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getPaymentsUnderReviewById = async (cognitoId: string): Promise<GetByIdResponse> => {
	try {
		const response = await GET_ASYNC(`api/NStudent/GetPaymentsUnderReviewByCognitoId/${cognitoId}`);

		if (response && response.success) {
			return { success: response.success, student: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const promoteUser = async (request: IPromoteUserRequest): Promise<IPromoteUserResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IPromoteUserResponse>(`api/NUser/Promote`, request);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	getById,
	getPaymentsUnderReviewById,
	promoteUser
};
