import {
	DELETE_TYPED_ASYNC,
	GET_TYPED_ASYNC,
	POST_TYPED_ASYNC
} from '../components/extras/HttpHelper';

const BASE_PATH = 'api/Schedule';

interface ScheduleResponse {
	success: boolean;
	schedule?: ISchedule[];
	error?: string;
}

interface ListAllSchedulesResponse {
	schedule?: IParseSchedule[];
	error?: string;
}

const listAllSchedules = async (): Promise<ListAllSchedulesResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IParseSchedule>(`${BASE_PATH}/ListAll`);
		if (!response.success) {
			return { error: response.message || response.exception }
		}

		return { schedule: response.collection };
	} catch (err: any) {
		return { error: err.message };
	}
};


type NewScheduleType = Omit<ISchedule, 'id'>;

const addNewSchedule = async (newSchedule: NewScheduleType): Promise<ScheduleResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<ISchedule>(`${BASE_PATH}/Create`, newSchedule);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const deleteSchedule = async (id: string): Promise<ScheduleResponse> => {
	try {
		const response = await DELETE_TYPED_ASYNC<ISchedule>(`${BASE_PATH}/Delete/${id}`);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	addNewSchedule,
	listAllSchedules,
	deleteSchedule
};
