import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import COLORS from '../../../../common/data/enumColors';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import { showSuccess, showWarning } from '../../../../components/extras/Notifications';
import useDarkMode from '../../../../hooks/useDarkMode';
import financialService from '../../../../services/financial-service';
import { FILTER_FORM_INITIAL_VALUES } from './CommonStudent.const';
import { IPaidStudentsProps, PaidStudentsFilterFormValues } from './CommonStudents.types';
import { CommonStudentsFilter } from './CommonStudentsFilter';

const PaidStudents: FC<IPaidStudentsProps> = ({ paidStudents, onUpdate }) => {
	const { darkModeStatus } = useDarkMode();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [filterValues, setFilterValues] = useState<PaidStudentsFilterFormValues>(
		FILTER_FORM_INITIAL_VALUES,
	);

	const filterData = () => {
		if (!filterValues.nameFilter) return paidStudents;

		return paidStudents.filter((item: IStudent) => {
			return item.fullName
				.toLowerCase()
				.includes(filterValues.nameFilter.toLocaleLowerCase());
		});
	};

	const reversePayment = async (payments: IPayment[]) => {
		
		const currentMonth = dayjs().month() + 1;
		const currentYear = dayjs().year();

		const filteredPayments = payments.filter((payment) => {
			const paymentDate = dayjs(payment.paymentDate);
			return paymentDate.month() + 1 === currentMonth && paymentDate.year() === currentYear;
		});

		if (!filteredPayments || filteredPayments.length <= 0) {
			showWarning('Pagamento de mensalidade atual não encontrado');
			return;
		}

		const currentPayment = filteredPayments[0];

		if (!currentPayment.cashFlowEntryId) {
			showWarning('Mensalidade não possui vínculo com entrada do fluxo de caixa');
			return;
		}

		setIsSubmitting(true);

		const response = await financialService.deleteStudentPayment(currentPayment.id);

		setIsSubmitting(false);

		if (response && response.success) {
			showSuccess('Mensalidade estornada com sucesso');
			onUpdate();
		} else {
			showWarning(response.error || 'Falha ao registrar pagamento de mensalidade do aluno');
		}
	};

	const onFilterChange = (values: PaidStudentsFilterFormValues) => {
		setFilterValues(values);
	};

	const filteredData = filterData();

	return (
		<>
			<Card stretch>
				<CardHeader>
					<CardLabel icon='AttachMoney' iconColor='success'>
						<CardTitle tag='div' className='h5'>
							Mensalidades Pagas
						</CardTitle>
					</CardLabel>

					<CardActions>
						<CommonStudentsFilter onFilterChange={onFilterChange} />
					</CardActions>
				</CardHeader>
				<CardBody isScrollable style={{ minHeight: 400 }}>
					<div className='row g-3'>
						{filteredData.map((p) => (
							<div key={p.id} className='col-12'>
								<div className='row g-2'>
									<div className='col d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={p.profileImageUrl}
												srcSet={p.profileImageUrl}
												size={50}
												userName={p.username}
												color={COLORS.PRIMARY.name}
											/>
										</div>
										<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
											<div className='fw-bold'>
												{p.firstName + ' ' + p.lastName}
											</div>
										</div>

										<div className='flex-shrink-0'>
											<div className='col-auto'>
												<div
													style={{ width: 100 }}
													className={classNames(
														`bg-l${darkModeStatus ? 'o25' : '10'}-success text-success fw-bold py-2 rounded-pill me-2 text-center`,
													)}>
													{'Pago'}
												</div>
											</div>
										</div>

										<div className='flex-shrink-0'>
											<div className='col-auto text-end'>
												<Dropdown>
													<DropdownToggle hasIcon={false}>
														<Button
															icon='MoreHoriz'
															color='dark'
															isLight
															shadow='sm'
															aria-label='Mais Ações'
														/>
													</DropdownToggle>
													<DropdownMenu isAlignmentEnd>
														<DropdownItem>
															<Button
																isOutline={!darkModeStatus}
																color='dark'
																isLight
																className={classNames(
																	'text-nowrap',
																	{
																		'border-light':
																			!darkModeStatus,
																	},
																)}
																icon='Cancel'
																onClick={() =>
																	reversePayment(p.payments || [])
																}>
																Estornar
															</Button>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</CardBody>
			</Card>
		</>
	);
};

export default PaidStudents;
