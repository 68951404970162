import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Badge from "../../../../components/bootstrap/Badge";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

const CommonStudentActivityList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);

    const [studentActivities, setStudentActivities] = useState<IStudentActivity[]>([]);

    const headers = [
        { label: 'Nome', key: 'name' },
        { label: 'Data da Matrícula', key: 'subscriptionDateView' },
        { label: 'Matrícula', key: 'subscription' },
        { label: 'Valor', key: 'feeView' },
        { label: 'Situação', key: 'statusView' }        
    ];

    useEffect(() => {
        getStudentActivites();

    }, []);

    const getStudentActivites = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetStudentActivity`);

        setIsLoading(false);

        if(response && response.success) {

            if (response.collection) {
                const studentActivities = response.collection.map((studentActivity: IStudentActivity) => ({
                    ...studentActivity,
                    subscriptionDateView: studentActivity.subscriptionDate ? dayjs(studentActivity.subscriptionDate).format('DD/MM/YYYY') : '',
                    feeView: studentActivity.fee ? priceFormat(studentActivity.fee) : '',
                    statusView: studentActivity.status ? 'ativo' : 'inativo'
                }));

                setStudentActivities(studentActivities);
            } else {
                setStudentActivities(response.collection);
            }
        } else {
            showWarning(response.message);
        }
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='History' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Histórico de Alunos
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>

                                <ExportButton headers={headers} data={studentActivities} filename="historico-alunos" />
                                    
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Data da Matrícula</th>
                                            <th>Matrícula</th>
                                            <th>Valor</th>
                                            <th>Situação</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(studentActivities, currentPage, perPage).map((item) => (
                                            <tr key={item.id}>

                                                <td>{item.name}</td>
                                                <td>{item.subscriptionDate == null ? '' : dayjs(item?.subscriptionDate).format('DD/MM/YYYY')}</td>
                                                <td>{item.subscription}</td>
                                                <td>{priceFormat(item?.fee || 0)}</td>

                                                <td>
                                                    {item.status ? (
                                                        <h5>
                                                            <Badge color='success' className='align-items-center justify-content-center'>
                                                                Ativo
                                                            </Badge>
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            <Badge color='danger' className='align-items-center justify-content-center'>
                                                                Inativo
                                                            </Badge>
                                                        </h5>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={studentActivities}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonStudentActivityList;