import dayjs from 'dayjs';
import Card, { CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import { CardBodyTable } from '../../../components/table/CardBodyTable';
import { priceFormat } from '../../../helpers/helpers';
import useSortableData from '../../../hooks/useSortableData';

interface Props {
	payments: IPayment[];
	isCompact?: boolean;
	cardActions?: React.ReactNode;
}

const SubscriptionMonthlyHistory = ({ payments, isCompact = true, cardActions = null }: Props) => {
	const { items: sortablePayments, requestSort, getClassNamesFor } = useSortableData(payments);

	return (
		<Card
			{...(isCompact && { isCompact: true, className: 'mb-0' })}
			{...(!isCompact && { borderSize: 1 })}>
			<CardHeader {...(!isCompact && { borderSize: 1, className: 'mb-1' })}>
				<CardLabel icon='Receipt'>
					<CardTitle tag='div' className='h5'>
						Histórico de Mensalidades
					</CardTitle>
				</CardLabel>
				{cardActions && cardActions}
			</CardHeader>
			<CardBodyTable
				tableClassNames='table-hover'
				data={sortablePayments}
				head={
					<tr>
						<th
							onClick={() => requestSort('paymentDate')}
							className='cursor-pointer text-decoration-underline'>
							Data{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('paymentDate')}
								icon='FilterList'
							/>
						</th>
						<th
							onClick={() => requestSort('amount')}
							className='cursor-pointer text-decoration-underline'>
							Valor{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('amount')}
								icon='FilterList'
							/>
						</th>
					</tr>
				}
				body={(item, index) => (
					<tr key={index}>
						<td>{dayjs(item.paymentDate).format('DD/MM/YYYY')}</td>
						<td>{priceFormat(item.amount)}</td>
					</tr>
				)}
			/>
		</Card>
	);
};

export default SubscriptionMonthlyHistory;
