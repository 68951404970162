import { useContext } from "react";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import AuthContext from "../../../../contexts/authContext";

interface Props {
	selectedSchedule: IParseSchedule | null;
	isOpen: boolean;
	onDetailToggle: (toggle: boolean) => void;
	onScheduleCourseDelete: (id?: string) => void;
}

export const ScheduleDetail = ({ selectedSchedule, isOpen, onDetailToggle, onScheduleCourseDelete }: Props) => {
	if (!selectedSchedule) return null;

	const { userData } = useContext(AuthContext);

	return (
		<OffCanvas
			setOpen={onDetailToggle}
			isOpen={isOpen}
			titleId='canvas-title'
			placement='end'
			isBodyScroll={false}
		>
			<OffCanvasHeader
				setOpen={onDetailToggle}
				className='p-4'>
				<OffCanvasTitle id='canvas-title'>{'Informações da Aula'}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody tag='form' className='p-4'>
				<Card>
					<CardHeader>
						<CardLabel>
							<CardTitle>{selectedSchedule.course.name}</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup label='Hora de Início' id='start'>
									<Input
										type='text'
										value={new Date(selectedSchedule.start).toLocaleDateString('pt-BR', {
											hour: '2-digit',
											minute: '2-digit',
										})}
										readOnly
									/>
								</FormGroup>
							</div>
							{/* End */}
							<div className='col-12'>
								<FormGroup label='Hora de Término'>
									<Input
										type='text'
										value={new Date(selectedSchedule.end).toLocaleDateString('pt-BR', {
											hour: '2-digit',
											minute: '2-digit',
										})}
										readOnly
									/>
								</FormGroup>
							</div>
							{/* Course */}
							<div className='col-12'>
								<FormGroup label='Curso'>
									<div className='row'>
										<div className='col-8'>
											<Input
												type='text'
												value={selectedSchedule.course.name}
												readOnly
											/>
										</div>
										<div className='col-4'>
											<Input
												type='color'
												value={selectedSchedule.course.color}
												disabled
											/>
										</div>
									</div>
								</FormGroup>
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<div className="row g-4">

								{
									userData.isAdmin ? (

										<div className='col-12'>
											<Button
											icon="Delete"
												color='danger'
												onClick={() => onScheduleCourseDelete(selectedSchedule.id)}>
												Remover
											</Button>
										</div>

									) : (

										<div className='col-12'>
											<Button
											icon="Close"
												color='danger'
												onClick={() => onDetailToggle(false)}>
												Fechar
											</Button>
										</div>

									)
								}
							</div>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</OffCanvasBody>
		</OffCanvas>
	);
};