import { useContext, useEffect, useState } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { appMenu, dashboardPagesMenu, userMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { getDecodedToken } from '../../../common/data/jwtPayload';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [role, setRole] = useState<boolean>();

	const userData = getDecodedToken();

	useEffect(() => {
		setRole(userData['cognito:groups']?.includes("Professores" || "Administradores"));
	}, [userData]);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				{<NavigationLine />}
				{<Navigation menu={userMenu} id='user-menu' />}
				{<NavigationLine />}
				{
					role &&
					<NavigationLine /> &&
					<Navigation menu={appMenu} id='app-menu' />
				}

			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
