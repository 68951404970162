import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { ICashFlowAccount } from "../../../../common/data/financial/cashFlowAccount";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import Spinner from "../../../../components/bootstrap/Spinner";
import { showError, showSuccess, showWarning } from "../../../../components/extras/Notifications";
import Icon from "../../../../components/icon/Icon";
import cashFlowAccountService, { IGetCashFlowAccountResponse } from "../../../../services/cash-flow-account-service";
import cepService from "../../../../services/cep-service";
import paymentTypeService, { IGetPaymentTypesResponse } from "../../../../services/payment-type-service";
import systemConfigurationService, { IGetConfigurationResponse } from "../../../../services/system-configuration-service";
import LoadingPage from "../../loading/LoadingPage";

const CommonSettings: FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
    const [cashFlowAccounts, setCashFlowAccounts] = useState<ICashFlowAccount[]>([]);
    const [configuration, setConfiguration] = useState<IConfiguration>();

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            instagram: '',
            facebook: '',
            youtube: '',
            whatsapp: '',
            email: '',
            phone: '',
            address: '',
            neighborhood: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'Brasil',
            observation: '',
            checkinLocalizationParameter: '',
            emailDomain: '',
            subscriptionAccountId: '',
            feeAccountId: '',
            pixPaymentTypeId: '',
            status: true,
            creationDate: null,
            lastUpdate: null,
            inactivationDate: null,
        },

        validate: (values) => {
            const errors: {
                email?: string;
                subscriptionAccountId?: string;
                feeAccountId?: string;
                pixPaymentTypeId?: string;
            } = {};

            if (values.email) {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const isValid = emailRegex.test(values.email);

                if (!isValid)
                    errors.email = 'Formato inválido';
            }

            if (!values.subscriptionAccountId)
                errors.subscriptionAccountId = 'Campo obrigatório';

            if (!values.feeAccountId)
                errors.feeAccountId = 'Campo obrigatório';

            if (!values.pixPaymentTypeId)
                errors.pixPaymentTypeId = 'Campo obrigatório';

            return errors;
        },

        onSubmit: (values) => {
            saveSettings(values);
        },
    });

    const debounce = (func: any, wait: number | undefined) => {
        let timeout: string | number | NodeJS.Timeout | undefined;

        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    useEffect(() => {
        onLoad();
    }, []);

    const onLoad = async () => {

        const promises = []

        promises.push(paymentTypeService.getActivePaymentTypes());
        promises.push(cashFlowAccountService.getActiveCashFlowAccount());
        promises.push(systemConfigurationService.getConfiguration());

        setIsLoading(true);

        const [getActivePaymentTypesResponse, getActiveCashFlowAccountResponse, getConfigurationResponse] =
            await Promise.all(promises) as [IGetPaymentTypesResponse, IGetCashFlowAccountResponse, IGetConfigurationResponse];

        setIsLoading(false);

        if (getActivePaymentTypesResponse.error) {
            showWarning(getActivePaymentTypesResponse.error);
            return;
        }

        if (getActiveCashFlowAccountResponse.error) {
            showWarning(getActiveCashFlowAccountResponse.error);
            return;
        }

        setPaymentTypes(getActivePaymentTypesResponse.paymentTypes || []);
        setCashFlowAccounts(getActiveCashFlowAccountResponse.cashFlowAccounts || []);

        if (getConfigurationResponse.error) {
            showWarning(getConfigurationResponse.error);
            return;
        } else {

            const configuration = getConfigurationResponse.configuration || undefined;

            setConfiguration(configuration);
            resetForm();

            setIsUpdate(false);

            if (configuration) {
                setIsUpdate(true);
                setFieldValues(configuration);
            }
        }
    }

    const getCep = async (digits: string) => {

        setIsLoading(true);

        const { cep, error, success } = await cepService.getCep(digits);

        setIsLoading(false);

        if (success) {
            resetAddressFieldValues();
            setAddressFieldValues(cep);
        } else {
            showWarning(error || 'Falha ao recuperar dados de endereço');
        }
    };

    const setAddressFieldValues = (values: any) => {
        formik.setFieldValue('zipCode', values.zipCode);
        formik.setFieldValue('address', values.address);
        formik.setFieldValue('observation', values.observation);
        formik.setFieldValue('neighborhood', values.neighborhood);
        formik.setFieldValue('city', values.city);
        formik.setFieldValue('state', values.state);
    }

    const resetAddressFieldValues = () => {
        formik.setFieldValue('zipCode', '');
        formik.setFieldValue('address', '');
        formik.setFieldValue('observation', '');
        formik.setFieldValue('neighborhood', '');
        formik.setFieldValue('city', '');
        formik.setFieldValue('state', '');
        formik.setFieldValue('country', 'Brasil');
    }

    const resetForm = () => {
        formik.resetForm({
            values: {
                id: '',
                name: '',
                instagram: '',
                facebook: '',
                youtube: '',
                whatsapp: '',
                email: '',
                phone: '',
                address: '',
                neighborhood: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'Brasil',
                observation: '',
                checkinLocalizationParameter: '',
                emailDomain: '',
                subscriptionAccountId: '',
                feeAccountId: '',
                pixPaymentTypeId: '',
                status: true,
                creationDate: null,
                lastUpdate: null,
                inactivationDate: null,
            },
        })
    }

    const getSettings = async () => {

        setIsLoading(true);

        const { success, error, configuration } = await systemConfigurationService.getConfiguration();

        setIsLoading(false);

        if (success) {
            resetForm();
            setFieldValues(configuration);
            setIsUpdate(true);
        } else {
            showWarning(error || 'Falha ao recuperar configurações');
        }
    }

    const setFieldValues = (values: any) => {
        formik.setFieldValue('id', values.id);
        formik.setFieldValue('name', values.name);
        formik.setFieldValue('instagram', values.instagram);
        formik.setFieldValue('facebook', values.facebook);
        formik.setFieldValue('whatsapp', values.whatsapp);
        formik.setFieldValue('youtube', values.youtube);
        formik.setFieldValue('email', values.email);
        formik.setFieldValue('phone', values.phone);
        formik.setFieldValue('address', values.address);
        formik.setFieldValue('neighborhood', values.neighborhood);
        formik.setFieldValue('city', values.city);
        formik.setFieldValue('state', values.state);
        formik.setFieldValue('zipCode', values.zipCode);
        formik.setFieldValue('country', values.country);
        formik.setFieldValue('observation', values.observation);
        formik.setFieldValue('checkinLocalizationParameter', values.checkinLocalizationParameter);
        formik.setFieldValue('subscriptionAccountId', values.subscriptionAccountId);
        formik.setFieldValue('feeAccountId', values.feeAccountId);
        formik.setFieldValue('pixPaymentTypeId', values.pixPaymentTypeId);
        formik.setFieldValue('status', values.status);
        formik.setFieldValue('creationDate', values.creationDate);
        formik.setFieldValue('lastUpdate', values.lastUpdate);
        formik.setFieldValue('inactivationDate', values.inactivationDate);
    }

    const saveSettings = async (values: any) => {

        if (!values.id)
            createSettings(values);
        else
            updateSettings(values);

    }

    const createSettings = async (values: any) => {
        const request = {
            name: values.name,
            instagram: values.instagram,
            facebook: values.facebook,
            youtube: values.youtube,
            whatsapp: values.whatsapp,
            email: values.email,
            phone: values.phone,
            address: values.address,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            country: values.country,
            observation: values.observation,
            checkinLocalizationParameter: values.checkinLocalizationParameter,
            emailDomain: values.emailDomain,
            subscriptionAccountId: values.subscriptionAccountId,
            feeAccountId: values.feeAccountId,
            pixPaymentTypeId: values.pixPaymentTypeId,
        };

        setIsLoading(true);

        const response = await systemConfigurationService.saveConfiguration(request);

        setIsLoading(false);

        if (response && response.success) {

            getSettings();

            showSuccess('Registro gravado com sucesso');
        } else {
            showError('Falha ao gravar registro')
        }
    }

    const updateSettings = async (values: any) => {
        const request = {
            id: values.id,
            name: values.name,
            instagram: values.instagram,
            facebook: values.facebook,
            youtube: values.youtube,
            whatsapp: values.whatsapp,
            email: values.email,
            phone: values.phone,
            address: values.address,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            country: values.country,
            observation: values.observation,
            checkinLocalizationParameter: values.checkinLocalizationParameter,
            emailDomain: values.emailDomain,
            subscriptionAccountId: values.subscriptionAccountId,
            feeAccountId: values.feeAccountId,
            pixPaymentTypeId: values.pixPaymentTypeId,
        };

        setIsLoading(true);

        const response = await systemConfigurationService.saveConfiguration(request);

        setIsLoading(false);

        if (response && response.success) {

            getSettings();

            showSuccess('Registro atualizado com sucesso');
        } else {
            showError('Falha ao atualizar registro')
        }
    }

    return (
        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (
                    <>
                        <div className='col-12'>
                            <Card>
                                <CardBody>
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            {isUpdate ? (
                                                <>
                                                    <Icon
                                                        icon='DoneAll'
                                                        size='lg'
                                                        className='me-2 text-success'
                                                    />
                                                    <span className='me-2 text-success'>Configurações encontradas. Última atualização: 
                                                        <b>
                                                            { dayjs(configuration?.lastUpdate || configuration?.creationDate).format(
                                                                'DD/MM/YYYY',
                                                            )}
                                                        </b>
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <Icon
                                                        icon='Warning'
                                                        size='lg'
                                                        className='me-2 text-warning'
                                                    />
                                                    <span className='text-warning'>Configurações não encontradas</span>
                                                </>
                                            )}
                                        </div>
                                        <div className='col-auto'>
                                            <div className='row g-1'>
                                                <div className='col-auto'>
                                                    <Button
                                                        className='me-3'
                                                        icon={isLoading ? undefined : 'Save'}
                                                        isLight
                                                        color={'success'}
                                                        isDisable={isLoading}
                                                        onClick={formik.handleSubmit}>
                                                        {isLoading && <Spinner isSmall inButton />}
                                                        {isLoading
                                                            ? 'Salvando'
                                                            : 'Salvar'
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='SportsKabaddi'>
                                        <CardTitle>Academia</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='name' label='Nome da Academia' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    isTouched={formik.touched.name}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-12'>
                                            <FormGroup id='checkinLocalizationParameter' label='Localização (Latitude e Longitude)' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.checkinLocalizationParameter}
                                                    isTouched={formik.touched.checkinLocalizationParameter}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='Contacts'>
                                        <CardTitle>Contatos</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='email' label='E-mail' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    isValid={formik.isValid}
                                                    isTouched={formik.touched.email}
                                                    type='email'
                                                    invalidFeedback={
                                                        formik.errors.email
                                                    }
                                                    validFeedback=''
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-12'>
                                            <FormGroup id='phone' label='Telefone' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone}
                                                    isTouched={formik.touched.phone}
                                                    type='tel'
                                                    mask='+55 (99) 99999-9999'
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>


                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='Groups'>
                                        <CardTitle>Redes Sociais</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='whatsapp' label='Whatsapp' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.whatsapp}
                                                    isTouched={formik.touched.whatsapp}
                                                    type='tel'
                                                    mask='+55 (99) 99999-9999'
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='instagram' label='Instagram' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.instagram}
                                                    isTouched={formik.touched.instagram}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='facebook' label='Facebook' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facebook}
                                                    isTouched={formik.touched.facebook}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='youtube' label='Youtube' isFloating>
                                                <Input
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.youtube}
                                                    isTouched={formik.touched.youtube}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='HolidayVillage'>
                                        <CardTitle>Endereço</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='zipCode' label='CEP' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.zipCode}
                                                    isTouched={formik.touched.zipCode}
                                                    onChange={(e: { target: { value: string } }) => {
                                                        formik.handleChange(e);

                                                        if (e.target.value.length === 8)
                                                            debounce(
                                                                () =>
                                                                    getCep(e.target.value),
                                                                1000,
                                                            )();

                                                        if (e.target.value.length === 0)
                                                            resetAddressFieldValues();
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='address' label='Endereço' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address}
                                                    isTouched={formik.touched.address}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='neighborhood' label='Bairro' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.neighborhood}
                                                    isTouched={formik.touched.neighborhood}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='city' label='Cidade' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.city}
                                                    isTouched={formik.touched.city}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='state' label='Estado' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.state}
                                                    isTouched={formik.touched.state}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <FormGroup id='country' label='País' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                    isTouched={formik.touched.country}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='observation' label='Complemento' isFloating>
                                                <Input
                                                    className="text-uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.observation}
                                                    isTouched={formik.touched.observation}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='AttachMoney'>
                                        <CardTitle>Financeiro</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='subscriptionAccountId' label='Escolha a conta para recebimento de mensalidades' isFloating>

                                                <Select
                                                    ariaLabel=''
                                                    placeholder='Recebimento de mensalidades'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.subscriptionAccountId}
                                                    list={
                                                        cashFlowAccounts.map((cashFlowAccount) => ({
                                                            value: cashFlowAccount.id,
                                                            text: `${cashFlowAccount.name}`
                                                        }))
                                                    }
                                                    isValid={formik.isValid}
                                                    isTouched={formik.touched.subscriptionAccountId}
                                                    invalidFeedback={
                                                        formik.errors.subscriptionAccountId
                                                    }
                                                    validFeedback=''
                                                />

                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='feeAccountId' label='Escolha a conta para lançamento de taxas' isFloating>

                                                <Select
                                                    ariaLabel=''
                                                    placeholder='Escolha a conta para lançamento de taxas'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.feeAccountId}
                                                    list={
                                                        cashFlowAccounts.map((cashFlowAccount) => ({
                                                            value: cashFlowAccount.id,
                                                            text: `${cashFlowAccount.name}`
                                                        }))
                                                    }
                                                    isValid={formik.isValid}
                                                    isTouched={formik.touched.feeAccountId}
                                                    invalidFeedback={
                                                        formik.errors.feeAccountId
                                                    }
                                                    validFeedback=''
                                                />

                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <FormGroup id='pixPaymentTypeId' label='Escolha a forma de pagamento PIX' isFloating>

                                                <Select
                                                    ariaLabel=''
                                                    placeholder='Escolha a forma de pagamento PIX'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pixPaymentTypeId}
                                                    list={
                                                        paymentTypes.map((paymentType) => ({
                                                            value: paymentType.id,
                                                            text: `${paymentType.name}`
                                                        }))
                                                    }
                                                    isValid={formik.isValid}
                                                    isTouched={formik.touched.pixPaymentTypeId}
                                                    invalidFeedback={
                                                        formik.errors.pixPaymentTypeId
                                                    }
                                                    validFeedback=''
                                                />

                                            </FormGroup>
                                        </div>


                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default CommonSettings;