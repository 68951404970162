import { FC, useContext, useEffect, useState } from "react";
import Avatar from "../../../../components/Avatar";
import AuthContext from "../../../../contexts/authContext";
import useDeviceScreen from "../../../../hooks/useDeviceScreen";

const beltColors = [
    'Branca',
    'CinzaEBranca',
    'Cinza',
    'CinzaEPreta',
    'AmarelaEBranca',
    'Amarela',
    'AmarelaEPreta',
    'LaranjaEBranca',
    'Laranja',
    'LaranjaEPreta',
    'VerdeEBranca',
    'Verde',
    'VerdeEPreta',
    'Azul',
    'Roxa',
    'Marrom',
    'Preta',
    'Amarela',
    'Verde',
    'Laranja',
    'Cinza',
];
interface ICommonAttendanceBeltProps {
    userBelt?: string;
    userGrade?: string;
}
const CommonAttendanceBelt: FC<ICommonAttendanceBeltProps> = ({userBelt, userGrade}) => {

    const deviceScreen = useDeviceScreen();

    const { userData } = useContext(AuthContext);
    const [beltImage, setBeltImage] = useState<string>('');
    const [size, setSize] = useState<number>();

    useEffect(() => {
        const belt = getUserBeltImage(userBelt ?? '', userGrade ?? '0')
        setBeltImage(belt);
        getSize();
    }, [beltImage]);

    const getUserBeltImage = (graduation: string, grade: string) => {
        if (!graduation || !grade)
            return require('../../../../assets/img/belticon/defaultbelt.png')

        if (beltColors.includes(graduation)) {
            return require(`../../../../assets/img/belticon/${graduation}${grade}.png`);
        } else {
            return require('../../../../assets/img/belticon/defaultbelt.png');
        }
    };

    const getSize = () => {
        // @ts-ignore
        const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;
        setSize(mobileDesign ? 20 : 60);
    }

    return (
        <div className='row g-1 justify-content-center align-items-center'>
            <div className='col-auto'>
                <div className='g-1 '>
                    <div className='col-auto align-items-center'>
                        <Avatar
                            src={beltImage}
                            srcSet={beltImage}
                            size={size}
                            rounded={1}
                            // border={1}
                            // borderColor={'success'} 
                            />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonAttendanceBelt;