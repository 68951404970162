import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

const CommonLastAttendanceList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);

    const [lastAttendances, setLastAttendances] = useState<ILastAttendance[]>([]);

    const headers = [
        { label: 'Nome', key: 'name' },
        { label: 'Matrícula', key: 'subscription' },
        { label: 'Último Check-in', key: 'lastAttendanceView' },
        { label: 'Dias Atrás', key: 'daysAgo' },
    ];

    useEffect(() => {
        getLastAttendances();
    }, []);

    const getLastAttendances = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetLastAttendances`);

        setIsLoading(false);

        if (response && response.success) {

            if (response.collection) {
                const userLastAttendances = response.collection.map((userLastAttendance: ILastAttendance) => ({
                    ...userLastAttendance,
                    lastAttendanceView: userLastAttendance.lastAttendance ? dayjs(userLastAttendance.lastAttendance).format('DD/MM/YYYY') : '',
                }));

                setLastAttendances(userLastAttendances);
            } else {
                setLastAttendances(response.collection);
            }
        } else {
            showWarning(response.message);
        }
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='ReportProblem' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Maiores Ausências
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>

                                    <ExportButton headers={headers} data={lastAttendances} filename="maiores-ausencias" />

                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Matrícula</th>
                                            <th>Último check-in</th>
                                            <th>Dias atrás</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(lastAttendances, currentPage, perPage).map((item) => (
                                            <tr key={item.id}>

                                                <td>{item.name}</td>
                                                <td>{item.subscription}</td>
                                                <td>{item.lastAttendance == null ? '' : dayjs(item?.lastAttendance).format('DD/MM/YYYY')}</td>
                                                <td>{item.daysAgo}</td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={lastAttendances}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonLastAttendanceList;