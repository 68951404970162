import classNames from "classnames";
import { FC } from "react";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import useDarkMode from "../../../../hooks/useDarkMode";

interface ICommonEmergencyCardProps {
    student?: IStudent
}

const CommonEmergencyCard: FC<ICommonEmergencyCardProps> = ({ student }) => {

    const { darkModeStatus } = useDarkMode();

    return (

        <>
            <Card borderSize={1} stretch>
                <CardBody className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                        <div className='ratio ratio-1x1' style={{ width: 72 }}>
                            <div
                                className={classNames(
                                    'rounded-2 d-flex align-items-center justify-content-center',
                                    {
                                        'bg-l10-primary': !darkModeStatus,
                                        'bg-lo25-primary': darkModeStatus,
                                    },
                                )}>
                                <span className='text-primary fs-1 fw-bold'>
                                    <Icon icon={'Emergency'} />
                                </span>
                            </div>
                        </div>
                    </div>

                    {
                        student?.emergencyName ? (

                            <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold fs-6 mb-0'>{'Emergência'}</div>
                                    <div className='text-muted'>
                                        <span className='fw-bold'>
                                            {
                                               `${student.emergencyName} (${student.emergencyKinship || 'Contato emergência não informado'})`  
                                            }
                                        </span>
                                    </div>
                                    <div className='text-muted'>
                                        <span className='fw-bold'>
                                            {
                                               `${student.emergencyPhone}`  
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold fs-6 mb-0'>{'Contato de emergência não informado'}</div>
                                </div>
                            </div>
                        )
                    }
                </CardBody>
            </Card >
        </>
    );
};

CommonEmergencyCard.defaultProps = {
    student: undefined
};

export default CommonEmergencyCard;