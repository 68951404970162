import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Calendar, momentLocalizer, View as TView, Views } from 'react-big-calendar';
import { DEFAULT_COLOR } from '../../../../components/common/ColorPicker';

const MIN_HOUR = 6;
const MAX_HOUR = 23;

const MyWeekEvent = ({ event }: { event: any }) => {
	return (
		<div className='row g-2'>
      <div className='col-12 text-truncate'>{event.course.name}</div>
    </div>
	);
};

const getTimeAsDate = (hour: number, tzMoment: any) => {
	const m = tzMoment('1970-01-01');
	return new Date(m.hour(hour).minute(0).format());
};

interface Props {
	schedules: IParseSchedule[];
	onScheduleClick: (schedule: IParseSchedule) => void;
}

export const ScheduleCalendar = ({ schedules, onScheduleClick }: Props) => {
  const [viewMode] = useState<TView>(Views.WEEK);
  const [availableViews] = useState<TView[]>([Views.WEEK]);

	const eventStyleGetter = (event: IParseSchedule) => {
		const backgroundColor = event.course.color || DEFAULT_COLOR; // Use the event's color or a default color
		const style = {
			backgroundColor,
			color: 'white',
			borderRadius: '5px',
			border: 'none',
			display: 'block',
		};
		return {
			style,
		};
	};

	const localizer = momentLocalizer(moment.tz.setDefault('America/Sao_Paulo'));

	const events = ({ schedules }: { schedules: IParseSchedule[] }) => {
		//GAMBETA
		const scheduleList = schedules.map((item) => {
			const endDate = moment(item.end).toDate();  // Convert item.end to a Date object
			endDate.setHours(endDate.getHours()+3); // Now you can use setHours on the Date object
	
			const startDate = moment(item.start).toDate();  // Convert item.end to a Date object
			startDate.setHours(startDate.getHours()+3); // Now you can use setHours on the Date object

			return {
				id: item.id,
				start: startDate,
				end: endDate,
				course: item.course,
			};
		});
    
		return scheduleList;
	}
	
  return (
    <div style={{ height: '100%', minHeight: '500px' }}>
      <Calendar
        localizer={localizer}
        toolbar={false}
        events={events({ schedules })}
        onSelectEvent={(event) => {
          const parsedSchedule: IParseSchedule = {
            id: event.id,
            start: event.start,
            end: event.end,
            course: event.course,
          };
          onScheduleClick(parsedSchedule);
        }}
        min={getTimeAsDate(MIN_HOUR, moment)}
        max={getTimeAsDate(MAX_HOUR, moment)}
        views={availableViews}
        defaultDate={new Date()}
        defaultView={viewMode}
        components={{
					event: MyWeekEvent,
				}}
        style={{ height: '100%' }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
	);
};
