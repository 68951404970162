import classNames from "classnames";
import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import Spinner from "../../../../components/bootstrap/Spinner";
import { GET_ASYNC, POST_ASYNC } from "../../../../components/extras/HttpHelper";
import { showSuccess, showWarning } from "../../../../components/extras/Notifications";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import AuthContext from "../../../../contexts/authContext";
import useSortableData from "../../../../hooks/useSortableData";
import LoadingPage from "../../loading/LoadingPage";

const CommonClassCheckinList: FC = () => {

    const { userData, cognitoId } = useContext(AuthContext);
    const [photos, setPhotos] = useState<IPhoto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { items, requestSort, getClassNamesFor } = useSortableData(photos);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
    const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
    const [classCheckinOffcanvas, setClassCheckinOffcanvas] = useState(false);
    //const [classCheckinPhotoOffcanvas, setClassCheckinPhotoOffcanvas] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const inputFileRef = React.useRef<HTMLInputElement>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);

    const navigate = useNavigate();

    const formik = useFormik({
        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            return undefined;
        },
        initialValues: {
            checkinDate: ''
        },
    });

    useEffect(() => {
    }, [userData, cognitoId]);

    useEffect(() => {

        getMainPhotos();

    }, []);

    const getMainPhotos = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC('api/NPhoto/GetMain/1/999');

        setIsLoading(false);

        if (response && response.success) {
            setPhotos(response.collection);
        } else {
            showWarning(response.message);
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const sizeInMB = file.size / (1024 * 1024);
            if (sizeInMB > 10) {
                showWarning("Arquivo excede 10MB. Por favor envie um arquivo menor.");
                return;
            }
            const reader = new FileReader();
            setImageBlob(event.target.files[0]);
            reader.onload = (e) => {
                setImageUrl(e.target?.result as string);
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleClassCheckinOffcanvas = () => {
        setClassCheckinOffcanvas(!classCheckinOffcanvas);
        resetForm();
    };

    const saveClassCheckin = async () => {

        if (!imageBlob || !formik.values.checkinDate) {
            showWarning('Falha na validação. Por favor, verifique os dados fornecidos.');
        }

        const formData = new FormData();

        if (imageBlob) {
            formData.append('image', imageBlob, 'captured-image.jpg');
            formData.append('checkInDate', formik.values.checkinDate);
        }

        setIsLoading(true);

        const response = await POST_ASYNC('api/NPhoto/EnqueueImage', formData);

        setIsLoading(false);

        if (response && response.success) {
            resetForm();
            setClassCheckinOffcanvas(false);
            getMainPhotos();

            showSuccess('Imagem enviada com sucesso. O processamento será iniciado.');
        } else {
            showWarning(response.message);
        }
    }

    const resetForm = () => {
        setImageUrl(null);
        setImageBlob(null);

        formik.values.checkinDate = '';
    }

    return (
        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (
                    <>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='PhotoCameraFront' iconColor='primary'>
                                    <CardTitle>
                                        Fotos dos Treinos
                                    </CardTitle>
                                </CardLabel>

                                <CardActions>
                                    <Button
                                        color='success'
                                        icon='AddCircle'
                                        isLight
                                        onClick={() => handleClassCheckinOffcanvas()}>
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Foto</th>

                                            <th
                                                onClick={() => requestSort('checkinDate')}
                                                className='cursor-pointer text-decoration-underline text-center'>
                                                Data de Envio {' '}

                                            </th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataPagination(items, currentPage, perPage).map((item, index) => (
                                            <tr key={item.id}>
                                                <td className="text-center">
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedImage(`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/Main/${item.name}.${item.fileExtension}`)}
                                                        className={classNames(
                                                            'ratio ratio-1x1',
                                                            'rounded-2',
                                                            'border-0',
                                                        )}>
                                                        <img
                                                            src={`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/Main/${item.name}.${item.fileExtension}`}
                                                            alt={`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/Main/${item.name}.${item.fileExtension}`}
                                                            width='100%'
                                                            height='auto'
                                                            className='object-fit-contain p-1'
                                                        />
                                                    </button>
                                                </td>
                                                <td className="text-center">
                                                    <div>
                                                        {dayjs(`${item.checkinDate}`).format('DD/MM/YYYY')}
                                                    </div>
                                                </td>

                                                <td className="text-end">
                                                    <Dropdown>
                                                        <DropdownToggle hasIcon={false}>
                                                            <Button
                                                                icon='MoreHoriz'
                                                                color='dark'
                                                                isLight
                                                                shadow='sm'
                                                                aria-label='Mais Ações'
                                                            />
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd>
                                                            <DropdownItem>
                                                                <Button
                                                                    isOutline={false}
                                                                    color='dark'
                                                                    isLight={true}
                                                                    className={classNames('text-nowrap', {
                                                                        'border-light': !true,
                                                                    })}
                                                                    icon='RemoveRedEye'
                                                                    onClick={() => navigate(`../attendance/class-checkin-detail/${item.name}`, { state: { itemName: item.name, fileExtension: item.fileExtension } })}>
                                                                    Detalhes
                                                                </Button>
                                                            </DropdownItem>

                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={items}
                                label='fotos'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>

                        <Modal
                            setIsOpen={setClassCheckinOffcanvas}
                            isOpen={classCheckinOffcanvas}
                            titleId='subscriptionType'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                            size={'lg'}
                        >
                            <ModalHeader setIsOpen={setClassCheckinOffcanvas}>
                                <OffCanvasTitle id='subscriptionType'>{'Enviar Foto do Treino'}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>
                                <div className='row g-4'>
                                    <div className='col-12'>
                                        <FormGroup id='photo' isFloating>
                                            <Input
                                                id='inputFile'
                                                type='file'
                                                ref={inputFileRef}
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <FormGroup id='checkinDate' label='Data de Check-in' isFloating>
                                            <Input
                                                placeholder='Data de Check-in'
                                                onChange={formik.handleChange}
                                                value={formik.values.checkinDate}
                                                type='date'
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <div className='row pt-4 g-4 text-center'>
                                            {imageUrl &&
                                                <div className='col-12'>
                                                    <img src={imageUrl} alt="Preview" width={900} height={900} className="img-fluid" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={saveClassCheckin}
                                    isDisable={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Enviar
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <Modal setIsOpen={setSelectedImage} isOpen={!!selectedImage} isCentered>
                            <ModalHeader setIsOpen={setSelectedImage}>
                                <ModalTitle id='preview'>Foto</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                <img src={selectedImage} alt='preview' width='100%' />
                            </ModalBody>
                        </Modal>

                    </>
                )
            }
        </>
    );
};

export default CommonClassCheckinList;