import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const addEndingZeros = (amount: number) => {
	const amountLength = amount.toString().length;
	return amount.toString().padEnd(amountLength + 2, '0');
};

const getStripeObject = async () => {
	const { data } = await axios.get(
		`${process.env.REACT_APP_GB_API_URL}api/StripePayment/GetStripePublicKey`,
	);
	return await loadStripe(data.publicKey);
};

const createaPaymentIntent = async (amount: number, currency: string, userId: string) => {
	const { data } = await axios.post(
		`${process.env.REACT_APP_GB_API_URL}api/StripePayment/CreatePaymentIntent`,
		{
			amount: addEndingZeros(amount),
			currency,
			userId
		},
	);
	return data.clientSecret;
};

export default {
	createaPaymentIntent,
	getStripeObject,
};
