export interface IFileUpload {
    id: string;
    file?: File | null | string;
    description?: string;
    name: string;
    type?: string;
    operationType: OperationTypeEnum;
}

export enum OperationTypeEnum {
    Add, Remove, Added
}