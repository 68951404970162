import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import IAcademyFinancial from '../../../common/data/academy/academyFinancial';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner';
import { showCreateSuccess, showError, showWarning } from '../../../components/extras/Notifications';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import financialService, { IGetFinancialResults } from '../../../services/financial-service';
import paymentTypeService, { IGetPaymentTypesResponse } from '../../../services/payment-type-service';
import studentService, { StudentResponse } from '../../../services/student-service';
import LoadingPage from '../loading/LoadingPage';
import ExpectedSubscriptionValue from './common/CommonExpectedSubscriptionValue';
import NoStudentsCount from './common/CommonNoStudentsCount';
import PaidStudents from './common/CommonPaidStudents';
import PaidSubscriptionValue from './common/CommonPaidSubscriptionValue';
import StudentsCount from './common/CommonStudentsCount';
import UnpaidStudents from './common/CommonUnpaidStudents';

const FinancialPage = () => {
	const { darkModeStatus } = useDarkMode();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [subscriptionOffcanvas, setSubscriptionOffcanvas] = useState(false);

	const [academyFinancial, setAcademyFinancial] = useState<IAcademyFinancial>();
	const [students, setStudents] = useState<IStudent[]>([]);
	const [student, setStudent] = useState<IStudent>();
	const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);


	const formik = useFormik({
		initialValues: {
			paymentDate: '',
			amount: 0,
			userId: '',
			paymentType: ''
		},
		validate: (values) => {
			const errors: {
				paymentDate?: string,
				amount?: string,
				userId?: string,
				paymentType?: string
			} = {};

			if (!values.paymentDate)
				errors.paymentDate = 'Campo obrigatório';

			if (!values.amount)
				errors.amount = 'Campo obrigatório';

			if (!values.userId)
				errors.userId = 'Campo obrigatório';

			if (!values.paymentType)
				errors.paymentType = 'Campo obrigatório';

			return errors;
		},
		onSubmit: async (values, { resetForm }) => {
			createPayment(values);
			resetForm();
		},
	});

	useEffect(() => {

		onLoad();

	}, []);

	const onLoad = async () => {

		const promises = []
		promises.push(financialService.getFinancialResults());
		promises.push(studentService.getActiveWithSubscription());
		promises.push(paymentTypeService.getActivePaymentTypes());

		setIsLoading(true);

		const [getFinancialResultsResponse, getActiveWithSubscriptionResponse, getActivePaymentTypesResponse] =
			await Promise.all(promises) as [IGetFinancialResults, StudentResponse, IGetPaymentTypesResponse];

		setIsLoading(false);

		if (getFinancialResultsResponse.error) {
			showWarning(getFinancialResultsResponse.error);
			return;
		}

		if (getActiveWithSubscriptionResponse.error) {
			showWarning(getActiveWithSubscriptionResponse.error);
			return;
		}

		if (getActivePaymentTypesResponse.error) {
			showWarning(getActivePaymentTypesResponse.error);
			return;
		}

		setAcademyFinancial(getFinancialResultsResponse.academyFinancialResult);
		setStudents(getActiveWithSubscriptionResponse.students || []);
		setPaymentTypes(getActivePaymentTypesResponse.paymentTypes || []);

	}

	const getAcademyFinancialInformation = async () => {

		setIsLoading(true);

		const { success, error, academyFinancialResult } = await financialService.getFinancialResults();

		setIsLoading(false);

		if (success) {
			setAcademyFinancial(academyFinancialResult);
		} else {
			showWarning(error || 'Falha ao recuperar informação financeiras da academia');
		}
	}

	const getActiveStudentsWithSubscription = async () => {

		setIsLoading(true);

		const { success, error, students } = await studentService.getActiveWithSubscription();

		setIsLoading(false);

		if (success) {
			setStudents(students || []);
		} else {
			showWarning(error || 'Falha ao recuperar alunos ativos com matrícula');
		}
	}

	const handleAcademyFinancialInformationUpdate = () => {
		getAcademyFinancialInformation();
	};

	const handleSubscriptionOffcanvas = () => {
		setStudent(undefined);
		setSubscriptionOffcanvas(!subscriptionOffcanvas);
		resetForm();
	}

	const handleChangeStudent = (id: string) => {
		const selectedStudent = students.find(student => student.id === id);
		if (selectedStudent) {
			setStudent(selectedStudent);
			formik.setFieldValue('amount', selectedStudent?.subscription?.subscriptionType?.fee || 0);
			formik.setFieldValue('userId', selectedStudent?.id || '');
		}
		else
			setStudent(undefined);
	}

	const resetForm = () => {
		formik.values.userId = '';
		formik.values.paymentDate = '';
		formik.values.amount = 0;
		formik.values.paymentType = '';
	}

	const createPayment = async (values: any) => {

		const request = {
			studentId: values?.userId,
			paymentDate: values.paymentDate,
			amount: values.amount,
			paymentTypeId: values.paymentType
		}

		setIsSubmitting(true);

		const response = await financialService.createStudentPayment({
			studentId: request.studentId!,
			amount: request.amount,
			paymentDate: request.paymentDate,
			paymentTypeId: request.paymentTypeId
		});

		setIsSubmitting(false);

		if (response && response.success) {
			resetForm();
			setSubscriptionOffcanvas(false);
			getAcademyFinancialInformation();

			showCreateSuccess();
		} else {
			showError(response.message);
		}
	};

	return (
		<PageWrapper title={'Mensalidades'}>
			<Page container='fluid'>
				{
					isLoading ?

						(
							<LoadingPage />
						)

						: (
							<>

								<Card stretch style={{ minHeight: 600 }}>
									<CardHeader borderSize={1}>
										<CardLabel icon='Payments' iconColor='primary'>
											<CardTitle tag='div' className='h5'>
												Mensalidades
											</CardTitle>
										</CardLabel>
										<CardActions>
											<Button
												color='success'
												icon='AddCircle'
												isLight
												onClick={() => handleSubscriptionOffcanvas()}
											>
												Adicionar
											</Button>
										</CardActions>
									</CardHeader>
									<CardBody>
										<div className='row g-4'>
											<div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
												<ExpectedSubscriptionValue expectedValue={academyFinancial?.expectedSubscriptionValue || 0} />
											</div>

											<div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
												<PaidSubscriptionValue paidValue={academyFinancial?.paidSubscriptionValue || 0} />
											</div>

											<div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
												<StudentsCount studentsCounter={academyFinancial?.studentsCount || 0} />
											</div>

											<div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
												<NoStudentsCount noStudentsCounter={academyFinancial?.noStudentsCount || 0} />
											</div>
										</div>

										<div className='row g-4'>
											<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12' style={{ minHeight: 500 }}>

												<div className='row g-4'>
													<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
														<PaidStudents
															paidStudents={academyFinancial?.paidStudents || []}
															onUpdate={handleAcademyFinancialInformationUpdate} />
													</div>
													<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
														<UnpaidStudents
															unPaidStudents={academyFinancial?.unPaidStudents || []}
															onUpdate={handleAcademyFinancialInformationUpdate}
														/>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>

								<Modal
									setIsOpen={setSubscriptionOffcanvas}
									isOpen={subscriptionOffcanvas}
									titleId='subscription'
									isCentered
									isScrollable
									isStaticBackdrop={true}
								//									size={'sm'}
								>
									<ModalHeader setIsOpen={setSubscriptionOffcanvas}>
										<OffCanvasTitle id='userAttendance'>{'Adicionar Mensalidade'}</OffCanvasTitle>
									</ModalHeader>
									<ModalBody>
										<div className='row g-4'>

											{(student) && (
												<div className="row g-4">
													<div className='d-flex align-items-center justify-content-center'>
														<div className='flex-shrink-0'>
															<Avatar
																src={student.profileImageUrl}
																srcSet={student.profileImageUrl}
																color='primary'
																className='rounded-circle'
																shadow='sm'
																size={80}
															/>
														</div>
													</div>
												</div>
											)}

											<br />

											<div className='row g-4'>

												<div className='col-12'>
													<FormGroup
														id='userId'
														label='Aluno'
														isFloating>
														<Select
															ariaLabel='Escolha um aluno'
															placeholder='Escolha um aluno'
															list={
																students.map((student) => ({
																	value: student.id,
																	text: `${student.fullName}`,
																	label: `${student.fullName}`
																}))
															}
															onChange={(e: { target: { value: any } }) => {
																formik.handleChange(e);

																if (e.target.value) {
																	handleChangeStudent(e.target.value)
																}
															}}
															onBlur={formik.handleBlur}
															value={formik.values.userId}
															isValid={formik.isValid}
															isTouched={formik.touched.userId}
															invalidFeedback={
																formik.errors.userId
															}
															validFeedback=''
														/>
													</FormGroup>
												</div>

												<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='paymentDate' label='Data' isFloating>
														<Input
															placeholder='Data de Pagamento'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.paymentDate}
															type='date'
															isValid={formik.isValid}
															isTouched={formik.touched.paymentDate}
															invalidFeedback={
																formik.errors.paymentDate
															}
															validFeedback=''
														/>
													</FormGroup>
												</div>

												<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='amount' label='Valor da Matrícula' isFloating>
														<Input
															placeholder='Valor'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.amount}
															type='number'
															isValid={formik.isValid}
															isTouched={formik.touched.amount}
															invalidFeedback={
																formik.errors.amount
															}
															validFeedback=''
														/>
													</FormGroup>
												</div>

												<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
													<FormGroup
														id='paymentType'
														label='Forma de Pagamento'
														isFloating
													>
														<Select
															ariaLabel='Escolha uma forma de pagamento'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.paymentType}
															list={
																paymentTypes.map((paymentType) => ({
																	value: paymentType.id,
																	text: `${paymentType.name}`
																}))
															}
															isValid={formik.isValid}
															isTouched={formik.touched.paymentType}
															invalidFeedback={
																formik.errors.paymentType
															}
															validFeedback=''
														/>
													</FormGroup>
												</div>

											</div>
										</div>
									</ModalBody>
									<ModalFooter className='bg-transparent'>
										<Button
											color='primary'
											className='w-100'
											onClick={formik.handleSubmit}
											isDisable={isSubmitting}
										>
											{isSubmitting && (
												<Spinner isSmall inButton isGrow />
											)}
											Salvar
										</Button>
									</ModalFooter>
								</Modal>
							</>
						)
				}
			</Page>
		</PageWrapper>
	);
};

export default FinancialPage;
