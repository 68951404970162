import dayjs from 'dayjs';
import { getMonthByDigit } from '../common/data/calendar';

export const getCurrentMonthName = () => getMonthByDigit(dayjs().month() + 1);

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const utcToLocalWithFormat = (date: string, format: string = DATE_FORMAT) => dayjs.utc(date).local().format(format);

export const localToUtc = (date: string) => dayjs.utc(date).toDate();