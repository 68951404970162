import { FC } from "react";
import Badge from "../../../../components/bootstrap/Badge";

interface ICommonBadgeStatusProps {
    status: boolean,
    lastUpdate?: Date
}

const CommonBadgeStatus: FC<ICommonBadgeStatusProps> = ({ status, lastUpdate }) => {
    if (status) {

        return (
            <h5>
                <Badge color='success' className='align-items-center justify-content-center'>
                    Aprovado
                </Badge>
            </h5>
        );

    } else {
        if (lastUpdate) {

            return (
                <h5>
                    <Badge color='danger' className='align-items-center justify-content-center'>
                        Reprovado
                    </Badge>
                </h5>

            );

        } else {
            return (
                <h5>
                    <Badge color='info' className='align-items-center justify-content-center'>
                        Aguardando
                    </Badge>
                </h5>
            );
        }
    }

}

export default CommonBadgeStatus;