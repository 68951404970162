import {
	DELETE_TYPED_ASYNC,
	GET_TYPED_ASYNC,
	POST_TYPED_ASYNC,
	PUT_TYPED_ASYNC,
} from '../components/extras/HttpHelper';

const BASE_PATH = 'api/Course';

interface CourseResponse {
	success: boolean;
	courses?: ICourse[];
	error?: string;
}

const getAllCourses = async (): Promise<CourseResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<ICourse>(`${BASE_PATH}/GetAll`);
		if (!response.success) {
			return { success: response.success, error: response.message || response.exception };
		}
		return { success: response.success, courses: response.collection };
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

type NewCourseType = Omit<ICourse, 'id'>;

const addNewCourse = async (newCourse: NewCourseType): Promise<CourseResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<ICourse>(`${BASE_PATH}/Create`, newCourse);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const deleteCourse = async (id: string): Promise<CourseResponse> => {
	try {
		const response = await DELETE_TYPED_ASYNC<ICourse>(`${BASE_PATH}/Delete/${id}`);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const updateCourse = async (course: ICourse): Promise<CourseResponse> => {
	try {
		const response = await PUT_TYPED_ASYNC<ICourse>(`${BASE_PATH}/Update`, course);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	addNewCourse,
	deleteCourse,
	getAllCourses,
	updateCourse,
};
