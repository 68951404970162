import Page from '../layout/Page/Page';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import SubHeader from './SubHeader/SubHeader';

interface Props {
	title: string;
	children: React.ReactNode;
	subHeader?: React.ReactNode;
	divSize?: number;
}

export const PagesLayout = ({ title, children, subHeader = null, divSize = 12 }: Props) => {
	return (
		<PageWrapper title={title}>
			<>
				{subHeader ? <SubHeader>{subHeader}</SubHeader> : <></>}
				<Page container='fluid'>
					<div className='row'>
						<div className={`col-xl-${divSize.toString()}`}>{children}</div>
					</div>
				</Page>
			</>
		</PageWrapper>
	);
};
