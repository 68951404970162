import classNames from 'classnames';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { authMenu, hiddenMenu } from '../../menu';
import { NavigationLine } from '../Navigation/Navigation';

const User = () => {
	const { width } = useWindowSize();
	const { setAsideStatus } = useContext(ThemeContext);
	const { userData, setCognitoId, cognitoId } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<Icon icon={'Person'} />
				</div>
				<div className='user-info'>
					<div className='user-name'>
						{`${userData?.name} ${userData?.surname}`}
					</div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${hiddenMenu.profile.path}/profile/${cognitoId}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>

				{
					userData.isAdmin && (

						<nav aria-label='aside-bottom-user-menu-3'>
							<div className='navigation'>
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() =>
										window.open(
											`../${hiddenMenu.facialCheckin.path}`,
											'_blank'
										)
									}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon icon='Face' className='navigation-icon' />
											<span className='navigation-text'>
												{'Check-in Facial' as ReactNode}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					)
				}

				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (setCognitoId) {
									setCognitoId('');
								}
								if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
									setAsideStatus(false);
								}
								navigate(`../${authMenu.loginV2.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{'Sair' as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
