import { FormikErrors } from 'formik';
import { useEffect, useRef } from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import { SaveForm } from '../../../components/common/SaveForm';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';
import systemConfigurationService from "../../../services/system-configuration-service";

type ConfigurationPixValues = {
	pixName: string;
	city: string;
	pixCode: string;
	acceptPix: boolean;
};

interface Props {
	isModalOpened: boolean;
    isFirstTimeCreated: boolean;
    selectedPix: IConfiguration | null;
	onModalClose: () => void;
	onAfterSubmit: () => void;
}

export const PixSettingsModal = ({
	isModalOpened,
    isFirstTimeCreated,
    selectedPix,
	onModalClose,
	onAfterSubmit,
}: Props) => {
	const descriptionInputRef = useRef<HTMLInputElement>(null);

	// Adicionei isso pra colocar o foco quando o filtro abrir
	useEffect(() => {
		if (isModalOpened && descriptionInputRef.current) {
			descriptionInputRef.current.focus();
		}
	}, [isModalOpened]);

	const onFormValidate = (values: ConfigurationPixValues) => {
		const errors: FormikErrors<ConfigurationPixValues> = {};

		if (!values.pixName) {
			errors.pixName = 'Campo obrigatório';
		} else {
			if(values.pixName.length > 25)
				errors.pixName = 'Nome do beneficiário não deve ter mais que 25 caracteres'
		}

        if (!values.city) {
			errors.city = 'Campo obrigatório';
		}
        if (!values.pixCode) {
			errors.pixCode = 'Campo obrigatório';
		}


		return errors;
	};

	const onFormSubmit = async (values: ConfigurationPixValues) => {
        if(!selectedPix) {
            const response = await systemConfigurationService.addNewConfiguration({
				pixName: values.pixName,
				city: values.city,
				pixCode: values.pixCode,
				acceptPix: true
			});

			if (response.error) {
				showWarning(response.error);
				return;
			}

			showSuccess('Configuração adicionada com sucesso');
		}
        else {
            const response = await systemConfigurationService.updateConfiguration({
                pixName: values.pixName,
				city: values.city,
				pixCode: values.pixCode,
				acceptPix: true
            });
            if (response.error) {
				showWarning(response.error);
				return;
			}
            showSuccess('Configuração alterada com sucesso');
        }
		// form.resetForm();
		onAfterSubmit();
    }
	
    const onClose = () => {
        onModalClose();
    };		

	return (
		<Modal isOpen={isModalOpened} setIsOpen={onClose} isCentered isStaticBackdrop>
			<ModalHeader setIsOpen={onClose}>
				<ModalTitle id='course-modal'>Adicionar Pix</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<SaveForm<ConfigurationPixValues>
					initialValues={{
                        pixName: selectedPix?.pixName || '',
                        city: selectedPix?.city || '',
                        pixCode: selectedPix?.pixCode || '',
						acceptPix: true
					}}
					onSubmit={onFormSubmit}
					onValidate={onFormValidate}>
					{(form) => (
						<>
							<div className='col-12'>
								<FormGroup id='pixName' label='Nome do Beneficiário' isFloating>
									<Input
										id='pixName-input'
                                        type='text'
										ref={descriptionInputRef}
										placeholder='Nome do Beneficiário'
										onChange={form.handleChange}
										onBlur={form.handleBlur}
										value={form.values.pixName}
										isValid={form.isValid}
										invalidFeedback={form.errors.pixName}
										isTouched={form.touched.pixName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='pixCode' label='Chave PIX' isFloating>
									<Input
										id='pixCode-input'
										type='text'
										onChange={form.handleChange}
										value={form.values.pixCode}
										isValid={form.isValid}
										invalidFeedback={form.errors.pixCode}
										isTouched={form.touched.pixCode}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
                            <FormGroup id='city' label='Cidade' isFloating>
									<Input
										id='city-input'
										type='text'
										onChange={form.handleChange}
										value={form.values.city}
										isValid={form.isValid}
										invalidFeedback={form.errors.city}
										isTouched={form.touched.city}
									/>
								</FormGroup>
							</div>
						</>
					)}
				</SaveForm>
			</ModalBody>
		</Modal>
	);
};
