import { jwtDecode } from 'jwt-decode';
import { TColor } from '../../type/color-type';

interface IJwtPayload {
    name: string;
    surname: string
    username: string;
    src: string;
    srcSet: string;
    color: TColor;
    email: string;
    registrationDate?: string;
    lastCheckIn?: string;
    graduation?: string;
    grade?: string;
    profileUrl: string;
    'custom:profileUrl': string;
    'cognito:groups': string[];
    isAdmin: boolean;
    sub: string;
    exp: number;
}

const emptyLoggedUser: IJwtPayload = {
    name: '',
    surname: '',
    username: '',
    src: '',
    srcSet: '',
    color: 'primary',
    email: '',
    profileUrl: '',
    'custom:profileUrl': '',
    'cognito:groups': [],
    isAdmin: false,
    sub: '',
    exp: 0
}

export function getDecodedToken(): IJwtPayload {
    let decodedToken = emptyLoggedUser;

    try {
        const token = sessionStorage.getItem('gb_accesstoken') === '' || sessionStorage.getItem('gb_accesstoken') === null ? localStorage.getItem('gb_accesstoken') : sessionStorage.getItem('gb_accesstoken');
        if (token) {
            decodedToken = jwtDecode<IJwtPayload>(token);
            decodedToken.isAdmin = isAdmin(decodedToken['cognito:groups']);

            const isExpired = isTokenExpired(decodedToken);
            if (isExpired) {
                clearStorages();
            }
        }
        else {
            clearStorages();
        }
    } catch (error) {
        console.error('Invalid token', error);
    }

    return decodedToken;
}

export function decodeToken(token: string): IJwtPayload {
    let decodedToken = emptyLoggedUser;

    try {
        if (token) {
            decodedToken = jwtDecode<IJwtPayload>(token);
            decodedToken.isAdmin = isAdmin(decodedToken['cognito:groups']);
        }
    } catch (error) {
        console.error('Invalid token', error);
    }

    return decodedToken;
}

function isAdmin(cognitoGroups: string[]): boolean {
    if (!cognitoGroups) {
        return false;
    }

    if (cognitoGroups.includes('Administradores')) {
        return true;
    }

    return false;
}

function clearStorages() {
    localStorage.removeItem('cognitoId');
    sessionStorage.removeItem('gb_accesstoken');
    localStorage.removeItem('gb_accesstoken');
}

function isTokenExpired(decodedToken: IJwtPayload) {

    const currentTime = Date.now() / 1000;

    return decodedToken.exp < currentTime;

}

export default IJwtPayload;