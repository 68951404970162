import classNames from "classnames";
import PropTypes from 'prop-types';
import { HTMLAttributes, forwardRef } from "react";
import Popovers from "../../components/bootstrap/Popovers";
import useDarkMode from "../../hooks/useDarkMode";
import { TColor } from "../../type/color-type";

interface IBeltProps extends HTMLAttributes<HTMLImageElement> {
	border?: null | 0 | 1 | 2 | 3 | 4 | 5;
	borderColor?: null | TColor | 'link' | 'brand' | 'brand-two' | 'storybook' | 'white';
	className?: string;
	color?: TColor | 'link' | 'brand' | 'brand-two' | 'storybook';
	rounded?: 'default' | 0 | 1 | 2 | 3 | 'bottom' | 'top' | 'circle' | 'end' | 'start' | 'pill';
	shadow?: 'none' | 'sm' | 'default' | 'lg' | null;
	size?: number;
	height?: number;
	width?: number;
	src: string;
	srcSet?: string | undefined;
	beltName?: string | null;
}

const Belt = forwardRef<HTMLImageElement, IBeltProps>(
	(
		{
			srcSet,
			src,
			className,
			size,
			rounded,
			shadow,
			color,
			border,
			height,
			width,
			borderColor,
			beltName,
			...props
		},
		ref,
	) => {
		const { darkModeStatus } = useDarkMode();

		const INNER = (
			<img
				ref={ref}
				className={classNames(
					'avatar',
					{
						[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
						'rounded-0': rounded === 0,
						[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
						border: !!border,
						[`border-${border}`]: !!border,
						[`border-${borderColor}`]: borderColor,
					},
					`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
					className,
				)}
				srcSet={srcSet}
				src={src}
				alt='Belt'
				{...props}
			/>
		);

		if (beltName) {
			return (
				<Popovers desc={beltName} trigger='hover'>
					{INNER}
				</Popovers>
			);
		}
		return INNER;
	},
);

Belt.displayName = 'Belt';

Belt.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.number,
	rounded: PropTypes.oneOf([
		'default',
		0,
		1,
		2,
		3,
		'bottom',
		'top',
		'circle',
		'end',
		'start',
		'pill',
	]),
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
	]),
	shadow: PropTypes.oneOf([null, 'none', 'sm', 'default', 'lg']),
	border: PropTypes.oneOf([null, 0, 1, 2, 3, 4, 5]),
	borderColor: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
		'white',
	]),
	beltName: PropTypes.string
};

Belt.defaultProps = {
	srcSet: undefined,
	className: 'mw-100',
	rounded: 'start',
	color: 'primary',
	height: 100,
	width: 500,
	shadow: null,
	border: null,
	borderColor: null,
	beltName: null,
};

export default Belt;