import classNames from "classnames";
import dayjs from "dayjs";
import { FC } from "react";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";

const CommonPaymentTransactionItem: FC<IPayment> = ({ id, paymentDate, amount, }) => {

	const { darkModeStatus } = useDarkMode();

	return (
		<div key={id} className='col-12'>
			<div className='row'>
				<div className='col d-flex align-items-center'>
					<div className='flex-shrink-0'>
						<div
							style={{ width: 100 }}
							className={classNames(
								`bg-l${
									darkModeStatus ? 'o25' : '10'
								}-success text-success fw-bold py-2 rounded-pill me-3 text-center`,
							)}>
							{'Paga'}
						</div>
					</div>
					<div className='flex-grow-1'>
						<div className='fs-6'>{dayjs(paymentDate).format('DD/MM/YYYY')}</div>
					</div>
				</div>
				<div className='col-auto text-end'>
					<div>
						<strong>{priceFormat(amount || 0)}</strong>
					</div>
				</div>
			</div>
		</div>
	);
};




export default CommonPaymentTransactionItem;