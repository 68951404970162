import { useEffect, useRef, useState } from "react";
import { PAYMENT_TYPE_FILTER_INITIAL_VALUES, PaymentTypeFilter } from "../../../../common/data/filters/paymentTypeFilter";
import Button from "../../../../components/bootstrap/Button";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import Checks from "../../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { FilterForm } from "../../../../components/common/FilterForm";

interface Props {
	paymentTypes: IPaymentType[];
	onFilterChange: (values: PaymentTypeFilter) => void;
}

export const CommonPaymentTypesFilter = ({ paymentTypes, onFilterChange }: Props) => {
	const nameFilterRef = useRef<HTMLInputElement>(null);

	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [filterValues, setFilterValues] = useState<PaymentTypeFilter>(
		PAYMENT_TYPE_FILTER_INITIAL_VALUES,
	);

	const onFormSubmit = (values: PaymentTypeFilter): void | Promise<any> => {
		onFilterChange(values);
		setFilterValues(values);
		setIsFilterOpened(false);
	};

	const onFormClear = () => {
		setFilterValues(PAYMENT_TYPE_FILTER_INITIAL_VALUES);
	};

	// Adicionei isso pra colocar o foco quando o filtro abrir
	useEffect(() => {
		if (isFilterOpened && nameFilterRef.current) {
			nameFilterRef.current.focus();
		}
	}, [isFilterOpened]);

	return (
		<Dropdown isOpen={isFilterOpened} setIsOpen={setIsFilterOpened} isButtonGroup>
			<DropdownToggle hasIcon={false}>
				<Button isLink={true} icon='FilterAlt' color='primary'>
					Filtrar
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd size='xl' isCloseAfterLeave={false}>
				<FilterForm<PaymentTypeFilter>
					initialValues={filterValues}
					onSubmit={onFormSubmit}
					onClear={onFormClear}>
					{(filterForm) => (
						<>
							<FormGroup id='nameFilter' label='Nome'>
								<Input
									ref={nameFilterRef}
									onChange={filterForm.handleChange}
									value={filterForm.values.nameFilter}
								/>
							</FormGroup>

                            <FormGroup id='descriptionFilter' label='Descrição'>
								<Input
									onChange={filterForm.handleChange}
									value={filterForm.values.descriptionFilter}
								/>
							</FormGroup>

							<FormGroup id='statusFilter'>
								<Checks
									id="status-filter"
									label="Formas ativas"
									name="statusFilter"
									onChange={filterForm.handleChange}
									type="switch"
									checked={filterForm.values.statusFilter}
								/>
							</FormGroup>

						</>
					)}
				</FilterForm>
			</DropdownMenu>
		</Dropdown>
	);
};