export interface ICity {
    value?: string;
    text: string;
    state: string;
}

const cities: ICity[] = [
    { value: "Abadia de Goiás - GO", text: "Abadia de Goiás - GO", state: "GO" },
    { value: "Abadia dos Dourados - MG", text: "Abadia dos Dourados - MG", state: "MG" },
    { value: "Abadiânia - GO", text: "Abadiânia - GO", state: "GO" },
    { value: "Abaetetuba - PA", text: "Abaetetuba - PA", state: "PA" },
    { value: "Abaeté - MG", text: "Abaeté - MG", state: "MG" },
    { value: "Abaiara - CE", text: "Abaiara - CE", state: "CE" },
    { value: "Abaré - BA", text: "Abaré - BA", state: "BA" },
    { value: "Abatiá - PR", text: "Abatiá - PR", state: "PR" },
    { value: "Abaíra - BA", text: "Abaíra - BA", state: "BA" },
    { value: "Abdon Batista - SC", text: "Abdon Batista - SC", state: "SC" },
    { value: "Abel Figueiredo - PA", text: "Abel Figueiredo - PA", state: "PA" },
    { value: "Abelardo Luz - SC", text: "Abelardo Luz - SC", state: "SC" },
    { value: "Abre Campo - MG", text: "Abre Campo - MG", state: "MG" },
    { value: "Abreu e Lima - PE", text: "Abreu e Lima - PE", state: "PE" },
    { value: "Abreulândia - TO", text: "Abreulândia - TO", state: "TO" },
    { value: "Acaiaca - MG", text: "Acaiaca - MG", state: "MG" },
    { value: "Acajutiba - BA", text: "Acajutiba - BA", state: "BA" },
    { value: "Acarape - CE", text: "Acarape - CE", state: "CE" },
    { value: "Acaraú - CE", text: "Acaraú - CE", state: "CE" },
    { value: "Acari - RN", text: "Acari - RN", state: "RN" },
    { value: "Acará - PA", text: "Acará - PA", state: "PA" },
    { value: "Acauã - PI", text: "Acauã - PI", state: "PI" },
    { value: "Aceguá - RS", text: "Aceguá - RS", state: "RS" },
    { value: "Acopiara - CE", text: "Acopiara - CE", state: "CE" },
    { value: "Acorizal - MT", text: "Acorizal - MT", state: "MT" },
    { value: "Acrelândia - AC", text: "Acrelândia - AC", state: "AC" },
    { value: "Acreúna - GO", text: "Acreúna - GO", state: "GO" },
    { value: "Adamantina - SP", text: "Adamantina - SP", state: "SP" },
    { value: "Adelândia - GO", text: "Adelândia - GO", state: "GO" },
    { value: "Adolfo - SP", text: "Adolfo - SP", state: "SP" },
    { value: "Adrianópolis - PR", text: "Adrianópolis - PR", state: "PR" },
    { value: "Adustina - BA", text: "Adustina - BA", state: "BA" },
    { value: "Afogados da Ingazeira - PE", text: "Afogados da Ingazeira - PE", state: "PE" },
    { value: "Afonso Bezerra - RN", text: "Afonso Bezerra - RN", state: "RN" },
    { value: "Afonso Cláudio - ES", text: "Afonso Cláudio - ES", state: "ES" },
    { value: "Afonso Cunha - MA", text: "Afonso Cunha - MA", state: "MA" },
    { value: "Afrânio - PE", text: "Afrânio - PE", state: "PE" },
    { value: "Afuá - PA", text: "Afuá - PA", state: "PA" },
    { value: "Agrestina - PE", text: "Agrestina - PE", state: "PE" },
    { value: "Agricolândia - PI", text: "Agricolândia - PI", state: "PI" },
    { value: "Agrolândia - SC", text: "Agrolândia - SC", state: "SC" },
    { value: "Agronômica - SC", text: "Agronômica - SC", state: "SC" },
    { value: "Aguanil - MG", text: "Aguanil - MG", state: "MG" },
    { value: "Aguaí - SP", text: "Aguaí - SP", state: "SP" },
    { value: "Agudo - RS", text: "Agudo - RS", state: "RS" },
    { value: "Agudos - SP", text: "Agudos - SP", state: "SP" },
    { value: "Agudos do Sul - PR", text: "Agudos do Sul - PR", state: "PR" },
    { value: "Aguiar - PB", text: "Aguiar - PB", state: "PB" },
    { value: "Aguiarnópolis - TO", text: "Aguiarnópolis - TO", state: "TO" },
    { value: "Aimorés - MG", text: "Aimorés - MG", state: "MG" },
    { value: "Aiquara - BA", text: "Aiquara - BA", state: "BA" },
    { value: "Aiuaba - CE", text: "Aiuaba - CE", state: "CE" },
    { value: "Aiuruoca - MG", text: "Aiuruoca - MG", state: "MG" },
    { value: "Ajuricaba - RS", text: "Ajuricaba - RS", state: "RS" },
    { value: "Alagoa - MG", text: "Alagoa - MG", state: "MG" },
    { value: "Alagoa Grande - PB", text: "Alagoa Grande - PB", state: "PB" },
    { value: "Alagoa Nova - PB", text: "Alagoa Nova - PB", state: "PB" },
    { value: "Alagoinha - PB", text: "Alagoinha - PB", state: "PB" },
    { value: "Alagoinha - PE", text: "Alagoinha - PE", state: "PE" },
    { value: "Alagoinha do Piauí - PI", text: "Alagoinha do Piauí - PI", state: "PI" },
    { value: "Alagoinhas - BA", text: "Alagoinhas - BA", state: "BA" },
    { value: "Alambari - SP", text: "Alambari - SP", state: "SP" },
    { value: "Albertina - MG", text: "Albertina - MG", state: "MG" },
    { value: "Alcantil - PB", text: "Alcantil - PB", state: "PB" },
    { value: "Alcinópolis - MS", text: "Alcinópolis - MS", state: "MS" },
    { value: "Alcobaça - BA", text: "Alcobaça - BA", state: "BA" },
    { value: "Alcântara - MA", text: "Alcântara - MA", state: "MA" },
    { value: "Alcântaras - CE", text: "Alcântaras - CE", state: "CE" },
    { value: "Aldeias Altas - MA", text: "Aldeias Altas - MA", state: "MA" },
    { value: "Alecrim - RS", text: "Alecrim - RS", state: "RS" },
    { value: "Alegre - ES", text: "Alegre - ES", state: "ES" },
    { value: "Alegrete - RS", text: "Alegrete - RS", state: "RS" },
    { value: "Alegrete do Piauí - PI", text: "Alegrete do Piauí - PI", state: "PI" },
    { value: "Alegria - RS", text: "Alegria - RS", state: "RS" },
    { value: "Alenquer - PA", text: "Alenquer - PA", state: "PA" },
    { value: "Alexandria - RN", text: "Alexandria - RN", state: "RN" },
    { value: "Alexânia - GO", text: "Alexânia - GO", state: "GO" },
    { value: "Alfenas - MG", text: "Alfenas - MG", state: "MG" },
    { value: "Alfredo Chaves - ES", text: "Alfredo Chaves - ES", state: "ES" },
    { value: "Alfredo Marcondes - SP", text: "Alfredo Marcondes - SP", state: "SP" },
    { value: "Alfredo Vasconcelos - MG", text: "Alfredo Vasconcelos - MG", state: "MG" },
    { value: "Alfredo Wagner - SC", text: "Alfredo Wagner - SC", state: "SC" },
    { value: "Algodão de Jandaíra - PB", text: "Algodão de Jandaíra - PB", state: "PB" },
    { value: "Alhandra - PB", text: "Alhandra - PB", state: "PB" },
    { value: "Aliança - PE", text: "Aliança - PE", state: "PE" },
    { value: "Aliança do Tocantins - TO", text: "Aliança do Tocantins - TO", state: "TO" },
    { value: "Almadina - BA", text: "Almadina - BA", state: "BA" },
    { value: "Almas - TO", text: "Almas - TO", state: "TO" },
    { value: "Almeirim - PA", text: "Almeirim - PA", state: "PA" },
    { value: "Almenara - MG", text: "Almenara - MG", state: "MG" },
    { value: "Almino Afonso - RN", text: "Almino Afonso - RN", state: "RN" },
    { value: "Almirante Tamandaré - PR", text: "Almirante Tamandaré - PR", state: "PR" },
    { value: "Almirante Tamandaré do Sul - RS", text: "Almirante Tamandaré do Sul - RS", state: "RS" },
    { value: "Aloândia - GO", text: "Aloândia - GO", state: "GO" },
    { value: "Alpercata - MG", text: "Alpercata - MG", state: "MG" },
    { value: "Alpestre - RS", text: "Alpestre - RS", state: "RS" },
    { value: "Alpinópolis - MG", text: "Alpinópolis - MG", state: "MG" },
    { value: "Alta Floresta - MT", text: "Alta Floresta - MT", state: "MT" },
    { value: "Alta Floresta d'Oeste - RO", text: "Alta Floresta d'Oeste - RO", state: "RO" },
    { value: "Altair - SP", text: "Altair - SP", state: "SP" },
    { value: "Altamira - PA", text: "Altamira - PA", state: "PA" },
    { value: "Altamira do Maranhão - MA", text: "Altamira do Maranhão - MA", state: "MA" },
    { value: "Altamira do Paraná - PR", text: "Altamira do Paraná - PR", state: "PR" },
    { value: "Altaneira - CE", text: "Altaneira - CE", state: "CE" },
    { value: "Alterosa - MG", text: "Alterosa - MG", state: "MG" },
    { value: "Altinho - PE", text: "Altinho - PE", state: "PE" },
    { value: "Altinópolis - SP", text: "Altinópolis - SP", state: "SP" },
    { value: "Alto Alegre - RR", text: "Alto Alegre - RR", state: "RR" },
    { value: "Alto Alegre - RS", text: "Alto Alegre - RS", state: "RS" },
    { value: "Alto Alegre - SP", text: "Alto Alegre - SP", state: "SP" },
    { value: "Alto Alegre do Maranhão - MA", text: "Alto Alegre do Maranhão - MA", state: "MA" },
    { value: "Alto Alegre do Parecis - RO", text: "Alto Alegre do Parecis - RO", state: "RO" },
    { value: "Alto Alegre do Pindaré - MA", text: "Alto Alegre do Pindaré - MA", state: "MA" },
    { value: "Alto Araguaia - MT", text: "Alto Araguaia - MT", state: "MT" },
    { value: "Alto Bela Vista - SC", text: "Alto Bela Vista - SC", state: "SC" },
    { value: "Alto Boa Vista - MT", text: "Alto Boa Vista - MT", state: "MT" },
    { value: "Alto Caparaó - MG", text: "Alto Caparaó - MG", state: "MG" },
    { value: "Alto Feliz - RS", text: "Alto Feliz - RS", state: "RS" },
    { value: "Alto Garças - MT", text: "Alto Garças - MT", state: "MT" },
    { value: "Alto Horizonte - GO", text: "Alto Horizonte - GO", state: "GO" },
    { value: "Alto Jequitibá - MG", text: "Alto Jequitibá - MG", state: "MG" },
    { value: "Alto Longá - PI", text: "Alto Longá - PI", state: "PI" },
    { value: "Alto Paraguai - MT", text: "Alto Paraguai - MT", state: "MT" },
    { value: "Alto Paraná - PR", text: "Alto Paraná - PR", state: "PR" },
    { value: "Alto Paraíso - RO", text: "Alto Paraíso - RO", state: "RO" },
    { value: "Alto Paraíso de Goiás - GO", text: "Alto Paraíso de Goiás - GO", state: "GO" },
    { value: "Alto Parnaíba - MA", text: "Alto Parnaíba - MA", state: "MA" },
    { value: "Alto Piquiri - PR", text: "Alto Piquiri - PR", state: "PR" },
    { value: "Alto Rio Doce - MG", text: "Alto Rio Doce - MG", state: "MG" },
    { value: "Alto Rio Novo - ES", text: "Alto Rio Novo - ES", state: "ES" },
    { value: "Alto Santo - CE", text: "Alto Santo - CE", state: "CE" },
    { value: "Alto Taquari - MT", text: "Alto Taquari - MT", state: "MT" },
    { value: "Alto do Rodrigues - RN", text: "Alto do Rodrigues - RN", state: "RN" },
    { value: "Altos - PI", text: "Altos - PI", state: "PI" },
    { value: "Altônia - PR", text: "Altônia - PR", state: "PR" },
    { value: "Alumínio - SP", text: "Alumínio - SP", state: "SP" },
    { value: "Alvarenga - MG", text: "Alvarenga - MG", state: "MG" },
    { value: "Alvarães - AM", text: "Alvarães - AM", state: "AM" },
    { value: "Alvinlândia - SP", text: "Alvinlândia - SP", state: "SP" },
    { value: "Alvinópolis - MG", text: "Alvinópolis - MG", state: "MG" },
    { value: "Alvorada - RS", text: "Alvorada - RS", state: "RS" },
    { value: "Alvorada - TO", text: "Alvorada - TO", state: "TO" },
    { value: "Alvorada d'Oeste - RO", text: "Alvorada d'Oeste - RO", state: "RO" },
    { value: "Alvorada de Minas - MG", text: "Alvorada de Minas - MG", state: "MG" },
    { value: "Alvorada do Gurguéia - PI", text: "Alvorada do Gurguéia - PI", state: "PI" },
    { value: "Alvorada do Norte - GO", text: "Alvorada do Norte - GO", state: "GO" },
    { value: "Alvorada do Sul - PR", text: "Alvorada do Sul - PR", state: "PR" },
    { value: "Além Paraíba - MG", text: "Além Paraíba - MG", state: "MG" },
    { value: "Amajari - RR", text: "Amajari - RR", state: "RR" },
    { value: "Amambaí - MS", text: "Amambaí - MS", state: "MS" },
    { value: "Amaporã - PR", text: "Amaporã - PR", state: "PR" },
    { value: "Amapá - AP", text: "Amapá - AP", state: "AP" },
    { value: "Amapá do Maranhão - MA", text: "Amapá do Maranhão - MA", state: "MA" },
    { value: "Amaraji - PE", text: "Amaraji - PE", state: "PE" },
    { value: "Amaral Ferrador - RS", text: "Amaral Ferrador - RS", state: "RS" },
    { value: "Amaralina - GO", text: "Amaralina - GO", state: "GO" },
    { value: "Amarante - PI", text: "Amarante - PI", state: "PI" },
    { value: "Amarante do Maranhão - MA", text: "Amarante do Maranhão - MA", state: "MA" },
    { value: "Amargosa - BA", text: "Amargosa - BA", state: "BA" },
    { value: "Amaturá - AM", text: "Amaturá - AM", state: "AM" },
    { value: "Americana - SP", text: "Americana - SP", state: "SP" },
    { value: "Americano do Brasil - GO", text: "Americano do Brasil - GO", state: "GO" },
    { value: "Ametista do Sul - RS", text: "Ametista do Sul - RS", state: "RS" },
    { value: "Amontada - CE", text: "Amontada - CE", state: "CE" },
    { value: "Amorinópolis - GO", text: "Amorinópolis - GO", state: "GO" },
    { value: "Amparo - PB", text: "Amparo - PB", state: "PB" },
    { value: "Amparo - SP", text: "Amparo - SP", state: "SP" },
    { value: "Amparo de São Francisco - SE", text: "Amparo de São Francisco - SE", state: "SE" },
    { value: "Amparo do Serra - MG", text: "Amparo do Serra - MG", state: "MG" },
    { value: "Ampére - PR", text: "Ampére - PR", state: "PR" },
    { value: "Amélia Rodrigues - BA", text: "Amélia Rodrigues - BA", state: "BA" },
    { value: "América Dourada - BA", text: "América Dourada - BA", state: "BA" },
    { value: "Américo Brasiliense - SP", text: "Américo Brasiliense - SP", state: "SP" },
    { value: "Américo de Campos - SP", text: "Américo de Campos - SP", state: "SP" },
    { value: "Anadia - AL", text: "Anadia - AL", state: "AL" },
    { value: "Anagé - BA", text: "Anagé - BA", state: "BA" },
    { value: "Anahy - PR", text: "Anahy - PR", state: "PR" },
    { value: "Anajatuba - MA", text: "Anajatuba - MA", state: "MA" },
    { value: "Anajás - PA", text: "Anajás - PA", state: "PA" },
    { value: "Analândia - SP", text: "Analândia - SP", state: "SP" },
    { value: "Anamã - AM", text: "Anamã - AM", state: "AM" },
    { value: "Ananindeua - PA", text: "Ananindeua - PA", state: "PA" },
    { value: "Ananás - TO", text: "Ananás - TO", state: "TO" },
    { value: "Anapu - PA", text: "Anapu - PA", state: "PA" },
    { value: "Anapurus - MA", text: "Anapurus - MA", state: "MA" },
    { value: "Anastácio - MS", text: "Anastácio - MS", state: "MS" },
    { value: "Anaurilândia - MS", text: "Anaurilândia - MS", state: "MS" },
    { value: "Anchieta - ES", text: "Anchieta - ES", state: "ES" },
    { value: "Anchieta - SC", text: "Anchieta - SC", state: "SC" },
    { value: "Andaraí - BA", text: "Andaraí - BA", state: "BA" },
    { value: "Andirá - PR", text: "Andirá - PR", state: "PR" },
    { value: "Andorinha - BA", text: "Andorinha - BA", state: "BA" },
    { value: "Andradas - MG", text: "Andradas - MG", state: "MG" },
    { value: "Andradina - SP", text: "Andradina - SP", state: "SP" },
    { value: "Andrelândia - MG", text: "Andrelândia - MG", state: "MG" },
    { value: "André da Rocha - RS", text: "André da Rocha - RS", state: "RS" },
    { value: "Angatuba - SP", text: "Angatuba - SP", state: "SP" },
    { value: "Angelim - PE", text: "Angelim - PE", state: "PE" },
    { value: "Angelina - SC", text: "Angelina - SC", state: "SC" },
    { value: "Angelândia - MG", text: "Angelândia - MG", state: "MG" },
    { value: "Angical - BA", text: "Angical - BA", state: "BA" },
    { value: "Angical do Piauí - PI", text: "Angical do Piauí - PI", state: "PI" },
    { value: "Angico - TO", text: "Angico - TO", state: "TO" },
    { value: "Angicos - RN", text: "Angicos - RN", state: "RN" },
    { value: "Angra dos Reis - RJ", text: "Angra dos Reis - RJ", state: "RJ" },
    { value: "Anguera - BA", text: "Anguera - BA", state: "BA" },
    { value: "Angélica - MS", text: "Angélica - MS", state: "MS" },
    { value: "Anhanguera - GO", text: "Anhanguera - GO", state: "GO" },
    { value: "Anhembi - SP", text: "Anhembi - SP", state: "SP" },
    { value: "Anhumas - SP", text: "Anhumas - SP", state: "SP" },
    { value: "Anicuns - GO", text: "Anicuns - GO", state: "GO" },
    { value: "Anita Garibaldi - SC", text: "Anita Garibaldi - SC", state: "SC" },
    { value: "Anitápolis - SC", text: "Anitápolis - SC", state: "SC" },
    { value: "Anori - AM", text: "Anori - AM", state: "AM" },
    { value: "Anta Gorda - RS", text: "Anta Gorda - RS", state: "RS" },
    { value: "Antas - BA", text: "Antas - BA", state: "BA" },
    { value: "Antonina - PR", text: "Antonina - PR", state: "PR" },
    { value: "Antonina do Norte - CE", text: "Antonina do Norte - CE", state: "CE" },
    { value: "Antônio Almeida - PI", text: "Antônio Almeida - PI", state: "PI" },
    { value: "Antônio Cardoso - BA", text: "Antônio Cardoso - BA", state: "BA" },
    { value: "Antônio Carlos - MG", text: "Antônio Carlos - MG", state: "MG" },
    { value: "Antônio Carlos - SC", text: "Antônio Carlos - SC", state: "SC" },
    { value: "Antônio Dias - MG", text: "Antônio Dias - MG", state: "MG" },
    { value: "Antônio Gonçalves - BA", text: "Antônio Gonçalves - BA", state: "BA" },
    { value: "Antônio João - MS", text: "Antônio João - MS", state: "MS" },
    { value: "Antônio Martins - RN", text: "Antônio Martins - RN", state: "RN" },
    { value: "Antônio Olinto - PR", text: "Antônio Olinto - PR", state: "PR" },
    { value: "Antônio Prado - RS", text: "Antônio Prado - RS", state: "RS" },
    { value: "Antônio Prado de Minas - MG", text: "Antônio Prado de Minas - MG", state: "MG" },
    { value: "Anápolis - GO", text: "Anápolis - GO", state: "GO" },
    { value: "Anísio de Abreu - PI", text: "Anísio de Abreu - PI", state: "PI" },
    { value: "Aparecida - PB", text: "Aparecida - PB", state: "PB" },
    { value: "Aparecida - SP", text: "Aparecida - SP", state: "SP" },
    { value: "Aparecida d'Oeste - SP", text: "Aparecida d'Oeste - SP", state: "SP" },
    { value: "Aparecida de Goiânia - GO", text: "Aparecida de Goiânia - GO", state: "GO" },
    { value: "Aparecida do Rio Doce - GO", text: "Aparecida do Rio Doce - GO", state: "GO" },
    { value: "Aparecida do Rio Negro - TO", text: "Aparecida do Rio Negro - TO", state: "TO" },
    { value: "Aparecida do Taboado - MS", text: "Aparecida do Taboado - MS", state: "MS" },
    { value: "Aperibé - RJ", text: "Aperibé - RJ", state: "RJ" },
    { value: "Apiacá - ES", text: "Apiacá - ES", state: "ES" },
    { value: "Apiacás - MT", text: "Apiacás - MT", state: "MT" },
    { value: "Apiaí - SP", text: "Apiaí - SP", state: "SP" },
    { value: "Apicum-Açu - MA", text: "Apicum-Açu - MA", state: "MA" },
    { value: "Apiúna - SC", text: "Apiúna - SC", state: "SC" },
    { value: "Apodi - RN", text: "Apodi - RN", state: "RN" },
    { value: "Aporá - BA", text: "Aporá - BA", state: "BA" },
    { value: "Aporé - GO", text: "Aporé - GO", state: "GO" },
    { value: "Apuarema - BA", text: "Apuarema - BA", state: "BA" },
    { value: "Apucarana - PR", text: "Apucarana - PR", state: "PR" },
    { value: "Apuiarés - CE", text: "Apuiarés - CE", state: "CE" },
    { value: "Apuí - AM", text: "Apuí - AM", state: "AM" },
    { value: "Aquidabã - SE", text: "Aquidabã - SE", state: "SE" },
    { value: "Aquidauana - MS", text: "Aquidauana - MS", state: "MS" },
    { value: "Aquiraz - CE", text: "Aquiraz - CE", state: "CE" },
    { value: "Arabutã - SC", text: "Arabutã - SC", state: "SC" },
    { value: "Aracaju - SE", text: "Aracaju - SE", state: "SE" },
    { value: "Aracati - CE", text: "Aracati - CE", state: "CE" },
    { value: "Aracatu - BA", text: "Aracatu - BA", state: "BA" },
    { value: "Araci - BA", text: "Araci - BA", state: "BA" },
    { value: "Aracitaba - MG", text: "Aracitaba - MG", state: "MG" },
    { value: "Aracoiaba - CE", text: "Aracoiaba - CE", state: "CE" },
    { value: "Aracruz - ES", text: "Aracruz - ES", state: "ES" },
    { value: "Aragarças - GO", text: "Aragarças - GO", state: "GO" },
    { value: "Aragoiânia - GO", text: "Aragoiânia - GO", state: "GO" },
    { value: "Aragominas - TO", text: "Aragominas - TO", state: "TO" },
    { value: "Araguacema - TO", text: "Araguacema - TO", state: "TO" },
    { value: "Araguaiana - MT", text: "Araguaiana - MT", state: "MT" },
    { value: "Araguainha - MT", text: "Araguainha - MT", state: "MT" },
    { value: "Araguanã - MA", text: "Araguanã - MA", state: "MA" },
    { value: "Araguanã - TO", text: "Araguanã - TO", state: "TO" },
    { value: "Araguapaz - GO", text: "Araguapaz - GO", state: "GO" },
    { value: "Araguari - MG", text: "Araguari - MG", state: "MG" },
    { value: "Araguatins - TO", text: "Araguatins - TO", state: "TO" },
    { value: "Araguaçu - TO", text: "Araguaçu - TO", state: "TO" },
    { value: "Araguaína - TO", text: "Araguaína - TO", state: "TO" },
    { value: "Araioses - MA", text: "Araioses - MA", state: "MA" },
    { value: "Aral Moreira - MS", text: "Aral Moreira - MS", state: "MS" },
    { value: "Aramari - BA", text: "Aramari - BA", state: "BA" },
    { value: "Arambaré - RS", text: "Arambaré - RS", state: "RS" },
    { value: "Arame - MA", text: "Arame - MA", state: "MA" },
    { value: "Aramina - SP", text: "Aramina - SP", state: "SP" },
    { value: "Arandu - SP", text: "Arandu - SP", state: "SP" },
    { value: "Arantina - MG", text: "Arantina - MG", state: "MG" },
    { value: "Arapeí - SP", text: "Arapeí - SP", state: "SP" },
    { value: "Arapiraca - AL", text: "Arapiraca - AL", state: "AL" },
    { value: "Arapoema - TO", text: "Arapoema - TO", state: "TO" },
    { value: "Araponga - MG", text: "Araponga - MG", state: "MG" },
    { value: "Arapongas - PR", text: "Arapongas - PR", state: "PR" },
    { value: "Araporã - MG", text: "Araporã - MG", state: "MG" },
    { value: "Arapoti - PR", text: "Arapoti - PR", state: "PR" },
    { value: "Araputanga - MT", text: "Araputanga - MT", state: "MT" },
    { value: "Arapuá - MG", text: "Arapuá - MG", state: "MG" },
    { value: "Arapuã - PR", text: "Arapuã - PR", state: "PR" },
    { value: "Araquari - SC", text: "Araquari - SC", state: "SC" },
    { value: "Arara - PB", text: "Arara - PB", state: "PB" },
    { value: "Araranguá - SC", text: "Araranguá - SC", state: "SC" },
    { value: "Araraquara - SP", text: "Araraquara - SP", state: "SP" },
    { value: "Araras - SP", text: "Araras - SP", state: "SP" },
    { value: "Ararendá - CE", text: "Ararendá - CE", state: "CE" },
    { value: "Arari - MA", text: "Arari - MA", state: "MA" },
    { value: "Araricá - RS", text: "Araricá - RS", state: "RS" },
    { value: "Araripe - CE", text: "Araripe - CE", state: "CE" },
    { value: "Araripina - PE", text: "Araripina - PE", state: "PE" },
    { value: "Araruama - RJ", text: "Araruama - RJ", state: "RJ" },
    { value: "Araruna - PB", text: "Araruna - PB", state: "PB" },
    { value: "Araruna - PR", text: "Araruna - PR", state: "PR" },
    { value: "Arataca - BA", text: "Arataca - BA", state: "BA" },
    { value: "Aratiba - RS", text: "Aratiba - RS", state: "RS" },
    { value: "Aratuba - CE", text: "Aratuba - CE", state: "CE" },
    { value: "Aratuípe - BA", text: "Aratuípe - BA", state: "BA" },
    { value: "Araucária - PR", text: "Araucária - PR", state: "PR" },
    { value: "Arauá - SE", text: "Arauá - SE", state: "SE" },
    { value: "Araxá - MG", text: "Araxá - MG", state: "MG" },
    { value: "Araçagi - PB", text: "Araçagi - PB", state: "PB" },
    { value: "Araçariguama - SP", text: "Araçariguama - SP", state: "SP" },
    { value: "Araças - BA", text: "Araças - BA", state: "BA" },
    { value: "Araçatuba - SP", text: "Araçatuba - SP", state: "SP" },
    { value: "Araçaí - MG", text: "Araçaí - MG", state: "MG" },
    { value: "Araçoiaba - PE", text: "Araçoiaba - PE", state: "PE" },
    { value: "Araçoiaba da Serra - SP", text: "Araçoiaba da Serra - SP", state: "SP" },
    { value: "Araçu - GO", text: "Araçu - GO", state: "GO" },
    { value: "Araçuaí - MG", text: "Araçuaí - MG", state: "MG" },
    { value: "Araújos - MG", text: "Araújos - MG", state: "MG" },
    { value: "Arceburgo - MG", text: "Arceburgo - MG", state: "MG" },
    { value: "Arco-Íris - SP", text: "Arco-Íris - SP", state: "SP" },
    { value: "Arcos - MG", text: "Arcos - MG", state: "MG" },
    { value: "Arcoverde - PE", text: "Arcoverde - PE", state: "PE" },
    { value: "Areado - MG", text: "Areado - MG", state: "MG" },
    { value: "Areal - RJ", text: "Areal - RJ", state: "RJ" },
    { value: "Arealva - SP", text: "Arealva - SP", state: "SP" },
    { value: "Areia - PB", text: "Areia - PB", state: "PB" },
    { value: "Areia Branca - RN", text: "Areia Branca - RN", state: "RN" },
    { value: "Areia Branca - SE", text: "Areia Branca - SE", state: "SE" },
    { value: "Areia de Baraúnas - PB", text: "Areia de Baraúnas - PB", state: "PB" },
    { value: "Areial - PB", text: "Areial - PB", state: "PB" },
    { value: "Areias - SP", text: "Areias - SP", state: "SP" },
    { value: "Areiópolis - SP", text: "Areiópolis - SP", state: "SP" },
    { value: "Arenápolis - MT", text: "Arenápolis - MT", state: "MT" },
    { value: "Arenópolis - GO", text: "Arenópolis - GO", state: "GO" },
    { value: "Argirita - MG", text: "Argirita - MG", state: "MG" },
    { value: "Aricanduva - MG", text: "Aricanduva - MG", state: "MG" },
    { value: "Arinos - MG", text: "Arinos - MG", state: "MG" },
    { value: "Aripuanã - MT", text: "Aripuanã - MT", state: "MT" },
    { value: "Ariquemes - RO", text: "Ariquemes - RO", state: "RO" },
    { value: "Ariranha - SP", text: "Ariranha - SP", state: "SP" },
    { value: "Ariranha do Ivaí - PR", text: "Ariranha do Ivaí - PR", state: "PR" },
    { value: "Armazém - SC", text: "Armazém - SC", state: "SC" },
    { value: "Armação de Búzios - RJ", text: "Armação de Búzios - RJ", state: "RJ" },
    { value: "Arneiroz - CE", text: "Arneiroz - CE", state: "CE" },
    { value: "Arniqueira - DF", text: "Arniqueira - DF", state: "DF" },
    { value: "Aroazes - PI", text: "Aroazes - PI", state: "PI" },
    { value: "Aroeiras - PB", text: "Aroeiras - PB", state: "PB" },
    { value: "Arraial - PI", text: "Arraial - PI", state: "PI" },
    { value: "Arraial do Cabo - RJ", text: "Arraial do Cabo - RJ", state: "RJ" },
    { value: "Arraias - TO", text: "Arraias - TO", state: "TO" },
    { value: "Arroio Grande - RS", text: "Arroio Grande - RS", state: "RS" },
    { value: "Arroio Trinta - SC", text: "Arroio Trinta - SC", state: "SC" },
    { value: "Arroio do Meio - RS", text: "Arroio do Meio - RS", state: "RS" },
    { value: "Arroio do Padre - RS", text: "Arroio do Padre - RS", state: "RS" },
    { value: "Arroio do Sal - RS", text: "Arroio do Sal - RS", state: "RS" },
    { value: "Arroio do Tigre - RS", text: "Arroio do Tigre - RS", state: "RS" },
    { value: "Arroio dos Ratos - RS", text: "Arroio dos Ratos - RS", state: "RS" },
    { value: "Artur Nogueira - SP", text: "Artur Nogueira - SP", state: "SP" },
    { value: "Aruanã - GO", text: "Aruanã - GO", state: "GO" },
    { value: "Arujá - SP", text: "Arujá - SP", state: "SP" },
    { value: "Arvoredo - SC", text: "Arvoredo - SC", state: "SC" },
    { value: "Arvorezinha - RS", text: "Arvorezinha - RS", state: "RS" },
    { value: "Arês - RN", text: "Arês - RN", state: "RN" },
    { value: "Ascurra - SC", text: "Ascurra - SC", state: "SC" },
    { value: "Aspásia - SP", text: "Aspásia - SP", state: "SP" },
    { value: "Assaré - CE", text: "Assaré - CE", state: "CE" },
    { value: "Assaí - PR", text: "Assaí - PR", state: "PR" },
    { value: "Assis - SP", text: "Assis - SP", state: "SP" },
    { value: "Assis Brasil - AC", text: "Assis Brasil - AC", state: "AC" },
    { value: "Assis Chateaubriand - PR", text: "Assis Chateaubriand - PR", state: "PR" },
    { value: "Assunção - PB", text: "Assunção - PB", state: "PB" },
    { value: "Assunção do Piauí - PI", text: "Assunção do Piauí - PI", state: "PI" },
    { value: "Astolfo Dutra - MG", text: "Astolfo Dutra - MG", state: "MG" },
    { value: "Astorga - PR", text: "Astorga - PR", state: "PR" },
    { value: "Atalaia - AL", text: "Atalaia - AL", state: "AL" },
    { value: "Atalaia - PR", text: "Atalaia - PR", state: "PR" },
    { value: "Atalaia do Norte - AM", text: "Atalaia do Norte - AM", state: "AM" },
    { value: "Atalanta - SC", text: "Atalanta - SC", state: "SC" },
    { value: "Ataléia - MG", text: "Ataléia - MG", state: "MG" },
    { value: "Atibaia - SP", text: "Atibaia - SP", state: "SP" },
    { value: "Atilio Vivacqua - ES", text: "Atilio Vivacqua - ES", state: "ES" },
    { value: "Augustinópolis - TO", text: "Augustinópolis - TO", state: "TO" },
    { value: "Augusto Corrêa - PA", text: "Augusto Corrêa - PA", state: "PA" },
    { value: "Augusto Pestana - RS", text: "Augusto Pestana - RS", state: "RS" },
    { value: "Augusto Severo - RN", text: "Augusto Severo - RN", state: "RN" },
    { value: "Augusto de Lima - MG", text: "Augusto de Lima - MG", state: "MG" },
    { value: "Aurelino Leal - BA", text: "Aurelino Leal - BA", state: "BA" },
    { value: "Auriflama - SP", text: "Auriflama - SP", state: "SP" },
    { value: "Aurilândia - GO", text: "Aurilândia - GO", state: "GO" },
    { value: "Aurora - CE", text: "Aurora - CE", state: "CE" },
    { value: "Aurora - SC", text: "Aurora - SC", state: "SC" },
    { value: "Aurora do Pará - PA", text: "Aurora do Pará - PA", state: "PA" },
    { value: "Aurora do Tocantins - TO", text: "Aurora do Tocantins - TO", state: "TO" },
    { value: "Autazes - AM", text: "Autazes - AM", state: "AM" },
    { value: "Avanhandava - SP", text: "Avanhandava - SP", state: "SP" },
    { value: "Avaré - SP", text: "Avaré - SP", state: "SP" },
    { value: "Avaí - SP", text: "Avaí - SP", state: "SP" },
    { value: "Aveiro - PA", text: "Aveiro - PA", state: "PA" },
    { value: "Avelino Lopes - PI", text: "Avelino Lopes - PI", state: "PI" },
    { value: "Avelinópolis - GO", text: "Avelinópolis - GO", state: "GO" },
    { value: "Axixá - MA", text: "Axixá - MA", state: "MA" },
    { value: "Axixá do Tocantins - TO", text: "Axixá do Tocantins - TO", state: "TO" },
    { value: "Açailândia - MA", text: "Açailândia - MA", state: "MA" },
    { value: "Açu - RN", text: "Açu - RN", state: "RN" },
    { value: "Açucena - MG", text: "Açucena - MG", state: "MG" },
    { value: "Babaçulândia - TO", text: "Babaçulândia - TO", state: "TO" },
    { value: "Bacabal - MA", text: "Bacabal - MA", state: "MA" },
    { value: "Bacabeira - MA", text: "Bacabeira - MA", state: "MA" },
    { value: "Bacuri - MA", text: "Bacuri - MA", state: "MA" },
    { value: "Bacurituba - MA", text: "Bacurituba - MA", state: "MA" },
    { value: "Bady Bassitt - SP", text: "Bady Bassitt - SP", state: "SP" },
    { value: "Baependi - MG", text: "Baependi - MG", state: "MG" },
    { value: "Bagre - PA", text: "Bagre - PA", state: "PA" },
    { value: "Bagé - RS", text: "Bagé - RS", state: "RS" },
    { value: "Baianópolis - BA", text: "Baianópolis - BA", state: "BA" },
    { value: "Baixa Grande - BA", text: "Baixa Grande - BA", state: "BA" },
    { value: "Baixa Grande do Ribeiro - PI", text: "Baixa Grande do Ribeiro - PI", state: "PI" },
    { value: "Baixio - CE", text: "Baixio - CE", state: "CE" },
    { value: "Baixo Guandu - ES", text: "Baixo Guandu - ES", state: "ES" },
    { value: "Baião - PA", text: "Baião - PA", state: "PA" },
    { value: "Balbinos - SP", text: "Balbinos - SP", state: "SP" },
    { value: "Baldim - MG", text: "Baldim - MG", state: "MG" },
    { value: "Baliza - GO", text: "Baliza - GO", state: "GO" },
    { value: "Balneário Arroio do Silva - SC", text: "Balneário Arroio do Silva - SC", state: "SC" },
    { value: "Balneário Barra do Sul - SC", text: "Balneário Barra do Sul - SC", state: "SC" },
    { value: "Balneário Camboriú - SC", text: "Balneário Camboriú - SC", state: "SC" },
    { value: "Balneário Gaivota - SC", text: "Balneário Gaivota - SC", state: "SC" },
    { value: "Balneário Pinhal - RS", text: "Balneário Pinhal - RS", state: "RS" },
    { value: "Balsa Nova - PR", text: "Balsa Nova - PR", state: "PR" },
    { value: "Balsas - MA", text: "Balsas - MA", state: "MA" },
    { value: "Bambuí - MG", text: "Bambuí - MG", state: "MG" },
    { value: "Banabuiú - CE", text: "Banabuiú - CE", state: "CE" },
    { value: "Bananal - SP", text: "Bananal - SP", state: "SP" },
    { value: "Bananeiras - PB", text: "Bananeiras - PB", state: "PB" },
    { value: "Bandeira - MG", text: "Bandeira - MG", state: "MG" },
    { value: "Bandeira do Sul - MG", text: "Bandeira do Sul - MG", state: "MG" },
    { value: "Bandeirante - SC", text: "Bandeirante - SC", state: "SC" },
    { value: "Bandeirantes - MS", text: "Bandeirantes - MS", state: "MS" },
    { value: "Bandeirantes - PR", text: "Bandeirantes - PR", state: "PR" },
    { value: "Bandeirantes do Tocantins - TO", text: "Bandeirantes do Tocantins - TO", state: "TO" },
    { value: "Bannach - PA", text: "Bannach - PA", state: "PA" },
    { value: "Banzaê - BA", text: "Banzaê - BA", state: "BA" },
    { value: "Baraúna - PB", text: "Baraúna - PB", state: "PB" },
    { value: "Baraúna - RN", text: "Baraúna - RN", state: "RN" },
    { value: "Barbacena - MG", text: "Barbacena - MG", state: "MG" },
    { value: "Barbalha - CE", text: "Barbalha - CE", state: "CE" },
    { value: "Barbosa - SP", text: "Barbosa - SP", state: "SP" },
    { value: "Barbosa Ferraz - PR", text: "Barbosa Ferraz - PR", state: "PR" },
    { value: "Barcarena - PA", text: "Barcarena - PA", state: "PA" },
    { value: "Barcelona - RN", text: "Barcelona - RN", state: "RN" },
    { value: "Barcelos - AM", text: "Barcelos - AM", state: "AM" },
    { value: "Bariri - SP", text: "Bariri - SP", state: "SP" },
    { value: "Barra - BA", text: "Barra - BA", state: "BA" },
    { value: "Barra Bonita - SC", text: "Barra Bonita - SC", state: "SC" },
    { value: "Barra Bonita - SP", text: "Barra Bonita - SP", state: "SP" },
    { value: "Barra Funda - RS", text: "Barra Funda - RS", state: "RS" },
    { value: "Barra Longa - MG", text: "Barra Longa - MG", state: "MG" },
    { value: "Barra Mansa - RJ", text: "Barra Mansa - RJ", state: "RJ" },
    { value: "Barra Velha - SC", text: "Barra Velha - SC", state: "SC" },
    { value: "Barra d'Alcântara - PI", text: "Barra d'Alcântara - PI", state: "PI" },
    { value: "Barra da Estiva - BA", text: "Barra da Estiva - BA", state: "BA" },
    { value: "Barra de Guabiraba - PE", text: "Barra de Guabiraba - PE", state: "PE" },
    { value: "Barra de Santa Rosa - PB", text: "Barra de Santa Rosa - PB", state: "PB" },
    { value: "Barra de Santana - PB", text: "Barra de Santana - PB", state: "PB" },
    { value: "Barra de Santo Antônio - AL", text: "Barra de Santo Antônio - AL", state: "AL" },
    { value: "Barra de São Francisco - ES", text: "Barra de São Francisco - ES", state: "ES" },
    { value: "Barra de São Miguel - AL", text: "Barra de São Miguel - AL", state: "AL" },
    { value: "Barra de São Miguel - PB", text: "Barra de São Miguel - PB", state: "PB" },
    { value: "Barra do Bugres - MT", text: "Barra do Bugres - MT", state: "MT" },
    { value: "Barra do Chapéu - SP", text: "Barra do Chapéu - SP", state: "SP" },
    { value: "Barra do Choça - BA", text: "Barra do Choça - BA", state: "BA" },
    { value: "Barra do Corda - MA", text: "Barra do Corda - MA", state: "MA" },
    { value: "Barra do Garças - MT", text: "Barra do Garças - MT", state: "MT" },
    { value: "Barra do Guarita - RS", text: "Barra do Guarita - RS", state: "RS" },
    { value: "Barra do Jacaré - PR", text: "Barra do Jacaré - PR", state: "PR" },
    { value: "Barra do Mendes - BA", text: "Barra do Mendes - BA", state: "BA" },
    { value: "Barra do Ouro - TO", text: "Barra do Ouro - TO", state: "TO" },
    { value: "Barra do Piraí - RJ", text: "Barra do Piraí - RJ", state: "RJ" },
    { value: "Barra do Quaraí - RS", text: "Barra do Quaraí - RS", state: "RS" },
    { value: "Barra do Ribeiro - RS", text: "Barra do Ribeiro - RS", state: "RS" },
    { value: "Barra do Rio Azul - RS", text: "Barra do Rio Azul - RS", state: "RS" },
    { value: "Barra do Rocha - BA", text: "Barra do Rocha - BA", state: "BA" },
    { value: "Barra do Turvo - SP", text: "Barra do Turvo - SP", state: "SP" },
    { value: "Barra dos Coqueiros - SE", text: "Barra dos Coqueiros - SE", state: "SE" },
    { value: "Barracão - PR", text: "Barracão - PR", state: "PR" },
    { value: "Barracão - RS", text: "Barracão - RS", state: "RS" },
    { value: "Barras - PI", text: "Barras - PI", state: "PI" },
    { value: "Barreira - CE", text: "Barreira - CE", state: "CE" },
    { value: "Barreiras - BA", text: "Barreiras - BA", state: "BA" },
    { value: "Barreiras do Piauí - PI", text: "Barreiras do Piauí - PI", state: "PI" },
    { value: "Barreirinha - AM", text: "Barreirinha - AM", state: "AM" },
    { value: "Barreirinhas - MA", text: "Barreirinhas - MA", state: "MA" },
    { value: "Barreiros - PE", text: "Barreiros - PE", state: "PE" },
    { value: "Barretos - SP", text: "Barretos - SP", state: "SP" },
    { value: "Barrinha - SP", text: "Barrinha - SP", state: "SP" },
    { value: "Barro - CE", text: "Barro - CE", state: "CE" },
    { value: "Barro Alto - BA", text: "Barro Alto - BA", state: "BA" },
    { value: "Barro Alto - GO", text: "Barro Alto - GO", state: "GO" },
    { value: "Barro Duro - PI", text: "Barro Duro - PI", state: "PI" },
    { value: "Barro Preto - BA", text: "Barro Preto - BA", state: "BA" },
    { value: "Barrocas - BA", text: "Barrocas - BA", state: "BA" },
    { value: "Barrolândia - TO", text: "Barrolândia - TO", state: "TO" },
    { value: "Barroquinha - CE", text: "Barroquinha - CE", state: "CE" },
    { value: "Barros Cassal - RS", text: "Barros Cassal - RS", state: "RS" },
    { value: "Barroso - MG", text: "Barroso - MG", state: "MG" },
    { value: "Barueri - SP", text: "Barueri - SP", state: "SP" },
    { value: "Barão - RS", text: "Barão - RS", state: "RS" },
    { value: "Barão de Antonina - SP", text: "Barão de Antonina - SP", state: "SP" },
    { value: "Barão de Cocais - MG", text: "Barão de Cocais - MG", state: "MG" },
    { value: "Barão de Cotegipe - RS", text: "Barão de Cotegipe - RS", state: "RS" },
    { value: "Barão de Grajaú - MA", text: "Barão de Grajaú - MA", state: "MA" },
    { value: "Barão de Melgaço - MT", text: "Barão de Melgaço - MT", state: "MT" },
    { value: "Barão de Monte Alto - MG", text: "Barão de Monte Alto - MG", state: "MG" },
    { value: "Barão do Triunfo - RS", text: "Barão do Triunfo - RS", state: "RS" },
    { value: "Bastos - SP", text: "Bastos - SP", state: "SP" },
    { value: "Bataguassu - MS", text: "Bataguassu - MS", state: "MS" },
    { value: "Bataiporã - MS", text: "Bataiporã - MS", state: "MS" },
    { value: "Batalha - AL", text: "Batalha - AL", state: "AL" },
    { value: "Batalha - PI", text: "Batalha - PI", state: "PI" },
    { value: "Batatais - SP", text: "Batatais - SP", state: "SP" },
    { value: "Baturité - CE", text: "Baturité - CE", state: "CE" },
    { value: "Bauru - SP", text: "Bauru - SP", state: "SP" },
    { value: "Bayeux - PB", text: "Bayeux - PB", state: "PB" },
    { value: "Baía Formosa - RN", text: "Baía Formosa - RN", state: "RN" },
    { value: "Baía da Traição - PB", text: "Baía da Traição - PB", state: "PB" },
    { value: "Bebedouro - SP", text: "Bebedouro - SP", state: "SP" },
    { value: "Beberibe - CE", text: "Beberibe - CE", state: "CE" },
    { value: "Bela Cruz - CE", text: "Bela Cruz - CE", state: "CE" },
    { value: "Bela Vista - MS", text: "Bela Vista - MS", state: "MS" },
    { value: "Bela Vista da Caroba - PR", text: "Bela Vista da Caroba - PR", state: "PR" },
    { value: "Bela Vista de Goiás - GO", text: "Bela Vista de Goiás - GO", state: "GO" },
    { value: "Bela Vista de Minas - MG", text: "Bela Vista de Minas - MG", state: "MG" },
    { value: "Bela Vista do Maranhão - MA", text: "Bela Vista do Maranhão - MA", state: "MA" },
    { value: "Bela Vista do Paraíso - PR", text: "Bela Vista do Paraíso - PR", state: "PR" },
    { value: "Bela Vista do Piauí - PI", text: "Bela Vista do Piauí - PI", state: "PI" },
    { value: "Bela Vista do Toldo - SC", text: "Bela Vista do Toldo - SC", state: "SC" },
    { value: "Belford Roxo - RJ", text: "Belford Roxo - RJ", state: "RJ" },
    { value: "Belmiro Braga - MG", text: "Belmiro Braga - MG", state: "MG" },
    { value: "Belmonte - BA", text: "Belmonte - BA", state: "BA" },
    { value: "Belmonte - SC", text: "Belmonte - SC", state: "SC" },
    { value: "Belo Campo - BA", text: "Belo Campo - BA", state: "BA" },
    { value: "Belo Horizonte - MG", text: "Belo Horizonte - MG", state: "MG" },
    { value: "Belo Jardim - PE", text: "Belo Jardim - PE", state: "PE" },
    { value: "Belo Monte - AL", text: "Belo Monte - AL", state: "AL" },
    { value: "Belo Oriente - MG", text: "Belo Oriente - MG", state: "MG" },
    { value: "Belo Vale - MG", text: "Belo Vale - MG", state: "MG" },
    { value: "Belterra - PA", text: "Belterra - PA", state: "PA" },
    { value: "Belágua - MA", text: "Belágua - MA", state: "MA" },
    { value: "Belém - AL", text: "Belém - AL", state: "AL" },
    { value: "Belém - PA", text: "Belém - PA", state: "PA" },
    { value: "Belém - PB", text: "Belém - PB", state: "PB" },
    { value: "Belém de Maria - PE", text: "Belém de Maria - PE", state: "PE" },
    { value: "Belém de São Francisco - PE", text: "Belém de São Francisco - PE", state: "PE" },
    { value: "Belém do Brejo do Cruz - PB", text: "Belém do Brejo do Cruz - PB", state: "PB" },
    { value: "Belém do Piauí - PI", text: "Belém do Piauí - PI", state: "PI" },
    { value: "Beneditinos - PI", text: "Beneditinos - PI", state: "PI" },
    { value: "Benedito Leite - MA", text: "Benedito Leite - MA", state: "MA" },
    { value: "Benedito Novo - SC", text: "Benedito Novo - SC", state: "SC" },
    { value: "Benevides - PA", text: "Benevides - PA", state: "PA" },
    { value: "Benjamin Constan do Sul - RS", text: "Benjamin Constan do Sul - RS", state: "RS" },
    { value: "Benjamin Constant - AM", text: "Benjamin Constant - AM", state: "AM" },
    { value: "Bento Fernandes - RN", text: "Bento Fernandes - RN", state: "RN" },
    { value: "Bento Gonçalves - RS", text: "Bento Gonçalves - RS", state: "RS" },
    { value: "Bento de Abreu - SP", text: "Bento de Abreu - SP", state: "SP" },
    { value: "Bequimão - MA", text: "Bequimão - MA", state: "MA" },
    { value: "Berilo - MG", text: "Berilo - MG", state: "MG" },
    { value: "Berizal - MG", text: "Berizal - MG", state: "MG" },
    { value: "Bernardino Batista - PB", text: "Bernardino Batista - PB", state: "PB" },
    { value: "Bernardino de Campos - SP", text: "Bernardino de Campos - SP", state: "SP" },
    { value: "Bernardo Sayão - TO", text: "Bernardo Sayão - TO", state: "TO" },
    { value: "Bernardo do Mearim - MA", text: "Bernardo do Mearim - MA", state: "MA" },
    { value: "Bertioga - SP", text: "Bertioga - SP", state: "SP" },
    { value: "Bertolínia - PI", text: "Bertolínia - PI", state: "PI" },
    { value: "Bertópolis - MG", text: "Bertópolis - MG", state: "MG" },
    { value: "Beruri - AM", text: "Beruri - AM", state: "AM" },
    { value: "Betim - MG", text: "Betim - MG", state: "MG" },
    { value: "Betânia - PE", text: "Betânia - PE", state: "PE" },
    { value: "Betânia do Piauí - PI", text: "Betânia do Piauí - PI", state: "PI" },
    { value: "Bezerros - PE", text: "Bezerros - PE", state: "PE" },
    { value: "Bias Fortes - MG", text: "Bias Fortes - MG", state: "MG" },
    { value: "Bicas - MG", text: "Bicas - MG", state: "MG" },
    { value: "Biguaçu - SC", text: "Biguaçu - SC", state: "SC" },
    { value: "Bilac - SP", text: "Bilac - SP", state: "SP" },
    { value: "Biquinhas - MG", text: "Biquinhas - MG", state: "MG" },
    { value: "Birigui - SP", text: "Birigui - SP", state: "SP" },
    { value: "Biritiba-Mirim - SP", text: "Biritiba-Mirim - SP", state: "SP" },
    { value: "Biritinga - BA", text: "Biritinga - BA", state: "BA" },
    { value: "Bituruna - PR", text: "Bituruna - PR", state: "PR" },
    { value: "Blumenau - SC", text: "Blumenau - SC", state: "SC" },
    { value: "Boa Esperança - ES", text: "Boa Esperança - ES", state: "ES" },
    { value: "Boa Esperança - MG", text: "Boa Esperança - MG", state: "MG" },
    { value: "Boa Esperança - PR", text: "Boa Esperança - PR", state: "PR" },
    { value: "Boa Esperança do Iguaçu - PR", text: "Boa Esperança do Iguaçu - PR", state: "PR" },
    { value: "Boa Esperança do Sul - SP", text: "Boa Esperança do Sul - SP", state: "SP" },
    { value: "Boa Hora - PI", text: "Boa Hora - PI", state: "PI" },
    { value: "Boa Nova - BA", text: "Boa Nova - BA", state: "BA" },
    { value: "Boa Ventura - PB", text: "Boa Ventura - PB", state: "PB" },
    { value: "Boa Ventura de São Roque - PR", text: "Boa Ventura de São Roque - PR", state: "PR" },
    { value: "Boa Viagem - CE", text: "Boa Viagem - CE", state: "CE" },
    { value: "Boa Vista - PB", text: "Boa Vista - PB", state: "PB" },
    { value: "Boa Vista - RR", text: "Boa Vista - RR", state: "RR" },
    { value: "Boa Vista da Aparecida - PR", text: "Boa Vista da Aparecida - PR", state: "PR" },
    { value: "Boa Vista das Missões - RS", text: "Boa Vista das Missões - RS", state: "RS" },
    { value: "Boa Vista do Buricá - RS", text: "Boa Vista do Buricá - RS", state: "RS" },
    { value: "Boa Vista do Cadeado - RS", text: "Boa Vista do Cadeado - RS", state: "RS" },
    { value: "Boa Vista do Gurupi - MA", text: "Boa Vista do Gurupi - MA", state: "MA" },
    { value: "Boa Vista do Incra - RS", text: "Boa Vista do Incra - RS", state: "RS" },
    { value: "Boa Vista do Ramos - AM", text: "Boa Vista do Ramos - AM", state: "AM" },
    { value: "Boa Vista do Sul - RS", text: "Boa Vista do Sul - RS", state: "RS" },
    { value: "Boa Vista do Tupim - BA", text: "Boa Vista do Tupim - BA", state: "BA" },
    { value: "Boca da Mata - AL", text: "Boca da Mata - AL", state: "AL" },
    { value: "Boca do Acre - AM", text: "Boca do Acre - AM", state: "AM" },
    { value: "Bocaina - PI", text: "Bocaina - PI", state: "PI" },
    { value: "Bocaina - SP", text: "Bocaina - SP", state: "SP" },
    { value: "Bocaina de Minas - MG", text: "Bocaina de Minas - MG", state: "MG" },
    { value: "Bocaina do Sul - SC", text: "Bocaina do Sul - SC", state: "SC" },
    { value: "Bocaiúva - MG", text: "Bocaiúva - MG", state: "MG" },
    { value: "Bocaiúva do Sul - PR", text: "Bocaiúva do Sul - PR", state: "PR" },
    { value: "Bodocó - PE", text: "Bodocó - PE", state: "PE" },
    { value: "Bodoquena - MS", text: "Bodoquena - MS", state: "MS" },
    { value: "Bodó - RN", text: "Bodó - RN", state: "RN" },
    { value: "Bofete - SP", text: "Bofete - SP", state: "SP" },
    { value: "Boituva - SP", text: "Boituva - SP", state: "SP" },
    { value: "Bom Conselho - PE", text: "Bom Conselho - PE", state: "PE" },
    { value: "Bom Despacho - MG", text: "Bom Despacho - MG", state: "MG" },
    { value: "Bom Jardim - MA", text: "Bom Jardim - MA", state: "MA" },
    { value: "Bom Jardim - PE", text: "Bom Jardim - PE", state: "PE" },
    { value: "Bom Jardim - RJ", text: "Bom Jardim - RJ", state: "RJ" },
    { value: "Bom Jardim da Serra - SC", text: "Bom Jardim da Serra - SC", state: "SC" },
    { value: "Bom Jardim de Goiás - GO", text: "Bom Jardim de Goiás - GO", state: "GO" },
    { value: "Bom Jardim de Minas - MG", text: "Bom Jardim de Minas - MG", state: "MG" },
    { value: "Bom Jesus - PB", text: "Bom Jesus - PB", state: "PB" },
    { value: "Bom Jesus - PI", text: "Bom Jesus - PI", state: "PI" },
    { value: "Bom Jesus - RN", text: "Bom Jesus - RN", state: "RN" },
    { value: "Bom Jesus - RS", text: "Bom Jesus - RS", state: "RS" },
    { value: "Bom Jesus - SC", text: "Bom Jesus - SC", state: "SC" },
    { value: "Bom Jesus da Lapa - BA", text: "Bom Jesus da Lapa - BA", state: "BA" },
    { value: "Bom Jesus da Penha - MG", text: "Bom Jesus da Penha - MG", state: "MG" },
    { value: "Bom Jesus da Serra - BA", text: "Bom Jesus da Serra - BA", state: "BA" },
    { value: "Bom Jesus das Selvas - MA", text: "Bom Jesus das Selvas - MA", state: "MA" },
    { value: "Bom Jesus de Goiás - GO", text: "Bom Jesus de Goiás - GO", state: "GO" },
    { value: "Bom Jesus do Amparo - MG", text: "Bom Jesus do Amparo - MG", state: "MG" },
    { value: "Bom Jesus do Araguaia - MT", text: "Bom Jesus do Araguaia - MT", state: "MT" },
    { value: "Bom Jesus do Galho - MG", text: "Bom Jesus do Galho - MG", state: "MG" },
    { value: "Bom Jesus do Itabapoana - RJ", text: "Bom Jesus do Itabapoana - RJ", state: "RJ" },
    { value: "Bom Jesus do Norte - ES", text: "Bom Jesus do Norte - ES", state: "ES" },
    { value: "Bom Jesus do Oeste - SC", text: "Bom Jesus do Oeste - SC", state: "SC" },
    { value: "Bom Jesus do Sul - PR", text: "Bom Jesus do Sul - PR", state: "PR" },
    { value: "Bom Jesus do Tocantins - PA", text: "Bom Jesus do Tocantins - PA", state: "PA" },
    { value: "Bom Jesus do Tocantins - TO", text: "Bom Jesus do Tocantins - TO", state: "TO" },
    { value: "Bom Jesus dos Perdões - SP", text: "Bom Jesus dos Perdões - SP", state: "SP" },
    { value: "Bom Lugar - MA", text: "Bom Lugar - MA", state: "MA" },
    { value: "Bom Princípio - RS", text: "Bom Princípio - RS", state: "RS" },
    { value: "Bom Princípio do Piauí - PI", text: "Bom Princípio do Piauí - PI", state: "PI" },
    { value: "Bom Progresso - RS", text: "Bom Progresso - RS", state: "RS" },
    { value: "Bom Repouso - MG", text: "Bom Repouso - MG", state: "MG" },
    { value: "Bom Retiro - SC", text: "Bom Retiro - SC", state: "SC" },
    { value: "Bom Retiro do Sul - RS", text: "Bom Retiro do Sul - RS", state: "RS" },
    { value: "Bom Sucesso - MG", text: "Bom Sucesso - MG", state: "MG" },
    { value: "Bom Sucesso - PB", text: "Bom Sucesso - PB", state: "PB" },
    { value: "Bom Sucesso - PR", text: "Bom Sucesso - PR", state: "PR" },
    { value: "Bom Sucesso de Itararé - SP", text: "Bom Sucesso de Itararé - SP", state: "SP" },
    { value: "Bom Sucesso do Sul - PR", text: "Bom Sucesso do Sul - PR", state: "PR" },
    { value: "Bombinhas - SC", text: "Bombinhas - SC", state: "SC" },
    { value: "Bonfim - MG", text: "Bonfim - MG", state: "MG" },
    { value: "Bonfim - RR", text: "Bonfim - RR", state: "RR" },
    { value: "Bonfim do Piauí - PI", text: "Bonfim do Piauí - PI", state: "PI" },
    { value: "Bonfinópolis - GO", text: "Bonfinópolis - GO", state: "GO" },
    { value: "Bonfinópolis de Minas - MG", text: "Bonfinópolis de Minas - MG", state: "MG" },
    { value: "Boninal - BA", text: "Boninal - BA", state: "BA" },
    { value: "Bonito - BA", text: "Bonito - BA", state: "BA" },
    { value: "Bonito - MS", text: "Bonito - MS", state: "MS" },
    { value: "Bonito - PA", text: "Bonito - PA", state: "PA" },
    { value: "Bonito - PE", text: "Bonito - PE", state: "PE" },
    { value: "Bonito de Minas - MG", text: "Bonito de Minas - MG", state: "MG" },
    { value: "Bonito de Santa Fé - PB", text: "Bonito de Santa Fé - PB", state: "PB" },
    { value: "Bonópolis - GO", text: "Bonópolis - GO", state: "GO" },
    { value: "Boqueirão - PB", text: "Boqueirão - PB", state: "PB" },
    { value: "Boqueirão do Leão - RS", text: "Boqueirão do Leão - RS", state: "RS" },
    { value: "Boqueirão do Piauí - PI", text: "Boqueirão do Piauí - PI", state: "PI" },
    { value: "Boquim - SE", text: "Boquim - SE", state: "SE" },
    { value: "Boquira - BA", text: "Boquira - BA", state: "BA" },
    { value: "Boracéia - SP", text: "Boracéia - SP", state: "SP" },
    { value: "Borba - AM", text: "Borba - AM", state: "AM" },
    { value: "Borborema - PB", text: "Borborema - PB", state: "PB" },
    { value: "Borborema - SP", text: "Borborema - SP", state: "SP" },
    { value: "Borda da Mata - MG", text: "Borda da Mata - MG", state: "MG" },
    { value: "Borebi - SP", text: "Borebi - SP", state: "SP" },
    { value: "Borrazópolis - PR", text: "Borrazópolis - PR", state: "PR" },
    { value: "Borá - SP", text: "Borá - SP", state: "SP" },
    { value: "Bossoroca - RS", text: "Bossoroca - RS", state: "RS" },
    { value: "Botelhos - MG", text: "Botelhos - MG", state: "MG" },
    { value: "Botucatu - SP", text: "Botucatu - SP", state: "SP" },
    { value: "Botumirim - MG", text: "Botumirim - MG", state: "MG" },
    { value: "Botuporã - BA", text: "Botuporã - BA", state: "BA" },
    { value: "Botuverá - SC", text: "Botuverá - SC", state: "SC" },
    { value: "Bozano - RS", text: "Bozano - RS", state: "RS" },
    { value: "Braga - RS", text: "Braga - RS", state: "RS" },
    { value: "Braganey - PR", text: "Braganey - PR", state: "PR" },
    { value: "Bragança - PA", text: "Bragança - PA", state: "PA" },
    { value: "Bragança Paulista - SP", text: "Bragança Paulista - SP", state: "SP" },
    { value: "Branquinha - AL", text: "Branquinha - AL", state: "AL" },
    { value: "Brasil Novo - PA", text: "Brasil Novo - PA", state: "PA" },
    { value: "Brasileira - PI", text: "Brasileira - PI", state: "PI" },
    { value: "Brasilândia - MS", text: "Brasilândia - MS", state: "MS" },
    { value: "Brasilândia de Minas - MG", text: "Brasilândia de Minas - MG", state: "MG" },
    { value: "Brasilândia do Sul - PR", text: "Brasilândia do Sul - PR", state: "PR" },
    { value: "Brasilândia do Tocantins - TO", text: "Brasilândia do Tocantins - TO", state: "TO" },
    { value: "Brasiléia - AC", text: "Brasiléia - AC", state: "AC" },
    { value: "Brasnorte - MT", text: "Brasnorte - MT", state: "MT" },
    { value: "Brasília - DF", text: "Brasília - DF", state: "DF" },
    { value: "Brasília de Minas - MG", text: "Brasília de Minas - MG", state: "MG" },
    { value: "Brasópolis - MG", text: "Brasópolis - MG", state: "MG" },
    { value: "Brazabrantes - GO", text: "Brazabrantes - GO", state: "GO" },
    { value: "Brazlândia - DF", text: "Brazlândia - DF", state: "DF" },
    { value: "Braço do Norte - SC", text: "Braço do Norte - SC", state: "SC" },
    { value: "Braço do Trombudo - SC", text: "Braço do Trombudo - SC", state: "SC" },
    { value: "Braúna - SP", text: "Braúna - SP", state: "SP" },
    { value: "Braúnas - MG", text: "Braúnas - MG", state: "MG" },
    { value: "Brejetuba - ES", text: "Brejetuba - ES", state: "ES" },
    { value: "Brejinho - PE", text: "Brejinho - PE", state: "PE" },
    { value: "Brejinho - RN", text: "Brejinho - RN", state: "RN" },
    { value: "Brejinho de Nazaré - TO", text: "Brejinho de Nazaré - TO", state: "TO" },
    { value: "Brejo - MA", text: "Brejo - MA", state: "MA" },
    { value: "Brejo Alegre - SP", text: "Brejo Alegre - SP", state: "SP" },
    { value: "Brejo Grande - SE", text: "Brejo Grande - SE", state: "SE" },
    { value: "Brejo Grande do Araguaia - PA", text: "Brejo Grande do Araguaia - PA", state: "PA" },
    { value: "Brejo Santo - CE", text: "Brejo Santo - CE", state: "CE" },
    { value: "Brejo da Madre de Deus - PE", text: "Brejo da Madre de Deus - PE", state: "PE" },
    { value: "Brejo de Areia - MA", text: "Brejo de Areia - MA", state: "MA" },
    { value: "Brejo do Cruz - PB", text: "Brejo do Cruz - PB", state: "PB" },
    { value: "Brejo do Piauí - PI", text: "Brejo do Piauí - PI", state: "PI" },
    { value: "Brejo dos Santos - PB", text: "Brejo dos Santos - PB", state: "PB" },
    { value: "Brejolândia - BA", text: "Brejolândia - BA", state: "BA" },
    { value: "Brejão - PE", text: "Brejão - PE", state: "PE" },
    { value: "Brejões - BA", text: "Brejões - BA", state: "BA" },
    { value: "Breu Branco - PA", text: "Breu Branco - PA", state: "PA" },
    { value: "Breves - PA", text: "Breves - PA", state: "PA" },
    { value: "Britânia - GO", text: "Britânia - GO", state: "GO" },
    { value: "Brochier - RS", text: "Brochier - RS", state: "RS" },
    { value: "Brodowski - SP", text: "Brodowski - SP", state: "SP" },
    { value: "Brotas - SP", text: "Brotas - SP", state: "SP" },
    { value: "Brotas de Macaúbas - BA", text: "Brotas de Macaúbas - BA", state: "BA" },
    { value: "Brumadinho - MG", text: "Brumadinho - MG", state: "MG" },
    { value: "Brumado - BA", text: "Brumado - BA", state: "BA" },
    { value: "Brunópolis - SC", text: "Brunópolis - SC", state: "SC" },
    { value: "Brusque - SC", text: "Brusque - SC", state: "SC" },
    { value: "Brás Pires - MG", text: "Brás Pires - MG", state: "MG" },
    { value: "Bueno Brandão - MG", text: "Bueno Brandão - MG", state: "MG" },
    { value: "Buenos Aires - PE", text: "Buenos Aires - PE", state: "PE" },
    { value: "Buenópolis - MG", text: "Buenópolis - MG", state: "MG" },
    { value: "Buerarema - BA", text: "Buerarema - BA", state: "BA" },
    { value: "Bugre - MG", text: "Bugre - MG", state: "MG" },
    { value: "Bujari - AC", text: "Bujari - AC", state: "AC" },
    { value: "Bujaru - PA", text: "Bujaru - PA", state: "PA" },
    { value: "Buri - SP", text: "Buri - SP", state: "SP" },
    { value: "Buritama - SP", text: "Buritama - SP", state: "SP" },
    { value: "Buriti - MA", text: "Buriti - MA", state: "MA" },
    { value: "Buriti Alegre - GO", text: "Buriti Alegre - GO", state: "GO" },
    { value: "Buriti Bravo - MA", text: "Buriti Bravo - MA", state: "MA" },
    { value: "Buriti de Goiás - GO", text: "Buriti de Goiás - GO", state: "GO" },
    { value: "Buriti do Tocantins - TO", text: "Buriti do Tocantins - TO", state: "TO" },
    { value: "Buriti dos Lopes - PI", text: "Buriti dos Lopes - PI", state: "PI" },
    { value: "Buriti dos Montes - PI", text: "Buriti dos Montes - PI", state: "PI" },
    { value: "Buriticupu - MA", text: "Buriticupu - MA", state: "MA" },
    { value: "Buritinópolis - GO", text: "Buritinópolis - GO", state: "GO" },
    { value: "Buritirama - BA", text: "Buritirama - BA", state: "BA" },
    { value: "Buritirana - MA", text: "Buritirana - MA", state: "MA" },
    { value: "Buritis - MG", text: "Buritis - MG", state: "MG" },
    { value: "Buritis - RO", text: "Buritis - RO", state: "RO" },
    { value: "Buritizal - SP", text: "Buritizal - SP", state: "SP" },
    { value: "Buritizeiro - MG", text: "Buritizeiro - MG", state: "MG" },
    { value: "Butiá - RS", text: "Butiá - RS", state: "RS" },
    { value: "Buíque - PE", text: "Buíque - PE", state: "PE" },
    { value: "Bálsamo - SP", text: "Bálsamo - SP", state: "SP" },
    { value: "Caapiranga - AM", text: "Caapiranga - AM", state: "AM" },
    { value: "Caaporã - PB", text: "Caaporã - PB", state: "PB" },
    { value: "Caarapó - MS", text: "Caarapó - MS", state: "MS" },
    { value: "Caatiba - BA", text: "Caatiba - BA", state: "BA" },
    { value: "Cabaceiras - PB", text: "Cabaceiras - PB", state: "PB" },
    { value: "Cabaceiras do Paraguaçu - BA", text: "Cabaceiras do Paraguaçu - BA", state: "BA" },
    { value: "Cabeceira Grande - MG", text: "Cabeceira Grande - MG", state: "MG" },
    { value: "Cabeceiras - GO", text: "Cabeceiras - GO", state: "GO" },
    { value: "Cabeceiras do Piauí - PI", text: "Cabeceiras do Piauí - PI", state: "PI" },
    { value: "Cabedelo - PB", text: "Cabedelo - PB", state: "PB" },
    { value: "Cabixi - RO", text: "Cabixi - RO", state: "RO" },
    { value: "Cabo Frio - RJ", text: "Cabo Frio - RJ", state: "RJ" },
    { value: "Cabo Verde - MG", text: "Cabo Verde - MG", state: "MG" },
    { value: "Cabo de Santo Agostinho - PE", text: "Cabo de Santo Agostinho - PE", state: "PE" },
    { value: "Cabreúva - SP", text: "Cabreúva - SP", state: "SP" },
    { value: "Cabrobó - PE", text: "Cabrobó - PE", state: "PE" },
    { value: "Cabrália Paulista - SP", text: "Cabrália Paulista - SP", state: "SP" },
    { value: "Cacaulândia - RO", text: "Cacaulândia - RO", state: "RO" },
    { value: "Cacequi - RS", text: "Cacequi - RS", state: "RS" },
    { value: "Cachoeira - BA", text: "Cachoeira - BA", state: "BA" },
    { value: "Cachoeira Alta - GO", text: "Cachoeira Alta - GO", state: "GO" },
    { value: "Cachoeira Dourada - GO", text: "Cachoeira Dourada - GO", state: "GO" },
    { value: "Cachoeira Dourada - MG", text: "Cachoeira Dourada - MG", state: "MG" },
    { value: "Cachoeira Grande - MA", text: "Cachoeira Grande - MA", state: "MA" },
    { value: "Cachoeira Paulista - SP", text: "Cachoeira Paulista - SP", state: "SP" },
    { value: "Cachoeira da Prata - MG", text: "Cachoeira da Prata - MG", state: "MG" },
    { value: "Cachoeira de Goiás - GO", text: "Cachoeira de Goiás - GO", state: "GO" },
    { value: "Cachoeira de Minas - MG", text: "Cachoeira de Minas - MG", state: "MG" },
    { value: "Cachoeira de Pajeú - MG", text: "Cachoeira de Pajeú - MG", state: "MG" },
    { value: "Cachoeira do Arari - PA", text: "Cachoeira do Arari - PA", state: "PA" },
    { value: "Cachoeira do Piriá - PA", text: "Cachoeira do Piriá - PA", state: "PA" },
    { value: "Cachoeira do Sul - RS", text: "Cachoeira do Sul - RS", state: "RS" },
    { value: "Cachoeira dos Índios - PB", text: "Cachoeira dos Índios - PB", state: "PB" },
    { value: "Cachoeiras de Macacu - RJ", text: "Cachoeiras de Macacu - RJ", state: "RJ" },
    { value: "Cachoeirinha - PE", text: "Cachoeirinha - PE", state: "PE" },
    { value: "Cachoeirinha - RS", text: "Cachoeirinha - RS", state: "RS" },
    { value: "Cachoeirinha - TO", text: "Cachoeirinha - TO", state: "TO" },
    { value: "Cachoeiro de Itapemirim - ES", text: "Cachoeiro de Itapemirim - ES", state: "ES" },
    { value: "Cacimba de Areia - PB", text: "Cacimba de Areia - PB", state: "PB" },
    { value: "Cacimba de Dentro - PB", text: "Cacimba de Dentro - PB", state: "PB" },
    { value: "Cacimbas - PB", text: "Cacimbas - PB", state: "PB" },
    { value: "Cacimbinhas - AL", text: "Cacimbinhas - AL", state: "AL" },
    { value: "Cacique Doble - RS", text: "Cacique Doble - RS", state: "RS" },
    { value: "Cacoal - RO", text: "Cacoal - RO", state: "RO" },
    { value: "Caconde - SP", text: "Caconde - SP", state: "SP" },
    { value: "Caculé - BA", text: "Caculé - BA", state: "BA" },
    { value: "Caetanos - BA", text: "Caetanos - BA", state: "BA" },
    { value: "Caetanópolis - MG", text: "Caetanópolis - MG", state: "MG" },
    { value: "Caetité - BA", text: "Caetité - BA", state: "BA" },
    { value: "Caeté - MG", text: "Caeté - MG", state: "MG" },
    { value: "Caetés - PE", text: "Caetés - PE", state: "PE" },
    { value: "Cafarnaum - BA", text: "Cafarnaum - BA", state: "BA" },
    { value: "Cafeara - PR", text: "Cafeara - PR", state: "PR" },
    { value: "Cafelândia - PR", text: "Cafelândia - PR", state: "PR" },
    { value: "Cafelândia - SP", text: "Cafelândia - SP", state: "SP" },
    { value: "Cafezal do Sul - PR", text: "Cafezal do Sul - PR", state: "PR" },
    { value: "Caiabu - SP", text: "Caiabu - SP", state: "SP" },
    { value: "Caiana - MG", text: "Caiana - MG", state: "MG" },
    { value: "Caiapônia - GO", text: "Caiapônia - GO", state: "GO" },
    { value: "Caibaté - RS", text: "Caibaté - RS", state: "RS" },
    { value: "Caibi - SC", text: "Caibi - SC", state: "SC" },
    { value: "Caicó - RN", text: "Caicó - RN", state: "RN" },
    { value: "Caieiras - SP", text: "Caieiras - SP", state: "SP" },
    { value: "Cairu - BA", text: "Cairu - BA", state: "BA" },
    { value: "Caiuá - SP", text: "Caiuá - SP", state: "SP" },
    { value: "Caiçara - PB", text: "Caiçara - PB", state: "PB" },
    { value: "Caiçara - RS", text: "Caiçara - RS", state: "RS" },
    { value: "Caiçara do Norte - RN", text: "Caiçara do Norte - RN", state: "RN" },
    { value: "Caiçara do Rio do Vento - RN", text: "Caiçara do Rio do Vento - RN", state: "RN" },
    { value: "Cajamar - SP", text: "Cajamar - SP", state: "SP" },
    { value: "Cajapió - MA", text: "Cajapió - MA", state: "MA" },
    { value: "Cajari - MA", text: "Cajari - MA", state: "MA" },
    { value: "Cajati - SP", text: "Cajati - SP", state: "SP" },
    { value: "Cajazeiras - PB", text: "Cajazeiras - PB", state: "PB" },
    { value: "Cajazeiras do Piauí - PI", text: "Cajazeiras do Piauí - PI", state: "PI" },
    { value: "Cajazeirinhas - PB", text: "Cajazeirinhas - PB", state: "PB" },
    { value: "Cajobi - SP", text: "Cajobi - SP", state: "SP" },
    { value: "Cajueiro - AL", text: "Cajueiro - AL", state: "AL" },
    { value: "Cajueiro da Praia - PI", text: "Cajueiro da Praia - PI", state: "PI" },
    { value: "Cajuri - MG", text: "Cajuri - MG", state: "MG" },
    { value: "Cajuru - SP", text: "Cajuru - SP", state: "SP" },
    { value: "Caldas - MG", text: "Caldas - MG", state: "MG" },
    { value: "Caldas Brandão - PB", text: "Caldas Brandão - PB", state: "PB" },
    { value: "Caldas Novas - GO", text: "Caldas Novas - GO", state: "GO" },
    { value: "Caldazinha - GO", text: "Caldazinha - GO", state: "GO" },
    { value: "Caldeirão Grande - BA", text: "Caldeirão Grande - BA", state: "BA" },
    { value: "Caldeirão Grande do Piauí - PI", text: "Caldeirão Grande do Piauí - PI", state: "PI" },
    { value: "Califórnia - PR", text: "Califórnia - PR", state: "PR" },
    { value: "Calmon - SC", text: "Calmon - SC", state: "SC" },
    { value: "Calumbi - PE", text: "Calumbi - PE", state: "PE" },
    { value: "Calçado - PE", text: "Calçado - PE", state: "PE" },
    { value: "Calçoene - AP", text: "Calçoene - AP", state: "AP" },
    { value: "Camacan - BA", text: "Camacan - BA", state: "BA" },
    { value: "Camacho - MG", text: "Camacho - MG", state: "MG" },
    { value: "Camalaú - PB", text: "Camalaú - PB", state: "PB" },
    { value: "Camamu - BA", text: "Camamu - BA", state: "BA" },
    { value: "Camanducaia - MG", text: "Camanducaia - MG", state: "MG" },
    { value: "Camapuã - MS", text: "Camapuã - MS", state: "MS" },
    { value: "Camaquã - RS", text: "Camaquã - RS", state: "RS" },
    { value: "Camaragibe - PE", text: "Camaragibe - PE", state: "PE" },
    { value: "Camargo - RS", text: "Camargo - RS", state: "RS" },
    { value: "Camaçari - BA", text: "Camaçari - BA", state: "BA" },
    { value: "Cambará - PR", text: "Cambará - PR", state: "PR" },
    { value: "Cambará do Sul - RS", text: "Cambará do Sul - RS", state: "RS" },
    { value: "Cambira - PR", text: "Cambira - PR", state: "PR" },
    { value: "Camboriú - SC", text: "Camboriú - SC", state: "SC" },
    { value: "Cambuci - RJ", text: "Cambuci - RJ", state: "RJ" },
    { value: "Cambuquira - MG", text: "Cambuquira - MG", state: "MG" },
    { value: "Cambuí - MG", text: "Cambuí - MG", state: "MG" },
    { value: "Cambé - PR", text: "Cambé - PR", state: "PR" },
    { value: "Cametá - PA", text: "Cametá - PA", state: "PA" },
    { value: "Camocim - CE", text: "Camocim - CE", state: "CE" },
    { value: "Camocim de São Félix - PE", text: "Camocim de São Félix - PE", state: "PE" },
    { value: "Campanha - MG", text: "Campanha - MG", state: "MG" },
    { value: "Campanário - MG", text: "Campanário - MG", state: "MG" },
    { value: "Campestre - AL", text: "Campestre - AL", state: "AL" },
    { value: "Campestre - MG", text: "Campestre - MG", state: "MG" },
    { value: "Campestre da Serra - RS", text: "Campestre da Serra - RS", state: "RS" },
    { value: "Campestre de Goiás - GO", text: "Campestre de Goiás - GO", state: "GO" },
    { value: "Campestre do Maranhão - MA", text: "Campestre do Maranhão - MA", state: "MA" },
    { value: "Campina Grande - PB", text: "Campina Grande - PB", state: "PB" },
    { value: "Campina Grande do Sul - PR", text: "Campina Grande do Sul - PR", state: "PR" },
    { value: "Campina Verde - MG", text: "Campina Verde - MG", state: "MG" },
    { value: "Campina da Lagoa - PR", text: "Campina da Lagoa - PR", state: "PR" },
    { value: "Campina das Missões - RS", text: "Campina das Missões - RS", state: "RS" },
    { value: "Campina do Monte Alegre - SP", text: "Campina do Monte Alegre - SP", state: "SP" },
    { value: "Campina do Simão - PR", text: "Campina do Simão - PR", state: "PR" },
    { value: "Campinas - SP", text: "Campinas - SP", state: "SP" },
    { value: "Campinas do Piauí - PI", text: "Campinas do Piauí - PI", state: "PI" },
    { value: "Campinas do Sul - RS", text: "Campinas do Sul - RS", state: "RS" },
    { value: "Campinaçu - GO", text: "Campinaçu - GO", state: "GO" },
    { value: "Campinorte - GO", text: "Campinorte - GO", state: "GO" },
    { value: "Campinápolis - MT", text: "Campinápolis - MT", state: "MT" },
    { value: "Campo Alegre - AL", text: "Campo Alegre - AL", state: "AL" },
    { value: "Campo Alegre - SC", text: "Campo Alegre - SC", state: "SC" },
    { value: "Campo Alegre de Goiás - GO", text: "Campo Alegre de Goiás - GO", state: "GO" },
    { value: "Campo Alegre de Lourdes - BA", text: "Campo Alegre de Lourdes - BA", state: "BA" },
    { value: "Campo Alegre do Fidalgo - PI", text: "Campo Alegre do Fidalgo - PI", state: "PI" },
    { value: "Campo Azul - MG", text: "Campo Azul - MG", state: "MG" },
    { value: "Campo Belo - MG", text: "Campo Belo - MG", state: "MG" },
    { value: "Campo Belo do Sul - SC", text: "Campo Belo do Sul - SC", state: "SC" },
    { value: "Campo Bom - RS", text: "Campo Bom - RS", state: "RS" },
    { value: "Campo Bonito - PR", text: "Campo Bonito - PR", state: "PR" },
    { value: "Campo Erê - SC", text: "Campo Erê - SC", state: "SC" },
    { value: "Campo Florido - MG", text: "Campo Florido - MG", state: "MG" },
    { value: "Campo Formoso - BA", text: "Campo Formoso - BA", state: "BA" },
    { value: "Campo Grande - AL", text: "Campo Grande - AL", state: "AL" },
    { value: "Campo Grande - MS", text: "Campo Grande - MS", state: "MS" },
    { value: "Campo Grande do Piauí - PI", text: "Campo Grande do Piauí - PI", state: "PI" },
    { value: "Campo Largo - PR", text: "Campo Largo - PR", state: "PR" },
    { value: "Campo Largo do Piauí - PI", text: "Campo Largo do Piauí - PI", state: "PI" },
    { value: "Campo Limpo Paulista - SP", text: "Campo Limpo Paulista - SP", state: "SP" },
    { value: "Campo Magro - PR", text: "Campo Magro - PR", state: "PR" },
    { value: "Campo Maior - PI", text: "Campo Maior - PI", state: "PI" },
    { value: "Campo Mourão - PR", text: "Campo Mourão - PR", state: "PR" },
    { value: "Campo Novo - RS", text: "Campo Novo - RS", state: "RS" },
    { value: "Campo Novo de Rondônia - RO", text: "Campo Novo de Rondônia - RO", state: "RO" },
    { value: "Campo Novo do Parecis - MT", text: "Campo Novo do Parecis - MT", state: "MT" },
    { value: "Campo Redondo - RN", text: "Campo Redondo - RN", state: "RN" },
    { value: "Campo Verde - MT", text: "Campo Verde - MT", state: "MT" },
    { value: "Campo de Santana - PB", text: "Campo de Santana - PB", state: "PB" },
    { value: "Campo do Brito - SE", text: "Campo do Brito - SE", state: "SE" },
    { value: "Campo do Meio - MG", text: "Campo do Meio - MG", state: "MG" },
    { value: "Campo do Tenente - PR", text: "Campo do Tenente - PR", state: "PR" },
    { value: "Campos Altos - MG", text: "Campos Altos - MG", state: "MG" },
    { value: "Campos Belos - GO", text: "Campos Belos - GO", state: "GO" },
    { value: "Campos Borges - RS", text: "Campos Borges - RS", state: "RS" },
    { value: "Campos Gerais - MG", text: "Campos Gerais - MG", state: "MG" },
    { value: "Campos Limpo de Goiás - GO", text: "Campos Limpo de Goiás - GO", state: "GO" },
    { value: "Campos Lindos - TO", text: "Campos Lindos - TO", state: "TO" },
    { value: "Campos Novos - SC", text: "Campos Novos - SC", state: "SC" },
    { value: "Campos Novos Paulista - SP", text: "Campos Novos Paulista - SP", state: "SP" },
    { value: "Campos Sales - CE", text: "Campos Sales - CE", state: "CE" },
    { value: "Campos Verdes - GO", text: "Campos Verdes - GO", state: "GO" },
    { value: "Campos de Júlio - MT", text: "Campos de Júlio - MT", state: "MT" },
    { value: "Campos do Jordão - SP", text: "Campos do Jordão - SP", state: "SP" },
    { value: "Campos dos Goytacazes - RJ", text: "Campos dos Goytacazes - RJ", state: "RJ" },
    { value: "Camutanga - PE", text: "Camutanga - PE", state: "PE" },
    { value: "Cana Verde - MG", text: "Cana Verde - MG", state: "MG" },
    { value: "Canabrava do Norte - MT", text: "Canabrava do Norte - MT", state: "MT" },
    { value: "Cananéia - SP", text: "Cananéia - SP", state: "SP" },
    { value: "Canapi - AL", text: "Canapi - AL", state: "AL" },
    { value: "Canarana - BA", text: "Canarana - BA", state: "BA" },
    { value: "Canarana - MT", text: "Canarana - MT", state: "MT" },
    { value: "Canas - SP", text: "Canas - SP", state: "SP" },
    { value: "Canavieira - PI", text: "Canavieira - PI", state: "PI" },
    { value: "Canavieiras - BA", text: "Canavieiras - BA", state: "BA" },
    { value: "Canaã - MG", text: "Canaã - MG", state: "MG" },
    { value: "Canaã dos Carajás - PA", text: "Canaã dos Carajás - PA", state: "PA" },
    { value: "Candangolândia - DF", text: "Candangolândia - DF", state: "DF" },
    { value: "Candeal - BA", text: "Candeal - BA", state: "BA" },
    { value: "Candeias - BA", text: "Candeias - BA", state: "BA" },
    { value: "Candeias - MG", text: "Candeias - MG", state: "MG" },
    { value: "Candeias do Jamari - RO", text: "Candeias do Jamari - RO", state: "RO" },
    { value: "Candelária - RS", text: "Candelária - RS", state: "RS" },
    { value: "Candiba - BA", text: "Candiba - BA", state: "BA" },
    { value: "Candiota - RS", text: "Candiota - RS", state: "RS" },
    { value: "Candói - PR", text: "Candói - PR", state: "PR" },
    { value: "Canela - RS", text: "Canela - RS", state: "RS" },
    { value: "Canelinha - SC", text: "Canelinha - SC", state: "SC" },
    { value: "Canguaretama - RN", text: "Canguaretama - RN", state: "RN" },
    { value: "Canguçu - RS", text: "Canguçu - RS", state: "RS" },
    { value: "Canhoba - SE", text: "Canhoba - SE", state: "SE" },
    { value: "Canhotinho - PE", text: "Canhotinho - PE", state: "PE" },
    { value: "Canindé - CE", text: "Canindé - CE", state: "CE" },
    { value: "Canindé de São Francisco - SE", text: "Canindé de São Francisco - SE", state: "SE" },
    { value: "Canitar - SP", text: "Canitar - SP", state: "SP" },
    { value: "Canoas - RS", text: "Canoas - RS", state: "RS" },
    { value: "Canoinhas - SC", text: "Canoinhas - SC", state: "SC" },
    { value: "Cansanção - BA", text: "Cansanção - BA", state: "BA" },
    { value: "Cantagalo - MG", text: "Cantagalo - MG", state: "MG" },
    { value: "Cantagalo - PR", text: "Cantagalo - PR", state: "PR" },
    { value: "Cantagalo - RJ", text: "Cantagalo - RJ", state: "RJ" },
    { value: "Cantanhede - MA", text: "Cantanhede - MA", state: "MA" },
    { value: "Canto do Buriti - PI", text: "Canto do Buriti - PI", state: "PI" },
    { value: "Cantá - RR", text: "Cantá - RR", state: "RR" },
    { value: "Canudos - BA", text: "Canudos - BA", state: "BA" },
    { value: "Canudos do Vale - RS", text: "Canudos do Vale - RS", state: "RS" },
    { value: "Canutama - AM", text: "Canutama - AM", state: "AM" },
    { value: "Canápolis - BA", text: "Canápolis - BA", state: "BA" },
    { value: "Canápolis - MG", text: "Canápolis - MG", state: "MG" },
    { value: "Capanema - PA", text: "Capanema - PA", state: "PA" },
    { value: "Capanema - PR", text: "Capanema - PR", state: "PR" },
    { value: "Caparaó - MG", text: "Caparaó - MG", state: "MG" },
    { value: "Capela - AL", text: "Capela - AL", state: "AL" },
    { value: "Capela - SE", text: "Capela - SE", state: "SE" },
    { value: "Capela Nova - MG", text: "Capela Nova - MG", state: "MG" },
    { value: "Capela de Santana - RS", text: "Capela de Santana - RS", state: "RS" },
    { value: "Capela do Alto - SP", text: "Capela do Alto - SP", state: "SP" },
    { value: "Capela do Alto Alegre - BA", text: "Capela do Alto Alegre - BA", state: "BA" },
    { value: "Capelinha - MG", text: "Capelinha - MG", state: "MG" },
    { value: "Capetinga - MG", text: "Capetinga - MG", state: "MG" },
    { value: "Capim - PB", text: "Capim - PB", state: "PB" },
    { value: "Capim Branco - MG", text: "Capim Branco - MG", state: "MG" },
    { value: "Capim Grosso - BA", text: "Capim Grosso - BA", state: "BA" },
    { value: "Capinzal - SC", text: "Capinzal - SC", state: "SC" },
    { value: "Capinzal do Norte - MA", text: "Capinzal do Norte - MA", state: "MA" },
    { value: "Capinópolis - MG", text: "Capinópolis - MG", state: "MG" },
    { value: "Capistrano - CE", text: "Capistrano - CE", state: "CE" },
    { value: "Capitão - RS", text: "Capitão - RS", state: "RS" },
    { value: "Capitão Andrade - MG", text: "Capitão Andrade - MG", state: "MG" },
    { value: "Capitão Enéas - MG", text: "Capitão Enéas - MG", state: "MG" },
    { value: "Capitão Gervásio Oliveira - PI", text: "Capitão Gervásio Oliveira - PI", state: "PI" },
    { value: "Capitão Leônidas Marques - PR", text: "Capitão Leônidas Marques - PR", state: "PR" },
    { value: "Capitão Poço - PA", text: "Capitão Poço - PA", state: "PA" },
    { value: "Capitão de Campos - PI", text: "Capitão de Campos - PI", state: "PI" },
    { value: "Capitólio - MG", text: "Capitólio - MG", state: "MG" },
    { value: "Capivari - SP", text: "Capivari - SP", state: "SP" },
    { value: "Capivari de Baixo - SC", text: "Capivari de Baixo - SC", state: "SC" },
    { value: "Capivari do Sul - RS", text: "Capivari do Sul - RS", state: "RS" },
    { value: "Capixaba - AC", text: "Capixaba - AC", state: "AC" },
    { value: "Capoeiras - PE", text: "Capoeiras - PE", state: "PE" },
    { value: "Caputira - MG", text: "Caputira - MG", state: "MG" },
    { value: "Capão Alto - SC", text: "Capão Alto - SC", state: "SC" },
    { value: "Capão Bonito - SP", text: "Capão Bonito - SP", state: "SP" },
    { value: "Capão Bonito do Sul - RS", text: "Capão Bonito do Sul - RS", state: "RS" },
    { value: "Capão da Canoa - RS", text: "Capão da Canoa - RS", state: "RS" },
    { value: "Capão do Cipó - RS", text: "Capão do Cipó - RS", state: "RS" },
    { value: "Capão do Leão - RS", text: "Capão do Leão - RS", state: "RS" },
    { value: "Caracaraí - RR", text: "Caracaraí - RR", state: "RR" },
    { value: "Caracol - MS", text: "Caracol - MS", state: "MS" },
    { value: "Caracol - PI", text: "Caracol - PI", state: "PI" },
    { value: "Caraguatatuba - SP", text: "Caraguatatuba - SP", state: "SP" },
    { value: "Carambeí - PR", text: "Carambeí - PR", state: "PR" },
    { value: "Caranaíba - MG", text: "Caranaíba - MG", state: "MG" },
    { value: "Carandaí - MG", text: "Carandaí - MG", state: "MG" },
    { value: "Carangola - MG", text: "Carangola - MG", state: "MG" },
    { value: "Carapebus - RJ", text: "Carapebus - RJ", state: "RJ" },
    { value: "Carapicuíba - SP", text: "Carapicuíba - SP", state: "SP" },
    { value: "Caratinga - MG", text: "Caratinga - MG", state: "MG" },
    { value: "Carauari - AM", text: "Carauari - AM", state: "AM" },
    { value: "Caravelas - BA", text: "Caravelas - BA", state: "BA" },
    { value: "Carazinho - RS", text: "Carazinho - RS", state: "RS" },
    { value: "Caraá - RS", text: "Caraá - RS", state: "RS" },
    { value: "Caraí - MG", text: "Caraí - MG", state: "MG" },
    { value: "Caraíbas - BA", text: "Caraíbas - BA", state: "BA" },
    { value: "Caraúbas - PB", text: "Caraúbas - PB", state: "PB" },
    { value: "Caraúbas - RN", text: "Caraúbas - RN", state: "RN" },
    { value: "Caraúbas do Piauí - PI", text: "Caraúbas do Piauí - PI", state: "PI" },
    { value: "Carbonita - MG", text: "Carbonita - MG", state: "MG" },
    { value: "Cardeal da Silva - BA", text: "Cardeal da Silva - BA", state: "BA" },
    { value: "Cardoso - SP", text: "Cardoso - SP", state: "SP" },
    { value: "Cardoso Moreira - RJ", text: "Cardoso Moreira - RJ", state: "RJ" },
    { value: "Careaçu - MG", text: "Careaçu - MG", state: "MG" },
    { value: "Careiro - AM", text: "Careiro - AM", state: "AM" },
    { value: "Careiro da Várzea - AM", text: "Careiro da Várzea - AM", state: "AM" },
    { value: "Cariacica - ES", text: "Cariacica - ES", state: "ES" },
    { value: "Caridade - CE", text: "Caridade - CE", state: "CE" },
    { value: "Caridade do Piauí - PI", text: "Caridade do Piauí - PI", state: "PI" },
    { value: "Carinhanha - BA", text: "Carinhanha - BA", state: "BA" },
    { value: "Carira - SE", text: "Carira - SE", state: "SE" },
    { value: "Cariri do Tocantins - TO", text: "Cariri do Tocantins - TO", state: "TO" },
    { value: "Caririaçu - CE", text: "Caririaçu - CE", state: "CE" },
    { value: "Cariré - CE", text: "Cariré - CE", state: "CE" },
    { value: "Cariús - CE", text: "Cariús - CE", state: "CE" },
    { value: "Carlinda - MT", text: "Carlinda - MT", state: "MT" },
    { value: "Carlos Barbosa - RS", text: "Carlos Barbosa - RS", state: "RS" },
    { value: "Carlos Chagas - MG", text: "Carlos Chagas - MG", state: "MG" },
    { value: "Carlos Gomes - RS", text: "Carlos Gomes - RS", state: "RS" },
    { value: "Carlópolis - PR", text: "Carlópolis - PR", state: "PR" },
    { value: "Carmo - RJ", text: "Carmo - RJ", state: "RJ" },
    { value: "Carmo da Cachoeira - MG", text: "Carmo da Cachoeira - MG", state: "MG" },
    { value: "Carmo da Mata - MG", text: "Carmo da Mata - MG", state: "MG" },
    { value: "Carmo de Minas - MG", text: "Carmo de Minas - MG", state: "MG" },
    { value: "Carmo do Cajuru - MG", text: "Carmo do Cajuru - MG", state: "MG" },
    { value: "Carmo do Paranaíba - MG", text: "Carmo do Paranaíba - MG", state: "MG" },
    { value: "Carmo do Rio Claro - MG", text: "Carmo do Rio Claro - MG", state: "MG" },
    { value: "Carmo do Rio Verde - GO", text: "Carmo do Rio Verde - GO", state: "GO" },
    { value: "Carmolândia - TO", text: "Carmolândia - TO", state: "TO" },
    { value: "Carmésia - MG", text: "Carmésia - MG", state: "MG" },
    { value: "Carmópolis - SE", text: "Carmópolis - SE", state: "SE" },
    { value: "Carmópolis de Minas - MG", text: "Carmópolis de Minas - MG", state: "MG" },
    { value: "Carnaubais - RN", text: "Carnaubais - RN", state: "RN" },
    { value: "Carnaubal - CE", text: "Carnaubal - CE", state: "CE" },
    { value: "Carnaubeira da Penha - PE", text: "Carnaubeira da Penha - PE", state: "PE" },
    { value: "Carnaíba - PE", text: "Carnaíba - PE", state: "PE" },
    { value: "Carnaúba dos Dantas - RN", text: "Carnaúba dos Dantas - RN", state: "RN" },
    { value: "Carneirinho - MG", text: "Carneirinho - MG", state: "MG" },
    { value: "Carneiros - AL", text: "Carneiros - AL", state: "AL" },
    { value: "Caroebe - RR", text: "Caroebe - RR", state: "RR" },
    { value: "Carolina - MA", text: "Carolina - MA", state: "MA" },
    { value: "Carpina - PE", text: "Carpina - PE", state: "PE" },
    { value: "Carrancas - MG", text: "Carrancas - MG", state: "MG" },
    { value: "Carrapateira - PB", text: "Carrapateira - PB", state: "PB" },
    { value: "Carrasco Bonito - TO", text: "Carrasco Bonito - TO", state: "TO" },
    { value: "Caruaru - PE", text: "Caruaru - PE", state: "PE" },
    { value: "Carutapera - MA", text: "Carutapera - MA", state: "MA" },
    { value: "Carvalhos - MG", text: "Carvalhos - MG", state: "MG" },
    { value: "Carvalhópolis - MG", text: "Carvalhópolis - MG", state: "MG" },
    { value: "Casa Branca - SP", text: "Casa Branca - SP", state: "SP" },
    { value: "Casa Grande - MG", text: "Casa Grande - MG", state: "MG" },
    { value: "Casa Nova - BA", text: "Casa Nova - BA", state: "BA" },
    { value: "Casca - RS", text: "Casca - RS", state: "RS" },
    { value: "Cascalho Rico - MG", text: "Cascalho Rico - MG", state: "MG" },
    { value: "Cascavel - CE", text: "Cascavel - CE", state: "CE" },
    { value: "Cascavel - PR", text: "Cascavel - PR", state: "PR" },
    { value: "Caseara - TO", text: "Caseara - TO", state: "TO" },
    { value: "Caseiros - RS", text: "Caseiros - RS", state: "RS" },
    { value: "Casimiro de Abreu - RJ", text: "Casimiro de Abreu - RJ", state: "RJ" },
    { value: "Casinhas - PE", text: "Casinhas - PE", state: "PE" },
    { value: "Casserengue - PB", text: "Casserengue - PB", state: "PB" },
    { value: "Cassilândia - MS", text: "Cassilândia - MS", state: "MS" },
    { value: "Castanhal - PA", text: "Castanhal - PA", state: "PA" },
    { value: "Castanheira - MT", text: "Castanheira - MT", state: "MT" },
    { value: "Castanheiras - RO", text: "Castanheiras - RO", state: "RO" },
    { value: "Castelo - ES", text: "Castelo - ES", state: "ES" },
    { value: "Castelo do Piauí - PI", text: "Castelo do Piauí - PI", state: "PI" },
    { value: "Castelândia - GO", text: "Castelândia - GO", state: "GO" },
    { value: "Castilho - SP", text: "Castilho - SP", state: "SP" },
    { value: "Castro - PR", text: "Castro - PR", state: "PR" },
    { value: "Castro Alves - BA", text: "Castro Alves - BA", state: "BA" },
    { value: "Cataguases - MG", text: "Cataguases - MG", state: "MG" },
    { value: "Catalão - GO", text: "Catalão - GO", state: "GO" },
    { value: "Catanduva - SP", text: "Catanduva - SP", state: "SP" },
    { value: "Catanduvas - PR", text: "Catanduvas - PR", state: "PR" },
    { value: "Catanduvas - SC", text: "Catanduvas - SC", state: "SC" },
    { value: "Catarina - CE", text: "Catarina - CE", state: "CE" },
    { value: "Catas Altas - MG", text: "Catas Altas - MG", state: "MG" },
    { value: "Catas Altas da Noruega - MG", text: "Catas Altas da Noruega - MG", state: "MG" },
    { value: "Catende - PE", text: "Catende - PE", state: "PE" },
    { value: "Catiguá - SP", text: "Catiguá - SP", state: "SP" },
    { value: "Catingueira - PB", text: "Catingueira - PB", state: "PB" },
    { value: "Catolândia - BA", text: "Catolândia - BA", state: "BA" },
    { value: "Catolé do Rocha - PB", text: "Catolé do Rocha - PB", state: "PB" },
    { value: "Catu - BA", text: "Catu - BA", state: "BA" },
    { value: "Catuji - MG", text: "Catuji - MG", state: "MG" },
    { value: "Catunda - CE", text: "Catunda - CE", state: "CE" },
    { value: "Caturama - BA", text: "Caturama - BA", state: "BA" },
    { value: "Caturaí - GO", text: "Caturaí - GO", state: "GO" },
    { value: "Caturité - PB", text: "Caturité - PB", state: "PB" },
    { value: "Catuti - MG", text: "Catuti - MG", state: "MG" },
    { value: "Catuípe - RS", text: "Catuípe - RS", state: "RS" },
    { value: "Caucaia - CE", text: "Caucaia - CE", state: "CE" },
    { value: "Cavalcante - GO", text: "Cavalcante - GO", state: "GO" },
    { value: "Caxambu - MG", text: "Caxambu - MG", state: "MG" },
    { value: "Caxambu do Sul - SC", text: "Caxambu do Sul - SC", state: "SC" },
    { value: "Caxias - MA", text: "Caxias - MA", state: "MA" },
    { value: "Caxias do Sul - RS", text: "Caxias do Sul - RS", state: "RS" },
    { value: "Caxingó - PI", text: "Caxingó - PI", state: "PI" },
    { value: "Caçador - SC", text: "Caçador - SC", state: "SC" },
    { value: "Caçapava - SP", text: "Caçapava - SP", state: "SP" },
    { value: "Caçapava do Sul - RS", text: "Caçapava do Sul - RS", state: "RS" },
    { value: "Caçu - GO", text: "Caçu - GO", state: "GO" },
    { value: "Caém - BA", text: "Caém - BA", state: "BA" },
    { value: "Ceará-Mirim - RN", text: "Ceará-Mirim - RN", state: "RN" },
    { value: "Cedral - MA", text: "Cedral - MA", state: "MA" },
    { value: "Cedral - SP", text: "Cedral - SP", state: "SP" },
    { value: "Cedro - CE", text: "Cedro - CE", state: "CE" },
    { value: "Cedro - PE", text: "Cedro - PE", state: "PE" },
    { value: "Cedro de São João - SE", text: "Cedro de São João - SE", state: "SE" },
    { value: "Cedro do Abaeté - MG", text: "Cedro do Abaeté - MG", state: "MG" },
    { value: "Ceilândia - DF", text: "Ceilândia - DF", state: "DF" },
    { value: "Celso Ramos - SC", text: "Celso Ramos - SC", state: "SC" },
    { value: "Centenário - RS", text: "Centenário - RS", state: "RS" },
    { value: "Centenário - TO", text: "Centenário - TO", state: "TO" },
    { value: "Centenário do Sul - PR", text: "Centenário do Sul - PR", state: "PR" },
    { value: "Central - BA", text: "Central - BA", state: "BA" },
    { value: "Central de Minas - MG", text: "Central de Minas - MG", state: "MG" },
    { value: "Central do Maranhão - MA", text: "Central do Maranhão - MA", state: "MA" },
    { value: "Centralina - MG", text: "Centralina - MG", state: "MG" },
    { value: "Centro Novo do Maranhão - MA", text: "Centro Novo do Maranhão - MA", state: "MA" },
    { value: "Centro do Guilherme - MA", text: "Centro do Guilherme - MA", state: "MA" },
    { value: "Cerejeiras - RO", text: "Cerejeiras - RO", state: "RO" },
    { value: "Ceres - GO", text: "Ceres - GO", state: "GO" },
    { value: "Cerqueira César - SP", text: "Cerqueira César - SP", state: "SP" },
    { value: "Cerquilho - SP", text: "Cerquilho - SP", state: "SP" },
    { value: "Cerrito - RS", text: "Cerrito - RS", state: "RS" },
    { value: "Cerro Azul - PR", text: "Cerro Azul - PR", state: "PR" },
    { value: "Cerro Branco - RS", text: "Cerro Branco - RS", state: "RS" },
    { value: "Cerro Corá - RN", text: "Cerro Corá - RN", state: "RN" },
    { value: "Cerro Grande - RS", text: "Cerro Grande - RS", state: "RS" },
    { value: "Cerro Grande do Sul - RS", text: "Cerro Grande do Sul - RS", state: "RS" },
    { value: "Cerro Largo - RS", text: "Cerro Largo - RS", state: "RS" },
    { value: "Cerro Negro - SC", text: "Cerro Negro - SC", state: "SC" },
    { value: "Cesário Lange - SP", text: "Cesário Lange - SP", state: "SP" },
    { value: "Cezarina - GO", text: "Cezarina - GO", state: "GO" },
    { value: "Chalé - MG", text: "Chalé - MG", state: "MG" },
    { value: "Chapada - RS", text: "Chapada - RS", state: "RS" },
    { value: "Chapada Gaúcha - MG", text: "Chapada Gaúcha - MG", state: "MG" },
    { value: "Chapada da Natividade - TO", text: "Chapada da Natividade - TO", state: "TO" },
    { value: "Chapada de Areia - TO", text: "Chapada de Areia - TO", state: "TO" },
    { value: "Chapada do Norte - MG", text: "Chapada do Norte - MG", state: "MG" },
    { value: "Chapada dos Guimarães - MT", text: "Chapada dos Guimarães - MT", state: "MT" },
    { value: "Chapadinha - MA", text: "Chapadinha - MA", state: "MA" },
    { value: "Chapadão do Céu - GO", text: "Chapadão do Céu - GO", state: "GO" },
    { value: "Chapadão do Lageado - SC", text: "Chapadão do Lageado - SC", state: "SC" },
    { value: "Chapadão do Sul - MS", text: "Chapadão do Sul - MS", state: "MS" },
    { value: "Chapecó - SC", text: "Chapecó - SC", state: "SC" },
    { value: "Charqueada - SP", text: "Charqueada - SP", state: "SP" },
    { value: "Charqueadas - RS", text: "Charqueadas - RS", state: "RS" },
    { value: "Charrua - RS", text: "Charrua - RS", state: "RS" },
    { value: "Chaval - CE", text: "Chaval - CE", state: "CE" },
    { value: "Chavantes - SP", text: "Chavantes - SP", state: "SP" },
    { value: "Chaves - PA", text: "Chaves - PA", state: "PA" },
    { value: "Chiador - MG", text: "Chiador - MG", state: "MG" },
    { value: "Chiapeta - RS", text: "Chiapeta - RS", state: "RS" },
    { value: "Chopinzinho - PR", text: "Chopinzinho - PR", state: "PR" },
    { value: "Chorozinho - CE", text: "Chorozinho - CE", state: "CE" },
    { value: "Chorrochó - BA", text: "Chorrochó - BA", state: "BA" },
    { value: "Choró - CE", text: "Choró - CE", state: "CE" },
    { value: "Chupinguaia - RO", text: "Chupinguaia - RO", state: "RO" },
    { value: "Chuvisca - RS", text: "Chuvisca - RS", state: "RS" },
    { value: "Chuí - RS", text: "Chuí - RS", state: "RS" },
    { value: "Chácara - MG", text: "Chácara - MG", state: "MG" },
    { value: "Chã Grande - PE", text: "Chã Grande - PE", state: "PE" },
    { value: "Chã Preta - AL", text: "Chã Preta - AL", state: "AL" },
    { value: "Chã de Alegria - PE", text: "Chã de Alegria - PE", state: "PE" },
    { value: "Cianorte - PR", text: "Cianorte - PR", state: "PR" },
    { value: "Cidade Gaúcha - PR", text: "Cidade Gaúcha - PR", state: "PR" },
    { value: "Cidade Ocidental - GO", text: "Cidade Ocidental - GO", state: "GO" },
    { value: "Cidelândia - MA", text: "Cidelândia - MA", state: "MA" },
    { value: "Cidreira - RS", text: "Cidreira - RS", state: "RS" },
    { value: "Cipotânea - MG", text: "Cipotânea - MG", state: "MG" },
    { value: "Cipó - BA", text: "Cipó - BA", state: "BA" },
    { value: "Ciríaco - RS", text: "Ciríaco - RS", state: "RS" },
    { value: "Claraval - MG", text: "Claraval - MG", state: "MG" },
    { value: "Claro dos Poções - MG", text: "Claro dos Poções - MG", state: "MG" },
    { value: "Clementina - SP", text: "Clementina - SP", state: "SP" },
    { value: "Clevelândia - PR", text: "Clevelândia - PR", state: "PR" },
    { value: "Cláudia - MT", text: "Cláudia - MT", state: "MT" },
    { value: "Cláudio - MG", text: "Cláudio - MG", state: "MG" },
    { value: "Coaraci - BA", text: "Coaraci - BA", state: "BA" },
    { value: "Coari - AM", text: "Coari - AM", state: "AM" },
    { value: "Cocal - PI", text: "Cocal - PI", state: "PI" },
    { value: "Cocal de Telha - PI", text: "Cocal de Telha - PI", state: "PI" },
    { value: "Cocal do Sul - SC", text: "Cocal do Sul - SC", state: "SC" },
    { value: "Cocal dos Alves - PI", text: "Cocal dos Alves - PI", state: "PI" },
    { value: "Cocalinho - MT", text: "Cocalinho - MT", state: "MT" },
    { value: "Cocalzinho de Goiás - GO", text: "Cocalzinho de Goiás - GO", state: "GO" },
    { value: "Cocos - BA", text: "Cocos - BA", state: "BA" },
    { value: "Codajás - AM", text: "Codajás - AM", state: "AM" },
    { value: "Codó - MA", text: "Codó - MA", state: "MA" },
    { value: "Coelho Neto - MA", text: "Coelho Neto - MA", state: "MA" },
    { value: "Coimbra - MG", text: "Coimbra - MG", state: "MG" },
    { value: "Coité do Nóia - AL", text: "Coité do Nóia - AL", state: "AL" },
    { value: "Coivaras - PI", text: "Coivaras - PI", state: "PI" },
    { value: "Colares - PA", text: "Colares - PA", state: "PA" },
    { value: "Colatina - ES", text: "Colatina - ES", state: "ES" },
    { value: "Colina - SP", text: "Colina - SP", state: "SP" },
    { value: "Colinas - MA", text: "Colinas - MA", state: "MA" },
    { value: "Colinas - RS", text: "Colinas - RS", state: "RS" },
    { value: "Colinas do Sul - GO", text: "Colinas do Sul - GO", state: "GO" },
    { value: "Colinas do Tocantins - TO", text: "Colinas do Tocantins - TO", state: "TO" },
    { value: "Colméia - TO", text: "Colméia - TO", state: "TO" },
    { value: "Colniza - MT", text: "Colniza - MT", state: "MT" },
    { value: "Colombo - PR", text: "Colombo - PR", state: "PR" },
    { value: "Colorado - PR", text: "Colorado - PR", state: "PR" },
    { value: "Colorado - RS", text: "Colorado - RS", state: "RS" },
    { value: "Colorado do Oeste - RO", text: "Colorado do Oeste - RO", state: "RO" },
    { value: "Coluna - MG", text: "Coluna - MG", state: "MG" },
    { value: "Colíder - MT", text: "Colíder - MT", state: "MT" },
    { value: "Colômbia - SP", text: "Colômbia - SP", state: "SP" },
    { value: "Colônia Leopoldina - AL", text: "Colônia Leopoldina - AL", state: "AL" },
    { value: "Colônia do Gurguéia - PI", text: "Colônia do Gurguéia - PI", state: "PI" },
    { value: "Colônia do Piauí - PI", text: "Colônia do Piauí - PI", state: "PI" },
    { value: "Combinado - TO", text: "Combinado - TO", state: "TO" },
    { value: "Comendador Gomes - MG", text: "Comendador Gomes - MG", state: "MG" },
    { value: "Comendador Levy Gasparian - RJ", text: "Comendador Levy Gasparian - RJ", state: "RJ" },
    { value: "Comercinho - MG", text: "Comercinho - MG", state: "MG" },
    { value: "Comodoro - MT", text: "Comodoro - MT", state: "MT" },
    { value: "Conceição - PB", text: "Conceição - PB", state: "PB" },
    { value: "Conceição da Aparecida - MG", text: "Conceição da Aparecida - MG", state: "MG" },
    { value: "Conceição da Barra - ES", text: "Conceição da Barra - ES", state: "ES" },
    { value: "Conceição da Barra de Minas - MG", text: "Conceição da Barra de Minas - MG", state: "MG" },
    { value: "Conceição da Feira - BA", text: "Conceição da Feira - BA", state: "BA" },
    { value: "Conceição das Alagoas - MG", text: "Conceição das Alagoas - MG", state: "MG" },
    { value: "Conceição das Pedras - MG", text: "Conceição das Pedras - MG", state: "MG" },
    { value: "Conceição de Ipanema - MG", text: "Conceição de Ipanema - MG", state: "MG" },
    { value: "Conceição de Macabu - RJ", text: "Conceição de Macabu - RJ", state: "RJ" },
    { value: "Conceição do Almeida - BA", text: "Conceição do Almeida - BA", state: "BA" },
    { value: "Conceição do Araguaia - PA", text: "Conceição do Araguaia - PA", state: "PA" },
    { value: "Conceição do Canindé - PI", text: "Conceição do Canindé - PI", state: "PI" },
    { value: "Conceição do Castelo - ES", text: "Conceição do Castelo - ES", state: "ES" },
    { value: "Conceição do Coité - BA", text: "Conceição do Coité - BA", state: "BA" },
    { value: "Conceição do Jacuípe - BA", text: "Conceição do Jacuípe - BA", state: "BA" },
    { value: "Conceição do Lago-Açu - MA", text: "Conceição do Lago-Açu - MA", state: "MA" },
    { value: "Conceição do Mato Dentro - MG", text: "Conceição do Mato Dentro - MG", state: "MG" },
    { value: "Conceição do Pará - MG", text: "Conceição do Pará - MG", state: "MG" },
    { value: "Conceição do Rio Verde - MG", text: "Conceição do Rio Verde - MG", state: "MG" },
    { value: "Conceição do Tocantins - TO", text: "Conceição do Tocantins - TO", state: "TO" },
    { value: "Conceição dos Ouros - MG", text: "Conceição dos Ouros - MG", state: "MG" },
    { value: "Conchal - SP", text: "Conchal - SP", state: "SP" },
    { value: "Conchas - SP", text: "Conchas - SP", state: "SP" },
    { value: "Concórdia - SC", text: "Concórdia - SC", state: "SC" },
    { value: "Concórdia do Pará - PA", text: "Concórdia do Pará - PA", state: "PA" },
    { value: "Condado - PB", text: "Condado - PB", state: "PB" },
    { value: "Condado - PE", text: "Condado - PE", state: "PE" },
    { value: "Conde - BA", text: "Conde - BA", state: "BA" },
    { value: "Conde - PB", text: "Conde - PB", state: "PB" },
    { value: "Condeúba - BA", text: "Condeúba - BA", state: "BA" },
    { value: "Condor - RS", text: "Condor - RS", state: "RS" },
    { value: "Confins - MG", text: "Confins - MG", state: "MG" },
    { value: "Confresa - MT", text: "Confresa - MT", state: "MT" },
    { value: "Congo - PB", text: "Congo - PB", state: "PB" },
    { value: "Congonhal - MG", text: "Congonhal - MG", state: "MG" },
    { value: "Congonhas - MG", text: "Congonhas - MG", state: "MG" },
    { value: "Congonhas do Norte - MG", text: "Congonhas do Norte - MG", state: "MG" },
    { value: "Congonhinhas - PR", text: "Congonhinhas - PR", state: "PR" },
    { value: "Conquista - MG", text: "Conquista - MG", state: "MG" },
    { value: "Conquista d'Oeste - MT", text: "Conquista d'Oeste - MT", state: "MT" },
    { value: "Conselheiro Lafaiete - MG", text: "Conselheiro Lafaiete - MG", state: "MG" },
    { value: "Conselheiro Mairinck - PR", text: "Conselheiro Mairinck - PR", state: "PR" },
    { value: "Conselheiro Pena - MG", text: "Conselheiro Pena - MG", state: "MG" },
    { value: "Consolação - MG", text: "Consolação - MG", state: "MG" },
    { value: "Constantina - RS", text: "Constantina - RS", state: "RS" },
    { value: "Contagem - MG", text: "Contagem - MG", state: "MG" },
    { value: "Contenda - PR", text: "Contenda - PR", state: "PR" },
    { value: "Contendas do Sincorá - BA", text: "Contendas do Sincorá - BA", state: "BA" },
    { value: "Coqueiral - MG", text: "Coqueiral - MG", state: "MG" },
    { value: "Coqueiro Baixo - RS", text: "Coqueiro Baixo - RS", state: "RS" },
    { value: "Coqueiro Seco - AL", text: "Coqueiro Seco - AL", state: "AL" },
    { value: "Coqueiros do Sul - RS", text: "Coqueiros do Sul - RS", state: "RS" },
    { value: "Coração de Jesus - MG", text: "Coração de Jesus - MG", state: "MG" },
    { value: "Coração de Maria - BA", text: "Coração de Maria - BA", state: "BA" },
    { value: "Corbélia - PR", text: "Corbélia - PR", state: "PR" },
    { value: "Cordeiro - RJ", text: "Cordeiro - RJ", state: "RJ" },
    { value: "Cordeiros - BA", text: "Cordeiros - BA", state: "BA" },
    { value: "Cordeirópolis - SP", text: "Cordeirópolis - SP", state: "SP" },
    { value: "Cordilheira Alta - SC", text: "Cordilheira Alta - SC", state: "SC" },
    { value: "Cordisburgo - MG", text: "Cordisburgo - MG", state: "MG" },
    { value: "Cordislândia - MG", text: "Cordislândia - MG", state: "MG" },
    { value: "Coreaú - CE", text: "Coreaú - CE", state: "CE" },
    { value: "Coremas - PB", text: "Coremas - PB", state: "PB" },
    { value: "Corguinho - MS", text: "Corguinho - MS", state: "MS" },
    { value: "Coribe - BA", text: "Coribe - BA", state: "BA" },
    { value: "Corinto - MG", text: "Corinto - MG", state: "MG" },
    { value: "Cornélio Procópio - PR", text: "Cornélio Procópio - PR", state: "PR" },
    { value: "Coroaci - MG", text: "Coroaci - MG", state: "MG" },
    { value: "Coroados - SP", text: "Coroados - SP", state: "SP" },
    { value: "Coroatá - MA", text: "Coroatá - MA", state: "MA" },
    { value: "Coromandel - MG", text: "Coromandel - MG", state: "MG" },
    { value: "Coronel Barros - RS", text: "Coronel Barros - RS", state: "RS" },
    { value: "Coronel Bicaco - RS", text: "Coronel Bicaco - RS", state: "RS" },
    { value: "Coronel Domingos Soares - PR", text: "Coronel Domingos Soares - PR", state: "PR" },
    { value: "Coronel Ezequiel - RN", text: "Coronel Ezequiel - RN", state: "RN" },
    { value: "Coronel Fabriciano - MG", text: "Coronel Fabriciano - MG", state: "MG" },
    { value: "Coronel Freitas - SC", text: "Coronel Freitas - SC", state: "SC" },
    { value: "Coronel José Dias - PI", text: "Coronel José Dias - PI", state: "PI" },
    { value: "Coronel João Pessoa - RN", text: "Coronel João Pessoa - RN", state: "RN" },
    { value: "Coronel João Sá - BA", text: "Coronel João Sá - BA", state: "BA" },
    { value: "Coronel Macedo - SP", text: "Coronel Macedo - SP", state: "SP" },
    { value: "Coronel Martins - SC", text: "Coronel Martins - SC", state: "SC" },
    { value: "Coronel Murta - MG", text: "Coronel Murta - MG", state: "MG" },
    { value: "Coronel Pacheco - MG", text: "Coronel Pacheco - MG", state: "MG" },
    { value: "Coronel Pilar - RS", text: "Coronel Pilar - RS", state: "RS" },
    { value: "Coronel Sapucaia - MS", text: "Coronel Sapucaia - MS", state: "MS" },
    { value: "Coronel Vivida - PR", text: "Coronel Vivida - PR", state: "PR" },
    { value: "Coronel Xavier Chaves - MG", text: "Coronel Xavier Chaves - MG", state: "MG" },
    { value: "Correia Pinto - SC", text: "Correia Pinto - SC", state: "SC" },
    { value: "Corrente - PI", text: "Corrente - PI", state: "PI" },
    { value: "Correntes - PE", text: "Correntes - PE", state: "PE" },
    { value: "Correntina - BA", text: "Correntina - BA", state: "BA" },
    { value: "Cortês - PE", text: "Cortês - PE", state: "PE" },
    { value: "Corumbataí - SP", text: "Corumbataí - SP", state: "SP" },
    { value: "Corumbataí do Sul - PR", text: "Corumbataí do Sul - PR", state: "PR" },
    { value: "Corumbaíba - GO", text: "Corumbaíba - GO", state: "GO" },
    { value: "Corumbiara - RO", text: "Corumbiara - RO", state: "RO" },
    { value: "Corumbá - MS", text: "Corumbá - MS", state: "MS" },
    { value: "Corumbá de Goiás - GO", text: "Corumbá de Goiás - GO", state: "GO" },
    { value: "Corupá - SC", text: "Corupá - SC", state: "SC" },
    { value: "Coruripe - AL", text: "Coruripe - AL", state: "AL" },
    { value: "Cosmorama - SP", text: "Cosmorama - SP", state: "SP" },
    { value: "Cosmópolis - SP", text: "Cosmópolis - SP", state: "SP" },
    { value: "Costa Marques - RO", text: "Costa Marques - RO", state: "RO" },
    { value: "Costa Rica - MS", text: "Costa Rica - MS", state: "MS" },
    { value: "Cotegipe - BA", text: "Cotegipe - BA", state: "BA" },
    { value: "Cotia - SP", text: "Cotia - SP", state: "SP" },
    { value: "Cotiporã - RS", text: "Cotiporã - RS", state: "RS" },
    { value: "Cotriguaçu - MT", text: "Cotriguaçu - MT", state: "MT" },
    { value: "Couto Magalhães - TO", text: "Couto Magalhães - TO", state: "TO" },
    { value: "Couto de Magalhães de Minas - MG", text: "Couto de Magalhães de Minas - MG", state: "MG" },
    { value: "Coxilha - RS", text: "Coxilha - RS", state: "RS" },
    { value: "Coxim - MS", text: "Coxim - MS", state: "MS" },
    { value: "Coxixola - PB", text: "Coxixola - PB", state: "PB" },
    { value: "Crateús - CE", text: "Crateús - CE", state: "CE" },
    { value: "Crato - CE", text: "Crato - CE", state: "CE" },
    { value: "Cravinhos - SP", text: "Cravinhos - SP", state: "SP" },
    { value: "Cravolândia - BA", text: "Cravolândia - BA", state: "BA" },
    { value: "Craíbas - AL", text: "Craíbas - AL", state: "AL" },
    { value: "Criciúma - SC", text: "Criciúma - SC", state: "SC" },
    { value: "Crissiumal - RS", text: "Crissiumal - RS", state: "RS" },
    { value: "Cristais - MG", text: "Cristais - MG", state: "MG" },
    { value: "Cristais Paulista - SP", text: "Cristais Paulista - SP", state: "SP" },
    { value: "Cristal - RS", text: "Cristal - RS", state: "RS" },
    { value: "Cristal do Sul - RS", text: "Cristal do Sul - RS", state: "RS" },
    { value: "Cristalina - GO", text: "Cristalina - GO", state: "GO" },
    { value: "Cristalândia - TO", text: "Cristalândia - TO", state: "TO" },
    { value: "Cristalândia do Piauí - PI", text: "Cristalândia do Piauí - PI", state: "PI" },
    { value: "Cristiano Otoni - MG", text: "Cristiano Otoni - MG", state: "MG" },
    { value: "Cristianópolis - GO", text: "Cristianópolis - GO", state: "GO" },
    { value: "Cristina - MG", text: "Cristina - MG", state: "MG" },
    { value: "Cristino Castro - PI", text: "Cristino Castro - PI", state: "PI" },
    { value: "Cristinápolis - SE", text: "Cristinápolis - SE", state: "SE" },
    { value: "Cristália - MG", text: "Cristália - MG", state: "MG" },
    { value: "Cristópolis - BA", text: "Cristópolis - BA", state: "BA" },
    { value: "Crisólita - MG", text: "Crisólita - MG", state: "MG" },
    { value: "Crisópolis - BA", text: "Crisópolis - BA", state: "BA" },
    { value: "Crixás - GO", text: "Crixás - GO", state: "GO" },
    { value: "Crixás do Tocantins - TO", text: "Crixás do Tocantins - TO", state: "TO" },
    { value: "Croatá - CE", text: "Croatá - CE", state: "CE" },
    { value: "Cromínia - GO", text: "Cromínia - GO", state: "GO" },
    { value: "Crucilândia - MG", text: "Crucilândia - MG", state: "MG" },
    { value: "Cruz - CE", text: "Cruz - CE", state: "CE" },
    { value: "Cruz Alta - RS", text: "Cruz Alta - RS", state: "RS" },
    { value: "Cruz Machado - PR", text: "Cruz Machado - PR", state: "PR" },
    { value: "Cruz das Almas - BA", text: "Cruz das Almas - BA", state: "BA" },
    { value: "Cruz do Espírito Santo - PB", text: "Cruz do Espírito Santo - PB", state: "PB" },
    { value: "Cruzaltense - RS", text: "Cruzaltense - RS", state: "RS" },
    { value: "Cruzeiro - DF", text: "Cruzeiro - DF", state: "DF" },
    { value: "Cruzeiro - SP", text: "Cruzeiro - SP", state: "SP" },
    { value: "Cruzeiro da Fortaleza - MG", text: "Cruzeiro da Fortaleza - MG", state: "MG" },
    { value: "Cruzeiro do Iguaçu - PR", text: "Cruzeiro do Iguaçu - PR", state: "PR" },
    { value: "Cruzeiro do Oeste - PR", text: "Cruzeiro do Oeste - PR", state: "PR" },
    { value: "Cruzeiro do Sul - AC", text: "Cruzeiro do Sul - AC", state: "AC" },
    { value: "Cruzeiro do Sul - PR", text: "Cruzeiro do Sul - PR", state: "PR" },
    { value: "Cruzeiro do Sul - RS", text: "Cruzeiro do Sul - RS", state: "RS" },
    { value: "Cruzeta - RN", text: "Cruzeta - RN", state: "RN" },
    { value: "Cruzmaltina - PR", text: "Cruzmaltina - PR", state: "PR" },
    { value: "Cruzália - SP", text: "Cruzália - SP", state: "SP" },
    { value: "Cruzília - MG", text: "Cruzília - MG", state: "MG" },
    { value: "Cubati - PB", text: "Cubati - PB", state: "PB" },
    { value: "Cubatão - SP", text: "Cubatão - SP", state: "SP" },
    { value: "Cuiabá - MT", text: "Cuiabá - MT", state: "MT" },
    { value: "Cuitegi - PB", text: "Cuitegi - PB", state: "PB" },
    { value: "Cuité - PB", text: "Cuité - PB", state: "PB" },
    { value: "Cuité de Mamanguape - PB", text: "Cuité de Mamanguape - PB", state: "PB" },
    { value: "Cujubim - RO", text: "Cujubim - RO", state: "RO" },
    { value: "Cumari - GO", text: "Cumari - GO", state: "GO" },
    { value: "Cumaru - PE", text: "Cumaru - PE", state: "PE" },
    { value: "Cumaru do Norte - PA", text: "Cumaru do Norte - PA", state: "PA" },
    { value: "Cumbe - SE", text: "Cumbe - SE", state: "SE" },
    { value: "Cunha - SP", text: "Cunha - SP", state: "SP" },
    { value: "Cunha Porã - SC", text: "Cunha Porã - SC", state: "SC" },
    { value: "Cunhataí - SC", text: "Cunhataí - SC", state: "SC" },
    { value: "Cuparaque - MG", text: "Cuparaque - MG", state: "MG" },
    { value: "Cupira - PE", text: "Cupira - PE", state: "PE" },
    { value: "Curaçá - BA", text: "Curaçá - BA", state: "BA" },
    { value: "Curimatá - PI", text: "Curimatá - PI", state: "PI" },
    { value: "Curionópolis - PA", text: "Curionópolis - PA", state: "PA" },
    { value: "Curitiba - PR", text: "Curitiba - PR", state: "PR" },
    { value: "Curitibanos - SC", text: "Curitibanos - SC", state: "SC" },
    { value: "Curiúva - PR", text: "Curiúva - PR", state: "PR" },
    { value: "Currais - PI", text: "Currais - PI", state: "PI" },
    { value: "Currais Novos - RN", text: "Currais Novos - RN", state: "RN" },
    { value: "Curral Novo do Piauí - PI", text: "Curral Novo do Piauí - PI", state: "PI" },
    { value: "Curral Velho - PB", text: "Curral Velho - PB", state: "PB" },
    { value: "Curral de Cima - PB", text: "Curral de Cima - PB", state: "PB" },
    { value: "Curral de Dentro - MG", text: "Curral de Dentro - MG", state: "MG" },
    { value: "Curralinho - PA", text: "Curralinho - PA", state: "PA" },
    { value: "Curralinhos - PI", text: "Curralinhos - PI", state: "PI" },
    { value: "Cururupu - MA", text: "Cururupu - MA", state: "MA" },
    { value: "Curuá - PA", text: "Curuá - PA", state: "PA" },
    { value: "Curuçá - PA", text: "Curuçá - PA", state: "PA" },
    { value: "Curvelo - MG", text: "Curvelo - MG", state: "MG" },
    { value: "Curvelândia - MT", text: "Curvelândia - MT", state: "MT" },
    { value: "Custódia - PE", text: "Custódia - PE", state: "PE" },
    { value: "Cutias - AP", text: "Cutias - AP", state: "AP" },
    { value: "Cáceres - MT", text: "Cáceres - MT", state: "MT" },
    { value: "Cássia - MG", text: "Cássia - MG", state: "MG" },
    { value: "Cássia dos Coqueiros - SP", text: "Cássia dos Coqueiros - SP", state: "SP" },
    { value: "Cândido Godói - RS", text: "Cândido Godói - RS", state: "RS" },
    { value: "Cândido Mendes - MA", text: "Cândido Mendes - MA", state: "MA" },
    { value: "Cândido Mota - SP", text: "Cândido Mota - SP", state: "SP" },
    { value: "Cândido Rodrigues - SP", text: "Cândido Rodrigues - SP", state: "SP" },
    { value: "Cândido Sales - BA", text: "Cândido Sales - BA", state: "BA" },
    { value: "Cândido de Abreu - PR", text: "Cândido de Abreu - PR", state: "PR" },
    { value: "Céu Azul - PR", text: "Céu Azul - PR", state: "PR" },
    { value: "Cícero Dantas - BA", text: "Cícero Dantas - BA", state: "BA" },
    { value: "Córrego Danta - MG", text: "Córrego Danta - MG", state: "MG" },
    { value: "Córrego Fundo - MG", text: "Córrego Fundo - MG", state: "MG" },
    { value: "Córrego Novo - MG", text: "Córrego Novo - MG", state: "MG" },
    { value: "Córrego do Bom Jesus - MG", text: "Córrego do Bom Jesus - MG", state: "MG" },
    { value: "Córrego do Ouro - GO", text: "Córrego do Ouro - GO", state: "GO" },
    { value: "Cônego Marinho - MG", text: "Cônego Marinho - MG", state: "MG" },
    { value: "Damianópolis - GO", text: "Damianópolis - GO", state: "GO" },
    { value: "Damião - PB", text: "Damião - PB", state: "PB" },
    { value: "Damolândia - GO", text: "Damolândia - GO", state: "GO" },
    { value: "Darcinópolis - TO", text: "Darcinópolis - TO", state: "TO" },
    { value: "Datas - MG", text: "Datas - MG", state: "MG" },
    { value: "David Canabarro - RS", text: "David Canabarro - RS", state: "RS" },
    { value: "Davinópolis - GO", text: "Davinópolis - GO", state: "GO" },
    { value: "Davinópolis - MA", text: "Davinópolis - MA", state: "MA" },
    { value: "Delfim Moreira - MG", text: "Delfim Moreira - MG", state: "MG" },
    { value: "Delfinópolis - MG", text: "Delfinópolis - MG", state: "MG" },
    { value: "Delmiro Gouveia - AL", text: "Delmiro Gouveia - AL", state: "AL" },
    { value: "Delta - MG", text: "Delta - MG", state: "MG" },
    { value: "Demerval Lobão - PI", text: "Demerval Lobão - PI", state: "PI" },
    { value: "Denise - MT", text: "Denise - MT", state: "MT" },
    { value: "Deodápolis - MS", text: "Deodápolis - MS", state: "MS" },
    { value: "Deputado Irapuan Pinheiro - CE", text: "Deputado Irapuan Pinheiro - CE", state: "CE" },
    { value: "Derrubadas - RS", text: "Derrubadas - RS", state: "RS" },
    { value: "Descalvado - SP", text: "Descalvado - SP", state: "SP" },
    { value: "Descanso - SC", text: "Descanso - SC", state: "SC" },
    { value: "Descoberto - MG", text: "Descoberto - MG", state: "MG" },
    { value: "Desterro - PB", text: "Desterro - PB", state: "PB" },
    { value: "Desterro de Entre Rios - MG", text: "Desterro de Entre Rios - MG", state: "MG" },
    { value: "Desterro do Melo - MG", text: "Desterro do Melo - MG", state: "MG" },
    { value: "Dezesseis de Novembro - RS", text: "Dezesseis de Novembro - RS", state: "RS" },
    { value: "Diadema - SP", text: "Diadema - SP", state: "SP" },
    { value: "Diamante - PB", text: "Diamante - PB", state: "PB" },
    { value: "Diamante d'Oeste - PR", text: "Diamante d'Oeste - PR", state: "PR" },
    { value: "Diamante do Norte - PR", text: "Diamante do Norte - PR", state: "PR" },
    { value: "Diamante do Sul - PR", text: "Diamante do Sul - PR", state: "PR" },
    { value: "Diamantina - MG", text: "Diamantina - MG", state: "MG" },
    { value: "Diamantino - MT", text: "Diamantino - MT", state: "MT" },
    { value: "Dianópolis - TO", text: "Dianópolis - TO", state: "TO" },
    { value: "Dias d'Ávila - BA", text: "Dias d'Ávila - BA", state: "BA" },
    { value: "Dilermando de Aguiar - RS", text: "Dilermando de Aguiar - RS", state: "RS" },
    { value: "Diogo de Vasconcelos - MG", text: "Diogo de Vasconcelos - MG", state: "MG" },
    { value: "Dionísio - MG", text: "Dionísio - MG", state: "MG" },
    { value: "Dionísio Cerqueira - SC", text: "Dionísio Cerqueira - SC", state: "SC" },
    { value: "Diorama - GO", text: "Diorama - GO", state: "GO" },
    { value: "Dirce Reis - SP", text: "Dirce Reis - SP", state: "SP" },
    { value: "Dirceu Arcoverde - PI", text: "Dirceu Arcoverde - PI", state: "PI" },
    { value: "Divina Pastora - SE", text: "Divina Pastora - SE", state: "SE" },
    { value: "Divino - MG", text: "Divino - MG", state: "MG" },
    { value: "Divino das Laranjeiras - MG", text: "Divino das Laranjeiras - MG", state: "MG" },
    { value: "Divino de São Lourenço - ES", text: "Divino de São Lourenço - ES", state: "ES" },
    { value: "Divinolândia - SP", text: "Divinolândia - SP", state: "SP" },
    { value: "Divinolândia de Minas - MG", text: "Divinolândia de Minas - MG", state: "MG" },
    { value: "Divinésia - MG", text: "Divinésia - MG", state: "MG" },
    { value: "Divinópolis - MG", text: "Divinópolis - MG", state: "MG" },
    { value: "Divinópolis de Goiás - GO", text: "Divinópolis de Goiás - GO", state: "GO" },
    { value: "Divinópolis do Tocantins - TO", text: "Divinópolis do Tocantins - TO", state: "TO" },
    { value: "Divisa Alegre - MG", text: "Divisa Alegre - MG", state: "MG" },
    { value: "Divisa Nova - MG", text: "Divisa Nova - MG", state: "MG" },
    { value: "Divisópolis - MG", text: "Divisópolis - MG", state: "MG" },
    { value: "Dobrada - SP", text: "Dobrada - SP", state: "SP" },
    { value: "Dois Córregos - SP", text: "Dois Córregos - SP", state: "SP" },
    { value: "Dois Irmãos - RS", text: "Dois Irmãos - RS", state: "RS" },
    { value: "Dois Irmãos das Missões - RS", text: "Dois Irmãos das Missões - RS", state: "RS" },
    { value: "Dois Irmãos do Buriti - MS", text: "Dois Irmãos do Buriti - MS", state: "MS" },
    { value: "Dois Irmãos do Tocantins - TO", text: "Dois Irmãos do Tocantins - TO", state: "TO" },
    { value: "Dois Lajeados - RS", text: "Dois Lajeados - RS", state: "RS" },
    { value: "Dois Riachos - AL", text: "Dois Riachos - AL", state: "AL" },
    { value: "Dois Vizinhos - PR", text: "Dois Vizinhos - PR", state: "PR" },
    { value: "Dolcinópolis - SP", text: "Dolcinópolis - SP", state: "SP" },
    { value: "Dom Aquino - MT", text: "Dom Aquino - MT", state: "MT" },
    { value: "Dom Basílio - BA", text: "Dom Basílio - BA", state: "BA" },
    { value: "Dom Bosco - MG", text: "Dom Bosco - MG", state: "MG" },
    { value: "Dom Cavati - MG", text: "Dom Cavati - MG", state: "MG" },
    { value: "Dom Eliseu - PA", text: "Dom Eliseu - PA", state: "PA" },
    { value: "Dom Expedito Lopes - PI", text: "Dom Expedito Lopes - PI", state: "PI" },
    { value: "Dom Feliciano - RS", text: "Dom Feliciano - RS", state: "RS" },
    { value: "Dom Inocêncio - PI", text: "Dom Inocêncio - PI", state: "PI" },
    { value: "Dom Joaquim - MG", text: "Dom Joaquim - MG", state: "MG" },
    { value: "Dom Macedo Costa - BA", text: "Dom Macedo Costa - BA", state: "BA" },
    { value: "Dom Pedrito - RS", text: "Dom Pedrito - RS", state: "RS" },
    { value: "Dom Pedro - MA", text: "Dom Pedro - MA", state: "MA" },
    { value: "Dom Pedro de Alcântara - RS", text: "Dom Pedro de Alcântara - RS", state: "RS" },
    { value: "Dom Silvério - MG", text: "Dom Silvério - MG", state: "MG" },
    { value: "Dom Viçoso - MG", text: "Dom Viçoso - MG", state: "MG" },
    { value: "Domingos Martins - ES", text: "Domingos Martins - ES", state: "ES" },
    { value: "Domingos Mourão - PI", text: "Domingos Mourão - PI", state: "PI" },
    { value: "Dona Emma - SC", text: "Dona Emma - SC", state: "SC" },
    { value: "Dona Euzébia - MG", text: "Dona Euzébia - MG", state: "MG" },
    { value: "Dona Francisca - RS", text: "Dona Francisca - RS", state: "RS" },
    { value: "Dona Inês - PB", text: "Dona Inês - PB", state: "PB" },
    { value: "Dores de Campos - MG", text: "Dores de Campos - MG", state: "MG" },
    { value: "Dores de Guanhães - MG", text: "Dores de Guanhães - MG", state: "MG" },
    { value: "Dores do Indaiá - MG", text: "Dores do Indaiá - MG", state: "MG" },
    { value: "Dores do Rio Preto - ES", text: "Dores do Rio Preto - ES", state: "ES" },
    { value: "Dores do Turvo - MG", text: "Dores do Turvo - MG", state: "MG" },
    { value: "Doresópolis - MG", text: "Doresópolis - MG", state: "MG" },
    { value: "Dormentes - PE", text: "Dormentes - PE", state: "PE" },
    { value: "Douradina - MS", text: "Douradina - MS", state: "MS" },
    { value: "Douradina - PR", text: "Douradina - PR", state: "PR" },
    { value: "Dourado - SP", text: "Dourado - SP", state: "SP" },
    { value: "Douradoquara - MG", text: "Douradoquara - MG", state: "MG" },
    { value: "Dourados - MS", text: "Dourados - MS", state: "MS" },
    { value: "Doutor Camargo - PR", text: "Doutor Camargo - PR", state: "PR" },
    { value: "Doutor Maurício Cardoso - RS", text: "Doutor Maurício Cardoso - RS", state: "RS" },
    { value: "Doutor Pedrinho - SC", text: "Doutor Pedrinho - SC", state: "SC" },
    { value: "Doutor Ricardo - RS", text: "Doutor Ricardo - RS", state: "RS" },
    { value: "Doutor Severiano - RN", text: "Doutor Severiano - RN", state: "RN" },
    { value: "Doutor Ulysses - PR", text: "Doutor Ulysses - PR", state: "PR" },
    { value: "Doverlândia - GO", text: "Doverlândia - GO", state: "GO" },
    { value: "Dracena - SP", text: "Dracena - SP", state: "SP" },
    { value: "Duartina - SP", text: "Duartina - SP", state: "SP" },
    { value: "Duas Barras - RJ", text: "Duas Barras - RJ", state: "RJ" },
    { value: "Duas Estradas - PB", text: "Duas Estradas - PB", state: "PB" },
    { value: "Dueré - TO", text: "Dueré - TO", state: "TO" },
    { value: "Dumont - SP", text: "Dumont - SP", state: "SP" },
    { value: "Duque Bacelar - MA", text: "Duque Bacelar - MA", state: "MA" },
    { value: "Duque de Caxias - RJ", text: "Duque de Caxias - RJ", state: "RJ" },
    { value: "Durandé - MG", text: "Durandé - MG", state: "MG" },
    { value: "Dário Meira - BA", text: "Dário Meira - BA", state: "BA" },
    { value: "Echaporã - SP", text: "Echaporã - SP", state: "SP" },
    { value: "Ecoporanga - ES", text: "Ecoporanga - ES", state: "ES" },
    { value: "Edealina - GO", text: "Edealina - GO", state: "GO" },
    { value: "Edéia - GO", text: "Edéia - GO", state: "GO" },
    { value: "Eirunepé - AM", text: "Eirunepé - AM", state: "AM" },
    { value: "Eldorado - MS", text: "Eldorado - MS", state: "MS" },
    { value: "Eldorado - SP", text: "Eldorado - SP", state: "SP" },
    { value: "Eldorado do Sul - RS", text: "Eldorado do Sul - RS", state: "RS" },
    { value: "Eldorado dos Carajás - PA", text: "Eldorado dos Carajás - PA", state: "PA" },
    { value: "Elesbão Veloso - PI", text: "Elesbão Veloso - PI", state: "PI" },
    { value: "Elias Fausto - SP", text: "Elias Fausto - SP", state: "SP" },
    { value: "Eliseu Martins - PI", text: "Eliseu Martins - PI", state: "PI" },
    { value: "Elisiário - SP", text: "Elisiário - SP", state: "SP" },
    { value: "Elísio Medrado - BA", text: "Elísio Medrado - BA", state: "BA" },
    { value: "Elói Mendes - MG", text: "Elói Mendes - MG", state: "MG" },
    { value: "Emas - PB", text: "Emas - PB", state: "PB" },
    { value: "Embaúba - SP", text: "Embaúba - SP", state: "SP" },
    { value: "Embu - SP", text: "Embu - SP", state: "SP" },
    { value: "Embu-Guaçu - SP", text: "Embu-Guaçu - SP", state: "SP" },
    { value: "Emilianópolis - SP", text: "Emilianópolis - SP", state: "SP" },
    { value: "Encantado - RS", text: "Encantado - RS", state: "RS" },
    { value: "Encanto - RN", text: "Encanto - RN", state: "RN" },
    { value: "Encruzilhada - BA", text: "Encruzilhada - BA", state: "BA" },
    { value: "Encruzilhada do Sul - RS", text: "Encruzilhada do Sul - RS", state: "RS" },
    { value: "Engenheiro Beltrão - PR", text: "Engenheiro Beltrão - PR", state: "PR" },
    { value: "Engenheiro Caldas - MG", text: "Engenheiro Caldas - MG", state: "MG" },
    { value: "Engenheiro Coelho - SP", text: "Engenheiro Coelho - SP", state: "SP" },
    { value: "Engenheiro Navarro - MG", text: "Engenheiro Navarro - MG", state: "MG" },
    { value: "Engenheiro Paulo de Frontin - RJ", text: "Engenheiro Paulo de Frontin - RJ", state: "RJ" },
    { value: "Engenho Velho - RS", text: "Engenho Velho - RS", state: "RS" },
    { value: "Entre Folhas - MG", text: "Entre Folhas - MG", state: "MG" },
    { value: "Entre Rios - BA", text: "Entre Rios - BA", state: "BA" },
    { value: "Entre Rios - SC", text: "Entre Rios - SC", state: "SC" },
    { value: "Entre Rios de Minas - MG", text: "Entre Rios de Minas - MG", state: "MG" },
    { value: "Entre Rios do Oeste - PR", text: "Entre Rios do Oeste - PR", state: "PR" },
    { value: "Entre Rios do Sul - RS", text: "Entre Rios do Sul - RS", state: "RS" },
    { value: "Entre-Ijuís - RS", text: "Entre-Ijuís - RS", state: "RS" },
    { value: "Envira - AM", text: "Envira - AM", state: "AM" },
    { value: "Enéas Marques - PR", text: "Enéas Marques - PR", state: "PR" },
    { value: "Epitaciolândia - AC", text: "Epitaciolândia - AC", state: "AC" },
    { value: "Equador - RN", text: "Equador - RN", state: "RN" },
    { value: "Erebango - RS", text: "Erebango - RS", state: "RS" },
    { value: "Erechim - RS", text: "Erechim - RS", state: "RS" },
    { value: "Ererê - CE", text: "Ererê - CE", state: "CE" },
    { value: "Ermo - SC", text: "Ermo - SC", state: "SC" },
    { value: "Ernestina - RS", text: "Ernestina - RS", state: "RS" },
    { value: "Erval Grande - RS", text: "Erval Grande - RS", state: "RS" },
    { value: "Erval Seco - RS", text: "Erval Seco - RS", state: "RS" },
    { value: "Erval Velho - SC", text: "Erval Velho - SC", state: "SC" },
    { value: "Ervália - MG", text: "Ervália - MG", state: "MG" },
    { value: "Escada - PE", text: "Escada - PE", state: "PE" },
    { value: "Esmeralda - RS", text: "Esmeralda - RS", state: "RS" },
    { value: "Esmeraldas - MG", text: "Esmeraldas - MG", state: "MG" },
    { value: "Espera Feliz - MG", text: "Espera Feliz - MG", state: "MG" },
    { value: "Esperantina - PI", text: "Esperantina - PI", state: "PI" },
    { value: "Esperantina - TO", text: "Esperantina - TO", state: "TO" },
    { value: "Esperantinópolis - MA", text: "Esperantinópolis - MA", state: "MA" },
    { value: "Esperança - PB", text: "Esperança - PB", state: "PB" },
    { value: "Esperança Nova - PR", text: "Esperança Nova - PR", state: "PR" },
    { value: "Esperança do Sul - RS", text: "Esperança do Sul - RS", state: "RS" },
    { value: "Espigão Alto do Iguaçu - PR", text: "Espigão Alto do Iguaçu - PR", state: "PR" },
    { value: "Espigão d'Oeste - RO", text: "Espigão d'Oeste - RO", state: "RO" },
    { value: "Espinosa - MG", text: "Espinosa - MG", state: "MG" },
    { value: "Esplanada - BA", text: "Esplanada - BA", state: "BA" },
    { value: "Espumoso - RS", text: "Espumoso - RS", state: "RS" },
    { value: "Espírito Santo - RN", text: "Espírito Santo - RN", state: "RN" },
    { value: "Espírito Santo do Dourado - MG", text: "Espírito Santo do Dourado - MG", state: "MG" },
    { value: "Espírito Santo do Pinhal - SP", text: "Espírito Santo do Pinhal - SP", state: "SP" },
    { value: "Espírito Santo do Turvo - SP", text: "Espírito Santo do Turvo - SP", state: "SP" },
    { value: "Estação - RS", text: "Estação - RS", state: "RS" },
    { value: "Esteio - RS", text: "Esteio - RS", state: "RS" },
    { value: "Estiva - MG", text: "Estiva - MG", state: "MG" },
    { value: "Estiva Gerbi - SP", text: "Estiva Gerbi - SP", state: "SP" },
    { value: "Estreito - MA", text: "Estreito - MA", state: "MA" },
    { value: "Estrela - RS", text: "Estrela - RS", state: "RS" },
    { value: "Estrela Dalva - MG", text: "Estrela Dalva - MG", state: "MG" },
    { value: "Estrela Velha - RS", text: "Estrela Velha - RS", state: "RS" },
    { value: "Estrela d'Oeste - SP", text: "Estrela d'Oeste - SP", state: "SP" },
    { value: "Estrela de Alagoas - AL", text: "Estrela de Alagoas - AL", state: "AL" },
    { value: "Estrela do Indaiá - MG", text: "Estrela do Indaiá - MG", state: "MG" },
    { value: "Estrela do Norte - GO", text: "Estrela do Norte - GO", state: "GO" },
    { value: "Estrela do Norte - SP", text: "Estrela do Norte - SP", state: "SP" },
    { value: "Estrela do Sul - MG", text: "Estrela do Sul - MG", state: "MG" },
    { value: "Estrutural - DF", text: "Estrutural - DF", state: "DF" },
    { value: "Estância - SE", text: "Estância - SE", state: "SE" },
    { value: "Estância Velha - RS", text: "Estância Velha - RS", state: "RS" },
    { value: "Euclides da Cunha - BA", text: "Euclides da Cunha - BA", state: "BA" },
    { value: "Euclides da Cunha Paulista - SP", text: "Euclides da Cunha Paulista - SP", state: "SP" },
    { value: "Eugenópolis - MG", text: "Eugenópolis - MG", state: "MG" },
    { value: "Eugênio de Castro - RS", text: "Eugênio de Castro - RS", state: "RS" },
    { value: "Eunápolis - BA", text: "Eunápolis - BA", state: "BA" },
    { value: "Eusébio - CE", text: "Eusébio - CE", state: "CE" },
    { value: "Ewbank da Câmara - MG", text: "Ewbank da Câmara - MG", state: "MG" },
    { value: "Extrema - MG", text: "Extrema - MG", state: "MG" },
    { value: "Extremoz - RN", text: "Extremoz - RN", state: "RN" },
    { value: "Exu - PE", text: "Exu - PE", state: "PE" },
    { value: "Fagundes - PB", text: "Fagundes - PB", state: "PB" },
    { value: "Fagundes Varela - RS", text: "Fagundes Varela - RS", state: "RS" },
    { value: "Faina - GO", text: "Faina - GO", state: "GO" },
    { value: "Fama - MG", text: "Fama - MG", state: "MG" },
    { value: "Faria Lemos - MG", text: "Faria Lemos - MG", state: "MG" },
    { value: "Farias Brito - CE", text: "Farias Brito - CE", state: "CE" },
    { value: "Faro - PA", text: "Faro - PA", state: "PA" },
    { value: "Farol - PR", text: "Farol - PR", state: "PR" },
    { value: "Farroupilha - RS", text: "Farroupilha - RS", state: "RS" },
    { value: "Fartura - SP", text: "Fartura - SP", state: "SP" },
    { value: "Fartura do Piauí - PI", text: "Fartura do Piauí - PI", state: "PI" },
    { value: "Faxinal - PR", text: "Faxinal - PR", state: "PR" },
    { value: "Faxinal do Soturno - RS", text: "Faxinal do Soturno - RS", state: "RS" },
    { value: "Faxinal dos Guedes - SC", text: "Faxinal dos Guedes - SC", state: "SC" },
    { value: "Faxinalzinho - RS", text: "Faxinalzinho - RS", state: "RS" },
    { value: "Fazenda Nova - GO", text: "Fazenda Nova - GO", state: "GO" },
    { value: "Fazenda Rio Grande - PR", text: "Fazenda Rio Grande - PR", state: "PR" },
    { value: "Fazenda Vilanova - RS", text: "Fazenda Vilanova - RS", state: "RS" },
    { value: "Feijó - AC", text: "Feijó - AC", state: "AC" },
    { value: "Feira Grande - AL", text: "Feira Grande - AL", state: "AL" },
    { value: "Feira Nova - PE", text: "Feira Nova - PE", state: "PE" },
    { value: "Feira Nova - SE", text: "Feira Nova - SE", state: "SE" },
    { value: "Feira Nova do Maranhão - MA", text: "Feira Nova do Maranhão - MA", state: "MA" },
    { value: "Feira da Mata - BA", text: "Feira da Mata - BA", state: "BA" },
    { value: "Feira de Santana - BA", text: "Feira de Santana - BA", state: "BA" },
    { value: "Felipe Guerra - RN", text: "Felipe Guerra - RN", state: "RN" },
    { value: "Felisburgo - MG", text: "Felisburgo - MG", state: "MG" },
    { value: "Felixlândia - MG", text: "Felixlândia - MG", state: "MG" },
    { value: "Feliz - RS", text: "Feliz - RS", state: "RS" },
    { value: "Feliz Deserto - AL", text: "Feliz Deserto - AL", state: "AL" },
    { value: "Feliz Natal - MT", text: "Feliz Natal - MT", state: "MT" },
    { value: "Felício dos Santos - MG", text: "Felício dos Santos - MG", state: "MG" },
    { value: "Fercal - DF", text: "Fercal - DF", state: "DF" },
    { value: "Fernandes Pinheiro - PR", text: "Fernandes Pinheiro - PR", state: "PR" },
    { value: "Fernandes Tourinho - MG", text: "Fernandes Tourinho - MG", state: "MG" },
    { value: "Fernando Falcão - MA", text: "Fernando Falcão - MA", state: "MA" },
    { value: "Fernando Pedroza - RN", text: "Fernando Pedroza - RN", state: "RN" },
    { value: "Fernando Prestes - SP", text: "Fernando Prestes - SP", state: "SP" },
    { value: "Fernando de Noronha - PE", text: "Fernando de Noronha - PE", state: "PE" },
    { value: "Fernandópolis - SP", text: "Fernandópolis - SP", state: "SP" },
    { value: "Fernão - SP", text: "Fernão - SP", state: "SP" },
    { value: "Ferraz de Vasconcelos - SP", text: "Ferraz de Vasconcelos - SP", state: "SP" },
    { value: "Ferreira Gomes - AP", text: "Ferreira Gomes - AP", state: "AP" },
    { value: "Ferreiros - PE", text: "Ferreiros - PE", state: "PE" },
    { value: "Ferros - MG", text: "Ferros - MG", state: "MG" },
    { value: "Fervedouro - MG", text: "Fervedouro - MG", state: "MG" },
    { value: "Figueira - PR", text: "Figueira - PR", state: "PR" },
    { value: "Figueirópolis - TO", text: "Figueirópolis - TO", state: "TO" },
    { value: "Figueirópolis d'Oeste - MT", text: "Figueirópolis d'Oeste - MT", state: "MT" },
    { value: "Filadélfia - BA", text: "Filadélfia - BA", state: "BA" },
    { value: "Filadélfia - TO", text: "Filadélfia - TO", state: "TO" },
    { value: "Firmino Alves - BA", text: "Firmino Alves - BA", state: "BA" },
    { value: "Firminópolis - GO", text: "Firminópolis - GO", state: "GO" },
    { value: "Flexeiras - AL", text: "Flexeiras - AL", state: "AL" },
    { value: "Flor da Serra do Sul - PR", text: "Flor da Serra do Sul - PR", state: "PR" },
    { value: "Flor do Sertão - SC", text: "Flor do Sertão - SC", state: "SC" },
    { value: "Flora Rica - SP", text: "Flora Rica - SP", state: "SP" },
    { value: "Floraí - PR", text: "Floraí - PR", state: "PR" },
    { value: "Floreal - SP", text: "Floreal - SP", state: "SP" },
    { value: "Flores - PE", text: "Flores - PE", state: "PE" },
    { value: "Flores da Cunha - RS", text: "Flores da Cunha - RS", state: "RS" },
    { value: "Flores de Goiás - GO", text: "Flores de Goiás - GO", state: "GO" },
    { value: "Flores do Piauí - PI", text: "Flores do Piauí - PI", state: "PI" },
    { value: "Floresta - PE", text: "Floresta - PE", state: "PE" },
    { value: "Floresta - PR", text: "Floresta - PR", state: "PR" },
    { value: "Floresta Azul - BA", text: "Floresta Azul - BA", state: "BA" },
    { value: "Floresta do Araguaia - PA", text: "Floresta do Araguaia - PA", state: "PA" },
    { value: "Floresta do Piauí - PI", text: "Floresta do Piauí - PI", state: "PI" },
    { value: "Florestal - MG", text: "Florestal - MG", state: "MG" },
    { value: "Florestópolis - PR", text: "Florestópolis - PR", state: "PR" },
    { value: "Floriano - PI", text: "Floriano - PI", state: "PI" },
    { value: "Floriano Peixoto - RS", text: "Floriano Peixoto - RS", state: "RS" },
    { value: "Florianópolis - SC", text: "Florianópolis - SC", state: "SC" },
    { value: "Florânia - RN", text: "Florânia - RN", state: "RN" },
    { value: "Florínia - SP", text: "Florínia - SP", state: "SP" },
    { value: "Flórida - PR", text: "Flórida - PR", state: "PR" },
    { value: "Flórida Paulista - SP", text: "Flórida Paulista - SP", state: "SP" },
    { value: "Fonte Boa - AM", text: "Fonte Boa - AM", state: "AM" },
    { value: "Fontoura Xavier - RS", text: "Fontoura Xavier - RS", state: "RS" },
    { value: "Formiga - MG", text: "Formiga - MG", state: "MG" },
    { value: "Formigueiro - RS", text: "Formigueiro - RS", state: "RS" },
    { value: "Formosa - GO", text: "Formosa - GO", state: "GO" },
    { value: "Formosa da Serra Negra - MA", text: "Formosa da Serra Negra - MA", state: "MA" },
    { value: "Formosa do Oeste - PR", text: "Formosa do Oeste - PR", state: "PR" },
    { value: "Formosa do Rio Preto - BA", text: "Formosa do Rio Preto - BA", state: "BA" },
    { value: "Formosa do Sul - SC", text: "Formosa do Sul - SC", state: "SC" },
    { value: "Formoso - GO", text: "Formoso - GO", state: "GO" },
    { value: "Formoso - MG", text: "Formoso - MG", state: "MG" },
    { value: "Formoso do Araguaia - TO", text: "Formoso do Araguaia - TO", state: "TO" },
    { value: "Forquetinha - RS", text: "Forquetinha - RS", state: "RS" },
    { value: "Forquilha - CE", text: "Forquilha - CE", state: "CE" },
    { value: "Forquilhinha - SC", text: "Forquilhinha - SC", state: "SC" },
    { value: "Fortaleza - CE", text: "Fortaleza - CE", state: "CE" },
    { value: "Fortaleza de Minas - MG", text: "Fortaleza de Minas - MG", state: "MG" },
    { value: "Fortaleza do Tabocão - TO", text: "Fortaleza do Tabocão - TO", state: "TO" },
    { value: "Fortaleza dos Nogueiras - MA", text: "Fortaleza dos Nogueiras - MA", state: "MA" },
    { value: "Fortaleza dos Valos - RS", text: "Fortaleza dos Valos - RS", state: "RS" },
    { value: "Fortim - CE", text: "Fortim - CE", state: "CE" },
    { value: "Fortuna - MA", text: "Fortuna - MA", state: "MA" },
    { value: "Fortuna de Minas - MG", text: "Fortuna de Minas - MG", state: "MG" },
    { value: "Foz do Iguaçu - PR", text: "Foz do Iguaçu - PR", state: "PR" },
    { value: "Foz do Jordão - PR", text: "Foz do Jordão - PR", state: "PR" },
    { value: "Fraiburgo - SC", text: "Fraiburgo - SC", state: "SC" },
    { value: "Franca - SP", text: "Franca - SP", state: "SP" },
    { value: "Francinópolis - PI", text: "Francinópolis - PI", state: "PI" },
    { value: "Francisco Alves - PR", text: "Francisco Alves - PR", state: "PR" },
    { value: "Francisco Ayres - PI", text: "Francisco Ayres - PI", state: "PI" },
    { value: "Francisco Badaró - MG", text: "Francisco Badaró - MG", state: "MG" },
    { value: "Francisco Beltrão - PR", text: "Francisco Beltrão - PR", state: "PR" },
    { value: "Francisco Dantas - RN", text: "Francisco Dantas - RN", state: "RN" },
    { value: "Francisco Dumont - MG", text: "Francisco Dumont - MG", state: "MG" },
    { value: "Francisco Macedo - PI", text: "Francisco Macedo - PI", state: "PI" },
    { value: "Francisco Morato - SP", text: "Francisco Morato - SP", state: "SP" },
    { value: "Francisco Santos - PI", text: "Francisco Santos - PI", state: "PI" },
    { value: "Francisco Sá - MG", text: "Francisco Sá - MG", state: "MG" },
    { value: "Franciscópolis - MG", text: "Franciscópolis - MG", state: "MG" },
    { value: "Franco da Rocha - SP", text: "Franco da Rocha - SP", state: "SP" },
    { value: "Frecheirinha - CE", text: "Frecheirinha - CE", state: "CE" },
    { value: "Frederico Westphalen - RS", text: "Frederico Westphalen - RS", state: "RS" },
    { value: "Frei Gaspar - MG", text: "Frei Gaspar - MG", state: "MG" },
    { value: "Frei Inocêncio - MG", text: "Frei Inocêncio - MG", state: "MG" },
    { value: "Frei Lagonegro - MG", text: "Frei Lagonegro - MG", state: "MG" },
    { value: "Frei Martinho - PB", text: "Frei Martinho - PB", state: "PB" },
    { value: "Frei Miguelinho - PE", text: "Frei Miguelinho - PE", state: "PE" },
    { value: "Frei Paulo - SE", text: "Frei Paulo - SE", state: "SE" },
    { value: "Frei Rogério - SC", text: "Frei Rogério - SC", state: "SC" },
    { value: "Fronteira - MG", text: "Fronteira - MG", state: "MG" },
    { value: "Fronteira dos Vales - MG", text: "Fronteira dos Vales - MG", state: "MG" },
    { value: "Fronteiras - PI", text: "Fronteiras - PI", state: "PI" },
    { value: "Fruta de Leite - MG", text: "Fruta de Leite - MG", state: "MG" },
    { value: "Frutal - MG", text: "Frutal - MG", state: "MG" },
    { value: "Frutuoso Gomes - RN", text: "Frutuoso Gomes - RN", state: "RN" },
    { value: "Fundão - ES", text: "Fundão - ES", state: "ES" },
    { value: "Funilândia - MG", text: "Funilândia - MG", state: "MG" },
    { value: "Fátima - BA", text: "Fátima - BA", state: "BA" },
    { value: "Fátima - TO", text: "Fátima - TO", state: "TO" },
    { value: "Fátima do Sul - MS", text: "Fátima do Sul - MS", state: "MS" },
    { value: "Fênix - PR", text: "Fênix - PR", state: "PR" },
    { value: "Gabriel Monteiro - SP", text: "Gabriel Monteiro - SP", state: "SP" },
    { value: "Gado Bravo - PB", text: "Gado Bravo - PB", state: "PB" },
    { value: "Galiléia - MG", text: "Galiléia - MG", state: "MG" },
    { value: "Galinhos - RN", text: "Galinhos - RN", state: "RN" },
    { value: "Galvão - SC", text: "Galvão - SC", state: "SC" },
    { value: "Gama - DF", text: "Gama - DF", state: "DF" },
    { value: "Gameleira - PE", text: "Gameleira - PE", state: "PE" },
    { value: "Gameleira de Goiás - GO", text: "Gameleira de Goiás - GO", state: "GO" },
    { value: "Gameleiras - MG", text: "Gameleiras - MG", state: "MG" },
    { value: "Gandu - BA", text: "Gandu - BA", state: "BA" },
    { value: "Garanhuns - PE", text: "Garanhuns - PE", state: "PE" },
    { value: "Gararu - SE", text: "Gararu - SE", state: "SE" },
    { value: "Garibaldi - RS", text: "Garibaldi - RS", state: "RS" },
    { value: "Garopaba - SC", text: "Garopaba - SC", state: "SC" },
    { value: "Garrafão do Norte - PA", text: "Garrafão do Norte - PA", state: "PA" },
    { value: "Garruchos - RS", text: "Garruchos - RS", state: "RS" },
    { value: "Garuva - SC", text: "Garuva - SC", state: "SC" },
    { value: "Garça - SP", text: "Garça - SP", state: "SP" },
    { value: "Gaspar - SC", text: "Gaspar - SC", state: "SC" },
    { value: "Gastão Vidigal - SP", text: "Gastão Vidigal - SP", state: "SP" },
    { value: "Gaurama - RS", text: "Gaurama - RS", state: "RS" },
    { value: "Gavião - BA", text: "Gavião - BA", state: "BA" },
    { value: "Gavião Peixoto - SP", text: "Gavião Peixoto - SP", state: "SP" },
    { value: "Gaúcha do Norte - MT", text: "Gaúcha do Norte - MT", state: "MT" },
    { value: "Geminiano - PI", text: "Geminiano - PI", state: "PI" },
    { value: "General Carneiro - MT", text: "General Carneiro - MT", state: "MT" },
    { value: "General Carneiro - PR", text: "General Carneiro - PR", state: "PR" },
    { value: "General Câmara - RS", text: "General Câmara - RS", state: "RS" },
    { value: "General Maynard - SE", text: "General Maynard - SE", state: "SE" },
    { value: "General Salgado - SP", text: "General Salgado - SP", state: "SP" },
    { value: "General Sampaio - CE", text: "General Sampaio - CE", state: "CE" },
    { value: "Gentil - RS", text: "Gentil - RS", state: "RS" },
    { value: "Gentio do Ouro - BA", text: "Gentio do Ouro - BA", state: "BA" },
    { value: "Getulina - SP", text: "Getulina - SP", state: "SP" },
    { value: "Getúlio Vargas - RS", text: "Getúlio Vargas - RS", state: "RS" },
    { value: "Gilbués - PI", text: "Gilbués - PI", state: "PI" },
    { value: "Girau do Ponciano - AL", text: "Girau do Ponciano - AL", state: "AL" },
    { value: "Giruá - RS", text: "Giruá - RS", state: "RS" },
    { value: "Glaucilândia - MG", text: "Glaucilândia - MG", state: "MG" },
    { value: "Glicério - SP", text: "Glicério - SP", state: "SP" },
    { value: "Glorinha - RS", text: "Glorinha - RS", state: "RS" },
    { value: "Glória - BA", text: "Glória - BA", state: "BA" },
    { value: "Glória d'Oeste - MT", text: "Glória d'Oeste - MT", state: "MT" },
    { value: "Glória de Dourados - MS", text: "Glória de Dourados - MS", state: "MS" },
    { value: "Glória do Goitá - PE", text: "Glória do Goitá - PE", state: "PE" },
    { value: "Godofredo Viana - MA", text: "Godofredo Viana - MA", state: "MA" },
    { value: "Godoy Moreira - PR", text: "Godoy Moreira - PR", state: "PR" },
    { value: "Goiabeira - MG", text: "Goiabeira - MG", state: "MG" },
    { value: "Goiana - PE", text: "Goiana - PE", state: "PE" },
    { value: "Goiandira - GO", text: "Goiandira - GO", state: "GO" },
    { value: "Goianinha - RN", text: "Goianinha - RN", state: "RN" },
    { value: "Goianira - GO", text: "Goianira - GO", state: "GO" },
    { value: "Goianorte - TO", text: "Goianorte - TO", state: "TO" },
    { value: "Goianá - MG", text: "Goianá - MG", state: "MG" },
    { value: "Goianápolis - GO", text: "Goianápolis - GO", state: "GO" },
    { value: "Goianésia - GO", text: "Goianésia - GO", state: "GO" },
    { value: "Goianésia do Pará - PA", text: "Goianésia do Pará - PA", state: "PA" },
    { value: "Goiatins - TO", text: "Goiatins - TO", state: "TO" },
    { value: "Goiatuba - GO", text: "Goiatuba - GO", state: "GO" },
    { value: "Goioerê - PR", text: "Goioerê - PR", state: "PR" },
    { value: "Goioxim - PR", text: "Goioxim - PR", state: "PR" },
    { value: "Goiás - GO", text: "Goiás - GO", state: "GO" },
    { value: "Goiânia - GO", text: "Goiânia - GO", state: "GO" },
    { value: "Gongogi - BA", text: "Gongogi - BA", state: "BA" },
    { value: "Gonzaga - MG", text: "Gonzaga - MG", state: "MG" },
    { value: "Gonçalves - MG", text: "Gonçalves - MG", state: "MG" },
    { value: "Gonçalves Dias - MA", text: "Gonçalves Dias - MA", state: "MA" },
    { value: "Gouveia - MG", text: "Gouveia - MG", state: "MG" },
    { value: "Gouvelândia - GO", text: "Gouvelândia - GO", state: "GO" },
    { value: "Governador Archer - MA", text: "Governador Archer - MA", state: "MA" },
    { value: "Governador Celso Ramos - SC", text: "Governador Celso Ramos - SC", state: "SC" },
    { value: "Governador Dix-Sept Rosado - RN", text: "Governador Dix-Sept Rosado - RN", state: "RN" },
    { value: "Governador Edison Lobão - MA", text: "Governador Edison Lobão - MA", state: "MA" },
    { value: "Governador Eugênio Barros - MA", text: "Governador Eugênio Barros - MA", state: "MA" },
    { value: "Governador Jorge Teixeira - RO", text: "Governador Jorge Teixeira - RO", state: "RO" },
    { value: "Governador Lindenberg - ES", text: "Governador Lindenberg - ES", state: "ES" },
    { value: "Governador Luiz Rocha - MA", text: "Governador Luiz Rocha - MA", state: "MA" },
    { value: "Governador Mangabeira - BA", text: "Governador Mangabeira - BA", state: "BA" },
    { value: "Governador Newton Bello - MA", text: "Governador Newton Bello - MA", state: "MA" },
    { value: "Governador Nunes Freire - MA", text: "Governador Nunes Freire - MA", state: "MA" },
    { value: "Governador Valadares - MG", text: "Governador Valadares - MG", state: "MG" },
    { value: "Gracho Cardoso - SE", text: "Gracho Cardoso - SE", state: "SE" },
    { value: "Grajaú - MA", text: "Grajaú - MA", state: "MA" },
    { value: "Gramado - RS", text: "Gramado - RS", state: "RS" },
    { value: "Gramado Xavier - RS", text: "Gramado Xavier - RS", state: "RS" },
    { value: "Gramado dos Loureiros - RS", text: "Gramado dos Loureiros - RS", state: "RS" },
    { value: "Grandes Rios - PR", text: "Grandes Rios - PR", state: "PR" },
    { value: "Granito - PE", text: "Granito - PE", state: "PE" },
    { value: "Granja - CE", text: "Granja - CE", state: "CE" },
    { value: "Granjeiro - CE", text: "Granjeiro - CE", state: "CE" },
    { value: "Gravatal - SC", text: "Gravatal - SC", state: "SC" },
    { value: "Gravataí - RS", text: "Gravataí - RS", state: "RS" },
    { value: "Gravatá - PE", text: "Gravatá - PE", state: "PE" },
    { value: "Graça - CE", text: "Graça - CE", state: "CE" },
    { value: "Graça Aranha - MA", text: "Graça Aranha - MA", state: "MA" },
    { value: "Groaíras - CE", text: "Groaíras - CE", state: "CE" },
    { value: "Grossos - RN", text: "Grossos - RN", state: "RN" },
    { value: "Grupiara - MG", text: "Grupiara - MG", state: "MG" },
    { value: "Grão Mogol - MG", text: "Grão Mogol - MG", state: "MG" },
    { value: "Grão Pará - SC", text: "Grão Pará - SC", state: "SC" },
    { value: "Guabiju - RS", text: "Guabiju - RS", state: "RS" },
    { value: "Guabiruba - SC", text: "Guabiruba - SC", state: "SC" },
    { value: "Guadalupe - PI", text: "Guadalupe - PI", state: "PI" },
    { value: "Guaimbê - SP", text: "Guaimbê - SP", state: "SP" },
    { value: "Guairaçá - PR", text: "Guairaçá - PR", state: "PR" },
    { value: "Guaiçara - SP", text: "Guaiçara - SP", state: "SP" },
    { value: "Guaiúba - CE", text: "Guaiúba - CE", state: "CE" },
    { value: "Guajará - AM", text: "Guajará - AM", state: "AM" },
    { value: "Guajará-Mirim - RO", text: "Guajará-Mirim - RO", state: "RO" },
    { value: "Guajeru - BA", text: "Guajeru - BA", state: "BA" },
    { value: "Guamaré - RN", text: "Guamaré - RN", state: "RN" },
    { value: "Guamiranga - PR", text: "Guamiranga - PR", state: "PR" },
    { value: "Guanambi - BA", text: "Guanambi - BA", state: "BA" },
    { value: "Guanhães - MG", text: "Guanhães - MG", state: "MG" },
    { value: "Guapiara - SP", text: "Guapiara - SP", state: "SP" },
    { value: "Guapiaçu - SP", text: "Guapiaçu - SP", state: "SP" },
    { value: "Guapimirim - RJ", text: "Guapimirim - RJ", state: "RJ" },
    { value: "Guapirama - PR", text: "Guapirama - PR", state: "PR" },
    { value: "Guaporema - PR", text: "Guaporema - PR", state: "PR" },
    { value: "Guaporé - RS", text: "Guaporé - RS", state: "RS" },
    { value: "Guapé - MG", text: "Guapé - MG", state: "MG" },
    { value: "Guapó - GO", text: "Guapó - GO", state: "GO" },
    { value: "Guarabira - PB", text: "Guarabira - PB", state: "PB" },
    { value: "Guaraci - PR", text: "Guaraci - PR", state: "PR" },
    { value: "Guaraci - SP", text: "Guaraci - SP", state: "SP" },
    { value: "Guaraciaba - MG", text: "Guaraciaba - MG", state: "MG" },
    { value: "Guaraciaba - SC", text: "Guaraciaba - SC", state: "SC" },
    { value: "Guaraciaba do Norte - CE", text: "Guaraciaba do Norte - CE", state: "CE" },
    { value: "Guaraciama - MG", text: "Guaraciama - MG", state: "MG" },
    { value: "Guaramiranga - CE", text: "Guaramiranga - CE", state: "CE" },
    { value: "Guaramirim - SC", text: "Guaramirim - SC", state: "SC" },
    { value: "Guarani - MG", text: "Guarani - MG", state: "MG" },
    { value: "Guarani d'Oeste - SP", text: "Guarani d'Oeste - SP", state: "SP" },
    { value: "Guarani das Missões - RS", text: "Guarani das Missões - RS", state: "RS" },
    { value: "Guarani de Goiás - GO", text: "Guarani de Goiás - GO", state: "GO" },
    { value: "Guaraniaçu - PR", text: "Guaraniaçu - PR", state: "PR" },
    { value: "Guarantã - SP", text: "Guarantã - SP", state: "SP" },
    { value: "Guarantã do Norte - MT", text: "Guarantã do Norte - MT", state: "MT" },
    { value: "Guaranésia - MG", text: "Guaranésia - MG", state: "MG" },
    { value: "Guarapari - ES", text: "Guarapari - ES", state: "ES" },
    { value: "Guarapuava - PR", text: "Guarapuava - PR", state: "PR" },
    { value: "Guaraqueçaba - PR", text: "Guaraqueçaba - PR", state: "PR" },
    { value: "Guararapes - SP", text: "Guararapes - SP", state: "SP" },
    { value: "Guararema - SP", text: "Guararema - SP", state: "SP" },
    { value: "Guarará - MG", text: "Guarará - MG", state: "MG" },
    { value: "Guaratinga - BA", text: "Guaratinga - BA", state: "BA" },
    { value: "Guaratinguetá - SP", text: "Guaratinguetá - SP", state: "SP" },
    { value: "Guaratuba - PR", text: "Guaratuba - PR", state: "PR" },
    { value: "Guaraçaí - SP", text: "Guaraçaí - SP", state: "SP" },
    { value: "Guaraí - TO", text: "Guaraí - TO", state: "TO" },
    { value: "Guaraíta - GO", text: "Guaraíta - GO", state: "GO" },
    { value: "Guarda-Mor - MG", text: "Guarda-Mor - MG", state: "MG" },
    { value: "Guareí - SP", text: "Guareí - SP", state: "SP" },
    { value: "Guariba - SP", text: "Guariba - SP", state: "SP" },
    { value: "Guaribas - PI", text: "Guaribas - PI", state: "PI" },
    { value: "Guarinos - GO", text: "Guarinos - GO", state: "GO" },
    { value: "Guarujá - SP", text: "Guarujá - SP", state: "SP" },
    { value: "Guarujá do Sul - SC", text: "Guarujá do Sul - SC", state: "SC" },
    { value: "Guarulhos - SP", text: "Guarulhos - SP", state: "SP" },
    { value: "Guará - DF", text: "Guará - DF", state: "DF" },
    { value: "Guará - SP", text: "Guará - SP", state: "SP" },
    { value: "Guatambú - SC", text: "Guatambú - SC", state: "SC" },
    { value: "Guatapará - SP", text: "Guatapará - SP", state: "SP" },
    { value: "Guaxupé - MG", text: "Guaxupé - MG", state: "MG" },
    { value: "Guaçuí - ES", text: "Guaçuí - ES", state: "ES" },
    { value: "Guaíba - RS", text: "Guaíba - RS", state: "RS" },
    { value: "Guaíra - PR", text: "Guaíra - PR", state: "PR" },
    { value: "Guaíra - SP", text: "Guaíra - SP", state: "SP" },
    { value: "Guia Lopes da Laguna - MS", text: "Guia Lopes da Laguna - MS", state: "MS" },
    { value: "Guidoval - MG", text: "Guidoval - MG", state: "MG" },
    { value: "Guimarânia - MG", text: "Guimarânia - MG", state: "MG" },
    { value: "Guimarães - MA", text: "Guimarães - MA", state: "MA" },
    { value: "Guiratinga - MT", text: "Guiratinga - MT", state: "MT" },
    { value: "Guiricema - MG", text: "Guiricema - MG", state: "MG" },
    { value: "Gurinhatã - MG", text: "Gurinhatã - MG", state: "MG" },
    { value: "Gurinhém - PB", text: "Gurinhém - PB", state: "PB" },
    { value: "Gurjão - PB", text: "Gurjão - PB", state: "PB" },
    { value: "Gurupi - TO", text: "Gurupi - TO", state: "TO" },
    { value: "Gurupá - PA", text: "Gurupá - PA", state: "PA" },
    { value: "Guzolândia - SP", text: "Guzolândia - SP", state: "SP" },
    { value: "Gália - SP", text: "Gália - SP", state: "SP" },
    { value: "Harmonia - RS", text: "Harmonia - RS", state: "RS" },
    { value: "Heitoraí - GO", text: "Heitoraí - GO", state: "GO" },
    { value: "Heliodora - MG", text: "Heliodora - MG", state: "MG" },
    { value: "Heliópolis - BA", text: "Heliópolis - BA", state: "BA" },
    { value: "Herculândia - SP", text: "Herculândia - SP", state: "SP" },
    { value: "Herval - RS", text: "Herval - RS", state: "RS" },
    { value: "Herval d'Oeste - SC", text: "Herval d'Oeste - SC", state: "SC" },
    { value: "Herveiras - RS", text: "Herveiras - RS", state: "RS" },
    { value: "Hidrolina - GO", text: "Hidrolina - GO", state: "GO" },
    { value: "Hidrolândia - CE", text: "Hidrolândia - CE", state: "CE" },
    { value: "Hidrolândia - GO", text: "Hidrolândia - GO", state: "GO" },
    { value: "Holambra - SP", text: "Holambra - SP", state: "SP" },
    { value: "Honório Serpa - PR", text: "Honório Serpa - PR", state: "PR" },
    { value: "Horizonte - CE", text: "Horizonte - CE", state: "CE" },
    { value: "Horizontina - RS", text: "Horizontina - RS", state: "RS" },
    { value: "Hortolândia - SP", text: "Hortolândia - SP", state: "SP" },
    { value: "Hugo Napoleão - PI", text: "Hugo Napoleão - PI", state: "PI" },
    { value: "Hulha Negra - RS", text: "Hulha Negra - RS", state: "RS" },
    { value: "Humaitá - AM", text: "Humaitá - AM", state: "AM" },
    { value: "Humaitá - RS", text: "Humaitá - RS", state: "RS" },
    { value: "Humberto de Campos - MA", text: "Humberto de Campos - MA", state: "MA" },
    { value: "Iacanga - SP", text: "Iacanga - SP", state: "SP" },
    { value: "Iaciara - GO", text: "Iaciara - GO", state: "GO" },
    { value: "Iacri - SP", text: "Iacri - SP", state: "SP" },
    { value: "Iapu - MG", text: "Iapu - MG", state: "MG" },
    { value: "Iaras - SP", text: "Iaras - SP", state: "SP" },
    { value: "Iati - PE", text: "Iati - PE", state: "PE" },
    { value: "Iaçu - BA", text: "Iaçu - BA", state: "BA" },
    { value: "Ibaiti - PR", text: "Ibaiti - PR", state: "PR" },
    { value: "Ibarama - RS", text: "Ibarama - RS", state: "RS" },
    { value: "Ibaretama - CE", text: "Ibaretama - CE", state: "CE" },
    { value: "Ibateguara - AL", text: "Ibateguara - AL", state: "AL" },
    { value: "Ibatiba - ES", text: "Ibatiba - ES", state: "ES" },
    { value: "Ibaté - SP", text: "Ibaté - SP", state: "SP" },
    { value: "Ibema - PR", text: "Ibema - PR", state: "PR" },
    { value: "Ibertioga - MG", text: "Ibertioga - MG", state: "MG" },
    { value: "Ibiam - SC", text: "Ibiam - SC", state: "SC" },
    { value: "Ibiapina - CE", text: "Ibiapina - CE", state: "CE" },
    { value: "Ibiara - PB", text: "Ibiara - PB", state: "PB" },
    { value: "Ibiassucê - BA", text: "Ibiassucê - BA", state: "BA" },
    { value: "Ibiaçá - RS", text: "Ibiaçá - RS", state: "RS" },
    { value: "Ibiaí - MG", text: "Ibiaí - MG", state: "MG" },
    { value: "Ibicaraí - BA", text: "Ibicaraí - BA", state: "BA" },
    { value: "Ibicaré - SC", text: "Ibicaré - SC", state: "SC" },
    { value: "Ibicoara - BA", text: "Ibicoara - BA", state: "BA" },
    { value: "Ibicuitinga - CE", text: "Ibicuitinga - CE", state: "CE" },
    { value: "Ibicuí - BA", text: "Ibicuí - BA", state: "BA" },
    { value: "Ibimirim - PE", text: "Ibimirim - PE", state: "PE" },
    { value: "Ibipeba - BA", text: "Ibipeba - BA", state: "BA" },
    { value: "Ibipitanga - BA", text: "Ibipitanga - BA", state: "BA" },
    { value: "Ibiporã - PR", text: "Ibiporã - PR", state: "PR" },
    { value: "Ibiquera - BA", text: "Ibiquera - BA", state: "BA" },
    { value: "Ibiracatu - MG", text: "Ibiracatu - MG", state: "MG" },
    { value: "Ibiraci - MG", text: "Ibiraci - MG", state: "MG" },
    { value: "Ibiraiaras - RS", text: "Ibiraiaras - RS", state: "RS" },
    { value: "Ibirajuba - PE", text: "Ibirajuba - PE", state: "PE" },
    { value: "Ibirama - SC", text: "Ibirama - SC", state: "SC" },
    { value: "Ibirapitanga - BA", text: "Ibirapitanga - BA", state: "BA" },
    { value: "Ibirapuitã - RS", text: "Ibirapuitã - RS", state: "RS" },
    { value: "Ibirapuã - BA", text: "Ibirapuã - BA", state: "BA" },
    { value: "Ibirarema - SP", text: "Ibirarema - SP", state: "SP" },
    { value: "Ibirataia - BA", text: "Ibirataia - BA", state: "BA" },
    { value: "Ibiraçu - ES", text: "Ibiraçu - ES", state: "ES" },
    { value: "Ibirité - MG", text: "Ibirité - MG", state: "MG" },
    { value: "Ibirubá - RS", text: "Ibirubá - RS", state: "RS" },
    { value: "Ibirá - SP", text: "Ibirá - SP", state: "SP" },
    { value: "Ibitiara - BA", text: "Ibitiara - BA", state: "BA" },
    { value: "Ibitinga - SP", text: "Ibitinga - SP", state: "SP" },
    { value: "Ibitirama - ES", text: "Ibitirama - ES", state: "ES" },
    { value: "Ibititá - BA", text: "Ibititá - BA", state: "BA" },
    { value: "Ibitiúra de Minas - MG", text: "Ibitiúra de Minas - MG", state: "MG" },
    { value: "Ibituruna - MG", text: "Ibituruna - MG", state: "MG" },
    { value: "Ibiá - MG", text: "Ibiá - MG", state: "MG" },
    { value: "Ibiúna - SP", text: "Ibiúna - SP", state: "SP" },
    { value: "Ibotirama - BA", text: "Ibotirama - BA", state: "BA" },
    { value: "Icapuí - CE", text: "Icapuí - CE", state: "CE" },
    { value: "Icaraí de Minas - MG", text: "Icaraí de Minas - MG", state: "MG" },
    { value: "Icaraíma - PR", text: "Icaraíma - PR", state: "PR" },
    { value: "Icatu - MA", text: "Icatu - MA", state: "MA" },
    { value: "Ichu - BA", text: "Ichu - BA", state: "BA" },
    { value: "Iconha - ES", text: "Iconha - ES", state: "ES" },
    { value: "Icém - SP", text: "Icém - SP", state: "SP" },
    { value: "Icó - CE", text: "Icó - CE", state: "CE" },
    { value: "Ielmo Marinho - RN", text: "Ielmo Marinho - RN", state: "RN" },
    { value: "Iepê - SP", text: "Iepê - SP", state: "SP" },
    { value: "Igaci - AL", text: "Igaci - AL", state: "AL" },
    { value: "Igaporã - BA", text: "Igaporã - BA", state: "BA" },
    { value: "Igaracy - PB", text: "Igaracy - PB", state: "PB" },
    { value: "Igarapava - SP", text: "Igarapava - SP", state: "SP" },
    { value: "Igarapé - MG", text: "Igarapé - MG", state: "MG" },
    { value: "Igarapé Grande - MA", text: "Igarapé Grande - MA", state: "MA" },
    { value: "Igarapé do Meio - MA", text: "Igarapé do Meio - MA", state: "MA" },
    { value: "Igarapé-Açu - PA", text: "Igarapé-Açu - PA", state: "PA" },
    { value: "Igarapé-Miri - PA", text: "Igarapé-Miri - PA", state: "PA" },
    { value: "Igarassu - PE", text: "Igarassu - PE", state: "PE" },
    { value: "Igaratinga - MG", text: "Igaratinga - MG", state: "MG" },
    { value: "Igaratá - SP", text: "Igaratá - SP", state: "SP" },
    { value: "Igaraçu do Tietê - SP", text: "Igaraçu do Tietê - SP", state: "SP" },
    { value: "Igrapiúna - BA", text: "Igrapiúna - BA", state: "BA" },
    { value: "Igreja Nova - AL", text: "Igreja Nova - AL", state: "AL" },
    { value: "Igrejinha - RS", text: "Igrejinha - RS", state: "RS" },
    { value: "Iguaba Grande - RJ", text: "Iguaba Grande - RJ", state: "RJ" },
    { value: "Iguape - SP", text: "Iguape - SP", state: "SP" },
    { value: "Iguaraci - PE", text: "Iguaraci - PE", state: "PE" },
    { value: "Iguaraçu - PR", text: "Iguaraçu - PR", state: "PR" },
    { value: "Iguatama - MG", text: "Iguatama - MG", state: "MG" },
    { value: "Iguatemi - MS", text: "Iguatemi - MS", state: "MS" },
    { value: "Iguatu - CE", text: "Iguatu - CE", state: "CE" },
    { value: "Iguatu - PR", text: "Iguatu - PR", state: "PR" },
    { value: "Iguaí - BA", text: "Iguaí - BA", state: "BA" },
    { value: "Ijaci - MG", text: "Ijaci - MG", state: "MG" },
    { value: "Ijuí - RS", text: "Ijuí - RS", state: "RS" },
    { value: "Ilha Comprida - SP", text: "Ilha Comprida - SP", state: "SP" },
    { value: "Ilha Grande - PI", text: "Ilha Grande - PI", state: "PI" },
    { value: "Ilha Solteira - SP", text: "Ilha Solteira - SP", state: "SP" },
    { value: "Ilha das Flores - SE", text: "Ilha das Flores - SE", state: "SE" },
    { value: "Ilhabela - SP", text: "Ilhabela - SP", state: "SP" },
    { value: "Ilhota - SC", text: "Ilhota - SC", state: "SC" },
    { value: "Ilhéus - BA", text: "Ilhéus - BA", state: "BA" },
    { value: "Ilicínea - MG", text: "Ilicínea - MG", state: "MG" },
    { value: "Ilópolis - RS", text: "Ilópolis - RS", state: "RS" },
    { value: "Imaculada - PB", text: "Imaculada - PB", state: "PB" },
    { value: "Imaruí - SC", text: "Imaruí - SC", state: "SC" },
    { value: "Imbaú - PR", text: "Imbaú - PR", state: "PR" },
    { value: "Imbituba - SC", text: "Imbituba - SC", state: "SC" },
    { value: "Imbituva - PR", text: "Imbituva - PR", state: "PR" },
    { value: "Imbuia - SC", text: "Imbuia - SC", state: "SC" },
    { value: "Imbé - RS", text: "Imbé - RS", state: "RS" },
    { value: "Imbé de Minas - MG", text: "Imbé de Minas - MG", state: "MG" },
    { value: "Imigrante - RS", text: "Imigrante - RS", state: "RS" },
    { value: "Imperatriz - MA", text: "Imperatriz - MA", state: "MA" },
    { value: "Inaciolândia - GO", text: "Inaciolândia - GO", state: "GO" },
    { value: "Inajá - PE", text: "Inajá - PE", state: "PE" },
    { value: "Inajá - PR", text: "Inajá - PR", state: "PR" },
    { value: "Inconfidentes - MG", text: "Inconfidentes - MG", state: "MG" },
    { value: "Indaiabira - MG", text: "Indaiabira - MG", state: "MG" },
    { value: "Indaial - SC", text: "Indaial - SC", state: "SC" },
    { value: "Indaiatuba - SP", text: "Indaiatuba - SP", state: "SP" },
    { value: "Independência - CE", text: "Independência - CE", state: "CE" },
    { value: "Independência - RS", text: "Independência - RS", state: "RS" },
    { value: "Indiana - SP", text: "Indiana - SP", state: "SP" },
    { value: "Indianópolis - MG", text: "Indianópolis - MG", state: "MG" },
    { value: "Indianópolis - PR", text: "Indianópolis - PR", state: "PR" },
    { value: "Indiaporã - SP", text: "Indiaporã - SP", state: "SP" },
    { value: "Indiara - GO", text: "Indiara - GO", state: "GO" },
    { value: "Indiaroba - SE", text: "Indiaroba - SE", state: "SE" },
    { value: "Indiavaí - MT", text: "Indiavaí - MT", state: "MT" },
    { value: "Ingazeira - PE", text: "Ingazeira - PE", state: "PE" },
    { value: "Ingaí - MG", text: "Ingaí - MG", state: "MG" },
    { value: "Ingá - PB", text: "Ingá - PB", state: "PB" },
    { value: "Inhacorá - RS", text: "Inhacorá - RS", state: "RS" },
    { value: "Inhambupe - BA", text: "Inhambupe - BA", state: "BA" },
    { value: "Inhangapi - PA", text: "Inhangapi - PA", state: "PA" },
    { value: "Inhapi - AL", text: "Inhapi - AL", state: "AL" },
    { value: "Inhapim - MG", text: "Inhapim - MG", state: "MG" },
    { value: "Inhaúma - MG", text: "Inhaúma - MG", state: "MG" },
    { value: "Inhuma - PI", text: "Inhuma - PI", state: "PI" },
    { value: "Inhumas - GO", text: "Inhumas - GO", state: "GO" },
    { value: "Inimutaba - MG", text: "Inimutaba - MG", state: "MG" },
    { value: "Inocência - MS", text: "Inocência - MS", state: "MS" },
    { value: "Inácio Martins - PR", text: "Inácio Martins - PR", state: "PR" },
    { value: "Inúbia Paulista - SP", text: "Inúbia Paulista - SP", state: "SP" },
    { value: "Iomerê - SC", text: "Iomerê - SC", state: "SC" },
    { value: "Ipaba - MG", text: "Ipaba - MG", state: "MG" },
    { value: "Ipameri - GO", text: "Ipameri - GO", state: "GO" },
    { value: "Ipanema - MG", text: "Ipanema - MG", state: "MG" },
    { value: "Ipanguaçu - RN", text: "Ipanguaçu - RN", state: "RN" },
    { value: "Ipaporanga - CE", text: "Ipaporanga - CE", state: "CE" },
    { value: "Ipatinga - MG", text: "Ipatinga - MG", state: "MG" },
    { value: "Ipaumirim - CE", text: "Ipaumirim - CE", state: "CE" },
    { value: "Ipauçu - SP", text: "Ipauçu - SP", state: "SP" },
    { value: "Ipecaetá - BA", text: "Ipecaetá - BA", state: "BA" },
    { value: "Iperó - SP", text: "Iperó - SP", state: "SP" },
    { value: "Ipeúna - SP", text: "Ipeúna - SP", state: "SP" },
    { value: "Ipiaçu - MG", text: "Ipiaçu - MG", state: "MG" },
    { value: "Ipiaú - BA", text: "Ipiaú - BA", state: "BA" },
    { value: "Ipiguá - SP", text: "Ipiguá - SP", state: "SP" },
    { value: "Ipira - SC", text: "Ipira - SC", state: "SC" },
    { value: "Ipiranga - PR", text: "Ipiranga - PR", state: "PR" },
    { value: "Ipiranga de Goiás - GO", text: "Ipiranga de Goiás - GO", state: "GO" },
    { value: "Ipiranga do Piauí - PI", text: "Ipiranga do Piauí - PI", state: "PI" },
    { value: "Ipiranga do Sul - RS", text: "Ipiranga do Sul - RS", state: "RS" },
    { value: "Ipirá - BA", text: "Ipirá - BA", state: "BA" },
    { value: "Ipixuna - AM", text: "Ipixuna - AM", state: "AM" },
    { value: "Ipixuna do Pará - PA", text: "Ipixuna do Pará - PA", state: "PA" },
    { value: "Ipojuca - PE", text: "Ipojuca - PE", state: "PE" },
    { value: "Iporanga - SP", text: "Iporanga - SP", state: "SP" },
    { value: "Iporá - GO", text: "Iporá - GO", state: "GO" },
    { value: "Iporã - PR", text: "Iporã - PR", state: "PR" },
    { value: "Iporã do Oeste - SC", text: "Iporã do Oeste - SC", state: "SC" },
    { value: "Ipu - CE", text: "Ipu - CE", state: "CE" },
    { value: "Ipuaçu - SC", text: "Ipuaçu - SC", state: "SC" },
    { value: "Ipubi - PE", text: "Ipubi - PE", state: "PE" },
    { value: "Ipueira - RN", text: "Ipueira - RN", state: "RN" },
    { value: "Ipueiras - CE", text: "Ipueiras - CE", state: "CE" },
    { value: "Ipueiras - TO", text: "Ipueiras - TO", state: "TO" },
    { value: "Ipuiúna - MG", text: "Ipuiúna - MG", state: "MG" },
    { value: "Ipumirim - SC", text: "Ipumirim - SC", state: "SC" },
    { value: "Ipupiara - BA", text: "Ipupiara - BA", state: "BA" },
    { value: "Ipuã - SP", text: "Ipuã - SP", state: "SP" },
    { value: "Ipê - RS", text: "Ipê - RS", state: "RS" },
    { value: "Iracema - CE", text: "Iracema - CE", state: "CE" },
    { value: "Iracema - RR", text: "Iracema - RR", state: "RR" },
    { value: "Iracema do Oeste - PR", text: "Iracema do Oeste - PR", state: "PR" },
    { value: "Iraceminha - SC", text: "Iraceminha - SC", state: "SC" },
    { value: "Iracemápolis - SP", text: "Iracemápolis - SP", state: "SP" },
    { value: "Irajuba - BA", text: "Irajuba - BA", state: "BA" },
    { value: "Iramaia - BA", text: "Iramaia - BA", state: "BA" },
    { value: "Iranduba - AM", text: "Iranduba - AM", state: "AM" },
    { value: "Irani - SC", text: "Irani - SC", state: "SC" },
    { value: "Irapuru - SP", text: "Irapuru - SP", state: "SP" },
    { value: "Irapuã - SP", text: "Irapuã - SP", state: "SP" },
    { value: "Iraquara - BA", text: "Iraquara - BA", state: "BA" },
    { value: "Irará - BA", text: "Irará - BA", state: "BA" },
    { value: "Irati - PR", text: "Irati - PR", state: "PR" },
    { value: "Irati - SC", text: "Irati - SC", state: "SC" },
    { value: "Irauçuba - CE", text: "Irauçuba - CE", state: "CE" },
    { value: "Iraí - RS", text: "Iraí - RS", state: "RS" },
    { value: "Iraí de Minas - MG", text: "Iraí de Minas - MG", state: "MG" },
    { value: "Irecê - BA", text: "Irecê - BA", state: "BA" },
    { value: "Iretama - PR", text: "Iretama - PR", state: "PR" },
    { value: "Irineópolis - SC", text: "Irineópolis - SC", state: "SC" },
    { value: "Irituia - PA", text: "Irituia - PA", state: "PA" },
    { value: "Irupi - ES", text: "Irupi - ES", state: "ES" },
    { value: "Isaías Coelho - PI", text: "Isaías Coelho - PI", state: "PI" },
    { value: "Israelândia - GO", text: "Israelândia - GO", state: "GO" },
    { value: "Itaara - RS", text: "Itaara - RS", state: "RS" },
    { value: "Itabaiana - PB", text: "Itabaiana - PB", state: "PB" },
    { value: "Itabaiana - SE", text: "Itabaiana - SE", state: "SE" },
    { value: "Itabaianinha - SE", text: "Itabaianinha - SE", state: "SE" },
    { value: "Itabela - BA", text: "Itabela - BA", state: "BA" },
    { value: "Itaberaba - BA", text: "Itaberaba - BA", state: "BA" },
    { value: "Itaberaí - GO", text: "Itaberaí - GO", state: "GO" },
    { value: "Itaberá - SP", text: "Itaberá - SP", state: "SP" },
    { value: "Itabi - SE", text: "Itabi - SE", state: "SE" },
    { value: "Itabira - MG", text: "Itabira - MG", state: "MG" },
    { value: "Itabirinha de Mantena - MG", text: "Itabirinha de Mantena - MG", state: "MG" },
    { value: "Itabirito - MG", text: "Itabirito - MG", state: "MG" },
    { value: "Itaboraí - RJ", text: "Itaboraí - RJ", state: "RJ" },
    { value: "Itabuna - BA", text: "Itabuna - BA", state: "BA" },
    { value: "Itacajá - TO", text: "Itacajá - TO", state: "TO" },
    { value: "Itacambira - MG", text: "Itacambira - MG", state: "MG" },
    { value: "Itacarambi - MG", text: "Itacarambi - MG", state: "MG" },
    { value: "Itacaré - BA", text: "Itacaré - BA", state: "BA" },
    { value: "Itacoatiara - AM", text: "Itacoatiara - AM", state: "AM" },
    { value: "Itacuruba - PE", text: "Itacuruba - PE", state: "PE" },
    { value: "Itacurubi - RS", text: "Itacurubi - RS", state: "RS" },
    { value: "Itaeté - BA", text: "Itaeté - BA", state: "BA" },
    { value: "Itagi - BA", text: "Itagi - BA", state: "BA" },
    { value: "Itagibá - BA", text: "Itagibá - BA", state: "BA" },
    { value: "Itagimirim - BA", text: "Itagimirim - BA", state: "BA" },
    { value: "Itaguajé - PR", text: "Itaguajé - PR", state: "PR" },
    { value: "Itaguara - MG", text: "Itaguara - MG", state: "MG" },
    { value: "Itaguari - GO", text: "Itaguari - GO", state: "GO" },
    { value: "Itaguaru - GO", text: "Itaguaru - GO", state: "GO" },
    { value: "Itaguatins - TO", text: "Itaguatins - TO", state: "TO" },
    { value: "Itaguaçu - ES", text: "Itaguaçu - ES", state: "ES" },
    { value: "Itaguaçu da Bahia - BA", text: "Itaguaçu da Bahia - BA", state: "BA" },
    { value: "Itaguaí - RJ", text: "Itaguaí - RJ", state: "RJ" },
    { value: "Itainópolis - PI", text: "Itainópolis - PI", state: "PI" },
    { value: "Itaipava do Grajaú - MA", text: "Itaipava do Grajaú - MA", state: "MA" },
    { value: "Itaipulândia - PR", text: "Itaipulândia - PR", state: "PR" },
    { value: "Itaipé - MG", text: "Itaipé - MG", state: "MG" },
    { value: "Itaitinga - CE", text: "Itaitinga - CE", state: "CE" },
    { value: "Itaituba - PA", text: "Itaituba - PA", state: "PA" },
    { value: "Itaiçaba - CE", text: "Itaiçaba - CE", state: "CE" },
    { value: "Itaiópolis - SC", text: "Itaiópolis - SC", state: "SC" },
    { value: "Itajaí - SC", text: "Itajaí - SC", state: "SC" },
    { value: "Itajobi - SP", text: "Itajobi - SP", state: "SP" },
    { value: "Itaju - SP", text: "Itaju - SP", state: "SP" },
    { value: "Itaju do Colônia - BA", text: "Itaju do Colônia - BA", state: "BA" },
    { value: "Itajubá - MG", text: "Itajubá - MG", state: "MG" },
    { value: "Itajuípe - BA", text: "Itajuípe - BA", state: "BA" },
    { value: "Itajá - GO", text: "Itajá - GO", state: "GO" },
    { value: "Itajá - RN", text: "Itajá - RN", state: "RN" },
    { value: "Italva - RJ", text: "Italva - RJ", state: "RJ" },
    { value: "Itamaracá - PE", text: "Itamaracá - PE", state: "PE" },
    { value: "Itamaraju - BA", text: "Itamaraju - BA", state: "BA" },
    { value: "Itamarandiba - MG", text: "Itamarandiba - MG", state: "MG" },
    { value: "Itamarati - AM", text: "Itamarati - AM", state: "AM" },
    { value: "Itamarati de Minas - MG", text: "Itamarati de Minas - MG", state: "MG" },
    { value: "Itamari - BA", text: "Itamari - BA", state: "BA" },
    { value: "Itambacuri - MG", text: "Itambacuri - MG", state: "MG" },
    { value: "Itambaracá - PR", text: "Itambaracá - PR", state: "PR" },
    { value: "Itambé - BA", text: "Itambé - BA", state: "BA" },
    { value: "Itambé - PE", text: "Itambé - PE", state: "PE" },
    { value: "Itambé - PR", text: "Itambé - PR", state: "PR" },
    { value: "Itambé do Mato Dentro - MG", text: "Itambé do Mato Dentro - MG", state: "MG" },
    { value: "Itamogi - MG", text: "Itamogi - MG", state: "MG" },
    { value: "Itamonte - MG", text: "Itamonte - MG", state: "MG" },
    { value: "Itanagra - BA", text: "Itanagra - BA", state: "BA" },
    { value: "Itanhandu - MG", text: "Itanhandu - MG", state: "MG" },
    { value: "Itanhaém - SP", text: "Itanhaém - SP", state: "SP" },
    { value: "Itanhomi - MG", text: "Itanhomi - MG", state: "MG" },
    { value: "Itanhém - BA", text: "Itanhém - BA", state: "BA" },
    { value: "Itaobim - MG", text: "Itaobim - MG", state: "MG" },
    { value: "Itaocara - RJ", text: "Itaocara - RJ", state: "RJ" },
    { value: "Itapaci - GO", text: "Itapaci - GO", state: "GO" },
    { value: "Itapagipe - MG", text: "Itapagipe - MG", state: "MG" },
    { value: "Itapajé - CE", text: "Itapajé - CE", state: "CE" },
    { value: "Itaparica - BA", text: "Itaparica - BA", state: "BA" },
    { value: "Itapebi - BA", text: "Itapebi - BA", state: "BA" },
    { value: "Itapecerica - MG", text: "Itapecerica - MG", state: "MG" },
    { value: "Itapecerica da Serra - SP", text: "Itapecerica da Serra - SP", state: "SP" },
    { value: "Itapecuru Mirim - MA", text: "Itapecuru Mirim - MA", state: "MA" },
    { value: "Itapejara d'Oeste - PR", text: "Itapejara d'Oeste - PR", state: "PR" },
    { value: "Itapema - SC", text: "Itapema - SC", state: "SC" },
    { value: "Itapemirim - ES", text: "Itapemirim - ES", state: "ES" },
    { value: "Itaperuna - RJ", text: "Itaperuna - RJ", state: "RJ" },
    { value: "Itaperuçu - PR", text: "Itaperuçu - PR", state: "PR" },
    { value: "Itapetim - PE", text: "Itapetim - PE", state: "PE" },
    { value: "Itapetinga - BA", text: "Itapetinga - BA", state: "BA" },
    { value: "Itapetininga - SP", text: "Itapetininga - SP", state: "SP" },
    { value: "Itapeva - MG", text: "Itapeva - MG", state: "MG" },
    { value: "Itapeva - SP", text: "Itapeva - SP", state: "SP" },
    { value: "Itapevi - SP", text: "Itapevi - SP", state: "SP" },
    { value: "Itapicuru - BA", text: "Itapicuru - BA", state: "BA" },
    { value: "Itapipoca - CE", text: "Itapipoca - CE", state: "CE" },
    { value: "Itapira - SP", text: "Itapira - SP", state: "SP" },
    { value: "Itapiranga - AM", text: "Itapiranga - AM", state: "AM" },
    { value: "Itapiranga - SC", text: "Itapiranga - SC", state: "SC" },
    { value: "Itapirapuã - GO", text: "Itapirapuã - GO", state: "GO" },
    { value: "Itapirapuã Paulista - SP", text: "Itapirapuã Paulista - SP", state: "SP" },
    { value: "Itapiratins - TO", text: "Itapiratins - TO", state: "TO" },
    { value: "Itapissuma - PE", text: "Itapissuma - PE", state: "PE" },
    { value: "Itapitanga - BA", text: "Itapitanga - BA", state: "BA" },
    { value: "Itapiúna - CE", text: "Itapiúna - CE", state: "CE" },
    { value: "Itaporanga - PB", text: "Itaporanga - PB", state: "PB" },
    { value: "Itaporanga - SP", text: "Itaporanga - SP", state: "SP" },
    { value: "Itaporanga d'Ajuda - SE", text: "Itaporanga d'Ajuda - SE", state: "SE" },
    { value: "Itapororoca - PB", text: "Itapororoca - PB", state: "PB" },
    { value: "Itaporã - MS", text: "Itaporã - MS", state: "MS" },
    { value: "Itaporã do Tocantins - TO", text: "Itaporã do Tocantins - TO", state: "TO" },
    { value: "Itapoá - SC", text: "Itapoá - SC", state: "SC" },
    { value: "Itapoã - DF", text: "Itapoã - DF", state: "DF" },
    { value: "Itapuca - RS", text: "Itapuca - RS", state: "RS" },
    { value: "Itapura - SP", text: "Itapura - SP", state: "SP" },
    { value: "Itapuranga - GO", text: "Itapuranga - GO", state: "GO" },
    { value: "Itapuã do Oeste - RO", text: "Itapuã do Oeste - RO", state: "RO" },
    { value: "Itapuí - SP", text: "Itapuí - SP", state: "SP" },
    { value: "Itapé - BA", text: "Itapé - BA", state: "BA" },
    { value: "Itaquaquecetuba - SP", text: "Itaquaquecetuba - SP", state: "SP" },
    { value: "Itaquara - BA", text: "Itaquara - BA", state: "BA" },
    { value: "Itaqui - RS", text: "Itaqui - RS", state: "RS" },
    { value: "Itaquiraí - MS", text: "Itaquiraí - MS", state: "MS" },
    { value: "Itaquitinga - PE", text: "Itaquitinga - PE", state: "PE" },
    { value: "Itarana - ES", text: "Itarana - ES", state: "ES" },
    { value: "Itarantim - BA", text: "Itarantim - BA", state: "BA" },
    { value: "Itararé - SP", text: "Itararé - SP", state: "SP" },
    { value: "Itarema - CE", text: "Itarema - CE", state: "CE" },
    { value: "Itariri - SP", text: "Itariri - SP", state: "SP" },
    { value: "Itarumã - GO", text: "Itarumã - GO", state: "GO" },
    { value: "Itati - RS", text: "Itati - RS", state: "RS" },
    { value: "Itatiaia - RJ", text: "Itatiaia - RJ", state: "RJ" },
    { value: "Itatiaiuçu - MG", text: "Itatiaiuçu - MG", state: "MG" },
    { value: "Itatiba - SP", text: "Itatiba - SP", state: "SP" },
    { value: "Itatiba do Sul - RS", text: "Itatiba do Sul - RS", state: "RS" },
    { value: "Itatim - BA", text: "Itatim - BA", state: "BA" },
    { value: "Itatinga - SP", text: "Itatinga - SP", state: "SP" },
    { value: "Itatira - CE", text: "Itatira - CE", state: "CE" },
    { value: "Itatuba - PB", text: "Itatuba - PB", state: "PB" },
    { value: "Itaubal - AP", text: "Itaubal - AP", state: "AP" },
    { value: "Itaueira - PI", text: "Itaueira - PI", state: "PI" },
    { value: "Itauçu - GO", text: "Itauçu - GO", state: "GO" },
    { value: "Itaverava - MG", text: "Itaverava - MG", state: "MG" },
    { value: "Itaí - SP", text: "Itaí - SP", state: "SP" },
    { value: "Itaíba - PE", text: "Itaíba - PE", state: "PE" },
    { value: "Itaóca - SP", text: "Itaóca - SP", state: "SP" },
    { value: "Itaú - RN", text: "Itaú - RN", state: "RN" },
    { value: "Itaú de Minas - MG", text: "Itaú de Minas - MG", state: "MG" },
    { value: "Itaúba - MT", text: "Itaúba - MT", state: "MT" },
    { value: "Itaúna - MG", text: "Itaúna - MG", state: "MG" },
    { value: "Itaúna do Sul - PR", text: "Itaúna do Sul - PR", state: "PR" },
    { value: "Itinga - MG", text: "Itinga - MG", state: "MG" },
    { value: "Itinga do Maranhão - MA", text: "Itinga do Maranhão - MA", state: "MA" },
    { value: "Itiquira - MT", text: "Itiquira - MT", state: "MT" },
    { value: "Itirapina - SP", text: "Itirapina - SP", state: "SP" },
    { value: "Itirapuã - SP", text: "Itirapuã - SP", state: "SP" },
    { value: "Itiruçu - BA", text: "Itiruçu - BA", state: "BA" },
    { value: "Itiúba - BA", text: "Itiúba - BA", state: "BA" },
    { value: "Itobi - SP", text: "Itobi - SP", state: "SP" },
    { value: "Itororó - BA", text: "Itororó - BA", state: "BA" },
    { value: "Itu - SP", text: "Itu - SP", state: "SP" },
    { value: "Ituaçu - BA", text: "Ituaçu - BA", state: "BA" },
    { value: "Ituberá - BA", text: "Ituberá - BA", state: "BA" },
    { value: "Itueta - MG", text: "Itueta - MG", state: "MG" },
    { value: "Ituiutaba - MG", text: "Ituiutaba - MG", state: "MG" },
    { value: "Itumbiara - GO", text: "Itumbiara - GO", state: "GO" },
    { value: "Itumirim - MG", text: "Itumirim - MG", state: "MG" },
    { value: "Itupeva - SP", text: "Itupeva - SP", state: "SP" },
    { value: "Itupiranga - PA", text: "Itupiranga - PA", state: "PA" },
    { value: "Ituporanga - SC", text: "Ituporanga - SC", state: "SC" },
    { value: "Iturama - MG", text: "Iturama - MG", state: "MG" },
    { value: "Itutinga - MG", text: "Itutinga - MG", state: "MG" },
    { value: "Ituverava - SP", text: "Ituverava - SP", state: "SP" },
    { value: "Itá - SC", text: "Itá - SC", state: "SC" },
    { value: "Itápolis - SP", text: "Itápolis - SP", state: "SP" },
    { value: "Iuiú - BA", text: "Iuiú - BA", state: "BA" },
    { value: "Ivaiporã - PR", text: "Ivaiporã - PR", state: "PR" },
    { value: "Ivatuba - PR", text: "Ivatuba - PR", state: "PR" },
    { value: "Ivaté - PR", text: "Ivaté - PR", state: "PR" },
    { value: "Ivaí - PR", text: "Ivaí - PR", state: "PR" },
    { value: "Ivinhema - MS", text: "Ivinhema - MS", state: "MS" },
    { value: "Ivolândia - GO", text: "Ivolândia - GO", state: "GO" },
    { value: "Ivorá - RS", text: "Ivorá - RS", state: "RS" },
    { value: "Ivoti - RS", text: "Ivoti - RS", state: "RS" },
    { value: "Içara - SC", text: "Içara - SC", state: "SC" },
    { value: "Iúna - ES", text: "Iúna - ES", state: "ES" },
    { value: "Jaboatão dos Guararapes - PE", text: "Jaboatão dos Guararapes - PE", state: "PE" },
    { value: "Jaborandi - BA", text: "Jaborandi - BA", state: "BA" },
    { value: "Jaborandi - SP", text: "Jaborandi - SP", state: "SP" },
    { value: "Jaborá - SC", text: "Jaborá - SC", state: "SC" },
    { value: "Jaboti - PR", text: "Jaboti - PR", state: "PR" },
    { value: "Jaboticaba - RS", text: "Jaboticaba - RS", state: "RS" },
    { value: "Jaboticabal - SP", text: "Jaboticabal - SP", state: "SP" },
    { value: "Jaboticatubas - MG", text: "Jaboticatubas - MG", state: "MG" },
    { value: "Jacaraci - BA", text: "Jacaraci - BA", state: "BA" },
    { value: "Jacaraú - PB", text: "Jacaraú - PB", state: "PB" },
    { value: "Jacareacanga - PA", text: "Jacareacanga - PA", state: "PA" },
    { value: "Jacarezinho - PR", text: "Jacarezinho - PR", state: "PR" },
    { value: "Jacareí - SP", text: "Jacareí - SP", state: "SP" },
    { value: "Jacaré dos Homens - AL", text: "Jacaré dos Homens - AL", state: "AL" },
    { value: "Jaci - SP", text: "Jaci - SP", state: "SP" },
    { value: "Jaciara - MT", text: "Jaciara - MT", state: "MT" },
    { value: "Jacinto - MG", text: "Jacinto - MG", state: "MG" },
    { value: "Jacinto Machado - SC", text: "Jacinto Machado - SC", state: "SC" },
    { value: "Jacobina - BA", text: "Jacobina - BA", state: "BA" },
    { value: "Jacobina do Piauí - PI", text: "Jacobina do Piauí - PI", state: "PI" },
    { value: "Jacuizinho - RS", text: "Jacuizinho - RS", state: "RS" },
    { value: "Jacundá - PA", text: "Jacundá - PA", state: "PA" },
    { value: "Jacupiranga - SP", text: "Jacupiranga - SP", state: "SP" },
    { value: "Jacutinga - MG", text: "Jacutinga - MG", state: "MG" },
    { value: "Jacutinga - RS", text: "Jacutinga - RS", state: "RS" },
    { value: "Jacuí - MG", text: "Jacuí - MG", state: "MG" },
    { value: "Jacuípe - AL", text: "Jacuípe - AL", state: "AL" },
    { value: "Jaguapitã - PR", text: "Jaguapitã - PR", state: "PR" },
    { value: "Jaguaquara - BA", text: "Jaguaquara - BA", state: "BA" },
    { value: "Jaguarari - BA", text: "Jaguarari - BA", state: "BA" },
    { value: "Jaguaraçu - MG", text: "Jaguaraçu - MG", state: "MG" },
    { value: "Jaguaretama - CE", text: "Jaguaretama - CE", state: "CE" },
    { value: "Jaguari - RS", text: "Jaguari - RS", state: "RS" },
    { value: "Jaguariaíva - PR", text: "Jaguariaíva - PR", state: "PR" },
    { value: "Jaguaribara - CE", text: "Jaguaribara - CE", state: "CE" },
    { value: "Jaguaribe - CE", text: "Jaguaribe - CE", state: "CE" },
    { value: "Jaguaripe - BA", text: "Jaguaripe - BA", state: "BA" },
    { value: "Jaguariúna - SP", text: "Jaguariúna - SP", state: "SP" },
    { value: "Jaguaruana - CE", text: "Jaguaruana - CE", state: "CE" },
    { value: "Jaguaruna - SC", text: "Jaguaruna - SC", state: "SC" },
    { value: "Jaguarão - RS", text: "Jaguarão - RS", state: "RS" },
    { value: "Jaguaré - ES", text: "Jaguaré - ES", state: "ES" },
    { value: "Jaicós - PI", text: "Jaicós - PI", state: "PI" },
    { value: "Jales - SP", text: "Jales - SP", state: "SP" },
    { value: "Jambeiro - SP", text: "Jambeiro - SP", state: "SP" },
    { value: "Jampruca - MG", text: "Jampruca - MG", state: "MG" },
    { value: "Janaúba - MG", text: "Janaúba - MG", state: "MG" },
    { value: "Jandaia - GO", text: "Jandaia - GO", state: "GO" },
    { value: "Jandaia do Sul - PR", text: "Jandaia do Sul - PR", state: "PR" },
    { value: "Jandaíra - BA", text: "Jandaíra - BA", state: "BA" },
    { value: "Jandaíra - RN", text: "Jandaíra - RN", state: "RN" },
    { value: "Jandira - SP", text: "Jandira - SP", state: "SP" },
    { value: "Janduís - RN", text: "Janduís - RN", state: "RN" },
    { value: "Jangada - MT", text: "Jangada - MT", state: "MT" },
    { value: "Janiópolis - PR", text: "Janiópolis - PR", state: "PR" },
    { value: "Januária - MG", text: "Januária - MG", state: "MG" },
    { value: "Januário Cicco - RN", text: "Januário Cicco - RN", state: "RN" },
    { value: "Japaratinga - AL", text: "Japaratinga - AL", state: "AL" },
    { value: "Japaratuba - SE", text: "Japaratuba - SE", state: "SE" },
    { value: "Japaraíba - MG", text: "Japaraíba - MG", state: "MG" },
    { value: "Japeri - RJ", text: "Japeri - RJ", state: "RJ" },
    { value: "Japi - RN", text: "Japi - RN", state: "RN" },
    { value: "Japira - PR", text: "Japira - PR", state: "PR" },
    { value: "Japoatã - SE", text: "Japoatã - SE", state: "SE" },
    { value: "Japonvar - MG", text: "Japonvar - MG", state: "MG" },
    { value: "Japorã - MS", text: "Japorã - MS", state: "MS" },
    { value: "Japurá - AM", text: "Japurá - AM", state: "AM" },
    { value: "Japurá - PR", text: "Japurá - PR", state: "PR" },
    { value: "Jaqueira - PE", text: "Jaqueira - PE", state: "PE" },
    { value: "Jaquirana - RS", text: "Jaquirana - RS", state: "RS" },
    { value: "Jaraguari - MS", text: "Jaraguari - MS", state: "MS" },
    { value: "Jaraguá - GO", text: "Jaraguá - GO", state: "GO" },
    { value: "Jaraguá do Sul - SC", text: "Jaraguá do Sul - SC", state: "SC" },
    { value: "Jaramataia - AL", text: "Jaramataia - AL", state: "AL" },
    { value: "Jardim - CE", text: "Jardim - CE", state: "CE" },
    { value: "Jardim - MS", text: "Jardim - MS", state: "MS" },
    { value: "Jardim Alegre - PR", text: "Jardim Alegre - PR", state: "PR" },
    { value: "Jardim Botânico - DF", text: "Jardim Botânico - DF", state: "DF" },
    { value: "Jardim Olinda - PR", text: "Jardim Olinda - PR", state: "PR" },
    { value: "Jardim de Angicos - RN", text: "Jardim de Angicos - RN", state: "RN" },
    { value: "Jardim de Piranhas - RN", text: "Jardim de Piranhas - RN", state: "RN" },
    { value: "Jardim do Mulato - PI", text: "Jardim do Mulato - PI", state: "PI" },
    { value: "Jardim do Seridó - RN", text: "Jardim do Seridó - RN", state: "RN" },
    { value: "Jardinópolis - SC", text: "Jardinópolis - SC", state: "SC" },
    { value: "Jardinópolis - SP", text: "Jardinópolis - SP", state: "SP" },
    { value: "Jari - RS", text: "Jari - RS", state: "RS" },
    { value: "Jarinu - SP", text: "Jarinu - SP", state: "SP" },
    { value: "Jaru - RO", text: "Jaru - RO", state: "RO" },
    { value: "Jataizinho - PR", text: "Jataizinho - PR", state: "PR" },
    { value: "Jataí - GO", text: "Jataí - GO", state: "GO" },
    { value: "Jataúba - PE", text: "Jataúba - PE", state: "PE" },
    { value: "Jateí - MS", text: "Jateí - MS", state: "MS" },
    { value: "Jati - CE", text: "Jati - CE", state: "CE" },
    { value: "Jatobá - MA", text: "Jatobá - MA", state: "MA" },
    { value: "Jatobá - PE", text: "Jatobá - PE", state: "PE" },
    { value: "Jatobá do Piauí - PI", text: "Jatobá do Piauí - PI", state: "PI" },
    { value: "Jaupaci - GO", text: "Jaupaci - GO", state: "GO" },
    { value: "Jauru - MT", text: "Jauru - MT", state: "MT" },
    { value: "Jaçanã - RN", text: "Jaçanã - RN", state: "RN" },
    { value: "Jaíba - MG", text: "Jaíba - MG", state: "MG" },
    { value: "Jaú - SP", text: "Jaú - SP", state: "SP" },
    { value: "Jaú do Tocantins - TO", text: "Jaú do Tocantins - TO", state: "TO" },
    { value: "Jeceaba - MG", text: "Jeceaba - MG", state: "MG" },
    { value: "Jenipapo de Minas - MG", text: "Jenipapo de Minas - MG", state: "MG" },
    { value: "Jenipapo dos Vieiras - MA", text: "Jenipapo dos Vieiras - MA", state: "MA" },
    { value: "Jequeri - MG", text: "Jequeri - MG", state: "MG" },
    { value: "Jequitaí - MG", text: "Jequitaí - MG", state: "MG" },
    { value: "Jequitibá - MG", text: "Jequitibá - MG", state: "MG" },
    { value: "Jequitinhonha - MG", text: "Jequitinhonha - MG", state: "MG" },
    { value: "Jequiá da Praia - AL", text: "Jequiá da Praia - AL", state: "AL" },
    { value: "Jequié - BA", text: "Jequié - BA", state: "BA" },
    { value: "Jeremoabo - BA", text: "Jeremoabo - BA", state: "BA" },
    { value: "Jericó - PB", text: "Jericó - PB", state: "PB" },
    { value: "Jeriquara - SP", text: "Jeriquara - SP", state: "SP" },
    { value: "Jerumenha - PI", text: "Jerumenha - PI", state: "PI" },
    { value: "Jerônimo Monteiro - ES", text: "Jerônimo Monteiro - ES", state: "ES" },
    { value: "Jesuânia - MG", text: "Jesuânia - MG", state: "MG" },
    { value: "Jesuítas - PR", text: "Jesuítas - PR", state: "PR" },
    { value: "Jesúpolis - GO", text: "Jesúpolis - GO", state: "GO" },
    { value: "Ji-Paraná - RO", text: "Ji-Paraná - RO", state: "RO" },
    { value: "Jijoca de Jericoaroara - CE", text: "Jijoca de Jericoaroara - CE", state: "CE" },
    { value: "Jiquiriçá - BA", text: "Jiquiriçá - BA", state: "BA" },
    { value: "Jitaúna - BA", text: "Jitaúna - BA", state: "BA" },
    { value: "Joanésia - MG", text: "Joanésia - MG", state: "MG" },
    { value: "Joanópolis - SP", text: "Joanópolis - SP", state: "SP" },
    { value: "Joaquim Felício - MG", text: "Joaquim Felício - MG", state: "MG" },
    { value: "Joaquim Gomes - AL", text: "Joaquim Gomes - AL", state: "AL" },
    { value: "Joaquim Nabuco - PE", text: "Joaquim Nabuco - PE", state: "PE" },
    { value: "Joaquim Pires - PI", text: "Joaquim Pires - PI", state: "PI" },
    { value: "Joaquim Távora - PR", text: "Joaquim Távora - PR", state: "PR" },
    { value: "Joaçaba - SC", text: "Joaçaba - SC", state: "SC" },
    { value: "Joaíma - MG", text: "Joaíma - MG", state: "MG" },
    { value: "Joca Marques - PI", text: "Joca Marques - PI", state: "PI" },
    { value: "Joinville - SC", text: "Joinville - SC", state: "SC" },
    { value: "Jordânia - MG", text: "Jordânia - MG", state: "MG" },
    { value: "Jordão - AC", text: "Jordão - AC", state: "AC" },
    { value: "Joselândia - MA", text: "Joselândia - MA", state: "MA" },
    { value: "Josenópolis - MG", text: "Josenópolis - MG", state: "MG" },
    { value: "José Boiteux - SC", text: "José Boiteux - SC", state: "SC" },
    { value: "José Bonifácio - SP", text: "José Bonifácio - SP", state: "SP" },
    { value: "José Gonçalves de Minas - MG", text: "José Gonçalves de Minas - MG", state: "MG" },
    { value: "José Raydan - MG", text: "José Raydan - MG", state: "MG" },
    { value: "José da Penha - RN", text: "José da Penha - RN", state: "RN" },
    { value: "José de Freitas - PI", text: "José de Freitas - PI", state: "PI" },
    { value: "Joviânia - GO", text: "Joviânia - GO", state: "GO" },
    { value: "João Alfredo - PE", text: "João Alfredo - PE", state: "PE" },
    { value: "João Costa - PI", text: "João Costa - PI", state: "PI" },
    { value: "João Câmara - RN", text: "João Câmara - RN", state: "RN" },
    { value: "João Dias - RN", text: "João Dias - RN", state: "RN" },
    { value: "João Dourado - BA", text: "João Dourado - BA", state: "BA" },
    { value: "João Lisboa - MA", text: "João Lisboa - MA", state: "MA" },
    { value: "João Monlevade - MG", text: "João Monlevade - MG", state: "MG" },
    { value: "João Neiva - ES", text: "João Neiva - ES", state: "ES" },
    { value: "João Pessoa - PB", text: "João Pessoa - PB", state: "PB" },
    { value: "João Pinheiro - MG", text: "João Pinheiro - MG", state: "MG" },
    { value: "João Ramalho - SP", text: "João Ramalho - SP", state: "SP" },
    { value: "Juara - MT", text: "Juara - MT", state: "MT" },
    { value: "Juarez Távora - PB", text: "Juarez Távora - PB", state: "PB" },
    { value: "Juarina - TO", text: "Juarina - TO", state: "TO" },
    { value: "Juatuba - MG", text: "Juatuba - MG", state: "MG" },
    { value: "Juazeirinho - PB", text: "Juazeirinho - PB", state: "PB" },
    { value: "Juazeiro - BA", text: "Juazeiro - BA", state: "BA" },
    { value: "Juazeiro do Norte - CE", text: "Juazeiro do Norte - CE", state: "CE" },
    { value: "Juazeiro do Piauí - PI", text: "Juazeiro do Piauí - PI", state: "PI" },
    { value: "Jucati - PE", text: "Jucati - PE", state: "PE" },
    { value: "Jucurutu - RN", text: "Jucurutu - RN", state: "RN" },
    { value: "Jucuruçu - BA", text: "Jucuruçu - BA", state: "BA" },
    { value: "Jucás - CE", text: "Jucás - CE", state: "CE" },
    { value: "Juiz de Fora - MG", text: "Juiz de Fora - MG", state: "MG" },
    { value: "Jumirim - SP", text: "Jumirim - SP", state: "SP" },
    { value: "Junco do Maranhão - MA", text: "Junco do Maranhão - MA", state: "MA" },
    { value: "Junco do Seridó - PB", text: "Junco do Seridó - PB", state: "PB" },
    { value: "Jundiaí - SP", text: "Jundiaí - SP", state: "SP" },
    { value: "Jundiaí do Sul - PR", text: "Jundiaí do Sul - PR", state: "PR" },
    { value: "Jundiá - AL", text: "Jundiá - AL", state: "AL" },
    { value: "Jundiá - RN", text: "Jundiá - RN", state: "RN" },
    { value: "Junqueiro - AL", text: "Junqueiro - AL", state: "AL" },
    { value: "Junqueirópolis - SP", text: "Junqueirópolis - SP", state: "SP" },
    { value: "Jupi - PE", text: "Jupi - PE", state: "PE" },
    { value: "Jupiá - SC", text: "Jupiá - SC", state: "SC" },
    { value: "Juquitiba - SP", text: "Juquitiba - SP", state: "SP" },
    { value: "Juquiá - SP", text: "Juquiá - SP", state: "SP" },
    { value: "Juramento - MG", text: "Juramento - MG", state: "MG" },
    { value: "Juranda - PR", text: "Juranda - PR", state: "PR" },
    { value: "Jurema - PE", text: "Jurema - PE", state: "PE" },
    { value: "Jurema - PI", text: "Jurema - PI", state: "PI" },
    { value: "Juripiranga - PB", text: "Juripiranga - PB", state: "PB" },
    { value: "Juru - PB", text: "Juru - PB", state: "PB" },
    { value: "Juruaia - MG", text: "Juruaia - MG", state: "MG" },
    { value: "Juruena - MT", text: "Juruena - MT", state: "MT" },
    { value: "Juruti - PA", text: "Juruti - PA", state: "PA" },
    { value: "Juruá - AM", text: "Juruá - AM", state: "AM" },
    { value: "Juscimeira - MT", text: "Juscimeira - MT", state: "MT" },
    { value: "Jussara - BA", text: "Jussara - BA", state: "BA" },
    { value: "Jussara - GO", text: "Jussara - GO", state: "GO" },
    { value: "Jussara - PR", text: "Jussara - PR", state: "PR" },
    { value: "Jussari - BA", text: "Jussari - BA", state: "BA" },
    { value: "Jussiape - BA", text: "Jussiape - BA", state: "BA" },
    { value: "Jutaí - AM", text: "Jutaí - AM", state: "AM" },
    { value: "Juti - MS", text: "Juti - MS", state: "MS" },
    { value: "Juvenília - MG", text: "Juvenília - MG", state: "MG" },
    { value: "Juína - MT", text: "Juína - MT", state: "MT" },
    { value: "Jóia - RS", text: "Jóia - RS", state: "RS" },
    { value: "Júlio Borges - PI", text: "Júlio Borges - PI", state: "PI" },
    { value: "Júlio Mesquita - SP", text: "Júlio Mesquita - SP", state: "SP" },
    { value: "Júlio de Castilhos - RS", text: "Júlio de Castilhos - RS", state: "RS" },
    { value: "Kaloré - PR", text: "Kaloré - PR", state: "PR" },
    { value: "Lacerdópolis - SC", text: "Lacerdópolis - SC", state: "SC" },
    { value: "Ladainha - MG", text: "Ladainha - MG", state: "MG" },
    { value: "Ladário - MS", text: "Ladário - MS", state: "MS" },
    { value: "Lafaiete Coutinho - BA", text: "Lafaiete Coutinho - BA", state: "BA" },
    { value: "Lagamar - MG", text: "Lagamar - MG", state: "MG" },
    { value: "Lagarto - SE", text: "Lagarto - SE", state: "SE" },
    { value: "Lages - SC", text: "Lages - SC", state: "SC" },
    { value: "Lago Norte - DF", text: "Lago Norte - DF", state: "DF" },
    { value: "Lago Sul - DF", text: "Lago Sul - DF", state: "DF" },
    { value: "Lago Verde - MA", text: "Lago Verde - MA", state: "MA" },
    { value: "Lago da Pedra - MA", text: "Lago da Pedra - MA", state: "MA" },
    { value: "Lago do Junco - MA", text: "Lago do Junco - MA", state: "MA" },
    { value: "Lago dos Rodrigues - MA", text: "Lago dos Rodrigues - MA", state: "MA" },
    { value: "Lagoa - PB", text: "Lagoa - PB", state: "PB" },
    { value: "Lagoa Alegre - PI", text: "Lagoa Alegre - PI", state: "PI" },
    { value: "Lagoa Bonita do Sul - RS", text: "Lagoa Bonita do Sul - RS", state: "RS" },
    { value: "Lagoa Dourada - MG", text: "Lagoa Dourada - MG", state: "MG" },
    { value: "Lagoa Formosa - MG", text: "Lagoa Formosa - MG", state: "MG" },
    { value: "Lagoa Grande - MG", text: "Lagoa Grande - MG", state: "MG" },
    { value: "Lagoa Grande - PE", text: "Lagoa Grande - PE", state: "PE" },
    { value: "Lagoa Grande do Maranhão - MA", text: "Lagoa Grande do Maranhão - MA", state: "MA" },
    { value: "Lagoa Nova - RN", text: "Lagoa Nova - RN", state: "RN" },
    { value: "Lagoa Real - BA", text: "Lagoa Real - BA", state: "BA" },
    { value: "Lagoa Salgada - RN", text: "Lagoa Salgada - RN", state: "RN" },
    { value: "Lagoa Santa - GO", text: "Lagoa Santa - GO", state: "GO" },
    { value: "Lagoa Santa - MG", text: "Lagoa Santa - MG", state: "MG" },
    { value: "Lagoa Seca - PB", text: "Lagoa Seca - PB", state: "PB" },
    { value: "Lagoa Vermelha - RS", text: "Lagoa Vermelha - RS", state: "RS" },
    { value: "Lagoa d'Anta - RN", text: "Lagoa d'Anta - RN", state: "RN" },
    { value: "Lagoa da Canoa - AL", text: "Lagoa da Canoa - AL", state: "AL" },
    { value: "Lagoa da Confusão - TO", text: "Lagoa da Confusão - TO", state: "TO" },
    { value: "Lagoa da Prata - MG", text: "Lagoa da Prata - MG", state: "MG" },
    { value: "Lagoa de Dentro - PB", text: "Lagoa de Dentro - PB", state: "PB" },
    { value: "Lagoa de Pedras - RN", text: "Lagoa de Pedras - RN", state: "RN" },
    { value: "Lagoa de São Francisco - PI", text: "Lagoa de São Francisco - PI", state: "PI" },
    { value: "Lagoa de Velhos - RN", text: "Lagoa de Velhos - RN", state: "RN" },
    { value: "Lagoa do Barro do Piauí - PI", text: "Lagoa do Barro do Piauí - PI", state: "PI" },
    { value: "Lagoa do Carro - PE", text: "Lagoa do Carro - PE", state: "PE" },
    { value: "Lagoa do Itaenga - PE", text: "Lagoa do Itaenga - PE", state: "PE" },
    { value: "Lagoa do Mato - MA", text: "Lagoa do Mato - MA", state: "MA" },
    { value: "Lagoa do Ouro - PE", text: "Lagoa do Ouro - PE", state: "PE" },
    { value: "Lagoa do Piauí - PI", text: "Lagoa do Piauí - PI", state: "PI" },
    { value: "Lagoa do Sítio - PI", text: "Lagoa do Sítio - PI", state: "PI" },
    { value: "Lagoa do Tocantins - TO", text: "Lagoa do Tocantins - TO", state: "TO" },
    { value: "Lagoa dos Gatos - PE", text: "Lagoa dos Gatos - PE", state: "PE" },
    { value: "Lagoa dos Patos - MG", text: "Lagoa dos Patos - MG", state: "MG" },
    { value: "Lagoa dos Três Cantos - RS", text: "Lagoa dos Três Cantos - RS", state: "RS" },
    { value: "Lagoinha - SP", text: "Lagoinha - SP", state: "SP" },
    { value: "Lagoinha do Piauí - PI", text: "Lagoinha do Piauí - PI", state: "PI" },
    { value: "Lagoão - RS", text: "Lagoão - RS", state: "RS" },
    { value: "Laguna - SC", text: "Laguna - SC", state: "SC" },
    { value: "Laguna Carapã - MS", text: "Laguna Carapã - MS", state: "MS" },
    { value: "Laje - BA", text: "Laje - BA", state: "BA" },
    { value: "Laje do Muriaé - RJ", text: "Laje do Muriaé - RJ", state: "RJ" },
    { value: "Lajeado - RS", text: "Lajeado - RS", state: "RS" },
    { value: "Lajeado - TO", text: "Lajeado - TO", state: "TO" },
    { value: "Lajeado Grande - SC", text: "Lajeado Grande - SC", state: "SC" },
    { value: "Lajeado Novo - MA", text: "Lajeado Novo - MA", state: "MA" },
    { value: "Lajeado do Bugre - RS", text: "Lajeado do Bugre - RS", state: "RS" },
    { value: "Lajedinho - BA", text: "Lajedinho - BA", state: "BA" },
    { value: "Lajedo - PE", text: "Lajedo - PE", state: "PE" },
    { value: "Lajedo do Tabocal - BA", text: "Lajedo do Tabocal - BA", state: "BA" },
    { value: "Lajedão - BA", text: "Lajedão - BA", state: "BA" },
    { value: "Lajes - RN", text: "Lajes - RN", state: "RN" },
    { value: "Lajes Pintadas - RN", text: "Lajes Pintadas - RN", state: "RN" },
    { value: "Lajinha - MG", text: "Lajinha - MG", state: "MG" },
    { value: "Lamarão - BA", text: "Lamarão - BA", state: "BA" },
    { value: "Lambari - MG", text: "Lambari - MG", state: "MG" },
    { value: "Lambari d'Oeste - MT", text: "Lambari d'Oeste - MT", state: "MT" },
    { value: "Lamim - MG", text: "Lamim - MG", state: "MG" },
    { value: "Landri Sales - PI", text: "Landri Sales - PI", state: "PI" },
    { value: "Lapa - PR", text: "Lapa - PR", state: "PR" },
    { value: "Lapão - BA", text: "Lapão - BA", state: "BA" },
    { value: "Laranja da Terra - ES", text: "Laranja da Terra - ES", state: "ES" },
    { value: "Laranjal - MG", text: "Laranjal - MG", state: "MG" },
    { value: "Laranjal - PR", text: "Laranjal - PR", state: "PR" },
    { value: "Laranjal Paulista - SP", text: "Laranjal Paulista - SP", state: "SP" },
    { value: "Laranjal do Jari - AP", text: "Laranjal do Jari - AP", state: "AP" },
    { value: "Laranjeiras - SE", text: "Laranjeiras - SE", state: "SE" },
    { value: "Laranjeiras do Sul - PR", text: "Laranjeiras do Sul - PR", state: "PR" },
    { value: "Lassance - MG", text: "Lassance - MG", state: "MG" },
    { value: "Lastro - PB", text: "Lastro - PB", state: "PB" },
    { value: "Laurentino - SC", text: "Laurentino - SC", state: "SC" },
    { value: "Lauro Muller - SC", text: "Lauro Muller - SC", state: "SC" },
    { value: "Lauro de Freitas - BA", text: "Lauro de Freitas - BA", state: "BA" },
    { value: "Lavandeira - TO", text: "Lavandeira - TO", state: "TO" },
    { value: "Lavras - MG", text: "Lavras - MG", state: "MG" },
    { value: "Lavras da Mangabeira - CE", text: "Lavras da Mangabeira - CE", state: "CE" },
    { value: "Lavras do Sul - RS", text: "Lavras do Sul - RS", state: "RS" },
    { value: "Lavrinhas - SP", text: "Lavrinhas - SP", state: "SP" },
    { value: "Lavínia - SP", text: "Lavínia - SP", state: "SP" },
    { value: "Leandro Ferreira - MG", text: "Leandro Ferreira - MG", state: "MG" },
    { value: "Lebon Régis - SC", text: "Lebon Régis - SC", state: "SC" },
    { value: "Leme - SP", text: "Leme - SP", state: "SP" },
    { value: "Leme do Prado - MG", text: "Leme do Prado - MG", state: "MG" },
    { value: "Lençóis - BA", text: "Lençóis - BA", state: "BA" },
    { value: "Lençóis Paulista - SP", text: "Lençóis Paulista - SP", state: "SP" },
    { value: "Leoberto Leal - SC", text: "Leoberto Leal - SC", state: "SC" },
    { value: "Leopoldina - MG", text: "Leopoldina - MG", state: "MG" },
    { value: "Leopoldo de Bulhões - GO", text: "Leopoldo de Bulhões - GO", state: "GO" },
    { value: "Leópolis - PR", text: "Leópolis - PR", state: "PR" },
    { value: "Liberato Salzano - RS", text: "Liberato Salzano - RS", state: "RS" },
    { value: "Liberdade - MG", text: "Liberdade - MG", state: "MG" },
    { value: "Licínio de Almeida - BA", text: "Licínio de Almeida - BA", state: "BA" },
    { value: "Lidianópolis - PR", text: "Lidianópolis - PR", state: "PR" },
    { value: "Lima Campos - MA", text: "Lima Campos - MA", state: "MA" },
    { value: "Lima Duarte - MG", text: "Lima Duarte - MG", state: "MG" },
    { value: "Limeira - SP", text: "Limeira - SP", state: "SP" },
    { value: "Limeira do Oeste - MG", text: "Limeira do Oeste - MG", state: "MG" },
    { value: "Limoeiro - PE", text: "Limoeiro - PE", state: "PE" },
    { value: "Limoeiro de Anadia - AL", text: "Limoeiro de Anadia - AL", state: "AL" },
    { value: "Limoeiro do Ajuru - PA", text: "Limoeiro do Ajuru - PA", state: "PA" },
    { value: "Limoeiro do Norte - CE", text: "Limoeiro do Norte - CE", state: "CE" },
    { value: "Lindoeste - PR", text: "Lindoeste - PR", state: "PR" },
    { value: "Lindolfo Collor - RS", text: "Lindolfo Collor - RS", state: "RS" },
    { value: "Lindóia - SP", text: "Lindóia - SP", state: "SP" },
    { value: "Lindóia do Sul - SC", text: "Lindóia do Sul - SC", state: "SC" },
    { value: "Linha Nova - RS", text: "Linha Nova - RS", state: "RS" },
    { value: "Linhares - ES", text: "Linhares - ES", state: "ES" },
    { value: "Lins - SP", text: "Lins - SP", state: "SP" },
    { value: "Livramento - PB", text: "Livramento - PB", state: "PB" },
    { value: "Livramento de Nossa Senhora - BA", text: "Livramento de Nossa Senhora - BA", state: "BA" },
    { value: "Lizarda - TO", text: "Lizarda - TO", state: "TO" },
    { value: "Loanda - PR", text: "Loanda - PR", state: "PR" },
    { value: "Lobato - PR", text: "Lobato - PR", state: "PR" },
    { value: "Logradouro - PB", text: "Logradouro - PB", state: "PB" },
    { value: "Londrina - PR", text: "Londrina - PR", state: "PR" },
    { value: "Lontra - MG", text: "Lontra - MG", state: "MG" },
    { value: "Lontras - SC", text: "Lontras - SC", state: "SC" },
    { value: "Lorena - SP", text: "Lorena - SP", state: "SP" },
    { value: "Loreto - MA", text: "Loreto - MA", state: "MA" },
    { value: "Lourdes - SP", text: "Lourdes - SP", state: "SP" },
    { value: "Louveira - SP", text: "Louveira - SP", state: "SP" },
    { value: "Lucas do Rio Verde - MT", text: "Lucas do Rio Verde - MT", state: "MT" },
    { value: "Lucena - PB", text: "Lucena - PB", state: "PB" },
    { value: "Lucianópolis - SP", text: "Lucianópolis - SP", state: "SP" },
    { value: "Luciára - MT", text: "Luciára - MT", state: "MT" },
    { value: "Lucrécia - RN", text: "Lucrécia - RN", state: "RN" },
    { value: "Lucélia - SP", text: "Lucélia - SP", state: "SP" },
    { value: "Luisburgo - MG", text: "Luisburgo - MG", state: "MG" },
    { value: "Luislândia - MG", text: "Luislândia - MG", state: "MG" },
    { value: "Luiz Alves - SC", text: "Luiz Alves - SC", state: "SC" },
    { value: "Luiziana - PR", text: "Luiziana - PR", state: "PR" },
    { value: "Luiziânia - SP", text: "Luiziânia - SP", state: "SP" },
    { value: "Luminárias - MG", text: "Luminárias - MG", state: "MG" },
    { value: "Lunardelli - PR", text: "Lunardelli - PR", state: "PR" },
    { value: "Lupionópolis - PR", text: "Lupionópolis - PR", state: "PR" },
    { value: "Lupércio - SP", text: "Lupércio - SP", state: "SP" },
    { value: "Lutécia - SP", text: "Lutécia - SP", state: "SP" },
    { value: "Luz - MG", text: "Luz - MG", state: "MG" },
    { value: "Luzerna - SC", text: "Luzerna - SC", state: "SC" },
    { value: "Luzilândia - PI", text: "Luzilândia - PI", state: "PI" },
    { value: "Luzinópolis - TO", text: "Luzinópolis - TO", state: "TO" },
    { value: "Luziânia - GO", text: "Luziânia - GO", state: "GO" },
    { value: "Luís Antônio - SP", text: "Luís Antônio - SP", state: "SP" },
    { value: "Luís Correia - PI", text: "Luís Correia - PI", state: "PI" },
    { value: "Luís Domingues - MA", text: "Luís Domingues - MA", state: "MA" },
    { value: "Luís Eduardo Magalhães - BA", text: "Luís Eduardo Magalhães - BA", state: "BA" },
    { value: "Luís Gomes - RN", text: "Luís Gomes - RN", state: "RN" },
    { value: "Lábrea - AM", text: "Lábrea - AM", state: "AM" },
    { value: "Macajuba - BA", text: "Macajuba - BA", state: "BA" },
    { value: "Macambira - SE", text: "Macambira - SE", state: "SE" },
    { value: "Macaparana - PE", text: "Macaparana - PE", state: "PE" },
    { value: "Macapá - AP", text: "Macapá - AP", state: "AP" },
    { value: "Macarani - BA", text: "Macarani - BA", state: "BA" },
    { value: "Macatuba - SP", text: "Macatuba - SP", state: "SP" },
    { value: "Macau - RN", text: "Macau - RN", state: "RN" },
    { value: "Macaubal - SP", text: "Macaubal - SP", state: "SP" },
    { value: "Macaé - RJ", text: "Macaé - RJ", state: "RJ" },
    { value: "Macaíba - RN", text: "Macaíba - RN", state: "RN" },
    { value: "Macaúbas - BA", text: "Macaúbas - BA", state: "BA" },
    { value: "Macedônia - SP", text: "Macedônia - SP", state: "SP" },
    { value: "Maceió - AL", text: "Maceió - AL", state: "AL" },
    { value: "Machacalis - MG", text: "Machacalis - MG", state: "MG" },
    { value: "Machadinho - RS", text: "Machadinho - RS", state: "RS" },
    { value: "Machadinho d'Oeste - RO", text: "Machadinho d'Oeste - RO", state: "RO" },
    { value: "Machado - MG", text: "Machado - MG", state: "MG" },
    { value: "Machados - PE", text: "Machados - PE", state: "PE" },
    { value: "Macieira - SC", text: "Macieira - SC", state: "SC" },
    { value: "Macuco - RJ", text: "Macuco - RJ", state: "RJ" },
    { value: "Macururé - BA", text: "Macururé - BA", state: "BA" },
    { value: "Madalena - CE", text: "Madalena - CE", state: "CE" },
    { value: "Madeiro - PI", text: "Madeiro - PI", state: "PI" },
    { value: "Madre de Deus - BA", text: "Madre de Deus - BA", state: "BA" },
    { value: "Madre de Deus de Minas - MG", text: "Madre de Deus de Minas - MG", state: "MG" },
    { value: "Maetinga - BA", text: "Maetinga - BA", state: "BA" },
    { value: "Mafra - SC", text: "Mafra - SC", state: "SC" },
    { value: "Magalhães Barata - PA", text: "Magalhães Barata - PA", state: "PA" },
    { value: "Magalhães de Almeida - MA", text: "Magalhães de Almeida - MA", state: "MA" },
    { value: "Magda - SP", text: "Magda - SP", state: "SP" },
    { value: "Magé - RJ", text: "Magé - RJ", state: "RJ" },
    { value: "Maiquinique - BA", text: "Maiquinique - BA", state: "BA" },
    { value: "Mairi - BA", text: "Mairi - BA", state: "BA" },
    { value: "Mairinque - SP", text: "Mairinque - SP", state: "SP" },
    { value: "Mairiporã - SP", text: "Mairiporã - SP", state: "SP" },
    { value: "Mairipotaba - GO", text: "Mairipotaba - GO", state: "GO" },
    { value: "Major Gercino - SC", text: "Major Gercino - SC", state: "SC" },
    { value: "Major Isidoro - AL", text: "Major Isidoro - AL", state: "AL" },
    { value: "Major Sales - RN", text: "Major Sales - RN", state: "RN" },
    { value: "Major Vieira - SC", text: "Major Vieira - SC", state: "SC" },
    { value: "Malacacheta - MG", text: "Malacacheta - MG", state: "MG" },
    { value: "Malhada - BA", text: "Malhada - BA", state: "BA" },
    { value: "Malhada de Pedras - BA", text: "Malhada de Pedras - BA", state: "BA" },
    { value: "Malhada dos Bois - SE", text: "Malhada dos Bois - SE", state: "SE" },
    { value: "Malhador - SE", text: "Malhador - SE", state: "SE" },
    { value: "Mallet - PR", text: "Mallet - PR", state: "PR" },
    { value: "Malta - PB", text: "Malta - PB", state: "PB" },
    { value: "Mamanguape - PB", text: "Mamanguape - PB", state: "PB" },
    { value: "Mambaí - GO", text: "Mambaí - GO", state: "GO" },
    { value: "Mamborê - PR", text: "Mamborê - PR", state: "PR" },
    { value: "Mamonas - MG", text: "Mamonas - MG", state: "MG" },
    { value: "Mampituba - RS", text: "Mampituba - RS", state: "RS" },
    { value: "Manacapuru - AM", text: "Manacapuru - AM", state: "AM" },
    { value: "Manaquiri - AM", text: "Manaquiri - AM", state: "AM" },
    { value: "Manari - PE", text: "Manari - PE", state: "PE" },
    { value: "Manaus - AM", text: "Manaus - AM", state: "AM" },
    { value: "Manaíra - PB", text: "Manaíra - PB", state: "PB" },
    { value: "Mandaguari - PR", text: "Mandaguari - PR", state: "PR" },
    { value: "Mandaguaçu - PR", text: "Mandaguaçu - PR", state: "PR" },
    { value: "Mandirituba - PR", text: "Mandirituba - PR", state: "PR" },
    { value: "Manduri - SP", text: "Manduri - SP", state: "SP" },
    { value: "Manfrinópolis - PR", text: "Manfrinópolis - PR", state: "PR" },
    { value: "Manga - MG", text: "Manga - MG", state: "MG" },
    { value: "Mangaratiba - RJ", text: "Mangaratiba - RJ", state: "RJ" },
    { value: "Mangueirinha - PR", text: "Mangueirinha - PR", state: "PR" },
    { value: "Manhuaçu - MG", text: "Manhuaçu - MG", state: "MG" },
    { value: "Manhumirim - MG", text: "Manhumirim - MG", state: "MG" },
    { value: "Manicoré - AM", text: "Manicoré - AM", state: "AM" },
    { value: "Manoel Emídio - PI", text: "Manoel Emídio - PI", state: "PI" },
    { value: "Manoel Ribas - PR", text: "Manoel Ribas - PR", state: "PR" },
    { value: "Manoel Urbano - AC", text: "Manoel Urbano - AC", state: "AC" },
    { value: "Manoel Viana - RS", text: "Manoel Viana - RS", state: "RS" },
    { value: "Manoel Vitorino - BA", text: "Manoel Vitorino - BA", state: "BA" },
    { value: "Mansidão - BA", text: "Mansidão - BA", state: "BA" },
    { value: "Mantena - MG", text: "Mantena - MG", state: "MG" },
    { value: "Mantenópolis - ES", text: "Mantenópolis - ES", state: "ES" },
    { value: "Maquiné - RS", text: "Maquiné - RS", state: "RS" },
    { value: "Mar Vermelho - AL", text: "Mar Vermelho - AL", state: "AL" },
    { value: "Mar de Espanha - MG", text: "Mar de Espanha - MG", state: "MG" },
    { value: "Mara Rosa - GO", text: "Mara Rosa - GO", state: "GO" },
    { value: "Marabá - PA", text: "Marabá - PA", state: "PA" },
    { value: "Marabá Paulista - SP", text: "Marabá Paulista - SP", state: "SP" },
    { value: "Maracaju - MS", text: "Maracaju - MS", state: "MS" },
    { value: "Maracajá - SC", text: "Maracajá - SC", state: "SC" },
    { value: "Maracanaú - CE", text: "Maracanaú - CE", state: "CE" },
    { value: "Maracanã - PA", text: "Maracanã - PA", state: "PA" },
    { value: "Maracaçumé - MA", text: "Maracaçumé - MA", state: "MA" },
    { value: "Maracaí - SP", text: "Maracaí - SP", state: "SP" },
    { value: "Maracás - BA", text: "Maracás - BA", state: "BA" },
    { value: "Maragogi - AL", text: "Maragogi - AL", state: "AL" },
    { value: "Maragogipe - BA", text: "Maragogipe - BA", state: "BA" },
    { value: "Maraial - PE", text: "Maraial - PE", state: "PE" },
    { value: "Marajá do Sena - MA", text: "Marajá do Sena - MA", state: "MA" },
    { value: "Maranguape - CE", text: "Maranguape - CE", state: "CE" },
    { value: "Maranhãozinho - MA", text: "Maranhãozinho - MA", state: "MA" },
    { value: "Marapanim - PA", text: "Marapanim - PA", state: "PA" },
    { value: "Marapoama - SP", text: "Marapoama - SP", state: "SP" },
    { value: "Marataizes - ES", text: "Marataizes - ES", state: "ES" },
    { value: "Maratá - RS", text: "Maratá - RS", state: "RS" },
    { value: "Marau - RS", text: "Marau - RS", state: "RS" },
    { value: "Maravilha - AL", text: "Maravilha - AL", state: "AL" },
    { value: "Maravilha - SC", text: "Maravilha - SC", state: "SC" },
    { value: "Maravilhas - MG", text: "Maravilhas - MG", state: "MG" },
    { value: "Maraã - AM", text: "Maraã - AM", state: "AM" },
    { value: "Maraú - BA", text: "Maraú - BA", state: "BA" },
    { value: "Marcação - PB", text: "Marcação - PB", state: "PB" },
    { value: "Marcelino Ramos - RS", text: "Marcelino Ramos - RS", state: "RS" },
    { value: "Marcelino Vieira - RN", text: "Marcelino Vieira - RN", state: "RN" },
    { value: "Marcelândia - MT", text: "Marcelândia - MT", state: "MT" },
    { value: "Marcionílio Souza - BA", text: "Marcionílio Souza - BA", state: "BA" },
    { value: "Marco - CE", text: "Marco - CE", state: "CE" },
    { value: "Marcolândia - PI", text: "Marcolândia - PI", state: "PI" },
    { value: "Marcos Parente - PI", text: "Marcos Parente - PI", state: "PI" },
    { value: "Marechal Cândido Rondon - PR", text: "Marechal Cândido Rondon - PR", state: "PR" },
    { value: "Marechal Deodoro - AL", text: "Marechal Deodoro - AL", state: "AL" },
    { value: "Marechal Floriano - ES", text: "Marechal Floriano - ES", state: "ES" },
    { value: "Marechal Thaumaturgo - AC", text: "Marechal Thaumaturgo - AC", state: "AC" },
    { value: "Marema - SC", text: "Marema - SC", state: "SC" },
    { value: "Mari - PB", text: "Mari - PB", state: "PB" },
    { value: "Maria Helena - PR", text: "Maria Helena - PR", state: "PR" },
    { value: "Maria da Fé - MG", text: "Maria da Fé - MG", state: "MG" },
    { value: "Marialva - PR", text: "Marialva - PR", state: "PR" },
    { value: "Mariana - MG", text: "Mariana - MG", state: "MG" },
    { value: "Mariana Pimentel - RS", text: "Mariana Pimentel - RS", state: "RS" },
    { value: "Mariano Moro - RS", text: "Mariano Moro - RS", state: "RS" },
    { value: "Marianópolis do Tocantins - TO", text: "Marianópolis do Tocantins - TO", state: "TO" },
    { value: "Maribondo - AL", text: "Maribondo - AL", state: "AL" },
    { value: "Maricá - RJ", text: "Maricá - RJ", state: "RJ" },
    { value: "Marilac - MG", text: "Marilac - MG", state: "MG" },
    { value: "Marilena - PR", text: "Marilena - PR", state: "PR" },
    { value: "Mariluz - PR", text: "Mariluz - PR", state: "PR" },
    { value: "Marilândia - ES", text: "Marilândia - ES", state: "ES" },
    { value: "Marilândia do Sul - PR", text: "Marilândia do Sul - PR", state: "PR" },
    { value: "Maringá - PR", text: "Maringá - PR", state: "PR" },
    { value: "Marinópolis - SP", text: "Marinópolis - SP", state: "SP" },
    { value: "Maripá - PR", text: "Maripá - PR", state: "PR" },
    { value: "Maripá de Minas - MG", text: "Maripá de Minas - MG", state: "MG" },
    { value: "Marituba - PA", text: "Marituba - PA", state: "PA" },
    { value: "Marizópolis - PB", text: "Marizópolis - PB", state: "PB" },
    { value: "Mariápolis - SP", text: "Mariápolis - SP", state: "SP" },
    { value: "Mariópolis - PR", text: "Mariópolis - PR", state: "PR" },
    { value: "Marliéria - MG", text: "Marliéria - MG", state: "MG" },
    { value: "Marmeleiro - PR", text: "Marmeleiro - PR", state: "PR" },
    { value: "Marmelópolis - MG", text: "Marmelópolis - MG", state: "MG" },
    { value: "Marques de Souza - RS", text: "Marques de Souza - RS", state: "RS" },
    { value: "Marquinho - PR", text: "Marquinho - PR", state: "PR" },
    { value: "Martinho Campos - MG", text: "Martinho Campos - MG", state: "MG" },
    { value: "Martins - RN", text: "Martins - RN", state: "RN" },
    { value: "Martins Soares - MG", text: "Martins Soares - MG", state: "MG" },
    { value: "Martinópole - CE", text: "Martinópole - CE", state: "CE" },
    { value: "Martinópolis - SP", text: "Martinópolis - SP", state: "SP" },
    { value: "Maruim - SE", text: "Maruim - SE", state: "SE" },
    { value: "Marumbi - PR", text: "Marumbi - PR", state: "PR" },
    { value: "Marzagão - GO", text: "Marzagão - GO", state: "GO" },
    { value: "Marília - SP", text: "Marília - SP", state: "SP" },
    { value: "Mascote - BA", text: "Mascote - BA", state: "BA" },
    { value: "Massapê - CE", text: "Massapê - CE", state: "CE" },
    { value: "Massapê do Piauí - PI", text: "Massapê do Piauí - PI", state: "PI" },
    { value: "Massaranduba - PB", text: "Massaranduba - PB", state: "PB" },
    { value: "Massaranduba - SC", text: "Massaranduba - SC", state: "SC" },
    { value: "Mata - RS", text: "Mata - RS", state: "RS" },
    { value: "Mata Grande - AL", text: "Mata Grande - AL", state: "AL" },
    { value: "Mata Roma - MA", text: "Mata Roma - MA", state: "MA" },
    { value: "Mata Verde - MG", text: "Mata Verde - MG", state: "MG" },
    { value: "Mata de São João - BA", text: "Mata de São João - BA", state: "BA" },
    { value: "Mataraca - PB", text: "Mataraca - PB", state: "PB" },
    { value: "Mateiros - TO", text: "Mateiros - TO", state: "TO" },
    { value: "Matelândia - PR", text: "Matelândia - PR", state: "PR" },
    { value: "Materlândia - MG", text: "Materlândia - MG", state: "MG" },
    { value: "Mateus Leme - MG", text: "Mateus Leme - MG", state: "MG" },
    { value: "Mathias Lobato - MG", text: "Mathias Lobato - MG", state: "MG" },
    { value: "Matias Barbosa - MG", text: "Matias Barbosa - MG", state: "MG" },
    { value: "Matias Cardoso - MG", text: "Matias Cardoso - MG", state: "MG" },
    { value: "Matias Olímpio - PI", text: "Matias Olímpio - PI", state: "PI" },
    { value: "Matina - BA", text: "Matina - BA", state: "BA" },
    { value: "Matinha - MA", text: "Matinha - MA", state: "MA" },
    { value: "Matinhas - PB", text: "Matinhas - PB", state: "PB" },
    { value: "Matinhos - PR", text: "Matinhos - PR", state: "PR" },
    { value: "Matipó - MG", text: "Matipó - MG", state: "MG" },
    { value: "Mato Castelhano - RS", text: "Mato Castelhano - RS", state: "RS" },
    { value: "Mato Grosso - PB", text: "Mato Grosso - PB", state: "PB" },
    { value: "Mato Leitão - RS", text: "Mato Leitão - RS", state: "RS" },
    { value: "Mato Queimado - RS", text: "Mato Queimado - RS", state: "RS" },
    { value: "Mato Rico - PR", text: "Mato Rico - PR", state: "PR" },
    { value: "Mato Verde - MG", text: "Mato Verde - MG", state: "MG" },
    { value: "Matos Costa - SC", text: "Matos Costa - SC", state: "SC" },
    { value: "Matozinhos - MG", text: "Matozinhos - MG", state: "MG" },
    { value: "Matrinchã - GO", text: "Matrinchã - GO", state: "GO" },
    { value: "Matriz de Camaragibe - AL", text: "Matriz de Camaragibe - AL", state: "AL" },
    { value: "Matupá - MT", text: "Matupá - MT", state: "MT" },
    { value: "Maturéia - PB", text: "Maturéia - PB", state: "PB" },
    { value: "Matutina - MG", text: "Matutina - MG", state: "MG" },
    { value: "Matão - SP", text: "Matão - SP", state: "SP" },
    { value: "Matões - MA", text: "Matões - MA", state: "MA" },
    { value: "Matões do Norte - MA", text: "Matões do Norte - MA", state: "MA" },
    { value: "Maurilândia - GO", text: "Maurilândia - GO", state: "GO" },
    { value: "Maurilândia do Tocantins - TO", text: "Maurilândia do Tocantins - TO", state: "TO" },
    { value: "Mauriti - CE", text: "Mauriti - CE", state: "CE" },
    { value: "Mauá - SP", text: "Mauá - SP", state: "SP" },
    { value: "Mauá da Serra - PR", text: "Mauá da Serra - PR", state: "PR" },
    { value: "Maués - AM", text: "Maués - AM", state: "AM" },
    { value: "Maxaranguape - RN", text: "Maxaranguape - RN", state: "RN" },
    { value: "Maximiliano de Almeida - RS", text: "Maximiliano de Almeida - RS", state: "RS" },
    { value: "Mazagão - AP", text: "Mazagão - AP", state: "AP" },
    { value: "Maçambara - RS", text: "Maçambara - RS", state: "RS" },
    { value: "Medeiros - MG", text: "Medeiros - MG", state: "MG" },
    { value: "Medeiros Neto - BA", text: "Medeiros Neto - BA", state: "BA" },
    { value: "Medianeira - PR", text: "Medianeira - PR", state: "PR" },
    { value: "Medicilândia - PA", text: "Medicilândia - PA", state: "PA" },
    { value: "Medina - MG", text: "Medina - MG", state: "MG" },
    { value: "Meleiro - SC", text: "Meleiro - SC", state: "SC" },
    { value: "Melgaço - PA", text: "Melgaço - PA", state: "PA" },
    { value: "Mendes - RJ", text: "Mendes - RJ", state: "RJ" },
    { value: "Mendes Pimentel - MG", text: "Mendes Pimentel - MG", state: "MG" },
    { value: "Mendonça - SP", text: "Mendonça - SP", state: "SP" },
    { value: "Mercedes - PR", text: "Mercedes - PR", state: "PR" },
    { value: "Mercês - MG", text: "Mercês - MG", state: "MG" },
    { value: "Meridiano - SP", text: "Meridiano - SP", state: "SP" },
    { value: "Meruoca - CE", text: "Meruoca - CE", state: "CE" },
    { value: "Mesquita - MG", text: "Mesquita - MG", state: "MG" },
    { value: "Mesquita - RJ", text: "Mesquita - RJ", state: "RJ" },
    { value: "Messias - AL", text: "Messias - AL", state: "AL" },
    { value: "Messias Targino - RN", text: "Messias Targino - RN", state: "RN" },
    { value: "Mesópolis - SP", text: "Mesópolis - SP", state: "SP" },
    { value: "Miguel Alves - PI", text: "Miguel Alves - PI", state: "PI" },
    { value: "Miguel Calmon - BA", text: "Miguel Calmon - BA", state: "BA" },
    { value: "Miguel Leão - PI", text: "Miguel Leão - PI", state: "PI" },
    { value: "Miguel Pereira - RJ", text: "Miguel Pereira - RJ", state: "RJ" },
    { value: "Miguelópolis - SP", text: "Miguelópolis - SP", state: "SP" },
    { value: "Milagres - BA", text: "Milagres - BA", state: "BA" },
    { value: "Milagres - CE", text: "Milagres - CE", state: "CE" },
    { value: "Milagres do Maranhão - MA", text: "Milagres do Maranhão - MA", state: "MA" },
    { value: "Milhã - CE", text: "Milhã - CE", state: "CE" },
    { value: "Milton Brandão - PI", text: "Milton Brandão - PI", state: "PI" },
    { value: "Mimoso de Goiás - GO", text: "Mimoso de Goiás - GO", state: "GO" },
    { value: "Mimoso do Sul - ES", text: "Mimoso do Sul - ES", state: "ES" },
    { value: "Minador do Negrão - AL", text: "Minador do Negrão - AL", state: "AL" },
    { value: "Minas Novas - MG", text: "Minas Novas - MG", state: "MG" },
    { value: "Minas do Leão - RS", text: "Minas do Leão - RS", state: "RS" },
    { value: "Minaçu - GO", text: "Minaçu - GO", state: "GO" },
    { value: "Minduri - MG", text: "Minduri - MG", state: "MG" },
    { value: "Mineiros - GO", text: "Mineiros - GO", state: "GO" },
    { value: "Mineiros do Tietê - SP", text: "Mineiros do Tietê - SP", state: "SP" },
    { value: "Ministro Andreazza - RO", text: "Ministro Andreazza - RO", state: "RO" },
    { value: "Mira Estrela - SP", text: "Mira Estrela - SP", state: "SP" },
    { value: "Mirabela - MG", text: "Mirabela - MG", state: "MG" },
    { value: "Miracatu - SP", text: "Miracatu - SP", state: "SP" },
    { value: "Miracema - RJ", text: "Miracema - RJ", state: "RJ" },
    { value: "Miracema do Tocantins - TO", text: "Miracema do Tocantins - TO", state: "TO" },
    { value: "Mirador - MA", text: "Mirador - MA", state: "MA" },
    { value: "Mirador - PR", text: "Mirador - PR", state: "PR" },
    { value: "Miradouro - MG", text: "Miradouro - MG", state: "MG" },
    { value: "Miraguaí - RS", text: "Miraguaí - RS", state: "RS" },
    { value: "Miranda - MS", text: "Miranda - MS", state: "MS" },
    { value: "Miranda do Norte - MA", text: "Miranda do Norte - MA", state: "MA" },
    { value: "Mirandiba - PE", text: "Mirandiba - PE", state: "PE" },
    { value: "Mirandópolis - SP", text: "Mirandópolis - SP", state: "SP" },
    { value: "Mirangaba - BA", text: "Mirangaba - BA", state: "BA" },
    { value: "Miranorte - TO", text: "Miranorte - TO", state: "TO" },
    { value: "Mirante - BA", text: "Mirante - BA", state: "BA" },
    { value: "Mirante da Serra - RO", text: "Mirante da Serra - RO", state: "RO" },
    { value: "Mirante do Paranapanema - SP", text: "Mirante do Paranapanema - SP", state: "SP" },
    { value: "Miraselva - PR", text: "Miraselva - PR", state: "PR" },
    { value: "Mirassol - SP", text: "Mirassol - SP", state: "SP" },
    { value: "Mirassol d'Oeste - MT", text: "Mirassol d'Oeste - MT", state: "MT" },
    { value: "Mirassolândia - SP", text: "Mirassolândia - SP", state: "SP" },
    { value: "Miravânia - MG", text: "Miravânia - MG", state: "MG" },
    { value: "Miraí - MG", text: "Miraí - MG", state: "MG" },
    { value: "Miraíma - CE", text: "Miraíma - CE", state: "CE" },
    { value: "Mirim Doce - SC", text: "Mirim Doce - SC", state: "SC" },
    { value: "Mirinzal - MA", text: "Mirinzal - MA", state: "MA" },
    { value: "Missal - PR", text: "Missal - PR", state: "PR" },
    { value: "Missão Velha - CE", text: "Missão Velha - CE", state: "CE" },
    { value: "Mocajuba - PA", text: "Mocajuba - PA", state: "PA" },
    { value: "Mococa - SP", text: "Mococa - SP", state: "SP" },
    { value: "Modelo - SC", text: "Modelo - SC", state: "SC" },
    { value: "Moeda - MG", text: "Moeda - MG", state: "MG" },
    { value: "Moema - MG", text: "Moema - MG", state: "MG" },
    { value: "Mogeiro - PB", text: "Mogeiro - PB", state: "PB" },
    { value: "Mogi das Cruzes - SP", text: "Mogi das Cruzes - SP", state: "SP" },
    { value: "Mogi-Guaçu - SP", text: "Mogi-Guaçu - SP", state: "SP" },
    { value: "Mogi-Mirim - SP", text: "Mogi-Mirim - SP", state: "SP" },
    { value: "Moiporá - GO", text: "Moiporá - GO", state: "GO" },
    { value: "Moita Bonita - SE", text: "Moita Bonita - SE", state: "SE" },
    { value: "Moju - PA", text: "Moju - PA", state: "PA" },
    { value: "Mombaça - CE", text: "Mombaça - CE", state: "CE" },
    { value: "Mombuca - SP", text: "Mombuca - SP", state: "SP" },
    { value: "Mondaí - SC", text: "Mondaí - SC", state: "SC" },
    { value: "Mongaguá - SP", text: "Mongaguá - SP", state: "SP" },
    { value: "Monjolos - MG", text: "Monjolos - MG", state: "MG" },
    { value: "Monsenhor Gil - PI", text: "Monsenhor Gil - PI", state: "PI" },
    { value: "Monsenhor Hipólito - PI", text: "Monsenhor Hipólito - PI", state: "PI" },
    { value: "Monsenhor Paulo - MG", text: "Monsenhor Paulo - MG", state: "MG" },
    { value: "Monsenhor Tabosa - CE", text: "Monsenhor Tabosa - CE", state: "CE" },
    { value: "Montadas - PB", text: "Montadas - PB", state: "PB" },
    { value: "Montalvânia - MG", text: "Montalvânia - MG", state: "MG" },
    { value: "Montanha - ES", text: "Montanha - ES", state: "ES" },
    { value: "Montanhas - RN", text: "Montanhas - RN", state: "RN" },
    { value: "Montauri - RS", text: "Montauri - RS", state: "RS" },
    { value: "Monte Alegre - PA", text: "Monte Alegre - PA", state: "PA" },
    { value: "Monte Alegre - RN", text: "Monte Alegre - RN", state: "RN" },
    { value: "Monte Alegre de Goiás - GO", text: "Monte Alegre de Goiás - GO", state: "GO" },
    { value: "Monte Alegre de Minas - MG", text: "Monte Alegre de Minas - MG", state: "MG" },
    { value: "Monte Alegre de Sergipe - SE", text: "Monte Alegre de Sergipe - SE", state: "SE" },
    { value: "Monte Alegre do Piauí - PI", text: "Monte Alegre do Piauí - PI", state: "PI" },
    { value: "Monte Alegre do Sul - SP", text: "Monte Alegre do Sul - SP", state: "SP" },
    { value: "Monte Alegre dos Campos - RS", text: "Monte Alegre dos Campos - RS", state: "RS" },
    { value: "Monte Alto - SP", text: "Monte Alto - SP", state: "SP" },
    { value: "Monte Aprazível - SP", text: "Monte Aprazível - SP", state: "SP" },
    { value: "Monte Azul - MG", text: "Monte Azul - MG", state: "MG" },
    { value: "Monte Azul Paulista - SP", text: "Monte Azul Paulista - SP", state: "SP" },
    { value: "Monte Belo - MG", text: "Monte Belo - MG", state: "MG" },
    { value: "Monte Belo do Sul - RS", text: "Monte Belo do Sul - RS", state: "RS" },
    { value: "Monte Carlo - SC", text: "Monte Carlo - SC", state: "SC" },
    { value: "Monte Carmelo - MG", text: "Monte Carmelo - MG", state: "MG" },
    { value: "Monte Castelo - SC", text: "Monte Castelo - SC", state: "SC" },
    { value: "Monte Castelo - SP", text: "Monte Castelo - SP", state: "SP" },
    { value: "Monte Formoso - MG", text: "Monte Formoso - MG", state: "MG" },
    { value: "Monte Horebe - PB", text: "Monte Horebe - PB", state: "PB" },
    { value: "Monte Mor - SP", text: "Monte Mor - SP", state: "SP" },
    { value: "Monte Negro - RO", text: "Monte Negro - RO", state: "RO" },
    { value: "Monte Santo - BA", text: "Monte Santo - BA", state: "BA" },
    { value: "Monte Santo de Minas - MG", text: "Monte Santo de Minas - MG", state: "MG" },
    { value: "Monte Santo do Tocantins - TO", text: "Monte Santo do Tocantins - TO", state: "TO" },
    { value: "Monte Sião - MG", text: "Monte Sião - MG", state: "MG" },
    { value: "Monte das Gameleiras - RN", text: "Monte das Gameleiras - RN", state: "RN" },
    { value: "Monte do Carmo - TO", text: "Monte do Carmo - TO", state: "TO" },
    { value: "Monteiro - PB", text: "Monteiro - PB", state: "PB" },
    { value: "Monteiro Lobato - SP", text: "Monteiro Lobato - SP", state: "SP" },
    { value: "Monteirópolis - AL", text: "Monteirópolis - AL", state: "AL" },
    { value: "Montenegro - RS", text: "Montenegro - RS", state: "RS" },
    { value: "Montes Altos - MA", text: "Montes Altos - MA", state: "MA" },
    { value: "Montes Claros - MG", text: "Montes Claros - MG", state: "MG" },
    { value: "Montes Claros de Goiás - GO", text: "Montes Claros de Goiás - GO", state: "GO" },
    { value: "Montezuma - MG", text: "Montezuma - MG", state: "MG" },
    { value: "Montividiu - GO", text: "Montividiu - GO", state: "GO" },
    { value: "Montividiu do Norte - GO", text: "Montividiu do Norte - GO", state: "GO" },
    { value: "Monção - MA", text: "Monção - MA", state: "MA" },
    { value: "Monções - SP", text: "Monções - SP", state: "SP" },
    { value: "Morada Nova - CE", text: "Morada Nova - CE", state: "CE" },
    { value: "Morada Nova de Minas - MG", text: "Morada Nova de Minas - MG", state: "MG" },
    { value: "Moraújo - CE", text: "Moraújo - CE", state: "CE" },
    { value: "Moreilândia - PE", text: "Moreilândia - PE", state: "PE" },
    { value: "Moreira Sales - PR", text: "Moreira Sales - PR", state: "PR" },
    { value: "Moreno - PE", text: "Moreno - PE", state: "PE" },
    { value: "Mormaço - RS", text: "Mormaço - RS", state: "RS" },
    { value: "Morpará - BA", text: "Morpará - BA", state: "BA" },
    { value: "Morretes - PR", text: "Morretes - PR", state: "PR" },
    { value: "Morrinhos - CE", text: "Morrinhos - CE", state: "CE" },
    { value: "Morrinhos - GO", text: "Morrinhos - GO", state: "GO" },
    { value: "Morrinhos do Sul - RS", text: "Morrinhos do Sul - RS", state: "RS" },
    { value: "Morro Agudo - SP", text: "Morro Agudo - SP", state: "SP" },
    { value: "Morro Agudo de Goiás - GO", text: "Morro Agudo de Goiás - GO", state: "GO" },
    { value: "Morro Cabeça no Tempo - PI", text: "Morro Cabeça no Tempo - PI", state: "PI" },
    { value: "Morro Grande - SC", text: "Morro Grande - SC", state: "SC" },
    { value: "Morro Redondo - RS", text: "Morro Redondo - RS", state: "RS" },
    { value: "Morro Reuter - RS", text: "Morro Reuter - RS", state: "RS" },
    { value: "Morro da Fumaça - SC", text: "Morro da Fumaça - SC", state: "SC" },
    { value: "Morro da Garça - MG", text: "Morro da Garça - MG", state: "MG" },
    { value: "Morro do Chapéu - BA", text: "Morro do Chapéu - BA", state: "BA" },
    { value: "Morro do Chapéu do Piauí - PI", text: "Morro do Chapéu do Piauí - PI", state: "PI" },
    { value: "Morro do Pilar - MG", text: "Morro do Pilar - MG", state: "MG" },
    { value: "Morros - MA", text: "Morros - MA", state: "MA" },
    { value: "Mortugaba - BA", text: "Mortugaba - BA", state: "BA" },
    { value: "Morungaba - SP", text: "Morungaba - SP", state: "SP" },
    { value: "Mossoró - RN", text: "Mossoró - RN", state: "RN" },
    { value: "Mossâmedes - GO", text: "Mossâmedes - GO", state: "GO" },
    { value: "Mostardas - RS", text: "Mostardas - RS", state: "RS" },
    { value: "Motuca - SP", text: "Motuca - SP", state: "SP" },
    { value: "Mozarlândia - GO", text: "Mozarlândia - GO", state: "GO" },
    { value: "Muaná - PA", text: "Muaná - PA", state: "PA" },
    { value: "Mucajaí - RR", text: "Mucajaí - RR", state: "RR" },
    { value: "Mucambo - CE", text: "Mucambo - CE", state: "CE" },
    { value: "Mucugê - BA", text: "Mucugê - BA", state: "BA" },
    { value: "Mucuri - BA", text: "Mucuri - BA", state: "BA" },
    { value: "Mucurici - ES", text: "Mucurici - ES", state: "ES" },
    { value: "Muitos Capões - RS", text: "Muitos Capões - RS", state: "RS" },
    { value: "Muliterno - RS", text: "Muliterno - RS", state: "RS" },
    { value: "Mulungu - CE", text: "Mulungu - CE", state: "CE" },
    { value: "Mulungu - PB", text: "Mulungu - PB", state: "PB" },
    { value: "Mulungu do Morro - BA", text: "Mulungu do Morro - BA", state: "BA" },
    { value: "Mundo Novo - BA", text: "Mundo Novo - BA", state: "BA" },
    { value: "Mundo Novo - GO", text: "Mundo Novo - GO", state: "GO" },
    { value: "Mundo Novo - MS", text: "Mundo Novo - MS", state: "MS" },
    { value: "Munhoz - MG", text: "Munhoz - MG", state: "MG" },
    { value: "Munhoz de Melo - PR", text: "Munhoz de Melo - PR", state: "PR" },
    { value: "Muniz Ferreira - BA", text: "Muniz Ferreira - BA", state: "BA" },
    { value: "Muniz Freire - ES", text: "Muniz Freire - ES", state: "ES" },
    { value: "Muqui - ES", text: "Muqui - ES", state: "ES" },
    { value: "Muquém de São Francisco - BA", text: "Muquém de São Francisco - BA", state: "BA" },
    { value: "Muriaé - MG", text: "Muriaé - MG", state: "MG" },
    { value: "Muribeca - SE", text: "Muribeca - SE", state: "SE" },
    { value: "Murici - AL", text: "Murici - AL", state: "AL" },
    { value: "Murici dos Portelas - PI", text: "Murici dos Portelas - PI", state: "PI" },
    { value: "Muricilândia - TO", text: "Muricilândia - TO", state: "TO" },
    { value: "Muritiba - BA", text: "Muritiba - BA", state: "BA" },
    { value: "Murutinga do Sul - SP", text: "Murutinga do Sul - SP", state: "SP" },
    { value: "Mutum - MG", text: "Mutum - MG", state: "MG" },
    { value: "Mutunópolis - GO", text: "Mutunópolis - GO", state: "GO" },
    { value: "Mutuípe - BA", text: "Mutuípe - BA", state: "BA" },
    { value: "Muzambinho - MG", text: "Muzambinho - MG", state: "MG" },
    { value: "Muçum - RS", text: "Muçum - RS", state: "RS" },
    { value: "Mário Campos - MG", text: "Mário Campos - MG", state: "MG" },
    { value: "Mâncio Lima - AC", text: "Mâncio Lima - AC", state: "AC" },
    { value: "Mãe d'Água - PB", text: "Mãe d'Água - PB", state: "PB" },
    { value: "Mãe do Rio - PA", text: "Mãe do Rio - PA", state: "PA" },
    { value: "Nacip Raydan - MG", text: "Nacip Raydan - MG", state: "MG" },
    { value: "Nantes - SP", text: "Nantes - SP", state: "SP" },
    { value: "Nanuque - MG", text: "Nanuque - MG", state: "MG" },
    { value: "Naque - MG", text: "Naque - MG", state: "MG" },
    { value: "Narandiba - SP", text: "Narandiba - SP", state: "SP" },
    { value: "Natal - RN", text: "Natal - RN", state: "RN" },
    { value: "Natalândia - MG", text: "Natalândia - MG", state: "MG" },
    { value: "Natividade - RJ", text: "Natividade - RJ", state: "RJ" },
    { value: "Natividade - TO", text: "Natividade - TO", state: "TO" },
    { value: "Natividade da Serra - SP", text: "Natividade da Serra - SP", state: "SP" },
    { value: "Natuba - PB", text: "Natuba - PB", state: "PB" },
    { value: "Natércia - MG", text: "Natércia - MG", state: "MG" },
    { value: "Navegantes - SC", text: "Navegantes - SC", state: "SC" },
    { value: "Naviraí - MS", text: "Naviraí - MS", state: "MS" },
    { value: "Nazareno - MG", text: "Nazareno - MG", state: "MG" },
    { value: "Nazarezinho - PB", text: "Nazarezinho - PB", state: "PB" },
    { value: "Nazaré - BA", text: "Nazaré - BA", state: "BA" },
    { value: "Nazaré - TO", text: "Nazaré - TO", state: "TO" },
    { value: "Nazaré Paulista - SP", text: "Nazaré Paulista - SP", state: "SP" },
    { value: "Nazaré da Mata - PE", text: "Nazaré da Mata - PE", state: "PE" },
    { value: "Nazaré do Piauí - PI", text: "Nazaré do Piauí - PI", state: "PI" },
    { value: "Nazário - GO", text: "Nazário - GO", state: "GO" },
    { value: "Nepomuceno - MG", text: "Nepomuceno - MG", state: "MG" },
    { value: "Nerópolis - GO", text: "Nerópolis - GO", state: "GO" },
    { value: "Neves Paulista - SP", text: "Neves Paulista - SP", state: "SP" },
    { value: "Neópolis - SE", text: "Neópolis - SE", state: "SE" },
    { value: "Nhamundá - AM", text: "Nhamundá - AM", state: "AM" },
    { value: "Nhandeara - SP", text: "Nhandeara - SP", state: "SP" },
    { value: "Nicolau Vergueiro - RS", text: "Nicolau Vergueiro - RS", state: "RS" },
    { value: "Nilo Peçanha - BA", text: "Nilo Peçanha - BA", state: "BA" },
    { value: "Nilópolis - RJ", text: "Nilópolis - RJ", state: "RJ" },
    { value: "Nina Rodrigues - MA", text: "Nina Rodrigues - MA", state: "MA" },
    { value: "Ninheira - MG", text: "Ninheira - MG", state: "MG" },
    { value: "Nioaque - MS", text: "Nioaque - MS", state: "MS" },
    { value: "Nipoã - SP", text: "Nipoã - SP", state: "SP" },
    { value: "Niquelândia - GO", text: "Niquelândia - GO", state: "GO" },
    { value: "Niterói - RJ", text: "Niterói - RJ", state: "RJ" },
    { value: "Nobres - MT", text: "Nobres - MT", state: "MT" },
    { value: "Nonoai - RS", text: "Nonoai - RS", state: "RS" },
    { value: "Nordestina - BA", text: "Nordestina - BA", state: "BA" },
    { value: "Normandia - RR", text: "Normandia - RR", state: "RR" },
    { value: "Nortelândia - MT", text: "Nortelândia - MT", state: "MT" },
    { value: "Nossa Senhora Aparecida - SE", text: "Nossa Senhora Aparecida - SE", state: "SE" },
    { value: "Nossa Senhora da Glória - SE", text: "Nossa Senhora da Glória - SE", state: "SE" },
    { value: "Nossa Senhora das Dores - SE", text: "Nossa Senhora das Dores - SE", state: "SE" },
    { value: "Nossa Senhora das Graças - PR", text: "Nossa Senhora das Graças - PR", state: "PR" },
    { value: "Nossa Senhora de Lourdes - SE", text: "Nossa Senhora de Lourdes - SE", state: "SE" },
    { value: "Nossa Senhora de Nazaré - PI", text: "Nossa Senhora de Nazaré - PI", state: "PI" },
    { value: "Nossa Senhora do Livramento - MT", text: "Nossa Senhora do Livramento - MT", state: "MT" },
    { value: "Nossa Senhora do Socorro - SE", text: "Nossa Senhora do Socorro - SE", state: "SE" },
    { value: "Nossa Senhora dos Remédios - PI", text: "Nossa Senhora dos Remédios - PI", state: "PI" },
    { value: "Nova Aliança - SP", text: "Nova Aliança - SP", state: "SP" },
    { value: "Nova Aliança do Ivaí - PR", text: "Nova Aliança do Ivaí - PR", state: "PR" },
    { value: "Nova Alvorada - RS", text: "Nova Alvorada - RS", state: "RS" },
    { value: "Nova Alvorada do Sul - MS", text: "Nova Alvorada do Sul - MS", state: "MS" },
    { value: "Nova América - GO", text: "Nova América - GO", state: "GO" },
    { value: "Nova América da Colina - PR", text: "Nova América da Colina - PR", state: "PR" },
    { value: "Nova Andradina - MS", text: "Nova Andradina - MS", state: "MS" },
    { value: "Nova Araçá - RS", text: "Nova Araçá - RS", state: "RS" },
    { value: "Nova Aurora - GO", text: "Nova Aurora - GO", state: "GO" },
    { value: "Nova Aurora - PR", text: "Nova Aurora - PR", state: "PR" },
    { value: "Nova Bandeirantes - MT", text: "Nova Bandeirantes - MT", state: "MT" },
    { value: "Nova Bassano - RS", text: "Nova Bassano - RS", state: "RS" },
    { value: "Nova Belém - MG", text: "Nova Belém - MG", state: "MG" },
    { value: "Nova Boa Vista - RS", text: "Nova Boa Vista - RS", state: "RS" },
    { value: "Nova Brasilândia - MT", text: "Nova Brasilândia - MT", state: "MT" },
    { value: "Nova Brasilândia d'Oeste - RO", text: "Nova Brasilândia d'Oeste - RO", state: "RO" },
    { value: "Nova Bréscia - RS", text: "Nova Bréscia - RS", state: "RS" },
    { value: "Nova Campina - SP", text: "Nova Campina - SP", state: "SP" },
    { value: "Nova Canaã - BA", text: "Nova Canaã - BA", state: "BA" },
    { value: "Nova Canaã Paulista - SP", text: "Nova Canaã Paulista - SP", state: "SP" },
    { value: "Nova Candelária - RS", text: "Nova Candelária - RS", state: "RS" },
    { value: "Nova Cantu - PR", text: "Nova Cantu - PR", state: "PR" },
    { value: "Nova Canãa do Norte - MT", text: "Nova Canãa do Norte - MT", state: "MT" },
    { value: "Nova Castilho - SP", text: "Nova Castilho - SP", state: "SP" },
    { value: "Nova Colinas - MA", text: "Nova Colinas - MA", state: "MA" },
    { value: "Nova Crixás - GO", text: "Nova Crixás - GO", state: "GO" },
    { value: "Nova Cruz - RN", text: "Nova Cruz - RN", state: "RN" },
    { value: "Nova Era - MG", text: "Nova Era - MG", state: "MG" },
    { value: "Nova Erechim - SC", text: "Nova Erechim - SC", state: "SC" },
    { value: "Nova Esperança - PR", text: "Nova Esperança - PR", state: "PR" },
    { value: "Nova Esperança do Piriá - PA", text: "Nova Esperança do Piriá - PA", state: "PA" },
    { value: "Nova Esperança do Sudoeste - PR", text: "Nova Esperança do Sudoeste - PR", state: "PR" },
    { value: "Nova Esperança do Sul - RS", text: "Nova Esperança do Sul - RS", state: "RS" },
    { value: "Nova Europa - SP", text: "Nova Europa - SP", state: "SP" },
    { value: "Nova Floresta - PB", text: "Nova Floresta - PB", state: "PB" },
    { value: "Nova Friburgo - RJ", text: "Nova Friburgo - RJ", state: "RJ" },
    { value: "Nova Fátima - BA", text: "Nova Fátima - BA", state: "BA" },
    { value: "Nova Fátima - PR", text: "Nova Fátima - PR", state: "PR" },
    { value: "Nova Glória - GO", text: "Nova Glória - GO", state: "GO" },
    { value: "Nova Granada - SP", text: "Nova Granada - SP", state: "SP" },
    { value: "Nova Guarita - MT", text: "Nova Guarita - MT", state: "MT" },
    { value: "Nova Guataporanga - SP", text: "Nova Guataporanga - SP", state: "SP" },
    { value: "Nova Hartz - RS", text: "Nova Hartz - RS", state: "RS" },
    { value: "Nova Ibiá - BA", text: "Nova Ibiá - BA", state: "BA" },
    { value: "Nova Iguaçu - RJ", text: "Nova Iguaçu - RJ", state: "RJ" },
    { value: "Nova Iguaçu de Goiás - GO", text: "Nova Iguaçu de Goiás - GO", state: "GO" },
    { value: "Nova Independência - SP", text: "Nova Independência - SP", state: "SP" },
    { value: "Nova Iorque - MA", text: "Nova Iorque - MA", state: "MA" },
    { value: "Nova Ipixuna - PA", text: "Nova Ipixuna - PA", state: "PA" },
    { value: "Nova Itaberaba - SC", text: "Nova Itaberaba - SC", state: "SC" },
    { value: "Nova Itarana - BA", text: "Nova Itarana - BA", state: "BA" },
    { value: "Nova Lacerda - MT", text: "Nova Lacerda - MT", state: "MT" },
    { value: "Nova Laranjeiras - PR", text: "Nova Laranjeiras - PR", state: "PR" },
    { value: "Nova Lima - MG", text: "Nova Lima - MG", state: "MG" },
    { value: "Nova Londrina - PR", text: "Nova Londrina - PR", state: "PR" },
    { value: "Nova Luzitânia - SP", text: "Nova Luzitânia - SP", state: "SP" },
    { value: "Nova Mamoré - RO", text: "Nova Mamoré - RO", state: "RO" },
    { value: "Nova Marilândia - MT", text: "Nova Marilândia - MT", state: "MT" },
    { value: "Nova Maringá - MT", text: "Nova Maringá - MT", state: "MT" },
    { value: "Nova Monte Verde - MT", text: "Nova Monte Verde - MT", state: "MT" },
    { value: "Nova Mutum - MT", text: "Nova Mutum - MT", state: "MT" },
    { value: "Nova Módica - MG", text: "Nova Módica - MG", state: "MG" },
    { value: "Nova Nazaré - MT", text: "Nova Nazaré - MT", state: "MT" },
    { value: "Nova Odessa - SP", text: "Nova Odessa - SP", state: "SP" },
    { value: "Nova Olinda - CE", text: "Nova Olinda - CE", state: "CE" },
    { value: "Nova Olinda - PB", text: "Nova Olinda - PB", state: "PB" },
    { value: "Nova Olinda - TO", text: "Nova Olinda - TO", state: "TO" },
    { value: "Nova Olinda do Maranhão - MA", text: "Nova Olinda do Maranhão - MA", state: "MA" },
    { value: "Nova Olinda do Norte - AM", text: "Nova Olinda do Norte - AM", state: "AM" },
    { value: "Nova Olímpia - MT", text: "Nova Olímpia - MT", state: "MT" },
    { value: "Nova Olímpia - PR", text: "Nova Olímpia - PR", state: "PR" },
    { value: "Nova Palma - RS", text: "Nova Palma - RS", state: "RS" },
    { value: "Nova Palmeira - PB", text: "Nova Palmeira - PB", state: "PB" },
    { value: "Nova Petrópolis - RS", text: "Nova Petrópolis - RS", state: "RS" },
    { value: "Nova Ponte - MG", text: "Nova Ponte - MG", state: "MG" },
    { value: "Nova Porteirinha - MG", text: "Nova Porteirinha - MG", state: "MG" },
    { value: "Nova Prata - RS", text: "Nova Prata - RS", state: "RS" },
    { value: "Nova Prata do Iguaçu - PR", text: "Nova Prata do Iguaçu - PR", state: "PR" },
    { value: "Nova Pádua - RS", text: "Nova Pádua - RS", state: "RS" },
    { value: "Nova Ramada - RS", text: "Nova Ramada - RS", state: "RS" },
    { value: "Nova Redenção - BA", text: "Nova Redenção - BA", state: "BA" },
    { value: "Nova Resende - MG", text: "Nova Resende - MG", state: "MG" },
    { value: "Nova Roma - GO", text: "Nova Roma - GO", state: "GO" },
    { value: "Nova Roma do Sul - RS", text: "Nova Roma do Sul - RS", state: "RS" },
    { value: "Nova Rosalândia - TO", text: "Nova Rosalândia - TO", state: "TO" },
    { value: "Nova Russas - CE", text: "Nova Russas - CE", state: "CE" },
    { value: "Nova Santa Bárbara - PR", text: "Nova Santa Bárbara - PR", state: "PR" },
    { value: "Nova Santa Helena - MT", text: "Nova Santa Helena - MT", state: "MT" },
    { value: "Nova Santa Rita - PI", text: "Nova Santa Rita - PI", state: "PI" },
    { value: "Nova Santa Rita - RS", text: "Nova Santa Rita - RS", state: "RS" },
    { value: "Nova Santa Rosa - PR", text: "Nova Santa Rosa - PR", state: "PR" },
    { value: "Nova Serrana - MG", text: "Nova Serrana - MG", state: "MG" },
    { value: "Nova Soure - BA", text: "Nova Soure - BA", state: "BA" },
    { value: "Nova Tebas - PR", text: "Nova Tebas - PR", state: "PR" },
    { value: "Nova Timboteua - PA", text: "Nova Timboteua - PA", state: "PA" },
    { value: "Nova Trento - SC", text: "Nova Trento - SC", state: "SC" },
    { value: "Nova Ubiratã - MT", text: "Nova Ubiratã - MT", state: "MT" },
    { value: "Nova União - MG", text: "Nova União - MG", state: "MG" },
    { value: "Nova União - RO", text: "Nova União - RO", state: "RO" },
    { value: "Nova Veneza - GO", text: "Nova Veneza - GO", state: "GO" },
    { value: "Nova Veneza - SC", text: "Nova Veneza - SC", state: "SC" },
    { value: "Nova Venécia - ES", text: "Nova Venécia - ES", state: "ES" },
    { value: "Nova Viçosa - BA", text: "Nova Viçosa - BA", state: "BA" },
    { value: "Nova Xavantina - MT", text: "Nova Xavantina - MT", state: "MT" },
    { value: "Novais - SP", text: "Novais - SP", state: "SP" },
    { value: "Novo Acordo - TO", text: "Novo Acordo - TO", state: "TO" },
    { value: "Novo Airão - AM", text: "Novo Airão - AM", state: "AM" },
    { value: "Novo Alegre - TO", text: "Novo Alegre - TO", state: "TO" },
    { value: "Novo Aripuanã - AM", text: "Novo Aripuanã - AM", state: "AM" },
    { value: "Novo Barreiro - RS", text: "Novo Barreiro - RS", state: "RS" },
    { value: "Novo Brasil - GO", text: "Novo Brasil - GO", state: "GO" },
    { value: "Novo Cabrais - RS", text: "Novo Cabrais - RS", state: "RS" },
    { value: "Novo Cruzeiro - MG", text: "Novo Cruzeiro - MG", state: "MG" },
    { value: "Novo Gama - GO", text: "Novo Gama - GO", state: "GO" },
    { value: "Novo Hamburgo - RS", text: "Novo Hamburgo - RS", state: "RS" },
    { value: "Novo Horizonte - BA", text: "Novo Horizonte - BA", state: "BA" },
    { value: "Novo Horizonte - SC", text: "Novo Horizonte - SC", state: "SC" },
    { value: "Novo Horizonte - SP", text: "Novo Horizonte - SP", state: "SP" },
    { value: "Novo Horizonte do Norte - MT", text: "Novo Horizonte do Norte - MT", state: "MT" },
    { value: "Novo Horizonte do Oeste - RO", text: "Novo Horizonte do Oeste - RO", state: "RO" },
    { value: "Novo Horizonte do Sul - MS", text: "Novo Horizonte do Sul - MS", state: "MS" },
    { value: "Novo Itacolomi - PR", text: "Novo Itacolomi - PR", state: "PR" },
    { value: "Novo Jardim - TO", text: "Novo Jardim - TO", state: "TO" },
    { value: "Novo Lino - AL", text: "Novo Lino - AL", state: "AL" },
    { value: "Novo Machado - RS", text: "Novo Machado - RS", state: "RS" },
    { value: "Novo Mundo - MT", text: "Novo Mundo - MT", state: "MT" },
    { value: "Novo Oriente - CE", text: "Novo Oriente - CE", state: "CE" },
    { value: "Novo Oriente de Minas - MG", text: "Novo Oriente de Minas - MG", state: "MG" },
    { value: "Novo Oriente do Piauí - PI", text: "Novo Oriente do Piauí - PI", state: "PI" },
    { value: "Novo Planalto - GO", text: "Novo Planalto - GO", state: "GO" },
    { value: "Novo Progresso - PA", text: "Novo Progresso - PA", state: "PA" },
    { value: "Novo Repartimento - PA", text: "Novo Repartimento - PA", state: "PA" },
    { value: "Novo Santo Antônio - MT", text: "Novo Santo Antônio - MT", state: "MT" },
    { value: "Novo Santo Antônio - PI", text: "Novo Santo Antônio - PI", state: "PI" },
    { value: "Novo São Joaquim - MT", text: "Novo São Joaquim - MT", state: "MT" },
    { value: "Novo Tiradentes - RS", text: "Novo Tiradentes - RS", state: "RS" },
    { value: "Novo Triunfo - BA", text: "Novo Triunfo - BA", state: "BA" },
    { value: "Novo Xingu - RS", text: "Novo Xingu - RS", state: "RS" },
    { value: "Novorizonte - MG", text: "Novorizonte - MG", state: "MG" },
    { value: "Nuporanga - SP", text: "Nuporanga - SP", state: "SP" },
    { value: "Não-Me-Toque - RS", text: "Não-Me-Toque - RS", state: "RS" },
    { value: "Nísia Floresta - RN", text: "Nísia Floresta - RN", state: "RN" },
    { value: "Núcleo Bandeirante - DF", text: "Núcleo Bandeirante - DF", state: "DF" },
    { value: "Ocara - CE", text: "Ocara - CE", state: "CE" },
    { value: "Ocauçu - SP", text: "Ocauçu - SP", state: "SP" },
    { value: "Octogonal - DF", text: "Octogonal - DF", state: "DF" },
    { value: "Oeiras - PI", text: "Oeiras - PI", state: "PI" },
    { value: "Oeiras do Pará - PA", text: "Oeiras do Pará - PA", state: "PA" },
    { value: "Oiapoque - AP", text: "Oiapoque - AP", state: "AP" },
    { value: "Olaria - MG", text: "Olaria - MG", state: "MG" },
    { value: "Olho d'Água - PB", text: "Olho d'Água - PB", state: "PB" },
    { value: "Olho d'Água Grande - AL", text: "Olho d'Água Grande - AL", state: "AL" },
    { value: "Olho d'Água das Cunhãs - MA", text: "Olho d'Água das Cunhãs - MA", state: "MA" },
    { value: "Olho d'Água das Flores - AL", text: "Olho d'Água das Flores - AL", state: "AL" },
    { value: "Olho d'Água do Casado - AL", text: "Olho d'Água do Casado - AL", state: "AL" },
    { value: "Olho d'Água do Piauí - PI", text: "Olho d'Água do Piauí - PI", state: "PI" },
    { value: "Olho-d'Água do Borges - RN", text: "Olho-d'Água do Borges - RN", state: "RN" },
    { value: "Olhos-d'Água - MG", text: "Olhos-d'Água - MG", state: "MG" },
    { value: "Olinda - PE", text: "Olinda - PE", state: "PE" },
    { value: "Olinda Nova do Maranhão - MA", text: "Olinda Nova do Maranhão - MA", state: "MA" },
    { value: "Olindina - BA", text: "Olindina - BA", state: "BA" },
    { value: "Olivedos - PB", text: "Olivedos - PB", state: "PB" },
    { value: "Oliveira - MG", text: "Oliveira - MG", state: "MG" },
    { value: "Oliveira Fortes - MG", text: "Oliveira Fortes - MG", state: "MG" },
    { value: "Oliveira de Fátima - TO", text: "Oliveira de Fátima - TO", state: "TO" },
    { value: "Oliveira dos Brejinhos - BA", text: "Oliveira dos Brejinhos - BA", state: "BA" },
    { value: "Olivença - AL", text: "Olivença - AL", state: "AL" },
    { value: "Olímpia - SP", text: "Olímpia - SP", state: "SP" },
    { value: "Olímpio Noronha - MG", text: "Olímpio Noronha - MG", state: "MG" },
    { value: "Onda Verde - SP", text: "Onda Verde - SP", state: "SP" },
    { value: "Onça de Pitangui - MG", text: "Onça de Pitangui - MG", state: "MG" },
    { value: "Oratórios - MG", text: "Oratórios - MG", state: "MG" },
    { value: "Oriente - SP", text: "Oriente - SP", state: "SP" },
    { value: "Orindiúva - SP", text: "Orindiúva - SP", state: "SP" },
    { value: "Oriximiná - PA", text: "Oriximiná - PA", state: "PA" },
    { value: "Orizona - GO", text: "Orizona - GO", state: "GO" },
    { value: "Orizânia - MG", text: "Orizânia - MG", state: "MG" },
    { value: "Orleans - SC", text: "Orleans - SC", state: "SC" },
    { value: "Orlândia - SP", text: "Orlândia - SP", state: "SP" },
    { value: "Orobó - PE", text: "Orobó - PE", state: "PE" },
    { value: "Orocó - PE", text: "Orocó - PE", state: "PE" },
    { value: "Ortigueira - PR", text: "Ortigueira - PR", state: "PR" },
    { value: "Orós - CE", text: "Orós - CE", state: "CE" },
    { value: "Osasco - SP", text: "Osasco - SP", state: "SP" },
    { value: "Oscar Bressane - SP", text: "Oscar Bressane - SP", state: "SP" },
    { value: "Osvaldo Cruz - SP", text: "Osvaldo Cruz - SP", state: "SP" },
    { value: "Osório - RS", text: "Osório - RS", state: "RS" },
    { value: "Otacílio Costa - SC", text: "Otacílio Costa - SC", state: "SC" },
    { value: "Ouricuri - PE", text: "Ouricuri - PE", state: "PE" },
    { value: "Ourilândia do Norte - PA", text: "Ourilândia do Norte - PA", state: "PA" },
    { value: "Ourinhos - SP", text: "Ourinhos - SP", state: "SP" },
    { value: "Ourizona - PR", text: "Ourizona - PR", state: "PR" },
    { value: "Ouriçangas - BA", text: "Ouriçangas - BA", state: "BA" },
    { value: "Ouro - SC", text: "Ouro - SC", state: "SC" },
    { value: "Ouro Branco - AL", text: "Ouro Branco - AL", state: "AL" },
    { value: "Ouro Branco - MG", text: "Ouro Branco - MG", state: "MG" },
    { value: "Ouro Branco - RN", text: "Ouro Branco - RN", state: "RN" },
    { value: "Ouro Fino - MG", text: "Ouro Fino - MG", state: "MG" },
    { value: "Ouro Preto - MG", text: "Ouro Preto - MG", state: "MG" },
    { value: "Ouro Preto do Oeste - RO", text: "Ouro Preto do Oeste - RO", state: "RO" },
    { value: "Ouro Velho - PB", text: "Ouro Velho - PB", state: "PB" },
    { value: "Ouro Verde - SC", text: "Ouro Verde - SC", state: "SC" },
    { value: "Ouro Verde - SP", text: "Ouro Verde - SP", state: "SP" },
    { value: "Ouro Verde de Goiás - GO", text: "Ouro Verde de Goiás - GO", state: "GO" },
    { value: "Ouro Verde de Minas - MG", text: "Ouro Verde de Minas - MG", state: "MG" },
    { value: "Ouro Verde do Oeste - PR", text: "Ouro Verde do Oeste - PR", state: "PR" },
    { value: "Ouroeste - SP", text: "Ouroeste - SP", state: "SP" },
    { value: "Ourolândia - BA", text: "Ourolândia - BA", state: "BA" },
    { value: "Ourém - PA", text: "Ourém - PA", state: "PA" },
    { value: "Ouvidor - GO", text: "Ouvidor - GO", state: "GO" },
    { value: "Pacaembu - SP", text: "Pacaembu - SP", state: "SP" },
    { value: "Pacajus - CE", text: "Pacajus - CE", state: "CE" },
    { value: "Pacajá - PA", text: "Pacajá - PA", state: "PA" },
    { value: "Pacaraima - RR", text: "Pacaraima - RR", state: "RR" },
    { value: "Pacatuba - CE", text: "Pacatuba - CE", state: "CE" },
    { value: "Pacatuba - SE", text: "Pacatuba - SE", state: "SE" },
    { value: "Pacoti - CE", text: "Pacoti - CE", state: "CE" },
    { value: "Pacujá - CE", text: "Pacujá - CE", state: "CE" },
    { value: "Padre Bernardo - GO", text: "Padre Bernardo - GO", state: "GO" },
    { value: "Padre Carvalho - MG", text: "Padre Carvalho - MG", state: "MG" },
    { value: "Padre Marcos - PI", text: "Padre Marcos - PI", state: "PI" },
    { value: "Padre Paraíso - MG", text: "Padre Paraíso - MG", state: "MG" },
    { value: "Paes Landim - PI", text: "Paes Landim - PI", state: "PI" },
    { value: "Pai Pedro - MG", text: "Pai Pedro - MG", state: "MG" },
    { value: "Paial - SC", text: "Paial - SC", state: "SC" },
    { value: "Paim Filho - RS", text: "Paim Filho - RS", state: "RS" },
    { value: "Paineiras - MG", text: "Paineiras - MG", state: "MG" },
    { value: "Painel - SC", text: "Painel - SC", state: "SC" },
    { value: "Pains - MG", text: "Pains - MG", state: "MG" },
    { value: "Paiva - MG", text: "Paiva - MG", state: "MG" },
    { value: "Paiçandu - PR", text: "Paiçandu - PR", state: "PR" },
    { value: "Pajeú do Piauí - PI", text: "Pajeú do Piauí - PI", state: "PI" },
    { value: "Palestina - AL", text: "Palestina - AL", state: "AL" },
    { value: "Palestina - SP", text: "Palestina - SP", state: "SP" },
    { value: "Palestina de Goiás - GO", text: "Palestina de Goiás - GO", state: "GO" },
    { value: "Palestina do Pará - PA", text: "Palestina do Pará - PA", state: "PA" },
    { value: "Palhano - CE", text: "Palhano - CE", state: "CE" },
    { value: "Palhoça - SC", text: "Palhoça - SC", state: "SC" },
    { value: "Palma - MG", text: "Palma - MG", state: "MG" },
    { value: "Palma Sola - SC", text: "Palma Sola - SC", state: "SC" },
    { value: "Palmares - PE", text: "Palmares - PE", state: "PE" },
    { value: "Palmares Paulista - SP", text: "Palmares Paulista - SP", state: "SP" },
    { value: "Palmares do Sul - RS", text: "Palmares do Sul - RS", state: "RS" },
    { value: "Palmas - PR", text: "Palmas - PR", state: "PR" },
    { value: "Palmas - TO", text: "Palmas - TO", state: "TO" },
    { value: "Palmas de Monte Alto - BA", text: "Palmas de Monte Alto - BA", state: "BA" },
    { value: "Palmeira - PR", text: "Palmeira - PR", state: "PR" },
    { value: "Palmeira - SC", text: "Palmeira - SC", state: "SC" },
    { value: "Palmeira d'Oeste - SP", text: "Palmeira d'Oeste - SP", state: "SP" },
    { value: "Palmeira das Missões - RS", text: "Palmeira das Missões - RS", state: "RS" },
    { value: "Palmeira do Piauí - PI", text: "Palmeira do Piauí - PI", state: "PI" },
    { value: "Palmeira dos Índios - AL", text: "Palmeira dos Índios - AL", state: "AL" },
    { value: "Palmeirais - PI", text: "Palmeirais - PI", state: "PI" },
    { value: "Palmeirante - TO", text: "Palmeirante - TO", state: "TO" },
    { value: "Palmeiras - BA", text: "Palmeiras - BA", state: "BA" },
    { value: "Palmeiras de Goiás - GO", text: "Palmeiras de Goiás - GO", state: "GO" },
    { value: "Palmeiras do Tocantins - TO", text: "Palmeiras do Tocantins - TO", state: "TO" },
    { value: "Palmeirina - PE", text: "Palmeirina - PE", state: "PE" },
    { value: "Palmeirândia - MA", text: "Palmeirândia - MA", state: "MA" },
    { value: "Palmeirópolis - TO", text: "Palmeirópolis - TO", state: "TO" },
    { value: "Palmelo - GO", text: "Palmelo - GO", state: "GO" },
    { value: "Palminópolis - GO", text: "Palminópolis - GO", state: "GO" },
    { value: "Palmital - PR", text: "Palmital - PR", state: "PR" },
    { value: "Palmital - SP", text: "Palmital - SP", state: "SP" },
    { value: "Palmitinho - RS", text: "Palmitinho - RS", state: "RS" },
    { value: "Palmitos - SC", text: "Palmitos - SC", state: "SC" },
    { value: "Palmácia - CE", text: "Palmácia - CE", state: "CE" },
    { value: "Palmópolis - MG", text: "Palmópolis - MG", state: "MG" },
    { value: "Palotina - PR", text: "Palotina - PR", state: "PR" },
    { value: "Panambi - RS", text: "Panambi - RS", state: "RS" },
    { value: "Panamá - GO", text: "Panamá - GO", state: "GO" },
    { value: "Pancas - ES", text: "Pancas - ES", state: "ES" },
    { value: "Panelas - PE", text: "Panelas - PE", state: "PE" },
    { value: "Panorama - SP", text: "Panorama - SP", state: "SP" },
    { value: "Papagaios - MG", text: "Papagaios - MG", state: "MG" },
    { value: "Papanduva - SC", text: "Papanduva - SC", state: "SC" },
    { value: "Paquetá - PI", text: "Paquetá - PI", state: "PI" },
    { value: "Paracambi - RJ", text: "Paracambi - RJ", state: "RJ" },
    { value: "Paracatu - MG", text: "Paracatu - MG", state: "MG" },
    { value: "Paracuru - CE", text: "Paracuru - CE", state: "CE" },
    { value: "Paragominas - PA", text: "Paragominas - PA", state: "PA" },
    { value: "Paraguaçu - MG", text: "Paraguaçu - MG", state: "MG" },
    { value: "Paraguaçu Paulista - SP", text: "Paraguaçu Paulista - SP", state: "SP" },
    { value: "Paraibano - MA", text: "Paraibano - MA", state: "MA" },
    { value: "Paraibuna - SP", text: "Paraibuna - SP", state: "SP" },
    { value: "Paraipaba - CE", text: "Paraipaba - CE", state: "CE" },
    { value: "Paraisópolis - MG", text: "Paraisópolis - MG", state: "MG" },
    { value: "Parambu - CE", text: "Parambu - CE", state: "CE" },
    { value: "Paramirim - BA", text: "Paramirim - BA", state: "BA" },
    { value: "Paramoti - CE", text: "Paramoti - CE", state: "CE" },
    { value: "Paranacity - PR", text: "Paranacity - PR", state: "PR" },
    { value: "Paranaguá - PR", text: "Paranaguá - PR", state: "PR" },
    { value: "Paranaiguara - GO", text: "Paranaiguara - GO", state: "GO" },
    { value: "Paranapanema - SP", text: "Paranapanema - SP", state: "SP" },
    { value: "Paranapoema - PR", text: "Paranapoema - PR", state: "PR" },
    { value: "Paranapuã - SP", text: "Paranapuã - SP", state: "SP" },
    { value: "Paranatama - PE", text: "Paranatama - PE", state: "PE" },
    { value: "Paranatinga - MT", text: "Paranatinga - MT", state: "MT" },
    { value: "Paranavaí - PR", text: "Paranavaí - PR", state: "PR" },
    { value: "Paranaíba - MS", text: "Paranaíba - MS", state: "MS" },
    { value: "Paranaíta - MT", text: "Paranaíta - MT", state: "MT" },
    { value: "Paranhos - MS", text: "Paranhos - MS", state: "MS" },
    { value: "Paranoá - DF", text: "Paranoá - DF", state: "DF" },
    { value: "Paraná - RN", text: "Paraná - RN", state: "RN" },
    { value: "Paranã - TO", text: "Paranã - TO", state: "TO" },
    { value: "Paraopeba - MG", text: "Paraopeba - MG", state: "MG" },
    { value: "Parapuã - SP", text: "Parapuã - SP", state: "SP" },
    { value: "Parari - PB", text: "Parari - PB", state: "PB" },
    { value: "Parati - RJ", text: "Parati - RJ", state: "RJ" },
    { value: "Paratinga - BA", text: "Paratinga - BA", state: "BA" },
    { value: "Parauapebas - PA", text: "Parauapebas - PA", state: "PA" },
    { value: "Parazinho - RN", text: "Parazinho - RN", state: "RN" },
    { value: "Paraí - RS", text: "Paraí - RS", state: "RS" },
    { value: "Paraíba do Sul - RJ", text: "Paraíba do Sul - RJ", state: "RJ" },
    { value: "Paraíso - SC", text: "Paraíso - SC", state: "SC" },
    { value: "Paraíso - SP", text: "Paraíso - SP", state: "SP" },
    { value: "Paraíso do Norte - PR", text: "Paraíso do Norte - PR", state: "PR" },
    { value: "Paraíso do Sul - RS", text: "Paraíso do Sul - RS", state: "RS" },
    { value: "Paraíso do Tocantins - TO", text: "Paraíso do Tocantins - TO", state: "TO" },
    { value: "Paraú - RN", text: "Paraú - RN", state: "RN" },
    { value: "Paraúna - GO", text: "Paraúna - GO", state: "GO" },
    { value: "Pardinho - SP", text: "Pardinho - SP", state: "SP" },
    { value: "Pareci Novo - RS", text: "Pareci Novo - RS", state: "RS" },
    { value: "Parecis - RO", text: "Parecis - RO", state: "RO" },
    { value: "Parelhas - RN", text: "Parelhas - RN", state: "RN" },
    { value: "Pariconha - AL", text: "Pariconha - AL", state: "AL" },
    { value: "Parintins - AM", text: "Parintins - AM", state: "AM" },
    { value: "Paripiranga - BA", text: "Paripiranga - BA", state: "BA" },
    { value: "Paripueira - AL", text: "Paripueira - AL", state: "AL" },
    { value: "Pariquera-Açu - SP", text: "Pariquera-Açu - SP", state: "SP" },
    { value: "Parisi - SP", text: "Parisi - SP", state: "SP" },
    { value: "Park Way - DF", text: "Park Way - DF", state: "DF" },
    { value: "Parnaguá - PI", text: "Parnaguá - PI", state: "PI" },
    { value: "Parnamirim - PE", text: "Parnamirim - PE", state: "PE" },
    { value: "Parnamirim - RN", text: "Parnamirim - RN", state: "RN" },
    { value: "Parnarama - MA", text: "Parnarama - MA", state: "MA" },
    { value: "Parnaíba - PI", text: "Parnaíba - PI", state: "PI" },
    { value: "Parobé - RS", text: "Parobé - RS", state: "RS" },
    { value: "Pará de Minas - MG", text: "Pará de Minas - MG", state: "MG" },
    { value: "Passa Quatro - MG", text: "Passa Quatro - MG", state: "MG" },
    { value: "Passa Sete - RS", text: "Passa Sete - RS", state: "RS" },
    { value: "Passa Tempo - MG", text: "Passa Tempo - MG", state: "MG" },
    { value: "Passa e Fica - RN", text: "Passa e Fica - RN", state: "RN" },
    { value: "Passa-Vinte - MG", text: "Passa-Vinte - MG", state: "MG" },
    { value: "Passabém - MG", text: "Passabém - MG", state: "MG" },
    { value: "Passagem - PB", text: "Passagem - PB", state: "PB" },
    { value: "Passagem - RN", text: "Passagem - RN", state: "RN" },
    { value: "Passagem Franca - MA", text: "Passagem Franca - MA", state: "MA" },
    { value: "Passagem Franca do Piauí - PI", text: "Passagem Franca do Piauí - PI", state: "PI" },
    { value: "Passira - PE", text: "Passira - PE", state: "PE" },
    { value: "Passo Fundo - RS", text: "Passo Fundo - RS", state: "RS" },
    { value: "Passo de Camaragibe - AL", text: "Passo de Camaragibe - AL", state: "AL" },
    { value: "Passo de Torres - SC", text: "Passo de Torres - SC", state: "SC" },
    { value: "Passo do Sobrado - RS", text: "Passo do Sobrado - RS", state: "RS" },
    { value: "Passos - MG", text: "Passos - MG", state: "MG" },
    { value: "Passos Maia - SC", text: "Passos Maia - SC", state: "SC" },
    { value: "Pastos Bons - MA", text: "Pastos Bons - MA", state: "MA" },
    { value: "Patis - MG", text: "Patis - MG", state: "MG" },
    { value: "Pato Bragado - PR", text: "Pato Bragado - PR", state: "PR" },
    { value: "Pato Branco - PR", text: "Pato Branco - PR", state: "PR" },
    { value: "Patos - PB", text: "Patos - PB", state: "PB" },
    { value: "Patos de Minas - MG", text: "Patos de Minas - MG", state: "MG" },
    { value: "Patos do Piauí - PI", text: "Patos do Piauí - PI", state: "PI" },
    { value: "Patrocínio - MG", text: "Patrocínio - MG", state: "MG" },
    { value: "Patrocínio Paulista - SP", text: "Patrocínio Paulista - SP", state: "SP" },
    { value: "Patrocínio do Muriaé - MG", text: "Patrocínio do Muriaé - MG", state: "MG" },
    { value: "Patu - RN", text: "Patu - RN", state: "RN" },
    { value: "Paty do Alferes - RJ", text: "Paty do Alferes - RJ", state: "RJ" },
    { value: "Pau Brasil - BA", text: "Pau Brasil - BA", state: "BA" },
    { value: "Pau d'Arco - PA", text: "Pau d'Arco - PA", state: "PA" },
    { value: "Pau d'Arco - TO", text: "Pau d'Arco - TO", state: "TO" },
    { value: "Pau d'Arco do Piauí - PI", text: "Pau d'Arco do Piauí - PI", state: "PI" },
    { value: "Pau dos Ferros - RN", text: "Pau dos Ferros - RN", state: "RN" },
    { value: "Paudalho - PE", text: "Paudalho - PE", state: "PE" },
    { value: "Pauini - AM", text: "Pauini - AM", state: "AM" },
    { value: "Paula Cândido - MG", text: "Paula Cândido - MG", state: "MG" },
    { value: "Paula Freitas - PR", text: "Paula Freitas - PR", state: "PR" },
    { value: "Paulicéia - SP", text: "Paulicéia - SP", state: "SP" },
    { value: "Paulino Neves - MA", text: "Paulino Neves - MA", state: "MA" },
    { value: "Paulista - PB", text: "Paulista - PB", state: "PB" },
    { value: "Paulista - PE", text: "Paulista - PE", state: "PE" },
    { value: "Paulistana - PI", text: "Paulistana - PI", state: "PI" },
    { value: "Paulistas - MG", text: "Paulistas - MG", state: "MG" },
    { value: "Paulistânia - SP", text: "Paulistânia - SP", state: "SP" },
    { value: "Paulo Afonso - BA", text: "Paulo Afonso - BA", state: "BA" },
    { value: "Paulo Bento - RS", text: "Paulo Bento - RS", state: "RS" },
    { value: "Paulo Frontin - PR", text: "Paulo Frontin - PR", state: "PR" },
    { value: "Paulo Jacinto - AL", text: "Paulo Jacinto - AL", state: "AL" },
    { value: "Paulo Lopes - SC", text: "Paulo Lopes - SC", state: "SC" },
    { value: "Paulo Ramos - MA", text: "Paulo Ramos - MA", state: "MA" },
    { value: "Paulo de Faria - SP", text: "Paulo de Faria - SP", state: "SP" },
    { value: "Paulínia - SP", text: "Paulínia - SP", state: "SP" },
    { value: "Paverama - RS", text: "Paverama - RS", state: "RS" },
    { value: "Pavussu - PI", text: "Pavussu - PI", state: "PI" },
    { value: "Pavão - MG", text: "Pavão - MG", state: "MG" },
    { value: "Paço do Lumiar - MA", text: "Paço do Lumiar - MA", state: "MA" },
    { value: "Peabiru - PR", text: "Peabiru - PR", state: "PR" },
    { value: "Pederneiras - SP", text: "Pederneiras - SP", state: "SP" },
    { value: "Pedra - PE", text: "Pedra - PE", state: "PE" },
    { value: "Pedra Azul - MG", text: "Pedra Azul - MG", state: "MG" },
    { value: "Pedra Bela - SP", text: "Pedra Bela - SP", state: "SP" },
    { value: "Pedra Bonita - MG", text: "Pedra Bonita - MG", state: "MG" },
    { value: "Pedra Branca - CE", text: "Pedra Branca - CE", state: "CE" },
    { value: "Pedra Branca - PB", text: "Pedra Branca - PB", state: "PB" },
    { value: "Pedra Branca do Amapari - AP", text: "Pedra Branca do Amapari - AP", state: "AP" },
    { value: "Pedra Dourada - MG", text: "Pedra Dourada - MG", state: "MG" },
    { value: "Pedra Grande - RN", text: "Pedra Grande - RN", state: "RN" },
    { value: "Pedra Lavrada - PB", text: "Pedra Lavrada - PB", state: "PB" },
    { value: "Pedra Mole - SE", text: "Pedra Mole - SE", state: "SE" },
    { value: "Pedra Preta - MT", text: "Pedra Preta - MT", state: "MT" },
    { value: "Pedra Preta - RN", text: "Pedra Preta - RN", state: "RN" },
    { value: "Pedra do Anta - MG", text: "Pedra do Anta - MG", state: "MG" },
    { value: "Pedra do Indaiá - MG", text: "Pedra do Indaiá - MG", state: "MG" },
    { value: "Pedralva - MG", text: "Pedralva - MG", state: "MG" },
    { value: "Pedranópolis - SP", text: "Pedranópolis - SP", state: "SP" },
    { value: "Pedras Altas - RS", text: "Pedras Altas - RS", state: "RS" },
    { value: "Pedras Grandes - SC", text: "Pedras Grandes - SC", state: "SC" },
    { value: "Pedras de Fogo - PB", text: "Pedras de Fogo - PB", state: "PB" },
    { value: "Pedras de Maria da Cruz - MG", text: "Pedras de Maria da Cruz - MG", state: "MG" },
    { value: "Pedregulho - SP", text: "Pedregulho - SP", state: "SP" },
    { value: "Pedreira - SP", text: "Pedreira - SP", state: "SP" },
    { value: "Pedreiras - MA", text: "Pedreiras - MA", state: "MA" },
    { value: "Pedrinhas - SE", text: "Pedrinhas - SE", state: "SE" },
    { value: "Pedrinhas Paulista - SP", text: "Pedrinhas Paulista - SP", state: "SP" },
    { value: "Pedrinópolis - MG", text: "Pedrinópolis - MG", state: "MG" },
    { value: "Pedro Afonso - TO", text: "Pedro Afonso - TO", state: "TO" },
    { value: "Pedro Alexandre - BA", text: "Pedro Alexandre - BA", state: "BA" },
    { value: "Pedro Avelino - RN", text: "Pedro Avelino - RN", state: "RN" },
    { value: "Pedro Canário - ES", text: "Pedro Canário - ES", state: "ES" },
    { value: "Pedro Gomes - MS", text: "Pedro Gomes - MS", state: "MS" },
    { value: "Pedro II - PI", text: "Pedro II - PI", state: "PI" },
    { value: "Pedro Laurentino - PI", text: "Pedro Laurentino - PI", state: "PI" },
    { value: "Pedro Leopoldo - MG", text: "Pedro Leopoldo - MG", state: "MG" },
    { value: "Pedro Osório - RS", text: "Pedro Osório - RS", state: "RS" },
    { value: "Pedro Régis - PB", text: "Pedro Régis - PB", state: "PB" },
    { value: "Pedro Teixeira - MG", text: "Pedro Teixeira - MG", state: "MG" },
    { value: "Pedro Velho - RN", text: "Pedro Velho - RN", state: "RN" },
    { value: "Pedro de Toledo - SP", text: "Pedro de Toledo - SP", state: "SP" },
    { value: "Pedro do Rosário - MA", text: "Pedro do Rosário - MA", state: "MA" },
    { value: "Pedrão - BA", text: "Pedrão - BA", state: "BA" },
    { value: "Peixe - TO", text: "Peixe - TO", state: "TO" },
    { value: "Peixe-Boi - PA", text: "Peixe-Boi - PA", state: "PA" },
    { value: "Peixoto de Azevedo - MT", text: "Peixoto de Azevedo - MT", state: "MT" },
    { value: "Pejuçara - RS", text: "Pejuçara - RS", state: "RS" },
    { value: "Pelotas - RS", text: "Pelotas - RS", state: "RS" },
    { value: "Penaforte - CE", text: "Penaforte - CE", state: "CE" },
    { value: "Penalva - MA", text: "Penalva - MA", state: "MA" },
    { value: "Pendências - RN", text: "Pendências - RN", state: "RN" },
    { value: "Penedo - AL", text: "Penedo - AL", state: "AL" },
    { value: "Penha - SC", text: "Penha - SC", state: "SC" },
    { value: "Pentecoste - CE", text: "Pentecoste - CE", state: "CE" },
    { value: "Penápolis - SP", text: "Penápolis - SP", state: "SP" },
    { value: "Pequeri - MG", text: "Pequeri - MG", state: "MG" },
    { value: "Pequi - MG", text: "Pequi - MG", state: "MG" },
    { value: "Pequizeiro - TO", text: "Pequizeiro - TO", state: "TO" },
    { value: "Perdigão - MG", text: "Perdigão - MG", state: "MG" },
    { value: "Perdizes - MG", text: "Perdizes - MG", state: "MG" },
    { value: "Perdões - MG", text: "Perdões - MG", state: "MG" },
    { value: "Pereira Barreto - SP", text: "Pereira Barreto - SP", state: "SP" },
    { value: "Pereiras - SP", text: "Pereiras - SP", state: "SP" },
    { value: "Pereiro - CE", text: "Pereiro - CE", state: "CE" },
    { value: "Peri Mirim - MA", text: "Peri Mirim - MA", state: "MA" },
    { value: "Periquito - MG", text: "Periquito - MG", state: "MG" },
    { value: "Peritiba - SC", text: "Peritiba - SC", state: "SC" },
    { value: "Peritoró - MA", text: "Peritoró - MA", state: "MA" },
    { value: "Perobal - PR", text: "Perobal - PR", state: "PR" },
    { value: "Perolândia - GO", text: "Perolândia - GO", state: "GO" },
    { value: "Peruíbe - SP", text: "Peruíbe - SP", state: "SP" },
    { value: "Pescador - MG", text: "Pescador - MG", state: "MG" },
    { value: "Pesqueira - PE", text: "Pesqueira - PE", state: "PE" },
    { value: "Petrolina - PE", text: "Petrolina - PE", state: "PE" },
    { value: "Petrolina de Goiás - GO", text: "Petrolina de Goiás - GO", state: "GO" },
    { value: "Petrolândia - PE", text: "Petrolândia - PE", state: "PE" },
    { value: "Petrolândia - SC", text: "Petrolândia - SC", state: "SC" },
    { value: "Petrópolis - RJ", text: "Petrópolis - RJ", state: "RJ" },
    { value: "Peçanha - MG", text: "Peçanha - MG", state: "MG" },
    { value: "Piacatu - SP", text: "Piacatu - SP", state: "SP" },
    { value: "Piancó - PB", text: "Piancó - PB", state: "PB" },
    { value: "Piatã - BA", text: "Piatã - BA", state: "BA" },
    { value: "Piau - MG", text: "Piau - MG", state: "MG" },
    { value: "Piaçabuçu - AL", text: "Piaçabuçu - AL", state: "AL" },
    { value: "Picada Café - RS", text: "Picada Café - RS", state: "RS" },
    { value: "Picos - PI", text: "Picos - PI", state: "PI" },
    { value: "Picuí - PB", text: "Picuí - PB", state: "PB" },
    { value: "Piedade - SP", text: "Piedade - SP", state: "SP" },
    { value: "Piedade de Caratinga - MG", text: "Piedade de Caratinga - MG", state: "MG" },
    { value: "Piedade de Ponte Nova - MG", text: "Piedade de Ponte Nova - MG", state: "MG" },
    { value: "Piedade do Rio Grande - MG", text: "Piedade do Rio Grande - MG", state: "MG" },
    { value: "Piedade dos Gerais - MG", text: "Piedade dos Gerais - MG", state: "MG" },
    { value: "Pilar - AL", text: "Pilar - AL", state: "AL" },
    { value: "Pilar - PB", text: "Pilar - PB", state: "PB" },
    { value: "Pilar de Goiás - GO", text: "Pilar de Goiás - GO", state: "GO" },
    { value: "Pilar do Sul - SP", text: "Pilar do Sul - SP", state: "SP" },
    { value: "Pilão Arcado - BA", text: "Pilão Arcado - BA", state: "BA" },
    { value: "Pilões - PB", text: "Pilões - PB", state: "PB" },
    { value: "Pilões - RN", text: "Pilões - RN", state: "RN" },
    { value: "Pilõezinhos - PB", text: "Pilõezinhos - PB", state: "PB" },
    { value: "Pimenta - MG", text: "Pimenta - MG", state: "MG" },
    { value: "Pimenta Bueno - RO", text: "Pimenta Bueno - RO", state: "RO" },
    { value: "Pimenteiras - PI", text: "Pimenteiras - PI", state: "PI" },
    { value: "Pimenteiras do Oeste - RO", text: "Pimenteiras do Oeste - RO", state: "RO" },
    { value: "Pindamonhangaba - SP", text: "Pindamonhangaba - SP", state: "SP" },
    { value: "Pindaré Mirim - MA", text: "Pindaré Mirim - MA", state: "MA" },
    { value: "Pindaí - BA", text: "Pindaí - BA", state: "BA" },
    { value: "Pindoba - AL", text: "Pindoba - AL", state: "AL" },
    { value: "Pindobaçu - BA", text: "Pindobaçu - BA", state: "BA" },
    { value: "Pindorama - SP", text: "Pindorama - SP", state: "SP" },
    { value: "Pindorama do Tocantins - TO", text: "Pindorama do Tocantins - TO", state: "TO" },
    { value: "Pindoretama - CE", text: "Pindoretama - CE", state: "CE" },
    { value: "Pingo-d'Água - MG", text: "Pingo-d'Água - MG", state: "MG" },
    { value: "Pinhais - PR", text: "Pinhais - PR", state: "PR" },
    { value: "Pinhal - RS", text: "Pinhal - RS", state: "RS" },
    { value: "Pinhal Grande - RS", text: "Pinhal Grande - RS", state: "RS" },
    { value: "Pinhal da Serra - RS", text: "Pinhal da Serra - RS", state: "RS" },
    { value: "Pinhal de São Bento - PR", text: "Pinhal de São Bento - PR", state: "PR" },
    { value: "Pinhalzinho - SC", text: "Pinhalzinho - SC", state: "SC" },
    { value: "Pinhalzinho - SP", text: "Pinhalzinho - SP", state: "SP" },
    { value: "Pinhalão - PR", text: "Pinhalão - PR", state: "PR" },
    { value: "Pinheiral - RJ", text: "Pinheiral - RJ", state: "RJ" },
    { value: "Pinheirinho do Vale - RS", text: "Pinheirinho do Vale - RS", state: "RS" },
    { value: "Pinheiro - MA", text: "Pinheiro - MA", state: "MA" },
    { value: "Pinheiro Machado - RS", text: "Pinheiro Machado - RS", state: "RS" },
    { value: "Pinheiro Preto - SC", text: "Pinheiro Preto - SC", state: "SC" },
    { value: "Pinheiros - ES", text: "Pinheiros - ES", state: "ES" },
    { value: "Pinhão - PR", text: "Pinhão - PR", state: "PR" },
    { value: "Pinhão - SE", text: "Pinhão - SE", state: "SE" },
    { value: "Pintadas - BA", text: "Pintadas - BA", state: "BA" },
    { value: "Pintópolis - MG", text: "Pintópolis - MG", state: "MG" },
    { value: "Pio IX - PI", text: "Pio IX - PI", state: "PI" },
    { value: "Pio XII - MA", text: "Pio XII - MA", state: "MA" },
    { value: "Piquerobi - SP", text: "Piquerobi - SP", state: "SP" },
    { value: "Piquet Carneiro - CE", text: "Piquet Carneiro - CE", state: "CE" },
    { value: "Piquete - SP", text: "Piquete - SP", state: "SP" },
    { value: "Piracaia - SP", text: "Piracaia - SP", state: "SP" },
    { value: "Piracanjuba - GO", text: "Piracanjuba - GO", state: "GO" },
    { value: "Piracema - MG", text: "Piracema - MG", state: "MG" },
    { value: "Piracicaba - SP", text: "Piracicaba - SP", state: "SP" },
    { value: "Piracuruca - PI", text: "Piracuruca - PI", state: "PI" },
    { value: "Piraju - SP", text: "Piraju - SP", state: "SP" },
    { value: "Pirajuba - MG", text: "Pirajuba - MG", state: "MG" },
    { value: "Pirajuí - SP", text: "Pirajuí - SP", state: "SP" },
    { value: "Pirambu - SE", text: "Pirambu - SE", state: "SE" },
    { value: "Piranga - MG", text: "Piranga - MG", state: "MG" },
    { value: "Pirangi - SP", text: "Pirangi - SP", state: "SP" },
    { value: "Piranguinho - MG", text: "Piranguinho - MG", state: "MG" },
    { value: "Piranguçu - MG", text: "Piranguçu - MG", state: "MG" },
    { value: "Piranhas - AL", text: "Piranhas - AL", state: "AL" },
    { value: "Piranhas - GO", text: "Piranhas - GO", state: "GO" },
    { value: "Pirapemas - MA", text: "Pirapemas - MA", state: "MA" },
    { value: "Pirapetinga - MG", text: "Pirapetinga - MG", state: "MG" },
    { value: "Pirapora - MG", text: "Pirapora - MG", state: "MG" },
    { value: "Pirapora do Bom Jesus - SP", text: "Pirapora do Bom Jesus - SP", state: "SP" },
    { value: "Pirapozinho - SP", text: "Pirapozinho - SP", state: "SP" },
    { value: "Pirapó - RS", text: "Pirapó - RS", state: "RS" },
    { value: "Piraquara - PR", text: "Piraquara - PR", state: "PR" },
    { value: "Piraquê - TO", text: "Piraquê - TO", state: "TO" },
    { value: "Pirassununga - SP", text: "Pirassununga - SP", state: "SP" },
    { value: "Piratini - RS", text: "Piratini - RS", state: "RS" },
    { value: "Piratininga - SP", text: "Piratininga - SP", state: "SP" },
    { value: "Piratuba - SC", text: "Piratuba - SC", state: "SC" },
    { value: "Piraí - RJ", text: "Piraí - RJ", state: "RJ" },
    { value: "Piraí do Norte - BA", text: "Piraí do Norte - BA", state: "BA" },
    { value: "Piraí do Sul - PR", text: "Piraí do Sul - PR", state: "PR" },
    { value: "Piraúba - MG", text: "Piraúba - MG", state: "MG" },
    { value: "Pirenópolis - GO", text: "Pirenópolis - GO", state: "GO" },
    { value: "Pires Ferreira - CE", text: "Pires Ferreira - CE", state: "CE" },
    { value: "Pires do Rio - GO", text: "Pires do Rio - GO", state: "GO" },
    { value: "Piripiri - PI", text: "Piripiri - PI", state: "PI" },
    { value: "Piripá - BA", text: "Piripá - BA", state: "BA" },
    { value: "Piritiba - BA", text: "Piritiba - BA", state: "BA" },
    { value: "Pirpirituba - PB", text: "Pirpirituba - PB", state: "PB" },
    { value: "Pitanga - PR", text: "Pitanga - PR", state: "PR" },
    { value: "Pitangueiras - PR", text: "Pitangueiras - PR", state: "PR" },
    { value: "Pitangueiras - SP", text: "Pitangueiras - SP", state: "SP" },
    { value: "Pitangui - MG", text: "Pitangui - MG", state: "MG" },
    { value: "Pitimbu - PB", text: "Pitimbu - PB", state: "PB" },
    { value: "Pium - TO", text: "Pium - TO", state: "TO" },
    { value: "Piumhi - MG", text: "Piumhi - MG", state: "MG" },
    { value: "Piçarra - PA", text: "Piçarra - PA", state: "PA" },
    { value: "Piçarras - SC", text: "Piçarras - SC", state: "SC" },
    { value: "Piên - PR", text: "Piên - PR", state: "PR" },
    { value: "Piúma - ES", text: "Piúma - ES", state: "ES" },
    { value: "Placas - PA", text: "Placas - PA", state: "PA" },
    { value: "Planaltina - DF", text: "Planaltina - DF", state: "DF" },
    { value: "Planaltina - GO", text: "Planaltina - GO", state: "GO" },
    { value: "Planaltina do Paraná - PR", text: "Planaltina do Paraná - PR", state: "PR" },
    { value: "Planaltino - BA", text: "Planaltino - BA", state: "BA" },
    { value: "Planalto - BA", text: "Planalto - BA", state: "BA" },
    { value: "Planalto - PR", text: "Planalto - PR", state: "PR" },
    { value: "Planalto - RS", text: "Planalto - RS", state: "RS" },
    { value: "Planalto - SP", text: "Planalto - SP", state: "SP" },
    { value: "Planalto Alegre - SC", text: "Planalto Alegre - SC", state: "SC" },
    { value: "Planalto da Serra - MT", text: "Planalto da Serra - MT", state: "MT" },
    { value: "Plano Piloto - DF", text: "Plano Piloto - DF", state: "DF" },
    { value: "Planura - MG", text: "Planura - MG", state: "MG" },
    { value: "Platina - SP", text: "Platina - SP", state: "SP" },
    { value: "Plácido de Castro - AC", text: "Plácido de Castro - AC", state: "AC" },
    { value: "Pocinhos - PB", text: "Pocinhos - PB", state: "PB" },
    { value: "Poconé - MT", text: "Poconé - MT", state: "MT" },
    { value: "Pocrane - MG", text: "Pocrane - MG", state: "MG" },
    { value: "Pojuca - BA", text: "Pojuca - BA", state: "BA" },
    { value: "Poloni - SP", text: "Poloni - SP", state: "SP" },
    { value: "Pombal - PB", text: "Pombal - PB", state: "PB" },
    { value: "Pombos - PE", text: "Pombos - PE", state: "PE" },
    { value: "Pomerode - SC", text: "Pomerode - SC", state: "SC" },
    { value: "Pompéia - SP", text: "Pompéia - SP", state: "SP" },
    { value: "Pompéu - MG", text: "Pompéu - MG", state: "MG" },
    { value: "Pongaí - SP", text: "Pongaí - SP", state: "SP" },
    { value: "Ponta Grossa - PR", text: "Ponta Grossa - PR", state: "PR" },
    { value: "Ponta Porã - MS", text: "Ponta Porã - MS", state: "MS" },
    { value: "Ponta de Pedras - PA", text: "Ponta de Pedras - PA", state: "PA" },
    { value: "Pontal - SP", text: "Pontal - SP", state: "SP" },
    { value: "Pontal do Araguaia - MT", text: "Pontal do Araguaia - MT", state: "MT" },
    { value: "Pontal do Paraná - PR", text: "Pontal do Paraná - PR", state: "PR" },
    { value: "Pontalina - GO", text: "Pontalina - GO", state: "GO" },
    { value: "Pontalinda - SP", text: "Pontalinda - SP", state: "SP" },
    { value: "Ponte Alta - SC", text: "Ponte Alta - SC", state: "SC" },
    { value: "Ponte Alta do Bom Jesus - TO", text: "Ponte Alta do Bom Jesus - TO", state: "TO" },
    { value: "Ponte Alta do Norte - SC", text: "Ponte Alta do Norte - SC", state: "SC" },
    { value: "Ponte Alta do Tocantins - TO", text: "Ponte Alta do Tocantins - TO", state: "TO" },
    { value: "Ponte Branca - MT", text: "Ponte Branca - MT", state: "MT" },
    { value: "Ponte Nova - MG", text: "Ponte Nova - MG", state: "MG" },
    { value: "Ponte Preta - RS", text: "Ponte Preta - RS", state: "RS" },
    { value: "Ponte Serrada - SC", text: "Ponte Serrada - SC", state: "SC" },
    { value: "Pontes Gestal - SP", text: "Pontes Gestal - SP", state: "SP" },
    { value: "Pontes e Lacerda - MT", text: "Pontes e Lacerda - MT", state: "MT" },
    { value: "Ponto Belo - ES", text: "Ponto Belo - ES", state: "ES" },
    { value: "Ponto Chique - MG", text: "Ponto Chique - MG", state: "MG" },
    { value: "Ponto Novo - BA", text: "Ponto Novo - BA", state: "BA" },
    { value: "Ponto dos Volantes - MG", text: "Ponto dos Volantes - MG", state: "MG" },
    { value: "Pontão - RS", text: "Pontão - RS", state: "RS" },
    { value: "Populina - SP", text: "Populina - SP", state: "SP" },
    { value: "Poranga - CE", text: "Poranga - CE", state: "CE" },
    { value: "Porangaba - SP", text: "Porangaba - SP", state: "SP" },
    { value: "Porangatu - GO", text: "Porangatu - GO", state: "GO" },
    { value: "Porciúncula - RJ", text: "Porciúncula - RJ", state: "RJ" },
    { value: "Porecatu - PR", text: "Porecatu - PR", state: "PR" },
    { value: "Portalegre - RN", text: "Portalegre - RN", state: "RN" },
    { value: "Porteiras - CE", text: "Porteiras - CE", state: "CE" },
    { value: "Porteirinha - MG", text: "Porteirinha - MG", state: "MG" },
    { value: "Porteirão - GO", text: "Porteirão - GO", state: "GO" },
    { value: "Portel - PA", text: "Portel - PA", state: "PA" },
    { value: "Portelândia - GO", text: "Portelândia - GO", state: "GO" },
    { value: "Porto - PI", text: "Porto - PI", state: "PI" },
    { value: "Porto Acre - AC", text: "Porto Acre - AC", state: "AC" },
    { value: "Porto Alegre - RS", text: "Porto Alegre - RS", state: "RS" },
    { value: "Porto Alegre do Norte - MT", text: "Porto Alegre do Norte - MT", state: "MT" },
    { value: "Porto Alegre do Piauí - PI", text: "Porto Alegre do Piauí - PI", state: "PI" },
    { value: "Porto Alegre do Tocantins - TO", text: "Porto Alegre do Tocantins - TO", state: "TO" },
    { value: "Porto Amazonas - PR", text: "Porto Amazonas - PR", state: "PR" },
    { value: "Porto Barreiro - PR", text: "Porto Barreiro - PR", state: "PR" },
    { value: "Porto Belo - SC", text: "Porto Belo - SC", state: "SC" },
    { value: "Porto Calvo - AL", text: "Porto Calvo - AL", state: "AL" },
    { value: "Porto Esperidião - MT", text: "Porto Esperidião - MT", state: "MT" },
    { value: "Porto Estrela - MT", text: "Porto Estrela - MT", state: "MT" },
    { value: "Porto Feliz - SP", text: "Porto Feliz - SP", state: "SP" },
    { value: "Porto Ferreira - SP", text: "Porto Ferreira - SP", state: "SP" },
    { value: "Porto Firme - MG", text: "Porto Firme - MG", state: "MG" },
    { value: "Porto Franco - MA", text: "Porto Franco - MA", state: "MA" },
    { value: "Porto Grande - AP", text: "Porto Grande - AP", state: "AP" },
    { value: "Porto Lucena - RS", text: "Porto Lucena - RS", state: "RS" },
    { value: "Porto Mauá - RS", text: "Porto Mauá - RS", state: "RS" },
    { value: "Porto Murtinho - MS", text: "Porto Murtinho - MS", state: "MS" },
    { value: "Porto Nacional - TO", text: "Porto Nacional - TO", state: "TO" },
    { value: "Porto Real - RJ", text: "Porto Real - RJ", state: "RJ" },
    { value: "Porto Real do Colégio - AL", text: "Porto Real do Colégio - AL", state: "AL" },
    { value: "Porto Rico - PR", text: "Porto Rico - PR", state: "PR" },
    { value: "Porto Rico do Maranhão - MA", text: "Porto Rico do Maranhão - MA", state: "MA" },
    { value: "Porto Seguro - BA", text: "Porto Seguro - BA", state: "BA" },
    { value: "Porto União - SC", text: "Porto União - SC", state: "SC" },
    { value: "Porto Velho - RO", text: "Porto Velho - RO", state: "RO" },
    { value: "Porto Vera Cruz - RS", text: "Porto Vera Cruz - RS", state: "RS" },
    { value: "Porto Vitória - PR", text: "Porto Vitória - PR", state: "PR" },
    { value: "Porto Walter - AC", text: "Porto Walter - AC", state: "AC" },
    { value: "Porto Xavier - RS", text: "Porto Xavier - RS", state: "RS" },
    { value: "Porto da Folha - SE", text: "Porto da Folha - SE", state: "SE" },
    { value: "Porto de Moz - PA", text: "Porto de Moz - PA", state: "PA" },
    { value: "Porto de Pedras - AL", text: "Porto de Pedras - AL", state: "AL" },
    { value: "Porto do Mangue - RN", text: "Porto do Mangue - RN", state: "RN" },
    { value: "Porto dos Gaúchos - MT", text: "Porto dos Gaúchos - MT", state: "MT" },
    { value: "Portão - RS", text: "Portão - RS", state: "RS" },
    { value: "Posse - GO", text: "Posse - GO", state: "GO" },
    { value: "Potengi - CE", text: "Potengi - CE", state: "CE" },
    { value: "Potim - SP", text: "Potim - SP", state: "SP" },
    { value: "Potiraguá - BA", text: "Potiraguá - BA", state: "BA" },
    { value: "Potirendaba - SP", text: "Potirendaba - SP", state: "SP" },
    { value: "Potiretama - CE", text: "Potiretama - CE", state: "CE" },
    { value: "Poté - MG", text: "Poté - MG", state: "MG" },
    { value: "Pouso Alegre - MG", text: "Pouso Alegre - MG", state: "MG" },
    { value: "Pouso Alto - MG", text: "Pouso Alto - MG", state: "MG" },
    { value: "Pouso Novo - RS", text: "Pouso Novo - RS", state: "RS" },
    { value: "Pouso Redondo - SC", text: "Pouso Redondo - SC", state: "SC" },
    { value: "Poxoréo - MT", text: "Poxoréo - MT", state: "MT" },
    { value: "Poá - SP", text: "Poá - SP", state: "SP" },
    { value: "Poço Branco - RN", text: "Poço Branco - RN", state: "RN" },
    { value: "Poço Dantas - PB", text: "Poço Dantas - PB", state: "PB" },
    { value: "Poço Fundo - MG", text: "Poço Fundo - MG", state: "MG" },
    { value: "Poço Redondo - SE", text: "Poço Redondo - SE", state: "SE" },
    { value: "Poço Verde - SE", text: "Poço Verde - SE", state: "SE" },
    { value: "Poço das Antas - RS", text: "Poço das Antas - RS", state: "RS" },
    { value: "Poço das Trincheiras - AL", text: "Poço das Trincheiras - AL", state: "AL" },
    { value: "Poço de José de Moura - PB", text: "Poço de José de Moura - PB", state: "PB" },
    { value: "Poços de Caldas - MG", text: "Poços de Caldas - MG", state: "MG" },
    { value: "Poção - PE", text: "Poção - PE", state: "PE" },
    { value: "Poção de Pedras - MA", text: "Poção de Pedras - MA", state: "MA" },
    { value: "Poções - BA", text: "Poções - BA", state: "BA" },
    { value: "Pracinha - SP", text: "Pracinha - SP", state: "SP" },
    { value: "Pracuúba - AP", text: "Pracuúba - AP", state: "AP" },
    { value: "Prado - BA", text: "Prado - BA", state: "BA" },
    { value: "Prado Ferreira - PR", text: "Prado Ferreira - PR", state: "PR" },
    { value: "Prados - MG", text: "Prados - MG", state: "MG" },
    { value: "Pradópolis - SP", text: "Pradópolis - SP", state: "SP" },
    { value: "Praia Grande - SC", text: "Praia Grande - SC", state: "SC" },
    { value: "Praia Grande - SP", text: "Praia Grande - SP", state: "SP" },
    { value: "Praia Norte - TO", text: "Praia Norte - TO", state: "TO" },
    { value: "Prainha - PA", text: "Prainha - PA", state: "PA" },
    { value: "Pranchita - PR", text: "Pranchita - PR", state: "PR" },
    { value: "Prata - MG", text: "Prata - MG", state: "MG" },
    { value: "Prata - PB", text: "Prata - PB", state: "PB" },
    { value: "Prata do Piauí - PI", text: "Prata do Piauí - PI", state: "PI" },
    { value: "Pratinha - MG", text: "Pratinha - MG", state: "MG" },
    { value: "Pratápolis - MG", text: "Pratápolis - MG", state: "MG" },
    { value: "Pratânia - SP", text: "Pratânia - SP", state: "SP" },
    { value: "Presidente Alves - SP", text: "Presidente Alves - SP", state: "SP" },
    { value: "Presidente Bernardes - MG", text: "Presidente Bernardes - MG", state: "MG" },
    { value: "Presidente Bernardes - SP", text: "Presidente Bernardes - SP", state: "SP" },
    { value: "Presidente Castelo Branco - PR", text: "Presidente Castelo Branco - PR", state: "PR" },
    { value: "Presidente Castelo Branco - SC", text: "Presidente Castelo Branco - SC", state: "SC" },
    { value: "Presidente Dutra - BA", text: "Presidente Dutra - BA", state: "BA" },
    { value: "Presidente Dutra - MA", text: "Presidente Dutra - MA", state: "MA" },
    { value: "Presidente Epitácio - SP", text: "Presidente Epitácio - SP", state: "SP" },
    { value: "Presidente Figueiredo - AM", text: "Presidente Figueiredo - AM", state: "AM" },
    { value: "Presidente Getúlio - SC", text: "Presidente Getúlio - SC", state: "SC" },
    { value: "Presidente Juscelino - MA", text: "Presidente Juscelino - MA", state: "MA" },
    { value: "Presidente Juscelino - MG", text: "Presidente Juscelino - MG", state: "MG" },
    { value: "Presidente Juscelino - RN", text: "Presidente Juscelino - RN", state: "RN" },
    { value: "Presidente Jânio Quadros - BA", text: "Presidente Jânio Quadros - BA", state: "BA" },
    { value: "Presidente Kennedy - ES", text: "Presidente Kennedy - ES", state: "ES" },
    { value: "Presidente Kennedy - TO", text: "Presidente Kennedy - TO", state: "TO" },
    { value: "Presidente Kubitschek - MG", text: "Presidente Kubitschek - MG", state: "MG" },
    { value: "Presidente Lucena - RS", text: "Presidente Lucena - RS", state: "RS" },
    { value: "Presidente Médici - MA", text: "Presidente Médici - MA", state: "MA" },
    { value: "Presidente Médici - RO", text: "Presidente Médici - RO", state: "RO" },
    { value: "Presidente Nereu - SC", text: "Presidente Nereu - SC", state: "SC" },
    { value: "Presidente Olegário - MG", text: "Presidente Olegário - MG", state: "MG" },
    { value: "Presidente Prudente - SP", text: "Presidente Prudente - SP", state: "SP" },
    { value: "Presidente Sarney - MA", text: "Presidente Sarney - MA", state: "MA" },
    { value: "Presidente Tancredo Neves - BA", text: "Presidente Tancredo Neves - BA", state: "BA" },
    { value: "Presidente Vargas - MA", text: "Presidente Vargas - MA", state: "MA" },
    { value: "Presidente Venceslau - SP", text: "Presidente Venceslau - SP", state: "SP" },
    { value: "Primavera - PA", text: "Primavera - PA", state: "PA" },
    { value: "Primavera - PE", text: "Primavera - PE", state: "PE" },
    { value: "Primavera de Rondônia - RO", text: "Primavera de Rondônia - RO", state: "RO" },
    { value: "Primavera do Leste - MT", text: "Primavera do Leste - MT", state: "MT" },
    { value: "Primeira Cruz - MA", text: "Primeira Cruz - MA", state: "MA" },
    { value: "Primeiro de Maio - PR", text: "Primeiro de Maio - PR", state: "PR" },
    { value: "Princesa - SC", text: "Princesa - SC", state: "SC" },
    { value: "Princesa Isabel - PB", text: "Princesa Isabel - PB", state: "PB" },
    { value: "Professor Jamil - GO", text: "Professor Jamil - GO", state: "GO" },
    { value: "Progresso - RS", text: "Progresso - RS", state: "RS" },
    { value: "Promissão - SP", text: "Promissão - SP", state: "SP" },
    { value: "Propriá - SE", text: "Propriá - SE", state: "SE" },
    { value: "Protásio Alves - RS", text: "Protásio Alves - RS", state: "RS" },
    { value: "Prudente de Morais - MG", text: "Prudente de Morais - MG", state: "MG" },
    { value: "Prudentópolis - PR", text: "Prudentópolis - PR", state: "PR" },
    { value: "Pugmil - TO", text: "Pugmil - TO", state: "TO" },
    { value: "Pureza - RN", text: "Pureza - RN", state: "RN" },
    { value: "Putinga - RS", text: "Putinga - RS", state: "RS" },
    { value: "Puxinanã - PB", text: "Puxinanã - PB", state: "PB" },
    { value: "Pântano Grande - RS", text: "Pântano Grande - RS", state: "RS" },
    { value: "Pão de Açúcar - AL", text: "Pão de Açúcar - AL", state: "AL" },
    { value: "Pé de Serra - BA", text: "Pé de Serra - BA", state: "BA" },
    { value: "Pérola - PR", text: "Pérola - PR", state: "PR" },
    { value: "Pérola d'Oeste - PR", text: "Pérola d'Oeste - PR", state: "PR" },
    { value: "Pôr do Sol - DF", text: "Pôr do Sol - DF", state: "DF" },
    { value: "Quadra - SP", text: "Quadra - SP", state: "SP" },
    { value: "Quaraí - RS", text: "Quaraí - RS", state: "RS" },
    { value: "Quartel Geral - MG", text: "Quartel Geral - MG", state: "MG" },
    { value: "Quarto Centenário - PR", text: "Quarto Centenário - PR", state: "PR" },
    { value: "Quatiguá - PR", text: "Quatiguá - PR", state: "PR" },
    { value: "Quatipuru - PA", text: "Quatipuru - PA", state: "PA" },
    { value: "Quatis - RJ", text: "Quatis - RJ", state: "RJ" },
    { value: "Quatro Barras - PR", text: "Quatro Barras - PR", state: "PR" },
    { value: "Quatro Irmãos - RS", text: "Quatro Irmãos - RS", state: "RS" },
    { value: "Quatro Pontes - PR", text: "Quatro Pontes - PR", state: "PR" },
    { value: "Quatá - SP", text: "Quatá - SP", state: "SP" },
    { value: "Quebrangulo - AL", text: "Quebrangulo - AL", state: "AL" },
    { value: "Quedas do Iguaçu - PR", text: "Quedas do Iguaçu - PR", state: "PR" },
    { value: "Queimada Nova - PI", text: "Queimada Nova - PI", state: "PI" },
    { value: "Queimadas - BA", text: "Queimadas - BA", state: "BA" },
    { value: "Queimadas - PB", text: "Queimadas - PB", state: "PB" },
    { value: "Queimados - RJ", text: "Queimados - RJ", state: "RJ" },
    { value: "Queiroz - SP", text: "Queiroz - SP", state: "SP" },
    { value: "Queluz - SP", text: "Queluz - SP", state: "SP" },
    { value: "Queluzito - MG", text: "Queluzito - MG", state: "MG" },
    { value: "Querência - MT", text: "Querência - MT", state: "MT" },
    { value: "Querência do Norte - PR", text: "Querência do Norte - PR", state: "PR" },
    { value: "Quevedos - RS", text: "Quevedos - RS", state: "RS" },
    { value: "Quijingue - BA", text: "Quijingue - BA", state: "BA" },
    { value: "Quilombo - SC", text: "Quilombo - SC", state: "SC" },
    { value: "Quinta do Sol - PR", text: "Quinta do Sol - PR", state: "PR" },
    { value: "Quintana - SP", text: "Quintana - SP", state: "SP" },
    { value: "Quinze de Novembro - RS", text: "Quinze de Novembro - RS", state: "RS" },
    { value: "Quipapá - PE", text: "Quipapá - PE", state: "PE" },
    { value: "Quirinópolis - GO", text: "Quirinópolis - GO", state: "GO" },
    { value: "Quissamã - RJ", text: "Quissamã - RJ", state: "RJ" },
    { value: "Quitandinha - PR", text: "Quitandinha - PR", state: "PR" },
    { value: "Quiterianópolis - CE", text: "Quiterianópolis - CE", state: "CE" },
    { value: "Quixaba - PE", text: "Quixaba - PE", state: "PE" },
    { value: "Quixabeira - BA", text: "Quixabeira - BA", state: "BA" },
    { value: "Quixabá - PB", text: "Quixabá - PB", state: "PB" },
    { value: "Quixadá - CE", text: "Quixadá - CE", state: "CE" },
    { value: "Quixelô - CE", text: "Quixelô - CE", state: "CE" },
    { value: "Quixeramobim - CE", text: "Quixeramobim - CE", state: "CE" },
    { value: "Quixeré - CE", text: "Quixeré - CE", state: "CE" },
    { value: "Rafael Fernandes - RN", text: "Rafael Fernandes - RN", state: "RN" },
    { value: "Rafael Godeiro - RN", text: "Rafael Godeiro - RN", state: "RN" },
    { value: "Rafael Jambeiro - BA", text: "Rafael Jambeiro - BA", state: "BA" },
    { value: "Rafard - SP", text: "Rafard - SP", state: "SP" },
    { value: "Ramilândia - PR", text: "Ramilândia - PR", state: "PR" },
    { value: "Rancharia - SP", text: "Rancharia - SP", state: "SP" },
    { value: "Rancho Alegre - PR", text: "Rancho Alegre - PR", state: "PR" },
    { value: "Rancho Alegre d'Oeste - PR", text: "Rancho Alegre d'Oeste - PR", state: "PR" },
    { value: "Rancho Queimado - SC", text: "Rancho Queimado - SC", state: "SC" },
    { value: "Raposa - MA", text: "Raposa - MA", state: "MA" },
    { value: "Raposos - MG", text: "Raposos - MG", state: "MG" },
    { value: "Raul Soares - MG", text: "Raul Soares - MG", state: "MG" },
    { value: "Realeza - PR", text: "Realeza - PR", state: "PR" },
    { value: "Rebouças - PR", text: "Rebouças - PR", state: "PR" },
    { value: "Recanto das Emas - DF", text: "Recanto das Emas - DF", state: "DF" },
    { value: "Recife - PE", text: "Recife - PE", state: "PE" },
    { value: "Recreio - MG", text: "Recreio - MG", state: "MG" },
    { value: "Recursolândia - TO", text: "Recursolândia - TO", state: "TO" },
    { value: "Redentora - RS", text: "Redentora - RS", state: "RS" },
    { value: "Redenção - CE", text: "Redenção - CE", state: "CE" },
    { value: "Redenção - PA", text: "Redenção - PA", state: "PA" },
    { value: "Redenção da Serra - SP", text: "Redenção da Serra - SP", state: "SP" },
    { value: "Redenção do Gurguéia - PI", text: "Redenção do Gurguéia - PI", state: "PI" },
    { value: "Reduto - MG", text: "Reduto - MG", state: "MG" },
    { value: "Regeneração - PI", text: "Regeneração - PI", state: "PI" },
    { value: "Regente Feijó - SP", text: "Regente Feijó - SP", state: "SP" },
    { value: "Reginópolis - SP", text: "Reginópolis - SP", state: "SP" },
    { value: "Registro - SP", text: "Registro - SP", state: "SP" },
    { value: "Relvado - RS", text: "Relvado - RS", state: "RS" },
    { value: "Remanso - BA", text: "Remanso - BA", state: "BA" },
    { value: "Remígio - PB", text: "Remígio - PB", state: "PB" },
    { value: "Renascença - PR", text: "Renascença - PR", state: "PR" },
    { value: "Reriutaba - CE", text: "Reriutaba - CE", state: "CE" },
    { value: "Resende - RJ", text: "Resende - RJ", state: "RJ" },
    { value: "Resende Costa - MG", text: "Resende Costa - MG", state: "MG" },
    { value: "Reserva - PR", text: "Reserva - PR", state: "PR" },
    { value: "Reserva do Cabaçal - MT", text: "Reserva do Cabaçal - MT", state: "MT" },
    { value: "Reserva do Iguaçu - PR", text: "Reserva do Iguaçu - PR", state: "PR" },
    { value: "Resplendor - MG", text: "Resplendor - MG", state: "MG" },
    { value: "Ressaquinha - MG", text: "Ressaquinha - MG", state: "MG" },
    { value: "Restinga - SP", text: "Restinga - SP", state: "SP" },
    { value: "Restinga Seca - RS", text: "Restinga Seca - RS", state: "RS" },
    { value: "Retirolândia - BA", text: "Retirolândia - BA", state: "BA" },
    { value: "Riachinho - MG", text: "Riachinho - MG", state: "MG" },
    { value: "Riachinho - TO", text: "Riachinho - TO", state: "TO" },
    { value: "Riacho Frio - PI", text: "Riacho Frio - PI", state: "PI" },
    { value: "Riacho Fundo - DF", text: "Riacho Fundo - DF", state: "DF" },
    { value: "Riacho Fundo II - DF", text: "Riacho Fundo II - DF", state: "DF" },
    { value: "Riacho da Cruz - RN", text: "Riacho da Cruz - RN", state: "RN" },
    { value: "Riacho das Almas - PE", text: "Riacho das Almas - PE", state: "PE" },
    { value: "Riacho de Santana - BA", text: "Riacho de Santana - BA", state: "BA" },
    { value: "Riacho de Santana - RN", text: "Riacho de Santana - RN", state: "RN" },
    { value: "Riacho de Santo Antônio - PB", text: "Riacho de Santo Antônio - PB", state: "PB" },
    { value: "Riacho dos Cavalos - PB", text: "Riacho dos Cavalos - PB", state: "PB" },
    { value: "Riacho dos Machados - MG", text: "Riacho dos Machados - MG", state: "MG" },
    { value: "Riachuelo - RN", text: "Riachuelo - RN", state: "RN" },
    { value: "Riachuelo - SE", text: "Riachuelo - SE", state: "SE" },
    { value: "Riachão - MA", text: "Riachão - MA", state: "MA" },
    { value: "Riachão - PB", text: "Riachão - PB", state: "PB" },
    { value: "Riachão das Neves - BA", text: "Riachão das Neves - BA", state: "BA" },
    { value: "Riachão do Bacamarte - PB", text: "Riachão do Bacamarte - PB", state: "PB" },
    { value: "Riachão do Dantas - SE", text: "Riachão do Dantas - SE", state: "SE" },
    { value: "Riachão do Jacuípe - BA", text: "Riachão do Jacuípe - BA", state: "BA" },
    { value: "Riachão do Poço - PB", text: "Riachão do Poço - PB", state: "PB" },
    { value: "Rialma - GO", text: "Rialma - GO", state: "GO" },
    { value: "Rianápolis - GO", text: "Rianápolis - GO", state: "GO" },
    { value: "Ribamar Fiquene - MA", text: "Ribamar Fiquene - MA", state: "MA" },
    { value: "Ribas do Rio Pardo - MS", text: "Ribas do Rio Pardo - MS", state: "MS" },
    { value: "Ribeira - SP", text: "Ribeira - SP", state: "SP" },
    { value: "Ribeira do Amparo - BA", text: "Ribeira do Amparo - BA", state: "BA" },
    { value: "Ribeira do Piauí - PI", text: "Ribeira do Piauí - PI", state: "PI" },
    { value: "Ribeira do Pombal - BA", text: "Ribeira do Pombal - BA", state: "BA" },
    { value: "Ribeiro Gonçalves - PI", text: "Ribeiro Gonçalves - PI", state: "PI" },
    { value: "Ribeirão - PE", text: "Ribeirão - PE", state: "PE" },
    { value: "Ribeirão Bonito - SP", text: "Ribeirão Bonito - SP", state: "SP" },
    { value: "Ribeirão Branco - SP", text: "Ribeirão Branco - SP", state: "SP" },
    { value: "Ribeirão Cascalheira - MT", text: "Ribeirão Cascalheira - MT", state: "MT" },
    { value: "Ribeirão Claro - PR", text: "Ribeirão Claro - PR", state: "PR" },
    { value: "Ribeirão Corrente - SP", text: "Ribeirão Corrente - SP", state: "SP" },
    { value: "Ribeirão Grande - SP", text: "Ribeirão Grande - SP", state: "SP" },
    { value: "Ribeirão Pires - SP", text: "Ribeirão Pires - SP", state: "SP" },
    { value: "Ribeirão Preto - SP", text: "Ribeirão Preto - SP", state: "SP" },
    { value: "Ribeirão Vermelho - MG", text: "Ribeirão Vermelho - MG", state: "MG" },
    { value: "Ribeirão das Neves - MG", text: "Ribeirão das Neves - MG", state: "MG" },
    { value: "Ribeirão do Largo - BA", text: "Ribeirão do Largo - BA", state: "BA" },
    { value: "Ribeirão do Pinhal - PR", text: "Ribeirão do Pinhal - PR", state: "PR" },
    { value: "Ribeirão do Sul - SP", text: "Ribeirão do Sul - SP", state: "SP" },
    { value: "Ribeirão dos Índios - SP", text: "Ribeirão dos Índios - SP", state: "SP" },
    { value: "Ribeirãozinho - MT", text: "Ribeirãozinho - MT", state: "MT" },
    { value: "Ribeirópolis - SE", text: "Ribeirópolis - SE", state: "SE" },
    { value: "Rifaina - SP", text: "Rifaina - SP", state: "SP" },
    { value: "Rincão - SP", text: "Rincão - SP", state: "SP" },
    { value: "Rinópolis - SP", text: "Rinópolis - SP", state: "SP" },
    { value: "Rio Acima - MG", text: "Rio Acima - MG", state: "MG" },
    { value: "Rio Azul - PR", text: "Rio Azul - PR", state: "PR" },
    { value: "Rio Bananal - ES", text: "Rio Bananal - ES", state: "ES" },
    { value: "Rio Bom - PR", text: "Rio Bom - PR", state: "PR" },
    { value: "Rio Bonito - RJ", text: "Rio Bonito - RJ", state: "RJ" },
    { value: "Rio Bonito do Iguaçu - PR", text: "Rio Bonito do Iguaçu - PR", state: "PR" },
    { value: "Rio Branco - AC", text: "Rio Branco - AC", state: "AC" },
    { value: "Rio Branco - MT", text: "Rio Branco - MT", state: "MT" },
    { value: "Rio Branco do Ivaí - PR", text: "Rio Branco do Ivaí - PR", state: "PR" },
    { value: "Rio Branco do Sul - PR", text: "Rio Branco do Sul - PR", state: "PR" },
    { value: "Rio Brilhante - MS", text: "Rio Brilhante - MS", state: "MS" },
    { value: "Rio Casca - MG", text: "Rio Casca - MG", state: "MG" },
    { value: "Rio Claro - RJ", text: "Rio Claro - RJ", state: "RJ" },
    { value: "Rio Claro - SP", text: "Rio Claro - SP", state: "SP" },
    { value: "Rio Crespo - RO", text: "Rio Crespo - RO", state: "RO" },
    { value: "Rio Doce - MG", text: "Rio Doce - MG", state: "MG" },
    { value: "Rio Espera - MG", text: "Rio Espera - MG", state: "MG" },
    { value: "Rio Formoso - PE", text: "Rio Formoso - PE", state: "PE" },
    { value: "Rio Fortuna - SC", text: "Rio Fortuna - SC", state: "SC" },
    { value: "Rio Grande - RS", text: "Rio Grande - RS", state: "RS" },
    { value: "Rio Grande da Serra - SP", text: "Rio Grande da Serra - SP", state: "SP" },
    { value: "Rio Grande do Piauí - PI", text: "Rio Grande do Piauí - PI", state: "PI" },
    { value: "Rio Largo - AL", text: "Rio Largo - AL", state: "AL" },
    { value: "Rio Manso - MG", text: "Rio Manso - MG", state: "MG" },
    { value: "Rio Maria - PA", text: "Rio Maria - PA", state: "PA" },
    { value: "Rio Negrinho - SC", text: "Rio Negrinho - SC", state: "SC" },
    { value: "Rio Negro - MS", text: "Rio Negro - MS", state: "MS" },
    { value: "Rio Negro - PR", text: "Rio Negro - PR", state: "PR" },
    { value: "Rio Novo - MG", text: "Rio Novo - MG", state: "MG" },
    { value: "Rio Novo do Sul - ES", text: "Rio Novo do Sul - ES", state: "ES" },
    { value: "Rio Paranaíba - MG", text: "Rio Paranaíba - MG", state: "MG" },
    { value: "Rio Pardo - RS", text: "Rio Pardo - RS", state: "RS" },
    { value: "Rio Pardo de Minas - MG", text: "Rio Pardo de Minas - MG", state: "MG" },
    { value: "Rio Piracicaba - MG", text: "Rio Piracicaba - MG", state: "MG" },
    { value: "Rio Pomba - MG", text: "Rio Pomba - MG", state: "MG" },
    { value: "Rio Preto - MG", text: "Rio Preto - MG", state: "MG" },
    { value: "Rio Preto da Eva - AM", text: "Rio Preto da Eva - AM", state: "AM" },
    { value: "Rio Quente - GO", text: "Rio Quente - GO", state: "GO" },
    { value: "Rio Real - BA", text: "Rio Real - BA", state: "BA" },
    { value: "Rio Rufino - SC", text: "Rio Rufino - SC", state: "SC" },
    { value: "Rio Sono - TO", text: "Rio Sono - TO", state: "TO" },
    { value: "Rio Tinto - PB", text: "Rio Tinto - PB", state: "PB" },
    { value: "Rio Verde - GO", text: "Rio Verde - GO", state: "GO" },
    { value: "Rio Verde de Mato Grosso - MS", text: "Rio Verde de Mato Grosso - MS", state: "MS" },
    { value: "Rio Vermelho - MG", text: "Rio Vermelho - MG", state: "MG" },
    { value: "Rio da Conceição - TO", text: "Rio da Conceição - TO", state: "TO" },
    { value: "Rio das Antas - SC", text: "Rio das Antas - SC", state: "SC" },
    { value: "Rio das Flores - RJ", text: "Rio das Flores - RJ", state: "RJ" },
    { value: "Rio das Ostras - RJ", text: "Rio das Ostras - RJ", state: "RJ" },
    { value: "Rio das Pedras - SP", text: "Rio das Pedras - SP", state: "SP" },
    { value: "Rio de Contas - BA", text: "Rio de Contas - BA", state: "BA" },
    { value: "Rio de Janeiro - RJ", text: "Rio de Janeiro - RJ", state: "RJ" },
    { value: "Rio do Antônio - BA", text: "Rio do Antônio - BA", state: "BA" },
    { value: "Rio do Campo - SC", text: "Rio do Campo - SC", state: "SC" },
    { value: "Rio do Fogo - RN", text: "Rio do Fogo - RN", state: "RN" },
    { value: "Rio do Oeste - SC", text: "Rio do Oeste - SC", state: "SC" },
    { value: "Rio do Pires - BA", text: "Rio do Pires - BA", state: "BA" },
    { value: "Rio do Prado - MG", text: "Rio do Prado - MG", state: "MG" },
    { value: "Rio do Sul - SC", text: "Rio do Sul - SC", state: "SC" },
    { value: "Rio dos Bois - TO", text: "Rio dos Bois - TO", state: "TO" },
    { value: "Rio dos Cedros - SC", text: "Rio dos Cedros - SC", state: "SC" },
    { value: "Rio dos Índios - RS", text: "Rio dos Índios - RS", state: "RS" },
    { value: "Riolândia - SP", text: "Riolândia - SP", state: "SP" },
    { value: "Riozinho - RS", text: "Riozinho - RS", state: "RS" },
    { value: "Riqueza - SC", text: "Riqueza - SC", state: "SC" },
    { value: "Ritápolis - MG", text: "Ritápolis - MG", state: "MG" },
    { value: "Riversul - SP", text: "Riversul - SP", state: "SP" },
    { value: "Roca Sales - RS", text: "Roca Sales - RS", state: "RS" },
    { value: "Rochedo - MS", text: "Rochedo - MS", state: "MS" },
    { value: "Rochedo de Minas - MG", text: "Rochedo de Minas - MG", state: "MG" },
    { value: "Rodeio - SC", text: "Rodeio - SC", state: "SC" },
    { value: "Rodeio Bonito - RS", text: "Rodeio Bonito - RS", state: "RS" },
    { value: "Rodeiro - MG", text: "Rodeiro - MG", state: "MG" },
    { value: "Rodelas - BA", text: "Rodelas - BA", state: "BA" },
    { value: "Rodolfo Fernandes - RN", text: "Rodolfo Fernandes - RN", state: "RN" },
    { value: "Rodrigues Alves - AC", text: "Rodrigues Alves - AC", state: "AC" },
    { value: "Rolador - RS", text: "Rolador - RS", state: "RS" },
    { value: "Rolante - RS", text: "Rolante - RS", state: "RS" },
    { value: "Rolim de Moura - RO", text: "Rolim de Moura - RO", state: "RO" },
    { value: "Rolândia - PR", text: "Rolândia - PR", state: "PR" },
    { value: "Romaria - MG", text: "Romaria - MG", state: "MG" },
    { value: "Romelândia - SC", text: "Romelândia - SC", state: "SC" },
    { value: "Roncador - PR", text: "Roncador - PR", state: "PR" },
    { value: "Ronda Alta - RS", text: "Ronda Alta - RS", state: "RS" },
    { value: "Rondinha - RS", text: "Rondinha - RS", state: "RS" },
    { value: "Rondolândia - MT", text: "Rondolândia - MT", state: "MT" },
    { value: "Rondon - PR", text: "Rondon - PR", state: "PR" },
    { value: "Rondon do Pará - PA", text: "Rondon do Pará - PA", state: "PA" },
    { value: "Rondonópolis - MT", text: "Rondonópolis - MT", state: "MT" },
    { value: "Roque Gonzales - RS", text: "Roque Gonzales - RS", state: "RS" },
    { value: "Rorainópolis - RR", text: "Rorainópolis - RR", state: "RR" },
    { value: "Rosana - SP", text: "Rosana - SP", state: "SP" },
    { value: "Roseira - SP", text: "Roseira - SP", state: "SP" },
    { value: "Rosário - MA", text: "Rosário - MA", state: "MA" },
    { value: "Rosário Oeste - MT", text: "Rosário Oeste - MT", state: "MT" },
    { value: "Rosário da Limeira - MG", text: "Rosário da Limeira - MG", state: "MG" },
    { value: "Rosário do Catete - SE", text: "Rosário do Catete - SE", state: "SE" },
    { value: "Rosário do Ivaí - PR", text: "Rosário do Ivaí - PR", state: "PR" },
    { value: "Rosário do Sul - RS", text: "Rosário do Sul - RS", state: "RS" },
    { value: "Roteiro - AL", text: "Roteiro - AL", state: "AL" },
    { value: "Rubelita - MG", text: "Rubelita - MG", state: "MG" },
    { value: "Rubiataba - GO", text: "Rubiataba - GO", state: "GO" },
    { value: "Rubim - MG", text: "Rubim - MG", state: "MG" },
    { value: "Rubinéia - SP", text: "Rubinéia - SP", state: "SP" },
    { value: "Rubiácea - SP", text: "Rubiácea - SP", state: "SP" },
    { value: "Rurópolis - PA", text: "Rurópolis - PA", state: "PA" },
    { value: "Russas - CE", text: "Russas - CE", state: "CE" },
    { value: "Ruy Barbosa - BA", text: "Ruy Barbosa - BA", state: "BA" },
    { value: "Ruy Barbosa - RN", text: "Ruy Barbosa - RN", state: "RN" },
    { value: "SIA - DF", text: "SIA - DF", state: "DF" },
    { value: "Sabará - MG", text: "Sabará - MG", state: "MG" },
    { value: "Sabino - SP", text: "Sabino - SP", state: "SP" },
    { value: "Sabinópolis - MG", text: "Sabinópolis - MG", state: "MG" },
    { value: "Saboeiro - CE", text: "Saboeiro - CE", state: "CE" },
    { value: "Sabáudia - PR", text: "Sabáudia - PR", state: "PR" },
    { value: "Sacramento - MG", text: "Sacramento - MG", state: "MG" },
    { value: "Sagrada Família - RS", text: "Sagrada Família - RS", state: "RS" },
    { value: "Sagres - SP", text: "Sagres - SP", state: "SP" },
    { value: "Sairé - PE", text: "Sairé - PE", state: "PE" },
    { value: "Saldanha Marinho - RS", text: "Saldanha Marinho - RS", state: "RS" },
    { value: "Sales - SP", text: "Sales - SP", state: "SP" },
    { value: "Sales Oliveira - SP", text: "Sales Oliveira - SP", state: "SP" },
    { value: "Salesópolis - SP", text: "Salesópolis - SP", state: "SP" },
    { value: "Salete - SC", text: "Salete - SC", state: "SC" },
    { value: "Salgadinho - PB", text: "Salgadinho - PB", state: "PB" },
    { value: "Salgadinho - PE", text: "Salgadinho - PE", state: "PE" },
    { value: "Salgado - SE", text: "Salgado - SE", state: "SE" },
    { value: "Salgado Filho - PR", text: "Salgado Filho - PR", state: "PR" },
    { value: "Salgado de São Félix - PB", text: "Salgado de São Félix - PB", state: "PB" },
    { value: "Salgueiro - PE", text: "Salgueiro - PE", state: "PE" },
    { value: "Salinas - MG", text: "Salinas - MG", state: "MG" },
    { value: "Salinas da Margarida - BA", text: "Salinas da Margarida - BA", state: "BA" },
    { value: "Salinópolis - PA", text: "Salinópolis - PA", state: "PA" },
    { value: "Salitre - CE", text: "Salitre - CE", state: "CE" },
    { value: "Salmourão - SP", text: "Salmourão - SP", state: "SP" },
    { value: "Saloá - PE", text: "Saloá - PE", state: "PE" },
    { value: "Saltinho - SC", text: "Saltinho - SC", state: "SC" },
    { value: "Saltinho - SP", text: "Saltinho - SP", state: "SP" },
    { value: "Salto - SP", text: "Salto - SP", state: "SP" },
    { value: "Salto Grande - SP", text: "Salto Grande - SP", state: "SP" },
    { value: "Salto Veloso - SC", text: "Salto Veloso - SC", state: "SC" },
    { value: "Salto da Divisa - MG", text: "Salto da Divisa - MG", state: "MG" },
    { value: "Salto de Pirapora - SP", text: "Salto de Pirapora - SP", state: "SP" },
    { value: "Salto do Céu - MT", text: "Salto do Céu - MT", state: "MT" },
    { value: "Salto do Itararé - PR", text: "Salto do Itararé - PR", state: "PR" },
    { value: "Salto do Jacuí - RS", text: "Salto do Jacuí - RS", state: "RS" },
    { value: "Salto do Lontra - PR", text: "Salto do Lontra - PR", state: "PR" },
    { value: "Salvador - BA", text: "Salvador - BA", state: "BA" },
    { value: "Salvador das Missões - RS", text: "Salvador das Missões - RS", state: "RS" },
    { value: "Salvador do Sul - RS", text: "Salvador do Sul - RS", state: "RS" },
    { value: "Salvaterra - PA", text: "Salvaterra - PA", state: "PA" },
    { value: "Samambaia - DF", text: "Samambaia - DF", state: "DF" },
    { value: "Sambaíba - MA", text: "Sambaíba - MA", state: "MA" },
    { value: "Sampaio - TO", text: "Sampaio - TO", state: "TO" },
    { value: "Sananduva - RS", text: "Sananduva - RS", state: "RS" },
    { value: "Sanclerlândia - GO", text: "Sanclerlândia - GO", state: "GO" },
    { value: "Sandolândia - TO", text: "Sandolândia - TO", state: "TO" },
    { value: "Sandovalina - SP", text: "Sandovalina - SP", state: "SP" },
    { value: "Sangão - SC", text: "Sangão - SC", state: "SC" },
    { value: "Sanharó - PE", text: "Sanharó - PE", state: "PE" },
    { value: "Santa Adélia - SP", text: "Santa Adélia - SP", state: "SP" },
    { value: "Santa Albertina - SP", text: "Santa Albertina - SP", state: "SP" },
    { value: "Santa Amélia - PR", text: "Santa Amélia - PR", state: "PR" },
    { value: "Santa Branca - SP", text: "Santa Branca - SP", state: "SP" },
    { value: "Santa Brígida - BA", text: "Santa Brígida - BA", state: "BA" },
    { value: "Santa Bárbara - BA", text: "Santa Bárbara - BA", state: "BA" },
    { value: "Santa Bárbara - MG", text: "Santa Bárbara - MG", state: "MG" },
    { value: "Santa Bárbara d'Oeste - SP", text: "Santa Bárbara d'Oeste - SP", state: "SP" },
    { value: "Santa Bárbara de Goiás - GO", text: "Santa Bárbara de Goiás - GO", state: "GO" },
    { value: "Santa Bárbara do Leste - MG", text: "Santa Bárbara do Leste - MG", state: "MG" },
    { value: "Santa Bárbara do Monte Verde - MG", text: "Santa Bárbara do Monte Verde - MG", state: "MG" },
    { value: "Santa Bárbara do Pará - PA", text: "Santa Bárbara do Pará - PA", state: "PA" },
    { value: "Santa Bárbara do Sul - RS", text: "Santa Bárbara do Sul - RS", state: "RS" },
    { value: "Santa Bárbara do Tugúrio - MG", text: "Santa Bárbara do Tugúrio - MG", state: "MG" },
    { value: "Santa Carmem - MT", text: "Santa Carmem - MT", state: "MT" },
    { value: "Santa Cecília - PB", text: "Santa Cecília - PB", state: "PB" },
    { value: "Santa Cecília - SC", text: "Santa Cecília - SC", state: "SC" },
    { value: "Santa Cecília do Pavão - PR", text: "Santa Cecília do Pavão - PR", state: "PR" },
    { value: "Santa Cecília do Sul - RS", text: "Santa Cecília do Sul - RS", state: "RS" },
    { value: "Santa Clara d'Oeste - SP", text: "Santa Clara d'Oeste - SP", state: "SP" },
    { value: "Santa Clara do Sul - RS", text: "Santa Clara do Sul - RS", state: "RS" },
    { value: "Santa Cruz - PB", text: "Santa Cruz - PB", state: "PB" },
    { value: "Santa Cruz - PE", text: "Santa Cruz - PE", state: "PE" },
    { value: "Santa Cruz - RN", text: "Santa Cruz - RN", state: "RN" },
    { value: "Santa Cruz Cabrália - BA", text: "Santa Cruz Cabrália - BA", state: "BA" },
    { value: "Santa Cruz Monte Castelo - PR", text: "Santa Cruz Monte Castelo - PR", state: "PR" },
    { value: "Santa Cruz da Baixa Verde - PE", text: "Santa Cruz da Baixa Verde - PE", state: "PE" },
    { value: "Santa Cruz da Conceição - SP", text: "Santa Cruz da Conceição - SP", state: "SP" },
    { value: "Santa Cruz da Esperança - SP", text: "Santa Cruz da Esperança - SP", state: "SP" },
    { value: "Santa Cruz da Vitória - BA", text: "Santa Cruz da Vitória - BA", state: "BA" },
    { value: "Santa Cruz das Palmeiras - SP", text: "Santa Cruz das Palmeiras - SP", state: "SP" },
    { value: "Santa Cruz de Goiás - GO", text: "Santa Cruz de Goiás - GO", state: "GO" },
    { value: "Santa Cruz de Minas - MG", text: "Santa Cruz de Minas - MG", state: "MG" },
    { value: "Santa Cruz de Salinas - MG", text: "Santa Cruz de Salinas - MG", state: "MG" },
    { value: "Santa Cruz do Arari - PA", text: "Santa Cruz do Arari - PA", state: "PA" },
    { value: "Santa Cruz do Capibaribe - PE", text: "Santa Cruz do Capibaribe - PE", state: "PE" },
    { value: "Santa Cruz do Escalvado - MG", text: "Santa Cruz do Escalvado - MG", state: "MG" },
    { value: "Santa Cruz do Piauí - PI", text: "Santa Cruz do Piauí - PI", state: "PI" },
    { value: "Santa Cruz do Rio Pardo - SP", text: "Santa Cruz do Rio Pardo - SP", state: "SP" },
    { value: "Santa Cruz do Sul - RS", text: "Santa Cruz do Sul - RS", state: "RS" },
    { value: "Santa Cruz do Xingu - MT", text: "Santa Cruz do Xingu - MT", state: "MT" },
    { value: "Santa Cruz dos Milagres - PI", text: "Santa Cruz dos Milagres - PI", state: "PI" },
    { value: "Santa Efigênia de Minas - MG", text: "Santa Efigênia de Minas - MG", state: "MG" },
    { value: "Santa Ernestina - SP", text: "Santa Ernestina - SP", state: "SP" },
    { value: "Santa Filomena - PE", text: "Santa Filomena - PE", state: "PE" },
    { value: "Santa Filomena - PI", text: "Santa Filomena - PI", state: "PI" },
    { value: "Santa Filomena do Maranhão - MA", text: "Santa Filomena do Maranhão - MA", state: "MA" },
    { value: "Santa Fé - PR", text: "Santa Fé - PR", state: "PR" },
    { value: "Santa Fé de Goiás - GO", text: "Santa Fé de Goiás - GO", state: "GO" },
    { value: "Santa Fé de Minas - MG", text: "Santa Fé de Minas - MG", state: "MG" },
    { value: "Santa Fé do Araguaia - TO", text: "Santa Fé do Araguaia - TO", state: "TO" },
    { value: "Santa Fé do Sul - SP", text: "Santa Fé do Sul - SP", state: "SP" },
    { value: "Santa Gertrudes - SP", text: "Santa Gertrudes - SP", state: "SP" },
    { value: "Santa Helena - MA", text: "Santa Helena - MA", state: "MA" },
    { value: "Santa Helena - PB", text: "Santa Helena - PB", state: "PB" },
    { value: "Santa Helena - PR", text: "Santa Helena - PR", state: "PR" },
    { value: "Santa Helena - SC", text: "Santa Helena - SC", state: "SC" },
    { value: "Santa Helena de Goiás - GO", text: "Santa Helena de Goiás - GO", state: "GO" },
    { value: "Santa Helena de Minas - MG", text: "Santa Helena de Minas - MG", state: "MG" },
    { value: "Santa Inês - BA", text: "Santa Inês - BA", state: "BA" },
    { value: "Santa Inês - MA", text: "Santa Inês - MA", state: "MA" },
    { value: "Santa Inês - PB", text: "Santa Inês - PB", state: "PB" },
    { value: "Santa Inês - PR", text: "Santa Inês - PR", state: "PR" },
    { value: "Santa Isabel - GO", text: "Santa Isabel - GO", state: "GO" },
    { value: "Santa Isabel - SP", text: "Santa Isabel - SP", state: "SP" },
    { value: "Santa Isabel do Ivaí - PR", text: "Santa Isabel do Ivaí - PR", state: "PR" },
    { value: "Santa Isabel do Pará - PA", text: "Santa Isabel do Pará - PA", state: "PA" },
    { value: "Santa Isabel do Rio Negro - AM", text: "Santa Isabel do Rio Negro - AM", state: "AM" },
    { value: "Santa Izabel do Oeste - PR", text: "Santa Izabel do Oeste - PR", state: "PR" },
    { value: "Santa Juliana - MG", text: "Santa Juliana - MG", state: "MG" },
    { value: "Santa Leopoldina - ES", text: "Santa Leopoldina - ES", state: "ES" },
    { value: "Santa Luz - PI", text: "Santa Luz - PI", state: "PI" },
    { value: "Santa Luzia - BA", text: "Santa Luzia - BA", state: "BA" },
    { value: "Santa Luzia - MA", text: "Santa Luzia - MA", state: "MA" },
    { value: "Santa Luzia - MG", text: "Santa Luzia - MG", state: "MG" },
    { value: "Santa Luzia - PB", text: "Santa Luzia - PB", state: "PB" },
    { value: "Santa Luzia d'Oeste - RO", text: "Santa Luzia d'Oeste - RO", state: "RO" },
    { value: "Santa Luzia do Itanhy - SE", text: "Santa Luzia do Itanhy - SE", state: "SE" },
    { value: "Santa Luzia do Norte - AL", text: "Santa Luzia do Norte - AL", state: "AL" },
    { value: "Santa Luzia do Paruá - MA", text: "Santa Luzia do Paruá - MA", state: "MA" },
    { value: "Santa Luzia do Pará - PA", text: "Santa Luzia do Pará - PA", state: "PA" },
    { value: "Santa Lúcia - PR", text: "Santa Lúcia - PR", state: "PR" },
    { value: "Santa Lúcia - SP", text: "Santa Lúcia - SP", state: "SP" },
    { value: "Santa Margarida - MG", text: "Santa Margarida - MG", state: "MG" },
    { value: "Santa Margarida do Sul - RS", text: "Santa Margarida do Sul - RS", state: "RS" },
    { value: "Santa Maria - DF", text: "Santa Maria - DF", state: "DF" },
    { value: "Santa Maria - RN", text: "Santa Maria - RN", state: "RN" },
    { value: "Santa Maria - RS", text: "Santa Maria - RS", state: "RS" },
    { value: "Santa Maria Madalena - RJ", text: "Santa Maria Madalena - RJ", state: "RJ" },
    { value: "Santa Maria da Boa Vista - PE", text: "Santa Maria da Boa Vista - PE", state: "PE" },
    { value: "Santa Maria da Serra - SP", text: "Santa Maria da Serra - SP", state: "SP" },
    { value: "Santa Maria da Vitória - BA", text: "Santa Maria da Vitória - BA", state: "BA" },
    { value: "Santa Maria das Barreiras - PA", text: "Santa Maria das Barreiras - PA", state: "PA" },
    { value: "Santa Maria de Itabira - MG", text: "Santa Maria de Itabira - MG", state: "MG" },
    { value: "Santa Maria de Jetibá - ES", text: "Santa Maria de Jetibá - ES", state: "ES" },
    { value: "Santa Maria do Cambucá - PE", text: "Santa Maria do Cambucá - PE", state: "PE" },
    { value: "Santa Maria do Herval - RS", text: "Santa Maria do Herval - RS", state: "RS" },
    { value: "Santa Maria do Oeste - PR", text: "Santa Maria do Oeste - PR", state: "PR" },
    { value: "Santa Maria do Pará - PA", text: "Santa Maria do Pará - PA", state: "PA" },
    { value: "Santa Maria do Salto - MG", text: "Santa Maria do Salto - MG", state: "MG" },
    { value: "Santa Maria do Suaçuí - MG", text: "Santa Maria do Suaçuí - MG", state: "MG" },
    { value: "Santa Maria do Tocantins - TO", text: "Santa Maria do Tocantins - TO", state: "TO" },
    { value: "Santa Mariana - PR", text: "Santa Mariana - PR", state: "PR" },
    { value: "Santa Mercedes - SP", text: "Santa Mercedes - SP", state: "SP" },
    { value: "Santa Mônica - PR", text: "Santa Mônica - PR", state: "PR" },
    { value: "Santa Quitéria - CE", text: "Santa Quitéria - CE", state: "CE" },
    { value: "Santa Quitéria do Maranhão - MA", text: "Santa Quitéria do Maranhão - MA", state: "MA" },
    { value: "Santa Rita - MA", text: "Santa Rita - MA", state: "MA" },
    { value: "Santa Rita - PB", text: "Santa Rita - PB", state: "PB" },
    { value: "Santa Rita d'Oeste - SP", text: "Santa Rita d'Oeste - SP", state: "SP" },
    { value: "Santa Rita de Caldas - MG", text: "Santa Rita de Caldas - MG", state: "MG" },
    { value: "Santa Rita de Cássia - BA", text: "Santa Rita de Cássia - BA", state: "BA" },
    { value: "Santa Rita de Ibitipoca - MG", text: "Santa Rita de Ibitipoca - MG", state: "MG" },
    { value: "Santa Rita de Jacutinga - MG", text: "Santa Rita de Jacutinga - MG", state: "MG" },
    { value: "Santa Rita de Minas - MG", text: "Santa Rita de Minas - MG", state: "MG" },
    { value: "Santa Rita do Araguaia - GO", text: "Santa Rita do Araguaia - GO", state: "GO" },
    { value: "Santa Rita do Itueto - MG", text: "Santa Rita do Itueto - MG", state: "MG" },
    { value: "Santa Rita do Novo Destino - GO", text: "Santa Rita do Novo Destino - GO", state: "GO" },
    { value: "Santa Rita do Pardo - MS", text: "Santa Rita do Pardo - MS", state: "MS" },
    { value: "Santa Rita do Passa Quatro - SP", text: "Santa Rita do Passa Quatro - SP", state: "SP" },
    { value: "Santa Rita do Sapucaí - MG", text: "Santa Rita do Sapucaí - MG", state: "MG" },
    { value: "Santa Rita do Tocantins - TO", text: "Santa Rita do Tocantins - TO", state: "TO" },
    { value: "Santa Rita do Trivelato - MT", text: "Santa Rita do Trivelato - MT", state: "MT" },
    { value: "Santa Rosa - RS", text: "Santa Rosa - RS", state: "RS" },
    { value: "Santa Rosa da Serra - MG", text: "Santa Rosa da Serra - MG", state: "MG" },
    { value: "Santa Rosa de Goiás - GO", text: "Santa Rosa de Goiás - GO", state: "GO" },
    { value: "Santa Rosa de Lima - SC", text: "Santa Rosa de Lima - SC", state: "SC" },
    { value: "Santa Rosa de Lima - SE", text: "Santa Rosa de Lima - SE", state: "SE" },
    { value: "Santa Rosa de Viterbo - SP", text: "Santa Rosa de Viterbo - SP", state: "SP" },
    { value: "Santa Rosa do Piauí - PI", text: "Santa Rosa do Piauí - PI", state: "PI" },
    { value: "Santa Rosa do Purus - AC", text: "Santa Rosa do Purus - AC", state: "AC" },
    { value: "Santa Rosa do Sul - SC", text: "Santa Rosa do Sul - SC", state: "SC" },
    { value: "Santa Rosa do Tocantins - TO", text: "Santa Rosa do Tocantins - TO", state: "TO" },
    { value: "Santa Salete - SP", text: "Santa Salete - SP", state: "SP" },
    { value: "Santa Teresa - ES", text: "Santa Teresa - ES", state: "ES" },
    { value: "Santa Teresinha - BA", text: "Santa Teresinha - BA", state: "BA" },
    { value: "Santa Teresinha - PB", text: "Santa Teresinha - PB", state: "PB" },
    { value: "Santa Tereza - RS", text: "Santa Tereza - RS", state: "RS" },
    { value: "Santa Tereza de Goiás - GO", text: "Santa Tereza de Goiás - GO", state: "GO" },
    { value: "Santa Tereza do Oeste - PR", text: "Santa Tereza do Oeste - PR", state: "PR" },
    { value: "Santa Tereza do Tocantins - TO", text: "Santa Tereza do Tocantins - TO", state: "TO" },
    { value: "Santa Terezinha - MT", text: "Santa Terezinha - MT", state: "MT" },
    { value: "Santa Terezinha - PE", text: "Santa Terezinha - PE", state: "PE" },
    { value: "Santa Terezinha - SC", text: "Santa Terezinha - SC", state: "SC" },
    { value: "Santa Terezinha de Goiás - GO", text: "Santa Terezinha de Goiás - GO", state: "GO" },
    { value: "Santa Terezinha de Itaipu - PR", text: "Santa Terezinha de Itaipu - PR", state: "PR" },
    { value: "Santa Terezinha do Progresso - SC", text: "Santa Terezinha do Progresso - SC", state: "SC" },
    { value: "Santa Terezinha do Tocantins - TO", text: "Santa Terezinha do Tocantins - TO", state: "TO" },
    { value: "Santa Vitória - MG", text: "Santa Vitória - MG", state: "MG" },
    { value: "Santa Vitória do Palmar - RS", text: "Santa Vitória do Palmar - RS", state: "RS" },
    { value: "Santaluz - BA", text: "Santaluz - BA", state: "BA" },
    { value: "Santana - AP", text: "Santana - AP", state: "AP" },
    { value: "Santana - BA", text: "Santana - BA", state: "BA" },
    { value: "Santana da Boa Vista - RS", text: "Santana da Boa Vista - RS", state: "RS" },
    { value: "Santana da Ponte Pensa - SP", text: "Santana da Ponte Pensa - SP", state: "SP" },
    { value: "Santana da Vargem - MG", text: "Santana da Vargem - MG", state: "MG" },
    { value: "Santana de Cataguases - MG", text: "Santana de Cataguases - MG", state: "MG" },
    { value: "Santana de Mangueira - PB", text: "Santana de Mangueira - PB", state: "PB" },
    { value: "Santana de Parnaíba - SP", text: "Santana de Parnaíba - SP", state: "SP" },
    { value: "Santana de Pirapama - MG", text: "Santana de Pirapama - MG", state: "MG" },
    { value: "Santana do Acaraú - CE", text: "Santana do Acaraú - CE", state: "CE" },
    { value: "Santana do Araguaia - PA", text: "Santana do Araguaia - PA", state: "PA" },
    { value: "Santana do Cariri - CE", text: "Santana do Cariri - CE", state: "CE" },
    { value: "Santana do Deserto - MG", text: "Santana do Deserto - MG", state: "MG" },
    { value: "Santana do Garambéu - MG", text: "Santana do Garambéu - MG", state: "MG" },
    { value: "Santana do Ipanema - AL", text: "Santana do Ipanema - AL", state: "AL" },
    { value: "Santana do Itararé - PR", text: "Santana do Itararé - PR", state: "PR" },
    { value: "Santana do Jacaré - MG", text: "Santana do Jacaré - MG", state: "MG" },
    { value: "Santana do Livramento - RS", text: "Santana do Livramento - RS", state: "RS" },
    { value: "Santana do Manhuaçu - MG", text: "Santana do Manhuaçu - MG", state: "MG" },
    { value: "Santana do Maranhão - MA", text: "Santana do Maranhão - MA", state: "MA" },
    { value: "Santana do Matos - RN", text: "Santana do Matos - RN", state: "RN" },
    { value: "Santana do Mundaú - AL", text: "Santana do Mundaú - AL", state: "AL" },
    { value: "Santana do Paraíso - MG", text: "Santana do Paraíso - MG", state: "MG" },
    { value: "Santana do Piauí - PI", text: "Santana do Piauí - PI", state: "PI" },
    { value: "Santana do Riacho - MG", text: "Santana do Riacho - MG", state: "MG" },
    { value: "Santana do Seridó - RN", text: "Santana do Seridó - RN", state: "RN" },
    { value: "Santana do São Francisco - SE", text: "Santana do São Francisco - SE", state: "SE" },
    { value: "Santana dos Garrotes - PB", text: "Santana dos Garrotes - PB", state: "PB" },
    { value: "Santana dos Montes - MG", text: "Santana dos Montes - MG", state: "MG" },
    { value: "Santanópolis - BA", text: "Santanópolis - BA", state: "BA" },
    { value: "Santarém - PA", text: "Santarém - PA", state: "PA" },
    { value: "Santarém - PB", text: "Santarém - PB", state: "PB" },
    { value: "Santarém Novo - PA", text: "Santarém Novo - PA", state: "PA" },
    { value: "Santiago - RS", text: "Santiago - RS", state: "RS" },
    { value: "Santiago do Sul - SC", text: "Santiago do Sul - SC", state: "SC" },
    { value: "Santo Afonso - MT", text: "Santo Afonso - MT", state: "MT" },
    { value: "Santo Amaro - BA", text: "Santo Amaro - BA", state: "BA" },
    { value: "Santo Amaro da Imperatriz - SC", text: "Santo Amaro da Imperatriz - SC", state: "SC" },
    { value: "Santo Amaro das Brotas - SE", text: "Santo Amaro das Brotas - SE", state: "SE" },
    { value: "Santo Amaro do Maranhão - MA", text: "Santo Amaro do Maranhão - MA", state: "MA" },
    { value: "Santo Anastácio - SP", text: "Santo Anastácio - SP", state: "SP" },
    { value: "Santo André - PB", text: "Santo André - PB", state: "PB" },
    { value: "Santo André - SP", text: "Santo André - SP", state: "SP" },
    { value: "Santo Antônio - RN", text: "Santo Antônio - RN", state: "RN" },
    { value: "Santo Antônio da Alegria - SP", text: "Santo Antônio da Alegria - SP", state: "SP" },
    { value: "Santo Antônio da Barra - GO", text: "Santo Antônio da Barra - GO", state: "GO" },
    { value: "Santo Antônio da Patrulha - RS", text: "Santo Antônio da Patrulha - RS", state: "RS" },
    { value: "Santo Antônio da Platina - PR", text: "Santo Antônio da Platina - PR", state: "PR" },
    { value: "Santo Antônio das Missões - RS", text: "Santo Antônio das Missões - RS", state: "RS" },
    { value: "Santo Antônio de Goiás - GO", text: "Santo Antônio de Goiás - GO", state: "GO" },
    { value: "Santo Antônio de Jesus - BA", text: "Santo Antônio de Jesus - BA", state: "BA" },
    { value: "Santo Antônio de Lisboa - PI", text: "Santo Antônio de Lisboa - PI", state: "PI" },
    { value: "Santo Antônio de Posse - SP", text: "Santo Antônio de Posse - SP", state: "SP" },
    { value: "Santo Antônio de Pádua - RJ", text: "Santo Antônio de Pádua - RJ", state: "RJ" },
    { value: "Santo Antônio do Amparo - MG", text: "Santo Antônio do Amparo - MG", state: "MG" },
    { value: "Santo Antônio do Aracanguá - SP", text: "Santo Antônio do Aracanguá - SP", state: "SP" },
    { value: "Santo Antônio do Aventureiro - MG", text: "Santo Antônio do Aventureiro - MG", state: "MG" },
    { value: "Santo Antônio do Caiuá - PR", text: "Santo Antônio do Caiuá - PR", state: "PR" },
    { value: "Santo Antônio do Descoberto - GO", text: "Santo Antônio do Descoberto - GO", state: "GO" },
    { value: "Santo Antônio do Grama - MG", text: "Santo Antônio do Grama - MG", state: "MG" },
    { value: "Santo Antônio do Itambé - MG", text: "Santo Antônio do Itambé - MG", state: "MG" },
    { value: "Santo Antônio do Içá - AM", text: "Santo Antônio do Içá - AM", state: "AM" },
    { value: "Santo Antônio do Jacinto - MG", text: "Santo Antônio do Jacinto - MG", state: "MG" },
    { value: "Santo Antônio do Jardim - SP", text: "Santo Antônio do Jardim - SP", state: "SP" },
    { value: "Santo Antônio do Leste - MT", text: "Santo Antônio do Leste - MT", state: "MT" },
    { value: "Santo Antônio do Leverger - MT", text: "Santo Antônio do Leverger - MT", state: "MT" },
    { value: "Santo Antônio do Monte - MG", text: "Santo Antônio do Monte - MG", state: "MG" },
    { value: "Santo Antônio do Palma - RS", text: "Santo Antônio do Palma - RS", state: "RS" },
    { value: "Santo Antônio do Paraíso - PR", text: "Santo Antônio do Paraíso - PR", state: "PR" },
    { value: "Santo Antônio do Pinhal - SP", text: "Santo Antônio do Pinhal - SP", state: "SP" },
    { value: "Santo Antônio do Planalto - RS", text: "Santo Antônio do Planalto - RS", state: "RS" },
    { value: "Santo Antônio do Retiro - MG", text: "Santo Antônio do Retiro - MG", state: "MG" },
    { value: "Santo Antônio do Rio Abaixo - MG", text: "Santo Antônio do Rio Abaixo - MG", state: "MG" },
    { value: "Santo Antônio do Sudoeste - PR", text: "Santo Antônio do Sudoeste - PR", state: "PR" },
    { value: "Santo Antônio do Tauá - PA", text: "Santo Antônio do Tauá - PA", state: "PA" },
    { value: "Santo Antônio dos Lopes - MA", text: "Santo Antônio dos Lopes - MA", state: "MA" },
    { value: "Santo Antônio dos Milagres - PI", text: "Santo Antônio dos Milagres - PI", state: "PI" },
    { value: "Santo Augusto - RS", text: "Santo Augusto - RS", state: "RS" },
    { value: "Santo Cristo - RS", text: "Santo Cristo - RS", state: "RS" },
    { value: "Santo Estêvão - BA", text: "Santo Estêvão - BA", state: "BA" },
    { value: "Santo Expedito - SP", text: "Santo Expedito - SP", state: "SP" },
    { value: "Santo Expedito do Sul - RS", text: "Santo Expedito do Sul - RS", state: "RS" },
    { value: "Santo Hipólito - MG", text: "Santo Hipólito - MG", state: "MG" },
    { value: "Santo Inácio - PR", text: "Santo Inácio - PR", state: "PR" },
    { value: "Santo Inácio do Piauí - PI", text: "Santo Inácio do Piauí - PI", state: "PI" },
    { value: "Santo Ângelo - RS", text: "Santo Ângelo - RS", state: "RS" },
    { value: "Santos - SP", text: "Santos - SP", state: "SP" },
    { value: "Santos Dumont - MG", text: "Santos Dumont - MG", state: "MG" },
    { value: "Santópolis do Aguapeí - SP", text: "Santópolis do Aguapeí - SP", state: "SP" },
    { value: "Sapeaçu - BA", text: "Sapeaçu - BA", state: "BA" },
    { value: "Sapezal - MT", text: "Sapezal - MT", state: "MT" },
    { value: "Sapiranga - RS", text: "Sapiranga - RS", state: "RS" },
    { value: "Sapopema - PR", text: "Sapopema - PR", state: "PR" },
    { value: "Sapucaia - PA", text: "Sapucaia - PA", state: "PA" },
    { value: "Sapucaia - RJ", text: "Sapucaia - RJ", state: "RJ" },
    { value: "Sapucaia do Sul - RS", text: "Sapucaia do Sul - RS", state: "RS" },
    { value: "Sapucaí-Mirim - MG", text: "Sapucaí-Mirim - MG", state: "MG" },
    { value: "Sapé - PB", text: "Sapé - PB", state: "PB" },
    { value: "Saquarema - RJ", text: "Saquarema - RJ", state: "RJ" },
    { value: "Sarandi - PR", text: "Sarandi - PR", state: "PR" },
    { value: "Sarandi - RS", text: "Sarandi - RS", state: "RS" },
    { value: "Sarapuí - SP", text: "Sarapuí - SP", state: "SP" },
    { value: "Sardoá - MG", text: "Sardoá - MG", state: "MG" },
    { value: "Sarutaiá - SP", text: "Sarutaiá - SP", state: "SP" },
    { value: "Sarzedo - MG", text: "Sarzedo - MG", state: "MG" },
    { value: "Satuba - AL", text: "Satuba - AL", state: "AL" },
    { value: "Satubinha - MA", text: "Satubinha - MA", state: "MA" },
    { value: "Saubara - BA", text: "Saubara - BA", state: "BA" },
    { value: "Saudade do Iguaçu - PR", text: "Saudade do Iguaçu - PR", state: "PR" },
    { value: "Saudades - SC", text: "Saudades - SC", state: "SC" },
    { value: "Saúde - BA", text: "Saúde - BA", state: "BA" },
    { value: "Schroeder - SC", text: "Schroeder - SC", state: "SC" },
    { value: "Seabra - BA", text: "Seabra - BA", state: "BA" },
    { value: "Seara - SC", text: "Seara - SC", state: "SC" },
    { value: "Sebastianópolis do Sul - SP", text: "Sebastianópolis do Sul - SP", state: "SP" },
    { value: "Sebastião Barros - PI", text: "Sebastião Barros - PI", state: "PI" },
    { value: "Sebastião Laranjeiras - BA", text: "Sebastião Laranjeiras - BA", state: "BA" },
    { value: "Sebastião Leal - PI", text: "Sebastião Leal - PI", state: "PI" },
    { value: "Seberi - RS", text: "Seberi - RS", state: "RS" },
    { value: "Sede Nova - RS", text: "Sede Nova - RS", state: "RS" },
    { value: "Segredo - RS", text: "Segredo - RS", state: "RS" },
    { value: "Selbach - RS", text: "Selbach - RS", state: "RS" },
    { value: "Selvíria - MS", text: "Selvíria - MS", state: "MS" },
    { value: "Sem-Peixe - MG", text: "Sem-Peixe - MG", state: "MG" },
    { value: "Sena Madureira - AC", text: "Sena Madureira - AC", state: "AC" },
    { value: "Senador Alexandre Costa - MA", text: "Senador Alexandre Costa - MA", state: "MA" },
    { value: "Senador Amaral - MG", text: "Senador Amaral - MG", state: "MG" },
    { value: "Senador Canedo - GO", text: "Senador Canedo - GO", state: "GO" },
    { value: "Senador Cortes - MG", text: "Senador Cortes - MG", state: "MG" },
    { value: "Senador Elói de Souza - RN", text: "Senador Elói de Souza - RN", state: "RN" },
    { value: "Senador Firmino - MG", text: "Senador Firmino - MG", state: "MG" },
    { value: "Senador Georgino Avelino - RN", text: "Senador Georgino Avelino - RN", state: "RN" },
    { value: "Senador Guiomard - AC", text: "Senador Guiomard - AC", state: "AC" },
    { value: "Senador José Bento - MG", text: "Senador José Bento - MG", state: "MG" },
    { value: "Senador José Porfírio - PA", text: "Senador José Porfírio - PA", state: "PA" },
    { value: "Senador La Rocque - MA", text: "Senador La Rocque - MA", state: "MA" },
    { value: "Senador Modestino Gonçalves - MG", text: "Senador Modestino Gonçalves - MG", state: "MG" },
    { value: "Senador Pompeu - CE", text: "Senador Pompeu - CE", state: "CE" },
    { value: "Senador Rui Palmeira - AL", text: "Senador Rui Palmeira - AL", state: "AL" },
    { value: "Senador Salgado Filho - RS", text: "Senador Salgado Filho - RS", state: "RS" },
    { value: "Senador Sá - CE", text: "Senador Sá - CE", state: "CE" },
    { value: "Sengés - PR", text: "Sengés - PR", state: "PR" },
    { value: "Senhor do Bonfim - BA", text: "Senhor do Bonfim - BA", state: "BA" },
    { value: "Senhora de Oliveira - MG", text: "Senhora de Oliveira - MG", state: "MG" },
    { value: "Senhora do Porto - MG", text: "Senhora do Porto - MG", state: "MG" },
    { value: "Senhora dos Remédios - MG", text: "Senhora dos Remédios - MG", state: "MG" },
    { value: "Sentinela do Sul - RS", text: "Sentinela do Sul - RS", state: "RS" },
    { value: "Sento Sé - BA", text: "Sento Sé - BA", state: "BA" },
    { value: "Serafina Corrêa - RS", text: "Serafina Corrêa - RS", state: "RS" },
    { value: "Sericita - MG", text: "Sericita - MG", state: "MG" },
    { value: "Seridó - PB", text: "Seridó - PB", state: "PB" },
    { value: "Seringueiras - RO", text: "Seringueiras - RO", state: "RO" },
    { value: "Seritinga - MG", text: "Seritinga - MG", state: "MG" },
    { value: "Seropédica - RJ", text: "Seropédica - RJ", state: "RJ" },
    { value: "Serra - ES", text: "Serra - ES", state: "ES" },
    { value: "Serra Alta - SC", text: "Serra Alta - SC", state: "SC" },
    { value: "Serra Azul - SP", text: "Serra Azul - SP", state: "SP" },
    { value: "Serra Azul de Minas - MG", text: "Serra Azul de Minas - MG", state: "MG" },
    { value: "Serra Branca - PB", text: "Serra Branca - PB", state: "PB" },
    { value: "Serra Dourada - BA", text: "Serra Dourada - BA", state: "BA" },
    { value: "Serra Grande - PB", text: "Serra Grande - PB", state: "PB" },
    { value: "Serra Negra - SP", text: "Serra Negra - SP", state: "SP" },
    { value: "Serra Negra do Norte - RN", text: "Serra Negra do Norte - RN", state: "RN" },
    { value: "Serra Nova Dourada - MT", text: "Serra Nova Dourada - MT", state: "MT" },
    { value: "Serra Preta - BA", text: "Serra Preta - BA", state: "BA" },
    { value: "Serra Redonda - PB", text: "Serra Redonda - PB", state: "PB" },
    { value: "Serra Talhada - PE", text: "Serra Talhada - PE", state: "PE" },
    { value: "Serra da Raiz - PB", text: "Serra da Raiz - PB", state: "PB" },
    { value: "Serra da Saudade - MG", text: "Serra da Saudade - MG", state: "MG" },
    { value: "Serra de São Bento - RN", text: "Serra de São Bento - RN", state: "RN" },
    { value: "Serra do Mel - RN", text: "Serra do Mel - RN", state: "RN" },
    { value: "Serra do Navio - AP", text: "Serra do Navio - AP", state: "AP" },
    { value: "Serra do Ramalho - BA", text: "Serra do Ramalho - BA", state: "BA" },
    { value: "Serra do Salitre - MG", text: "Serra do Salitre - MG", state: "MG" },
    { value: "Serra dos Aimorés - MG", text: "Serra dos Aimorés - MG", state: "MG" },
    { value: "Serrana - SP", text: "Serrana - SP", state: "SP" },
    { value: "Serrania - MG", text: "Serrania - MG", state: "MG" },
    { value: "Serrano do Maranhão - MA", text: "Serrano do Maranhão - MA", state: "MA" },
    { value: "Serranos - MG", text: "Serranos - MG", state: "MG" },
    { value: "Serranópolis - GO", text: "Serranópolis - GO", state: "GO" },
    { value: "Serranópolis de Minas - MG", text: "Serranópolis de Minas - MG", state: "MG" },
    { value: "Serranópolis do Iguaçu - PR", text: "Serranópolis do Iguaçu - PR", state: "PR" },
    { value: "Serraria - PB", text: "Serraria - PB", state: "PB" },
    { value: "Serrinha - BA", text: "Serrinha - BA", state: "BA" },
    { value: "Serrinha - RN", text: "Serrinha - RN", state: "RN" },
    { value: "Serrinha dos Pintos - RN", text: "Serrinha dos Pintos - RN", state: "RN" },
    { value: "Serrita - PE", text: "Serrita - PE", state: "PE" },
    { value: "Serro - MG", text: "Serro - MG", state: "MG" },
    { value: "Serrolândia - BA", text: "Serrolândia - BA", state: "BA" },
    { value: "Sertaneja - PR", text: "Sertaneja - PR", state: "PR" },
    { value: "Sertanópolis - PR", text: "Sertanópolis - PR", state: "PR" },
    { value: "Sertânia - PE", text: "Sertânia - PE", state: "PE" },
    { value: "Sertão - RS", text: "Sertão - RS", state: "RS" },
    { value: "Sertão Santana - RS", text: "Sertão Santana - RS", state: "RS" },
    { value: "Sertãozinho - PB", text: "Sertãozinho - PB", state: "PB" },
    { value: "Sertãozinho - SP", text: "Sertãozinho - SP", state: "SP" },
    { value: "Sete Barras - SP", text: "Sete Barras - SP", state: "SP" },
    { value: "Sete Lagoas - MG", text: "Sete Lagoas - MG", state: "MG" },
    { value: "Sete Quedas - MS", text: "Sete Quedas - MS", state: "MS" },
    { value: "Sete de Setembro - RS", text: "Sete de Setembro - RS", state: "RS" },
    { value: "Setubinha - MG", text: "Setubinha - MG", state: "MG" },
    { value: "Severiano Melo - RN", text: "Severiano Melo - RN", state: "RN" },
    { value: "Severiano de Almeida - RS", text: "Severiano de Almeida - RS", state: "RS" },
    { value: "Severínia - SP", text: "Severínia - SP", state: "SP" },
    { value: "Siderópolis - SC", text: "Siderópolis - SC", state: "SC" },
    { value: "Sidrolândia - MS", text: "Sidrolândia - MS", state: "MS" },
    { value: "Sigefredo Pacheco - PI", text: "Sigefredo Pacheco - PI", state: "PI" },
    { value: "Silva Jardim - RJ", text: "Silva Jardim - RJ", state: "RJ" },
    { value: "Silvanópolis - TO", text: "Silvanópolis - TO", state: "TO" },
    { value: "Silveira Martins - RS", text: "Silveira Martins - RS", state: "RS" },
    { value: "Silveiras - SP", text: "Silveiras - SP", state: "SP" },
    { value: "Silveirânia - MG", text: "Silveirânia - MG", state: "MG" },
    { value: "Silves - AM", text: "Silves - AM", state: "AM" },
    { value: "Silvianópolis - MG", text: "Silvianópolis - MG", state: "MG" },
    { value: "Silvânia - GO", text: "Silvânia - GO", state: "GO" },
    { value: "Simolândia - GO", text: "Simolândia - GO", state: "GO" },
    { value: "Simonésia - MG", text: "Simonésia - MG", state: "MG" },
    { value: "Simplício Mendes - PI", text: "Simplício Mendes - PI", state: "PI" },
    { value: "Simão Dias - SE", text: "Simão Dias - SE", state: "SE" },
    { value: "Simão Pereira - MG", text: "Simão Pereira - MG", state: "MG" },
    { value: "Simões - PI", text: "Simões - PI", state: "PI" },
    { value: "Simões Filho - BA", text: "Simões Filho - BA", state: "BA" },
    { value: "Sinimbu - RS", text: "Sinimbu - RS", state: "RS" },
    { value: "Sinop - MT", text: "Sinop - MT", state: "MT" },
    { value: "Siqueira Campos - PR", text: "Siqueira Campos - PR", state: "PR" },
    { value: "Sirinhaém - PE", text: "Sirinhaém - PE", state: "PE" },
    { value: "Siriri - SE", text: "Siriri - SE", state: "SE" },
    { value: "Sobradinho - BA", text: "Sobradinho - BA", state: "BA" },
    { value: "Sobradinho - DF", text: "Sobradinho - DF", state: "DF" },
    { value: "Sobradinho - RS", text: "Sobradinho - RS", state: "RS" },
    { value: "Sobradinho II - DF", text: "Sobradinho II - DF", state: "DF" },
    { value: "Sobrado - PB", text: "Sobrado - PB", state: "PB" },
    { value: "Sobral - CE", text: "Sobral - CE", state: "CE" },
    { value: "Sobrália - MG", text: "Sobrália - MG", state: "MG" },
    { value: "Socorro - SP", text: "Socorro - SP", state: "SP" },
    { value: "Socorro do Piauí - PI", text: "Socorro do Piauí - PI", state: "PI" },
    { value: "Sol Nascente - DF", text: "Sol Nascente - DF", state: "DF" },
    { value: "Soledade - PB", text: "Soledade - PB", state: "PB" },
    { value: "Soledade - RS", text: "Soledade - RS", state: "RS" },
    { value: "Soledade de Minas - MG", text: "Soledade de Minas - MG", state: "MG" },
    { value: "Solidão - PE", text: "Solidão - PE", state: "PE" },
    { value: "Solonópole - CE", text: "Solonópole - CE", state: "CE" },
    { value: "Solânea - PB", text: "Solânea - PB", state: "PB" },
    { value: "Sombrio - SC", text: "Sombrio - SC", state: "SC" },
    { value: "Sonora - MS", text: "Sonora - MS", state: "MS" },
    { value: "Sooretama - ES", text: "Sooretama - ES", state: "ES" },
    { value: "Sorocaba - SP", text: "Sorocaba - SP", state: "SP" },
    { value: "Sorriso - MT", text: "Sorriso - MT", state: "MT" },
    { value: "Sossêgo - PB", text: "Sossêgo - PB", state: "PB" },
    { value: "Soure - PA", text: "Soure - PA", state: "PA" },
    { value: "Sousa - PB", text: "Sousa - PB", state: "PB" },
    { value: "Souto Soares - BA", text: "Souto Soares - BA", state: "BA" },
    { value: "Sucupira - TO", text: "Sucupira - TO", state: "TO" },
    { value: "Sucupira do Norte - MA", text: "Sucupira do Norte - MA", state: "MA" },
    { value: "Sucupira do Riachão - MA", text: "Sucupira do Riachão - MA", state: "MA" },
    { value: "Sud Mennucci - SP", text: "Sud Mennucci - SP", state: "SP" },
    { value: "Sudoeste - DF", text: "Sudoeste - DF", state: "DF" },
    { value: "Sul Brasil - SC", text: "Sul Brasil - SC", state: "SC" },
    { value: "Sulina - PR", text: "Sulina - PR", state: "PR" },
    { value: "Sumaré - SP", text: "Sumaré - SP", state: "SP" },
    { value: "Sumidouro - RJ", text: "Sumidouro - RJ", state: "RJ" },
    { value: "Sumé - PB", text: "Sumé - PB", state: "PB" },
    { value: "Surubim - PE", text: "Surubim - PE", state: "PE" },
    { value: "Sussuapara - PI", text: "Sussuapara - PI", state: "PI" },
    { value: "Suzano - SP", text: "Suzano - SP", state: "SP" },
    { value: "Suzanápolis - SP", text: "Suzanápolis - SP", state: "SP" },
    { value: "Sátiro Dias - BA", text: "Sátiro Dias - BA", state: "BA" },
    { value: "São Benedito - CE", text: "São Benedito - CE", state: "CE" },
    { value: "São Benedito do Rio Preto - MA", text: "São Benedito do Rio Preto - MA", state: "MA" },
    { value: "São Benedito do Sul - PE", text: "São Benedito do Sul - PE", state: "PE" },
    { value: "São Bentinho - PB", text: "São Bentinho - PB", state: "PB" },
    { value: "São Bento - MA", text: "São Bento - MA", state: "MA" },
    { value: "São Bento - PB", text: "São Bento - PB", state: "PB" },
    { value: "São Bento Abade - MG", text: "São Bento Abade - MG", state: "MG" },
    { value: "São Bento do Norte - RN", text: "São Bento do Norte - RN", state: "RN" },
    { value: "São Bento do Sapucaí - SP", text: "São Bento do Sapucaí - SP", state: "SP" },
    { value: "São Bento do Sul - SC", text: "São Bento do Sul - SC", state: "SC" },
    { value: "São Bento do Tocantins - TO", text: "São Bento do Tocantins - TO", state: "TO" },
    { value: "São Bento do Trairí - RN", text: "São Bento do Trairí - RN", state: "RN" },
    { value: "São Bento do Una - PE", text: "São Bento do Una - PE", state: "PE" },
    { value: "São Bernardino - SC", text: "São Bernardino - SC", state: "SC" },
    { value: "São Bernardo - MA", text: "São Bernardo - MA", state: "MA" },
    { value: "São Bernardo do Campo - SP", text: "São Bernardo do Campo - SP", state: "SP" },
    { value: "São Bonifácio - SC", text: "São Bonifácio - SC", state: "SC" },
    { value: "São Borja - RS", text: "São Borja - RS", state: "RS" },
    { value: "São Braz do Piauí - PI", text: "São Braz do Piauí - PI", state: "PI" },
    { value: "São Brás - AL", text: "São Brás - AL", state: "AL" },
    { value: "São Brás do Suaçuí - MG", text: "São Brás do Suaçuí - MG", state: "MG" },
    { value: "São Caetano de Odivela - PA", text: "São Caetano de Odivela - PA", state: "PA" },
    { value: "São Caetano do Sul - SP", text: "São Caetano do Sul - SP", state: "SP" },
    { value: "São Caitano - PE", text: "São Caitano - PE", state: "PE" },
    { value: "São Carlos - SC", text: "São Carlos - SC", state: "SC" },
    { value: "São Carlos - SP", text: "São Carlos - SP", state: "SP" },
    { value: "São Carlos do Ivaí - PR", text: "São Carlos do Ivaí - PR", state: "PR" },
    { value: "São Cristovão do Sul - SC", text: "São Cristovão do Sul - SC", state: "SC" },
    { value: "São Cristóvão - SE", text: "São Cristóvão - SE", state: "SE" },
    { value: "São Desidério - BA", text: "São Desidério - BA", state: "BA" },
    { value: "São Domingos - BA", text: "São Domingos - BA", state: "BA" },
    { value: "São Domingos - GO", text: "São Domingos - GO", state: "GO" },
    { value: "São Domingos - SC", text: "São Domingos - SC", state: "SC" },
    { value: "São Domingos - SE", text: "São Domingos - SE", state: "SE" },
    { value: "São Domingos das Dores - MG", text: "São Domingos das Dores - MG", state: "MG" },
    { value: "São Domingos de Pombal - PB", text: "São Domingos de Pombal - PB", state: "PB" },
    { value: "São Domingos do Araguaia - PA", text: "São Domingos do Araguaia - PA", state: "PA" },
    { value: "São Domingos do Azeitão - MA", text: "São Domingos do Azeitão - MA", state: "MA" },
    { value: "São Domingos do Capim - PA", text: "São Domingos do Capim - PA", state: "PA" },
    { value: "São Domingos do Cariri - PB", text: "São Domingos do Cariri - PB", state: "PB" },
    { value: "São Domingos do Maranhão - MA", text: "São Domingos do Maranhão - MA", state: "MA" },
    { value: "São Domingos do Norte - ES", text: "São Domingos do Norte - ES", state: "ES" },
    { value: "São Domingos do Prata - MG", text: "São Domingos do Prata - MG", state: "MG" },
    { value: "São Domingos do Sul - RS", text: "São Domingos do Sul - RS", state: "RS" },
    { value: "São Felipe - BA", text: "São Felipe - BA", state: "BA" },
    { value: "São Felipe d'Oeste - RO", text: "São Felipe d'Oeste - RO", state: "RO" },
    { value: "São Fernando - RN", text: "São Fernando - RN", state: "RN" },
    { value: "São Fidélis - RJ", text: "São Fidélis - RJ", state: "RJ" },
    { value: "São Francisco - MG", text: "São Francisco - MG", state: "MG" },
    { value: "São Francisco - PB", text: "São Francisco - PB", state: "PB" },
    { value: "São Francisco - SE", text: "São Francisco - SE", state: "SE" },
    { value: "São Francisco - SP", text: "São Francisco - SP", state: "SP" },
    { value: "São Francisco de Assis - RS", text: "São Francisco de Assis - RS", state: "RS" },
    { value: "São Francisco de Assis do Piauí - PI", text: "São Francisco de Assis do Piauí - PI", state: "PI" },
    { value: "São Francisco de Goiás - GO", text: "São Francisco de Goiás - GO", state: "GO" },
    { value: "São Francisco de Itabapoana - RJ", text: "São Francisco de Itabapoana - RJ", state: "RJ" },
    { value: "São Francisco de Paula - MG", text: "São Francisco de Paula - MG", state: "MG" },
    { value: "São Francisco de Paula - RS", text: "São Francisco de Paula - RS", state: "RS" },
    { value: "São Francisco de Sales - MG", text: "São Francisco de Sales - MG", state: "MG" },
    { value: "São Francisco do Brejão - MA", text: "São Francisco do Brejão - MA", state: "MA" },
    { value: "São Francisco do Conde - BA", text: "São Francisco do Conde - BA", state: "BA" },
    { value: "São Francisco do Glória - MG", text: "São Francisco do Glória - MG", state: "MG" },
    { value: "São Francisco do Guaporé - RO", text: "São Francisco do Guaporé - RO", state: "RO" },
    { value: "São Francisco do Maranhão - MA", text: "São Francisco do Maranhão - MA", state: "MA" },
    { value: "São Francisco do Oeste - RN", text: "São Francisco do Oeste - RN", state: "RN" },
    { value: "São Francisco do Pará - PA", text: "São Francisco do Pará - PA", state: "PA" },
    { value: "São Francisco do Piauí - PI", text: "São Francisco do Piauí - PI", state: "PI" },
    { value: "São Francisco do Sul - SC", text: "São Francisco do Sul - SC", state: "SC" },
    { value: "São Félix - BA", text: "São Félix - BA", state: "BA" },
    { value: "São Félix de Balsas - MA", text: "São Félix de Balsas - MA", state: "MA" },
    { value: "São Félix de Minas - MG", text: "São Félix de Minas - MG", state: "MG" },
    { value: "São Félix do Araguaia - MT", text: "São Félix do Araguaia - MT", state: "MT" },
    { value: "São Félix do Coribe - BA", text: "São Félix do Coribe - BA", state: "BA" },
    { value: "São Félix do Piauí - PI", text: "São Félix do Piauí - PI", state: "PI" },
    { value: "São Félix do Tocantins - TO", text: "São Félix do Tocantins - TO", state: "TO" },
    { value: "São Félix do Xingu - PA", text: "São Félix do Xingu - PA", state: "PA" },
    { value: "São Gabriel - BA", text: "São Gabriel - BA", state: "BA" },
    { value: "São Gabriel - RS", text: "São Gabriel - RS", state: "RS" },
    { value: "São Gabriel da Cachoeira - AM", text: "São Gabriel da Cachoeira - AM", state: "AM" },
    { value: "São Gabriel da Palha - ES", text: "São Gabriel da Palha - ES", state: "ES" },
    { value: "São Gabriel do Oeste - MS", text: "São Gabriel do Oeste - MS", state: "MS" },
    { value: "São Geraldo - MG", text: "São Geraldo - MG", state: "MG" },
    { value: "São Geraldo da Piedade - MG", text: "São Geraldo da Piedade - MG", state: "MG" },
    { value: "São Geraldo do Araguaia - PA", text: "São Geraldo do Araguaia - PA", state: "PA" },
    { value: "São Geraldo do Baixio - MG", text: "São Geraldo do Baixio - MG", state: "MG" },
    { value: "São Gonçalo - RJ", text: "São Gonçalo - RJ", state: "RJ" },
    { value: "São Gonçalo do Abaeté - MG", text: "São Gonçalo do Abaeté - MG", state: "MG" },
    { value: "São Gonçalo do Amarante - CE", text: "São Gonçalo do Amarante - CE", state: "CE" },
    { value: "São Gonçalo do Amarante - RN", text: "São Gonçalo do Amarante - RN", state: "RN" },
    { value: "São Gonçalo do Gurguéia - PI", text: "São Gonçalo do Gurguéia - PI", state: "PI" },
    { value: "São Gonçalo do Pará - MG", text: "São Gonçalo do Pará - MG", state: "MG" },
    { value: "São Gonçalo do Piauí - PI", text: "São Gonçalo do Piauí - PI", state: "PI" },
    { value: "São Gonçalo do Rio Abaixo - MG", text: "São Gonçalo do Rio Abaixo - MG", state: "MG" },
    { value: "São Gonçalo do Rio Preto - MG", text: "São Gonçalo do Rio Preto - MG", state: "MG" },
    { value: "São Gonçalo do Sapucaí - MG", text: "São Gonçalo do Sapucaí - MG", state: "MG" },
    { value: "São Gonçalo dos Campos - BA", text: "São Gonçalo dos Campos - BA", state: "BA" },
    { value: "São Gotardo - MG", text: "São Gotardo - MG", state: "MG" },
    { value: "São Jerônimo - RS", text: "São Jerônimo - RS", state: "RS" },
    { value: "São Jerônimo da Serra - PR", text: "São Jerônimo da Serra - PR", state: "PR" },
    { value: "São Joaquim - SC", text: "São Joaquim - SC", state: "SC" },
    { value: "São Joaquim da Barra - SP", text: "São Joaquim da Barra - SP", state: "SP" },
    { value: "São Joaquim de Bicas - MG", text: "São Joaquim de Bicas - MG", state: "MG" },
    { value: "São Joaquim do Monte - PE", text: "São Joaquim do Monte - PE", state: "PE" },
    { value: "São Jorge - RS", text: "São Jorge - RS", state: "RS" },
    { value: "São Jorge d'Oeste - PR", text: "São Jorge d'Oeste - PR", state: "PR" },
    { value: "São Jorge do Ivaí - PR", text: "São Jorge do Ivaí - PR", state: "PR" },
    { value: "São Jorge do Patrocínio - PR", text: "São Jorge do Patrocínio - PR", state: "PR" },
    { value: "São José - SC", text: "São José - SC", state: "SC" },
    { value: "São José da Barra - MG", text: "São José da Barra - MG", state: "MG" },
    { value: "São José da Bela Vista - SP", text: "São José da Bela Vista - SP", state: "SP" },
    { value: "São José da Boa Vista - PR", text: "São José da Boa Vista - PR", state: "PR" },
    { value: "São José da Coroa Grande - PE", text: "São José da Coroa Grande - PE", state: "PE" },
    { value: "São José da Lagoa Tapada - PB", text: "São José da Lagoa Tapada - PB", state: "PB" },
    { value: "São José da Laje - AL", text: "São José da Laje - AL", state: "AL" },
    { value: "São José da Lapa - MG", text: "São José da Lapa - MG", state: "MG" },
    { value: "São José da Safira - MG", text: "São José da Safira - MG", state: "MG" },
    { value: "São José da Tapera - AL", text: "São José da Tapera - AL", state: "AL" },
    { value: "São José da Varginha - MG", text: "São José da Varginha - MG", state: "MG" },
    { value: "São José da Vitória - BA", text: "São José da Vitória - BA", state: "BA" },
    { value: "São José das Missões - RS", text: "São José das Missões - RS", state: "RS" },
    { value: "São José das Palmeiras - PR", text: "São José das Palmeiras - PR", state: "PR" },
    { value: "São José de Caiana - PB", text: "São José de Caiana - PB", state: "PB" },
    { value: "São José de Espinharas - PB", text: "São José de Espinharas - PB", state: "PB" },
    { value: "São José de Mipibu - RN", text: "São José de Mipibu - RN", state: "RN" },
    { value: "São José de Piranhas - PB", text: "São José de Piranhas - PB", state: "PB" },
    { value: "São José de Princesa - PB", text: "São José de Princesa - PB", state: "PB" },
    { value: "São José de Ribamar - MA", text: "São José de Ribamar - MA", state: "MA" },
    { value: "São José de Ubá - RJ", text: "São José de Ubá - RJ", state: "RJ" },
    { value: "São José do Alegre - MG", text: "São José do Alegre - MG", state: "MG" },
    { value: "São José do Barreiro - SP", text: "São José do Barreiro - SP", state: "SP" },
    { value: "São José do Belmonte - PE", text: "São José do Belmonte - PE", state: "PE" },
    { value: "São José do Bonfim - PB", text: "São José do Bonfim - PB", state: "PB" },
    { value: "São José do Brejo do Cruz - PB", text: "São José do Brejo do Cruz - PB", state: "PB" },
    { value: "São José do Calçado - ES", text: "São José do Calçado - ES", state: "ES" },
    { value: "São José do Campestre - RN", text: "São José do Campestre - RN", state: "RN" },
    { value: "São José do Cedro - SC", text: "São José do Cedro - SC", state: "SC" },
    { value: "São José do Cerrito - SC", text: "São José do Cerrito - SC", state: "SC" },
    { value: "São José do Divino - MG", text: "São José do Divino - MG", state: "MG" },
    { value: "São José do Divino - PI", text: "São José do Divino - PI", state: "PI" },
    { value: "São José do Egito - PE", text: "São José do Egito - PE", state: "PE" },
    { value: "São José do Goiabal - MG", text: "São José do Goiabal - MG", state: "MG" },
    { value: "São José do Herval - RS", text: "São José do Herval - RS", state: "RS" },
    { value: "São José do Hortêncio - RS", text: "São José do Hortêncio - RS", state: "RS" },
    { value: "São José do Inhacorá - RS", text: "São José do Inhacorá - RS", state: "RS" },
    { value: "São José do Jacuri - MG", text: "São José do Jacuri - MG", state: "MG" },
    { value: "São José do Jacuípe - BA", text: "São José do Jacuípe - BA", state: "BA" },
    { value: "São José do Mantimento - MG", text: "São José do Mantimento - MG", state: "MG" },
    { value: "São José do Norte - RS", text: "São José do Norte - RS", state: "RS" },
    { value: "São José do Ouro - RS", text: "São José do Ouro - RS", state: "RS" },
    { value: "São José do Peixe - PI", text: "São José do Peixe - PI", state: "PI" },
    { value: "São José do Piauí - PI", text: "São José do Piauí - PI", state: "PI" },
    { value: "São José do Povo - MT", text: "São José do Povo - MT", state: "MT" },
    { value: "São José do Rio Claro - MT", text: "São José do Rio Claro - MT", state: "MT" },
    { value: "São José do Rio Pardo - SP", text: "São José do Rio Pardo - SP", state: "SP" },
    { value: "São José do Rio Preto - SP", text: "São José do Rio Preto - SP", state: "SP" },
    { value: "São José do Sabugi - PB", text: "São José do Sabugi - PB", state: "PB" },
    { value: "São José do Seridó - RN", text: "São José do Seridó - RN", state: "RN" },
    { value: "São José do Sul - RS", text: "São José do Sul - RS", state: "RS" },
    { value: "São José do Vale do Rio Preto - RJ", text: "São José do Vale do Rio Preto - RJ", state: "RJ" },
    { value: "São José do Xingu - MT", text: "São José do Xingu - MT", state: "MT" },
    { value: "São José dos Ausentes - RS", text: "São José dos Ausentes - RS", state: "RS" },
    { value: "São José dos Basílios - MA", text: "São José dos Basílios - MA", state: "MA" },
    { value: "São José dos Campos - SP", text: "São José dos Campos - SP", state: "SP" },
    { value: "São José dos Cordeiros - PB", text: "São José dos Cordeiros - PB", state: "PB" },
    { value: "São José dos Pinhais - PR", text: "São José dos Pinhais - PR", state: "PR" },
    { value: "São José dos Quatro Marcos - MT", text: "São José dos Quatro Marcos - MT", state: "MT" },
    { value: "São José dos Ramos - PB", text: "São José dos Ramos - PB", state: "PB" },
    { value: "São João - PE", text: "São João - PE", state: "PE" },
    { value: "São João - PR", text: "São João - PR", state: "PR" },
    { value: "São João Batista - MA", text: "São João Batista - MA", state: "MA" },
    { value: "São João Batista - SC", text: "São João Batista - SC", state: "SC" },
    { value: "São João Batista do Glória - MG", text: "São João Batista do Glória - MG", state: "MG" },
    { value: "São João Evangelista - MG", text: "São João Evangelista - MG", state: "MG" },
    { value: "São João Nepomuceno - MG", text: "São João Nepomuceno - MG", state: "MG" },
    { value: "São João d'Aliança - GO", text: "São João d'Aliança - GO", state: "GO" },
    { value: "São João da Baliza - RR", text: "São João da Baliza - RR", state: "RR" },
    { value: "São João da Barra - RJ", text: "São João da Barra - RJ", state: "RJ" },
    { value: "São João da Boa Vista - SP", text: "São João da Boa Vista - SP", state: "SP" },
    { value: "São João da Canabrava - PI", text: "São João da Canabrava - PI", state: "PI" },
    { value: "São João da Fronteira - PI", text: "São João da Fronteira - PI", state: "PI" },
    { value: "São João da Lagoa - MG", text: "São João da Lagoa - MG", state: "MG" },
    { value: "São João da Mata - MG", text: "São João da Mata - MG", state: "MG" },
    { value: "São João da Paraúna - GO", text: "São João da Paraúna - GO", state: "GO" },
    { value: "São João da Ponta - PA", text: "São João da Ponta - PA", state: "PA" },
    { value: "São João da Ponte - MG", text: "São João da Ponte - MG", state: "MG" },
    { value: "São João da Serra - PI", text: "São João da Serra - PI", state: "PI" },
    { value: "São João da Urtiga - RS", text: "São João da Urtiga - RS", state: "RS" },
    { value: "São João da Varjota - PI", text: "São João da Varjota - PI", state: "PI" },
    { value: "São João das Duas Pontes - SP", text: "São João das Duas Pontes - SP", state: "SP" },
    { value: "São João das Missões - MG", text: "São João das Missões - MG", state: "MG" },
    { value: "São João de Iracema - SP", text: "São João de Iracema - SP", state: "SP" },
    { value: "São João de Meriti - RJ", text: "São João de Meriti - RJ", state: "RJ" },
    { value: "São João de Pirabas - PA", text: "São João de Pirabas - PA", state: "PA" },
    { value: "São João del Rei - MG", text: "São João del Rei - MG", state: "MG" },
    { value: "São João do Araguaia - PA", text: "São João do Araguaia - PA", state: "PA" },
    { value: "São João do Arraial - PI", text: "São João do Arraial - PI", state: "PI" },
    { value: "São João do Caiuá - PR", text: "São João do Caiuá - PR", state: "PR" },
    { value: "São João do Cariri - PB", text: "São João do Cariri - PB", state: "PB" },
    { value: "São João do Carú - MA", text: "São João do Carú - MA", state: "MA" },
    { value: "São João do Itaperiú - SC", text: "São João do Itaperiú - SC", state: "SC" },
    { value: "São João do Ivaí - PR", text: "São João do Ivaí - PR", state: "PR" },
    { value: "São João do Jaguaribe - CE", text: "São João do Jaguaribe - CE", state: "CE" },
    { value: "São João do Manhuaçu - MG", text: "São João do Manhuaçu - MG", state: "MG" },
    { value: "São João do Manteninha - MG", text: "São João do Manteninha - MG", state: "MG" },
    { value: "São João do Oeste - SC", text: "São João do Oeste - SC", state: "SC" },
    { value: "São João do Oriente - MG", text: "São João do Oriente - MG", state: "MG" },
    { value: "São João do Pacuí - MG", text: "São João do Pacuí - MG", state: "MG" },
    { value: "São João do Paraíso - MA", text: "São João do Paraíso - MA", state: "MA" },
    { value: "São João do Paraíso - MG", text: "São João do Paraíso - MG", state: "MG" },
    { value: "São João do Pau d'Alho - SP", text: "São João do Pau d'Alho - SP", state: "SP" },
    { value: "São João do Piauí - PI", text: "São João do Piauí - PI", state: "PI" },
    { value: "São João do Polêsine - RS", text: "São João do Polêsine - RS", state: "RS" },
    { value: "São João do Rio do Peixe - PB", text: "São João do Rio do Peixe - PB", state: "PB" },
    { value: "São João do Sabugi - RN", text: "São João do Sabugi - RN", state: "RN" },
    { value: "São João do Soter - MA", text: "São João do Soter - MA", state: "MA" },
    { value: "São João do Sul - SC", text: "São João do Sul - SC", state: "SC" },
    { value: "São João do Tigre - PB", text: "São João do Tigre - PB", state: "PB" },
    { value: "São João do Triunfo - PR", text: "São João do Triunfo - PR", state: "PR" },
    { value: "São João dos Patos - MA", text: "São João dos Patos - MA", state: "MA" },
    { value: "São Julião - PI", text: "São Julião - PI", state: "PI" },
    { value: "São Leopoldo - RS", text: "São Leopoldo - RS", state: "RS" },
    { value: "São Lourenço - MG", text: "São Lourenço - MG", state: "MG" },
    { value: "São Lourenço da Mata - PE", text: "São Lourenço da Mata - PE", state: "PE" },
    { value: "São Lourenço da Serra - SP", text: "São Lourenço da Serra - SP", state: "SP" },
    { value: "São Lourenço do Oeste - SC", text: "São Lourenço do Oeste - SC", state: "SC" },
    { value: "São Lourenço do Piauí - PI", text: "São Lourenço do Piauí - PI", state: "PI" },
    { value: "São Lourenço do Sul - RS", text: "São Lourenço do Sul - RS", state: "RS" },
    { value: "São Ludgero - SC", text: "São Ludgero - SC", state: "SC" },
    { value: "São Luis do Piauí - PI", text: "São Luis do Piauí - PI", state: "PI" },
    { value: "São Luiz - RR", text: "São Luiz - RR", state: "RR" },
    { value: "São Luiz Gonzaga - RS", text: "São Luiz Gonzaga - RS", state: "RS" },
    { value: "São Luís - MA", text: "São Luís - MA", state: "MA" },
    { value: "São Luís Gonzaga do Maranhão - MA", text: "São Luís Gonzaga do Maranhão - MA", state: "MA" },
    { value: "São Luís de Montes Belos - GO", text: "São Luís de Montes Belos - GO", state: "GO" },
    { value: "São Luís do Curu - CE", text: "São Luís do Curu - CE", state: "CE" },
    { value: "São Luís do Paraitinga - SP", text: "São Luís do Paraitinga - SP", state: "SP" },
    { value: "São Luís do Quitunde - AL", text: "São Luís do Quitunde - AL", state: "AL" },
    { value: "São Luíz do Norte - GO", text: "São Luíz do Norte - GO", state: "GO" },
    { value: "São Mamede - PB", text: "São Mamede - PB", state: "PB" },
    { value: "São Manoel do Paraná - PR", text: "São Manoel do Paraná - PR", state: "PR" },
    { value: "São Manuel - SP", text: "São Manuel - SP", state: "SP" },
    { value: "São Marcos - RS", text: "São Marcos - RS", state: "RS" },
    { value: "São Martinho - RS", text: "São Martinho - RS", state: "RS" },
    { value: "São Martinho - SC", text: "São Martinho - SC", state: "SC" },
    { value: "São Martinho da Serra - RS", text: "São Martinho da Serra - RS", state: "RS" },
    { value: "São Mateus - ES", text: "São Mateus - ES", state: "ES" },
    { value: "São Mateus do Maranhão - MA", text: "São Mateus do Maranhão - MA", state: "MA" },
    { value: "São Mateus do Sul - PR", text: "São Mateus do Sul - PR", state: "PR" },
    { value: "São Miguel - RN", text: "São Miguel - RN", state: "RN" },
    { value: "São Miguel Arcanjo - SP", text: "São Miguel Arcanjo - SP", state: "SP" },
    { value: "São Miguel da Baixa Grande - PI", text: "São Miguel da Baixa Grande - PI", state: "PI" },
    { value: "São Miguel da Boa Vista - SC", text: "São Miguel da Boa Vista - SC", state: "SC" },
    { value: "São Miguel das Matas - BA", text: "São Miguel das Matas - BA", state: "BA" },
    { value: "São Miguel das Missões - RS", text: "São Miguel das Missões - RS", state: "RS" },
    { value: "São Miguel de Taipu - PB", text: "São Miguel de Taipu - PB", state: "PB" },
    { value: "São Miguel de Touros - RN", text: "São Miguel de Touros - RN", state: "RN" },
    { value: "São Miguel do Aleixo - SE", text: "São Miguel do Aleixo - SE", state: "SE" },
    { value: "São Miguel do Anta - MG", text: "São Miguel do Anta - MG", state: "MG" },
    { value: "São Miguel do Araguaia - GO", text: "São Miguel do Araguaia - GO", state: "GO" },
    { value: "São Miguel do Fidalgo - PI", text: "São Miguel do Fidalgo - PI", state: "PI" },
    { value: "São Miguel do Guamá - PA", text: "São Miguel do Guamá - PA", state: "PA" },
    { value: "São Miguel do Guaporé - RO", text: "São Miguel do Guaporé - RO", state: "RO" },
    { value: "São Miguel do Iguaçu - PR", text: "São Miguel do Iguaçu - PR", state: "PR" },
    { value: "São Miguel do Oeste - SC", text: "São Miguel do Oeste - SC", state: "SC" },
    { value: "São Miguel do Passa Quatro - GO", text: "São Miguel do Passa Quatro - GO", state: "GO" },
    { value: "São Miguel do Tapuio - PI", text: "São Miguel do Tapuio - PI", state: "PI" },
    { value: "São Miguel do Tocantins - TO", text: "São Miguel do Tocantins - TO", state: "TO" },
    { value: "São Miguel dos Campos - AL", text: "São Miguel dos Campos - AL", state: "AL" },
    { value: "São Miguel dos Milagres - AL", text: "São Miguel dos Milagres - AL", state: "AL" },
    { value: "São Nicolau - RS", text: "São Nicolau - RS", state: "RS" },
    { value: "São Patrício - GO", text: "São Patrício - GO", state: "GO" },
    { value: "São Paulo - SP", text: "São Paulo - SP", state: "SP" },
    { value: "São Paulo das Missões - RS", text: "São Paulo das Missões - RS", state: "RS" },
    { value: "São Paulo de Olivença - AM", text: "São Paulo de Olivença - AM", state: "AM" },
    { value: "São Paulo do Potengi - RN", text: "São Paulo do Potengi - RN", state: "RN" },
    { value: "São Pedro - RN", text: "São Pedro - RN", state: "RN" },
    { value: "São Pedro - SP", text: "São Pedro - SP", state: "SP" },
    { value: "São Pedro da Aldeia - RJ", text: "São Pedro da Aldeia - RJ", state: "RJ" },
    { value: "São Pedro da Cipa - MT", text: "São Pedro da Cipa - MT", state: "MT" },
    { value: "São Pedro da Serra - RS", text: "São Pedro da Serra - RS", state: "RS" },
    { value: "São Pedro da União - MG", text: "São Pedro da União - MG", state: "MG" },
    { value: "São Pedro da Água Branca - MA", text: "São Pedro da Água Branca - MA", state: "MA" },
    { value: "São Pedro das Missões - RS", text: "São Pedro das Missões - RS", state: "RS" },
    { value: "São Pedro de Alcântara - SC", text: "São Pedro de Alcântara - SC", state: "SC" },
    { value: "São Pedro do Butiá - RS", text: "São Pedro do Butiá - RS", state: "RS" },
    { value: "São Pedro do Iguaçu - PR", text: "São Pedro do Iguaçu - PR", state: "PR" },
    { value: "São Pedro do Ivaí - PR", text: "São Pedro do Ivaí - PR", state: "PR" },
    { value: "São Pedro do Paraná - PR", text: "São Pedro do Paraná - PR", state: "PR" },
    { value: "São Pedro do Piauí - PI", text: "São Pedro do Piauí - PI", state: "PI" },
    { value: "São Pedro do Suaçuí - MG", text: "São Pedro do Suaçuí - MG", state: "MG" },
    { value: "São Pedro do Sul - RS", text: "São Pedro do Sul - RS", state: "RS" },
    { value: "São Pedro do Turvo - SP", text: "São Pedro do Turvo - SP", state: "SP" },
    { value: "São Pedro dos Crentes - MA", text: "São Pedro dos Crentes - MA", state: "MA" },
    { value: "São Pedro dos Ferros - MG", text: "São Pedro dos Ferros - MG", state: "MG" },
    { value: "São Rafael - RN", text: "São Rafael - RN", state: "RN" },
    { value: "São Raimundo Nonato - PI", text: "São Raimundo Nonato - PI", state: "PI" },
    { value: "São Raimundo das Mangabeiras - MA", text: "São Raimundo das Mangabeiras - MA", state: "MA" },
    { value: "São Raimundo do Doca Bezerra - MA", text: "São Raimundo do Doca Bezerra - MA", state: "MA" },
    { value: "São Roberto - MA", text: "São Roberto - MA", state: "MA" },
    { value: "São Romão - MG", text: "São Romão - MG", state: "MG" },
    { value: "São Roque - SP", text: "São Roque - SP", state: "SP" },
    { value: "São Roque de Minas - MG", text: "São Roque de Minas - MG", state: "MG" },
    { value: "São Roque do Canaã - ES", text: "São Roque do Canaã - ES", state: "ES" },
    { value: "São Salvador do Tocantins - TO", text: "São Salvador do Tocantins - TO", state: "TO" },
    { value: "São Sebastião - AL", text: "São Sebastião - AL", state: "AL" },
    { value: "São Sebastião - DF", text: "São Sebastião - DF", state: "DF" },
    { value: "São Sebastião - SP", text: "São Sebastião - SP", state: "SP" },
    { value: "São Sebastião da Amoreira - PR", text: "São Sebastião da Amoreira - PR", state: "PR" },
    { value: "São Sebastião da Bela Vista - MG", text: "São Sebastião da Bela Vista - MG", state: "MG" },
    { value: "São Sebastião da Boa Vista - PA", text: "São Sebastião da Boa Vista - PA", state: "PA" },
    { value: "São Sebastião da Grama - SP", text: "São Sebastião da Grama - SP", state: "SP" },
    { value: "São Sebastião da Vargem Alegre - MG", text: "São Sebastião da Vargem Alegre - MG", state: "MG" },
    { value: "São Sebastião de Lagoa de Roça - PB", text: "São Sebastião de Lagoa de Roça - PB", state: "PB" },
    { value: "São Sebastião do Alto - RJ", text: "São Sebastião do Alto - RJ", state: "RJ" },
    { value: "São Sebastião do Anta - MG", text: "São Sebastião do Anta - MG", state: "MG" },
    { value: "São Sebastião do Caí - RS", text: "São Sebastião do Caí - RS", state: "RS" },
    { value: "São Sebastião do Maranhão - MG", text: "São Sebastião do Maranhão - MG", state: "MG" },
    { value: "São Sebastião do Oeste - MG", text: "São Sebastião do Oeste - MG", state: "MG" },
    { value: "São Sebastião do Paraíso - MG", text: "São Sebastião do Paraíso - MG", state: "MG" },
    { value: "São Sebastião do Passé - BA", text: "São Sebastião do Passé - BA", state: "BA" },
    { value: "São Sebastião do Rio Preto - MG", text: "São Sebastião do Rio Preto - MG", state: "MG" },
    { value: "São Sebastião do Rio Verde - MG", text: "São Sebastião do Rio Verde - MG", state: "MG" },
    { value: "São Sebastião do Tocantins - TO", text: "São Sebastião do Tocantins - TO", state: "TO" },
    { value: "São Sebastião do Uatumã - AM", text: "São Sebastião do Uatumã - AM", state: "AM" },
    { value: "São Sebastião do Umbuzeiro - PB", text: "São Sebastião do Umbuzeiro - PB", state: "PB" },
    { value: "São Sepé - RS", text: "São Sepé - RS", state: "RS" },
    { value: "São Simão - GO", text: "São Simão - GO", state: "GO" },
    { value: "São Simão - SP", text: "São Simão - SP", state: "SP" },
    { value: "São Thomé das Letras - MG", text: "São Thomé das Letras - MG", state: "MG" },
    { value: "São Tiago - MG", text: "São Tiago - MG", state: "MG" },
    { value: "São Tomás de Aquino - MG", text: "São Tomás de Aquino - MG", state: "MG" },
    { value: "São Tomé - PR", text: "São Tomé - PR", state: "PR" },
    { value: "São Tomé - RN", text: "São Tomé - RN", state: "RN" },
    { value: "São Valentim - RS", text: "São Valentim - RS", state: "RS" },
    { value: "São Valentim do Sul - RS", text: "São Valentim do Sul - RS", state: "RS" },
    { value: "São Valério - TO", text: "São Valério - TO", state: "TO" },
    { value: "São Valério do Sul - RS", text: "São Valério do Sul - RS", state: "RS" },
    { value: "São Vendelino - RS", text: "São Vendelino - RS", state: "RS" },
    { value: "São Vicente - RN", text: "São Vicente - RN", state: "RN" },
    { value: "São Vicente - SP", text: "São Vicente - SP", state: "SP" },
    { value: "São Vicente Ferrer - MA", text: "São Vicente Ferrer - MA", state: "MA" },
    { value: "São Vicente Ferrer - PE", text: "São Vicente Ferrer - PE", state: "PE" },
    { value: "São Vicente de Minas - MG", text: "São Vicente de Minas - MG", state: "MG" },
    { value: "São Vicente do Sul - RS", text: "São Vicente do Sul - RS", state: "RS" },
    { value: "Sério - RS", text: "Sério - RS", state: "RS" },
    { value: "Sítio Novo - MA", text: "Sítio Novo - MA", state: "MA" },
    { value: "Sítio Novo - RN", text: "Sítio Novo - RN", state: "RN" },
    { value: "Sítio Novo do Tocantins - TO", text: "Sítio Novo do Tocantins - TO", state: "TO" },
    { value: "Sítio d'Abadia - GO", text: "Sítio d'Abadia - GO", state: "GO" },
    { value: "Sítio do Mato - BA", text: "Sítio do Mato - BA", state: "BA" },
    { value: "Sítio do Quinto - BA", text: "Sítio do Quinto - BA", state: "BA" },
    { value: "Tabaporã - MT", text: "Tabaporã - MT", state: "MT" },
    { value: "Tabapuã - SP", text: "Tabapuã - SP", state: "SP" },
    { value: "Tabatinga - AM", text: "Tabatinga - AM", state: "AM" },
    { value: "Tabatinga - SP", text: "Tabatinga - SP", state: "SP" },
    { value: "Tabaí - RS", text: "Tabaí - RS", state: "RS" },
    { value: "Tabira - PE", text: "Tabira - PE", state: "PE" },
    { value: "Tabocas do Brejo Velho - BA", text: "Tabocas do Brejo Velho - BA", state: "BA" },
    { value: "Taboleiro Grande - RN", text: "Taboleiro Grande - RN", state: "RN" },
    { value: "Taboão da Serra - SP", text: "Taboão da Serra - SP", state: "SP" },
    { value: "Tabuleiro - MG", text: "Tabuleiro - MG", state: "MG" },
    { value: "Tabuleiro do Norte - CE", text: "Tabuleiro do Norte - CE", state: "CE" },
    { value: "Tacaimbó - PE", text: "Tacaimbó - PE", state: "PE" },
    { value: "Tacaratu - PE", text: "Tacaratu - PE", state: "PE" },
    { value: "Taciba - SP", text: "Taciba - SP", state: "SP" },
    { value: "Tacuru - MS", text: "Tacuru - MS", state: "MS" },
    { value: "Taguatinga - DF", text: "Taguatinga - DF", state: "DF" },
    { value: "Taguatinga - TO", text: "Taguatinga - TO", state: "TO" },
    { value: "Taguaí - SP", text: "Taguaí - SP", state: "SP" },
    { value: "Taiaçu - SP", text: "Taiaçu - SP", state: "SP" },
    { value: "Tailândia - PA", text: "Tailândia - PA", state: "PA" },
    { value: "Taiobeiras - MG", text: "Taiobeiras - MG", state: "MG" },
    { value: "Taipas do Tocantins - TO", text: "Taipas do Tocantins - TO", state: "TO" },
    { value: "Taipu - RN", text: "Taipu - RN", state: "RN" },
    { value: "Taió - SC", text: "Taió - SC", state: "SC" },
    { value: "Taiúva - SP", text: "Taiúva - SP", state: "SP" },
    { value: "Talismã - TO", text: "Talismã - TO", state: "TO" },
    { value: "Tamandaré - PE", text: "Tamandaré - PE", state: "PE" },
    { value: "Tamarana - PR", text: "Tamarana - PR", state: "PR" },
    { value: "Tambaú - SP", text: "Tambaú - SP", state: "SP" },
    { value: "Tamboara - PR", text: "Tamboara - PR", state: "PR" },
    { value: "Tamboril - CE", text: "Tamboril - CE", state: "CE" },
    { value: "Tamboril do Piauí - PI", text: "Tamboril do Piauí - PI", state: "PI" },
    { value: "Tanabi - SP", text: "Tanabi - SP", state: "SP" },
    { value: "Tangará - RN", text: "Tangará - RN", state: "RN" },
    { value: "Tangará - SC", text: "Tangará - SC", state: "SC" },
    { value: "Tangará da Serra - MT", text: "Tangará da Serra - MT", state: "MT" },
    { value: "Tanguá - RJ", text: "Tanguá - RJ", state: "RJ" },
    { value: "Tanhaçu - BA", text: "Tanhaçu - BA", state: "BA" },
    { value: "Tanque Novo - BA", text: "Tanque Novo - BA", state: "BA" },
    { value: "Tanque d'Arca - AL", text: "Tanque d'Arca - AL", state: "AL" },
    { value: "Tanque do Piauí - PI", text: "Tanque do Piauí - PI", state: "PI" },
    { value: "Tanquinho - BA", text: "Tanquinho - BA", state: "BA" },
    { value: "Taparuba - MG", text: "Taparuba - MG", state: "MG" },
    { value: "Tapauá - AM", text: "Tapauá - AM", state: "AM" },
    { value: "Tapejara - PR", text: "Tapejara - PR", state: "PR" },
    { value: "Tapejara - RS", text: "Tapejara - RS", state: "RS" },
    { value: "Tapera - RS", text: "Tapera - RS", state: "RS" },
    { value: "Taperoá - BA", text: "Taperoá - BA", state: "BA" },
    { value: "Taperoá - PB", text: "Taperoá - PB", state: "PB" },
    { value: "Tapes - RS", text: "Tapes - RS", state: "RS" },
    { value: "Tapira - MG", text: "Tapira - MG", state: "MG" },
    { value: "Tapira - PR", text: "Tapira - PR", state: "PR" },
    { value: "Tapiramutá - BA", text: "Tapiramutá - BA", state: "BA" },
    { value: "Tapiratiba - SP", text: "Tapiratiba - SP", state: "SP" },
    { value: "Tapiraí - MG", text: "Tapiraí - MG", state: "MG" },
    { value: "Tapiraí - SP", text: "Tapiraí - SP", state: "SP" },
    { value: "Tapurah - MT", text: "Tapurah - MT", state: "MT" },
    { value: "Taquara - RS", text: "Taquara - RS", state: "RS" },
    { value: "Taquaral - SP", text: "Taquaral - SP", state: "SP" },
    { value: "Taquaral de Goiás - GO", text: "Taquaral de Goiás - GO", state: "GO" },
    { value: "Taquarana - AL", text: "Taquarana - AL", state: "AL" },
    { value: "Taquaraçu de Minas - MG", text: "Taquaraçu de Minas - MG", state: "MG" },
    { value: "Taquari - RS", text: "Taquari - RS", state: "RS" },
    { value: "Taquaritinga - SP", text: "Taquaritinga - SP", state: "SP" },
    { value: "Taquaritinga do Norte - PE", text: "Taquaritinga do Norte - PE", state: "PE" },
    { value: "Taquarituba - SP", text: "Taquarituba - SP", state: "SP" },
    { value: "Taquarivaí - SP", text: "Taquarivaí - SP", state: "SP" },
    { value: "Taquarussu - MS", text: "Taquarussu - MS", state: "MS" },
    { value: "Taquaruçu do Sul - RS", text: "Taquaruçu do Sul - RS", state: "RS" },
    { value: "Tarabai - SP", text: "Tarabai - SP", state: "SP" },
    { value: "Tarauacá - AC", text: "Tarauacá - AC", state: "AC" },
    { value: "Tarrafas - CE", text: "Tarrafas - CE", state: "CE" },
    { value: "Tartarugalzinho - AP", text: "Tartarugalzinho - AP", state: "AP" },
    { value: "Tarumirim - MG", text: "Tarumirim - MG", state: "MG" },
    { value: "Tarumã - SP", text: "Tarumã - SP", state: "SP" },
    { value: "Tasso Fragoso - MA", text: "Tasso Fragoso - MA", state: "MA" },
    { value: "Tatuí - SP", text: "Tatuí - SP", state: "SP" },
    { value: "Taubaté - SP", text: "Taubaté - SP", state: "SP" },
    { value: "Tauá - CE", text: "Tauá - CE", state: "CE" },
    { value: "Tavares - PB", text: "Tavares - PB", state: "PB" },
    { value: "Tavares - RS", text: "Tavares - RS", state: "RS" },
    { value: "Tefé - AM", text: "Tefé - AM", state: "AM" },
    { value: "Teixeira - PB", text: "Teixeira - PB", state: "PB" },
    { value: "Teixeira Soares - PR", text: "Teixeira Soares - PR", state: "PR" },
    { value: "Teixeira de Freitas - BA", text: "Teixeira de Freitas - BA", state: "BA" },
    { value: "Teixeiras - MG", text: "Teixeiras - MG", state: "MG" },
    { value: "Teixeirópolis - RO", text: "Teixeirópolis - RO", state: "RO" },
    { value: "Tejupá - SP", text: "Tejupá - SP", state: "SP" },
    { value: "Tejuçuoca - CE", text: "Tejuçuoca - CE", state: "CE" },
    { value: "Telha - SE", text: "Telha - SE", state: "SE" },
    { value: "Telêmaco Borba - PR", text: "Telêmaco Borba - PR", state: "PR" },
    { value: "Tenente Ananias - RN", text: "Tenente Ananias - RN", state: "RN" },
    { value: "Tenente Laurentino Cruz - RN", text: "Tenente Laurentino Cruz - RN", state: "RN" },
    { value: "Tenente Portela - RS", text: "Tenente Portela - RS", state: "RS" },
    { value: "Tenório - PB", text: "Tenório - PB", state: "PB" },
    { value: "Teodoro Sampaio - BA", text: "Teodoro Sampaio - BA", state: "BA" },
    { value: "Teodoro Sampaio - SP", text: "Teodoro Sampaio - SP", state: "SP" },
    { value: "Teofilândia - BA", text: "Teofilândia - BA", state: "BA" },
    { value: "Teolândia - BA", text: "Teolândia - BA", state: "BA" },
    { value: "Teotônio Vilela - AL", text: "Teotônio Vilela - AL", state: "AL" },
    { value: "Terenos - MS", text: "Terenos - MS", state: "MS" },
    { value: "Teresina - PI", text: "Teresina - PI", state: "PI" },
    { value: "Teresina de Goiás - GO", text: "Teresina de Goiás - GO", state: "GO" },
    { value: "Teresópolis - RJ", text: "Teresópolis - RJ", state: "RJ" },
    { value: "Terezinha - PE", text: "Terezinha - PE", state: "PE" },
    { value: "Terezópolis de Goiás - GO", text: "Terezópolis de Goiás - GO", state: "GO" },
    { value: "Terra Alta - PA", text: "Terra Alta - PA", state: "PA" },
    { value: "Terra Boa - PR", text: "Terra Boa - PR", state: "PR" },
    { value: "Terra Nova - BA", text: "Terra Nova - BA", state: "BA" },
    { value: "Terra Nova - PE", text: "Terra Nova - PE", state: "PE" },
    { value: "Terra Nova do Norte - MT", text: "Terra Nova do Norte - MT", state: "MT" },
    { value: "Terra Rica - PR", text: "Terra Rica - PR", state: "PR" },
    { value: "Terra Roxa - PR", text: "Terra Roxa - PR", state: "PR" },
    { value: "Terra Roxa - SP", text: "Terra Roxa - SP", state: "SP" },
    { value: "Terra Santa - PA", text: "Terra Santa - PA", state: "PA" },
    { value: "Terra de Areia - RS", text: "Terra de Areia - RS", state: "RS" },
    { value: "Tesouro - MT", text: "Tesouro - MT", state: "MT" },
    { value: "Teutônia - RS", text: "Teutônia - RS", state: "RS" },
    { value: "Teófilo Otoni - MG", text: "Teófilo Otoni - MG", state: "MG" },
    { value: "Theobroma - RO", text: "Theobroma - RO", state: "RO" },
    { value: "Tianguá - CE", text: "Tianguá - CE", state: "CE" },
    { value: "Tibagi - PR", text: "Tibagi - PR", state: "PR" },
    { value: "Tibau - RN", text: "Tibau - RN", state: "RN" },
    { value: "Tibau do Sul - RN", text: "Tibau do Sul - RN", state: "RN" },
    { value: "Tietê - SP", text: "Tietê - SP", state: "SP" },
    { value: "Tigrinhos - SC", text: "Tigrinhos - SC", state: "SC" },
    { value: "Tijucas - SC", text: "Tijucas - SC", state: "SC" },
    { value: "Tijucas do Sul - PR", text: "Tijucas do Sul - PR", state: "PR" },
    { value: "Timbaúba - PE", text: "Timbaúba - PE", state: "PE" },
    { value: "Timbaúba dos Batistas - RN", text: "Timbaúba dos Batistas - RN", state: "RN" },
    { value: "Timbiras - MA", text: "Timbiras - MA", state: "MA" },
    { value: "Timburi - SP", text: "Timburi - SP", state: "SP" },
    { value: "Timbé do Sul - SC", text: "Timbé do Sul - SC", state: "SC" },
    { value: "Timbó - SC", text: "Timbó - SC", state: "SC" },
    { value: "Timbó Grande - SC", text: "Timbó Grande - SC", state: "SC" },
    { value: "Timon - MA", text: "Timon - MA", state: "MA" },
    { value: "Timóteo - MG", text: "Timóteo - MG", state: "MG" },
    { value: "Tio Hugo - RS", text: "Tio Hugo - RS", state: "RS" },
    { value: "Tiradentes - MG", text: "Tiradentes - MG", state: "MG" },
    { value: "Tiradentes do Sul - RS", text: "Tiradentes do Sul - RS", state: "RS" },
    { value: "Tiros - MG", text: "Tiros - MG", state: "MG" },
    { value: "Tobias Barreto - SE", text: "Tobias Barreto - SE", state: "SE" },
    { value: "Tocantins - MG", text: "Tocantins - MG", state: "MG" },
    { value: "Tocantinópolis - TO", text: "Tocantinópolis - TO", state: "TO" },
    { value: "Tocantínia - TO", text: "Tocantínia - TO", state: "TO" },
    { value: "Tocos do Moji - MG", text: "Tocos do Moji - MG", state: "MG" },
    { value: "Toledo - MG", text: "Toledo - MG", state: "MG" },
    { value: "Toledo - PR", text: "Toledo - PR", state: "PR" },
    { value: "Tomar do Geru - SE", text: "Tomar do Geru - SE", state: "SE" },
    { value: "Tomazina - PR", text: "Tomazina - PR", state: "PR" },
    { value: "Tombos - MG", text: "Tombos - MG", state: "MG" },
    { value: "Tomé-Açu - PA", text: "Tomé-Açu - PA", state: "PA" },
    { value: "Tonantins - AM", text: "Tonantins - AM", state: "AM" },
    { value: "Toritama - PE", text: "Toritama - PE", state: "PE" },
    { value: "Torixoréu - MT", text: "Torixoréu - MT", state: "MT" },
    { value: "Toropi - RS", text: "Toropi - RS", state: "RS" },
    { value: "Torre de Pedra - SP", text: "Torre de Pedra - SP", state: "SP" },
    { value: "Torres - RS", text: "Torres - RS", state: "RS" },
    { value: "Torrinha - SP", text: "Torrinha - SP", state: "SP" },
    { value: "Touros - RN", text: "Touros - RN", state: "RN" },
    { value: "Trabiju - SP", text: "Trabiju - SP", state: "SP" },
    { value: "Tracuateua - PA", text: "Tracuateua - PA", state: "PA" },
    { value: "Tracunhaém - PE", text: "Tracunhaém - PE", state: "PE" },
    { value: "Traipu - AL", text: "Traipu - AL", state: "AL" },
    { value: "Trairi - CE", text: "Trairi - CE", state: "CE" },
    { value: "Trairão - PA", text: "Trairão - PA", state: "PA" },
    { value: "Trajano de Morais - RJ", text: "Trajano de Morais - RJ", state: "RJ" },
    { value: "Tramandaí - RS", text: "Tramandaí - RS", state: "RS" },
    { value: "Travesseiro - RS", text: "Travesseiro - RS", state: "RS" },
    { value: "Tremedal - BA", text: "Tremedal - BA", state: "BA" },
    { value: "Tremembé - SP", text: "Tremembé - SP", state: "SP" },
    { value: "Treviso - SC", text: "Treviso - SC", state: "SC" },
    { value: "Treze Tílias - SC", text: "Treze Tílias - SC", state: "SC" },
    { value: "Treze de Maio - SC", text: "Treze de Maio - SC", state: "SC" },
    { value: "Trindade - GO", text: "Trindade - GO", state: "GO" },
    { value: "Trindade - PE", text: "Trindade - PE", state: "PE" },
    { value: "Trindade do Sul - RS", text: "Trindade do Sul - RS", state: "RS" },
    { value: "Triunfo - PB", text: "Triunfo - PB", state: "PB" },
    { value: "Triunfo - PE", text: "Triunfo - PE", state: "PE" },
    { value: "Triunfo - RS", text: "Triunfo - RS", state: "RS" },
    { value: "Triunfo Potiguar - RN", text: "Triunfo Potiguar - RN", state: "RN" },
    { value: "Trizidela do Vale - MA", text: "Trizidela do Vale - MA", state: "MA" },
    { value: "Trombas - GO", text: "Trombas - GO", state: "GO" },
    { value: "Trombudo Central - SC", text: "Trombudo Central - SC", state: "SC" },
    { value: "Três Arroios - RS", text: "Três Arroios - RS", state: "RS" },
    { value: "Três Barras - SC", text: "Três Barras - SC", state: "SC" },
    { value: "Três Barras do Paraná - PR", text: "Três Barras do Paraná - PR", state: "PR" },
    { value: "Três Cachoeiras - RS", text: "Três Cachoeiras - RS", state: "RS" },
    { value: "Três Corações - MG", text: "Três Corações - MG", state: "MG" },
    { value: "Três Coroas - RS", text: "Três Coroas - RS", state: "RS" },
    { value: "Três Forquilhas - RS", text: "Três Forquilhas - RS", state: "RS" },
    { value: "Três Fronteiras - SP", text: "Três Fronteiras - SP", state: "SP" },
    { value: "Três Lagoas - MS", text: "Três Lagoas - MS", state: "MS" },
    { value: "Três Marias - MG", text: "Três Marias - MG", state: "MG" },
    { value: "Três Palmeiras - RS", text: "Três Palmeiras - RS", state: "RS" },
    { value: "Três Passos - RS", text: "Três Passos - RS", state: "RS" },
    { value: "Três Pontas - MG", text: "Três Pontas - MG", state: "MG" },
    { value: "Três Ranchos - GO", text: "Três Ranchos - GO", state: "GO" },
    { value: "Três Rios - RJ", text: "Três Rios - RJ", state: "RJ" },
    { value: "Três de Maio - RS", text: "Três de Maio - RS", state: "RS" },
    { value: "Tubarão - SC", text: "Tubarão - SC", state: "SC" },
    { value: "Tucano - BA", text: "Tucano - BA", state: "BA" },
    { value: "Tucumã - PA", text: "Tucumã - PA", state: "PA" },
    { value: "Tucunduva - RS", text: "Tucunduva - RS", state: "RS" },
    { value: "Tucuruí - PA", text: "Tucuruí - PA", state: "PA" },
    { value: "Tufilândia - MA", text: "Tufilândia - MA", state: "MA" },
    { value: "Tuiuti - SP", text: "Tuiuti - SP", state: "SP" },
    { value: "Tumiritinga - MG", text: "Tumiritinga - MG", state: "MG" },
    { value: "Tunas - RS", text: "Tunas - RS", state: "RS" },
    { value: "Tunas do Paraná - PR", text: "Tunas do Paraná - PR", state: "PR" },
    { value: "Tuneiras do Oeste - PR", text: "Tuneiras do Oeste - PR", state: "PR" },
    { value: "Tuntum - MA", text: "Tuntum - MA", state: "MA" },
    { value: "Tunápolis - SC", text: "Tunápolis - SC", state: "SC" },
    { value: "Tupaciguara - MG", text: "Tupaciguara - MG", state: "MG" },
    { value: "Tupanatinga - PE", text: "Tupanatinga - PE", state: "PE" },
    { value: "Tupanci do Sul - RS", text: "Tupanci do Sul - RS", state: "RS" },
    { value: "Tupanciretã - RS", text: "Tupanciretã - RS", state: "RS" },
    { value: "Tupandi - RS", text: "Tupandi - RS", state: "RS" },
    { value: "Tuparendi - RS", text: "Tuparendi - RS", state: "RS" },
    { value: "Tuparetama - PE", text: "Tuparetama - PE", state: "PE" },
    { value: "Tupi Paulista - SP", text: "Tupi Paulista - SP", state: "SP" },
    { value: "Tupirama - TO", text: "Tupirama - TO", state: "TO" },
    { value: "Tupiratins - TO", text: "Tupiratins - TO", state: "TO" },
    { value: "Tupã - SP", text: "Tupã - SP", state: "SP" },
    { value: "Tupãssi - PR", text: "Tupãssi - PR", state: "PR" },
    { value: "Turiaçu - MA", text: "Turiaçu - MA", state: "MA" },
    { value: "Turilândia - MA", text: "Turilândia - MA", state: "MA" },
    { value: "Turiúba - SP", text: "Turiúba - SP", state: "SP" },
    { value: "Turmalina - MG", text: "Turmalina - MG", state: "MG" },
    { value: "Turmalina - SP", text: "Turmalina - SP", state: "SP" },
    { value: "Tururu - CE", text: "Tururu - CE", state: "CE" },
    { value: "Turuçu - RS", text: "Turuçu - RS", state: "RS" },
    { value: "Turvelândia - GO", text: "Turvelândia - GO", state: "GO" },
    { value: "Turvo - PR", text: "Turvo - PR", state: "PR" },
    { value: "Turvo - SC", text: "Turvo - SC", state: "SC" },
    { value: "Turvolândia - MG", text: "Turvolândia - MG", state: "MG" },
    { value: "Turvânia - GO", text: "Turvânia - GO", state: "GO" },
    { value: "Tutóia - MA", text: "Tutóia - MA", state: "MA" },
    { value: "Uarini - AM", text: "Uarini - AM", state: "AM" },
    { value: "Uauá - BA", text: "Uauá - BA", state: "BA" },
    { value: "Ubaitaba - BA", text: "Ubaitaba - BA", state: "BA" },
    { value: "Ubajara - CE", text: "Ubajara - CE", state: "CE" },
    { value: "Ubaporanga - MG", text: "Ubaporanga - MG", state: "MG" },
    { value: "Ubarana - SP", text: "Ubarana - SP", state: "SP" },
    { value: "Ubatuba - SP", text: "Ubatuba - SP", state: "SP" },
    { value: "Ubatã - BA", text: "Ubatã - BA", state: "BA" },
    { value: "Ubaí - MG", text: "Ubaí - MG", state: "MG" },
    { value: "Ubaíra - BA", text: "Ubaíra - BA", state: "BA" },
    { value: "Uberaba - MG", text: "Uberaba - MG", state: "MG" },
    { value: "Uberlândia - MG", text: "Uberlândia - MG", state: "MG" },
    { value: "Ubirajara - SP", text: "Ubirajara - SP", state: "SP" },
    { value: "Ubiratã - PR", text: "Ubiratã - PR", state: "PR" },
    { value: "Ubiretama - RS", text: "Ubiretama - RS", state: "RS" },
    { value: "Ubá - MG", text: "Ubá - MG", state: "MG" },
    { value: "Uchoa - SP", text: "Uchoa - SP", state: "SP" },
    { value: "Uibaí - BA", text: "Uibaí - BA", state: "BA" },
    { value: "Uiramutã - RR", text: "Uiramutã - RR", state: "RR" },
    { value: "Uirapuru - GO", text: "Uirapuru - GO", state: "GO" },
    { value: "Uiraúna - PB", text: "Uiraúna - PB", state: "PB" },
    { value: "Ulianópolis - PA", text: "Ulianópolis - PA", state: "PA" },
    { value: "Umari - CE", text: "Umari - CE", state: "CE" },
    { value: "Umarizal - RN", text: "Umarizal - RN", state: "RN" },
    { value: "Umbaúba - SE", text: "Umbaúba - SE", state: "SE" },
    { value: "Umburanas - BA", text: "Umburanas - BA", state: "BA" },
    { value: "Umburatiba - MG", text: "Umburatiba - MG", state: "MG" },
    { value: "Umbuzeiro - PB", text: "Umbuzeiro - PB", state: "PB" },
    { value: "Umirim - CE", text: "Umirim - CE", state: "CE" },
    { value: "Umuarama - PR", text: "Umuarama - PR", state: "PR" },
    { value: "Una - BA", text: "Una - BA", state: "BA" },
    { value: "Unaí - MG", text: "Unaí - MG", state: "MG" },
    { value: "Uniflor - PR", text: "Uniflor - PR", state: "PR" },
    { value: "Unistalda - RS", text: "Unistalda - RS", state: "RS" },
    { value: "União - PI", text: "União - PI", state: "PI" },
    { value: "União Paulista - SP", text: "União Paulista - SP", state: "SP" },
    { value: "União da Serra - RS", text: "União da Serra - RS", state: "RS" },
    { value: "União da Vitória - PR", text: "União da Vitória - PR", state: "PR" },
    { value: "União de Minas - MG", text: "União de Minas - MG", state: "MG" },
    { value: "União do Oeste - SC", text: "União do Oeste - SC", state: "SC" },
    { value: "União do Sul - MT", text: "União do Sul - MT", state: "MT" },
    { value: "União dos Palmares - AL", text: "União dos Palmares - AL", state: "AL" },
    { value: "Upanema - RN", text: "Upanema - RN", state: "RN" },
    { value: "Urandi - BA", text: "Urandi - BA", state: "BA" },
    { value: "Uraí - PR", text: "Uraí - PR", state: "PR" },
    { value: "Urbano Santos - MA", text: "Urbano Santos - MA", state: "MA" },
    { value: "Uru - SP", text: "Uru - SP", state: "SP" },
    { value: "Uruana - GO", text: "Uruana - GO", state: "GO" },
    { value: "Uruana de Minas - MG", text: "Uruana de Minas - MG", state: "MG" },
    { value: "Uruará - PA", text: "Uruará - PA", state: "PA" },
    { value: "Uruaçu - GO", text: "Uruaçu - GO", state: "GO" },
    { value: "Urubici - SC", text: "Urubici - SC", state: "SC" },
    { value: "Uruburetama - CE", text: "Uruburetama - CE", state: "CE" },
    { value: "Urucará - AM", text: "Urucará - AM", state: "AM" },
    { value: "Urucuia - MG", text: "Urucuia - MG", state: "MG" },
    { value: "Urucurituba - AM", text: "Urucurituba - AM", state: "AM" },
    { value: "Urucânia - MG", text: "Urucânia - MG", state: "MG" },
    { value: "Uruguaiana - RS", text: "Uruguaiana - RS", state: "RS" },
    { value: "Uruoca - CE", text: "Uruoca - CE", state: "CE" },
    { value: "Urupema - SC", text: "Urupema - SC", state: "SC" },
    { value: "Urupá - RO", text: "Urupá - RO", state: "RO" },
    { value: "Urupês - SP", text: "Urupês - SP", state: "SP" },
    { value: "Urussanga - SC", text: "Urussanga - SC", state: "SC" },
    { value: "Urutaí - GO", text: "Urutaí - GO", state: "GO" },
    { value: "Uruçuca - BA", text: "Uruçuca - BA", state: "BA" },
    { value: "Uruçuí - PI", text: "Uruçuí - PI", state: "PI" },
    { value: "Urânia - SP", text: "Urânia - SP", state: "SP" },
    { value: "Utinga - BA", text: "Utinga - BA", state: "BA" },
    { value: "Vacaria - RS", text: "Vacaria - RS", state: "RS" },
    { value: "Vale Real - RS", text: "Vale Real - RS", state: "RS" },
    { value: "Vale Verde - RS", text: "Vale Verde - RS", state: "RS" },
    { value: "Vale de São Domingos - MT", text: "Vale de São Domingos - MT", state: "MT" },
    { value: "Vale do Anari - RO", text: "Vale do Anari - RO", state: "RO" },
    { value: "Vale do Paraíso - RO", text: "Vale do Paraíso - RO", state: "RO" },
    { value: "Vale do Sol - RS", text: "Vale do Sol - RS", state: "RS" },
    { value: "Valente - BA", text: "Valente - BA", state: "BA" },
    { value: "Valentim Gentil - SP", text: "Valentim Gentil - SP", state: "SP" },
    { value: "Valença - BA", text: "Valença - BA", state: "BA" },
    { value: "Valença - RJ", text: "Valença - RJ", state: "RJ" },
    { value: "Valença do Piauí - PI", text: "Valença do Piauí - PI", state: "PI" },
    { value: "Valinhos - SP", text: "Valinhos - SP", state: "SP" },
    { value: "Valparaíso - SP", text: "Valparaíso - SP", state: "SP" },
    { value: "Valparaíso de Goiás - GO", text: "Valparaíso de Goiás - GO", state: "GO" },
    { value: "Vanini - RS", text: "Vanini - RS", state: "RS" },
    { value: "Vargem - SC", text: "Vargem - SC", state: "SC" },
    { value: "Vargem - SP", text: "Vargem - SP", state: "SP" },
    { value: "Vargem Alegre - MG", text: "Vargem Alegre - MG", state: "MG" },
    { value: "Vargem Alta - ES", text: "Vargem Alta - ES", state: "ES" },
    { value: "Vargem Bonita - MG", text: "Vargem Bonita - MG", state: "MG" },
    { value: "Vargem Bonita - SC", text: "Vargem Bonita - SC", state: "SC" },
    { value: "Vargem Grande - MA", text: "Vargem Grande - MA", state: "MA" },
    { value: "Vargem Grande Paulista - SP", text: "Vargem Grande Paulista - SP", state: "SP" },
    { value: "Vargem Grande do Rio Pardo - MG", text: "Vargem Grande do Rio Pardo - MG", state: "MG" },
    { value: "Vargem Grande do Sul - SP", text: "Vargem Grande do Sul - SP", state: "SP" },
    { value: "Vargeão - SC", text: "Vargeão - SC", state: "SC" },
    { value: "Varginha - MG", text: "Varginha - MG", state: "MG" },
    { value: "Varjota - CE", text: "Varjota - CE", state: "CE" },
    { value: "Varjão - DF", text: "Varjão - DF", state: "DF" },
    { value: "Varjão - GO", text: "Varjão - GO", state: "GO" },
    { value: "Varjão de Minas - MG", text: "Varjão de Minas - MG", state: "MG" },
    { value: "Varre-Sai - RJ", text: "Varre-Sai - RJ", state: "RJ" },
    { value: "Varzedo - BA", text: "Varzedo - BA", state: "BA" },
    { value: "Varzelândia - MG", text: "Varzelândia - MG", state: "MG" },
    { value: "Vassouras - RJ", text: "Vassouras - RJ", state: "RJ" },
    { value: "Vazante - MG", text: "Vazante - MG", state: "MG" },
    { value: "Venda Nova do Imigrante - ES", text: "Venda Nova do Imigrante - ES", state: "ES" },
    { value: "Venha-Ver - RN", text: "Venha-Ver - RN", state: "RN" },
    { value: "Ventania - PR", text: "Ventania - PR", state: "PR" },
    { value: "Venturosa - PE", text: "Venturosa - PE", state: "PE" },
    { value: "Venâncio Aires - RS", text: "Venâncio Aires - RS", state: "RS" },
    { value: "Vera - MT", text: "Vera - MT", state: "MT" },
    { value: "Vera Cruz - BA", text: "Vera Cruz - BA", state: "BA" },
    { value: "Vera Cruz - RN", text: "Vera Cruz - RN", state: "RN" },
    { value: "Vera Cruz - RS", text: "Vera Cruz - RS", state: "RS" },
    { value: "Vera Cruz - SP", text: "Vera Cruz - SP", state: "SP" },
    { value: "Vera Cruz do Oeste - PR", text: "Vera Cruz do Oeste - PR", state: "PR" },
    { value: "Vera Mendes - PI", text: "Vera Mendes - PI", state: "PI" },
    { value: "Veranópolis - RS", text: "Veranópolis - RS", state: "RS" },
    { value: "Verdejante - PE", text: "Verdejante - PE", state: "PE" },
    { value: "Verdelândia - MG", text: "Verdelândia - MG", state: "MG" },
    { value: "Vereda - BA", text: "Vereda - BA", state: "BA" },
    { value: "Veredinha - MG", text: "Veredinha - MG", state: "MG" },
    { value: "Vermelho Novo - MG", text: "Vermelho Novo - MG", state: "MG" },
    { value: "Vertente do Lério - PE", text: "Vertente do Lério - PE", state: "PE" },
    { value: "Vertentes - PE", text: "Vertentes - PE", state: "PE" },
    { value: "Verê - PR", text: "Verê - PR", state: "PR" },
    { value: "Veríssimo - MG", text: "Veríssimo - MG", state: "MG" },
    { value: "Vespasiano - MG", text: "Vespasiano - MG", state: "MG" },
    { value: "Vespasiano Correa - RS", text: "Vespasiano Correa - RS", state: "RS" },
    { value: "Viadutos - RS", text: "Viadutos - RS", state: "RS" },
    { value: "Viamão - RS", text: "Viamão - RS", state: "RS" },
    { value: "Viana - ES", text: "Viana - ES", state: "ES" },
    { value: "Viana - MA", text: "Viana - MA", state: "MA" },
    { value: "Vianópolis - GO", text: "Vianópolis - GO", state: "GO" },
    { value: "Vicente Dutra - RS", text: "Vicente Dutra - RS", state: "RS" },
    { value: "Vicente Pires - DF", text: "Vicente Pires - DF", state: "DF" },
    { value: "Vicentina - MS", text: "Vicentina - MS", state: "MS" },
    { value: "Vicentinópolis - GO", text: "Vicentinópolis - GO", state: "GO" },
    { value: "Victor Graeff - RS", text: "Victor Graeff - RS", state: "RS" },
    { value: "Vicência - PE", text: "Vicência - PE", state: "PE" },
    { value: "Vidal Ramos - SC", text: "Vidal Ramos - SC", state: "SC" },
    { value: "Videira - SC", text: "Videira - SC", state: "SC" },
    { value: "Vieiras - MG", text: "Vieiras - MG", state: "MG" },
    { value: "Vieirópolis - PB", text: "Vieirópolis - PB", state: "PB" },
    { value: "Vigia - PA", text: "Vigia - PA", state: "PA" },
    { value: "Vila Alta - PR", text: "Vila Alta - PR", state: "PR" },
    { value: "Vila Bela da Santíssima Trindade - MT", text: "Vila Bela da Santíssima Trindade - MT", state: "MT" },
    { value: "Vila Boa - GO", text: "Vila Boa - GO", state: "GO" },
    { value: "Vila Flor - RN", text: "Vila Flor - RN", state: "RN" },
    { value: "Vila Flores - RS", text: "Vila Flores - RS", state: "RS" },
    { value: "Vila Lângaro - RS", text: "Vila Lângaro - RS", state: "RS" },
    { value: "Vila Maria - RS", text: "Vila Maria - RS", state: "RS" },
    { value: "Vila Nova do Piauí - PI", text: "Vila Nova do Piauí - PI", state: "PI" },
    { value: "Vila Nova do Sul - RS", text: "Vila Nova do Sul - RS", state: "RS" },
    { value: "Vila Nova dos Martírios - MA", text: "Vila Nova dos Martírios - MA", state: "MA" },
    { value: "Vila Pavão - ES", text: "Vila Pavão - ES", state: "ES" },
    { value: "Vila Propício - GO", text: "Vila Propício - GO", state: "GO" },
    { value: "Vila Rica - MT", text: "Vila Rica - MT", state: "MT" },
    { value: "Vila Valério - ES", text: "Vila Valério - ES", state: "ES" },
    { value: "Vila Velha - ES", text: "Vila Velha - ES", state: "ES" },
    { value: "Vilhena - RO", text: "Vilhena - RO", state: "RO" },
    { value: "Vinhedo - SP", text: "Vinhedo - SP", state: "SP" },
    { value: "Viradouro - SP", text: "Viradouro - SP", state: "SP" },
    { value: "Virgem da Lapa - MG", text: "Virgem da Lapa - MG", state: "MG" },
    { value: "Virginópolis - MG", text: "Virginópolis - MG", state: "MG" },
    { value: "Virgolândia - MG", text: "Virgolândia - MG", state: "MG" },
    { value: "Virgínia - MG", text: "Virgínia - MG", state: "MG" },
    { value: "Virmond - PR", text: "Virmond - PR", state: "PR" },
    { value: "Visconde do Rio Branco - MG", text: "Visconde do Rio Branco - MG", state: "MG" },
    { value: "Viseu - PA", text: "Viseu - PA", state: "PA" },
    { value: "Vista Alegre - RS", text: "Vista Alegre - RS", state: "RS" },
    { value: "Vista Alegre do Alto - SP", text: "Vista Alegre do Alto - SP", state: "SP" },
    { value: "Vista Alegre do Prata - RS", text: "Vista Alegre do Prata - RS", state: "RS" },
    { value: "Vista Gaúcha - RS", text: "Vista Gaúcha - RS", state: "RS" },
    { value: "Vista Serrana - PB", text: "Vista Serrana - PB", state: "PB" },
    { value: "Vitor Meireles - SC", text: "Vitor Meireles - SC", state: "SC" },
    { value: "Vitorino - PR", text: "Vitorino - PR", state: "PR" },
    { value: "Vitorino Freire - MA", text: "Vitorino Freire - MA", state: "MA" },
    { value: "Vitória - ES", text: "Vitória - ES", state: "ES" },
    { value: "Vitória Brasil - SP", text: "Vitória Brasil - SP", state: "SP" },
    { value: "Vitória da Conquista - BA", text: "Vitória da Conquista - BA", state: "BA" },
    { value: "Vitória das Missões - RS", text: "Vitória das Missões - RS", state: "RS" },
    { value: "Vitória de Santo Antão - PE", text: "Vitória de Santo Antão - PE", state: "PE" },
    { value: "Vitória do Jari - AP", text: "Vitória do Jari - AP", state: "AP" },
    { value: "Vitória do Mearim - MA", text: "Vitória do Mearim - MA", state: "MA" },
    { value: "Vitória do Xingu - PA", text: "Vitória do Xingu - PA", state: "PA" },
    { value: "Viçosa - AL", text: "Viçosa - AL", state: "AL" },
    { value: "Viçosa - MG", text: "Viçosa - MG", state: "MG" },
    { value: "Viçosa - RN", text: "Viçosa - RN", state: "RN" },
    { value: "Viçosa do Ceará - CE", text: "Viçosa do Ceará - CE", state: "CE" },
    { value: "Volta Grande - MG", text: "Volta Grande - MG", state: "MG" },
    { value: "Volta Redonda - RJ", text: "Volta Redonda - RJ", state: "RJ" },
    { value: "Votorantim - SP", text: "Votorantim - SP", state: "SP" },
    { value: "Votuporanga - SP", text: "Votuporanga - SP", state: "SP" },
    { value: "Várzea - PB", text: "Várzea - PB", state: "PB" },
    { value: "Várzea - RN", text: "Várzea - RN", state: "RN" },
    { value: "Várzea Alegre - CE", text: "Várzea Alegre - CE", state: "CE" },
    { value: "Várzea Branca - PI", text: "Várzea Branca - PI", state: "PI" },
    { value: "Várzea Grande - MT", text: "Várzea Grande - MT", state: "MT" },
    { value: "Várzea Grande - PI", text: "Várzea Grande - PI", state: "PI" },
    { value: "Várzea Nova - BA", text: "Várzea Nova - BA", state: "BA" },
    { value: "Várzea Paulista - SP", text: "Várzea Paulista - SP", state: "SP" },
    { value: "Várzea da Palma - MG", text: "Várzea da Palma - MG", state: "MG" },
    { value: "Várzea da Roça - BA", text: "Várzea da Roça - BA", state: "BA" },
    { value: "Várzea do Poço - BA", text: "Várzea do Poço - BA", state: "BA" },
    { value: "Wagner - BA", text: "Wagner - BA", state: "BA" },
    { value: "Wall Ferraz - PI", text: "Wall Ferraz - PI", state: "PI" },
    { value: "Wanderley - BA", text: "Wanderley - BA", state: "BA" },
    { value: "Wanderlândia - TO", text: "Wanderlândia - TO", state: "TO" },
    { value: "Wenceslau Braz - MG", text: "Wenceslau Braz - MG", state: "MG" },
    { value: "Wenceslau Braz - PR", text: "Wenceslau Braz - PR", state: "PR" },
    { value: "Wenceslau Guimarães - BA", text: "Wenceslau Guimarães - BA", state: "BA" },
    { value: "Westfália - RS", text: "Westfália - RS", state: "RS" },
    { value: "Witmarsum - SC", text: "Witmarsum - SC", state: "SC" },
    { value: "Xambioá - TO", text: "Xambioá - TO", state: "TO" },
    { value: "Xambrê - PR", text: "Xambrê - PR", state: "PR" },
    { value: "Xangri-lá - RS", text: "Xangri-lá - RS", state: "RS" },
    { value: "Xanxerê - SC", text: "Xanxerê - SC", state: "SC" },
    { value: "Xapuri - AC", text: "Xapuri - AC", state: "AC" },
    { value: "Xavantina - SC", text: "Xavantina - SC", state: "SC" },
    { value: "Xaxim - SC", text: "Xaxim - SC", state: "SC" },
    { value: "Xexéu - PE", text: "Xexéu - PE", state: "PE" },
    { value: "Xinguara - PA", text: "Xinguara - PA", state: "PA" },
    { value: "Xique-Xique - BA", text: "Xique-Xique - BA", state: "BA" },
    { value: "Zabelê - PB", text: "Zabelê - PB", state: "PB" },
    { value: "Zacarias - SP", text: "Zacarias - SP", state: "SP" },
    { value: "Zortéa - SC", text: "Zortéa - SC", state: "SC" },
    { value: "Zé Doca - MA", text: "Zé Doca - MA", state: "MA" },
    { value: "Água Azul do Norte - PA", text: "Água Azul do Norte - PA", state: "PA" },
    { value: "Água Boa - MG", text: "Água Boa - MG", state: "MG" },
    { value: "Água Boa - MT", text: "Água Boa - MT", state: "MT" },
    { value: "Água Branca - AL", text: "Água Branca - AL", state: "AL" },
    { value: "Água Branca - PB", text: "Água Branca - PB", state: "PB" },
    { value: "Água Branca - PI", text: "Água Branca - PI", state: "PI" },
    { value: "Água Clara - MS", text: "Água Clara - MS", state: "MS" },
    { value: "Água Comprida - MG", text: "Água Comprida - MG", state: "MG" },
    { value: "Água Doce - SC", text: "Água Doce - SC", state: "SC" },
    { value: "Água Doce do Maranhão - MA", text: "Água Doce do Maranhão - MA", state: "MA" },
    { value: "Água Doce do Norte - ES", text: "Água Doce do Norte - ES", state: "ES" },
    { value: "Água Fria - BA", text: "Água Fria - BA", state: "BA" },
    { value: "Água Fria de Goiás - GO", text: "Água Fria de Goiás - GO", state: "GO" },
    { value: "Água Limpa - GO", text: "Água Limpa - GO", state: "GO" },
    { value: "Água Nova - RN", text: "Água Nova - RN", state: "RN" },
    { value: "Água Preta - PE", text: "Água Preta - PE", state: "PE" },
    { value: "Água Santa - RS", text: "Água Santa - RS", state: "RS" },
    { value: "Águas Belas - PE", text: "Águas Belas - PE", state: "PE" },
    { value: "Águas Claras - DF", text: "Águas Claras - DF", state: "DF" },
    { value: "Águas Formosas - MG", text: "Águas Formosas - MG", state: "MG" },
    { value: "Águas Frias - SC", text: "Águas Frias - SC", state: "SC" },
    { value: "Águas Lindas de Goiás - GO", text: "Águas Lindas de Goiás - GO", state: "GO" },
    { value: "Águas Mornas - SC", text: "Águas Mornas - SC", state: "SC" },
    { value: "Águas Vermelhas - MG", text: "Águas Vermelhas - MG", state: "MG" },
    { value: "Águas da Prata - SP", text: "Águas da Prata - SP", state: "SP" },
    { value: "Águas de Chapecó - SC", text: "Águas de Chapecó - SC", state: "SC" },
    { value: "Águas de Lindóia - SP", text: "Águas de Lindóia - SP", state: "SP" },
    { value: "Águas de Santa Bárbara - SP", text: "Águas de Santa Bárbara - SP", state: "SP" },
    { value: "Águas de São Pedro - SP", text: "Águas de São Pedro - SP", state: "SP" },
    { value: "Águia Branca - ES", text: "Águia Branca - ES", state: "ES" },
    { value: "Álvares Florence - SP", text: "Álvares Florence - SP", state: "SP" },
    { value: "Álvares Machado - SP", text: "Álvares Machado - SP", state: "SP" },
    { value: "Álvaro de Carvalho - SP", text: "Álvaro de Carvalho - SP", state: "SP" },
    { value: "Áurea - RS", text: "Áurea - RS", state: "RS" },
    { value: "Ângulo - PR", text: "Ângulo - PR", state: "PR" },
    { value: "Érico Cardoso - BA", text: "Érico Cardoso - BA", state: "BA" },
    { value: "Óbidos - PA", text: "Óbidos - PA", state: "PA" },
    { value: "Óleo - SP", text: "Óleo - SP", state: "SP" }
]

export default cities;