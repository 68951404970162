import classNames from "classnames";
import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getFilterMonth } from "../../../../common/data/calendar";
import CashFlowAccountTypeEnum from "../../../../common/data/enum/enumCashFlowAccountType";
import CashFlowEntryStatusEnum from "../../../../common/data/enum/enumCashFlowEntryStatus";
import enumOperation from "../../../../common/data/enum/enumOperation";
import { ICashFlowAccount } from "../../../../common/data/financial/cashFlowAccount";
import { ICashFlowEntry } from "../../../../common/data/financial/cashFlowEntries";
import { ICashFlowEntryResult } from "../../../../common/data/financial/cashFlowEntryResult";
import { ICashFlowResult } from "../../../../common/data/financial/cashFlowResult";
import "../../../../common/data/financial/request/cashFlowEntryRQ";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Alert, { AlertHeading } from "../../../../components/bootstrap/Alert";
import Badge from "../../../../components/bootstrap/Badge";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Label from "../../../../components/bootstrap/forms/Label";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC, POST_ASYNC, PUT_ASYNC } from "../../../../components/extras/HttpHelper";
import { showCreateError, showCreateSuccess, showDeleteError, showDeleteSuccess, showSuccess, showUpdateError, showUpdateSuccess, showWarning } from "../../../../components/extras/Notifications";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";
import cashFlowAccountService, { IGetCashFlowAccountResponse } from '../../../../services/cash-flow-account-service';
import cashFlowEntryService, { IGetCashFlowEntryResultResponse } from '../../../../services/cash-flow-entry-service';
import paymentTypeService, { IGetPaymentTypesResponse } from '../../../../services/payment-type-service';
import LoadingPage from "../../loading/LoadingPage";

const CommonCashFlowEntriesList: FC = () => {

    const { darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [filterMenu, setFilterMenu] = useState<boolean>(false);

    const [modalTitle, setModalTitle] = useState('');
    const [cashFlowEntryOffcanvas, setCashFlowEntryOffcanvas] = useState(false);
    const [operation, setOperation] = useState<enumOperation>();

    const [cashFlowAccounts, setCashFlowAccounts] = useState<ICashFlowAccount[]>([]);
    const [cashFlowEntries, setCashFlowEntries] = useState<ICashFlowEntry[]>([]);
    const [cashFlowEntryResult, setCashFlowEntryResult] = useState<ICashFlowEntryResult>();
    const [date, setDate] = useState(new Date);

    const [cashierOffcanvas, setCashierOffcanvas] = useState(false);

    const [cashFlowResult, setCashFlowResult] = useState<ICashFlowResult>();
    const [closedCashier, setClosedCashier] = useState<boolean | undefined>(false);
    const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);

    const [entryDateLabel, setEntryDateLabel] = useState<string>('Data do Lançamento');

    const filterMonth = getFilterMonth();

    const formik = useFormik({
        initialValues: {
            id: undefined,
            date: '',
            cashFlowAccountId: '',
            value: '',
            paymentType: '',
            paymentTypeId: '',
            observation: '',
            paymentStatus: '1',
            status: true,
            creationDate: null,
            lastUpdate: null,
            inactivationDate: null,
            cashFlowEntryId: ''
        },
        validate: (values) => {
            const errors: {
                cashFlowAccountId?: string,
                date?: string,
                value?: string,
                paymentTypeId?: string,
                paymentStatus?: string,
            } = {};

            if (!values.cashFlowAccountId)
                errors.cashFlowAccountId = 'Campo obrigatório';

            if (!values.date)
                errors.date = 'Campo obrigatório';

            if (!values.value)
                errors.value = 'Campo obrigatório';

            if (!values.paymentTypeId)
                errors.paymentTypeId = 'Campo obrigatório';

            if (!values.paymentStatus)
                errors.paymentStatus = 'Campo obrigatório';

            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            saveCashFlowEntry(values);
            resetForm();
        },
        validateOnChange: false,
    });

    const formikFilter = useFormik({
        initialValues: {
            monthFilter: '',
            yearFilter: '',
        },

        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {

            getCashFlowEntryResult();

            setFilterMenu(false);
        }
    });

    const formikCashier = useFormik({
        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            return undefined;
        },
        initialValues: {
            cashierId: '',
            cashierValue: '',
            cashierReference: ''
        },
        validate: (values) => {
            const errors: { cashierValue?: string; } = {};

            if (!values.cashierValue) {
                errors.cashierValue = 'Campo obrigatório';
            }

            return errors;
        },
        validateOnChange: true,
    });

    useEffect(() => {

        onLoad();

    }, []);

    const onLoad = async () => {

        formikFilter.values.monthFilter = `${date.getMonth() + 1}`;
        formikFilter.values.yearFilter = `${date.getFullYear()}`;

        const reference = getReference();

        const promises = []
        promises.push(cashFlowAccountService.getActiveCashFlowAccount());
        promises.push(cashFlowEntryService.getCashFlowEntryResult(reference));
        promises.push(paymentTypeService.getActivePaymentTypes());

        setIsLoading(true);

        const [getcashFlowAccountResponse, getcashFlowEntryResultResponse, getActivePaymentTypesResponse] =
            await Promise.all(promises) as [IGetCashFlowAccountResponse, IGetCashFlowEntryResultResponse, IGetPaymentTypesResponse];

        setIsLoading(false);

        if (getcashFlowAccountResponse.error) {
            showWarning(getcashFlowAccountResponse.error);
            return;
        }

        if (getcashFlowEntryResultResponse.error) {
            showWarning(getcashFlowEntryResultResponse.error);
            return;
        }

        if (getActivePaymentTypesResponse.error) {
            showWarning(getActivePaymentTypesResponse.error);
            return;
        }

        setCashFlowAccounts(getcashFlowAccountResponse.cashFlowAccounts || []);
        setCashFlowEntries(getcashFlowEntryResultResponse.cashFlowEntryResult?.cashFlowEntries || []);
        setCashFlowEntryResult(getcashFlowEntryResultResponse.cashFlowEntryResult || undefined);
        setPaymentTypes(getActivePaymentTypesResponse.paymentTypes || []);

        setClosedCashier(getcashFlowEntryResultResponse.cashFlowEntryResult?.cashFlowResult?.isClosed);
    }

    const getReference = () => {
        return `${formikFilter.values.monthFilter}-${formikFilter.values.yearFilter}`;
    }

    const handleCashFlowEntryOffcanvas = () => {
        setCashFlowEntryOffcanvas(!cashFlowEntryOffcanvas);
        resetForm();
        setModalTitle('Criar Lançamento');
        setOperation(enumOperation.Create);
    };

    const resetForm = () => {
        formik.resetForm({
            values: {

                id: undefined,
                date: '',
                cashFlowAccountId: '',
                value: '',
                paymentType: '',
                paymentTypeId: '',
                observation: '',
                paymentStatus: '1',
                status: true,
                creationDate: null,
                lastUpdate: null,
                inactivationDate: null,
                cashFlowEntryId: ''
            },
        })
    }

    //Entradas
    const saveCashFlowEntry = async (values: any) => {

        if (formik.values.id) {
            await updateCashFlowEntry(values)
        } else {
            await createCashFlowEntry(values);
        }
    }

    const createCashFlowEntry = async (values: any) => {

        const request = {
            cashFlowAccountId: values.cashFlowAccountId,
            date: values.date,
            value: values.value,
            paymentTypeId: values.paymentTypeId,
            paymentStatus: values.paymentStatus,
            observation: values.observation,

        };

        setIsSubmitting(true);

        const response = await cashFlowEntryService.createCashFlowEntry({
            cashFlowAccountId: request.cashFlowAccountId,
            date: request.date,
            value: request.value,
            paymentTypeId: request.paymentTypeId,
            paymentStatus: request.paymentStatus,
            observation: request.observation

        });

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setCashFlowEntryOffcanvas(false);
            getCashFlowEntryResult();

            showCreateSuccess();
        } else {
            showCreateError();
        }
    };

    const updateCashFlowEntry = async (values: any) => {

        const request = {
            id: values.id,
            cashFlowAccountId: values.cashFlowAccountId,
            date: values.date,
            value: values.value,
            paymentTypeId: values.paymentTypeId,
            paymentStatus: values.paymentStatus,
            observation: values.observation,
            status: values.status,
            creationDate: values.creationDate,
            lastUpdate: values.lastUpdate,
            inactivationDate: values.inactivationDate,
            cashFlowEntryId: values.cashFlowEntryId
        };

        setIsSubmitting(true);

        const response = await cashFlowEntryService.updateCashFlowEntry(request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setCashFlowEntryOffcanvas(false);
            getCashFlowEntryResult();

            showUpdateSuccess();
        } else {
            showUpdateError();
        }
    }

    const deleteCashFlowEntry = async (cashFlowEntryId: string) => {

        setIsSubmitting(true);

        const response = await cashFlowEntryService.deleteCashFlowEntry(cashFlowEntryId);

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setCashFlowEntryOffcanvas(false);
            getCashFlowEntryResult();

            showDeleteSuccess();
        } else {
            showDeleteError();
        }
    }

    const getCashFlowEntryResult = async () => {

        const reference = getReference();

        setIsLoading(true);

        const { cashFlowEntryResult, error, success } = await cashFlowEntryService.getCashFlowEntryResult(reference);

        setIsLoading(false);

        if (success) {
            setCashFlowEntryResult(cashFlowEntryResult);
            setCashFlowEntries(cashFlowEntryResult?.cashFlowEntries || [])

            setClosedCashier(cashFlowEntryResult?.cashFlowResult?.isClosed);
        } else {
            showWarning(error || 'Falha ao recuperar resultados');
        }
    }

    const getCashFlowEntryById = async (id: string) => {

        setOperation(enumOperation.Update);

        setIsLoading(true);

        const { cashFlowEntry, error, success } = await cashFlowEntryService.getCashFlowEntryById(id);

        setIsLoading(false);

        if (success) {
            resetForm();
            setFieldValues(cashFlowEntry);

            setModalTitle('Alterar Lançamento');
            setCashFlowEntryOffcanvas(!cashFlowEntryOffcanvas);
        } else {
            showWarning(error || 'Falha ao recuperar lançamento');
        }
    }

    const setFieldValues = (values: any) => {

        formik.setFieldValue('id', values.id);
        formik.setFieldValue('date', dayjs(values.date).format('YYYY-MM-DD'));
        formik.setFieldValue('cashFlowAccountId', values.cashFlowAccountId);
        formik.setFieldValue('value', values.value);
        formik.setFieldValue('paymentTypeId', values.paymentTypeId);
        formik.setFieldValue('observation', values.observation);
        formik.setFieldValue('paymentStatus', values.paymentStatus !== '' ? values.paymentStatus.toString() : '');
        formik.setFieldValue('cashFlowEntryId', values.cashFlowEntryId);

        formik.setFieldValue('status', values.status);
        formik.setFieldValue('creationDate', values.creationDate);
        formik.setFieldValue('lastUpdate', values.lastUpdate);
        formik.setFieldValue('inactivationDate', values.inactivationDate);
    }

    const handlePaymentTypeOnChange = (id: string) => {
        const selectedPaymentType = paymentTypes.find((paymentType) => paymentType.id === id);
        if (selectedPaymentType) {

            if(selectedPaymentType.entryType === '0') {
                setEntryDateLabel('Data do Lançamento');
            } else {
                setEntryDateLabel('Data de Referência');
            }
        }
    }

    //Resultado

    const handleCashierOffcanvas = () => {
        setCashierOffcanvas(!cashierOffcanvas);
        resetCashierForm();

        getCurrentCashFlowResult();
    };

    const resetCashierForm = () => {
        formikCashier.values.cashierId = '';
        formikCashier.values.cashierValue = '';
    }

    const getCurrentCashFlowResult = async () => {
        const reference = getReference();

        setIsLoading(true);

        const response = await GET_ASYNC(`api/NCashFlowResult/GetCurrent/${reference}`);

        setIsLoading(false);

        if (response && response.success) {
            setCashFlowResult(response.entity);

            if (response.entity) {
                const cashierValue = response.entity.isClosed ? response.entity.result : response.entity.cashier;
                formikCashier.setFieldValue('cashierValue', cashierValue);

                if (reference === response.entity.reference) {
                    formikCashier.setFieldValue('cashierId', response.entity.id);
                }

            } else
                formikCashier.setFieldValue('cashierValue', 0);

        } else {
            showWarning(response.message);
        }
    }

    const saveCashier = async () => {
        if (formikCashier.values.cashierId) {
            await updateCashier()
        } else {
            await createCashier();
        }

    }

    const createCashier = async () => {
        const reference = getReference();

        const request = {
            cashier: formikCashier.values.cashierValue,
            reference: reference,
        };

        setIsSubmitting(true);

        const response = await POST_ASYNC(`api/NCashFlowResult/Create`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetCashierForm();
            setCashierOffcanvas(false);
            getCashFlowEntryResult();

            showCreateSuccess();
        } else {
            showCreateError();
        }
    }

    const updateCashier = async () => {

        const reference = getReference();

        const request = {
            id: formikCashier.values.cashierId,
            cashier: formikCashier.values.cashierValue,
            result: cashFlowResult?.result,
            isClosed: cashFlowResult?.isClosed,
            reference: reference,
            status: cashFlowResult?.status,
            creationDate: cashFlowResult?.creationDate,
            lastUpdate: cashFlowResult?.lastUpdate,
            inactivationDate: cashFlowResult?.inactivationDate,
        };

        setIsSubmitting(true);

        const response = await PUT_ASYNC(`api/NCashFlowResult/Update`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetCashierForm();
            setCashierOffcanvas(false);
            getCashFlowEntryResult();

            showUpdateSuccess();
        } else {
            showWarning(response.message);
        }
    }

    const finalizeCashier = async () => {

        const reference = getReference();

        const request = {
            reference: reference,
            result: cashFlowEntryResult?.result
        };

        setIsSubmitting(true);

        const response = await POST_ASYNC(`api/NCashFlowResult/Finalize`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetCashierForm();
            getCashFlowEntryResult();

            showSuccess('Caixa finalizado com sucesso');
        } else {
            showWarning(response.message);
        }
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>
                        <div className="row">

                            {
                                closedCashier && (
                                    <Alert
                                        icon='PointOfSale'
                                        isLight
                                        color='warning'
                                        borderWidth={0}
                                        className='shadow-warning'
                                    >
                                        <AlertHeading tag='h2' className='h5'>
                                            Atenção!
                                        </AlertHeading>
                                        <p className='h6'>
                                            Caixa já fechado.
                                        </p>
                                    </Alert>
                                )
                            }

                            <Card>
                                <CardBody className='table-responsive text-center'>
                                    <table className='table table-modern table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Caixa</th>
                                                <th>Receita</th>
                                                <th>Despesa</th>
                                                <th>Resultado do Mês</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{priceFormat(cashFlowEntryResult?.cashFlowResult?.cashier || 0)}</td>
                                                <td>{priceFormat(cashFlowEntryResult?.revenue || 0)}</td>
                                                <td>{priceFormat(cashFlowEntryResult?.expense || 0)}</td>
                                                <td><b>{priceFormat(cashFlowEntryResult?.result || 0)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>

                        </div>

                        <div className="row">

                            <Card style={{ minHeight: 400 }}>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='AttachMoney' iconColor='primary'>
                                        <CardTitle tag='div' className='h5'>
                                            Lançamentos
                                        </CardTitle>
                                    </CardLabel>
                                    <CardActions>

                                        <Button
                                            color='success'
                                            icon='AddCircle'
                                            isLight
                                            hidden={closedCashier}
                                            onClick={() => handleCashFlowEntryOffcanvas()}
                                        >
                                            Adicionar
                                        </Button>

                                        {/* <Dropdown isButtonGroup>
                                            <DropdownToggle hasIcon={false}>
                                                <Button
                                                    icon='AttachMoney'
                                                    color='primary'
                                                    isLight
                                                    shadow='sm'
                                                    aria-label='Mais Ações'
                                                    hidden={closedCashier}>
                                                    Caixa
                                                </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>

                                                    <Button
                                                        color='primary'
                                                        icon='AttachMoney'
                                                        isLight
                                                        onClick={() => handleCashierOffcanvas()}
                                                        hidden={closedCashier}
                                                    >
                                                        {!cashFlowEntryResult?.cashFlowResult || cashFlowEntryResult?.cashFlowResult?.cashier === 0 ? 'Adicionar Caixa' : 'Alterar Caixa'}
                                                    </Button>

                                                </DropdownItem>

                                                <DropdownItem>

                                                    <Button
                                                        color='primary'
                                                        icon='PointOfSale'
                                                        isLight
                                                        onClick={finalizeCashier}
                                                    >
                                                        Fechar
                                                    </Button>

                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}

                                        <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu} isButtonGroup>
                                            <DropdownToggle hasIcon={false}>
                                                <Button isLink={true} icon='FilterAlt' color='primary'>
                                                    Filtrar
                                                </Button>
                                            </DropdownToggle>
                                            <DropdownMenu
                                                isAlignmentEnd
                                                size='lg'
                                                isCloseAfterLeave={false}>
                                                <div className='container py-2'>
                                                    <form className='row g-3' onSubmit={formikFilter.handleSubmit}>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <Label htmlFor='monthFilter'>Mês</Label>
                                                                <Select
                                                                    id='monthFilter'
                                                                    ariaLabel='Mês'
                                                                    placeholder='Mês'
                                                                    list={filterMonth}
                                                                    onChange={formikFilter.handleChange}
                                                                    value={formikFilter.values.monthFilter}
                                                                />
                                                            </FormGroup>
                                                        </div>

                                                        <FormGroup id='yearFilter' label='Ano'>
                                                            <Input
                                                                onChange={formikFilter.handleChange}
                                                                value={formikFilter.values.yearFilter}
                                                            />
                                                        </FormGroup>

                                                        <div className='col-6'>
                                                            <Button
                                                                color='primary'
                                                                isOutline
                                                                className='w-100'
                                                                onClick={formikFilter.resetForm}>
                                                                Limpar
                                                            </Button>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Button color='primary' className='w-100' type='submit'>
                                                                Aplicar
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </CardActions>
                                </CardHeader>
                                <CardBody className='table-responsive'>
                                    <table className='table table-modern table-hover'>
                                        <thead>
                                            <tr>

                                                <th>Data</th>
                                                <th>Conta</th>
                                                <th>Tipo</th>
                                                <th>Valor</th>
                                                <th>Forma Pagamento</th>
                                                <th>Descrição</th>
                                                <th>Situação</th>
                                                <td aria-labelledby='Actions' />

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {dataPagination(cashFlowEntries, currentPage, perPage).map((item: ICashFlowEntry) => (

                                                <tr key={item.id}>

                                                    <td>{dayjs(item.date).format('DD/MM/YYYY')}</td>

                                                    <td>{item?.cashFlowAccount.name}</td>

                                                    <td>
                                                        {item?.cashFlowAccount.type === CashFlowAccountTypeEnum.Revenue ? (
                                                            <h5>
                                                                <Badge color='success' className='align-items-center justify-content-center'>
                                                                    Receita
                                                                </Badge>
                                                            </h5>
                                                        ) : (
                                                            <h5>
                                                                <Badge color='danger' className='align-items-center justify-content-center'>
                                                                    Despesa
                                                                </Badge>
                                                            </h5>
                                                        )}
                                                    </td>

                                                    <td>{priceFormat(item.value)}</td>

                                                    <td>{item.paymentType ? item.paymentType.name : item.paymentTypeDescription}</td>

                                                    <td> {item.observation} </td>

                                                    <td>
                                                        {item?.paymentStatus === CashFlowEntryStatusEnum.Paid ? (
                                                            <h5>
                                                                <Badge color='success' className='align-items-center justify-content-center'>
                                                                    Pago
                                                                </Badge>
                                                            </h5>
                                                        ) : (
                                                            <h5>
                                                                <Badge color='danger' className='align-items-center justify-content-center'>
                                                                    Em Aberto
                                                                </Badge>
                                                            </h5>
                                                        )}
                                                    </td>

                                                    <td className="text-end">
                                                        <Dropdown>
                                                            <DropdownToggle hasIcon={false}>
                                                                <Button
                                                                    icon='MoreHoriz'
                                                                    color='dark'
                                                                    isLight
                                                                    shadow='sm'
                                                                    aria-label='Mais Ações'
                                                                />
                                                            </DropdownToggle>
                                                            <DropdownMenu isAlignmentEnd>

                                                                {
                                                                    !item.isSubscription && (

                                                                        <DropdownItem>
                                                                            <Button
                                                                                isOutline={!darkModeStatus}
                                                                                color='dark'
                                                                                isLight
                                                                                className={classNames('text-nowrap', {
                                                                                    'border-light': !darkModeStatus,
                                                                                })}
                                                                                icon='Edit'
                                                                                onClick={() => getCashFlowEntryById(item.id)}
                                                                            >
                                                                                Alterar
                                                                            </Button>
                                                                        </DropdownItem>

                                                                    )
                                                                }

                                                                <DropdownItem>
                                                                    <Button
                                                                        isOutline={!darkModeStatus}
                                                                        color='dark'
                                                                        isLight
                                                                        className={classNames('text-nowrap', {
                                                                            'border-light': !darkModeStatus,
                                                                        })}
                                                                        icon='Delete'
                                                                        onClick={() => deleteCashFlowEntry(item.id)}
                                                                    >
                                                                        Remover
                                                                    </Button>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </td>


                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </CardBody>

                                <PaginationButtons
                                    data={cashFlowEntries}
                                    label='lançamentos'
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                />
                            </Card>
                        </div>

                        <Modal
                            setIsOpen={setCashFlowEntryOffcanvas}
                            isOpen={cashFlowEntryOffcanvas}
                            titleId='cashFlowEntry'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                            size={'lg'}
                        >
                            <ModalHeader setIsOpen={setCashFlowEntryOffcanvas}>
                                <OffCanvasTitle id='cashFlowEntry'>{modalTitle}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>

                                <div className='row g-4'>

                                    {
                                        operation == enumOperation.Update && (
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='id'
                                                    label='Código'
                                                    isFloating
                                                >
                                                    <Input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.id}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )
                                    }

                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                        <FormGroup
                                            id='cashFlowAccountId'
                                            label='Conta'
                                            isFloating
                                        >
                                            <Select
                                                ariaLabel='Escolha uma conta'
                                                placeholder='Escolha uma conta'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cashFlowAccountId}
                                                list={
                                                    cashFlowAccounts.map((cashFlowAccount) => ({
                                                        value: cashFlowAccount.id,
                                                        text: `${cashFlowAccount.name}`
                                                    }))
                                                }
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.cashFlowAccountId}
                                                invalidFeedback={
                                                    formik.errors.cashFlowAccountId
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                        <FormGroup
                                            id='paymentTypeId'
                                            label='Forma de Pagamento'
                                            isFloating
                                        >
                                            <Select
                                                ariaLabel='Escolha uma forma de pagamento'
                                                onChange={(e: { target: { value: any } }) => {
                                                    formik.handleChange(e);

                                                    if (e.target.value) {
                                                        handlePaymentTypeOnChange(e.target.value);
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.paymentTypeId}
                                                list={
                                                    paymentTypes.map((paymentType) => ({
                                                        value: paymentType.id,
                                                        text: `${paymentType.name}`
                                                    }))
                                                }
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.paymentTypeId}
                                                invalidFeedback={
                                                    formik.errors.paymentTypeId
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                        <FormGroup
                                            id='date'
                                            label={entryDateLabel}
                                            isFloating
                                        >
                                            <Input
                                                type="date"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.date}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.date}
                                                invalidFeedback={
                                                    formik.errors.date
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                        <FormGroup
                                            id='value'
                                            label='Valor'
                                            isFloating
                                        >
                                            <Input
                                                type={'number'}
                                                step={0.01}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.value}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.value}
                                                invalidFeedback={
                                                    formik.errors.value
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                        <FormGroup
                                            id='paymentStatus'
                                            label='Situação'
                                            isFloating
                                        >
                                            <Select
                                                ariaLabel='Escolha uma situação'
                                                placeholder='Escolha uma situação'
                                                list={
                                                    [
                                                        { value: '0', text: 'Pago' },
                                                        { value: '1', text: 'Em Aberto' }
                                                    ]
                                                }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.paymentStatus}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                        <FormGroup
                                            id='observation'
                                            label='Observação'
                                            isFloating
                                        >
                                            <Input
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.observation}
                                            />
                                        </FormGroup>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={formik.handleSubmit}
                                    isDisable={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Salvar
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <Modal
                            setIsOpen={setCashierOffcanvas}
                            isOpen={cashierOffcanvas}
                            titleId='cashier'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                            size={'sm'}
                        >
                            <ModalHeader setIsOpen={setCashierOffcanvas}>
                                <OffCanvasTitle id='cashier'>{'Adicionar Caixa'}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>

                                <div className='row g-4'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                        <FormGroup
                                            id='cashierValue'
                                            label='Valor'
                                        >
                                            <Input
                                                onChange={formikCashier.handleChange}
                                                onBlur={formikCashier.handleBlur}
                                                value={formikCashier.values.cashierValue}
                                                type={'number'}
                                                step={0.01}
                                                invalidFeedback={
                                                    formikCashier.errors.cashierValue
                                                }
                                                isValid={formikCashier.isValid}
                                                isTouched={formikCashier.touched.cashierValue}
                                            />
                                        </FormGroup>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup id='cashierId'>
                                            <Input
                                                onChange={formikCashier.handleChange}
                                                value={formikCashier.values.cashierId}
                                                hidden={true}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={saveCashier}
                                    isDisable={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Salvar
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )
            }
        </>
    )
};

export default CommonCashFlowEntriesList;