import { ICashFlowEntry } from "../common/data/financial/cashFlowEntries";
import { ICashFlowEntryResult } from "../common/data/financial/cashFlowEntryResult";
import ICashFlowEntryRQ from "../common/data/financial/request/cashFlowEntryRQ";
import { DELETE_TYPED_ASYNC, GET_TYPED_ASYNC, POST_TYPED_ASYNC, PUT_TYPED_ASYNC } from "../components/extras/HttpHelper";

export interface IGetCashFlowEntryResultResponse {
    success: boolean;
	cashFlowEntryResult?: ICashFlowEntryResult;
	error?: string;
};

export interface ICashFlowEntryResponse {
    success: boolean;
	cashFlowEntry?: ICashFlowEntry;
	error?: string;
};

type CreateRequest = Omit<ICashFlowEntryRQ, keyof IEntity>;

const createCashFlowEntry = async (request: CreateRequest): Promise<ICashFlowEntryResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<ICashFlowEntry>('api/NCashFlowEntry/Create', request);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getCashFlowEntryResult = async (reference: string): Promise<IGetCashFlowEntryResultResponse> => {

    try {

		const response = await GET_TYPED_ASYNC<ICashFlowEntryResult>(`api/NCashFlowEntry/GetByReference/${reference}`);
		
		if (response && response.success) {
			return { success: response.success, cashFlowEntryResult: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
    } catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getCashFlowEntryById = async (id: string): Promise<ICashFlowEntryResponse> => {

    try {

		const response = await GET_TYPED_ASYNC<ICashFlowEntry>(`api/NCashFlowEntry/GetById/${id}`);
		
		if (response && response.success) {
			return { success: response.success, cashFlowEntry: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
    } catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const updateCashFlowEntry = async (request: ICashFlowEntryRQ): Promise<ICashFlowEntryResponse> => {
	try {
		const response = await PUT_TYPED_ASYNC<ICashFlowEntryRQ>('api/NCashFlowEntry/Update', request);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const deleteCashFlowEntry = async (id: string): Promise<ICashFlowEntryResponse> => {
	try {
		const response = await DELETE_TYPED_ASYNC<ICashFlowEntry>(`api/NCashFlowEntry/Delete/${id}`);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	createCashFlowEntry,
    getCashFlowEntryResult,
	getCashFlowEntryById,
	updateCashFlowEntry,
	deleteCashFlowEntry
};