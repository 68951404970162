import { useContext, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import AuthContext from '../../../contexts/authContext';
import PixQrCodeGenerator from '../components/PixQrCodeGenerator';
import SubscriptionDetail from '../components/SubscriptionDetail';
import StripeFormElement from './StripeFormElement';
import systemConfigurationService from '../../../services/system-configuration-service';
import financialService from '../../../services/financial-service';
import { showError, showSuccess } from '../../../components/extras/Notifications';
interface Props {
	fee: number;
	subscriptionType: string;
	subscriptionTypeId: string;
	isPaymentModalOpen: boolean;
	isPixPayment: boolean;
	isMonthlySubscriptionPayment: boolean;
	toggleModalVisibility: (visible: boolean) => void;
	onSuccess: () => void;
	customAmount: boolean;
}
const QrCode = ({ isPixPayment, amount, username, onSuccess }: { isPixPayment: boolean, amount: number, username: string | undefined, onSuccess: () => void}) => {
	const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
	const [pixPayload, setPixPayload] = useState<string | null>(null);
	const [copyMessage, setCopyMessage] = useState<string>("Pix Copia e Cola");
    const [configuration, setConfiguration] = useState<IConfiguration | null>(null);
	const { cognitoId } = useContext(AuthContext);

	useEffect(() => {
		const getConfiguration = async () => {
			const response = await systemConfigurationService.getConfiguration();
			if (response.configuration) {
				setConfiguration(response.configuration);
			} else {
				setConfiguration(null);
			}
		};
		getConfiguration();
	}, []); // Empty dependency array to run only on mount

	useEffect(() => {
		// Generate QR code only if `isPixPayment` is true and configuration is available
		if (isPixPayment && configuration) {
			const pixKey = configuration.pixCode || '';
			const merchantName = configuration.pixName || '';
			const city = configuration.city || '';

			const description = username ? `${username}` : "Pagamento";

			const qrGenerator = new PixQrCodeGenerator(pixKey, merchantName, city, amount, description);
			qrGenerator.generateQrCodeDataUrl().then(setQrCodeUrl);
			
			setPixPayload(qrGenerator.generatePixCode());  // Store the PIX payload with CRC
		}
	},[isPixPayment, configuration, amount, username]); // Depend on `isPixPayment`, `configuration`, `amount`, `username`

	const copyToClipboard = () => {
		if (pixPayload) {
			navigator.clipboard.writeText(pixPayload).then(() => {
				setCopyMessage("Copiado!!");
				setTimeout(() => setCopyMessage("Pix Copia e Cola"), 2000); // Reset message after 2 seconds
			}).catch(err => {
				console.error("Falha ao copiar chave pix: ", err);
			});
		}
	};

	const handleSubmitAsPaid = async () => {
		const response = await financialService.createStudentPayment({
			studentId: cognitoId,
			amount,
			underReview: true,
			createdBy: cognitoId,
			paymentTypeId: configuration?.pixPaymentTypeId //TODO: adicionar em configuracoes
		});
		if (!response) {
			console.error(response.message);
			showError('Erro ao registrar pagamento');
			return;
		}
		showSuccess('Pagamento enviado para verificação');
		onSuccess(); // Close the modal
		window.location.reload(); // Refresh the page
	};

	return (
		<div style={{ textAlign: 'center' }}>
			{qrCodeUrl && (
				<>
					<img src={qrCodeUrl} alt="PIX QR Code" style={{ width: '100%', maxWidth: '300px' , textAlign: 'center'}} />
					{pixPayload && (
						<div style={{ marginTop: '15px', wordBreak: 'break-all', textAlign: 'center' }}>
							<button onClick={copyToClipboard} style={{ background: 'none', border: 'none', color: '#5f78fb', cursor: 'pointer', padding: '0', fontSize: '16px' }}>
								<strong>{copyMessage}</strong>
							</button>
							<hr></hr>
							<button 
								onClick={handleSubmitAsPaid} 
								style={{ marginTop: '10px', backgroundColor: '#5f78fb', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', fontSize: '16px' }}>
								Marcar como Pago
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
	};

const PaymentModal = ({
	fee,
	subscriptionTypeId,
	isPixPayment,
	subscriptionType,
	isPaymentModalOpen,
	isMonthlySubscriptionPayment,
	toggleModalVisibility,
	onSuccess,
}: Props) => {
	const { userData } = useContext(AuthContext);
	const onPaymentSuccess = () => {
		onSuccess();
		toggleModalVisibility(false);
	};

	return (
		<Modal
			id='payment-modal'
			isOpen={isPaymentModalOpen}
			setIsOpen={toggleModalVisibility}
			isScrollable
			size='lg'
			isCentered>
			<ModalHeader setIsOpen={toggleModalVisibility}>
				<ModalTitle id='paymentModal'>Pagar mensalidade</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row'>
					<div className='col-xl-6'>
						{isMonthlySubscriptionPayment && (
						<Card borderSize={1} stretch>
							<SubscriptionDetail
								description={subscriptionType}
								fee={fee}
								isSubscription
							/>
						</Card>
						) || (
								<Card borderSize={1} stretch>
									<SubscriptionDetail
										description='Outros pagamentos'
										fee={0}
										isSubscription={false}
									/>
									</Card>
						)}  
						
					</div>
					<>
					<div className='col-xl-6'>
						{/* TODO - how to get currency from the user? */}
						{ !isPixPayment && (
						<StripeFormElement amount={fee} currency='BRL' onSuccess={onSuccess} customAmount={!isMonthlySubscriptionPayment} />) ||
						(
							<Card borderSize={1} stretch>
							<CardBody>
							    <QrCode isPixPayment={isPixPayment} amount={fee} username={userData.username} onSuccess={onPaymentSuccess} />
							</CardBody>
						</Card>
						)}
					</div>
					</>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default PaymentModal;


