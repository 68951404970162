import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import { CardActions } from '../../../components/bootstrap/Card';
import { useStudent } from '../../../hooks/useStudent';
import { PagesLayout } from '../../../layout/PagesLayout';
import SubscriptionMonthlyHistory from '../components/SubscriptionMonthlyHistory';
import LoadingPage from '../loading/LoadingPage';
import PaymentModal from './PaymentModal';
import PaymentsGeneric from './PaymentsGeneric';
import cognitoService from '../../../services/cognito-service';


const Payments = () => {
	const { student, fetchStudent, fetchingStudent } = useStudent();

	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
	const [isOtherPaymentsSelected, setisOtherPaymentsSelected] = useState(false);
	const [isPixPayment, setisPixPayment] = useState(false);
	const [genericPayment, setGenericPayment] = useState(student?.payments || []);


	useEffect(() => {
		const getConfiguration = async () => {
			if (student?.cognitoUserId) {
				const response = await cognitoService.getPaymentsUnderReviewById(student.cognitoUserId);
				if (response.student?.payments) {
					setGenericPayment(response.student.payments);
					setisPixPayment(true);
				} else {
					setGenericPayment([]);
				}
			}
		};
		getConfiguration();

	}, [student]); // Trigger on `student` change

	if (fetchingStudent) return <LoadingPage />;

	const toggleModalVisibility = (visible: boolean) => {
		setIsPaymentModalOpen(visible);
	};

	const isMonthlySubscriptionPaid = () => {
		const { paymentDate } = student?.payments?.[0] || {};
		if (!paymentDate) return false;

		return dayjs().month() === dayjs(paymentDate).month();
	};

	const onPaymentSuccess = () => {
		fetchStudent();
		toggleModalVisibility(false);
	};

	const isPaid = isMonthlySubscriptionPaid();

	return (
		<PagesLayout title='Pagamentos'>
			{isPaid && (
				<Alert isLight color='success' borderWidth={0}>
					<AlertHeading tag='h2' className='h5'>
						Parabéns! 🎉
					</AlertHeading>
					<p className='h6'>A mensalidade do mês já foi paga</p>
				</Alert>
			)}
			<div className='row'>
				<div className='col-xl-12'>
					<SubscriptionMonthlyHistory
						payments={student?.payments || []}
						isCompact={false}
						cardActions={
							<CardActions>
								<Button
									color='success'
									icon='AddCircle'
									isLight
									onClick={() => toggleModalVisibility(true)}
									isDisable={isPaid}
									tag='button'>
									Pagar
								</Button>
								{/* <Button
								color='info'
								icon='AddCircle'
								isLight
								onClick={() => toggleModalVisibility(true)}
								isDisable={isPaid}
								tag='button'>
								Pagar com Pix
							</Button> */}
							</CardActions>
						}
					/>
				</div>
				<div className='col-xl-12'>
					<PaymentsGeneric
						payments={genericPayment}
						isCompact={false}
					/>
				</div>
			</div>
			<PaymentModal
				isPixPayment={isPixPayment}
				fee={student?.subscription?.subscriptionType?.fee || 0}
				subscriptionTypeId={student?.subscription?.subscriptionType?.id || ''}
				subscriptionType={student?.subscription?.subscriptionType?.description || ''}
				isPaymentModalOpen={isPaymentModalOpen}
				isMonthlySubscriptionPayment={!isOtherPaymentsSelected}
				toggleModalVisibility={toggleModalVisibility}
				onSuccess={onPaymentSuccess}
				customAmount={isOtherPaymentsSelected}
			/>
		</PagesLayout>
	);
};

export default Payments;
