import classNames from "classnames";
import { FC } from "react";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";

interface ICommonDashboardSubscriptionProps {
    student?: IStudent
}

const CommonDashboardSubscriptionCard: FC<ICommonDashboardSubscriptionProps> = ({ student }) => {

    const {darkModeStatus} = useDarkMode();

    return (

        <>

            <Card borderSize={1} stretch>
                <CardBody className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                        <div className='ratio ratio-1x1' style={{ width: 72 }}>
                            <div
                                className={classNames(
                                    'rounded-2 d-flex align-items-center justify-content-center bg-l10-primary',
                                    {
                                        
                                        'bg-l10-primary': !darkModeStatus,
                                        'bg-lo25-primary': darkModeStatus,
                                    },
                                )}>
                                <span className='text-primary fs-1 fw-bold'>
                                    <Icon icon={'LocalOffer'} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                        <div>
                            <div className='fw-bold fs-6 mb-0'>{student?.subscription?.subscriptionType?.description || 'Nenhum plano cadastrado'}</div>
                            <div className='text-muted'>
                                <small>
                                    <span className='fw-bold'>
                                        {
                                            student?.subscription?.subscriptionType?.fee && (
                                                priceFormat(student?.subscription?.subscriptionType?.fee || 0)

                                            )
                                        }
                                    </span>
                                </small>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card >
        </>
    );
};

CommonDashboardSubscriptionCard.defaultProps = {
    student: undefined
};

export default CommonDashboardSubscriptionCard;