import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useContext, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { useParams } from "react-router-dom";
import removeAccents from "remove-accents";
import belts from "../../../../common/data/beltData";
import cities, { ICity } from "../../../../common/data/cityData";
import { IFileUpload, OperationTypeEnum } from "../../../../common/data/document/fileUpload";
import UserTypeEnum from "../../../../common/data/enum/enumUserType";
import states from "../../../../common/data/stateData";
import Avatar from "../../../../components/Avatar";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC, POST_ASYNC, PUT_ASYNC } from "../../../../components/extras/HttpHelper";
import { showCreateSuccess, showWarning } from "../../../../components/extras/Notifications";
import Icon from "../../../../components/icon/Icon";
import AuthContext from "../../../../contexts/authContext";
import { priceFormat } from "../../../../helpers/helpers";
import cognitoService, { GetByIdResponse } from "../../../../services/cognito-service";
import subscriptionService, { SubscriptionTypesResponse } from "../../../../services/subscription-service";
import CommonFileUpload from "../../document/common/CommonFileUpload";
import studentValidate from "../helper/studentValidate";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

type TTabs = 'Detalhes do Usuário' | 'Dados Adicionais' | 'Endereço' | 'Detalhes do Atleta' | 'Planos' | 'Documentos';
interface ITabs {
	[key: string]: TTabs;
}

const TABS: ITabs = {
	ACCOUNT_DETAIL: 'Detalhes do Usuário',
	ADDITIONAL_DATA: 'Dados Adicionais',
	ADDRESS: 'Endereço',
	ATHLETE_DETAIL: 'Detalhes do Atleta',
	SUBSCRIPTION: 'Planos',
	DOCUMENTS: 'Documentos'
};

interface ICommonStudentProps {
	afterSubmit?: any;
}

const CommonStudent: FC<ICommonStudentProps> = ({ afterSubmit }) => {

	const defaultAspect = 16 / 16

	const { id } = useParams();
	const { userData, cognitoId } = useContext(AuthContext);

	const [activeTab, setActiveTab] = useState<TTabs>(TABS.ACCOUNT_DETAIL);
	const [image, setImage] = useState<string>('');
	const [selectedCities, setSelectedCities] = useState<ICity[]>([]);
	const [disableCity, setDisableCity] = useState<boolean>(true);
	const [activeSubscriptionTypes, setActiveSubscriptionTypes] = useState<ISubscriptionType[]>([]);
	const [selectedSubscription, setSelectedSubscription] = useState<ISubscriptionType>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedStudent, setSelectedStudent] = useState<IStudent>();
	const [files, setFiles] = useState<IFileUpload[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	const imgRef = useRef<HTMLImageElement>(null)
	const [crop, setCrop] = useState<Crop>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [aspect, setAspect] = useState<number | undefined>(defaultAspect)
	const [imgSrc, setImgSrc] = useState('')
	const [isModalOpen, setOpenModal] = useState<boolean>(false);
	const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
			}
		},
		100,
		[completedCrop, scale, rotate]
	)

	function handleToggleAspectClick() {
		if (aspect) {
			setAspect(undefined)
		} else {
			setAspect(defaultAspect)
			if (imgRef.current) {
				const { width, height } = imgRef.current
				setCrop(centerAspectCrop(width, height, defaultAspect))
			}
		}
	}

	function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
		return centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		)
	}

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
	}

	const formikUser = useFormik({
		initialValues: {
			id: '',
			fullName: '',
			email: '',
			userName: '',
			profileImage: null,
			phone: '',
			birthDate: Date(),
			address: '',
			neighborhood: '',
			city: '',
			state: '',
			zipCode: '',
			country: 'Brasil',
			observation: '',
			responsableName: '',
			responsablePhone: '',
			responsableKinship: '',
			subscriptionTypeId: '',
			ibjjfId: '',
			compnetId: '',
			belt: '',
			grade: '',
			document: '',
			nationalId: '',
			nationalIdSender: '',
			dateOfIssue: '',
			emergencyName: '',
			emergencyPhone: '',
			emergencyKinship: '',
			lastGrade: Date(),
		},
		validateOnChange: false,
		validate: studentValidate,
		onSubmit: () => {

		}
	});

	useEffect(() => {
		if (id) {
			getByCognitoId();
		} else {
			getActiveSubscriptions();
		}
	}, []);

	const getByCognitoId = async () => {
		resetForm();
		setIsLoading(true);

		const promises = []
		promises.push(cognitoService.getById(id || ''));
		promises.push(subscriptionService.getAllActives());

		const [getByCognitoIdResponse, getActiveSubscriptionResponse] =
			await Promise.all(promises) as [GetByIdResponse, SubscriptionTypesResponse];

		if (getActiveSubscriptionResponse.error) {
			showWarning(getActiveSubscriptionResponse.error);
			return;
		}

		if (getByCognitoIdResponse.error) {
			showWarning(getByCognitoIdResponse.error);
			return;
		}

		setActiveSubscriptionTypes(getActiveSubscriptionResponse.subscriptionTypes || []);

		setSelectedStudent(getByCognitoIdResponse.student);
		if (getByCognitoIdResponse.student) {
			fillForm(getByCognitoIdResponse.student);
		}

		setIsLoading(false);
	}

	const getActiveSubscriptions = async () => {
		resetForm();
		setIsLoading(true);

		const response = await GET_ASYNC('api/NSubscriptionType/GetActive');

		setIsLoading(false);

		if (response && response.success) {
			setActiveSubscriptionTypes(response.collection || []);
		} else {
			showWarning(response.message);
		}
	}

	const fillForm = (student: IStudent) => {

		if (student && id) {
			handleStateOnChange(student?.state || '');

			setImage(student.profileImageUrl);

			formikUser.values.id = student?.id || '';
			formikUser.values.fullName = student?.fullName || '';
			formikUser.values.userName = student?.username || '';
			formikUser.values.email = student?.email || '';
			formikUser.values.phone = student?.phone || '';
			formikUser.values.address = student?.address || '';
			formikUser.values.neighborhood = student?.neighborhood || '';
			formikUser.values.city = student?.city || '';
			formikUser.values.state = student?.state || '';
			formikUser.values.zipCode = student?.zipCode || '';
			formikUser.values.country = student?.country || '';
			formikUser.values.observation = student?.observation || '';
			formikUser.values.ibjjfId = student?.ibjjfId || '';
			formikUser.values.compnetId = student?.compnetId || '';
			formikUser.values.belt = student?.belt || '';
			formikUser.values.grade = student?.grade || '';
			formikUser.values.subscriptionTypeId = student?.subscription?.subscriptionTypeId || '';
			formikUser.values.birthDate = student.birthDate ? dayjs(student.birthDate).format('YYYY-MM-DD') : '';
			formikUser.values.document = student?.document || '';
			formikUser.values.nationalId = student?.nationalId || '';
			formikUser.values.nationalIdSender = student?.nationalIdSender || '';
			formikUser.values.dateOfIssue = student?.dateOfIssue ? dayjs(student.dateOfIssue).format('YYYY-MM-DD') : '';
			formikUser.values.responsableName = student?.responsableName || '';
			formikUser.values.responsablePhone = student?.responsablePhone || '';
			formikUser.values.responsableKinship = student?.responsableKinship || '';
			formikUser.values.emergencyName = student?.emergencyName || '';
			formikUser.values.emergencyPhone = student?.emergencyPhone || '';
			formikUser.values.emergencyKinship = student?.emergencyKinship || '';
			formikUser.values.lastGrade = student.lastGrade ? dayjs(student.lastGrade).format('YYYY-MM-DD') : '';
		}
	}

	const resetForm = () => {
		setActiveTab(TABS.ACCOUNT_DETAIL)
		setImage('');

		if (selectedSubscription) {
			selectedSubscription.selected = false;
		}

		formikUser.values.fullName = '';
		formikUser.values.phone = '';
		formikUser.values.address = '';
		formikUser.values.neighborhood = '';
		formikUser.values.zipCode = '';
		formikUser.values.country = 'Brasil';
		formikUser.values.observation = '';
		formikUser.values.ibjjfId = '';
		formikUser.values.compnetId = '';
		formikUser.values.belt = '';
		formikUser.values.grade = '';
		formikUser.values.profileImage = null;
		formikUser.values.city = '';
		formikUser.values.state = '';
		formikUser.values.birthDate = Date();
		formikUser.values.document = '';
		formikUser.values.nationalId = '';
		formikUser.values.nationalIdSender = '';
		formikUser.values.dateOfIssue = '';
		formikUser.values.responsableName = '';
		formikUser.values.responsablePhone = '';
		formikUser.values.responsableKinship = '';
		formikUser.values.emergencyName = '';
		formikUser.values.emergencyPhone = '';
		formikUser.values.lastGrade = Date();
	}

	const setImgSrcAsFile = () => {
		const imageFile = imgRef.current;
		const previewCanvas = previewCanvasRef.current;
		// convert previewCanvas to File
		const canvas = previewCanvas as HTMLCanvasElement;
		canvas.toBlob((blob) => {
			if (blob) {
				const imageFile = new File([blob], 'profile-image.jpg', { type: 'image/jpeg' });
				formikUser.setFieldValue('profileImage', imageFile);
				const reader = new FileReader();
				reader.onload = (e) => {
					setImage(e.target?.result as string);
					setImgSrc(e.target?.result as string);
					setOpenModal(false);
				};
				reader.readAsDataURL(imageFile);
			}
		})

		setOpenModal(false);
	}

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {

			if (file.type !== 'image/jpeg') {
				showWarning('Formato da imagem inválido. Por favor, insira uma imagem jpg ou jpeg');
				return;
			}

			// formikUser.setFieldValue('profileImage', file);
			setCrop(undefined);
			const reader = new FileReader();
			reader.onload = (e) => {
				setImage(e.target?.result as string);
				setImgSrc(e.target?.result as string);
				setOpenModal(true);
			};
			reader.readAsDataURL(file);
		}
	}

	const handleStateOnChange = (state: string) => {
		setSelectedCities([]);

		let filteredCities = cities.filter(
			(c) => c.state === state
		);

		if (filteredCities.length !== 0) {
			setSelectedCities(filteredCities);
			setDisableCity(false);
		} else {
			setDisableCity(true);
			setSelectedCities([]);
		}
	}

	const selectSubscriptionTabMenu = () => {
		setActiveTab(TABS.SUBSCRIPTION);

		const selectedUserSubscriptionId = formikUser.values?.subscriptionTypeId;
		if (selectedUserSubscriptionId && activeSubscriptionTypes) {

			let selectedUserSubscription = activeSubscriptionTypes.filter(
				(p) => p.id === selectedUserSubscriptionId
			);

			if (selectedUserSubscription) {
				selectedUserSubscription[0].selected = true;
				selectSubscription(selectedUserSubscription[0]);
			}
		}
	}

	const selectSubscription = (subscriptionType: ISubscriptionType) => {
		if (selectedSubscription) {
			selectedSubscription.selected = false;

			if (selectedSubscription.id == subscriptionType.id) {
				setSelectedSubscription(undefined);
				setIsUnsubscribe(true);
				return;
			}
		}

		subscriptionType.selected = true;
		setSelectedSubscription(subscriptionType);
		setIsUnsubscribe(false);
	}

	const saveStudent = async () => {

		formikUser.submitForm();

		if (!image) {
			showWarning('Falha ao salvar o usuário. Por favor, verifique a imagem selecionada para o perfil do usuário.');
			return;
		}

		if (!formikUser.values.birthDate || formikUser.values.birthDate >= Date()) {
			showWarning('Falha ao salvar o usuário. Por favor, informe uma data de nascimento válida.');
			return;
		}

		if (formikUser.values.id) {
			await updateStudent();
		} else {
			await createStudent();
		}
	}

	const createStudent = async () => {

		const formData = new FormData();

		formData.append('id', formikUser.values.id)
		formData.append('fullName', formikUser.values.fullName);
		formData.append('userType', UserTypeEnum.Student.toString());

		if (formikUser.values.profileImage) {
			formData.append('profileImage', formikUser.values.profileImage);
		}

		formData.append('phone', formikUser.values.phone);
		formData.append('address', formikUser.values.address);
		formData.append('neighborhood', formikUser.values.neighborhood);
		formData.append('city', formikUser.values.city);
		formData.append('state', formikUser.values.state);
		formData.append('zipCode', formikUser.values.zipCode);
		formData.append('country', formikUser.values.country);
		formData.append('observation', formikUser.values.observation);

		formData.append('ibjjfId', formikUser.values.ibjjfId);
		formData.append('compnetId', formikUser.values.compnetId);
		formData.append('belt', formikUser.values.belt);
		formData.append('grade', formikUser.values.grade);
		formData.append('lastGrade', formikUser.values.lastGrade);

		if (selectedSubscription?.id) {
			const subscription = {
				startDate: dayjs(),
				SubscriptionTypeId: selectedSubscription.id,
				CognitoId: cognitoId
			}

			formData.append('subscriptionJson', JSON.stringify(subscription));
		}

		formData.append('birthDate', formikUser.values.birthDate);

		if (files && files.length > 0) {
			files.map((document) => {
				if (document.file) {
					formData.append('documentsJson', JSON.stringify(document));
				}
			});
		}

		formData.append('document', formikUser.values.document);
		formData.append('nationalId', formikUser.values.nationalId);
		formData.append('nationalIdSender', formikUser.values.nationalIdSender);
		formData.append('dateOfIssue', formikUser.values.dateOfIssue);

		formData.append('emergencyName', formikUser.values.emergencyName);
		formData.append('emergencyPhone', formikUser.values.emergencyPhone);
		formData.append('emergencyKinship', formikUser.values.emergencyKinship);

		formData.append('responsableName', formikUser.values.responsableName);
		formData.append('responsablePhone', formikUser.values.responsablePhone);
		formData.append('responsableKinship', formikUser.values.responsableKinship);

		setIsSubmitting(true);

		const response = await POST_ASYNC('api/NStudent/Create', formData);

		setIsSubmitting(false);

		if (response && response.success) {
			showCreateSuccess();
			resetForm();

			if (afterSubmit) {
				afterSubmit();
			} else {
				showWarning(response.message);
			}
		}
	}

	const updateStudent = async () => {

		const formData = new FormData();

		formData.append('id', formikUser.values.id);
		formData.append('fullName', formikUser.values.fullName);
		formData.append('userName', formikUser.values.userName);
		formData.append('email', formikUser.values.email);
		formData.append('userType', UserTypeEnum.Student.toString());

		if (formikUser.values.profileImage) {
			formData.append('profileImage', formikUser.values.profileImage);
		}

		formData.append('phone', formikUser.values.phone);
		formData.append('address', formikUser.values.address);
		formData.append('neighborhood', formikUser.values.neighborhood);
		formData.append('city', formikUser.values.city);
		formData.append('state', formikUser.values.state);
		formData.append('zipCode', formikUser.values.zipCode);
		formData.append('country', formikUser.values.country);
		formData.append('observation', formikUser.values.observation);
		formData.append('ibjjfId', formikUser.values.ibjjfId);
		formData.append('compnetId', formikUser.values.compnetId);
		formData.append('belt', formikUser.values.belt);
		formData.append('grade', formikUser.values.grade);
		formData.append('lastGrade', formikUser.values.lastGrade);
		formData.append('isUnsubscribe', isUnsubscribe.toString());

		const sendCognitoId = id ? id : cognitoId;

		if (selectedSubscription?.id && selectedSubscription?.id != formikUser.values.subscriptionTypeId) {
			const subscription = {
				startDate: dayjs(),
				SubscriptionTypeId: selectedSubscription.id,
				CognitoId: sendCognitoId
			}

			formData.append('subscriptionJson', JSON.stringify(subscription));
		}

		formData.append('birthDate', formikUser.values.birthDate);

		if (files && files.length > 0) {
			files.map((document) => {
				if (document.file) {
					formData.append('documentsJson', JSON.stringify(document));
				}
			});
		}

		if (selectedStudent) {
			formData.append('cognitoUserId', selectedStudent.cognitoUserId);
			formData.append('profileImageUrl', selectedStudent.profileImageUrl);
			formData.append('status', selectedStudent.status.toString());
			formData.append('creationDate', selectedStudent.creationDate ? dayjs(selectedStudent.creationDate).toString() : '');
			formData.append('lastUpdate', selectedStudent.lastUpdate ? dayjs(selectedStudent.lastUpdate).toString() : '');
			formData.append('inactivationDate', selectedStudent.inactivationDate ? dayjs(selectedStudent.inactivationDate).toString() : '');
		}

		formData.append('document', formikUser.values.document);
		formData.append('nationalId', formikUser.values.nationalId);
		formData.append('nationalIdSender', formikUser.values.nationalIdSender);
		formData.append('dateOfIssue', formikUser.values.dateOfIssue);
		formData.append('emergencyName', formikUser.values.emergencyName);
		formData.append('emergencyPhone', formikUser.values.emergencyPhone);
		formData.append('emergencyKinship', formikUser.values.emergencyKinship);
		formData.append('responsableName', formikUser.values.responsableName);
		formData.append('responsablePhone', formikUser.values.responsablePhone);
		formData.append('responsableKinship', formikUser.values.responsableKinship);

		setIsSubmitting(true);

		const response = await PUT_ASYNC('api/NStudent/Update', formData);

		setIsSubmitting(false);

		if (response && response.success) {
			showCreateSuccess();
			resetForm();

			if (afterSubmit) {
				afterSubmit();
			} else {
				showWarning(response.message);
			}
		}

	}

	const onUpdateDocuments = (fileToUpload: IFileUpload) => {
		if (fileToUpload.operationType === OperationTypeEnum.Add || fileToUpload.operationType === OperationTypeEnum.Added) {
			setFiles([...files, fileToUpload]);
		} else {
			const updatedFiles = files.filter((file) => file.name !== fileToUpload.name);
			setFiles(updatedFiles);
		}
	}

	return (
		<>

			<div className='row h-100 w-100' style={{ minHeight: 600 }}>

				<div className='col-xxl-3 col-xl-4 col-lg-6'>
					<Card stretch>
						<CardHeader>
							<CardLabel icon='Person' iconColor='primary'>
								<CardTitle className='h5'>
									Informações da Conta
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody isScrollable>
							<div className='row g-3'>
								<div className='col-12'>
									<Button
										icon='Contacts'
										color='primary'
										className='w-100 p-3'
										isLight={TABS.ACCOUNT_DETAIL !== activeTab}
										onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}>
										{TABS.ACCOUNT_DETAIL}
									</Button>
								</div>
								<div className='col-12'>
									<Button
										icon='PostAdd'
										color='primary'
										className='w-100 p-3'
										isLight={TABS.ADDITIONAL_DATA !== activeTab}
										onClick={() => setActiveTab(TABS.ADDITIONAL_DATA)}>
										{TABS.ADDITIONAL_DATA}
									</Button>
								</div>
								<div className='col-12'>
									<Button
										icon='Place'
										color='primary'
										className='w-100 p-3'
										isLight={TABS.ADDRESS !== activeTab}
										onClick={() => setActiveTab(TABS.ADDRESS)}>
										{TABS.ADDRESS}
									</Button>
								</div>
								<div className='col-12'>
									<Button
										icon='SportsKabaddi'
										color='primary'
										className='w-100 p-3'
										isLight={TABS.ATHLETE_DETAIL !== activeTab}
										onClick={() => setActiveTab(TABS.ATHLETE_DETAIL)}>
										{TABS.ATHLETE_DETAIL}
									</Button>
								</div>

								{
									userData.isAdmin && selectedStudent?.status && (

										<div className='col-12'>
											<Button
												icon='LocalOffer'
												color='primary'
												className='w-100 p-3'
												isLight={TABS.SUBSCRIPTION !== activeTab}
												onClick={selectSubscriptionTabMenu}
											>
												{TABS.SUBSCRIPTION}
											</Button>
										</div>
									)
								}


								<div className='col-12'>
									<Button
										icon='UploadFile'
										color='primary'
										className='w-100 p-3'
										isLight={TABS.DOCUMENTS !== activeTab}
										onClick={() => setActiveTab(TABS.DOCUMENTS)}
									>
										{TABS.DOCUMENTS}
									</Button>
								</div>

							</div>
						</CardBody>
						<CardFooter>
							<CardFooterLeft className='w-100'>
								<Button
									color='success'
									isLight
									className='w-100 p-3'
									onClick={saveStudent}
									isDisable={isSubmitting}
								>
									{isSubmitting && (
										<Spinner isSmall inButton isGrow />
									)}
									Salvar Aluno
								</Button>
							</CardFooterLeft>
						</CardFooter>
					</Card>
				</div>

				<div className='col-xxl-9 col-xl-8 col-lg-6'>

					{TABS.ACCOUNT_DETAIL === activeTab && (

						<Card stretch noValidate>
							<CardHeader>
								<CardLabel icon='Contacts' iconColor='primary'>
									<CardTitle className='h5'>
										Detalhes do Usuário
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>

								<Card>
									<CardBody>
										<div className='row g-4 align-items-center'>

											{
												image && (
													<div className='col-xl-auto'>
														<Avatar srcSet={image} src={image} />
													</div>
												)
											}
											<div className='col-xl'>
												<div className='row g-4'>
													<div className='col-auto'>
														<Input
															type='file'
															autoComplete='photo'
															// ariaLabel='Selecione uma imagem para o usuário'
															accept='.jpg, .jpeg'
															onChange={handleImageChange}
														/>


													</div>
													<div className='col-12'>
														<p className='lead text-muted'>
															A imagem será utilizada no perfil e processo de autenticação do usuário.
														</p>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>

								<Card>
									<CardHeader>
										<CardLabel icon='Edit' iconColor='primary'>
											<CardTitle className='h5'>
												Dados Pessoais
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-4'>

											<div>
												<FormGroup
													id='id'
													isFloating>
													<Input
														onChange={formikUser.handleChange}
														value={formikUser.values.id}
														hidden={true}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-12 col-md-6 col-sm-12'>
												<FormGroup
													id='fullName'
													label='Nome Completo'
													isFloating
												>
													<Input
														placeholder='Nome Completo'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.fullName)}
														isValid={formikUser.isValid}
														isTouched={formikUser.touched.fullName}
														invalidFeedback={
															formikUser.errors.fullName
														}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='birthDate'
													label='Data de Nascimento'
													isFloating>
													<Input
														type="date"
														onChange={formikUser.handleChange}
														value={formikUser.values.birthDate}
														isValid={formikUser.isValid}
														isTouched={formikUser.touched.birthDate}
														invalidFeedback={
															formikUser.errors.birthDate
														}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='phone'
													label='Telefone'
													isFloating>
													<Input
														placeholder='Telefone'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.phone}
														mask="(99) 99999-9999"
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='document'
													label='CPF'
													isFloating>
													<Input
														placeholder="CPF"
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.document}
														mask="999.999.999-99"
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='nationalId'
													label='RG'
													isFloating>
													<Input
														placeholder="RG"
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.nationalId}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='nationalIdSender'
													label='Órgão Emissor'
													isFloating>
													<Input
														placeholder="Órgão Emissor"
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.nationalIdSender}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='dateOfIssue'
													label='Data de Emissão'
													isFloating>
													<Input
														type="date"
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.dateOfIssue}
													/>
												</FormGroup>
											</div>

											{
												id && (
													<>

														<div className='col-lg-6 col-md-6 col-sm-12'>
															<FormGroup
																id='userName'
																label='Usuário'
																hidden={true}
																isFloating>
																<Input
																	placeholder='Usuário'
																	onChange={formikUser.handleChange}
																	value={formikUser.values.userName}
																	disabled={true}
																/>
															</FormGroup>
														</div>

														<div className='col-lg-6 col-md-6 col-sm-12'>
															<FormGroup
																id='email'
																label='E-mail'
																hidden={true}
																isFloating>
																<Input
																	type='email'
																	placeholder='E-mail'
																	onChange={formikUser.handleChange}
																	value={formikUser.values.email}
																	disabled={true}
																/>
															</FormGroup>
														</div>
													</>
												)
											}
										</div>
									</CardBody>
								</Card>

							</CardBody>
						</Card>

					)}

					{TABS.ADDITIONAL_DATA === activeTab && (
						<Card stretch noValidate>
							<CardHeader>
								<CardLabel icon='PostAdd' iconColor='primary'>
									<CardTitle className='h5'>
										Dados Adicionais
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>

								<Card>
									<CardHeader>
										<CardLabel icon='PersonOutline' iconColor='primary'>
											<CardTitle className='h5'>
												Responsável
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-4'>

											<div className='col-lg-12 col-md-12 col-sm-12'>
												<FormGroup
													id='responsableName'
													label='Nome'
													isFloating>
													<Input
														placeholder='Nome'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.responsableName)}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='responsablePhone'
													label='Telefone'
													isFloating>
													<Input
														placeholder='Telefone'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.responsablePhone}
														mask="(99) 99999-9999"
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='responsableKinship'
													label='Parentesco'
													isFloating>
													<Input
														placeholder='Parentesco'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.responsableKinship)}
													/>
												</FormGroup>
											</div>

										</div>
									</CardBody>
								</Card>

								<Card>
									<CardHeader>
										<CardLabel icon='Emergency' iconColor='primary'>
											<CardTitle className='h5'>
												Emergência
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-4'>

											<div className='col-lg-12 col-md-12 col-sm-12'>
												<FormGroup
													id='emergencyName'
													label='Nome'
													isFloating>
													<Input
														placeholder='Nome'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.emergencyName)}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='emergencyPhone'
													label='Telefone'
													isFloating>
													<Input
														placeholder='Telefone'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.emergencyPhone}
														mask="(99) 99999-9999"
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='emergencyKinship'
													label='Parentesco'
													isFloating>
													<Input
														placeholder='Parentesco'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.emergencyKinship)}
													/>
												</FormGroup>
											</div>

										</div>
									</CardBody>
								</Card>

							</CardBody>
						</Card>
					)}

					{TABS.ADDRESS === activeTab && (

						<Card stretch noValidate>
							<CardHeader>
								<CardLabel icon='Place' iconColor='primary'>
									<CardTitle className='h5'>
										Endereço
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>

								<Card>
									<CardBody>
										<div className='row g-4'>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='address'
													label='Endereço'
													isFloating>
													<Input
														placeholder='Endereço'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.address)}
														isValid={formikUser.isValid}
														isTouched={formikUser.touched.address}
														invalidFeedback={
															formikUser.errors.address
														}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='neighborhood'
													label='Bairro'
													isFloating>
													<Input
														placeholder='Bairro'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.neighborhood)}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='state'
													label='Estado'
													isFloating>
													<Select
														ariaLabel='Escolha um estado'
														placeholder='Escolha um estado'
														list={states}

														onChange={(e: { target: { value: any } }) => {
															formikUser.handleChange(e);

															if (e.target.value) {
																handleStateOnChange(e.target.value)
															}
														}}

														onBlur={formikUser.handleBlur}
														value={formikUser.values.state}
														isValid={formikUser.isValid}
														isTouched={formikUser.touched.state}
														invalidFeedback={
															formikUser.errors.state
														}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='city'
													label='Cidade'
													isFloating>
													<Select
														ariaLabel='Escolha uma cidade'
														placeholder='Escolha uma cidade'
														list={selectedCities}
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.city}
														isValid={formikUser.isValid}
														isTouched={formikUser.touched.city}
														disabled={disableCity}
														invalidFeedback={
															formikUser.errors.city
														} />
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='zipCode'
													label='CEP'
													isFloating>
													<Input
														placeholder='CEP'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.zipCode}
														mask="99.999-999"
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='country'
													label='País'
													isFloating>
													<Input
														placeholder='País'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.country)}
														isTouched={formikUser.touched.country}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='observation'
													label='Complemento'
													isFloating>
													<Input
														placeholder='Complemento'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.observation)}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</CardBody>
						</Card>
					)}

					{TABS.ATHLETE_DETAIL === activeTab && (
						<Card stretch noValidate>
							<CardHeader>
								<CardLabel icon='SportsKabaddi' iconColor='primary'>
									<CardTitle className='h5'>
										Detalhes do Atleta
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<Card>
									<CardBody>
										<div className='row g-4'>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='belt'
													label='Faixa'
													isFloating>
													<Select
														ariaLabel='Escolha uma faixa'
														placeholder='Escolha uma faixa'
														list={belts}
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.belt}
													/>
												</FormGroup>
											</div>


											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='grade'
													label='Grau'
													isFloating>
													<Select
														ariaLabel='Escolha um grau'
														placeholder='Escolha um grau'
														list={
															[
																{ value: '0', text: 'Sem Grau' },
																{ value: '1', text: 'Grau 1' },
																{ value: '2', text: 'Grau 2' },
																{ value: '3', text: 'Grau 3' },
																{ value: '4', text: 'Grau 4' },
															]
														}
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.grade}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='lastGrade'
													label='Ultima Graduacao'
													isFloating>
													<Input
														type="date"
														ariaLabel='Ultima Graduacao'
														placeholder='Ultima Graduacao'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={formikUser.values.lastGrade}
													/>
												</FormGroup>
											</div>

											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='ibjjfId'
													label='IBJJF'
													isFloating>
													<Input
														placeholder='IBJJF'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.ibjjfId)}
													/>
												</FormGroup>
											</div>


											<div className='col-lg-6 col-md-6 col-sm-12'>
												<FormGroup
													id='compnetId'
													label='CompNet'
													isFloating>
													<Input
														placeholder='CompNet'
														onChange={formikUser.handleChange}
														onBlur={formikUser.handleBlur}
														value={removeAccents(formikUser.values.compnetId)}
													/>
												</FormGroup>
											</div>

										</div>
									</CardBody>
								</Card>

							</CardBody>
						</Card>
					)}

					{TABS.SUBSCRIPTION === activeTab && userData.isAdmin && (

						<Card stretch noValidate>
							<CardHeader>
								<CardLabel icon='LocalOffer' iconColor='primary'>
									<CardTitle className='h5'>
										Planos
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-4'>

									{activeSubscriptionTypes.map((subscriptionType: ISubscriptionType) => (

										<div className='col-md-6 col-sm-12' key={subscriptionType.id}>
											<Card
												className={subscriptionType.selected ? 'bg-l25-success' : ''}
											>
												<CardHeader className='bg-transparent'>
													<CardLabel>
														<CardTitle tag='div' className='h3 text-break'>
															{subscriptionType.description}
														</CardTitle>
													</CardLabel>
												</CardHeader>


												<CardBody>

													<div className='d-flex align-items-center pb-3'>
														<div className='flex-shrink-0'>
															<Icon icon='SportsKabaddi' size='4x' color='primary' />
														</div>
														<div className='flex-grow-1 ms-3 display-6 fw-bold text-break'>
															{priceFormat(subscriptionType.fee ?? 0)}
														</div>
													</div>
												</CardBody>
												<CardFooter className='bg-transparent'>
													<CardFooterRight>
														<Button
															color='primary'
															onClick={() => selectSubscription(subscriptionType)}>
															{subscriptionType.selected ? 'Desmatricular' : 'Matricular'}
														</Button>
													</CardFooterRight>
												</CardFooter>
											</Card>
										</div>
									))}
								</div>
							</CardBody>
						</Card>
					)}

					{TABS.DOCUMENTS === activeTab && (
						<CommonFileUpload onUpdateDocuments={onUpdateDocuments} uploadedFiles={selectedStudent?.documents || []} />
					)}
				</div>

				<Modal
					setIsOpen={setOpenModal}
					isOpen={!!isModalOpen}
					isCentered={true}
				>
					<ModalHeader setIsOpen={setOpenModal}>
						<ModalTitle id='preview'>Cortar Imagem</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{!!imgSrc && (
							<ReactCrop
								// className="ReactCrop--no-animate"
								crop={crop}
								onChange={(_, percentCrop) => setCrop(percentCrop)}
								onComplete={c => setCompletedCrop(c)}
								aspect={aspect}
								circularCrop
								ruleOfThirds
							>
								<img
									ref={imgRef}
									alt="Crop me"
									src={imgSrc}
									style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
									onLoad={onImageLoad}
								/>
							</ReactCrop>
						)}
						<div>
							{!!completedCrop && (
								<canvas
									ref={previewCanvasRef}
									style={{
										border: '1px solid black',
										objectFit: 'contain',
										width: completedCrop.width,
										height: completedCrop.height,
										display: 'none' // Hide the canvas
									}}
								/>
							)}
						</div>
					</ModalBody>
					<ModalFooter className='bg-transparent'>
						<Button
							color='primary'
							className='w-100'
							onClick={setImgSrcAsFile}
							isDisable={isSubmitting}
						>
							{isSubmitting && (
								<Spinner isSmall inButton isGrow />
							)}
							Cortar
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	);
};

export default CommonStudent;