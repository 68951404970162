enum DaysOfWeek {
	Monday = 0,
	Tuesday = 1,
	Wednesday = 2,
	Thursday = 3,
	Friday = 4,
	Saturday = 5,
	Sunday = 6,
}

interface IDaysOfWeek {
	value?: number;
	text: string;
}

export const DAYS_OF_WEEK: IDaysOfWeek[] = [
	{ value: DaysOfWeek.Sunday, text: 'Domingo' },
	{ value: DaysOfWeek.Monday, text: 'Segunda-feira' },
	{ value: DaysOfWeek.Tuesday, text: 'Terça-feira' },
	{ value: DaysOfWeek.Wednesday, text: 'Quarta-feira' },
	{ value: DaysOfWeek.Thursday, text: 'Quinta-feira' },
	{ value: DaysOfWeek.Friday, text: 'Sexta-feira' },
	{ value: DaysOfWeek.Saturday, text: 'Sábado' },
];