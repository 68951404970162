import { useContext, useEffect, useMemo, useState } from 'react';
import { showWarning } from '../components/extras/Notifications';
import AuthContext from '../contexts/authContext';
import cognitoService from '../services/cognito-service';

export const useStudent = () => {
	const { cognitoId } = useContext(AuthContext);

	const [student, setStudent] = useState<IStudent | undefined>(undefined);
	const [fetchingStudent, setFetchingStudent] = useState<boolean>(false);

	const fetchStudent = async () => {
		setFetchingStudent(true);

		const { student, error } = await cognitoService.getById(cognitoId);
		if (error) {
			showWarning(error);
			return;
		}

		setStudent(student);
		setFetchingStudent(false);
	};

	const getStudentPaymentUnderReview = async () => {
		const { student, error } = await cognitoService.getById(cognitoId);
		if (error) {
			showWarning(error);
			return;
		}
		setStudent(student);
	};

	const getStudentByCognitoId = useMemo(() => fetchStudent, [cognitoId]);

	useEffect(() => {
		if (!cognitoId) {
			return;
		}

		const fetchData = async () => {
			await getStudentByCognitoId();
		};

		fetchData();
	}, [cognitoId]);

	return { student, fetchStudent, fetchingStudent, getStudentPaymentUnderReview };
};
