import { GET_ASYNC, GET_TYPED_ASYNC } from '../components/extras/HttpHelper';

export interface SubscriptionTypesResponse {
	subscriptionTypes?: ISubscriptionType[];
	error?: string;
}

const getAllSubscriptionTypes = async (): Promise<SubscriptionTypesResponse> => {
	try {
		const response = await GET_ASYNC('api/NSubscriptionType/GetAll');
	
		if (response && response.success) {
			return { subscriptionTypes: response.collection };
		} else {
			return { error: response.message };
		}
	} catch (error: any) {
		console.error(error);
		return { error: error.message };
	}
};

const getAllActives = async (): Promise<SubscriptionTypesResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<ISubscriptionType>('api/NSubscriptionType/GetActive');
	
		if (response && response.success) {
			return { subscriptionTypes: response.collection };
		} else {
			return { error: response.message };
		}
		
	} catch (error: any) {
		console.error(error);
		return { error: error.message };
	}
};

export default {
	getAllActives,
	getAllSubscriptionTypes,
};
