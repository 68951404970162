import { lazy } from 'react';
import {
	appMenu,
	authMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	hiddenMenu,
	pageLayoutTypesPagesMenu,
	userMenu,
} from '../menu';
import AttendanceMaintenanceList from '../pages/presentation/attendance/AttendanceMaintenanceList';
import ClassCheckinDetailPage from '../pages/presentation/attendance/ClassCheckinDetailPage';
import ClassCheckinPage from '../pages/presentation/attendance/ClassCheckinPage';
import FacialCheckinPage from '../pages/presentation/attendance/FacialCheckinPage';
import Login from '../pages/presentation/auth/Login';
import LoginV2 from '../pages/presentation/auth/LoginV2';
import CoursesPage from '../pages/presentation/courses/CoursesPage';
import CashFlowAccountGroupPage from '../pages/presentation/financial/CashFlowAccountGroupPage';
import CashFlowAccountPage from '../pages/presentation/financial/CashFlowAccountPage';
import CashFlowPage from '../pages/presentation/financial/CashFlowPage';
import FinancialPage from '../pages/presentation/financial/FinancialPage';
import PaymentTypePage from '../pages/presentation/financial/PaymentTypePage';
import InstructorDetail from '../pages/presentation/instructor/InstructorDetail';
import PaymentMaintenanceList from '../pages/presentation/payment/PaymentMaintenanceList';
import Payments from '../pages/presentation/payment/Payments';
import Subscriptions from '../pages/presentation/register/subscriptions/Subscriptions';
import BirthdaysReportPage from '../pages/presentation/report/BirthdaysReportPage';
import CashFlowEntryReportPage from '../pages/presentation/report/CashFlowEntryReportPage';
import LastAttendanceReportPage from '../pages/presentation/report/LastAttendanceReportPage';
import MostAttendanceReportPage from '../pages/presentation/report/MostAttendanceReportPage';
import StudentActivityReportPage from '../pages/presentation/report/StudentActivityReportPage';
import UpcomingGraduations from '../pages/presentation/report/UpcomingGraduations';
import UserWithoutSubscriptionReportPage from '../pages/presentation/report/UserWithoutSubscriptionReportPage';
import SchedulePage from '../pages/presentation/schedule/SchedulePage';
import AddonsPage from '../pages/presentation/settings/AddonsPage';
import SettingsPage from '../pages/presentation/settings/SettingsPage';
import StudentDetail from '../pages/presentation/student/StudentDetail';
import StudentsPage from '../pages/presentation/student/StudentsPage';
import SubscriptionTypePage from '../pages/presentation/subscription/SubscriptionTypePage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const FINANCIAL = {
	FINANCIAL_INFO: lazy(() => import('../pages/presentation/financial/FinancialPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.loginv2.path,
		element: <LoginV2 />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},

	/*
		App Menu
	*/
	{
		path: appMenu.registers.subMenu.subscriptions.path,
		element: <SubscriptionTypePage />,
	},
	// {
	// 	path: appMenu.registers.subMenu.students.path,
	// 	element: <StudentPage />
	// },
	{
		path: appMenu.registers.subMenu.classCheckin.path,
		element: <ClassCheckinPage />,
	},
	{
		path: appMenu.registers.subMenu.courses.path,
		element: <CoursesPage />,
	},

	//Register
	// {
	// 	path: appMenu.registers.subMenu.leadsAndProspects.path,
	// 	element: <LeadsList />,
	// },
	// {
	// 	path: appMenu.registers.subMenu.classes.path,
	// 	element: <SchoolClasses />,
	// },
	{
		path: appMenu.registers.subMenu.schedule.path,
		element: <SchedulePage />,
	},
	// {
	// 	path: appMenu.registers.subMenu.message.path,
	// 	element: <Message />,
	// },
	{
		path: appMenu.registers.subMenu.subscriptions.path,
		element: <Subscriptions />,
	},
	{
		path: appMenu.registers.subMenu.students.path,
		element: <StudentsPage />,
	},
	// {
	// 	path: appMenu.registers.subMenu.instructors.path,
	// 	element: <InstructorsPage />,
	// },
	// {
	// 	path: appMenu.registers.subMenu.teachers.path,
	// 	element: <InstructorPage />,
	// },

	//Management

	{
		path: appMenu.management.subMenu.attendanceMaintenance.path,
		element: <AttendanceMaintenanceList />,
	},

	{
		path: appMenu.management.subMenu.paymentMaintenance.path,
		element: <PaymentMaintenanceList />,
	},
	// {
	// 	path: appMenu.management.subMenu.classEvaluation.path,
	// 	element: <ClassEvaluation />,
	// },
	// {
	// 	path: appMenu.management.subMenu.message.path,
	// 	element: <ManagementMessage />,
	// },
	// {
	// 	path: appMenu.management.subMenu.digitalMedia.path,
	// 	element: <DigitalMedia />,
	// },
	{
		path: appMenu.financialControl.subMenu.monthlyPayments.path,
		element: <FinancialPage />,
	},
	{
		path: appMenu.financialControl.subMenu.cashFlowAccount.path,
		element: <CashFlowAccountPage />,
	},
	{
		path: appMenu.financialControl.subMenu.cashFlow.path,
		element: <CashFlowPage />,
	},
	{
		path: appMenu.financialControl.subMenu.cashFlowAccountGroup.path,
		element: <CashFlowAccountGroupPage />,
	},
	{
		path: appMenu.financialControl.subMenu.paymentType.path,
		element: <PaymentTypePage />,
	},
	// {
	// 	path: appMenu.financialControl.subMenu.billingPeriod.path,
	// 	element: <BillingPeriodPage />,
	// },
	{
		path: hiddenMenu.students.path,
		element: <StudentDetail />,
	},
	 {
	 	path: userMenu.schedule.path,
	 	element: <SchedulePage />,
	 },
	 {
	 	path: userMenu.payments.path,
	 	element: <Payments />,
	 },
	{
		path: hiddenMenu.instructors.path,
		element: <InstructorDetail />,
	},
	// {
	// 	path: hiddenMenu.classCheckinItem.path,
	// 	element: <ClassCheckinItem />,
	// },
	{
		path: hiddenMenu.classCheckinDetail.path,
		element: <ClassCheckinDetailPage />,
	},
	{
		path: hiddenMenu.facialCheckin.path,
		element: <FacialCheckinPage />,
	},
	{
		path: authMenu.login.path,
		element: <Login />,
	},
	{
		path: authMenu.loginV2.path,
		element: <LoginV2 />,
	},

	// {
	// 	path: userMenu.myAppointment.path,
	// 	element: <CalendarPage />
	// },

	//Report
	{
		path: appMenu.reports.subMenu.upgcomingGraduations.path,
		element: <UpcomingGraduations />,
	},
	{
		path: appMenu.reports.subMenu.birthdays.path,
		element: <BirthdaysReportPage />,
	},
	{
		path: appMenu.reports.subMenu.studentHistory.path,
		element: <StudentActivityReportPage />,
	},
	{
		path: appMenu.reports.subMenu.frequency.path,
		element: <MostAttendanceReportPage />,
	},
	{
		path: appMenu.reports.subMenu.absences.path,
		element: <LastAttendanceReportPage />,
	},
	{
		path: appMenu.reports.subMenu.noSubscription.path,
		element: <UserWithoutSubscriptionReportPage />,
	},
	// {
	// 	path: appMenu.reports.subMenu.teachers.path,
	// 	element: <InstructorReportPage />
	// },
	{
		path: appMenu.reports.subMenu.cashFlowEntries.path,
		element: <CashFlowEntryReportPage />,
	},

	{
		path: appMenu.settings.subMenu.addon.path,
		element: <AddonsPage />,
	},
	{
		path: appMenu.settings.subMenu.settings.path,
		element: <SettingsPage />,
	},
];
const contents = [...presentation];

export default contents;
