import Button from '../../../components/bootstrap/Button';
import { CardActions, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import { StudentsFilter } from './StudentsFilter';
import { FilterFormValues } from './types';

interface Props {
	subscriptionTypes: ISubscriptionType[];
	onFilterChange: (values: FilterFormValues) => void;
	onCreateNewStudent: () => void;
}

const StudentsPageHeader = ({ subscriptionTypes, onFilterChange, onCreateNewStudent }: Props) => {
	return (
		<CardHeader borderSize={1}>
			<CardLabel icon='EmojiPeople' iconColor='primary'>
				<CardTitle tag='div' className='h5'>
					Alunos
				</CardTitle>
			</CardLabel>
			<CardActions>
				<Button color='success' icon='AddCircle' isLight onClick={onCreateNewStudent}>
					Adicionar
				</Button>

				<StudentsFilter
					subscriptionTypes={subscriptionTypes}
					onFilterChange={onFilterChange}
				/>
			</CardActions>
		</CardHeader>
	);
};

export default StudentsPageHeader;
