import { useEffect, useState } from 'react';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';
import { PagesLayout } from '../../../layout/PagesLayout';
import schedulesService from '../../../services/schedules-service';
import ScheduleCard from './components/ScheduleCard';
import { ScheduleDetail } from './components/ScheduleDetail';
import { ScheduleModal } from './components/SchedulePageModal';

export const SchedulePage = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [schedules, setSchedules] = useState<IParseSchedule[]>([]);
	const [selectedSchedule, setSelectedSchedule] = useState<IParseSchedule | null>(null);
	const [toggleScheduleDetail, setToggleScheduleDetail] = useState(false);

	useEffect(() => {
		fetchSchedules();
	}, []);

	const fetchSchedules = async () => {
		const response = await schedulesService.listAllSchedules();
		if (response.error) {
			showWarning(response.error);
			return;
		}
		if (response.schedule) {
			setSchedules(response.schedule);
		}
	};

	const deleteSchedule = async (id?: string) => {

		if(!id) {
			showWarning('Código da aula do cronograma não encontrado');
			return;
		}

		const response = await schedulesService.deleteSchedule(id);

		if (response && response.success) {
			onDetailToggle(false);
			await fetchSchedules();
			showSuccess('Aula do cronograma removida com sucesso');
		} else {
			showWarning(response.error || 'Falha ao remover aula do cronograma');
		}
	}


	const toggleModal = (isVisible: boolean) => {
		setIsModalOpened(isVisible);
	};

	const handleAddScheduleClick = () => {
		toggleModal(true);
	};

	const onModalClose = () => {
		toggleModal(false);
	};

	const onAfterSubmit = async () => {
		await fetchSchedules();
		onModalClose();
	};

	const onDetailToggle = (toggle: boolean) => setToggleScheduleDetail(toggle);

	const onScheduleClick = (schedule: IParseSchedule) => {
		setSelectedSchedule(schedule);
		setToggleScheduleDetail(true);
	};

	return (
		<PagesLayout title='Cronograma'>
			<ScheduleCard
				schedules={schedules}
				handleAddScheduleClick={handleAddScheduleClick}
				onScheduleClick={onScheduleClick}
			/>
			<ScheduleDetail
				isOpen={toggleScheduleDetail}
				onDetailToggle={onDetailToggle}
				selectedSchedule={selectedSchedule}
				onScheduleCourseDelete={(id) => deleteSchedule(id)}
			/>
			<ScheduleModal
				isModalOpened={isModalOpened}
				onModalClose={onModalClose}
				onAfterSubmit={onAfterSubmit}
			/>
		</PagesLayout>
	);
};

export default SchedulePage;
