import classNames from "classnames";
import { FC } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";

interface ICommonExpectedSubscriptionValueProps {
    expectedValue: number;
}

const ExpectedSubscriptionValue: FC<ICommonExpectedSubscriptionValueProps> = ({ expectedValue }) => {

    const { darkModeStatus } = useDarkMode();

    return (
        <Card
            className={classNames('transition-base rounded-2 mb-4 text-dark', {
                'bg-l25-success bg-l10-success-hover': !darkModeStatus,
                'bg-lo50-success bg-lo25-success': darkModeStatus,
            })}
            shadow='sm'>
            <CardHeader className='bg-transparent'>
                <CardLabel>
                    <CardTitle tag='div' className='h5'>
                        Receita Mensal Prevista
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='d-flex align-items-center pb-3'>
                    <div className='flex-shrink-0'>
                        <Icon icon='AttachMoney' size='4x' color='success' />
                    </div>
                    <div className='flex-grow-1 ms-3'>
                        <div className='fw-bold fs-3 mb-0'>
                            { priceFormat(expectedValue) }
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
};

ExpectedSubscriptionValue.defaultProps = {
    expectedValue: 0
};


export default ExpectedSubscriptionValue;