import { useEffect, useState } from 'react';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';
import { PagesLayout } from '../../../layout/PagesLayout';
import coursesService from '../../../services/courses-service';
import LoadingPage from '../loading/LoadingPage';
import { CoursesCard } from './components/CoursesCard';
import { CoursesModal } from './components/CoursesModal';

const CoursesPage = () => {
	const [courses, setCourses] = useState<ICourse[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);

	const fetchCourses = async () => {
		const response = await coursesService.getAllCourses();
		setIsLoading(false);

		if (response.error) {
			showWarning(response.error);
			return;
		}
		setCourses(response.courses || []);
	};

	useEffect(() => {
		fetchCourses();
	}, []);

	if (isLoading) {
		return <LoadingPage />;
	}

	const toggleModal = (isVisible: boolean) => {
		setIsModalOpened(isVisible);
	};

	const handleAddCourseClick = () => {
		toggleModal(true);
	};

	const onModalClose = () => {
		toggleModal(false);
		setSelectedCourse(null);
	};

	const onAfterSubmit = async () => {
		await fetchCourses();
		onModalClose();
	};

	const handleDeleteCourse = async (id: string) => {
		const response = await coursesService.deleteCourse(id);
		if (response.error) {
			showWarning(response.error);
			return;
		}
		showSuccess('Aula deletada com sucesso');
		await fetchCourses();
	};

	const handleDetaisClick = (course: ICourse) => {
		setSelectedCourse(course);
		toggleModal(true);
	};

	return (
		<PagesLayout title='Aulas'>
			<CoursesCard
				courses={courses}
				handleAddCourseClick={handleAddCourseClick}
				handleDeleteCourseClick={handleDeleteCourse}
				handleCourseDetailClick={handleDetaisClick}
			/>
			<CoursesModal
				isModalOpened={isModalOpened}
				onModalClose={onModalClose}
				onAfterSubmit={onAfterSubmit}
				selectedCourse={selectedCourse}
			/>
		</PagesLayout>
	);
};

export default CoursesPage;
