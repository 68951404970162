import { RouteProps } from 'react-router-dom';
import { authMenu, demoPagesMenu, hiddenMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.loginV2.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: hiddenMenu.facialCheckin.path, element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;

