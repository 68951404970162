import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { appMenu } from "../../../menu";
import CommonClassCheckinDetail from "./common/CommonClassCheckinDetail";

const ClassCheckinDetailPage = () => {

	return (
		<PageWrapper title='Detalhes da Fotos do Treino'>

			<SubHeader>
				<SubHeaderLeft>
					<Button
						color='primary'
						isLink
						icon='ArrowBack'
						tag='a'
						to={`../${appMenu.registers.subMenu.classCheckin.path}`}>
						Voltar
					</Button>
				</SubHeaderLeft>
			</SubHeader>

			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<CommonClassCheckinDetail />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ClassCheckinDetailPage;