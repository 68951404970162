import { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../../components/bootstrap/Dropdown';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import { FilterForm } from '../../../../components/common/FilterForm';
import { FILTER_FORM_INITIAL_VALUES } from './CommonStudent.const';
import { PaidStudentsFilterFormValues } from './CommonStudents.types';

interface Props {
	onFilterChange: (values: PaidStudentsFilterFormValues) => void;
}

export const CommonStudentsFilter = ({ onFilterChange }: Props) => {
	const nameFilterRef = useRef<HTMLInputElement>(null);

	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [filterValues, setFilterValues] = useState<PaidStudentsFilterFormValues>(
		FILTER_FORM_INITIAL_VALUES,
	);

	// Adicionei isso pra colocar o foco quando o filtro abrir
	useEffect(() => {
		if (isFilterOpened && nameFilterRef.current) {
			nameFilterRef.current.focus();
		}
	}, [isFilterOpened]);

	const onFormSubmit = (values: PaidStudentsFilterFormValues): void | Promise<any> => {
		onFilterChange(values);
		setFilterValues(values);
		setIsFilterOpened(false);
	};

	const onFormClear = () => {
		setFilterValues(FILTER_FORM_INITIAL_VALUES);
	};

	return (
		<Dropdown isOpen={isFilterOpened} setIsOpen={setIsFilterOpened} isButtonGroup>
			<DropdownToggle hasIcon={false}>
				<Button isLink={true} icon='FilterAlt' color='primary'>
					Filtrar
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd size='xl' isCloseAfterLeave={false}>
				<FilterForm<PaidStudentsFilterFormValues>
					initialValues={filterValues}
					onSubmit={onFormSubmit}
					onClear={onFormClear}>
					{(filterForm) => (
						<>
							<FormGroup id='nameFilter' label='Nome'>
								<Input
									ref={nameFilterRef}
									onChange={filterForm.handleChange}
									value={filterForm.values.nameFilter}
									placeholder='Digite o nome do aluno'
								/>
							</FormGroup>
						</>
					)}
				</FilterForm>
			</DropdownMenu>
		</Dropdown>
	);
};
