import { useState } from 'react';
import { DEFAULT_PAGE_COUNT, PER_COUNT } from './utils';

interface TableProps {
	head: React.ReactNode;
	body: React.ReactNode;
	tableClassNames?: string;
}

const Table = ({ head, body, tableClassNames }: TableProps) => {
	return (
		<table className={`table table-modern h-100 w-100 ${tableClassNames}`}>
			<thead>{head}</thead>
			<tbody>{body}</tbody>
		</table>
	);
};

export default Table;
