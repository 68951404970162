import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import { showError, showSuccess, showWarning } from '../../../components/extras/Notifications';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { CardBodyTable } from '../../../components/table/CardBodyTable';
import { priceFormat } from '../../../helpers/helpers';
import { PagesLayout } from '../../../layout/PagesLayout';
import financialService from '../../../services/financial-service';
import CommonBadgeStatus from '../attendance/common/CommonBadgeStatus';
import LoadingPage from '../loading/LoadingPage';

const PaymentMaintenanceList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [payments, setPayments] = useState<IPayment[]>([]);
	const [student, setStudent] = useState<IStudent>();

	useEffect(() => {
		fetchUnderReviewPayments();
	}, []);

	const fetchUnderReviewPayments = async () => {
		setIsLoading(true);

		const response = await financialService.getUnderReviewPayments();


		setIsLoading(false);

		if (response.error) {
			showWarning(response.error);
			return;
		}
		if (response.payment) {
			setPayments(response.payment);
		}
	};


    
	const handleApproveSubmit = async (payments: any ) => {

		const response = await financialService.updateStudentPayment(payments.id, payments as IPayment);
		if (response && response.success) {
            fetchUnderReviewPayments();

            showSuccess('Pagamento aprovado com sucesso')
        } else {
            showError('Erro ao aprovar pagamento');
        }

	};

	// const getByCognitoId = async (id: string) => {
	//     setIsLoading(true);

	//     const response = await GET_ASYNC(`api/NStudent/GetByCognitoId/${cognitoId}`);
	//     setIsLoading(false);

	//     if (response && response.success) {
	//         setStudent(response.entity);
	//     } else {
	//         showWarning(response.message);
	//     }
	// };

	const handleCancelSubmit = (payments: any) => {

		financialService.deleteStudentPayment(payments.id)
			.then((response) => {
				if (response.success) {
					showNotification(
						'Cancelar', // String, HTML or Component
						'Pagamento cancelado com sucesso', // String, HTML or Component
						'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);
					window.location.reload();
				} else {
					showWarning(response?.error || 'Erro ao cancelar pagamento');
				}
			})
	};

	if (isLoading) {
		return <LoadingPage />;
	}


	return (
		<PagesLayout title='Aprovação de Pagamentos'>
			<Card style={{ minHeight: 400 }}>
				<CardHeader borderSize={1}>
					<CardLabel icon='Payments' iconColor='primary'>
						<CardTitle tag='div' className='h5'>
							Aprovação de Pagamentos
						</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBodyTable
					tableClassNames='table-hover'
					data={payments}
					head={
						<tr>
							<th
								className='cursor-pointer text-decoration-underline'>
								Data{' '}
								<Icon
									size='lg'
									icon='FilterList'
								/>
							</th>
							<th
								className='cursor-pointer text-decoration-underline'>
								Valor{' '}
								<Icon
									size='lg'
									icon='FilterList'
								/>
							</th>
							<th
								className='cursor-pointer text-decoration-underline'>
								Aluno{' '}
								<Icon
									size='lg'
									icon='FilterList'
								/>
							</th>
							<th
								className='cursor-pointer text-decoration-underline'>
								Descrição{' '}
								<Icon
									size='lg'
									icon='FilterList'
								/>
							</th>
							<th>Situação</th>
							<th></th>
						</tr>
					}
					body={(item, index) => (
						<tr key={index}>
							<td>{dayjs(item.paymentDate).format('DD/MM/YYYY')}</td>
							<td>{priceFormat(item.amount)}</td>
							<td>{item.student.fullName}</td>
							<td>{'Foi enviado uma notificação para o administrador realizar a aprovação'}</td>
							<td>
								<CommonBadgeStatus status={!item.underReview} />
							</td>

							<td className="text-end">
								<Dropdown direction='up'>
									<DropdownToggle hasIcon={false}>
										<Button
											icon='MoreHoriz'
											color='dark'
											isLight
											shadow='sm'
											aria-label='Mais Ações'
										/>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem>
											<Button
												id='approve'
												isOutline={false}
												color='dark'
												isLight={true}
												className={classNames('text-nowrap', {
													'border-light': !true,
												})}
												icon='CheckCircle'
												onClick={() => handleApproveSubmit(item)}>
												Aprovar
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												id='cancel'
												isOutline={false}
												color='dark'
												isLight={true}
												className={classNames('text-nowrap', {
													'border-light': !true,
												})}
												icon='Cancel'
												onClick={() => handleCancelSubmit(item)}>
												Reprovar
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</td>

						</tr>
					)}
				/>
			</Card>

		</PagesLayout>
	);
};

export default PaymentMaintenanceList;
