import { FC } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import CommonCashFlowEntryList from "./common/CommonCashFlowEntryList";

const CashFlowEntryReportPage: FC = () => {

    return (
        <PageWrapper title='Fluxo de Caixa'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonCashFlowEntryList />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default CashFlowEntryReportPage;