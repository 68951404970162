import { FC } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import CommonPaymentTransactionItem from "./CommonPaymentTransactionItem";

interface IPaymentTransactionProps {
	student?: IStudent
}

const CommonPaymentTransaction: FC<IPaymentTransactionProps> = ({ student }) => {

	return (
		<Card stretch >
			<CardHeader>
				<CardLabel icon='Receipt' iconColor='primary'>
					<CardTitle tag='div' className='h5'>
						Últimas Mensalidades
					</CardTitle>
				</CardLabel>

			</CardHeader>
			<CardBody isScrollable>
				<div className='row g-4'>
					{student?.payments?.map((i, index) => (
						<CommonPaymentTransactionItem key={index} {...i} />
					))}
				</div>
			</CardBody>
		</Card>
	);
};

CommonPaymentTransaction.defaultProps = {
	student: undefined
};

export default CommonPaymentTransaction;