import classNames from "classnames";
import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Avatar from "../../../../components/Avatar";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../components/bootstrap/forms/Select";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import Spinner from "../../../../components/bootstrap/Spinner";
import { GET_ASYNC, POST_ASYNC } from "../../../../components/extras/HttpHelper";
import { showError, showSuccess, showWarning } from "../../../../components/extras/Notifications";
import LoadingPage from "../../loading/LoadingPage";
import CommonFaceBox from "./CommonFaceBox";

const CommonClassCheckinDetail: FC = () => {

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { itemName, fileExtension } = useLocation().state;

    const [faces, setFaces] = useState<IPhoto[]>([]);
    const [userAttendanceOffcanvas, setUserAttendanceOffcanvas] = useState(false);
    const [students, setStudents] = useState<IStudent[]>([]);
    const [student, setStudent] = useState<IStudent>();
    const [photoId, setPhotoId] = useState<string>();

    const [knownFaces, setKnownfaces] = useState<IPhoto[]>([]);
    const [unknownFaces, setUnknownFaces] = useState<IPhoto[]>([]);

    const formik = useFormik({
        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            return undefined;
        },
        initialValues: {
            userId: ''
        },
    });

    useEffect(() => {
        getByMainPhotoId();
        getActiveStudentsWithSubscription();
    }, []);

    const getByMainPhotoId = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`api/NPhoto/GetByMainId/${id}`);
        setIsLoading(false);

        if (response && response.success) {

            const knownFaces = response.collection.filter((face: IPhoto) => !face.name.includes("Desconhecido"));
            const unknownFaces = response.collection.filter((face: IPhoto) => face.name.includes("Desconhecido"));

            setKnownfaces(knownFaces || []);
            setUnknownFaces(unknownFaces || []);

            setFaces(response.collection);
        } else {
            showWarning(response.message);
        }
    };

    const handleChangeStudent = (id: string) => {
        const selectedStudent = students.find(student => student.id === id);
        if (selectedStudent)
            setStudent(selectedStudent);
        else
            setStudent(undefined);
    }

    const resetForm = () => {
        formik.values.userId = '';
    }

    const CreateByUnknownFace = async () => {

        setIsSubmitting(true);

        const request = {
            date: faces[0]?.checkinDate,
            userId: student?.id,
            comment: 'Check-in feito por upload de imagem',
            photoId: photoId,
            userName: student?.username
        };

        const response = await POST_ASYNC(`api/NAttendance/CreateByUnknownFace`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setUserAttendanceOffcanvas(false);
            getByMainPhotoId();

            showSuccess('Registro atualizado com sucesso')
        } else {
            showError('Falha ao inserir os registros');
        }
    };

    const getActiveStudentsWithSubscription = async () => {
        setIsLoading(true);

        const response = await GET_ASYNC(`api/NStudent/GetActiveWithSubscription`);

        setIsLoading(false);

        if (response && response.success) {
            setStudents(response.collection);
        } else {
            showWarning(response.message);
        }
    }

    const handleUserAttendanceOffcanvas = (id: string) => {
        setUserAttendanceOffcanvas(!userAttendanceOffcanvas);
        resetForm();
        setStudent(undefined);
        setPhotoId(id);
    };

    return (
        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (
                    <>

                        <Card>
                            <CardBody>
                                <div className='row'>
                                    <div className='flex-shrink-0'>

                                        <div
                                            className={classNames(
                                                'rounded-2',
                                                'mb-3',
                                                'd-flex align-items-center justify-content-center',
                                            )}>
                                            <img
                                                src={`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/Main/${itemName}.${fileExtension}`}
                                                alt=''
                                                width='50%'
                                                className='object-fit-contain'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>

                                    <div className='col-12'>
                                        <div className='display-6 fw-bold py-3'>Rostos Reconhecidos</div>
                                    </div>

                                    <div className='row mb-5'>

                                        {knownFaces.map((face) => (
                                            <div key={face.id} className='col-xl-4 col-lg-4 col-md-6'>
                                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                                <CommonFaceBox
                                                    id={face.id}
                                                    image={`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/Main/${face.path}/${face.name}.jpg`}
                                                    name={face.name}
                                                    faceImage={face.faceImage}
                                                    checkin={dayjs(face.checkinDate).format('DD/MM/YYYY')}
                                                    handleUserAttendanceOffcanvas={handleUserAttendanceOffcanvas}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='row'>

                                    <div className='col-12'>
                                        <div className='display-6 fw-bold py-3'>Rostos Desconhecidos</div>
                                    </div>

                                    <div className='row mb-5'>

                                        {unknownFaces.map((face) => (
                                            <div key={face.id} className='col-xl-4 col-lg-4 col-md-6'>
                                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                                <CommonFaceBox
                                                    id={face.id}
                                                    image={`https://${process.env.REACT_APP_FACEREKOGNITION_URL}.s3.amazonaws.com/${face.path}/${face.name}`}
                                                    name={face.name}
                                                    faceImage={face.faceImage}
                                                    checkin={dayjs(face.checkinDate).format('DD/MM/YYYY')}
                                                    handleUserAttendanceOffcanvas={handleUserAttendanceOffcanvas}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        <Modal
                            setIsOpen={setUserAttendanceOffcanvas}
                            isOpen={userAttendanceOffcanvas}
                            titleId='userAttendance'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                            size={'lg'}
                        >
                            <ModalHeader setIsOpen={setUserAttendanceOffcanvas}>
                                <OffCanvasTitle id='userAttendance'>{'Atribuir Aluno'}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>

                                <Card>
                                    <CardBody className='table-responsive'>
                                        {(student) && (
                                            <div className="row g-4">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Avatar
                                                            src={student.profileImageUrl}
                                                            srcSet={student.profileImageUrl}
                                                            color='primary'
                                                            className='rounded-circle'
                                                            shadow='sm'
                                                            size={100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <br />

                                        <div className='row g-4'>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='userId'
                                                    label='Aluno'
                                                    isFloating>
                                                    <Select
                                                        ariaLabel='Escolha um aluno'
                                                        placeholder='Escolha um aluno'
                                                        list={
                                                            students.map((student) => ({
                                                                value: student.id,
                                                                text: `${student.fullName}`,
                                                                label: `${student.fullName}`
                                                            }))
                                                        }
                                                        onChange={(e: { target: { value: any } }) => {
                                                            formik.handleChange(e);

                                                            if (e.target.value) {
                                                                handleChangeStudent(e.target.value)
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.userId}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={CreateByUnknownFace}
                                    isDisable={!student || isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Salvar
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )
            }
        </>
    );
}

export default CommonClassCheckinDetail;