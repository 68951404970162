import dayjs from "dayjs";

interface IValues {
    fullName: string;
    phone: string;
    birthDate: Date | string | undefined;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    observation: string;
    document: string;
    nationalId: string;
    nationalIdSender: string;
    dateOfIssue: Date | string | undefined;
    fathersName: string;
    mothersName: string;
    priceHour: string | undefined | number;
}

const instructorValidate = (values: IValues) => {

    const errors: IValues = {
        fullName: '',
        phone: '',
        birthDate: '',
        address: '',
        neighborhood: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        observation: '',
        document: '',
        nationalId: '',
        nationalIdSender: '',
        dateOfIssue: '',
        fathersName: '',
        mothersName: '',
        priceHour: ''

    };

    if (!values.fullName || values.fullName.trim() === '') {
        errors.fullName = 'Obrigatório';
    }

    if (!values.phone || values.phone.trim() === '') {
        errors.phone = 'Obrigatório';
    }

    if (!values.address || values.address.trim() === '') {
        errors.address = 'Obrigatório';
    }

    if (!values.city || values.city.trim() === '') {
        errors.city = 'Obrigatório';
    }

    if (!values.state || values.state.trim() === '') {
        errors.state = 'Obrigatório';
    }

    if(!values.birthDate) {
        errors.birthDate = 'Obrigatório'
    } else {
        const inputBirthDate = dayjs(values.birthDate).startOf('date');

        if(inputBirthDate.isAfter(dayjs().startOf('date')) || inputBirthDate.isSame(dayjs().startOf('date'))) {
            errors.birthDate = 'Data inválida'
        }

    }

    if (!values.fathersName || values.fathersName.trim() === '') {
        errors.fathersName = 'Obrigatório';
    }

    if (!values.mothersName || values.mothersName.trim() === '') {
        errors.mothersName = 'Obrigatório';
    }

    if (!values.document || values.document.trim() === '') {
        errors.document = 'Obrigatório';
    }

    if (!values.nationalId || values.nationalId.trim() === '') {
        errors.nationalId = 'Obrigatório';
    }

    if (!values.nationalIdSender || values.nationalIdSender.trim() === '') {
        errors.nationalIdSender = 'Obrigatório';
    }

    if(!values.dateOfIssue) {
        errors.dateOfIssue = 'Obrigatório'
    }

    return errors;
};

export default instructorValidate;