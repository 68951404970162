import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonSettings from "./common/CommonSettings";

const SettingsPage = () => {

	return (
		<PageWrapper title='Configurações'>
			<Page container='fluid'>
				<div className='row'>
					<CommonSettings />
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SettingsPage;