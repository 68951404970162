
export interface IFilterYear {
    [value: number]: { value: number; text: string };
}

export interface IFilterMonth {
    [value: string]: { value: string; text: string };
}

const FILTER_YEAR = [
    { value: 2023, text: '2023' },
    { value: 2024, text: '2024' },
    { value: 2025, text: '2025' },
    { value: 2026, text: '2026' },
    { value: 2027, text: '2027' },
    { value: 2028, text: '2028' },
    { value: 2029, text: '2029' },
    { value: 2030, text: '2030' },
];

const FILTER_MONTH = [
    { value: 1, text: 'Janeiro' },
    { value: 2, text: 'Fevereiro' },
    { value: 3, text: 'Março' },
    { value: 4, text: 'Abril' },
    { value: 5, text: 'Maio' },
    { value: 6, text: 'Junho' },
    { value: 7, text: 'Julho' },
    { value: 8, text: 'Agosto' },
    { value: 9, text: 'Setembro' },
    { value: 10, text: 'Outubro' },
    { value: 11, text: 'Novembro' },
    { value: 12, text: 'Dezembro' }
];

export function getFilterYear() {
    return FILTER_YEAR;
}

export function getFilterMonth() {
    return FILTER_MONTH;
}

export function getMonthByDigit(digit: number) {
    const monthObject = FILTER_MONTH.find((month) => month.value === digit);
    return monthObject ? monthObject.text : 'Unknown Month';
}
