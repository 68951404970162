import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { showWarning } from '../../../components/extras/Notifications';
import { DATE_FORMAT } from '../../../helpers/date';
import { PagesLayout } from '../../../layout/PagesLayout';
import attendanceService from '../../../services/attendance-service';
import studentService from '../../../services/student-service';
import LoadingPage from '../loading/LoadingPage';
import { AttendanceCard } from './components/AttendanceCard';

const AttendanceMaintenanceList = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [date, setDate] = useState<Date>(dayjs().toDate());

	const [attendances, setAttendances] = useState<IAttendance[]>([]);
	const [activeStudents, setActiveStudents] = useState<IStudent[]>([]);

	useEffect(() => {
		fetchAttendanceByDate();
		fetchActiveStudentsWithSubscription();
	}, []);

	const fetchAttendanceByDate = async (dateToFetch: Date = date) => {
		setIsLoading(true);

		const response = await attendanceService.getAttendancesByDate(
			dayjs(dateToFetch).format(DATE_FORMAT),
		);

		setIsLoading(false);

		if (response.error) {
			showWarning(response.error);
			return;
		}
		if (response.attendances) {
			setAttendances(response.attendances);
		}
	};

	const fetchActiveStudentsWithSubscription = async () => {
		setIsLoading(true);

		const response = await studentService.getActiveWithSubscription();

		setIsLoading(false);

		if (response.error) {
			showWarning(response.error);
			return;
		}
		if (response.students) {
			setActiveStudents(response.students);
		}
	};

	const onDateFilterChange = async (newDate: Date) => {
		await fetchAttendanceByDate(newDate);
		setDate(newDate);
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<PagesLayout title='Manutenção de check-in'>
			<AttendanceCard
				date={date}
				attendances={attendances}
				activeStudents={activeStudents}
				onFilterChange={onDateFilterChange}
				onAttendancesChange={fetchAttendanceByDate}
			/>
		</PagesLayout>
	);
};

export default AttendanceMaintenanceList;
