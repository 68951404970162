import {
	GET_TYPED_ASYNC,
	POST_TYPED_ASYNC,
	PUT_TYPED_ASYNC,
} from '../components/extras/HttpHelper';

export interface IGetPaymentTypesResponse {
	success: boolean;
	paymentType?: IPaymentType;
	paymentTypes?: IPaymentType[];
	error?: string;
}

type CreateRequest = Omit<IPaymentType, keyof IEntity>;
type InactivateRequest = Pick<IEntity, 'id'>;

const createPaymentType = async (request: CreateRequest): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IPaymentType>(`api/NPaymentType/Create`, request);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getPaymentTypes = async (): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IPaymentType>('api/NPaymentType/GetAll');

		if (response && response.success) {
			return { success: response.success, paymentTypes: response.collection };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getPaymentTypeById = async (id: string): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IPaymentType>(`api/NPaymentType/GetById/${id}`);

		if (response && response.success) {
			return { success: response.success, paymentType: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getActivePaymentTypes = async (): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IPaymentType>('api/NPaymentType/GetActive');

		if (response && response.success) {
			return { success: response.success, paymentTypes: response.collection };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const updatePaymentType = async (request: IPaymentType): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await PUT_TYPED_ASYNC<IPaymentType>(`api/NPaymentType/Update`, request);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const inactivatePaymentType = async (
	request: InactivateRequest,
): Promise<IGetPaymentTypesResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IPaymentType>(
			`api/NPaymentType/Inactivate`,
			request,
		);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	createPaymentType,
	getPaymentTypes,
	getPaymentTypeById,
	getActivePaymentTypes,
	updatePaymentType,
	inactivatePaymentType,
};
