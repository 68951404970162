import { FormikErrors } from 'formik';
import { useEffect, useRef } from 'react';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { ColorPicker, DEFAULT_COLOR } from '../../../../components/common/ColorPicker';
import { SaveForm } from '../../../../components/common/SaveForm';
import { showSuccess, showWarning } from '../../../../components/extras/Notifications';
import coursesService from '../../../../services/courses-service';

type CoursesFormValues = {
	description: string;
	totalMinutes: number;
	color: string;
	id?: string;
};

interface Props {
	isModalOpened: boolean;
	selectedCourse: ICourse | null;
	onModalClose: () => void;
	onAfterSubmit: () => void;
}

export const CoursesModal = ({
	isModalOpened,
	selectedCourse,
	onModalClose,
	onAfterSubmit,
}: Props) => {
	const descriptionInputRef = useRef<HTMLInputElement>(null);

	// Adicionei isso pra colocar o foco quando o filtro abrir
	useEffect(() => {
		if (isModalOpened && descriptionInputRef.current) {
			descriptionInputRef.current.focus();
		}
	}, [isModalOpened]);

	const onFormValidate = (values: CoursesFormValues) => {
		const errors: FormikErrors<CoursesFormValues> = {};

		if (!values.description) {
			errors.description = 'Campo obrigatório';
		}
		if (!values.totalMinutes || values.totalMinutes <= 0) {
			errors.totalMinutes = 'Campo obrigatório';
		}

		return errors;
	};

	const onFormSubmit = async (values: CoursesFormValues) => {
		if (selectedCourse) {
			const response = await coursesService.updateCourse({
				id: selectedCourse.id,
				name: values.description,
				totalMinutes: values.totalMinutes,
				color: values.color,
			});

			if (response.error) {
				showWarning(response.error);
				return;
			}

			showSuccess('Aula modificada com sucesso');
		} else {
			const response = await coursesService.addNewCourse({
				name: values.description,
				totalMinutes: values.totalMinutes,
				color: values.color,
			});

			if (response.error) {
				showWarning(response.error);
				return;
			}

			showSuccess('Aula criada com sucesso');
		}
		// form.resetForm();
		onAfterSubmit();
	};

	const onClose = () => {
		onModalClose();
	};

	return (
		<Modal isOpen={isModalOpened} setIsOpen={onClose} isCentered isStaticBackdrop>
			<ModalHeader setIsOpen={onClose}>
				<ModalTitle id='course-modal'>Adicionar Aula</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<SaveForm<CoursesFormValues>
					initialValues={{
						description: selectedCourse?.name || '',
						totalMinutes: selectedCourse?.totalMinutes || 0,
						color: selectedCourse?.color || DEFAULT_COLOR,
					}}
					onSubmit={onFormSubmit}
					onValidate={onFormValidate}>
					{(form) => (
						<>
							<div className='col-12'>
								<FormGroup id='description' label='Descrição' isFloating>
									<Input
										id='description-input'
										ref={descriptionInputRef}
										placeholder='Nome da aula'
										onChange={form.handleChange}
										value={form.values.description}
										isValid={form.isValid}
										invalidFeedback={form.errors.description}
										isTouched={form.touched.description}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='totalMinutes' label='Duração da aula (min)' isFloating>
									<Input
										id='total-minutes-input'
										type='number'
										onChange={form.handleChange}
										value={form.values.totalMinutes}
										isValid={form.isValid}
										invalidFeedback={form.errors.totalMinutes}
										isTouched={form.touched.totalMinutes}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='color' label='Cor'>
									<ColorPicker
										color={form.values.color}
										onColorSelect={(color) => {
											form.setFieldValue('color', color);
										}}
									/>
								</FormGroup>
							</div>
						</>
					)}
				</SaveForm>
			</ModalBody>
		</Modal>
	);
};
