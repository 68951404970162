import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import { showError, showSuccess } from '../../../components/extras/Notifications';
import AuthContext from '../../../contexts/authContext';
import financialService from '../../../services/financial-service';

interface Props {
	amount: number;
	onSuccess: () => void;
}

const CheckoutForm = ({ amount, onSuccess }: Props) => {
	const { cognitoId } = useContext(AuthContext);

	const stripe = useStripe();
	const elements = useElements();

	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsProcessing(true);

		const { error, paymentIntent } = await stripe.confirmPayment({
			elements,
			redirect: 'if_required',
		});

		if (error) {
			setIsProcessing(false);
			showError('Erro ao realizar pagamento');

			// TODO - Onde logar o erro?
			console.error(error.message);
			return;
		}

		const response = await financialService.createStudentPayment({
			studentId: cognitoId,
			amount,
			intentId: paymentIntent.id,
			paymentTypeId: "" //TODO: alterar no back
		});

		setIsProcessing(false);

		if (!response) {
			console.error(response.message);
			showError('Erro ao registrar pagamento');
			return;
		}

		showSuccess('Pagamento realizado com sucesso');
		onSuccess();
	};

	return (
		<form id='payment-form' onSubmit={handleSubmit}>
			<PaymentElement />
			<div className='d-flex justify-content-end'>
				<Button type='submit' color='info' className='mt-3 p-3'>
					<span id='button-text'>{isProcessing ? 'Processando...' : 'Pagar agora'}</span>
				</Button>
			</div>
		</form>
	);
};

export default CheckoutForm;
