import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonPaymentTypeList from "./common/CommonPaymentTypeList";

const PaymentTypePage = () => {

	return (
		<PageWrapper title='Formas de Pagamento'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<CommonPaymentTypeList />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default PaymentTypePage;