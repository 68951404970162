import { GET_TYPED_ASYNC, POST_TYPED_ASYNC } from '../components/extras/HttpHelper';

const BASE_PATH = 'api/NAttendance';

interface AttendanceResponse {
	success: boolean;
	attendance?:IAttendance;
	attendances?: IAttendance[];
	error?: string;
}

type NewAttendanceType = Omit<IAttendance, 'id'> | Partial<IAttendance>;

const addNewAttendance = async (newAttendance: NewAttendanceType): Promise<AttendanceResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IAttendance>(`${BASE_PATH}/Create`, newAttendance);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getAttendancesByDate = async (date: string): Promise<AttendanceResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IAttendance>(`${BASE_PATH}/GetByDate/${date}`);
		if (!response.success) {
			return { success: response.success, error: response.message || response.exception };
		}
		return { success: response.success, attendances: response.collection };
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getUserAttendancesByDate = async (date: string, userId: string): Promise<AttendanceResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IAttendance>(`${BASE_PATH}/GetUserAttendancesByFullDate/${date}/${userId}`);
		if (!response.success) {
			return { success: response.success, error: response.message || response.exception };
		}
		return { success: response.success, attendances: response.collection };
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const addNewAttendances = async (newAttendance: NewAttendanceType[]): Promise<AttendanceResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IAttendance>(`${BASE_PATH}/CreateAll`, newAttendance);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	addNewAttendance,
	addNewAttendances,
	getAttendancesByDate,
	getUserAttendancesByDate
};
