import { FC, useEffect, useState } from "react";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

const CommonUserWithoutSubscriptionList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);

    const [withoutSubscription, setWithoutSubscriptions] = useState<IWithoutSubscription[]>([]);

    const headers = [
        { label: 'Nome', key: 'name' },
    ];

    useEffect(() => {
        getWithoutSubscriptions();

    }, []);

    const getWithoutSubscriptions = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetUserWithoutSubscription`);

        setIsLoading(false);

        if (response && response.success) {
            setWithoutSubscriptions(response.collection);
        } else {
            showWarning(response.message);
        }
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='LocalOffer' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Usuários sem Matrícula
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>

                                <ExportButton headers={headers} data={withoutSubscription} filename="sem-matricula" />

                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(withoutSubscription, currentPage, perPage).map((item) => (
                                            <tr key={item.id}>

                                                <td>{item.name}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={withoutSubscription}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonUserWithoutSubscriptionList;