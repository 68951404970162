import { FormikHelpers, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import removeAccents from 'remove-accents';
import UserTypeEnum from '../../../common/data/enum/enumUserType';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Spinner from '../../../components/bootstrap/Spinner';
import { POST_ASYNC } from '../../../components/extras/HttpHelper';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';

interface ISignInModalProps {
	image: Blob;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
}

const SignInModal: FC<ISignInModalProps> = ({ image, isOpen, setIsOpen }) => {

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const formikUser = useFormik({
		initialValues: {
			fullName: ''
		},
		onSubmit: async (values: { fullName: string; }, { resetForm }: FormikHelpers<{ fullName: string; }>) => {

			if (!values.fullName) {
				showWarning("Por favor, preencha o nome completo para efetivar o cadastro");
				return;
			}

			const formData = new FormData();
			formData.append('userType', UserTypeEnum.Student.toString());
			formData.append('fullName', formikUser.values.fullName);

			if (image) {
				formData.append('profileImage', image);
			}

			setIsSubmitting(true);

			const response = await POST_ASYNC('api/NStudent/Create', formData);

			setIsSubmitting(false);

			if (response && response.success) {
				formikUser.values.fullName = '';
				setIsOpen(false);
				showSuccess('Usuário criado com sucesso.')
			} else {
				setIsOpen(false);
				showWarning('Falha ao criar o usuário. Por favor, entre em contato com o administrador do sistema')
			}
		},
	});

	if (true) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				// size='lg' 
				titleId={`0`}
				isStaticBackdrop={true}
				isCentered={true}
			>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={`0`}>{'Cadastrar Novo Usuário'}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>

					<p className='text-start'>
						Prezado usuário,
					</p>
					<p className='text-start' style={{ textAlign: 'justify' }}>
						Após uma busca minuciosa em nossa base de dados, constatamos que seu cadastro ainda não foi efetuado em nosso sistema.
					</p>
					<p className='text-start'>
						Por favor, informe seu <b>Nome Completo</b> para que possamos criar seu usuário.
					</p>


					<div className='row g-4'>
						<FormGroup id='fullName' label='Nome Completo' className='col-md-12'>
							<Input
								onChange={formikUser.handleChange}
								onBlur={formikUser.handleBlur}
								value={removeAccents(formikUser.values.fullName)}
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button color='primary'
						onClick={formikUser.handleSubmit}
						isDisable={isSubmitting}>
						{isSubmitting && (
							<Spinner isSmall inButton isGrow />
						)}
						Cadastrar
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
};
SignInModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default SignInModal;
