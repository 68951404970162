import classNames from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import removeAccents from 'remove-accents';
import GracieBarra from '../../../assets/logos/gbbatatais.png';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
interface IShowAlertProps {
	showAlert?: boolean;
	showAlertMessage?: string;
}
const apiUrl = process.env.REACT_APP_GB_API_URL;

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center hg fw-bold mt-5'>Cadastre-se</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bem-vindo(a)</div>
		</>
	);
};

const ShowAlert: FC<IShowAlertProps> = ({ showAlert, showAlertMessage }) => {
	if (showAlert) {
		return (
			<>
				<Alert
					icon='Error'
					isLight
					color='primary'
					borderWidth={0}
					className='shadow-3d-primary'
					isDismissible>
					<AlertHeading tag='h2' className='h4'>
						Erro
					</AlertHeading>
					<span>{showAlertMessage}</span>
				</Alert>
			</>
		);
	}
};

ShowAlert.defaultProps = {
	showAlert: false,
};

LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();


	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [isLoading, setIsLoading] = useState(false);
	const [loginButtonText, setLoginButtonText] = useState('Login');
	const [isDisable, setIsDisabled] = useState(false);
	const [showAlertError, setshowAlertError] = useState(false);
	const [showAlertMessage, setshowAlertMessage] = useState("Erro ao efetuar login");
	const { userData, setCognitoId } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: "",
			loginPassword: "",
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			setLoginButtonText('Carregando')
			setIsDisabled(true)
			const formData = new FormData();
			formData.append('loginUsername', formik.values.loginUsername);
			formData.append('loginPassword', formik.values.loginPassword);
			const response = await fetch(`${apiUrl}Student/Login`, {
				method: 'POST',
				body: formData,
			}).then(resp => {
				if (resp.status === 200) {
					let data = resp.json();
					data.then((resultado) => {
						sessionStorage.setItem('gb_accesstoken', resultado.authenticationResult.idToken);
						sessionStorage.setItem('jwt_accesstoken', resultado.authenticationResult.accessToken);
						//localStorage.setItem('cognitoId', formik.values.loginUsername);
						if (setCognitoId) {
							setCognitoId(formik.values.loginUsername);
						}
						handleOnClick();
					});

				}
				else {
					setshowAlertError(true);
					setshowAlertMessage("Usuário ou senha incorretos");
				}
			}).catch((error) => {
				setshowAlertError(true);
				setshowAlertMessage("Usuário ou senha incorretos");
			});

			setIsLoading(false);
			setIsDisabled(false)
			setLoginButtonText('Login')
		}
	});

	const formikSignin = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			name: "",
			surname: "",
			password: "",
			imageupload: null,
		},
		onSubmit: (values) => {
			handleNewUser();
		}
	});

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			formikSignin.setFieldValue('imageupload', file);
		}
	}

	const handleNewUser = async () => {
		const formData = new FormData();
		formData.append('name', formikSignin.values.name);
		formData.append('surname', formikSignin.values.surname);
		formData.append('password', formikSignin.values.password);
		if (formikSignin.values.imageupload) {
			formData.append('profileImage', formikSignin.values.imageupload);
		}

		const response = await fetch(`${apiUrl}Student/SignIn`, {
			method: 'POST',
			body: formData,
		}).then(resp => {
			if (resp.status === 200) {
				//localStorage.setItem('cognitoId', formikSignin.values.name + formikSignin.values.surname);
				navigate('/');
			}
			else {
				setshowAlertError(true);
				setshowAlertMessage("Erro ao fazer sign in");
			}
		}).catch((error) => { setshowAlertError(true); setshowAlertMessage(`Erro inesperado: ${error}`); });

	};

	const handleContinue = () => {
		setSignInPassword(true);
	};

	return (

		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Cadastro' : 'Login'}
			className={'bg-light'}>
			<Page className='p-0'>

				<div className='row h-100 align-items-center justify-content-center'>

					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<ShowAlert showAlert={showAlertError} showAlertMessage={showAlertMessage} />
						<Card className='shadow-3d-dark'>

							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Gracie Barra'>
										<img src={GracieBarra} alt='Gracie Barra' width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-2', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-2 pb-2 px-2 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Cadastro
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='name'
													isFloating
													label='Nome'>
													<Input
														autoComplete='name'
														value={removeAccents(formikSignin.values.name)}
														isTouched={formikSignin.touched.name}
														invalidFeedback={
															formikSignin.errors.name
														}
														isValid={formikSignin.isValid}
														onChange={formikSignin.handleChange}
														onBlur={formikSignin.handleBlur}
														onFocus={() => {
															formikSignin.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='surname'
													isFloating
													label='Sobrenome'>
													<Input
														autoComplete='surname'
														value={removeAccents(formikSignin.values.surname)}
														isTouched={formikSignin.touched.surname}
														invalidFeedback={
															formikSignin.errors.surname
														}
														isValid={formikSignin.isValid}
														onChange={formikSignin.handleChange}
														onBlur={formikSignin.handleBlur}
														onFocus={() => {
															formikSignin.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label='Senha'>
													<Input
														autoComplete='password'
														value={formikSignin.values.password}
														isTouched={formikSignin.touched.password}
														invalidFeedback={
															formikSignin.errors.password
														}
														type='password'
														isValid={formikSignin.isValid}
														onChange={formikSignin.handleChange}
														onBlur={formikSignin.handleBlur}
														onFocus={() => {
															formikSignin.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='imageupload'
													isFloating
													label='Foto'>
													<Input
														autoComplete='imageupload'
														isTouched={formikSignin.touched.imageupload}
														invalidFeedback={
															formikSignin.errors.imageupload
														}
														type='file'
														accept="image/*"
														onChange={handleImageChange}
														isValid={formikSignin.isValid}
														onBlur={formikSignin.handleBlur}
														onFocus={() => {
															formikSignin.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='primary'
													className='w-100 py-3'
													onClick={handleNewUser}>
													Cadastrar
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Usuário'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Olá, {formik.values.loginUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Senha'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='primary'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Acessar
													</Button>
												) : (
													<Button
														color='primary'
														className='w-100 py-3'
														onClick={formik.handleSubmit}
														isDisable={isDisable}
													>
														{isLoading && (
															<Spinner color={'light'} inButton isSmall>
																Loading...
															</Spinner>
														)}
														{loginButtonText}
													</Button>
												)}
											</div>



											<div className='col-12'>
												<hr />
												<div className='col'>
													<Button
														color={darkModeStatus ? 'light' : 'dark'}
														isLight={true}
														className='rounded-1 w-100'
														size='lg'
														onClick={() => {
															navigate('/attendance/checkin');
														}}>
														Realizar Check-In Facial
													</Button>
												</div>
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>

					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Login.propTypes = {
	isSignUp: PropTypes.bool,
};

Login.defaultProps = {
	isSignUp: false,
};

export default Login;
