import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonFacialCheckin from "./common/CommonFacialCheckin";

const FacialCheckinPage = () => {

	return (
		<PageWrapper
			title='Check-in Facial'
			isProtected={false}
			className={'page-wrapper-background'}
		>
			<Page className='p-0'>
				<CommonFacialCheckin />
			</Page>
		</PageWrapper>
	);
};

export default FacialCheckinPage;