import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import COLORS from '../../../../common/data/enumColors';
import Avatar from '../../../../components/Avatar';
import PaginationButtons, {
	dataPagination,
	PER_COUNT
} from '../../../../components/PaginationButtons';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import { showError, showSuccess, showWarning } from '../../../../components/extras/Notifications';
import Icon from '../../../../components/icon/Icon';
import AuthContext from '../../../../contexts/authContext';
import { priceFormat } from '../../../../helpers/helpers';
import useSortableData from '../../../../hooks/useSortableData';
import cognitoService, { GetByIdResponse } from '../../../../services/cognito-service';
import financialService from '../../../../services/financial-service';
import paymentTypeService, { IGetPaymentTypesResponse } from '../../../../services/payment-type-service';
import { FILTER_FORM_INITIAL_VALUES } from './CommonStudent.const';
import { PaidStudentsFilterFormValues } from './CommonStudents.types';
import { CommonStudentsFilter } from './CommonStudentsFilter';

interface IUnpaidStudentsProps {
	unPaidStudents: IStudent[];
	onUpdate: any;
}

const UnpaidStudents: FC<IUnpaidStudentsProps> = ({ unPaidStudents, onUpdate }) => {

	const { cognitoId } = useContext(AuthContext);

	const darkModeStatus = false;
	const [selectedStudent, setSelectedStudent] = useState<IStudent>();
	const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<ISubscriptionType>();

	const [paySubscriptionOffcanvas, setPaySubscriptionOffcanvas] = useState<boolean>(false);
	const [payments, setPayments] = useState<IPayment[]>([]);
	const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);

	const { items, requestSort, getClassNamesFor } = useSortableData(payments);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['5']);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [filterValues, setFilterValues] = useState<PaidStudentsFilterFormValues>(
		FILTER_FORM_INITIAL_VALUES,
	);

	const formik = useFormik({

		initialValues: {
			paymentDate: dayjs().format('YYYY-MM-DD'),
			amount: 0,
			paymentType: '',
			paymentFee: 0,
			paymentDiscount: 0,
			discountReason: ''
		},

		validate: (values) => {
			const errors: {
				paymentDate?: string;
				amount?: string;
				paymentType?: string;
				paymentDiscount?: string;
				discountReason?: string;
			} = {};

			if (!values.paymentDate)
				errors.paymentDate = 'Campo obrigatório';

			if (!values.amount || values.amount === 0)
				errors.amount = 'Campo obrigatório';

			if (!values.paymentType)
				errors.paymentType = 'Campo obrigatório';

			if(values.paymentDiscount && values.paymentDiscount > 0 && !values.discountReason)
				errors.discountReason = 'Campo obrigatório';

			return errors;
		},

		onSubmit: async (values, { resetForm }) => {
			createPayment(values);
			resetForm();
		},
	});

	const handleUnpaidSubscription = async (id: string) => {

		const promises = []
		promises.push(cognitoService.getById(id));
		promises.push(paymentTypeService.getActivePaymentTypes());

		setIsSubmitting(true);

		const [getByIdResponse, getActivePaymentTypesResponse] =
			await Promise.all(promises) as [GetByIdResponse, IGetPaymentTypesResponse];

		setIsSubmitting(false);

		if (getByIdResponse.error) {
			showWarning(getByIdResponse.error);
			return;
		} else {

			setSelectedStudent(getByIdResponse.student);
			setPayments(getByIdResponse?.student?.payments || []);

			if (getByIdResponse?.student?.subscription) {
				if (getByIdResponse.student.subscription.subscriptionType) {
					setSelectedSubscriptionType(getByIdResponse.student.subscription.subscriptionType);
					formik.values.amount = getByIdResponse.student.subscription.subscriptionType.fee;
				}
			} else {
				setSelectedSubscriptionType(undefined);
				formik.values.amount = 0;
			}
		}

		if (getActivePaymentTypesResponse.error) {
			showWarning(getActivePaymentTypesResponse.error);
			return;
		} else {
			setPaymentTypes(getActivePaymentTypesResponse.paymentTypes || []);
		}

		setPaySubscriptionOffcanvas(!paySubscriptionOffcanvas);
	};

	const getStudentByCognitoId = async (id: string) => {

		const response = await cognitoService.getById(id);

		if (response && response.success) {
			setSelectedStudent(response.student);
			setPayments(response?.student?.payments || []);

			if (response?.student?.subscription) {
				if (response.student.subscription.subscriptionType) {
					setSelectedSubscriptionType(response.student.subscription.subscriptionType);
					formik.values.amount = response.student.subscription.subscriptionType.fee;
				}
			} else {
				setSelectedSubscriptionType(undefined);
				formik.values.amount = 0;
			}
		} else {
			showWarning(response.error || 'Falha ao recuperar o aluno');
		}
	};

	const filterData = () => {
		if (!filterValues.nameFilter) return unPaidStudents;

		return unPaidStudents.filter((item: IStudent) => {
			return item.fullName
				.toLowerCase()
				.includes(filterValues.nameFilter.toLocaleLowerCase());
		});
	};

	const onFilterChange = (values: PaidStudentsFilterFormValues) => {
		setFilterValues(values);
	};

	const createPayment = async (values: any) => {

		const request = {
			studentId: selectedStudent?.id,
			paymentDate: values.paymentDate,
			amount: values.amount + values.paymentFee - values.paymentDiscount,
			paymentTypeId: values.paymentType,
			discountReason: values.discountReason,
			createdBy: cognitoId
		};

		const response = await financialService.createStudentPayment({
			studentId: request.studentId!,
			amount: request.amount < 0 ? 0 : request.amount,
			paymentDate: request.paymentDate,
			paymentTypeId: request.paymentTypeId,
			discountReason: request.discountReason,
			createdBy: request.createdBy
		});

		if (response && response.success) {

			setPayments([...payments, response]);
			setPaySubscriptionOffcanvas(!paySubscriptionOffcanvas);
			onUpdate();

			showSuccess('Mensalidade recebida com sucesso');
		} else {
			showError(response.message);
		}

	}

	const filteredData = filterData();

	return (
		<>
			<Card stretch>
				<CardHeader>
					<CardLabel icon='MoneyOff' iconColor='primary'>
						<CardTitle tag='div' className='h5'>
							Mensalidades Pendentes
						</CardTitle>
					</CardLabel>
					<CardActions>
						<CommonStudentsFilter onFilterChange={onFilterChange} />
					</CardActions>
				</CardHeader>
				<CardBody isScrollable style={{ minHeight: 400 }}>
					<div className='row g-3'>
						{filteredData.map((p, index) => (
							<div key={index} className='col-12'>
								<div className='row g-2'>
									<div className='col d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={p.profileImageUrl}
												srcSet={p.profileImageUrl}
												size={50}
												userName={p.username}
												color={COLORS.PRIMARY.name}
											/>
										</div>

										<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
											<div className='fw-bold'>
												{p.firstName + ' ' + p.lastName}
											</div>
										</div>

										<div className='flex-shrink-0'>
											<div className='col-auto'>
												<div
													style={{ width: 100 }}
													className={classNames(
														`bg-l${darkModeStatus ? 'o25' : '10'}-primary text-primary fw-bold py-2 rounded-pill me-3 text-center`,
													)}>
													{'Pendente'}
												</div>
											</div>
										</div>

										<div className='flex-shrink-0'>
											<div className='col-auto text-end'>
												<Dropdown>
													<DropdownToggle hasIcon={false}>
														<Button
															icon='MoreHoriz'
															color='dark'
															isLight
															shadow='sm'
															aria-label='Mais Ações'
														/>
													</DropdownToggle>
													<DropdownMenu isAlignmentEnd>
														<DropdownItem>
															<Button
																isOutline={!darkModeStatus}
																color='dark'
																isLight
																className={classNames(
																	'text-nowrap',
																	{
																		'border-light':
																			!darkModeStatus,
																	},
																)}
																icon='AttachMoney'
																onClick={() =>
																	handleUnpaidSubscription(
																		p.cognitoUserId,
																	)
																}>
																Receber
															</Button>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</CardBody>
			</Card>

			<Modal
				setIsOpen={setPaySubscriptionOffcanvas}
				isOpen={paySubscriptionOffcanvas}
				titleId='paySubscription'
				isCentered
				size='lg'
				isStaticBackdrop={true}
				isScrollable
			>
				<ModalHeader setIsOpen={setPaySubscriptionOffcanvas}>
					<OffCanvasTitle id='paySubscription' tag='h4'>{''}</OffCanvasTitle>
				</ModalHeader>

				{selectedSubscriptionType ? (
					<>
						<ModalBody >

							<Wizard
								isHeader
								stretch
								color='primary'
								//noValidate
								onSubmit={formik.handleSubmit}
								style={{ minHeight: 500 }}
							>

								<WizardItem id='step1' title='Plano Ativo'>
									<div className='row pt-4 g-4 text-center'>
										<div className='col-12'>
											<Icon
												icon={'SportsKabaddi'}
												size='4x'
												color={'primary'}
											/>
										</div>
										<div className='col-12'>
											<h3>{selectedSubscriptionType?.description}</h3>
										</div>
										<div className='col-12'>
											<h6>
												<span className='display-6 fw-bold'>
													{priceFormat(
														selectedSubscriptionType?.fee || 0,
													)}
												</span>
											</h6>
										</div>
									</div>
								</WizardItem>

								<WizardItem id='step2' title='Histórico de Mensalidades'>
									<div className='row g-4'>
										<div className='col-lg-12'>
											<table className='table table-modern table-hover table-responsive'>
												<thead>
													<tr>
														<th
															onClick={() =>
																requestSort('paymentDate')
															}
															className='cursor-pointer text-decoration-underline'>
															Data{' '}
															<Icon
																size='lg'
																className={getClassNamesFor(
																	'paymentDate',
																)}
																icon='FilterList'
															/>
														</th>
														<th>
															Valor
														</th>
														<th>Forma de Pagamento</th>
													</tr>
												</thead>
												<tbody>
													{dataPagination(
														items,
														currentPage,
														perPage,
													).map((i: IPayment) => (
														<tr key={i.id}>
															<td>{dayjs(i.paymentDate).format('DD/MM/YYYY')}</td>
															<td>{priceFormat(i.amount || 0)}</td>
															<td>{i?.paymentType?.name || '-'}</td>
														</tr>
													))}
												</tbody>
											</table>

											<PaginationButtons
												data={items}
												label='items'
												setCurrentPage={setCurrentPage}
												currentPage={currentPage}
												perPage={perPage}
												setPerPage={setPerPage}
											/>
										</div>
									</div>
								</WizardItem>

								<WizardItem id='step3' title='Receber Mensalidade'>

									<div className='row g-4'>
										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='paymentDate'
												label='Data de Pagamento'
												isFloating>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.paymentDate}
													isValid={formik.isValid}
													isTouched={formik.touched.paymentDate}
													invalidFeedback={
														formik.errors.paymentDate
													}
													validFeedback=''
													type='date'
												/>
											</FormGroup>
										</div>

										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='paymentType'
												label='Forma de Pagamento'
												isFloating
											>
												<Select
													ariaLabel='Escolha uma forma de pagamento'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.paymentType}
													list={
														paymentTypes.map((paymentType) => ({
															value: paymentType.id,
															text: `${paymentType.name}`
														}))
													}
													isValid={formik.isValid}
													isTouched={formik.touched.paymentType}
													invalidFeedback={
														formik.errors.paymentType
													}
													validFeedback=''
												/>
											</FormGroup>
										</div>

										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='amount'
												label='Valor da Mensalidade'
												isFloating>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.amount}
													isValid={formik.isValid}
													isTouched={formik.touched.amount}
													invalidFeedback={
														formik.errors.amount
													}
													validFeedback=''
													type='number'
												/>
											</FormGroup>
										</div>

										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='paymentFee'
												label='Valor da Taxa na Mensalidade'
												isFloating>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.paymentFee}
													isValid={formik.isValid}
													isTouched={formik.touched.paymentFee}
													type='number'
												/>
											</FormGroup>
										</div>

										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='paymentDiscount'
												label='Desconto na Mensalidade'
												isFloating>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.paymentDiscount}
													isValid={formik.isValid}
													isTouched={formik.touched.paymentDiscount}
													type='number'
												/>
											</FormGroup>
										</div>

										<div className=' col-xl-6 col-lg-6 col-md-6 col-sm-12'>
											<FormGroup
												id='discountReason'
												label='Motivo do Desconto'
												isFloating>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.discountReason}
													isValid={formik.isValid}
													isTouched={formik.touched.discountReason}
													invalidFeedback={
														formik.errors.discountReason
													}
													validFeedback=''
												/>
											</FormGroup>
										</div>


									</div>
								</WizardItem>

							</Wizard>

						</ModalBody>
					</>
				) : (
					<ModalBody>
						<div className='col-12'>
							<p className='lead text-center text-muted'>
								Aluno não matriculado. Por favor, selecione uma matrícula para o
								aluno.
								<br />
								<Link to='/student/students'>
									Clique aqui para adicionar uma matrícula
								</Link>
							</p>
						</div>
					</ModalBody>
				)}
			</Modal>
		</>
	);
};

export default UnpaidStudents;
