import { usePagination } from '../../hooks/usePagination';
import { CardBody } from '../bootstrap/Card';
import Table from './Table';
import { dataPagination } from './utils';

interface Props {
	data: any[];
	head: JSX.Element;
	body: (item: any, index: number) => JSX.Element;
	tableClassNames?: string;
	paginationLabel?: string;
}

export const CardBodyTable = ({ data, head, body, tableClassNames, paginationLabel }: Props) => {
	const { currentPage, perPage, PaginationElement } = usePagination({
		data,
		label: paginationLabel,
	});

	return (
		<>
			<CardBody className='table-responsive'>
				<Table
					tableClassNames={tableClassNames}
					head={head}
					body={dataPagination(data, currentPage, perPage).map((i, index) =>
						body(i, index),
					)}
				/>
			</CardBody>
			<PaginationElement />
		</>
	);
};
