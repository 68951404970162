import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getFilterMonth } from "../../../../common/data/calendar";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Label from "../../../../components/bootstrap/forms/Label";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

const CommonBirthdaysList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [filterMenu, setFilterMenu] = useState<boolean>(false);

    const [birthdays, setBirthdays] = useState<IMonthBirthdays[]>([]);

    const headers = [
        { label: 'Nome', key: 'name' },
        { label: 'Data', key: 'birthDateView' }
    ];

    const filterMonth = getFilterMonth();

    const formikFilter = useFormik({
        initialValues: {
            monthFilter: '',
        },

        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {

            getBirthdays();

            setFilterMenu(false);
        }
    });

    useEffect(() => {
        getCurrentMonth();

        getBirthdays();
    }, []);

    const getBirthdays = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetMonthBirthdays/${formikFilter.values.monthFilter}`);

        setIsLoading(false);

        if (response && response.success) {

            if (response.collection) {
                const monthBirthdays = response.collection.map((birthday: IMonthBirthdays) => ({
                    ...birthday,
                    birthDateView: birthday.birthDate ? dayjs(birthday.birthDate).format('DD/MM/YYYY') : '',
                }));

                setBirthdays(monthBirthdays);
            } else {
                setBirthdays(response.collection);
            }
        } else {
            showWarning(response.message);
        }
    }

    const getCurrentMonth = () => {
        const currentMonth = new Date().getMonth() + 1;
        formikFilter.values.monthFilter = currentMonth.toString();
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='cake' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Aniversariantes do Mês
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>

                                    <ExportButton headers={headers} data={birthdays} filename="aniversariantes" />

                                    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu} isButtonGroup>
                                        <DropdownToggle hasIcon={false}>
                                            <Button isLink={true} icon='FilterAlt' color='primary'>
                                                Filtrar
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <div className='container py-2'>
                                                <form className='row g-3' onSubmit={formikFilter.handleSubmit}>

                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <Label htmlFor='monthFilter'>Mês</Label>
                                                            <Select
                                                                id='monthFilter'
                                                                ariaLabel='Mês'
                                                                placeholder='Mês'
                                                                list={filterMonth}
                                                                onChange={formikFilter.handleChange}
                                                                value={formikFilter.values.monthFilter}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className='col-6'>
                                                        <Button
                                                            color='primary'
                                                            isOutline
                                                            className='w-100'
                                                            onClick={formikFilter.resetForm}>
                                                            Limpar
                                                        </Button>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Button color='primary' className='w-100' type='submit'>
                                                            Aplicar
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(birthdays, currentPage, perPage).map((item) => (
                                            <tr key={item.userId}>

                                                <td>{item.name}</td>
                                                <td>{dayjs(item.birthDate).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={birthdays}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonBirthdaysList;