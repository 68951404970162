import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getFilterMonth } from "../../../../common/data/calendar";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Label from "../../../../components/bootstrap/forms/Label";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC, POST_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

enum BeltColor {
    White = 0,
    GrayWhite = 1,
    Gray = 2,
    GrayBlack = 3,
    YellowWhite = 4,
    Yellow = 5,
    YellowBlack = 6,
    OrangeWhite = 7,
    Orange = 8,
    OrangeBlack = 9,
    GreenWhite = 10,
    Green = 11,
    GreenBlack = 12,
    Blue = 13,
    Purple = 14,
    Brown = 15,
    Black = 16,
    CoralBlack = 17,
    CoralWhite = 18,
    Red = 19
};

const beltColors = [
    'Branca',
    'CinzaEBranca',
    'Cinza',
    'CinzaEPreta',
    'AmarelaEBranca',
    'Amarela',
    'AmarelaEPreta',
    'LaranjaEBranca',
    'Laranja',
    'LaranjaEPreta',
    'VerdeEBranca',
    'Verde',
    'VerdeEPreta',
    'Azul',
    'Roxa',
    'Marrom',
    'Preta',
    'Amarela',
    'Verde',
    'Laranja',
    'Cinza',
];

const CommonGraduationsList: FC = () => {


    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);

    const [userGraduation, setuserGraduation] = useState<IUserGraduation[]>([]);

    const getBeltColorName = (belt: number) => {
        if (belt >= 0 && belt < beltColors.length) {
            return beltColors[belt];
        }
        return 'Branca'; // Handle invalid indices
    }

    useEffect(() => {
        getUserGraduation();
    }, []);

    const getUserGraduation = async () => {

        setIsLoading(true);

        const request = {
           
        };
        const response = await POST_ASYNC(`SystemConfiguration/RunGraduationCheck`, request);

        setIsLoading(false);

        if (response) {
            setuserGraduation(response);
            } else {
                setuserGraduation(response);
            }
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='school' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Proximas Graduações
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Faixa Atual</th>
                                            <th>Grau Atual</th>
                                            <th>Presenças</th>
                                            <th>Presenças Necessarias (IBJJF)</th>
                                            <th>Contados a Partir De</th>
                                            <th>Periodo Para Troca</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(userGraduation, currentPage, perPage).map((item) => (
                                            <tr key={item.userId}>

                                                <td>{item.studentName}</td>
                                                <td>{getBeltColorName(item.currentBelt)}</td>
                                                <td>{item.currentStripe}</td>
                                                <td>{item.studentTotalAttendanceFromLastGraduation}</td>
                                                <td>{item.requiredAttendanceDays}</td>
                                                <td>{dayjs(item.cutoffDate).format('DD/MM/YYYY')}</td>
                                                <td>{item.period}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={userGraduation}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonGraduationsList;
