import classNames from "classnames";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import enumOperation from "../../../../common/data/enum/enumOperation";
import { PAYMENT_TYPE_FILTER_INITIAL_VALUES, PaymentTypeFilter } from "../../../../common/data/filters/paymentTypeFilter";
import Badge from "../../../../components/bootstrap/Badge";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import Checks from "../../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import Spinner from "../../../../components/bootstrap/Spinner";
import { showCreateError, showCreateSuccess, showInactivateError, showInactivateSuccess, showUpdateError, showUpdateSuccess, showWarning } from "../../../../components/extras/Notifications";
import PaginationButtons, { dataPagination, PER_COUNT } from "../../../../components/PaginationButtons";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import paymentTypeService from "../../../../services/payment-type-service";
import LoadingPage from "../../loading/LoadingPage";
import CommonPaymentsTypePageHeader from "./CommonPaymentsTypePageHeader";

const CommonPaymentTypeList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);

    const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
    const { items, requestSort, getClassNamesFor } = useSortableData(paymentTypes);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState('');
    const [operation, setOperation] = useState<enumOperation>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [filters, setFilters] = useState<PaymentTypeFilter>(PAYMENT_TYPE_FILTER_INITIAL_VALUES);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            description: '',
            status: true,
            creationDate: null,
            lastUpdate: null,
            inactivationDate: null,
            entryType: '',
            entryPeriod: 0,
            entryDay: 0,
            fee: 0
        },

        validate: (values) => {
            const errors: {
                name?: string;
                entryType?: string;
                entryPeriod?: string;
                entryDay?: string;
                fee?: string;
            } = {};

            if (!values.name)
                errors.name = 'Campo obrigatório';

            if (!values.entryType)
                errors.entryType = 'Campo obrigatório';

            if (values.entryType !== '0') {

                if (!values.entryPeriod)
                    errors.entryPeriod = 'Campo obrigatório';
                else {

                    const isValidNumber = Number.isInteger(Number(values.entryPeriod));
                    if (!isValidNumber)
                        errors.entryPeriod = 'Valor inválido. Somente números inteiros são aceitos';
                    else {

                        if (values.entryType === '2' && !values.entryDay)
                            errors.entryDay = 'Campo obrigatório';

                        if (values.entryType === '2' && values.entryDay) {

                            const n = Number(values.entryDay);

                            const isValidNumber = Number.isInteger(n);
                            if (!isValidNumber || n <= 0 || n > 31)
                                errors.entryDay = 'Valor inválido. Somente números inteiros, maiores que 1 e menores que 31 são aceitos';

                        }
                    }
                }
            }

            return errors;
        },

        onSubmit: (values) => {
            savePaymentType(values);
        },
    });

    useEffect(() => {
        getPaymentTypes();
    }, []);

    const resetForm = () => {
        formik.resetForm({
            values: {
                id: '',
                name: '',
                description: '',
                status: true,
                creationDate: null,
                lastUpdate: null,
                inactivationDate: null,
                entryType: '',
                entryPeriod: 0,
                entryDay: 0,
                fee: 0
            },
        })
    }

    const handlePaymentTypeOffcanvas = () => {
        resetForm();
        setIsOpen(!isOpen);
        setModalTitle('Criar Forma de Pagamento');
        setOperation(enumOperation.Create);
    };

    const getPaymentTypes = async () => {

        setIsLoading(true);

        const { paymentTypes, error, success } = await paymentTypeService.getPaymentTypes();

        setIsLoading(false);

        if (success) {
            setPaymentTypes(paymentTypes || []);
        } else {
            showWarning(error || 'Falha ao recuperar formas de pagamento');
        }
    };

    const savePaymentType = async (values: any) => {

        if (operation === enumOperation.Create)
            createPaymentType(values);
        else
            updatePaymentType(values);
    }

    const createPaymentType = async (values: any) => {

        const request = {
            name: values.name,
            description: values.description,
            status: values.status,
            entryType: values.entryType,
            entryPeriod: values.entryPeriod,
            entryDay: values.entryDay,
            fee: values.fee
        };

        setIsSubmitting(true);

        const response = await paymentTypeService.createPaymentType(request);

        setIsSubmitting(false);

        if (response && response.success) {
            setIsOpen(!isOpen);
            getPaymentTypes();

            showCreateSuccess();
        } else {
            showCreateError();
        }
    }

    const updatePaymentType = async (values: any) => {

        const request = {
            id: values.id,
            name: values.name,
            description: values.description,
            status: values.status,
            entryType: values.entryType,
            entryPeriod: values.entryPeriod,
            entryDay: values.entryDay,
            fee: values.fee,
            creationDate: values.creationDate,
            lastUpdate: values.lastUpdate,
            inactivationDate: values.inactivationDate
        };

        setIsSubmitting(true);

        const response = await paymentTypeService.updatePaymentType(request);

        setIsSubmitting(false);

        if (response && response.success) {
            setIsOpen(!isOpen);
            getPaymentTypes();

            showUpdateSuccess();
        } else {
            showUpdateError();
        }
    }

    const getPaymentTypeById = async (id: string) => {

        setOperation(enumOperation.Update);

        setIsLoading(true);

        const { paymentType, error, success } = await paymentTypeService.getPaymentTypeById(id);

        setIsLoading(false);

        if (success) {
            resetForm();
            setFieldValues(paymentType);

            setModalTitle('Alterar Forma de Pagamento');
            setIsOpen(!isOpen);
        } else {
            showWarning(error || 'Falha ao recuperar forma de pagamento');
        }
    };

    const setFieldValues = (values: any) => {
        formik.setFieldValue('id', values.id);
        formik.setFieldValue('name', values.name);
        formik.setFieldValue('description', values.description);
        formik.setFieldValue('status', values.status);
        formik.setFieldValue('entryType', values.entryType);
        formik.setFieldValue('entryPeriod', values.entryPeriod);
        formik.setFieldValue('entryDay', values.entryDay);

        formik.setFieldValue('creationDate', values.creationDate);
        formik.setFieldValue('lastUpdate', values.lastUpdate);
        formik.setFieldValue('inactivationDate', values.inactivationDate);
    }

    const inactivatePaymentType = async (id: string) => {

        const request = {
            id: id
        };

        setIsLoading(true);

        const response = await paymentTypeService.inactivatePaymentType(request);

        setIsLoading(false);

        if (response && response.success) {
            getPaymentTypes();

            showInactivateSuccess();
        } else {
            showInactivateError();
        }
    }

    const onFilterChange = (values: PaymentTypeFilter) => {
        setFilters(values);
    };

    const filterData = (items: IPaymentType[]) => {
        if (!filters.nameFilter && !filters.descriptionFilter && filters.statusFilter === null)
            return items;

        let filteredItems = [...items];

        if (filters.nameFilter) {
            filteredItems = items.filter((paymentType: IPaymentType) =>
                paymentType.name.toLowerCase().includes(filters.nameFilter.toLowerCase()),
            );
        }

        if (filters.descriptionFilter) {
            filteredItems = items.filter((paymentType: IPaymentType) =>
                paymentType.description.toLowerCase().includes(filters.descriptionFilter.toLowerCase()),
            );
        }


        filteredItems = filteredItems.filter(
            (paymentType: IPaymentType) => paymentType.status === filters.statusFilter,
        );



        return filteredItems;
    };

    const filteredData = filterData(items);

    return (
        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CommonPaymentsTypePageHeader
                                paymentTypes={paymentTypes}
                                onFilterChange={onFilterChange}
                                onCreateNewStudent={handlePaymentTypeOffcanvas}
                            />

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Descrição</th>
                                            <th>Situação</th>
                                            <td aria-labelledby='Actions' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataPagination(filteredData, currentPage, perPage).map((item: IPaymentType) => (
                                            <tr key={item.id}>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>

                                                <td>
                                                    {item.status ? (
                                                        <h5>
                                                            <Badge color='success' className='align-items-center justify-content-center'>
                                                                Ativo
                                                            </Badge>
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            <Badge color='danger' className='align-items-center justify-content-center'>
                                                                Inativo
                                                            </Badge>
                                                        </h5>
                                                    )}
                                                </td>

                                                <td className="text-end">
                                                    <Dropdown>
                                                        <DropdownToggle hasIcon={false}>
                                                            <Button
                                                                icon='MoreHoriz'
                                                                color='dark'
                                                                isLight
                                                                shadow='sm'
                                                                aria-label='Mais Ações'
                                                            />
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd>
                                                            <DropdownItem>
                                                                <Button
                                                                    isOutline={!darkModeStatus}
                                                                    color='dark'
                                                                    isLight={darkModeStatus}
                                                                    className={classNames('text-nowrap', {
                                                                        'border-light': !darkModeStatus,
                                                                    })}
                                                                    icon='Edit'
                                                                    onClick={() => getPaymentTypeById(item.id)}
                                                                >
                                                                    Alterar
                                                                </Button>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <Button
                                                                    isOutline={!darkModeStatus}
                                                                    color='dark'
                                                                    isLight={darkModeStatus}
                                                                    className={classNames('text-nowrap', {
                                                                        'border-light': !darkModeStatus,
                                                                    })}
                                                                    icon='Delete'
                                                                    onClick={() => inactivatePaymentType(item.id)}
                                                                >
                                                                    Inativar
                                                                </Button>
                                                            </DropdownItem>

                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={filteredData}
                                label='formas de pagamento'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>

                        <Modal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            titleId='paymentType'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                            size={"lg"}
                        >
                            <ModalHeader setIsOpen={setIsOpen}>
                                <OffCanvasTitle id='paymentType'>{modalTitle}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>
                                <div className='row g-4'>

                                    {
                                        operation == enumOperation.Update && (
                                            <div className='col-12'>
                                                <FormGroup id='id' label='Código' isFloating>
                                                    <Input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.id}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )
                                    }

                                    <div className='col-12'>
                                        <FormGroup id='name' label='Nome' isFloating>
                                            <Input
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.name}
                                                invalidFeedback={
                                                    formik.errors.name
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <FormGroup id='description' label='Descrição' isFloating>
                                            <Input
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.description}
                                                invalidFeedback={
                                                    formik.errors.description
                                                }
                                                validFeedback=''
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <Card isCompact borderSize={1} shadow='none' className='mb-0'>
                                            <CardHeader
                                                size="sm"
                                            >
                                                <CardLabel >
                                                    <CardTitle>
                                                        <CardSubTitle>
                                                            Regras de Lançamento
                                                        </CardSubTitle>
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row g-4'>
                                                    <div className='col-12'>
                                                        <FormGroup
                                                            id='entryType'
                                                            label='Lançamento'
                                                            isFloating
                                                        >
                                                            <Select
                                                                ariaLabel=''
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.entryType}
                                                                list={
                                                                    [
                                                                        { value: '0', text: 'Imediato' },
                                                                        { value: '1', text: 'Em X dia(s)' },
                                                                        { value: '2', text: 'Em X mês(es)' }
                                                                    ]
                                                                }
                                                                isValid={formik.isValid}
                                                                isTouched={formik.touched.entryType}
                                                                invalidFeedback={
                                                                    formik.errors.entryType
                                                                }
                                                                validFeedback=''
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    {
                                                        formik.values.entryType && (formik.values.entryType === '1' || formik.values.entryType === '2') && (

                                                            <div className='col-12'>
                                                                <FormGroup
                                                                    id='entryPeriod'
                                                                    label={formik.values.entryType === '1' ? "Quantidade de dia(s)" : "Quantidade de mês(es)"}
                                                                    isFloating
                                                                >
                                                                    <Input
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.entryPeriod}
                                                                        isValid={formik.isValid}
                                                                        isTouched={formik.touched.entryPeriod}
                                                                        type="number"
                                                                        min={1}
                                                                        step={0}
                                                                        invalidFeedback={
                                                                            formik.errors.entryPeriod
                                                                        }
                                                                        validFeedback=''
                                                                    />
                                                                </FormGroup>
                                                            </div>

                                                        )
                                                    }

                                                    {
                                                        formik.values.entryType && formik.values.entryType === '2' && (

                                                            <div className='col-12'>
                                                                <FormGroup
                                                                    id='entryDay'
                                                                    label='Dia de Lançamento'
                                                                    isFloating
                                                                >
                                                                    <Input
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.entryDay}
                                                                        isValid={formik.isValid}
                                                                        isTouched={formik.touched.entryDay}
                                                                        type="number"
                                                                        min={1}
                                                                        step={0}
                                                                        max={31}
                                                                        invalidFeedback={
                                                                            formik.errors.entryDay
                                                                        }
                                                                        validFeedback=''
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        )
                                                    }

                                                    <div className='col-12'>
                                                        <FormGroup
                                                            id='fee'
                                                            label="Taxa (%)"
                                                            isFloating
                                                        >
                                                            <Input
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.fee}
                                                                isValid={formik.isValid}
                                                                isTouched={formik.touched.fee}
                                                                type="number"
                                                                step={0.01}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>


                                    <div className='col-12'>
                                        <FormGroup>
                                            <Checks
                                                type='switch'
                                                id='status'
                                                label='Ativo?'
                                                name='status'
                                                onChange={formik.handleChange}
                                                checked={formik.values.status}
                                            />

                                        </FormGroup>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={formik.handleSubmit}
                                    isDisable={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Salvar
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default CommonPaymentTypeList;