import classNames from "classnames";
import { FC } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import useDarkMode from "../../../../hooks/useDarkMode";

interface ICommonNoStudentsCountProps {
    noStudentsCounter: number;
}

const NoStudentsCount: FC<ICommonNoStudentsCountProps> = ({ noStudentsCounter }) => {
    const { darkModeStatus } = useDarkMode();

    return (
        <Card
            className={classNames('transition-base rounded-2 mb-0 text-dark', {
                'bg-l25-primary bg-l10-primary-hover': !darkModeStatus,
                'bg-lo50-primary bg-lo25-primary-hover': darkModeStatus,
            })}
            shadow='sm'>
            <CardHeader className='bg-transparent'>
                <CardLabel>
                    <CardTitle tag='div' className='h5'>
                        Alunos Não Matriculados
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='d-flex align-items-center pb-3'>
                    <div className='flex-shrink-0'>
                        <Icon icon='People' size='4x' color='primary' />
                    </div>
                    <div className='flex-grow-1 ms-3'>
                        <div className='fw-bold fs-3 mb-0'>
                            {`${noStudentsCounter} aluno(s)`}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
};

NoStudentsCount.defaultProps = {
    noStudentsCounter: 0
};

export default NoStudentsCount;