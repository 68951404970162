import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getFilterMonth } from "../../../../common/data/calendar";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Label from "../../../../components/bootstrap/forms/Label";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import useDarkMode from "../../../../hooks/useDarkMode";
import ExportButton from "../../../_common/ExportButton";
import LoadingPage from "../../loading/LoadingPage";

const CommonMostAttendanceList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [filterMenu, setFilterMenu] = useState<boolean>(false);

    const [mostAttendances, setMostAttendances] = useState<IMostAttendances[]>([]);

    const headers = [
        { label: 'Nome', key: 'name' },
        { label: 'Matrícula', key: 'subscription' },
        { label: 'Presenças', key: 'attendanceCount' }
    ];

    const filterMonth = getFilterMonth();

    const formikFilter = useFormik({
        initialValues: {
            monthFilter: '',
            yearFilter: ''
        },

        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            getMostAttendances();

            setFilterMenu(false);
        }
    });

    useEffect(() => {
        getCurrentDate();

        getMostAttendances();

    }, []);

    const getMostAttendances = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetMostAttendances/${formikFilter.values.monthFilter}/${formikFilter.values.yearFilter}`);

        setIsLoading(false);

        if (response && response.success) {
            setMostAttendances(response.collection);
        } else {
            showWarning(response.message);
        }
    }

    const getCurrentDate = () => {
        const currentMonth = new Date().getMonth() + 1;
        formikFilter.values.monthFilter = currentMonth.toString();
        formikFilter.values.yearFilter = new Date().getFullYear().toString();
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='AccessTime' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Maiores Frequências
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>

                                    <ExportButton headers={headers} data={mostAttendances} filename="maiores-frequencias" />

                                    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu} isButtonGroup>
                                        <DropdownToggle hasIcon={false}>
                                            <Button isLink={true} icon='FilterAlt' color='primary'>
                                                Filtrar
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <div className='container py-2'>
                                                <form className='row g-3' onSubmit={formikFilter.handleSubmit}>

                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <Label htmlFor='monthFilter'>Mês</Label>
                                                            <Select
                                                                id='monthFilter'
                                                                ariaLabel='Mês'
                                                                placeholder='Mês'
                                                                list={filterMonth}
                                                                onChange={formikFilter.handleChange}
                                                                value={formikFilter.values.monthFilter}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <FormGroup id='yearFilter' label='Ano'>
                                                        <Input
                                                            onChange={formikFilter.handleChange}
                                                            value={formikFilter.values.yearFilter}
                                                        />
                                                    </FormGroup>

                                                    <div className='col-6'>
                                                        <Button
                                                            color='primary'
                                                            isOutline
                                                            className='w-100'
                                                            onClick={formikFilter.resetForm}>
                                                            Limpar
                                                        </Button>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Button color='primary' className='w-100' type='submit'>
                                                            Aplicar
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Matrícula</th>
                                            <th>Presenças</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataPagination(mostAttendances, currentPage, perPage).map((item) => (
                                            <tr key={item.id}>

                                                <td>{item.name}</td>
                                                <td>{item.subscription}</td>
                                                <td>{item.attendanceCount}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={mostAttendances}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonMostAttendanceList;