import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonCashFlowAccount from "./common/CommonCashFlowAccount";

const CashFlowAccountPage = () => {

    return (
        <PageWrapper title='Contas'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonCashFlowAccount />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default CashFlowAccountPage;