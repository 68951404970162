import { GET_TYPED_ASYNC } from '../components/extras/HttpHelper';

const BASE_PATH = 'api/NStudent';

export interface StudentResponse {
	success: boolean;
	students?: IStudent[];
	error?: string;
}

const getActiveWithSubscription = async (): Promise<StudentResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IStudent>(`${BASE_PATH}/GetActiveWithSubscription`);

		if (!response.success) {
			return { success: response.success, error: response.message || response.exception };
		}
		return { success: response.success, students: response.collection };
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	getActiveWithSubscription,
};
