import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonCashFlowAccountGroup from "./common/CommonCashFlowAccountGroup";

const CashFlowAccountGroupPage = () => {

    return (
        <PageWrapper title='Contas'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonCashFlowAccountGroup />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default CashFlowAccountGroupPage;