import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonClassCheckinList from "./common/CommonClassCheckinList";

const ClassCheckinPage = () => {

	return (
		<PageWrapper title='Fotos dos Treinos'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<CommonClassCheckinList />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ClassCheckinPage;