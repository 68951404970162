import { FC, useEffect, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import showNotification from "../../../../components/extras/showNotification";
import Icon from "../../../../components/icon/Icon";
import useDarkMode from "../../../../hooks/useDarkMode";
import systemConfigurationService from "../../../../services/system-configuration-service";
import LoadingPage from "../../loading/LoadingPage";
import { PixSettingsModal } from "../PixSettingsModal";


const CommonAddonsList: FC = () => {

    const { darkModeStatus } = useDarkMode();
    const [data, setData] = useState<IStudent[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isConfigurationFiled, setIsConfigurationFiled] = useState<boolean>(false);
    const [configuration, setConfiguration] = useState<IConfiguration | null>(null);

    useEffect(() => {
        getConfiguration()
    }, [isConfigurationFiled]);

    const getConfiguration = async () => {
        setIsLoading(true);
        const response = await systemConfigurationService.getConfiguration();
        if (response.configuration) {
            setIsConfigurationFiled(true);
            setConfiguration(response.configuration);
        }
        else { setIsConfigurationFiled(false); }
        setIsLoading(false);
    };

    const handlePixSubmit = () => {
        setIsLoading(true);
        setIsModalOpen(true);
        setIsLoading(false);
    };
    const handlePixCreate = () => {
        setIsLoading(true);
        setIsModalOpen(false);
        setIsLoading(false);
    }

    return (
        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (
                    <>

                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Extension' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Extensões do Sistema
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-4'>

                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <Card stretch>
                                            <CardBody>
                                                <div className='row pt-5 g-4 align-items-center'>
                                                    <div className='col-auto'>
                                                        <Icon icon='Payment' size='5x' color='primary' />
                                                    </div>
                                                    <div className='col'>
                                                        <h2>PIX</h2>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h3 className='display-1 fw-bold'>
                                                            <span className='display-4 fw-bold'>R$</span>0
                                                            <span className='display-6'>/mês</span>
                                                        </h3>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Cadastro de PIX                                                        </div>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> QR Code e PIX Copia e Cola
                                                        </div>
                                                    </div>

                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className='col-12'>
                                                    <Button
                                                        color='primary'
                                                        isLight
                                                        onClick={handlePixSubmit}
                                                        className='w-100 py-3 text-uppercase'
                                                        size='lg'
                                                    >
                                                        {configuration?.acceptPix && (`Alterar`) || (`Configurar`)}
                                                    </Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </div>

                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <Card stretch>
                                            <CardBody>
                                                <div className='row pt-5 g-4 align-items-center'>
                                                    <div className='col-auto'>
                                                        <Icon icon='DocumentScanner' size='5x' color='primary' />
                                                    </div>
                                                    <div className='col'>
                                                        <h2>Nota Fiscal de Serviço</h2>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h3 className='display-1 fw-bold'>
                                                            <span className='display-4 fw-bold'>R$</span>219
                                                            <span className='display-6'>/mês</span>
                                                        </h3>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Ambiente exclusivo para geração de Nota Fiscal
                                                        </div>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Automatização de envio de Nota Fiscal
                                                        </div>
                                                    </div>

                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className='col-12'>
                                                    <Button
                                                        color='primary'
                                                        isLight
                                                        className='w-100 py-3 text-uppercase'
                                                        onClick={() => {
                                                            showNotification(
                                                                'Ativar', // String, HTML or Component
                                                                'Entre em contato com o administrador para ativar este modulo', // String, HTML or Component
                                                                'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
                                                            );
                                                        }}
                                                        size='lg'>
                                                        Desativado
                                                    </Button>
                                                </div>
                                            </CardFooter>
                                        </Card>

                                    </div>

                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <Card stretch borderColor='primary' shadow='lg'>
                                            <CardBody>
                                                <div className='row pt-5 g-4 align-items-center'>
                                                    <div className='col-auto'>
                                                        <Icon icon='Textsms' size='5x' color='primary' />
                                                    </div>
                                                    <div className='col'>
                                                        <h2>Envio de Mensagens e Eventos</h2>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h3 className='display-1 fw-bold'>
                                                            <span className='display-4 fw-bold'>R$</span>100
                                                            <span className='display-6'>/mês</span>
                                                        </h3>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Número exclusivo para envio de mensagens
                                                        </div>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Automatização de envio de mensagens e eventos com fotos                                                        </div>
                                                    </div>


                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className='col-12'>
                                                    <Button
                                                        color='primary'
                                                        isLight
                                                        className='w-100 py-3 text-uppercase'
                                                        size='lg'
                                                        onClick={() => {
                                                            showNotification(
                                                                'Ativar', // String, HTML or Component
                                                                'Entre em contato com o administrador para ativar este modulo', // String, HTML or Component
                                                                'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
                                                            );
                                                        }}>
                                                        Desativado
                                                    </Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </div>

                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <Card stretch>
                                            <CardBody>
                                                <div className='row pt-5 g-4 align-items-center'>
                                                    <div className='col-auto'>
                                                        <Icon icon='Payment' size='5x' color='primary' />
                                                    </div>
                                                    <div className='col'>
                                                        <h2>Pagamentos On-line</h2>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h3 className='display-1 fw-bold'>
                                                            <span className='display-4 fw-bold'>R$</span>100
                                                            <span className='display-6'>/mês</span>
                                                        </h3>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> Alunos podem pagar através do sistema                                                        </div>
                                                        <div className='lead'>
                                                            <Icon icon='Done Outline' color='success' /> PIX, Cartão de Crédito e Boleto
                                                        </div>
                                                    </div>

                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className='col-12'>
                                                    <Button
                                                        color='primary'
                                                        isLight
                                                        className='w-100 py-3 text-uppercase'
                                                        size='lg'
                                                        onClick={() => {
                                                            showNotification(
                                                                'Ativar', // String, HTML or Component
                                                                'Entre em contato com o administrador para ativar este modulo', // String, HTML or Component
                                                                'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
                                                            );
                                                        }}>
                                                        Desativado
                                                    </Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                        <PixSettingsModal isFirstTimeCreated={!isConfigurationFiled} isModalOpened={isModalOpen} onModalClose={handlePixCreate} onAfterSubmit={handlePixCreate} selectedPix={configuration} />
                    </>
                )
            }
        </>
    );

}

export default CommonAddonsList;