import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import removeAccents from "remove-accents";
import belts from "../../../../common/data/beltData";
import cities, { ICity } from "../../../../common/data/cityData";
import { IFileUpload, OperationTypeEnum } from "../../../../common/data/document/fileUpload";
import UserTypeEnum from "../../../../common/data/enum/enumUserType";
import states from "../../../../common/data/stateData";
import Avatar from "../../../../components/Avatar";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardFooterLeft, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC, POST_ASYNC, PUT_ASYNC } from "../../../../components/extras/HttpHelper";
import { showCreateSuccess, showWarning } from "../../../../components/extras/Notifications";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import CommonFileUpload from "../../document/common/CommonFileUpload";
import instructorValidate from "../helper/instructorValidate";


type TTabs = 'Detalhes do Usuário' | 'Endereço' | 'Detalhes do Atleta' | 'Documentos';
interface ITabs {
    [key: string]: TTabs;
}

const TABS: ITabs = {
    ACCOUNT_DETAIL: 'Detalhes do Usuário',
    ADDRESS: 'Endereço',
    ATHLETE_DETAIL: 'Detalhes do Atleta',
    DOCUMENTS: 'Documentos'
};

interface ICommonInstructorProps {
    afterSubmit?: any;
}

const CommonInstructor: FC<ICommonInstructorProps> = ({ afterSubmit }) => {

    const { id } = useParams();
    const { userData, cognitoId } = useContext(AuthContext);

    const { darkModeStatus } = useDarkMode();
    const [image, setImage] = useState<string>('');
    const [selectedCities, setSelectedCities] = useState<ICity[]>([]);
    const [disableCity, setDisableCity] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [files, setFiles] = useState<IFileUpload[]>([]);
    const [activeTab, setActiveTab] = useState<TTabs>(TABS.ACCOUNT_DETAIL);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [selectedInstructor, setSelectedInstructor] = useState<IInstructor>();

    const formikInstructor = useFormik({
        initialValues: {
            id: '',
            fullName: '',
            phone: '',
            userName: '',
            email: '',
            birthDate: Date(),
            profileImage: null,

            document: '',
            nationalId: '',
            nationalIdSender: '',
            dateOfIssue: Date(),
            fathersName: '',
            mothersName: '',
            priceHour: 0,

            address: '',
            neighborhood: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'Brasil',
            observation: '',

            ibjjfId: '',
            compnetId: '',
            belt: '',
            grade: ''
        },
        validateOnChange: false,
        validate: instructorValidate,
        onSubmit: () => {
        }
    });

    useEffect(() => {
        resetForm();
        if (id) {
            getByCognitoId();
        }

    }, []);

    const resetForm = () => {
        setActiveTab(TABS.ACCOUNT_DETAIL)
        setImage('');

        formikInstructor.values.fullName = '';
        formikInstructor.values.phone = '';
        formikInstructor.values.address = '';
        formikInstructor.values.neighborhood = '';
        formikInstructor.values.zipCode = '';
        formikInstructor.values.country = 'Brasil';
        formikInstructor.values.observation = '';
        formikInstructor.values.ibjjfId = '';
        formikInstructor.values.compnetId = '';
        formikInstructor.values.belt = '';
        formikInstructor.values.grade = '';
        formikInstructor.values.profileImage = null;
        formikInstructor.values.city = '';
        formikInstructor.values.state = '';
        formikInstructor.values.birthDate = Date();
        formikInstructor.values.document = '';
        formikInstructor.values.nationalId = '';
        formikInstructor.values.nationalIdSender = '';
        formikInstructor.values.dateOfIssue = Date();
        formikInstructor.values.fathersName = '';
        formikInstructor.values.mothersName = '';
        formikInstructor.values.priceHour = 0;
    }

    const fillForm = (instructor: IInstructor) => {

        if (instructor && id) {
            handleStateOnBlur(instructor?.state || '');

            setImage(instructor.profileImageUrl);

            formikInstructor.values.id = instructor?.id || '';
            formikInstructor.values.fullName = instructor?.fullName || '';
            formikInstructor.values.userName = instructor?.username || '';
            formikInstructor.values.email = instructor?.email || '';
            formikInstructor.values.phone = instructor?.phone || '';
            formikInstructor.values.address = instructor?.address || '';
            formikInstructor.values.neighborhood = instructor?.neighborhood || '';
            formikInstructor.values.city = instructor?.city || '';
            formikInstructor.values.state = instructor?.state || '';
            formikInstructor.values.zipCode = instructor?.zipCode || '';
            formikInstructor.values.country = instructor?.country || '';
            formikInstructor.values.observation = instructor?.observation || '';
            formikInstructor.values.ibjjfId = instructor?.ibjjfId || '';
            formikInstructor.values.compnetId = instructor?.compnetId || '';
            formikInstructor.values.belt = instructor?.belt || '';
            formikInstructor.values.grade = instructor?.grade || '';
            formikInstructor.values.birthDate = instructor.birthDate ? dayjs(instructor.birthDate).format('YYYY-MM-DD') : '';

            formikInstructor.values.document = instructor?.document || '';
            formikInstructor.values.nationalId = instructor?.nationalId || '';
            formikInstructor.values.nationalIdSender = instructor?.nationalIdSender || '';
            formikInstructor.values.dateOfIssue = instructor?.dateOfIssue ? dayjs(instructor.dateOfIssue).format('YYYY-MM-DD') : '';
            formikInstructor.values.fathersName = instructor?.fathersName || '';
            formikInstructor.values.mothersName = instructor?.mothersName || '';
            formikInstructor.values.priceHour = instructor?.priceHour || 0;
        }
    }

    const getByCognitoId = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`api/NInstructor/GetByCognitoId/${id}`);

        setIsLoading(false);

        if (response && response.success) {
            setSelectedInstructor(response.entity)
            fillForm(response.entity);
        } else {
            showWarning(response.message);
        }
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {

            if (file.type !== 'image/jpeg') {
                showWarning('Formato da imagem inválido. Por favor, insira uma imagem jpg ou jpeg');
                return;
            }

            formikInstructor.setFieldValue('profileImage', file);

            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleStateOnBlur = (state: string) => {

        formikInstructor.handleBlur
        setSelectedCities([]);

        let filteredCities = cities.filter(
            (c) => c.state === state
        );

        if (filteredCities.length !== 0) {
            setSelectedCities(filteredCities);
            setDisableCity(false);
        } else {
            setDisableCity(true);
            setSelectedCities([]);
        }
    }

    const saveStudent = async () => {

        formikInstructor.submitForm();

        if (!image) {
            showWarning('Falha ao salvar o usuário. Por favor, verifique a imagem selecionada para o perfil do usuário.');
            return;
        }

        if (!formikInstructor.values.birthDate || formikInstructor.values.birthDate >= Date()) {
            showWarning('Falha ao salvar o usuário. Por favor, informe uma data de nascimento válida.');
            return;
        }

        if (formikInstructor.values.id) {
            await updateInstructor();
        } else {
            await createInstructor();
        }
    }

    const createInstructor = async () => {
        const formData = new FormData();

        formData.append('userType', UserTypeEnum.Instructor.toString());
        formData.append('fullName', formikInstructor.values.fullName);
        formData.append('phone', formikInstructor.values.phone);
        formData.append('address', formikInstructor.values.address);
        formData.append('neighborhood', formikInstructor.values.neighborhood);
        formData.append('city', formikInstructor.values.city);
        formData.append('state', formikInstructor.values.state);
        formData.append('zipCode', formikInstructor.values.zipCode);
        formData.append('country', formikInstructor.values.country);
        formData.append('observation', formikInstructor.values.observation);
        formData.append('ibjjfId', formikInstructor.values.ibjjfId);
        formData.append('compnetId', formikInstructor.values.compnetId);
        formData.append('belt', formikInstructor.values.belt);
        formData.append('grade', formikInstructor.values.grade);
        formData.append('birthDate', formikInstructor.values.birthDate);
        formData.append('document', formikInstructor.values.document);
        formData.append('nationalId', formikInstructor.values.nationalId);
        formData.append('nationalIdSender', formikInstructor.values.nationalIdSender);
        formData.append('dateOfIssue', formikInstructor.values.dateOfIssue);
        formData.append('fathersName', formikInstructor.values.fathersName);
        formData.append('mothersName', formikInstructor.values.mothersName);
        formData.append('priceHour', formikInstructor.values.priceHour.toString());

        if (formikInstructor.values.profileImage) {
            formData.append('profileImage', formikInstructor.values.profileImage);
        }

        if (files && files.length > 0) {
            files.map((document) => {
                if (document.file) {
                    formData.append('documentsJson', JSON.stringify(document));
                }
            });
        }

        setIsSubmitting(true);

        const response = await POST_ASYNC('api/NInstructor/Create', formData);

        setIsSubmitting(false);

        if (response && response.success) {
            showCreateSuccess();
            resetForm();

            if (afterSubmit) {
                afterSubmit();
            } else {
                showWarning(response.message);
            }
        }
    }

    const updateInstructor = async () => {

        const formData = new FormData();

        formData.append('id', formikInstructor.values.id);
        formData.append('userType', UserTypeEnum.Instructor.toString());
        formData.append('fullName', formikInstructor.values.fullName);
        formData.append('phone', formikInstructor.values.phone);
        formData.append('address', formikInstructor.values.address);
        formData.append('neighborhood', formikInstructor.values.neighborhood);
        formData.append('city', formikInstructor.values.city);
        formData.append('state', formikInstructor.values.state);
        formData.append('zipCode', formikInstructor.values.zipCode);
        formData.append('country', formikInstructor.values.country);
        formData.append('observation', formikInstructor.values.observation);
        formData.append('ibjjfId', formikInstructor.values.ibjjfId);
        formData.append('compnetId', formikInstructor.values.compnetId);
        formData.append('belt', formikInstructor.values.belt);
        formData.append('grade', formikInstructor.values.grade);
        formData.append('birthDate', formikInstructor.values.birthDate);
        formData.append('document', formikInstructor.values.document);
        formData.append('nationalId', formikInstructor.values.nationalId);
        formData.append('nationalIdSender', formikInstructor.values.nationalIdSender);
        formData.append('dateOfIssue', formikInstructor.values.dateOfIssue);
        formData.append('fathersName', formikInstructor.values.fathersName);
        formData.append('mothersName', formikInstructor.values.mothersName);
        formData.append('priceHour', formikInstructor.values.priceHour.toString());

        if (selectedInstructor) {
            formData.append('cognitoUserId', selectedInstructor.cognitoUserId);
            formData.append('profileImageUrl', selectedInstructor.profileImageUrl);
            formData.append('stauts', selectedInstructor.status.toString());
            formData.append('creationDate', selectedInstructor.creationDate ? dayjs(selectedInstructor.creationDate).toString() : '');
            formData.append('lastUpdate', selectedInstructor.lastUpdate ? dayjs(selectedInstructor.lastUpdate).toString() : '');
            formData.append('inactivationDate', selectedInstructor.inactivationDate ? dayjs(selectedInstructor.inactivationDate).toString() : '');
        }

        if (formikInstructor.values.profileImage) {
            formData.append('profileImage', formikInstructor.values.profileImage);
        }

        if (files && files.length > 0) {
            files.map((document) => {
                if (document.file) {
                    formData.append('documentsJson', JSON.stringify(document));
                }
            });
        }

        if (id) {
            formData.append('email', formikInstructor.values.email);
            formData.append('userName', formikInstructor.values.userName);
        }

        setIsSubmitting(true);

        const response = await PUT_ASYNC('api/NInstructor/Update', formData);

        setIsSubmitting(false);

        if (response && response.success) {
            showCreateSuccess();
            resetForm();

            if (afterSubmit) {
                afterSubmit();
            } else {
                showWarning(response.message);
            }
        }
    }

    const onUpdateDocuments = (fileToUpload: IFileUpload) => {

        if (fileToUpload.operationType === OperationTypeEnum.Add) {
            setFiles([...files, fileToUpload]);
        } else {
            const updatedFiles = files.filter((file) => file.name !== fileToUpload.name);
            setFiles(updatedFiles);
        }
    }

    return (
        <>
            <div className='row h-100 w-100' style={{ minHeight: 600 }}>

                <div className='col-xxl-3 col-xl-4 col-lg-6'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel icon='PeopleAlt' iconColor='primary'>
                                <CardTitle className='h5'>
                                    Informações da Conta
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody isScrollable>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <Button
                                        icon='Contacts'
                                        color='primary'
                                        className='w-100 p-3'
                                        isLight={TABS.ACCOUNT_DETAIL !== activeTab}
                                        onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}>
                                        {TABS.ACCOUNT_DETAIL}
                                    </Button>
                                </div>
                                <div className='col-12'>
                                    <Button
                                        icon='Place'
                                        color='primary'
                                        className='w-100 p-3'
                                        isLight={TABS.ADDRESS !== activeTab}
                                        onClick={() => setActiveTab(TABS.ADDRESS)}>
                                        {TABS.ADDRESS}
                                    </Button>
                                </div>
                                <div className='col-12'>
                                    <Button
                                        icon='SportsKabaddi'
                                        color='primary'
                                        className='w-100 p-3'
                                        isLight={TABS.ATHLETE_DETAIL !== activeTab}
                                        onClick={() => setActiveTab(TABS.ATHLETE_DETAIL)}>
                                        {TABS.ATHLETE_DETAIL}
                                    </Button>
                                </div>

                                <div className='col-12'>
                                    <Button
                                        icon='UploadFile'
                                        color='primary'
                                        className='w-100 p-3'
                                        isLight={TABS.DOCUMENTS !== activeTab}
                                        onClick={() => setActiveTab(TABS.DOCUMENTS)}
                                    >
                                        {TABS.DOCUMENTS}
                                    </Button>
                                </div>

                            </div>
                        </CardBody>
                        <CardFooter>
                            <CardFooterLeft className='w-100'>
                                <Button
                                    color='success'
                                    isLight
                                    className='w-100 p-3'
                                    onClick={saveStudent}>
                                    Salvar Professor
                                </Button>
                            </CardFooterLeft>
                        </CardFooter>
                    </Card>
                </div>

                <div className='col-xxl-9 col-xl-8 col-lg-6'>

                    {TABS.ACCOUNT_DETAIL === activeTab && (

                        <Card stretch noValidate>
                            <CardHeader>
                                <CardLabel icon='Contacts' iconColor='primary'>
                                    <CardTitle className='h5'>
                                        Detalhes do Professor
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody isScrollable>

                                <Card>
                                    <CardBody>
                                        <div className='row g-4 align-items-center'>

                                            {
                                                image && (
                                                    <div className='col-xl-auto'>
                                                        <Avatar srcSet={image} src={image} />
                                                    </div>
                                                )
                                            }
                                            <div className='col-xl'>
                                                <div className='row g-4'>
                                                    <div className='col-auto'>
                                                        <Input
                                                            type='file'
                                                            autoComplete='photo'
                                                            ariaLabel='Selecione uma imagem para o usuário'
                                                            accept='.jpg, .jpeg'
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>
                                                    <div className='col-12'>
                                                        <p className='lead text-muted'>
                                                            A imagem será utilizada no perfil e processo de autenticação do usuário.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <CardLabel icon='Edit' iconColor='primary'>
                                            <CardTitle className='h5'>
                                                Dados Pessoais
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='fullName'
                                                    label='Nome Completo'
                                                    isFloating
                                                >
                                                    <Input
                                                        placeholder='Nome Completo'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.fullName)}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.fullName}
                                                        invalidFeedback={
                                                            formikInstructor.errors.fullName
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='phone'
                                                    label='Telefone'
                                                    isFloating>
                                                    <Input
                                                        placeholder='Telefone'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.phone}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.phone}
                                                        mask="(99) 99999-9999"
                                                        invalidFeedback={
                                                            formikInstructor.errors.phone
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='birthDate'
                                                    label='Data de Nascimento'
                                                    isFloating>
                                                    <Input
                                                        type="date"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.birthDate}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.birthDate}
                                                        invalidFeedback={
                                                            formikInstructor.errors.birthDate
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='priceHour'
                                                    label='Valor por Hora'
                                                    isFloating>
                                                    <Input
                                                        type={'number'}
                                                        step={0.01}
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.priceHour}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.priceHour}
                                                        invalidFeedback={
                                                            formikInstructor.errors.priceHour
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='fathersName'
                                                    label='Nome do Pai'
                                                    isFloating>
                                                    <Input
                                                        placeholder="Nome do Pai"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.fathersName}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.fathersName}
                                                        invalidFeedback={
                                                            formikInstructor.errors.fathersName
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='mothersName'
                                                    label='Nome da Mãe'
                                                    isFloating>
                                                    <Input
                                                        placeholder="Nome da Mãe"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.mothersName}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.mothersName}
                                                        invalidFeedback={
                                                            formikInstructor.errors.mothersName
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='document'
                                                    label='CPF'
                                                    isFloating>
                                                    <Input
                                                        placeholder="CPF"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.document}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.document}
                                                        invalidFeedback={
                                                            formikInstructor.errors.document
                                                        }
                                                        mask="999.999.999-99"
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='nationalId'
                                                    label='RG'
                                                    isFloating>
                                                    <Input
                                                        placeholder="RG"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.nationalId}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.nationalId}
                                                        invalidFeedback={
                                                            formikInstructor.errors.nationalId
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='nationalIdSender'
                                                    label='Órgão Emissor'
                                                    isFloating>
                                                    <Input
                                                        placeholder="Órgão Emissor"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.nationalIdSender}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.nationalIdSender}
                                                        invalidFeedback={
                                                            formikInstructor.errors.nationalIdSender
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup
                                                    id='dateOfIssue'
                                                    label='Data de Emissão'
                                                    isFloating>
                                                    <Input
                                                        type="date"
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.dateOfIssue}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.dateOfIssue}
                                                        invalidFeedback={
                                                            formikInstructor.errors.dateOfIssue
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>

                            </CardBody>
                        </Card>

                    )}

                    {TABS.ADDRESS === activeTab && (

                        <Card stretch noValidate>
                            <CardHeader>
                                <CardLabel icon='Place' iconColor='primary'>
                                    <CardTitle className='h5'>
                                        Endereço
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody isScrollable>

                                <Card>
                                    <CardBody>
                                        <div className='row g-4'>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='address'
                                                    label='Endereço'
                                                    isFloating>
                                                    <Input
                                                        placeholder='Endereço'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.address)}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.address}
                                                        invalidFeedback={
                                                            formikInstructor.errors.address
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='neighborhood'
                                                    label='Bairro'
                                                    isFloating>
                                                    <Input
                                                        placeholder='Bairro'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.neighborhood)}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='state'
                                                    label='Estado'
                                                    isFloating>
                                                    <Select
                                                        ariaLabel='Escolha um estado'
                                                        placeholder='Escolha um estado'
                                                        list={states}
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={() => handleStateOnBlur(formikInstructor.values.state)}
                                                        value={formikInstructor.values.state}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.state}
                                                        invalidFeedback={
                                                            formikInstructor.errors.state
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='city'
                                                    label='Cidade'
                                                    isFloating>
                                                    <Select
                                                        ariaLabel='Escolha uma cidade'
                                                        placeholder='Escolha uma cidade'
                                                        list={selectedCities}
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.city}
                                                        isValid={formikInstructor.isValid}
                                                        isTouched={formikInstructor.touched.city}
                                                        disabled={disableCity}
                                                        invalidFeedback={
                                                            formikInstructor.errors.city
                                                        } />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='zipCode'
                                                    label='CEP'
                                                    isFloating>
                                                    <Input
                                                        placeholder='CEP'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.zipCode}
                                                        mask="99.999-999"
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='country'
                                                    label='País'
                                                    isFloating>
                                                    <Input
                                                        placeholder='País'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.country)}
                                                        isTouched={formikInstructor.touched.country}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='observation'
                                                    label='Complemento'
                                                    isFloating>
                                                    <Input
                                                        placeholder='Complemento'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.observation)}
                                                    />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>

                            </CardBody>
                        </Card>

                    )}

                    {TABS.ATHLETE_DETAIL === activeTab && (
                        <Card stretch noValidate>
                            <CardHeader>
                                <CardLabel icon='SportsKabaddi' iconColor='primary'>
                                    <CardTitle className='h5'>
                                        Detalhes do Atleta
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody isScrollable>
                                <Card>
                                    <CardBody>
                                        <div className='row g-4'>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='belt'
                                                    label='Faixa'
                                                    isFloating>
                                                    <Select
                                                        ariaLabel='Escolha uma faixa'
                                                        placeholder='Escolha uma faixa'
                                                        list={belts}
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.belt}
                                                    />
                                                </FormGroup>
                                            </div>


                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='grade'
                                                    label='Grau'
                                                    isFloating>
                                                    <Select
                                                        ariaLabel='Escolha um grau'
                                                        placeholder='Escolha um grau'
                                                        list={
                                                            [
                                                                { value: '0', text: 'Sem Grau' },
                                                                { value: '1', text: 'Grau 1' },
                                                                { value: '2', text: 'Grau 2' },
                                                                { value: '3', text: 'Grau 3' },
                                                                { value: '4', text: 'Grau 4' },
                                                            ]
                                                        }
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={formikInstructor.values.grade}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='ibjjfId'
                                                    label='IBJJF'
                                                    isFloating>
                                                    <Input
                                                        placeholder='IBJJF'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.ibjjfId)}
                                                    />
                                                </FormGroup>
                                            </div>


                                            <div className='col-lg-6'>
                                                <FormGroup
                                                    id='compnetId'
                                                    label='CompNet'
                                                    isFloating>
                                                    <Input
                                                        placeholder='CompNet'
                                                        onChange={formikInstructor.handleChange}
                                                        onBlur={formikInstructor.handleBlur}
                                                        value={removeAccents(formikInstructor.values.compnetId)}
                                                    />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>

                            </CardBody>
                        </Card>
                    )}

                    {TABS.DOCUMENTS === activeTab && (
                        <CommonFileUpload onUpdateDocuments={onUpdateDocuments} uploadedFiles={selectedInstructor?.documents || []} />
                    )}
                </div>

            </div>
        </>
    );

};

export default CommonInstructor;

