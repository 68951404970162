import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserImage7 from '../../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../../assets/img/wanna/wanna7.webp';
import Avatar from "../../../components/Avatar";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader } from "../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import { GET_ASYNC } from "../../../components/extras/HttpHelper";
import { showWarning } from "../../../components/extras/Notifications";
import AuthContext from "../../../contexts/authContext";
import { priceFormat } from "../../../helpers/helpers";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { dashboardPagesMenu } from "../../../menu";
import CommonInstructor from "../user/common/CommonInstructor";

const InstructorDetail = () => {

	const { action } = useParams();
	const { id } = useParams();
	const [activeInstructor, setActiveInstructor] = useState<IInstructor>();
	const [instructorDetailsEditOffcanvas, setInstructorDetailsEditOffcanvas] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const { userData } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		getInstructorByCognitoId();
	}, [id, userData]);

	const getInstructorByCognitoId = async () => {

		setIsLoading(true);

		const response = await GET_ASYNC(`api/NInstructor/GetByCognitoId/${id}`);

		setIsLoading(false);

		if (response && response.success) {
			setActiveInstructor(response.entity);
		} else {
			showWarning(response.message);
		}
	}

	const handleInstructorDetailEdit = () => {
		setInstructorDetailsEditOffcanvas(!instructorDetailsEditOffcanvas);
	};

	const afterSubmit = () => {
		navigate('/instructor/instructors');
	}

	return (
		<PageWrapper title={'Detalhes do Aluno'}>

			<SubHeader>

				<SubHeaderLeft>
					<Button
						color='primary'
						isLink
						icon='ArrowBack'
						tag='a'
						to={`../${dashboardPagesMenu.dashboard.path}`}  >
						{/* to={action === 'detail' ? `../${appMenu.registers.subMenu.instructors.path}` : `../${dashboardPagesMenu.dashboard.path}`} */}
						Voltar
					</Button>
				</SubHeaderLeft>

				<SubHeaderRight>
					<Button
						color={'warning'}
						icon='Edit'
						onClick={handleInstructorDetailEdit}>
						Alterar
					</Button>
				</SubHeaderRight>
			</SubHeader>

			<Page>
				<div className='pt-3 pb-5 d-flex align-items-center'>
					<span className='display-4 fw-bold me-3'>{activeInstructor?.fullName}</span>
				</div>

				<div className='row'>

					<div className='col-lg-4'>

						<Card >
							<CardBody>
								<div className='row g-5 py-3'>
									<div className='col-12 d-flex justify-content-center'>
										<Avatar
											src={activeInstructor?.profileImageUrl || UserImage7}
											srcSet={activeInstructor?.profileImageUrl || UserImage7Webp}
											color='primary'
											className='rounded-circle'
											shadow='sm'
											size={110}
										/>
									</div>

									<div className='col-12'>
										<div className='row g-5'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'Usuário'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.username}
														</div>
													</div>
												</div>

												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'E-mail'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.email}
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</CardBody>
						</Card>

					</div>

					<div className='col-lg-8'>

						<Card>
							<CardBody>
								<div className='row g-5 py-3'>


									<div className='col-4'>

										<div className='d-flex align-items-center'>
											<div className='flex-grow-1 ms-3'>
												<div className='text-muted'>
													<small>{'Data de Nascimento'}</small>
												</div>
												<div className='fw-bold fs-5 mb-0'>
													{activeInstructor?.birthDate ? dayjs(activeInstructor?.birthDate).format('DD/MM/YYYY') : '-'}
												</div>
											</div>
										</div>

										<div className='d-flex align-items-center'>
											<div className='flex-grow-1 ms-3'>
												<div className='text-muted'>
													<small>{'Telefone'}</small>
												</div>
												<div className='fw-bold fs-5 mb-0'>
													{activeInstructor?.phone || '-'}
												</div>
											</div>
										</div>

                                        <div className='d-flex align-items-center'>
											<div className='flex-grow-1 ms-3'>
												<div className='text-muted'>
													<small>{'Valor Hora'}</small>
												</div>
												<div className='fw-bold fs-5 mb-0'>
													{ activeInstructor?.priceHour ? priceFormat(activeInstructor?.priceHour) : '-' }
												</div>
											</div>
										</div>
									</div>

									<div className='col-4'>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'Endereço'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.address || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'Bairro'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.neighborhood || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'Cidade'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.city || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'CEP'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.zipCode || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'País'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.country || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															<small>{'Complemento'}</small>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															{activeInstructor?.observation || '-'}
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>

									<div className='col-4'>
										<div className='col-12'>
											<div className='row g-3'>
												<div className='col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																<small>{'Faixa'}</small>
															</div>
															<div className='fw-bold fs-5 mb-0'>
																{activeInstructor?.belt || '-'}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='row g-3'>
												<div className='col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																<small>{'Grau'}</small>
															</div>
															<div className='fw-bold fs-5 mb-0'>
																{activeInstructor?.grade || '-'}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='row g-3'>
												<div className='col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																<small>{'IBJJF'}</small>
															</div>
															<div className='fw-bold fs-5 mb-0'>
																{activeInstructor?.ibjjfId || '-'}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='row g-3'>
												<div className='col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																<small>{'CompNet'}</small>
															</div>
															<div className='fw-bold fs-5 mb-0'>
																{activeInstructor?.compnetId || '-'}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>

					</div>
				</div>

				<Modal
					tag='form'
					setIsOpen={setInstructorDetailsEditOffcanvas}
					isOpen={instructorDetailsEditOffcanvas}
					titleId='instructorDetailEdit'
					isCentered
					size='xl'
					isStaticBackdrop={true}
				>
					<ModalHeader setIsOpen={setInstructorDetailsEditOffcanvas}>
						<OffCanvasTitle id='studentDetailEdit'>Alterar Professor</OffCanvasTitle>
					</ModalHeader>

					<ModalBody>

						<CommonInstructor afterSubmit={afterSubmit} />

					</ModalBody>

				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default InstructorDetail;