import classNames from "classnames";
import { FC } from "react";
import Blue from "../../../../assets/img/belt/bluebelt.png";
import Brown from "../../../../assets/img/belt/brownbelt.png";
import Default from "../../../../assets/img/belt/defaultbelt.png";
import Green from "../../../../assets/img/belt/greenbelt.png";
import Grey from "../../../../assets/img/belt/greybelt.png";
import Instructor from "../../../../assets/img/belt/instructorblackbelt.png";
import Master from "../../../../assets/img/belt/mestrebelt.png";
import Purple from "../../../../assets/img/belt/purplebelt.png";
import White from "../../../../assets/img/belt/whitebelt.png";
import { default as Orange, default as Yellow } from "../../../../assets/img/belt/yellowbelt.png";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import useDarkMode from "../../../../hooks/useDarkMode";
import Belt from "../../../_common/Belt";

interface ICommonDashboardGraduationCardProps {
    student?: IStudent
}

const CommonDashboardGraduationCard: FC<ICommonDashboardGraduationCardProps> = ({ student }) => {

    const { darkModeStatus } = useDarkMode();

    const getBeltImage = (belt: string | undefined) => {
        switch (belt) {
            case 'Cinza':
            case 'CinzaEBranca':
            case 'CinzaEPreta':
                return Grey;
            case 'Laranja':
            case 'LaranjaEBranca':
            case 'LaranjaEPreta':
                return Orange;
            case 'Azul':
                return Blue;
            case 'Marrom':
                return Brown;
            case 'Branca':
                return White;
            case 'Amarela':
                return Yellow;
            case 'Verde':
            case 'VerdeEBranca':
            case 'VerdeEPreta':
                return Green;
            case 'Roxa':
                return Purple;
            case 'Vermelha':
                return Master;
            case 'Preta':
                return Instructor;
            default:
                return Default;
        }
    };

    return (
        <Card borderSize={1} stretch>
            <CardBody>

                <div className='row g-2'>
                    <div className='col-12'>
                        {student?.belt ? (
                            <>
                                <div className='fw-bold fs-6 mb-0'>
                                    Faixa
                                </div>
                                {(
                                    <div className={`w-auto p-3 stripe-${student.grade}`}>
                                        <Belt
                                            src={getBeltImage(student?.belt)}
                                            color="light"
                                            shadow="sm"
                                            width={340}
                                            height={100}
                                        />
                                            {student.grade !== "" && student.grade === "3" && (
                                            <div className="extra-stripe"></div>)}
                                              {student.grade !== "" && student.grade === "4" && 
                                              (<><div className="extra-stripe-1"></div><div className="extra-stripe-2"></div></>)}
                                            
                                            {/* <div className="extra-stripe-2"></div> */}
                                            {/* <div className="extra-stripe-2"></div> */}
                                    </div>
                                )}
                               
                            </>
                        ) : (
                            <>
                                <div className='d-flex align-items-center'>
                                    <div className='flex-shrink-0'>
                                        <div className='ratio ratio-1x1' style={{ width: 72 }}>
                                            <div
                                                className={classNames(
                                                    'rounded-2 d-flex align-items-center justify-content-center',
                                                    {
                                                        'bg-l10-primary': !darkModeStatus,
                                                        'bg-lo25-primary': darkModeStatus,
                                                    },
                                                )}>
                                                <span className='text-primary fs-1 fw-bold'>
                                                    <Icon icon={'SportsKabaddi'} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                        <div>
                                            <div className='fw-bold fs-6 mb-0'>{'Graduação não cadastrada'}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            </CardBody>
        </Card >
    );
};

CommonDashboardGraduationCard.defaultProps = {
    student: undefined
};


export default CommonDashboardGraduationCard;
