import { useEffect, useState } from 'react';
import { getDecodedToken } from '../../../../common/data/jwtPayload';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import { ScheduleCalendar } from './ScheduleCalendar';

interface Props {
	schedules: IParseSchedule[];
	handleAddScheduleClick: () => void;
	onScheduleClick: (schedule: IParseSchedule) => void;
}

export const ScheduleCard = ({ schedules, handleAddScheduleClick, onScheduleClick }: Props) => {
	const [role, setRole] = useState<boolean>();

	const userData = getDecodedToken();

	useEffect(() => {
		const roles = userData['cognito:groups'];

		setRole(roles?.includes('Professores') || roles?.includes('Administradores'));
	}, [userData]);
	
	return (
		<Card stretch>
			<CardHeader borderSize={1}>
				<CardLabel icon='Class' iconColor='primary'>
					<CardTitle tag='div' className='h5'>
						Cronograma
					</CardTitle>
				</CardLabel>
				{role && (
					<CardActions>
						<Button
							color='success'
							icon='AddCircle'
							isLight
							onClick={() => handleAddScheduleClick()}>
							Adicionar
						</Button>
					</CardActions>
				)}
			</CardHeader>
			<CardBody>
				<ScheduleCalendar onScheduleClick={onScheduleClick} schedules={schedules} />
			</CardBody>
		</Card>
	);
};

export default ScheduleCard;
