import classNames from "classnames";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import enumOperation from "../../../../common/data/enum/enumOperation";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Badge from "../../../../components/bootstrap/Badge";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import Spinner from "../../../../components/bootstrap/Spinner";
import Checks from "../../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { GET_ASYNC, POST_ASYNC, PUT_ASYNC } from "../../../../components/extras/HttpHelper";
import { showCreateError, showCreateSuccess, showUpdateSuccess, showWarning } from "../../../../components/extras/Notifications";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";
import LoadingPage from "../../loading/LoadingPage";

const SubscriptionTypeList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [currentPage, setCurrentPage] = useState(1);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [modalTitle, setModalTitle] = useState('');
    const [subscriptionTypeOffcanvas, setSubscriptionTypeOffcanvas] = useState(false);
    const [operation, setOperation] = useState<enumOperation>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [currentSubscriptionType, setCurrentSubscriptionType] = useState<ISubscriptionTypeRQ>();

    const formik = useFormik({
        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            return undefined;
        },
        initialValues: {
            id: undefined,
            description: '',
            fee: undefined,
            isActive: true
        },
    });

    const resetForm = () => {
        formik.values.id = undefined;
        formik.values.description = '';
        formik.values.fee = undefined;
        formik.values.isActive = true;
    }

    useEffect(() => {
        getSubscriptionTypes();
    }, []);

    const getSubscriptionTypes = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC('api/NSubscriptionType/GetAll');

        setIsLoading(false);

        if (response && response.success) {
            setSubscriptionTypes(response.collection);
        } else {
            showWarning(response.message);
        }
    };

    const handleSubscriptionTypeOffcanvas = () => {
        setSubscriptionTypeOffcanvas(!subscriptionTypeOffcanvas);
        resetForm();
        setModalTitle('Criar Plano');
        setOperation(enumOperation.Create);
    };

    const saveSubscriptionType = async () => {

        if (!formik.values.description || formik.values.fee === undefined) {
            showWarning('Falha na validação. Por favor, verifique os dados fornecidos.');
            return;
        }

        if (formik.values.id) {
            await updateSubscriptionType()
        } else {
            await createSubscriptionType();
        }
    }

    const createSubscriptionType = async () => {

        const request = {
            description: formik.values.description,
            fee: formik.values.fee,
        };

        setIsSubmitting(true);

        const response = await POST_ASYNC(`api/NSubscriptionType/Create`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setSubscriptionTypeOffcanvas(false);
            getSubscriptionTypes();

            showCreateSuccess();
        } else {
            showCreateError();
        }
    };

    const updateSubscriptionType = async () => {

        const request = {
            id: formik.values.id,
            description: formik.values.description,
            fee: formik.values.fee,
            status: formik.values.isActive,
            creationDate: currentSubscriptionType?.creationDate,
            lastUpdate: currentSubscriptionType?.lastUpdate,
            inactivationDate: currentSubscriptionType?.inactivationDate,
        };

        setIsSubmitting(true);

        const response = await PUT_ASYNC(`api/NSubscriptionType/Update`, request);

        setIsSubmitting(false);

        if (response && response.success) {
            resetForm();
            setSubscriptionTypeOffcanvas(false);
            getSubscriptionTypes();

            showUpdateSuccess();
        } else {
            showCreateError();
        }
    }

    const inactivateSubscriptionType = async (guid: string) => {

        const request = {
            id: guid
        };

        setIsLoading(true);

        const response = await POST_ASYNC('api/NSubscriptionType/Inactivate', request);

        setIsLoading(false);

        if (response && response.success) {
            resetForm();
            setSubscriptionTypeOffcanvas(false);
            getSubscriptionTypes();

            showUpdateSuccess();
        } else {
            showWarning(response.message);
        }
    }

    const getSubscriptionTypeById = async (id: string) => {

        setOperation(enumOperation.Update);

        setIsLoading(true);

        const response = await GET_ASYNC(`api/NSubscriptionType/GetById/${id}`);

        setIsLoading(false);

        if (response && response.success) {
            resetForm();

            setCurrentSubscriptionType(response.entity);

            formik.values.id = response.entity.id;
            formik.values.description = response.entity.description;
            formik.values.fee = response.entity.fee;
            formik.values.isActive = response.entity.status;

            setModalTitle('Alterar Plano');
            setSubscriptionTypeOffcanvas(!subscriptionTypeOffcanvas);
        } else {
            showWarning(response.message);
        }
    };

    return (

        <>
            {
                isLoading ? (
                    <LoadingPage />
                ) : (

                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Article' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Planos
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>
                                    <Button
                                        color='success'
                                        icon='AddCircle'
                                        isLight
                                        onClick={() => handleSubscriptionTypeOffcanvas()}>
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Valor</th>
                                            <th>Situação</th>
                                            <td aria-labelledby='Actions' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataPagination(subscriptionTypes, currentPage, perPage).map((item) => (
                                            <tr key={item.id}>

                                                <td>{item.description}</td>

                                                <td>{item.fee !== undefined && priceFormat(item.fee)}</td>

                                                <td>
                                                    {item.status ? (
                                                        <h5>
                                                            <Badge color='success' className='align-items-center justify-content-center'>
                                                                Ativo
                                                            </Badge>
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            <Badge color='danger' className='align-items-center justify-content-center'>
                                                                Inativo
                                                            </Badge>
                                                        </h5>
                                                    )}
                                                </td>

                                                <td className="text-end">
                                                    <Dropdown>
                                                        <DropdownToggle hasIcon={false}>
                                                            <Button
                                                                icon='MoreHoriz'
                                                                color='dark'
                                                                isLight
                                                                shadow='sm'
                                                                aria-label='Mais Ações'
                                                            />
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd>
                                                            <DropdownItem>
                                                                <Button
                                                                    isOutline={!darkModeStatus}
                                                                    color='dark'
                                                                    isLight={darkModeStatus}
                                                                    className={classNames('text-nowrap', {
                                                                        'border-light': !darkModeStatus,
                                                                    })}
                                                                    icon='Edit'
                                                                    onClick={() => getSubscriptionTypeById(item.id)}>
                                                                    Alterar
                                                                </Button>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <Button
                                                                    isOutline={!darkModeStatus}
                                                                    color='dark'
                                                                    isLight={darkModeStatus}
                                                                    className={classNames('text-nowrap', {
                                                                        'border-light': !darkModeStatus,
                                                                    })}
                                                                    icon='Delete'
                                                                    onClick={() => inactivateSubscriptionType(item.id)}>
                                                                    Inativar
                                                                </Button>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                data={subscriptionTypes}
                                label='planos'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>

                        <Modal
                            setIsOpen={setSubscriptionTypeOffcanvas}
                            isOpen={subscriptionTypeOffcanvas}
                            titleId='subscriptionType'
                            isCentered
                            isScrollable
                            isStaticBackdrop={true}
                        >
                            <ModalHeader setIsOpen={setSubscriptionTypeOffcanvas}>
                                <OffCanvasTitle id='subscriptionType'>{modalTitle}</OffCanvasTitle>
                            </ModalHeader>
                            <ModalBody>
                                <div className='row g-4'>

                                    {
                                        operation == enumOperation.Update && (
                                            <div className='col-12'>
                                                <FormGroup id='id' label='Código' isFloating>
                                                    <Input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.id}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )
                                    }

                                    <div className='col-12'>
                                        <FormGroup id='description' label='Descrição' isFloating>
                                            <Input
                                                onChange={formik.handleChange}
                                                value={formik.values.description}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <FormGroup id='fee' label='Valor' isFloating>
                                            <Input
                                                type={'number'}
                                                step={0.01}
                                                onChange={formik.handleChange}
                                                value={formik.values.fee}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-12'>
                                        <FormGroup>
                                            <Checks
                                                type='switch'
                                                id='isActive'
                                                label='Ativo?'
                                                name='isActive'
                                                onChange={formik.handleChange}
                                                checked={formik.values.isActive}
                                            />

                                        </FormGroup>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className='bg-transparent'>
                                <Button
                                    color='primary'
                                    className='w-100'
                                    onClick={saveSubscriptionType}
                                    isDisable={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner isSmall inButton isGrow />
                                    )}
                                    Salvar
                                </Button>
                            </ModalFooter>
                        </Modal>

                    </>
                )
            }

        </>

    );
};

export default SubscriptionTypeList;
