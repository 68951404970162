import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubscriptionTypeList from "./common/SubscriptionTypeList";

const SubscriptionTypePage = () => {

	return (
		<PageWrapper title='Tipos de Matrícula'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<SubscriptionTypeList />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SubscriptionTypePage;