import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import { CardBodyTable } from '../../../../components/table/CardBodyTable';
import { CourseActionsDropdown } from './CourseDropdown';

interface Props {
	courses: ICourse[];
	handleAddCourseClick: () => void;
	handleDeleteCourseClick: (id: string) => void;
	handleCourseDetailClick: (course: ICourse) => void;
}

export const CoursesCard = ({
	courses,
	handleAddCourseClick,
	handleDeleteCourseClick,
	handleCourseDetailClick,
}: Props) => {
	return (
		<Card stretch style={{ minHeight: 150 }}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Class' iconColor='primary'>
					<CardTitle tag='div' className='h5'>
						Aulas
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Button color='success' icon='AddCircle' isLight onClick={handleAddCourseClick}>
						Adicionar
					</Button>
				</CardActions>
			</CardHeader>
			<CardBodyTable
				tableClassNames='table-hover'
				data={courses}
				head={
					<tr>
						<th>Descrição</th>

						<th>Duração (min)</th>
						<td aria-labelledby='Actions' />
					</tr>
				}
				body={(item, index) => (
					<tr key={index}>
						<td>
							<span>{item.name}</span>
						</td>
						<td>
							<span>{item.totalMinutes}</span>
						</td>

						<td className='text-end'>
							<CourseActionsDropdown
								item={item}
								handleDeleteCourseClick={handleDeleteCourseClick}
								handleCourseDetailClick={handleCourseDetailClick}
							/>
						</td>
					</tr>
				)}
			/>
		</Card>
	);
};
