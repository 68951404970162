import dayjs from "dayjs";
import { FC } from "react";
import { CSVLink } from "react-csv";
import Icon from "../../components/icon/Icon";

interface IExportButtonProps {
    data: any;
    headers: any;
    filename: string;

}

const ExportButton: FC<IExportButtonProps> = ({ data, headers, filename }) => {
    return (
        <>
            <CSVLink
                className="btn btn-light-info"
                data={data}
                headers={headers}
                filename={filename + '-' + dayjs().format('DDMMYYYY')}
                separator={";"}
                enclosingCharacter={`"`}
            >
                <Icon icon={'CloudDownload'} className='btn-icon' />
                Exportar
            </CSVLink>
        </>
    );
}

ExportButton.defaultProps = {
    data: [],
    headers: []
};

export default ExportButton;