import { FC } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import CommonLastAttendanceList from "./common/CommonLastAttendanceList";

const LastAttendanceReportPage: FC = () => {

    return (
        <PageWrapper title='Ausência'>
            <Page container='fluid'>
                <div className='row'>
                    <div className='col-xl-12'>
                        <CommonLastAttendanceList />
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );

};

export default LastAttendanceReportPage;