import { useEffect, useState } from "react";
import useDarkMode from "../../../hooks/useDarkMode";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import CommonCashFlowEntries from "./common/CommonCashFlowEntries";

const CashFlowPage = () => {

    return (
        <PageWrapper title='Fluxo de Caixa'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonCashFlowEntries />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default CashFlowPage;