import { GET_TYPED_ASYNC } from "../components/extras/HttpHelper";

export interface IGetCepResponse {
	success: boolean;
	cep?: ICep;
	error?: string;
}

const getCep = async (cep: string): Promise<IGetCepResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<ICep>(`api/NCep/${cep}`);

		if (response && response.success) {
			return { success: response.success, cep: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	getCep
};