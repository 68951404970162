export interface IBelt {
	value?: string;
	text: string;
}

const belts: IBelt[] = [
	{ value: '', text: ' ' },
	{ value: 'Cinza', text: 'Cinza' },
	{ value: 'CinzaEBranca', text: 'Cinza e Branca' },
	{ value: 'CinzaEPreta', text: 'Cinza e Preta' },
	{ value: 'Amarela', text: 'Amarela' },
	{ value: 'AmarelaEBranca', text: 'Amarela e Branca' },
	{ value: 'AmarelaEPreta', text: 'Amarela e Preta' },
	{ value: 'Laranja', text: 'Laranja' },
	{ value: 'LaranjaEBranca', text: 'Laranja e Branca' },
	{ value: 'LaranjaEPreta', text: 'Laranja e Preta' },
	{ value: 'Verde', text: 'Verde' },
	{ value: 'VerdeEBranca', text: 'Verde e Branca' },
	{ value: 'VerdeEPreta', text: 'Verde e Preta' },
	{ value: 'Azul', text: 'Azul' },
	{ value: 'Branca', text: 'Branca' },
	{ value: 'Marrom', text: 'Marrom' },
	{ value: 'Preta', text: 'Preta' },
	{ value: 'Roxa', text: 'Roxa' },
	{ value: 'Vermelha', text: 'Vermelha' },
];

export default belts;
