import dayjs from 'dayjs';
import IAcademyFinancial from '../common/data/academy/academyFinancial';
import { DELETE_TYPED_ASYNC, GET_TYPED_ASYNC, POST_ASYNC, PUT_TYPED_ASYNC } from '../components/extras/HttpHelper';

interface ICreateStudentPayment {
	studentId: string;
	amount: number;
	paymentDate?: string;
	intentId?: string;
	paymentTypeId?: string;
	discountReason?: string;
	createdBy?: string;
	underReview?: boolean;
}

export interface IGetFinancialResults {
	success: boolean;
	academyFinancialResult?: IAcademyFinancial;
	error?: string;
}

interface GetPaymentResponse {
	success: boolean;
	payment?: IPayment;
	error?: string;
}
interface GetPaymentsResponse {
	success: boolean;
	payment?: IPayment[];
	error?: string;
}
const createStudentPayment = async ({
	studentId,
	amount,
	intentId,
	paymentDate = dayjs().format('YYYY-MM-DD'),
	paymentTypeId,
	discountReason,
	createdBy,
	underReview
}: ICreateStudentPayment) => {
	const request = {
		studentId,
		amount,
		intentId,
		paymentDate,
		paymentTypeId,
		discountReason,
		createdBy,
		underReview
	};

	try {
		return await POST_ASYNC('api/NPayment/Create', request);
	} catch (error: Error | any) {
		console.error(error);
		return { success: false, message: error.message };
	}
};

const getFinancialResults = async (): Promise<IGetFinancialResults> => {
	try {
		const response = await GET_TYPED_ASYNC<IAcademyFinancial>(
			'api/NFinancial/GetFinancialResults',
		);

		if (response && response.success) {
			return { success: response.success, academyFinancialResult: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const getUnderReviewPayments = async (): Promise<GetPaymentsResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IPayment[]>(
			'api/NPayment/GetUnderReviewPayments',
		);

		if (response && response.success) {
			return { success: response.success, payment: response.entity };
		} else {
			return { success: response.success, error: response.message };
		}
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const deleteStudentPayment = async (id: string): Promise<GetPaymentResponse> => {
	try {
		const response = await DELETE_TYPED_ASYNC<IPayment>(`api/NPayment/Delete/${id}`);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const updateStudentPayment = async (id: string, request: IPayment): Promise<GetPaymentResponse> => {
	try {
		const response = await PUT_TYPED_ASYNC<IPayment>(`api/NPayment/UpdateUnderReview/${id}`, request);
		return {
			success: response.success,
			...(!response.success
				? { error: response.message || response.exception }
				: response.entity),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	createStudentPayment,
	getFinancialResults,
	deleteStudentPayment,
	updateStudentPayment,
	getUnderReviewPayments
};
