export type PaymentTypeFilter = {
	nameFilter: string;
    descriptionFilter: string;
	statusFilter: boolean;
};

export const PAYMENT_TYPE_FILTER_INITIAL_VALUES: PaymentTypeFilter = {
	nameFilter: '',
	descriptionFilter: '',
	statusFilter: true
};