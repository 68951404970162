import { FC } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import CommonGraduationsList from "./common/CommonGraduationsList";

const BirthdaysReportPage: FC = () => {

    return (
        <PageWrapper title='Proximas Graduações'>
        <Page container='fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <CommonGraduationsList />
                </div>
            </div>
        </Page>
    </PageWrapper>
    );

};

export default BirthdaysReportPage;