import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import CommonAddonsList from "./common/CommonAddonsList";

const AddonsPage = () => {

	return (
		<PageWrapper title='Extensões do Sistema'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<CommonAddonsList />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AddonsPage;