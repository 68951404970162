import { GET_TYPED_ASYNC, POST_TYPED_ASYNC, PUT_TYPED_ASYNC } from "../components/extras/HttpHelper";

const BASE_PATH = 'SystemConfiguration';

export interface IGetConfigurationResponse {
	success: boolean;
	configuration?: IConfiguration;
	error?: string;
}

const getConfiguration = async (): Promise<IGetConfigurationResponse> => {
	try {
		const response = await GET_TYPED_ASYNC<IConfiguration>(`${BASE_PATH}/Get`);
		if (!response.success) {
			return { success: response.success, error: response.message || response.exception };
		}
		return { success: response.success, configuration: response.entity };
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

type NewConfigurationType = Omit<IConfiguration, 'id'>;

const addNewConfiguration = async (newConfiguration: NewConfigurationType): Promise<IGetConfigurationResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IConfiguration>(`${BASE_PATH}/Create`, newConfiguration);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const updateConfiguration = async (course: IConfiguration): Promise<IGetConfigurationResponse> => {
	try {
		const response = await PUT_TYPED_ASYNC<IConfiguration>(`${BASE_PATH}/Update`, course);
		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

const saveConfiguration = async (request: IConfiguration): Promise<IGetConfigurationResponse> => {
	try {
		const response = await POST_TYPED_ASYNC<IConfiguration>(`${BASE_PATH}/Save`, request);

		return {
			success: response.success,
			...(!response.success ? { error: response.message || response.exception } : null),
		};
	} catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
	getConfiguration,
    addNewConfiguration,
    updateConfiguration,
	saveConfiguration
};