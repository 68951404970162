import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useState } from 'react';
import AttendanceActionEnum from '../../../../common/data/enum/enumAttendanceAction';
import COLORS from '../../../../common/data/enumColors';
import Avatar from '../../../../components/Avatar';
import Badge from '../../../../components/bootstrap/Badge';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle
} from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Checks from '../../../../components/bootstrap/forms/Checks';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import Spinner from '../../../../components/bootstrap/Spinner';
import { PUT_ASYNC } from '../../../../components/extras/HttpHelper';
import { showError, showSuccess, showWarning } from '../../../../components/extras/Notifications';
import Icon from '../../../../components/icon/Icon';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../components/PaginationButtons';
import {
	DATETIME_FORMAT,
	utcToLocalWithFormat
} from '../../../../helpers/date';
import useSelectTable from '../../../../hooks/useSelectTable';
import useSortableData from '../../../../hooks/useSortableData';
import attendanceService from '../../../../services/attendance-service';
import CommonBadgeStatus from '../common/CommonBadgeStatus';
import { AttendanceFilter } from './AttendanceFilter';

interface Props {
	attendances: IAttendance[];
	activeStudents: IStudent[];
	date: Date;
	onFilterChange: (newDate: Date) => void;
	onAttendancesChange: () => void;
}

export const AttendanceCard = ({ attendances, activeStudents, date, onFilterChange, onAttendancesChange }: Props) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [attendancesToCreate, setAttendancesToCreate] = useState<any[]>([]);

	const [student, setStudent] = useState<IStudent>();

	const { items, requestSort, getClassNamesFor } = useSortableData(attendances);

	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);

	const [userAttendanceOffcanvas, setUserAttendanceOffcanvas] = useState(false);

	const formik = useFormik({

		initialValues: {
			date: '',
			time: '',
			userId: '',
		},

		validate: (values) => {
			const errors: {
				date?: string;
				time?: string;
				userId?: string;
			} = {};

			if (!values.date)
				errors.date = 'Campo obrigatório';

			if (!values.time)
				errors.time = 'Campo obrigatório';

			if (!values.userId)
				errors.userId = 'Campo obrigatório';

			return errors;
		},

		onSubmit: (values) => {
			addAttendanceToCreate(values);
		},

	});

	const resetForm = () => {
		formik.resetForm({
			values: {
				date: '',
				time: '',
				userId: '',
			},
		})
	}

	const resetFormAfterAdd = () => {
		formik.setFieldValue('date', '');
		formik.setFieldValue('time', '');
	}

	const approveDisapproveAttendance = async (action: AttendanceActionEnum) => {
		const request = {
			attendanceIds: selectTable.values.selectedList,
			action: action,
		};

		// setIsLoading(true);

		const response = await PUT_ASYNC(`api/NAttendance/Maintenance`, request);

		// setIsLoading(false);

		if (response && response.success) {
			onAttendancesChange();
			showSuccess('Manutenção realizada com sucesso');

			selectTable.values.selectedList = [];
		} else {
			showWarning(response.message);
		}
	};

	const createAttendances = async () => {

		const request = attendancesToCreate.map((attendance) => ({
			userId: attendance.userId,
			date: attendance.date,
			comment: attendance.comment,
		}));
		
		setIsSubmitting(true);

		const response = await attendanceService.addNewAttendances(request);

		setIsSubmitting(false);

		if (response && response.success) {
			resetForm();
			setUserAttendanceOffcanvas(false);
			onAttendancesChange();

			showSuccess('Registros inseridos com sucesso');
		} else {
			showError('Falha ao inserir os registros');
		}
	};

	const handleUserAttendanceOffcanvas = () => {
		setUserAttendanceOffcanvas(!userAttendanceOffcanvas);
		resetForm();
		setStudent(undefined);
		setAttendancesToCreate([]);
	};

	const handleChangeStudent = (id: string) => {
		const selectedStudent = activeStudents.find((student) => student.id === id);
		if (selectedStudent) setStudent(selectedStudent);
		else setStudent(undefined);
	};

	const addAttendanceToCreate = async (values: any) => {
		const dateTimeString = `${values.date} ${values.time}`;
		const utcDate = dayjs(dateTimeString).utc();

		const attendance = {
			key: dayjs(values.date).unix(),
			date: utcDate.format(),
			userId: values.userId,
			comment: 'Check-in feito por admin',
			user: {
				profileImageUrl: student?.profileImageUrl,
				fullName: student?.fullName,
			},
		};

		const foundAttendance = attendancesToCreate.find((p) => p.key === attendance.key);
		if (foundAttendance) {
			showWarning('Combinação de check-in já informada para o aluno');
			return;
		}

		const response = await attendanceService.getUserAttendancesByDate(attendance.date, attendance.userId);

		if (response && response.success) {
			if (response.attendance == null) {
				setAttendancesToCreate([...attendancesToCreate, attendance]);
				resetFormAfterAdd();
			} else {
				showWarning(
					`Falha ao realizar check-in. Check-in já realizado no dia ${dayjs(formik.values.date).format('DD/MM/YYYY')}`,
				);
			}
		} else {
			showError(response.error || 'Falha ao recuperar o check-in');
		}
	};

	const removeAttendanceToCreate = (attendance: any) => {
		const updatedAttendances = attendancesToCreate.filter((p) => p.key !== attendance.key);

		setAttendancesToCreate(updatedAttendances);
	};

	return (
		<>
			<Card style={{ minHeight: 400 }}>
				<CardHeader borderSize={1}>
					<CardLabel icon='TouchApp' iconColor='primary'>
						<CardTitle tag='div' className='h5'>
							Manutenção de Check-in
						</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='success'
							icon='AddCircle'
							isLight
							onClick={handleUserAttendanceOffcanvas}>
							Adicionar
						</Button>

						<ButtonGroup className='gap-2'>
							<Button
								color='info'
								icon='CheckCircle'
								isLight
								onClick={() =>
									approveDisapproveAttendance(AttendanceActionEnum.Approve)
								}>
								Aprovar
							</Button>

							<Button
								color='info'
								icon='Cancel'
								isLight
								onClick={() =>
									approveDisapproveAttendance(AttendanceActionEnum.Disapprove)
								}>
								Reprovar
							</Button>
						</ButtonGroup>

						<AttendanceFilter date={date} onFilterChange={onFilterChange} />
					</CardActions>
				</CardHeader>

				<CardBody className='table-responsive'>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								<th scope='col'>{SelectAllCheck}</th>
								<td style={{ width: 60 }}></td>
								<th
									onClick={() => requestSort('fullName')}
									className='cursor-pointer text-decoration-underline'>
									Nome{' '}
									<Icon
										size='lg'
										className={getClassNamesFor('fullName')}
										icon='FilterList'
									/>
								</th>
								<th>Data/Hora</th>
								<th>Situação</th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(items, currentPage, perPage).map((i, index) => (
								<tr key={index}>
									<th scope='row' aria-label='Check'>
										<Checks
											id={i.id}
											onChange={selectTable.handleChange}
											name={'selectedList'}
											value={i.id}
											checked={selectTable.values.selectedList.includes(
												// @ts-ignore
												i.id.toString(),
											)}
											ariaLabel={'selectedList'}
										/>
									</th>

									<td style={{ width: 60 }}>
										<div className='d-flex align-items-center'>
											<div className='flex-shrink-0'>
												<Avatar
													src={i.user.profileImageUrl}
													srcSet={i.user.profileImageUrl}
													size={54}
													userName={i.user.username}
													color={COLORS.PRIMARY.name}
												/>
											</div>
										</div>
									</td>

									<td>{i.user.fullName}</td>

									<td>{utcToLocalWithFormat(i.date, DATETIME_FORMAT)}</td>

									<td>
										<CommonBadgeStatus
											status={i.status}
											lastUpdate={i.lastUpdate}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
				<PaginationButtons
					data={attendances}
					label='check-in'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>

			<Modal
				setIsOpen={setUserAttendanceOffcanvas}
				isOpen={userAttendanceOffcanvas}
				titleId='userAttendance'
				isCentered
				isScrollable
				isStaticBackdrop={true}
				size={'lg'}>
				<ModalHeader setIsOpen={setUserAttendanceOffcanvas}>
					<OffCanvasTitle id='userAttendance'>{'Adicionar Check-in'}</OffCanvasTitle>
				</ModalHeader>
				<ModalBody>
					<Card>
						<CardBody className='table-responsive'>
							{student && (
								<div className='row g-4'>
									<div className='d-flex align-items-center justify-content-center'>
										<div className='flex-shrink-0'>
											<Avatar
												src={student.profileImageUrl}
												srcSet={student.profileImageUrl}
												color='primary'
												className='rounded-circle'
												shadow='sm'
												size={80}
											/>
										</div>
									</div>
								</div>
							)}

							<br />

							<div className='row g-4'>
								<div className='col-12'>
									<FormGroup label='Aluno' isFloating>
										<Select
											id='userId'
											ariaLabel='Escolha um aluno'
											placeholder='Escolha um aluno'
											onChange={(e: { target: { value: any } }) => {
												formik.handleChange(e);

												if (e.target.value) {
													handleChangeStudent(e.target.value);
												}
											}}
											onBlur={formik.handleBlur}
											value={formik.values.userId}
											isValid={formik.isValid}
											isTouched={formik.touched.userId}
											disabled={
												attendancesToCreate &&
												attendancesToCreate.length > 0
											}
											list={activeStudents.map((student) => ({
												value: student.id,
												text: `${student.fullName}`,
												label: `${student.fullName}`,
											}))}
											invalidFeedback={
												formik.errors.userId
											}
											validFeedback=''
										/>
									</FormGroup>
								</div>

								<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
									<FormGroup id='date' label='Data de Check-in' isFloating>
										<Input

											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.date}
											isValid={formik.isValid}
											isTouched={formik.touched.date}
											invalidFeedback={
												formik.errors.date
											}
											validFeedback=''
											type='date'
										/>
									</FormGroup>
								</div>

								<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
									<FormGroup id='time' label='Hora de Check-in' isFloating>
										<Input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.time}
											isValid={formik.isValid}
											isTouched={formik.touched.time}
											invalidFeedback={
												formik.errors.time
											}
											validFeedback=''
											type='time'
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>
						<CardFooter className='d-flex'>
							<CardFooterRight>
								<Button
									color='success'
									icon='AddCircle'
									isLight
									onClick={formik.handleSubmit}>
									Adicionar
								</Button>
							</CardFooterRight>
						</CardFooter>

					</Card>

					<Card style={{ minHeight: 200 }}>
						<CardBody className='table-responsive'>
							<table className='table table-modern table-hover'>
								<thead>
									<tr>
										<th>Aluno</th>
										<th>Data de Check-in</th>
										<th>Situação</th>
										<td aria-labelledby='Actions' />
									</tr>
								</thead>
								<tbody>
									{attendancesToCreate.map((i, index) => (
										<tr key={index}>
											<td>{i.user.fullName}</td>

											<td>{dayjs(i.date).format('DD/MM/YYYY HH:mm:ss')}</td>

											<td>
												<h5>
													<Badge
														color='info'
														className='align-items-center justify-content-center'>
														Aguardando
													</Badge>
												</h5>
											</td>

											<td className='text-end'>
												<Dropdown>
													<DropdownToggle hasIcon={false}>
														<Button
															icon='MoreHoriz'
															color='dark'
															isLight
															shadow='sm'
															aria-label='More actions'
														/>
													</DropdownToggle>
													<DropdownMenu isAlignmentEnd>
														<DropdownItem>
															<Button
																isOutline={true}
																color='dark'
																isLight={true}
																className={classNames(
																	'text-nowrap',
																	{
																		'border-light': true,
																	},
																)}
																icon='Visibility'
																tag='a'
																onClick={() =>
																	removeAttendanceToCreate(i)
																}>
																Remover
															</Button>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</CardBody>
					</Card>
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<Button
						color='primary'
						className='w-100'
						onClick={createAttendances}
						isDisable={attendancesToCreate.length <= 0 || isSubmitting}>
						{isSubmitting && <Spinner isSmall inButton isGrow />}
						Salvar Check-in
					</Button>
				</ModalFooter>
			</Modal >
		</>
	);
};
