import { useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';

interface Props {
	item: ICourse;
	handleDeleteCourseClick: (id: string) => void;
	handleCourseDetailClick: (course: ICourse) => void;
}

export const CourseActionsDropdown = ({
	item,
	handleDeleteCourseClick,
	handleCourseDetailClick,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = (value: boolean | ((prevState: boolean) => boolean) | null) => {
		if (typeof value === 'boolean') {
			setIsOpen(value);
		}
	};

	const onDeleteClick = (id: string) => {
		toggleDropdown(false);
		handleDeleteCourseClick(id);
	};

	const onDetailClick = (course: ICourse) => {
		toggleDropdown(false);
		handleCourseDetailClick(course);
	};

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<Button
					icon='MoreHoriz'
					color='dark'
					isLight
					shadow='sm'
					aria-label='More actions'
				/>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd isOpen={isOpen} setIsOpen={toggleDropdown}>
				<DropdownItem>
					<Button
						color='dark'
						isLight
						icon='Edit'
						onClick={() => onDetailClick(item)}>
						Alterar
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						color='dark'
						isLight
						icon='Delete'
						onClick={() => onDeleteClick(item.id)}>
						Remover
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};
