import { ICashFlowAccount } from "../common/data/financial/cashFlowAccount";
import { GET_TYPED_ASYNC } from "../components/extras/HttpHelper";

export interface IGetCashFlowAccountResponse {
    success: boolean;
	cashFlowAccount?: ICashFlowAccount;
	cashFlowAccounts?: ICashFlowAccount[];
	error?: string;
};

const getActiveCashFlowAccount = async (): Promise<IGetCashFlowAccountResponse> => {

    try {

		const response = await GET_TYPED_ASYNC<ICashFlowAccount>('api/NCashFlowAccount/GetActive');
		
		if (response && response.success) {
			return { success: response.success, cashFlowAccounts: response.collection };
		} else {
			return { success: response.success, error: response.message };
		}
    } catch (err: any) {
		console.error(err);
		return { success: false, error: err.message };
	}
};

export default {
    getActiveCashFlowAccount
};