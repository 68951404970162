import { FormikErrors, FormikProps, FormikValues, useFormik } from 'formik';
import Button from '../bootstrap/Button';

interface Props<T extends FormikValues> {
	initialValues: T;
	onSubmit: (values: T) => void;
	children: (formik: FormikProps<T>) => React.ReactNode;
	onValidate?: (values: T) => FormikErrors<T>;
}

export const SaveForm = <T extends FormikValues>({
	initialValues,
	onSubmit,
	onValidate,
	children,
}: Props<T>) => {
	const formik = useFormik<T>({
		initialValues,
		onSubmit,
		validate: onValidate,
		enableReinitialize: true,
	});

	return (
		<form className='row g-3' onSubmit={formik.handleSubmit}>
			{children(formik)}
			<div className='col-12'>
				<Button color='primary' className='w-100' type='submit' aria-hidden='true'>
					Salvar
				</Button>
			</div>
		</form>
	);
};
