import dayjs from 'dayjs';
import Icon from '../../../components/icon/Icon';
import { getCurrentMonthName } from '../../../helpers/date';
import { priceFormat } from '../../../helpers/helpers';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Card, { CardBody } from '../../../components/bootstrap/Card';

interface Props {
	description: string;
	fee: number;
	isSubscription?: boolean;
}

const subscriptionDiv = (description: string, fee: number, isSubscription: boolean) => {
	return (
		
		
			<div className='row pt-4 g-4 text-center'>
		
		<div className='col-12'>
		<Icon icon={'SportsKabaddi'} size='4x' color={'primary'} />
	</div>
	<div className='col-12'>
		<h3>{description}</h3>
	</div>
	{ isSubscription && (
				<div className='col-12'>
				<h6>
					<span className='display-6 fw-bold'>{priceFormat(fee || 0)}</span>
				</h6>
			</div>
	) || ( 
		<FormGroup>
		<div className='col-12'>
		<InputGroup size='lg'>
	<InputGroupText>
		R$
	</InputGroupText>
	<Input type='text' />
	
</InputGroup>
			{/* <span className='display-6 fw-bold'>{priceFormat(fee || 0)}</span> */}
		{/* </h6> */}
	</div>
	</FormGroup>
	)}
	
	</div>
)};



const SubscriptionDetail = ({ description, fee, isSubscription = false}: Props) => {
	return (
		<>
	

	{subscriptionDiv(description, fee, isSubscription)}

	{/* <div className='row pt-4 g-4 text-center'>
			<div className='col-12'>
				<Icon icon={'SportsKabaddi'} size='4x' color={'primary'} />
			</div>
			<div className='col-12'>
				<h3>{description}</h3>
			</div>
			<div className='col-12'>
				<h6>
					<span className='display-6 fw-bold'>{priceFormat(fee || 0)}</span>
				</h6>
			</div>


					<div className='col-12'>
						<h3>{process.env.REACT_APP_SITE_NAME}</h3>
					</div>
					<div className='col-12 mb-4'>
						<h4>
							{getCurrentMonthName()}/{dayjs().year()}
						</h4>
					</div>

			
		</div> */}

			
		</>
	);
};

export default SubscriptionDetail;
