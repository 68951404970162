import { RouteProps } from 'react-router-dom';
import {
	appMenu,
	authMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	hiddenMenu,
	pageLayoutTypesPagesMenu,
	userMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	//Defaults
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: `*`,
		element: <DefaultHeader />,
	},

	//Register
	// { path: appMenu.registers.subMenu.leadsAndProspects.path, element: <DashboardHeader /> },
	{ path: appMenu.registers.subMenu.subscriptions.path, element: <DashboardHeader /> },
	// { path: appMenu.registers.subMenu.students.path, element: <DashboardHeader /> },
	// { path: appMenu.registers.subMenu.classes.path, element: <DashboardHeader /> },
	{ path: appMenu.registers.subMenu.classCheckin.path, element: <DashboardHeader /> },
	// { path: appMenu.registers.subMenu.classCheckinList.path, element: <DashboardHeader /> },
	{ path: appMenu.registers.subMenu.schedule.path, element: <DashboardHeader /> },
	// { path: appMenu.registers.subMenu.message.path, element: <DashboardHeader /> },
	// { path: appMenu.registers.subMenu.instructors.path, element: <DashboardHeader /> },
	{ path: appMenu.registers.subMenu.students.path, element: <DashboardHeader /> },
	{ path: appMenu.registers.subMenu.courses.path, element: <DashboardHeader /> },

	//Management
	{ path: appMenu.management.subMenu.attendanceMaintenance.path, element: <DashboardHeader /> },
	{ path: appMenu.management.subMenu.paymentMaintenance.path, element: <DashboardHeader /> },
	// { path: appMenu.management.subMenu.classEvaluation.path, element: <DashboardHeader /> },
	// { path: appMenu.management.subMenu.message.path, element: <DashboardHeader /> },
	// { path: appMenu.management.subMenu.digitalMedia.path, element: <DashboardHeader /> },

	//Financial Control
	{ path: appMenu.financialControl.subMenu.monthlyPayments.path, element: <DashboardHeader /> },
	{ path: appMenu.financialControl.subMenu.cashFlowAccount.path, element: <DashboardHeader /> },
	{ path: appMenu.financialControl.subMenu.cashFlow.path, element: <DashboardHeader /> },
	{ path: appMenu.financialControl.subMenu.cashFlowAccountGroup.path, element: <DashboardHeader /> },
	{ path: appMenu.financialControl.subMenu.paymentType.path, element: <DashboardHeader /> },
	// { path: appMenu.financialControl.subMenu.billingPeriod.path, element: <DashboardHeader /> },

	//Reports
	{ path: appMenu.reports.subMenu.upgcomingGraduations.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.birthdays.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.studentHistory.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.frequency.path, element: <DashboardHeader /> },
	// { path: appMenu.reports.subMenu.graduations.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.absences.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.noSubscription.path, element: <DashboardHeader /> },
	// { path: appMenu.reports.subMenu.teachers.path, element: <DashboardHeader /> },
	// { path: appMenu.reports.subMenu.leadsAndProspects.path, element: <DashboardHeader /> },
	{ path: appMenu.reports.subMenu.cashFlowEntries.path, element: <DashboardHeader /> },

	//Settings
	{ path: appMenu.settings.subMenu.addon.path, element: <DashboardHeader /> },
	{ path: appMenu.settings.subMenu.settings.path, element: <DashboardHeader /> },
	// { path: appMenu.settings.subMenu.graduationTime.path, element: <DashboardHeader /> },
	// { path: appMenu.settings.subMenu.socialMidia.path, element: <DashboardHeader /> },

	//Clean
	{ path: hiddenMenu.financial.path, element: <DashboardHeader /> },
	{ path: hiddenMenu.students.path, element: <DashboardHeader /> },
	{ path: hiddenMenu.instructors.path, element: <DashboardHeader /> },
	// { path: hiddenMenu.classCheckinItem.path, element: <DashboardHeader /> },
	{ path: hiddenMenu.classCheckinDetail.path, element: <DashboardHeader /> },
	{ path: userMenu.schedule.path, element: <DashboardHeader /> },
	{ path: userMenu.payments.path, element: <DashboardHeader /> },
	{ path: hiddenMenu.payment.path, element: <DashboardHeader /> },

	{ path: authMenu.login.path, element: null },
	{ path: authMenu.loginV2.path, element: null },
	{ path: hiddenMenu.facialCheckin.path, element: null },
];

export default headers;
