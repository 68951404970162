import dayjs from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { getFilterMonth } from "../../../../common/data/calendar";
import CashFlowAccountTypeEnum from "../../../../common/data/enum/enumCashFlowAccountType";
import CashFlowEntryStatusEnum from "../../../../common/data/enum/enumCashFlowEntryStatus";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../../components/PaginationButtons";
import Badge from "../../../../components/bootstrap/Badge";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Label from "../../../../components/bootstrap/forms/Label";
import Select from "../../../../components/bootstrap/forms/Select";
import { GET_ASYNC } from "../../../../components/extras/HttpHelper";
import { showWarning } from "../../../../components/extras/Notifications";
import { priceFormat } from "../../../../helpers/helpers";
import useDarkMode from "../../../../hooks/useDarkMode";
import LoadingPage from "../../loading/LoadingPage";

const CommonCashFlowEntryList: FC = () => {

    const { darkModeStatus } = useDarkMode();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [filterMenu, setFilterMenu] = useState<boolean>(false);

    const [cashFlowAccountEntries, setCashFlowAccountEntries] = useState([]);

    const filterMonth = getFilterMonth();

    const formikFilter = useFormik({
        initialValues: {
            monthFilter: '',
            yearFilter: ''
        },

        onSubmit<Values>(
            values: Values,
            formikHelpers: FormikHelpers<Values>,
        ): void | Promise<any> {
            getCashFlowAccountEntries();

            setFilterMenu(false);
        }
    });

    useEffect(() => {
        getCurrentDate();

        getCashFlowAccountEntries();

    }, []);

    const getCashFlowAccountEntries = async () => {

        setIsLoading(true);

        const response = await GET_ASYNC(`NReport/GetCashFlowAccountEntries/${formikFilter.values.monthFilter}/${formikFilter.values.yearFilter}`);

        setIsLoading(false);

        if(response && response.success) {
            setCashFlowAccountEntries(response.collection);
        } else {
            showWarning(response.message);
        }
    }

    const getCurrentDate = () => {
        const currentMonth = new Date().getMonth() + 1;
        formikFilter.values.monthFilter = currentMonth.toString();
        formikFilter.values.yearFilter = new Date().getFullYear().toString();
    }

    return (
        <>
            {
                isLoading ? (

                    <LoadingPage />

                ) : (
                    <>

                        <Card style={{ minHeight: 400 }}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='MonetizationOn' iconColor='primary'>
                                    <CardTitle tag='div' className='h5'>
                                        Fluxo de Caixa
                                    </CardTitle>
                                </CardLabel>
                                <CardActions>
                                    
                                {/* <ExportButton headers={headers} data={birthdays} filename="aniversariantes" /> */}

                                    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu} isButtonGroup>
                                        <DropdownToggle hasIcon={false}>
                                            <Button isLink={true} icon='FilterAlt' color='primary'>
                                                Filtrar
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <div className='container py-2'>
                                                <form className='row g-3' onSubmit={formikFilter.handleSubmit}>

                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <Label htmlFor='monthFilter'>Mês</Label>
                                                            <Select
                                                                id='monthFilter'
                                                                ariaLabel='Mês'
                                                                placeholder='Mês'
                                                                list={filterMonth}
                                                                onChange={formikFilter.handleChange}
                                                                value={formikFilter.values.monthFilter}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <FormGroup id='yearFilter' label='Ano'>
                                                        <Input
                                                            onChange={formikFilter.handleChange}
                                                            value={formikFilter.values.yearFilter}
                                                        />
                                                    </FormGroup>

                                                    <div className='col-6'>
                                                        <Button
                                                            color='primary'
                                                            isOutline
                                                            className='w-100'
                                                            onClick={formikFilter.resetForm}>
                                                            Limpar
                                                        </Button>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Button color='primary' className='w-100' type='submit'>
                                                            Aplicar
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>

                                <table className='table table-modern'>
                                    <tbody>

                                        {dataPagination(cashFlowAccountEntries, currentPage, perPage).map((item) => (
                                            <React.Fragment key={item.id}>
                                                <tr>
                                                    <td colSpan={5} className="table-active">{item.account}</td>
                                                    <td className="table-active">
                                                        {item.accountType === CashFlowAccountTypeEnum.Revenue ? (
                                                            <h5>
                                                                <Badge color='success' className='align-items-center justify-content-center'>
                                                                    Receita
                                                                </Badge>
                                                            </h5>
                                                        ) : (
                                                            <h5>
                                                                <Badge color='danger' className='align-items-center justify-content-center'>
                                                                    Despesa
                                                                </Badge>
                                                            </h5>
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={5}>
                                                        <table className='table mb-0'>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Data</th>
                                                                    <th>Valor</th>
                                                                    <th>Observação</th>
                                                                    <th>Situação</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {item.cashFlowEntries.map((entry: any) => (
                                                                    <tr>
                                                                        <td></td>
                                                                        <td>{dayjs(entry.date).format('DD/MM/YYYY')}</td>
                                                                        <td>{priceFormat(entry.value)}</td>
                                                                        <td>{entry.observation}</td>
                                                                        <td>
                                                                            {entry?.paid === CashFlowEntryStatusEnum.Paid ? (
                                                                                <h5>
                                                                                    <Badge color='success' className='align-items-center justify-content-center'>
                                                                                        Pago
                                                                                    </Badge>
                                                                                </h5>
                                                                            ) : (
                                                                                <h5>
                                                                                    <Badge color='danger' className='align-items-center justify-content-center'>
                                                                                        Em Aberto
                                                                                    </Badge>
                                                                                </h5>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>




                                            </React.Fragment>
                                        ))}


                                    </tbody>
                                </table>


                            </CardBody>
                            <PaginationButtons
                                data={cashFlowAccountEntries}
                                label='itens'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </Card>

                    </>
                )
            }
        </>
    );
};

export default CommonCashFlowEntryList;