import dayjs from "dayjs";

interface IValues {
    fullName: string;
    phone: string;
    birthDate: Date | string | undefined;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    observation: string;

}

const studentValidate = (values: IValues) => {

    const errors: IValues = {
        fullName: '',
        phone: '',
        birthDate: '',
        address: '',
        neighborhood: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        observation: '',

    };

    if (!values.fullName || values.fullName.trim() === '') {
        errors.fullName = 'Obrigatório';
    }

    if (!values.phone || values.phone.trim() === '') {
        errors.phone = 'Obrigatório';
    }

    if (!values.address || values.address.trim() === '') {
        errors.address = 'Obrigatório';
    }

    if (!values.city || values.city.trim() === '') {
        errors.city = 'Obrigatório';
    }

    if (!values.state || values.state.trim() === '') {
        errors.state = 'Obrigatório';
    }

    if(!values.birthDate) {
        errors.birthDate = 'Obrigatório'
    } else {
        const inputBirthDate = dayjs(values.birthDate).startOf('date');

        if(inputBirthDate.isAfter(dayjs().startOf('date')) || inputBirthDate.isSame(dayjs().startOf('date'))) {
            errors.birthDate = 'Data inválida'
        }

    }

    return errors;
};

export default studentValidate;