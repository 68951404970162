import { useEffect, useRef, useState } from 'react';
import belts from '../../../common/data/beltData';
import Button from '../../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Checks from '../../../components/bootstrap/forms/Checks';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import { FilterForm } from '../../../components/common/FilterForm';
import { STUDENTS_FILTER_INITIAL_VALUES } from './consts';
import { FilterFormValues } from './types';

interface Props {
	subscriptionTypes: ISubscriptionType[];
	onFilterChange: (values: FilterFormValues) => void;
}

export const StudentsFilter = ({ subscriptionTypes, onFilterChange }: Props) => {
	const nameFilterRef = useRef<HTMLInputElement>(null);

	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [filterValues, setFilterValues] = useState<FilterFormValues>(
		STUDENTS_FILTER_INITIAL_VALUES,
	);

	const onFormSubmit = (values: FilterFormValues): void | Promise<any> => {
		onFilterChange(values);
		setFilterValues(values);
		setIsFilterOpened(false);
	};

	const onFormClear = () => {
		setFilterValues(STUDENTS_FILTER_INITIAL_VALUES);
	};

	// Adicionei isso pra colocar o foco quando o filtro abrir
	useEffect(() => {
		if (isFilterOpened && nameFilterRef.current) {
			nameFilterRef.current.focus();
		}
	}, [isFilterOpened]);

	const mapSubscriptionTypesWithAnEmptyRow = () => {
		return [
			{ value: '', text: ' ' },
			...subscriptionTypes.map((s) => ({
				value: s.id,
				text: s.description,
			})),
		];
	};

	return (
		<Dropdown isOpen={isFilterOpened} setIsOpen={setIsFilterOpened} isButtonGroup>
			<DropdownToggle hasIcon={false}>
				<Button isLink={true} icon='FilterAlt' color='primary'>
					Filtrar
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd size='xl' isCloseAfterLeave={false}>
				<FilterForm<FilterFormValues>
					initialValues={filterValues}
					onSubmit={onFormSubmit}
					onClear={onFormClear}>
					{(filterForm) => (
						<>
							<FormGroup id='nameFilter' label='Nome'>
								<Input
									ref={nameFilterRef}
									onChange={filterForm.handleChange}
									value={filterForm.values.nameFilter}
								/>
							</FormGroup>

							<FormGroup id='subscriptionFilter' label='Matrícula'>
								<Select
									id='subscription-filter'
									list={mapSubscriptionTypesWithAnEmptyRow()}
									onChange={filterForm.handleChange}
									value={filterForm.values.subscriptionFilter}
									ariaLabel={'subscription-filter'}
									placeholder='Selectione uma matrícula'
								/>
							</FormGroup>

							<FormGroup id='beltFilter' label='Faixa'>
								<Select
									id='belt-filter'
									list={belts}
									onChange={filterForm.handleChange}
									value={filterForm.values.beltFilter}
									ariaLabel={'belt-filter'}
									placeholder='Selecione uma faixa'
								/>
							</FormGroup>
							<FormGroup id='statusFilter'>
								<Checks
									id="status-filter"
									label="Alunos ativos"
									name="statusFilter"
									onChange={filterForm.handleChange}
									type="switch"
									checked={filterForm.values.statusFilter}
								/>
							</FormGroup>

						</>
					)}
				</FilterForm>
			</DropdownMenu>
		</Dropdown>
	);
};
