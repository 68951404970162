import axios from 'axios';

export interface HttpTypedApiResponse<T> {
	success: boolean;
	message: string;
	exception: string;
	collection?: T[];
	entity?: T;
}

const baseUrl = process.env.REACT_APP_GB_API_URL;

const defaultResponse = {
	success: false,
	entity: null,
	collection: [],
	message: 'Falha ao processar requisição',
};

export const GET_TYPED_ASYNC = async <T extends any>(
	url: string,
): Promise<HttpTypedApiResponse<T>> => {
	return await GET_ASYNC(url);
};

export const POST_TYPED_ASYNC = async <T extends any>(
	url: string,
	request: any,
): Promise<HttpTypedApiResponse<T>> => {
	return await POST_ASYNC(url, request);
};

export const PUT_TYPED_ASYNC = async <T extends any>(
	url: string,
	request: any,
): Promise<HttpTypedApiResponse<T>> => {
	return await PUT_ASYNC(url, request);
};

export const DELETE_TYPED_ASYNC = async <T extends any>(
	url: string,
): Promise<HttpTypedApiResponse<T>> => {
	return await DELETE_ASYNC(url);
};

export const GET_ASYNC = async (url: string) => {
	try {
	 const token = sessionStorage.getItem('jwt_accesstoken') === null ?
            localStorage.getItem('jwt_accesstoken') : sessionStorage.getItem('jwt_accesstoken');

		const response = await axios.get(`${baseUrl}${url}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data || defaultResponse;
	} catch (error: any) {
		return defaultResponse;
	}
};

export const POST_ASYNC = async (url: string, request: any) => {
	try {
 const token = sessionStorage.getItem('jwt_accesstoken') === null ?
            localStorage.getItem('jwt_accesstoken') : sessionStorage.getItem('jwt_accesstoken');


		const response = await axios.post(`${baseUrl}${url}`, request, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data || defaultResponse;
	} catch (error: any) {
		return defaultResponse;
	}
};

export const PUT_ASYNC = async (url: string, request: any) => {
	try {
 const token = sessionStorage.getItem('jwt_accesstoken') === null ?
            localStorage.getItem('jwt_accesstoken') : sessionStorage.getItem('jwt_accesstoken');

		const response = await axios.put(`${baseUrl}${url}`, request, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data || defaultResponse;
	} catch (error: any) {
		return defaultResponse;
	}
};

export const DELETE_ASYNC = async (url: string, request?: any) => {
	try {
 const token = sessionStorage.getItem('jwt_accesstoken') === null ?
            localStorage.getItem('jwt_accesstoken') : sessionStorage.getItem('jwt_accesstoken');

		const response = await axios.delete(`${baseUrl}${url}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data || defaultResponse;
	} catch (error: any) {
		return defaultResponse;
	}
};
