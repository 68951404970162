import * as QRCode from 'qrcode';
import { Validators } from './Validators';

class PixQrCodeGenerator {
  private key: string;
  private merchantName: string;
  private city: string;
  private amount: number;
  private description: string;
  private validatedKey: string | boolean | null = null;
  private error: string | null = null;

  constructor(key: string, merchantName: string, city: string, amount: number, description: string) {
    this.key = key;
    this.merchantName = merchantName;
    this.city = city;
    this.amount = amount;
    this.description = description;
  }

  private sanitizeText(text: string): string {
    // Replace accented characters with non-accented versions
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public determineKeyType(): void {
    try {
      if (Validators.EMAIL_REGEX.test(this.key)) {
        this.validatedKey = Validators.isEmail(this.key);
      } else if (this.key.replace(/\D/g, '').length === Validators.CPF_LENGTH) {
        if (Validators.isCPF(this.key)) {
          this.validatedKey = Validators.isCPF(this.key) as string;
        } else if (
          this.key.replace(/\D/g, '').length === Validators.VALID_PHONE_LENGTH ||
          this.key.replace(/\D/g, '').length === Validators.VALID_PHONE_WITH_COUNTRY_CODE_LENGTH
        ) {
          this.validatedKey = Validators.isPhone(this.key);
        } else {
          throw new Error('The key type is invalid');
        }
      } else if (this.key.replace(/\D/g, '').length === Validators.CNPJ_LENGTH) {
        this.validatedKey = Validators.isCNPJ(this.key);
      } else if (this.key.length === Validators.RANDOM_KEY_LENGTH) {
        this.validatedKey = Validators.isRandomKey(this.key);
      } else {
        throw new Error('The key type is invalid');
      }
      this.error = null; // Clear any existing errors
    } catch (err) {
      this.error = (err as Error).message;
    }
  };

  public generatePixCode(): string {
    this.determineKeyType();
    // Ensure amount has two decimal places
    const formattedAmount = this.amount.toFixed(2);
    const sanitizedMerchantName = this.sanitizeText(this.merchantName);
    const sanitizedCity = this.sanitizeText(this.city);
    const count_key = this.validatedKey?.toString().length;
    const chave = `01${this.validatedKey?.toString().length.toString().padStart(2, '0')}${this.validatedKey}`;
    const gui = '0014BR.GOV.BCB.PIX';
    // Construct payload with corrected, padded lengths
    const payload =
      `000201` +  // Payload Format Indicator
      `26${(gui.length + chave.length).toString().padStart(2, '0')}${gui}` +  // Corrected length for Merchant Account Information
      // `${gui}` +  // Merchant Account Information domain
      `01${count_key}${this.validatedKey}` +  // PIX key without special characters
      `52040000` +  // Merchant Category Code
      `5303986` +  // Transaction Currency
      `54${formattedAmount.length.toString().padStart(2, '0')}${formattedAmount}` +  // Transaction Amount with length padding
      `5802BR` +  // Country Code
      `59${this.merchantName.length}${this.merchantName}` +  // Merchant Name, sanitized
      `60${this.city.length.toString().padStart(2, '0')}${this.city}` +  // Merchant City with length padding
      `62${this.description.length + 4}05${this.description.length}${this.description}` +  // Additional Data Field (description)
      `6304`;  // CRC placeholder

    const crc = this.calculateCRC16(payload);
    return payload + crc;
  }

  private calculateCRC16(data: string): string {
    const polynomial = 0x1021;
    let crc = 0xFFFF;

    for (let i = 0; i < data.length; i++) {
      crc ^= data.charCodeAt(i) << 8;
      for (let j = 0; j < 8; j++) {
        if (crc & 0x8000) {
          crc = (crc << 1) ^ polynomial;
        } else {
          crc <<= 1;
        }
        crc &= 0xFFFF;
      }
    }
    return crc.toString(16).toUpperCase().padStart(4, '0');
  }

  public async generateQrCodeDataUrl(): Promise<string> {
    const pixCode = this.generatePixCode();
    return await QRCode.toDataURL(pixCode);
  }
}

export default PixQrCodeGenerator;
