import { FormikProps, FormikValues, useFormik } from 'formik';
import Button from '../bootstrap/Button';

interface Props<T extends FormikValues> {
	initialValues: T;
	onSubmit: (values: T) => void;
	onClear: () => void;
	children: (formik: FormikProps<T>) => React.ReactNode;
}

export const FilterForm = <T extends FormikValues>({
	initialValues,
	onSubmit,
	onClear,
	children,
}: Props<T>) => {
	const formik = useFormik<T>({
		initialValues,
		onSubmit,
		enableReinitialize: true,
	});

	const handleClear = () => {
		formik.resetForm();
		onClear();
	};

	return (
		<div className='container py-2'>
			<form className='row g-3' onSubmit={formik.handleSubmit}>
				{children(formik)}
				<div className='col-6'>
					<Button color='primary' isOutline className='w-100' onClick={handleClear}>
						Limpar
					</Button>
				</div>
				<div className='col-6'>
					<Button color='primary' className='w-100' type='submit'>
						Aplicar
					</Button>
				</div>
			</form>
		</div>
	);
};
