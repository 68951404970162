import dayjs from 'dayjs';
import Card, { CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import { CardBodyTable } from '../../../components/table/CardBodyTable';
import { priceFormat } from '../../../helpers/helpers';
import useSortableData from '../../../hooks/useSortableData';
import CommonBadgeStatus from '../attendance/common/CommonBadgeStatus';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import { showSuccess, showWarning } from '../../../components/extras/Notifications';
import showNotification from '../../../components/extras/showNotification';
import financialService from '../../../services/financial-service';
import { useEffect, useState } from 'react';
import { getDecodedToken } from '../../../common/data/jwtPayload';

interface Props {
	payments: IPayment[];
	isCompact?: boolean;
	cardActions?: React.ReactNode;
}

const PaymentsGeneric = ({ payments, isCompact = true, cardActions = null }: Props) => {
	//TODO: Filter payments by type
	const { items: sortablePayments, requestSort, getClassNamesFor } = useSortableData(payments);
	const handleApproveSubmit = (payments: any ) => {
		financialService.updateStudentPayment(payments.id, payments as IPayment)
		.then((response) => {
			if (response.success) {
				showNotification(
					'Aprovar', // String, HTML or Component
					'Pagamento aprovado com sucesso', // String, HTML or Component
					'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
				window.location.reload();
			} else {
				showWarning(response?.error || 'Erro ao aprovar pagamento');
			}
		})
	
	};

	const [role, setRole] = useState<boolean>();

	const userData = getDecodedToken();

	useEffect(() => {
		setRole(userData['cognito:groups']?.includes("Professores" || "Administradores"));
	}, [userData]);

	
	const handleCancelSubmit = (payments: any ) => {

		financialService.deleteStudentPayment(payments.id)
		.then((response) => {
			if (response.success) {
				showNotification(
					'Cancelar', // String, HTML or Component
					'Pagamento cancelado com sucesso', // String, HTML or Component
					'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
				window.location.reload();
			} else {
				showWarning(response?.error || 'Erro ao cancelar pagamento');
			}
		})
	};


	return (
		<Card className='mb-4' stretch >
			<CardHeader {...(!isCompact && { borderSize: 1, className: 'mb-1' })}>
				<CardLabel icon='TouchApp'>
					<CardTitle tag='div' className='h5'>
						Pagamentos em Revisão
					</CardTitle>
				</CardLabel>
				{cardActions && cardActions}
			</CardHeader>
			<CardBodyTable
				tableClassNames='table-hover'
				data={sortablePayments}
				head={
					<tr>
						<th
							onClick={() => requestSort('paymentDate')}
							className='cursor-pointer text-decoration-underline'>
							Data{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('paymentDate')}
								icon='FilterList'
							/>
						</th>
						<th
							onClick={() => requestSort('amount')}
							className='cursor-pointer text-decoration-underline'>
							Valor{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('amount')}
								icon='FilterList'
							/>
						</th>
						<th
							onClick={() => requestSort('status')}
							className='cursor-pointer text-decoration-underline'>
							Status{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('status')}
								icon='FilterList'
							/>
						</th>
						<th
							onClick={() => requestSort('description')}
							className='cursor-pointer text-decoration-underline'>
							Descrição{' '}
							<Icon
								size='lg'
								className={getClassNamesFor('description')}
								icon='FilterList'
							/>
						</th>
						<th>Situação</th>
						<th></th>
					</tr>
				}
				body={(item, index) => (
					<tr key={index}>
						<td>{dayjs(item.paymentDate).format('DD/MM/YYYY')}</td>
						<td>{priceFormat(item.amount)}</td>
						<td>{'Aguardando Aprovação'}</td>
						<td>{'Foi enviado uma notificação para o administrador realizar a aprovação'}</td>
						<td>
                        	<CommonBadgeStatus status={!item.underReview} />
                        </td>
						{role && (
						<td className="text-end">
                            <Dropdown direction='up'>
                                <DropdownToggle  hasIcon={false}>
                                    <Button
                                        icon='MoreHoriz'
                                        color='dark'
                                        isLight
                                        shadow='sm'
                                        aria-label='Mais Ações'
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <Button
											id='approve'
                                            isOutline={false}
                                            color='dark'
                                            isLight={true}
                                            className={classNames('text-nowrap', {
                                                'border-light': !true,
                                            })}
                                            icon='CheckCircle'
                                            onClick={() => handleApproveSubmit(item)}>
                                            Aprovar
                                        </Button>
                                    </DropdownItem>
									<DropdownItem>
                                        <Button
											id='cancel'
                                            isOutline={false}
                                            color='dark'
                                            isLight={true}
                                            className={classNames('text-nowrap', {
                                                'border-light': !true,
                                            })}
                                            icon='Cancel'
                                            onClick={() => handleCancelSubmit(item)}>
                                            Reprovar
                                        </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </td>
						)}
					</tr>
				)}
			/>
		</Card>
	);
};

export default PaymentsGeneric;
