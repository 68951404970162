import Icon from "../icon/Icon";
import showNotification from "./showNotification";

export const showCreateSuccess = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Registro inserido com sucesso.',
        'success'
    );
};

export const showUpdateSuccess = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Registro modificado com sucesso.',
        'success'
    );
};

export const showDeleteSuccess = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Registro removido com sucesso.',
        'success'
    );
};

export const showInactivateSuccess = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Registro inativado com sucesso.',
        'success'
    );
};

export const showCreateError = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Error'
                size='lg'
                className='me-1'
            />
            <span>Erro</span>
        </span>,
        'Falha ao inserir o registro. Por favor, verifique os dados fornecidos.',
        'danger'
    );
};

export const showUpdateError = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Erro</span>
        </span>,
        'Falha ao modificar o registro. Por favor, verifique os dados fornecidos.',
        'danger'
    );
};

export const showDeleteError = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Falha ao remover o registro. Por favor, verifique os dados fornecidos.',
        'danger'
    );
};

export const showInactivateError = () => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        'Falha ao inativar o registro. Por favor, verifique os dados fornecidos.',
        'danger'
    );
};

export const showSuccess = (message: string) => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Verified'
                size='lg'
                className='me-1'
            />
            <span>Sucesso</span>
        </span>,
        message,
        'success'
    );
};

export const showInformation = (message: string) => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Info'
                size='lg'
                className='me-1'
            />
            <span>Atenção</span>
        </span>,
        message,
        'info'
    );
};

export const showWarning = (message: string) => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Warning'
                size='lg'
                className='me-1'
            />
            <span>Atenção</span>
        </span>,
        message,
        'warning'
    );
};

export const showError = (message: string) => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Icon
                icon='Error'
                size='lg'
                className='me-1'
            />
            <span>Erro</span>
        </span>,
        message,
        'danger'
    );
};