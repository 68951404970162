import dayjs from "dayjs";
import { FC } from "react";
import UserImage7 from '../../../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../../../assets/img/wanna/wanna7.webp';
import Avatar from "../../../../components/Avatar";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import CalendarPage from "../../appointment/CalendarPage";
import CommonDashboardGraduationCard from "../../dashboard/common/CommonDashboardGraduationCard";
import CommonDashboardSubscriptionCard from "../../dashboard/common/CommonDashboardSubscriptionCard";
import CommonPaymentTransaction from "../../financial/common/CommonPaymentTransaction";
import CommonAddressCard from "./CommonAddressCard";
import CommonEmergencyCard from "./CommonEmergencyCard";
import CommonResponsableCard from "./CommonResponsableCard";

interface ICommonStudentDetailInfoProps {
    student: IStudent | undefined;
}

const CommonStudentDetailInfo: FC<ICommonStudentDetailInfoProps> = ({ student }) => {

    return (

        <>

            <div className='pt-3 pb-5 d-flex align-items-center'>
                <span className='display-4 fw-bold me-3'>{student?.fullName}</span>
            </div>

            <div className="row">
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                    <Card borderSize={1} stretch>
                        <CardBody>
                            <div className='row g-5 py-3'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <Avatar
                                        src={student?.profileImageUrl || UserImage7}
                                        srcSet={student?.profileImageUrl || UserImage7Webp}
                                        color='primary'
                                        className='rounded-circle'
                                        shadow='sm'
                                        size={100}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>

                                    <div className='d-flex align-items-center'>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='text-muted'>
                                                <small>{'Data Nascimento'}</small>
                                            </div>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {student?.birthDate ? dayjs(student?.birthDate).format('DD/MM/YYYY') : '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='text-muted'>
                                                <small>{'Telefone'}</small>
                                            </div>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {student?.phone || '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </div>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <CommonResponsableCard student={student} />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <CommonEmergencyCard student={student} />
                        </div>
                    </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                    <CommonAddressCard student={student} />
                </div>

            </div >

            <div className="row">
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                    <CommonPaymentTransaction student={student} />
                </div>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <CommonDashboardGraduationCard student={student} />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <CommonDashboardSubscriptionCard student={student} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <CalendarPage
                        studentCognitoId={student?.cognitoUserId}
                        grade={student?.grade ?? undefined}
                        graduation={student?.belt ?? undefined}
                    />
                </div>
            </div>
        </>
    )
};

export default CommonStudentDetailInfo;