import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import IJwtPayload, { getDecodedToken } from '../common/data/jwtPayload';

export interface IAuthContextProps {
	cognitoId: string;
	setCognitoId: (id: string) => void;
	userData: Partial<IJwtPayload>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [cognitoId, setCognitoId] = useState<string>(localStorage.getItem('cognitoId') || '');
	const [userData, setUserData] = useState<Partial<IJwtPayload>>({});

	useEffect(() => {
		localStorage.setItem('cognitoId', cognitoId);
	}, [cognitoId]);

	useEffect(() => {
		if (cognitoId !== '') {
			const decodedToken = getDecodedToken();
				setUserData(decodedToken);
		} else {
			setUserData({});
		}
	}, [cognitoId]);

	const value = useMemo(
		() => ({
			cognitoId,
			setCognitoId,
			userData,
		}),
		[cognitoId, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
