import classNames from "classnames";
import { FC } from "react";
import Badge from "../../../../components/bootstrap/Badge";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";

interface CommonFaceBoxProps {
    id: string;
    image: string;
    name: string;
    checkin: string;
    faceImage: IFaceImage[];
    handleUserAttendanceOffcanvas: any;
}

const CommonFaceBox: FC<CommonFaceBoxProps> = ({ id, image, name, faceImage, checkin, handleUserAttendanceOffcanvas }) => {

    const handleOpenOffCanvas = (id: string) => {
        if(handleUserAttendanceOffcanvas)
            handleUserAttendanceOffcanvas(id);
    };

    return (
        <>

            <Card>
                <CardBody>
                    <div className='row g-3'>
                        <div className='col d-flex'>
                            <div className='flex-shrink-0'>
                                <div className='position-relative'>
                                    <div
                                        className='ratio ratio-1x1'
                                        style={{ width: 100 }}>
                                        <div
                                            className={classNames(
                                                `bg-l25-primary`,
                                                'rounded-2',
                                                'd-flex align-items-center justify-content-center',
                                                'overflow-hidden',
                                                'shadow',
                                            )}>
                                            <img
                                                src={image}
                                                alt={name}
                                                width={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex-grow-1 ms-3 d-flex justify-content-between'>
                                <div className='w-100'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex align-items-center'>
                                                <div className='fw-bold fs-5 me-2'>
                                                    {name}
                                                </div>
                                            </div>

                                            <br />
                                        </div>

                                        {/* <div className='col-auto'>
                                            <Button
                                                icon='Face'
                                                color='primary'
                                                isLight
                                                hoverShadow='sm'
                                                tag='a'
                                                onClick={() => handleOpenOffCanvas(id)}
                                                aria-label='Atribuir Aluno'
                                            />
                                        </div> */}
                                    </div>

                                    {
                                        checkin && (

                                            <div className='row g-2 mt-3'>
                                                {/* <div className='col-auto'>
                                                    {
                                                        faceImage[0].similarity > 0 ? (

                                                            <Badge
                                                                isLight
                                                                color={'success'}
                                                                className='px-3 py-2'>
                                                                <Icon
                                                                    icon={'Leaderboard'}
                                                                    size='lg'
                                                                    className='me-1'
                                                                />

                                                                {`Semelhança: ${faceImage[0].similarity.toFixed(2)}%`}
                                                            </Badge>

                                                        ) : (
                                                            <Badge isLight color={'danger'} className='px-3 py-2'>
                                                                <Icon
                                                                    icon={'Help'}
                                                                    size='lg'
                                                                    className='me-1'
                                                                />
                                                                {'Desconhecido'}
                                                            </Badge>
                                                        )
                                                    }

                                                </div> */}

                                                <div className='col-auto'>
                                                    <Badge isLight color={'success'} className='px-3 py-2'>
                                                        <Icon
                                                            icon={'CalendarToday'}
                                                            size='lg'
                                                            className='me-1'
                                                        />
                                                        {`Check-in automatico`}
                                                    </Badge>
                                                </div>
                                                <div className='col-auto'>
                                                    <Badge isLight color={'info'} className='px-3 py-2'>
                                                        <Icon
                                                            icon={'CalendarToday'}
                                                            size='lg'
                                                            className='me-1'
                                                        />
                                                        {`Data: ${checkin}`}
                                                    </Badge>
                                                </div>
                                            </div>

                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

            {/* <Card className='cursor-pointer shadow-3d-primary shadow-3d-hover'>
                <CardBody>
                    <div
                        className={classNames(
                            'rounded-2',
                            'mb-3',
                        )}>
                        <img
                            src={image}
                            alt={name}
                            width={100}
                            className='object-fit-contain p-3'
                        />
                    </div>
                    <CardTitle tag='div' className='h5 text-muted truncate-line-2'>
                        {name}
                    </CardTitle>

                    {
                        faceImage && faceImage.length > 0 && (

                            <div className='row g-2'>
                                <div className='col-auto'>

                                    {
                                        faceImage[0].similarity > 0 ? (

                                            <Badge isLight color={'success'} className='px-3 py-2'>
                                                {'Semelhança: '}{faceImage[0].similarity.toFixed(2)}{'%'}
                                            </Badge>

                                        ) : (
                                            <Badge isLight color={'danger'} className='px-3 py-2'>
                                                {'Desconhecido'}
                                            </Badge>
                                        )
                                    }
                                </div>
                            </div>

                        )
                    }
                </CardBody>
            </Card> */}
        </>
    );
};

export default CommonFaceBox;